export const GET_JOB_REQUEST = "GET_JOB_REQUEST",
	GET_JOB_SUCCESS = "GET_JOB_SUCCESS",
	GET_JOB_FAILURE = "GET_JOB_FAILURE";

export const ADD_JOB_REQUEST = "ADD_JOB_REQUEST",
	ADD_JOB_SUCCESS = "ADD_JOB_SUCCESS",
	ADD_JOB_FAILURE = "ADD_JOB_FAILURE";

export const GET_SINGLE_JOB_REQUEST = "GET_SINGLE_JOB_REQUEST",
	GET_SINGLE_JOB_SUCCESS = "GET_SINGLE_JOB_SUCCESS",
	GET_SINGLE_JOB_FAILURE = "GET_SINGLE_JOB_FAILURE";

export const UPDATE_SINGLE_JOB_REQUEST = "UPDATE_SINGLE_JOB_REQUEST",
	UPDATE_SINGLE_JOB_SUCCESS = "UPDATE_SINGLE_JOB_SUCCESS",
	UPDATE_SINGLE_JOB_FAILURE = "UPDATE_SINGLE_JOB_FAILURE";

export const DELETE_SINGLE_JOB_REQUEST = "DELETE_SINGLE_JOB_REQUEST",
	DELETE_SINGLE_JOB_SUCCESS = "DELETE_SINGLE_JOB_SUCCESS",
	DELETE_SINGLE_JOB_FAILURE = "DELETE_SINGLE_JOB_FAILURE";

export const SOFT_DELETE_SINGLE_JOB_REQUEST = "SOFT_DELETE_SINGLE_JOB_REQUEST",
	SOFT_DELETE_SINGLE_JOB_SUCCESS = "SOFT_DELETE_SINGLE_JOB_SUCCESS",
	SOFT_DELETE_SINGLE_JOB_FAILURE = "SOFT_DELETE_SINGLE_JOB_FAILURE";

export const GET_APPLICATION_REQUEST = "GET_APPLICATION_REQUEST",
	GET_APPLICATION_SUCCESS = "GET_APPLICATION_SUCCESS",
	GET_APPLICATION_FAILURE = "GET_APPLICATION_FAILURE";

export const GET_SINGLE_APPLICATION_REQUEST = "GET_SINGLE_APPLICATION_REQUEST",
	GET_SINGLE_APPLICATION_SUCCESS = "GET_SINGLE_APPLICATION_SUCCESS",
	GET_SINGLE_APPLICATION_FAILURE = "GET_SINGLE_APPLICATION_FAILURE";

export const SOFT_DELETE_SINGLE_APPLICATION_REQUEST = "SOFT_DELETE_SINGLE_APPLICATION_REQUEST",
	SOFT_DELETE_SINGLE_APPLICATION_SUCCESS = "SOFT_DELETE_SINGLE_APPLICATION_SUCCESS",
	SOFT_DELETE_SINGLE_APPLICATION_FAILURE = "SOFT_DELETE_SINGLE_APPLICATION_FAILURE";

export const GET_CATEGORIES_REQUEST = "GET_CATEGORIES_REQUEST",
	GET_CATEGORIES_SUCCESS = "GET_CATEGORIES_SUCCESS",
	GET_CATEGORIES_FAILURE = "GET_CATEGORIES_FAILURE";

export const GET_SINGLE_CATEGORY_REQUEST = "GET_SINGLE_CATEGORY_REQUEST",
	GET_SINGLE_CATEGORY_SUCCESS = "GET_SINGLE_CATEGORY_SUCCESS",
	GET_SINGLE_CATEGORY_FAILURE = "GET_SINGLE_CATEGORY_FAILURE";

export const ADD_SINGLE_CATEGORY_REQUEST = "ADD_SINGLE_CATEGORY_REQUEST",
	ADD_SINGLE_CATEGORY_SUCCESS = "ADD_SINGLE_CATEGORY_SUCCESS",
	ADD_SINGLE_CATEGORY_FAILURE = "ADD_SINGLE_CATEGORY_FAILURE";

export const UPDATE_SINGLE_CATEGORY_REQUEST = "UPDATE_SINGLE_CATEGORY_REQUEST",
	UPDATE_SINGLE_CATEGORY_SUCCESS = "UPDATE_SINGLE_CATEGORY_SUCCESS",
	UPDATE_SINGLE_CATEGORY_FAILURE = "UPDATE_SINGLE_CATEGORY_FAILURE";

export const DELETE_CATEGORIES_REQUEST = "DELETE_CATEGORIES_REQUEST",
	DELETE_CATEGORIES_SUCCESS = "DELETE_CATEGORIES_SUCCESS",
	DELETE_CATEGORIES_FAILURE = "DELETE_CATEGORIES_FAILURE";

export const SOFT_DELETE_CATEGORIES_REQUEST = "SOFT_DELETE_CATEGORIES_REQUEST",
	SOFT_DELETE_CATEGORIES_SUCCESS = "SOFT_DELETE_CATEGORIES_SUCCESS",
	SOFT_DELETE_CATEGORIES_FAILURE = "SOFT_DELETE_CATEGORIES_FAILURE";

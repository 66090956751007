import { Card, message, Tag } from "antd";
import { useMemo, useEffect } from "react";
import { ErrorBoundary } from "react-error-boundary";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import BasicLayout from "../../components/BasicLayout";
import FallBack from "../../components/common/FallBack/FallBack";
import Notification from "../../components/common/Notification";
import { CustomTab } from "../../components/common/Tab/Tab";
import { getSingleTemplate } from "../../store/actions/template/template.action";
import Tables from "../../components/common/Table/Table";

const ViewTemplate = (props) => {
	const templateId = +props.match.params.id;
	const dispatch = useDispatch();
	const template = useSelector((state) => state?.template?.template || {});
	const history = useHistory();

	const location = useLocation();
	const tab2 = location.search;
	const softdelete = new URLSearchParams(location.search).get("soft-delete");

	useEffect(() => {
		dispatch(getSingleTemplate(templateId, softdelete)).catch((err) => {
			Notification("error", err, "", "topRight");
			history.push("/usermanagement/template");
		});
	}, [dispatch, softdelete, history, templateId]);

	let data = [];
	let isOrderEmpty = Object.keys(template).length === 0;
	if (!isOrderEmpty) {
		data = [
			{ title: "Id", description: template.id },
			{
				title: "Name",
				description: template.name,
			},
			{
				title: "Received",
				description: new Date(template.createdAt).toLocaleString(),
			},
		];
	}

	const handleError = (error) => {
		message.error(error.message);
	};

	let operationData = {
		deletedBy: template?.deletedBy,
		updatedBy: template?.updatedBy,
		restoredBy: template?.restoredBy,
	};

	const accessLevel = template.permissions;

	const dataSource = useMemo(() => {
		let tempData = [];
		if (accessLevel) {
			for (const [key] of Object.entries(accessLevel)) {
				const objMenu = accessLevel[key];
				const mainMenu = key.replace(/([A-Z])/g, " $1");
				for (const [key, value] of Object.entries(objMenu)) {
					const subMenuKey = key.replace(/([A-Z])/g, " $1");

					const dbAccessAllowed = value;
					const accessAllowed = value;
					if (dbAccessAllowed.length === 0) {
						delete objMenu[subMenuKey];
					}
					tempData?.push({
						menu: mainMenu.replace(/^./, function (str) {
							return str.toUpperCase();
						}),
						subMenu: subMenuKey.replace(/^./, function (str) {
							return str.toUpperCase();
						}),
						access: accessAllowed,
						key: subMenuKey,
					});
				}
			}
			return tempData;
		}
		return [{}];
	}, [accessLevel]);

	return (
		<BasicLayout accessLevel={props.accessLevel}>
			<ErrorBoundary FallbackComponent={FallBack} onError={handleError}>
				<Card className="enqiury-detail-card" data-testid="enqiury-detail-card">
					<CustomTab
						data={data}
						operationData={operationData}
						redirectUrl={`/usermanagement/template/update/${templateId}`}
					/>
					{tab2 !== "?tabs=history" && (
						<Tables columns={columns} dataSource={dataSource} pagination={false} />
					)}
				</Card>
			</ErrorBoundary>
		</BasicLayout>
	);
};

export default ViewTemplate;

const columns = [
	{
		title: "Main Menu",
		dataIndex: "menu",
		key: "menu",
	},
	{
		title: "SubMenu",
		dataIndex: "subMenu",
		key: "subMenu",
	},
	{
		title: "Allowed Access",
		dataIndex: "access",
		key: "access",
		render: (access) => (
			<>
				{access?.map((tag) => {
					return (
						<Tag color="green" key={tag}>
							{tag.toUpperCase()}
						</Tag>
					);
				})}
			</>
		),
	},
];

import {
	GET_SUB_ASSEMBLIES_SUCCESS,
	GET_SINGLE_SUB_ASSEMBLY_SUCCESS,
	RESTORE_SINGLE_SUB_ASSEMBLY_SUCCESS,
	SOFT_DELETE_SINGLE_SUB_ASSEMBLY_SUCCESS,
	GET_MODEL_RELATED_SUB_ASSEMBLIES_SUCCESS,
} from "../actions/subAssembly/subAssembly.actionType";
import globalState from "../state";

const requestRegex = /_REQUEST/;
const errorRegex = /_FAILURE/;
const successRegex = /_SUCCESS/;

const initialState = {
	errorMessage: "",
	isLoading: false,
	subAssemblies: [],
	subAssembly: {},
	modelRelatedSubAssemblies: [],
	...globalState,
};

const subAssemblyReducer = (state = initialState, action) => {
	const isRequest = requestRegex.test(action.type);
	const isError = errorRegex.test(action.type);
	const isSuccess = successRegex.test(action.type);

	if (isRequest) {
		return { ...state, isLoading: true, errorMessage: "" };
	}
	if (isError) {
		return { ...state, isLoading: false, errorMessage: action.message };
	}
	if (isSuccess) {
		state = { ...state, isLoading: false, errorMessage: "" };
	}

	switch (action.type) {
		case GET_SUB_ASSEMBLIES_SUCCESS:
			return { ...state, subAssemblies: action.data };

		case GET_SINGLE_SUB_ASSEMBLY_SUCCESS:
			return { ...state, subAssembly: action.data };

		case GET_MODEL_RELATED_SUB_ASSEMBLIES_SUCCESS:
			return { ...state, modelRelatedSubAssemblies: action.data };

		case SOFT_DELETE_SINGLE_SUB_ASSEMBLY_SUCCESS:
			let softDeletedsubAssemblyId = action.data;
			let afterSoftDeletedData = state.subAssemblies.filter(
				(data) => data.id.toString() !== softDeletedsubAssemblyId.toString()
			);
			return { ...state, subAssemblies: afterSoftDeletedData };

		case RESTORE_SINGLE_SUB_ASSEMBLY_SUCCESS:
			return { ...state };

		default:
			return state;
	}
};

export default subAssemblyReducer;

import Notification from "../../../components/common/Notification";
import * as galleryApi from "../../../services/gallery";
import {
	ADD_GALLERY_FAILURE,
	ADD_GALLERY_REQUEST,
	ADD_GALLERY_SUCCESS,
	ASSIGN_GALLERY_FAILURE,
	ASSIGN_GALLERY_REQUEST,
	ASSIGN_GALLERY_SUCCESS,
	DELETE_GALLERY_SUBMISSION_FAILURE,
	DELETE_GALLERY_SUBMISSION_REQUEST,
	DELETE_GALLERY_SUBMISSION_SUCCESS,
	DELETE_SINGLE_GALLERY_FAILURE,
	DELETE_SINGLE_GALLERY_REQUEST,
	DELETE_SINGLE_GALLERY_SUCCESS,
	GET_GALLERY_FAILURE,
	GET_GALLERY_REQUEST,
	GET_GALLERY_SUBMISSION_FAILURE,
	GET_GALLERY_SUBMISSION_REQUEST,
	GET_GALLERY_SUBMISSION_SUCCESS,
	GET_GALLERY_SUCCESS,
	GET_SINGLE_GALLERY_FAILURE,
	GET_SINGLE_GALLERY_REQUEST,
	GET_SINGLE_GALLERY_SUBMISSION_FAILURE,
	GET_SINGLE_GALLERY_SUBMISSION_REQUEST,
	GET_SINGLE_GALLERY_SUBMISSION_SUCCESS,
	GET_SINGLE_GALLERY_SUCCESS,
	UPDATE_SINGLE_GALLERY_FAILURE,
	UPDATE_SINGLE_GALLERY_REQUEST,
	UPDATE_SINGLE_GALLERY_SUCCESS,
	SOFTDELETE_SINGLE_GALLERY_REQUEST,
	SOFTDELETE_SINGLE_GALLERY_SUCCESS,
	SOFTDELETE_SINGLE_GALLERY_FAILURE,
	SOFTDELETE_SINGLE_GALLERY_SUBMISISSION_REQUEST,
	SOFTDELETE_SINGLE_GALLERY_SUBMISISSION_FAILURE,
	SOFTDELETE_SINGLE_GALLERY_SUBMISISSION_SUCCESS,
} from "./gallery.actionType";

export const requestGallerySubmissions = () => {
	return {
		type: GET_GALLERY_SUBMISSION_REQUEST,
	};
};

export const getGallerySubmissionsSuccess = (data) => {
	return {
		type: GET_GALLERY_SUBMISSION_SUCCESS,
		data,
	};
};

export const getGallerySubmissionsFailure = (message) => {
	return {
		type: GET_GALLERY_SUBMISSION_FAILURE,
		message,
	};
};

export const getGallerySubmissions = () => {
	return (dispatch) => {
		dispatch(requestGallerySubmissions());
		return galleryApi
			.getGallerySubmission()
			.then((resp) => {
				const data = resp.data.data;
				dispatch(getGallerySubmissionsSuccess(data));
			})
			.catch((err) => {
				const errorMessage = err.response && err.response.data.message;
				dispatch(getGallerySubmissionsFailure(errorMessage));
			});
	};
};

export const requestSingleGallerySubmission = () => {
	return {
		type: GET_SINGLE_GALLERY_SUBMISSION_REQUEST,
	};
};

export const getSingleGallerySubmissionSuccess = (data) => {
	return {
		type: GET_SINGLE_GALLERY_SUBMISSION_SUCCESS,
		data,
	};
};

export const getSingleGallerySubmissionFailure = (message) => {
	return {
		type: GET_SINGLE_GALLERY_SUBMISSION_FAILURE,
		message,
	};
};

export const getSingleGallerySubmission = (id, softdelete) => {
	return (dispatch) => {
		dispatch(requestSingleGallerySubmission());
		return galleryApi
			.getSingleGallerySubmission(id, softdelete)
			.then((resp) => {
				const data = resp.data.data;
				dispatch(getSingleGallerySubmissionSuccess(data));
			})
			.catch((err) => {
				const errorMessage = err.response && err.response.data.message;
				dispatch(getSingleGallerySubmissionFailure(errorMessage));
				throw errorMessage;
			});
	};
};

export const requestDeleteSingleGallerySubmission = () => {
	return {
		type: DELETE_GALLERY_SUBMISSION_REQUEST,
	};
};

export const deleteSingleGallerySubmissionSuccess = (data) => {
	return {
		type: DELETE_GALLERY_SUBMISSION_SUCCESS,
		data,
	};
};

export const deleteSingleGallerySubmissionFailure = (message) => {
	return {
		type: DELETE_GALLERY_SUBMISSION_FAILURE,
		message,
	};
};

export const deleteSingleGallerySubmission = (id) => {
	return (dispatch) => {
		dispatch(requestDeleteSingleGallerySubmission());
		return galleryApi
			.deleteSingleGallerySubmission(id)
			.then((_resp) => {
				dispatch(deleteSingleGallerySubmissionSuccess(id));
				Notification("success", "Gallery submission has been deleted successfully", "", "topRight");
			})
			.catch((err) => {
				const errorMessage = err.response && err.response.data.message;
				dispatch(deleteSingleGallerySubmissionFailure(errorMessage));
				Notification("error", errorMessage, "", "topRight");
			});
	};
};

export const requestGalleryAssign = () => {
	return {
		type: ASSIGN_GALLERY_REQUEST,
	};
};

export const galleryAssignSuccess = (data) => {
	return {
		type: ASSIGN_GALLERY_SUCCESS,
		data,
	};
};

export const galleryAssignFailure = (message) => {
	return {
		type: ASSIGN_GALLERY_FAILURE,
		message,
	};
};

export const assignGallery = (data, history) => {
	return (dispatch) => {
		dispatch(requestGalleryAssign());
		return galleryApi
			.assignGallery(data)
			.then((resp) => {
				const data = resp.data.data;
				dispatch(galleryAssignSuccess(data));
				history.push("/gallery/added");
				Notification("success", "Assigning data is successfull!", "", "topRight");
			})
			.catch((err) => {
				const errorMessage = err.response && err.response.data.message;
				dispatch(galleryAssignFailure(errorMessage));
				Notification("error", "Error Occurred", errorMessage, "topRight");
			});
	};
};

export const requestGalleryAdd = () => {
	return {
		type: ADD_GALLERY_REQUEST,
	};
};

export const galleryAddSuccess = (data) => {
	return {
		type: ADD_GALLERY_SUCCESS,
		data,
	};
};

export const galleryAddFailure = (message) => {
	return {
		type: ADD_GALLERY_FAILURE,
		message,
	};
};

export const addGallery = (data) => {
	return (dispatch) => {
		dispatch(requestGalleryAdd());
		return galleryApi
			.addGallery(data)
			.then((resp) => {
				const data = resp.data.data;
				return data;
			})
			.catch((err) => {
				const errorMessage = err.response && err.response.data.message;
				dispatch(galleryAddFailure(errorMessage));
				Notification("error", "Error Occurred", errorMessage);
			});
	};
};

export const requestGetGallery = () => {
	return {
		type: GET_GALLERY_REQUEST,
	};
};

export const galleryGetSuccess = (data) => {
	return {
		type: GET_GALLERY_SUCCESS,
		data,
	};
};

export const galleryGetFailure = (message) => {
	return {
		type: GET_GALLERY_FAILURE,
		message,
	};
};

export const getGallery = () => {
	return (dispatch) => {
		dispatch(requestGetGallery());
		return galleryApi
			.getGallery()
			.then((resp) => {
				const data = resp.data.data;
				dispatch(galleryGetSuccess(data));
			})
			.catch((err) => {
				const errorMessage = err.response && err.response.data.message;
				dispatch(galleryGetFailure(errorMessage));
			});
	};
};

export const requestGetSingleGallery = () => {
	return {
		type: GET_SINGLE_GALLERY_REQUEST,
	};
};

export const galleryGetSingleSuccess = (data) => {
	return {
		type: GET_SINGLE_GALLERY_SUCCESS,
		data,
	};
};

export const galleryGetSingleFailure = (message) => {
	return {
		type: GET_SINGLE_GALLERY_FAILURE,
		message,
	};
};

export const getSingleGallery = (id, softdelete) => {
	return (dispatch) => {
		dispatch(requestGetSingleGallery());
		return galleryApi
			.getSingleGallery(id, softdelete)
			.then((resp) => {
				const data = resp.data.data;
				dispatch(galleryGetSingleSuccess(data));
			})
			.catch((err) => {
				const errorMessage = err.response && err.response.data.message;
				dispatch(galleryGetSingleFailure(errorMessage));
				throw errorMessage;
			});
	};
};

export const requestUpdateSingleGallery = () => {
	return {
		type: UPDATE_SINGLE_GALLERY_REQUEST,
	};
};

export const galleryUpdateSingleSuccess = (data) => {
	return {
		type: UPDATE_SINGLE_GALLERY_SUCCESS,
		data,
	};
};

export const galleryUpdateSingleFailure = (message) => {
	return {
		type: UPDATE_SINGLE_GALLERY_FAILURE,
		message,
	};
};

export const updateSingleGallery = (id, data, history) => {
	return (dispatch) => {
		dispatch(requestUpdateSingleGallery());
		return galleryApi
			.updateSingleGallery(id, data)
			.then((_resp) => {
				dispatch(galleryUpdateSingleSuccess({ id, data }));
				history.push("/gallery/added");
				Notification("success", "Media has been succesfully updated.", "", "topRight");
			})
			.catch((err) => {
				const errorMessage = err.response && err.response.data.message;
				dispatch(galleryUpdateSingleFailure(errorMessage));
				throw err;
			});
	};
};

export const requestDeleteSingleGallery = () => {
	return {
		type: DELETE_SINGLE_GALLERY_REQUEST,
	};
};

export const galleryDeleteSingleSuccess = (data) => {
	return {
		type: DELETE_SINGLE_GALLERY_SUCCESS,
		data,
	};
};

export const galleryDeleteSingleFailure = (message) => {
	return {
		type: DELETE_SINGLE_GALLERY_FAILURE,
		message,
	};
};

export const deleteSingleGallery = (id) => {
	return (dispatch) => {
		dispatch(requestDeleteSingleGallery());
		return galleryApi
			.deleteSingleGallery(id)
			.then((_resp) => {
				dispatch(galleryDeleteSingleSuccess(id));
				Notification("success", "Media has been succesfully deleted.", "", "topRight");
			})
			.catch((err) => {
				const errorMessage = err.response && err.response.data.message;
				dispatch(galleryDeleteSingleFailure(errorMessage));
				Notification("error", "Something Went Wrong! Please try again  later.", "", "topRight");
			});
	};
};

export const requestSoftDeleteSingleGallery = () => {
	return {
		type: SOFTDELETE_SINGLE_GALLERY_REQUEST,
	};
};

export const softDeleteSingleGallerySuccess = (data) => {
	return {
		type: SOFTDELETE_SINGLE_GALLERY_SUCCESS,
		data,
	};
};

export const softDeleteSingleGalleryFailure = (message) => {
	return {
		type: SOFTDELETE_SINGLE_GALLERY_FAILURE,
		message,
	};
};

export const softDeleteSingleGallery = (id, reason) => {
	return (dispatch) => {
		dispatch(requestSoftDeleteSingleGallery());
		return galleryApi
			.softDeleteSingleGallery(id, reason)
			.then((resp) => {
				dispatch(softDeleteSingleGallerySuccess(id));
				Notification("success", resp.data.message, "", "topRight");
			})
			.catch((err) => {
				const errorMessage = err.response && err.response.data.message;
				dispatch(softDeleteSingleGalleryFailure(errorMessage));
				Notification("error", errorMessage, "", "topRight");
			});
	};
};

export const requestSoftDeleteSingleGallerySubmission = () => {
	return {
		type: SOFTDELETE_SINGLE_GALLERY_SUBMISISSION_REQUEST,
	};
};

export const softDeleteSingleGallerySubmissionSuccess = (data) => {
	return {
		type: SOFTDELETE_SINGLE_GALLERY_SUBMISISSION_SUCCESS,
		data,
	};
};

export const softDeleteSingleGallerySubmissionFailure = (message) => {
	return {
		type: SOFTDELETE_SINGLE_GALLERY_SUBMISISSION_FAILURE,
		message,
	};
};

export const softDeleteSingleGallerySubmission = (id, reason) => {
	return (dispatch) => {
		dispatch(requestSoftDeleteSingleGallerySubmission());
		return galleryApi
			.softDeleteSingleGallerySubmission(id, reason)
			.then((resp) => {
				dispatch(softDeleteSingleGallerySubmissionSuccess(id));
				Notification("success", resp.data.message, "", "topRight");
			})
			.catch((err) => {
				const errorMessage = err.response && err.response.data.message;
				dispatch(softDeleteSingleGallerySubmissionFailure(errorMessage));
				Notification("error", errorMessage, "", "topRight");
			});
	};
};

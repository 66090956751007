import { Table } from "antd";
import React from "react";
import "./table.css";

const Tables = ({
	dataSource,
	columns,
	footerText,
	pagination = true,
	handleChange = () => {},
}) => {
	return (
		<Table
			style={{ marginTop: "20px" }}
			dataSource={dataSource}
			columns={columns}
			scroll={{ x: 800 }}
			className="table"
			footer={() => footerText}
			onChange={handleChange}
			pagination={pagination}
		/>
	);
};

export default Tables;

import Notification from "../../../../components/common/Notification";
import * as accessoryApi from "../../../../services/accessory";
import {
	ADD_ACCESSORY_FAILURE,
	ADD_ACCESSORY_REQUEST,
	ADD_ACCESSORY_SUCCESS,
	GET_ACCESSORIES_FAILURE,
	GET_ACCESSORIES_REQUEST,
	GET_ACCESSORIES_SUCCESS,
	GET_SINGLE_ACCESSORY_FAILURE,
	GET_SINGLE_ACCESSORY_REQUEST,
	GET_SINGLE_ACCESSORY_SUCCESS,
	RESTORE_SINGLE_ACCESSORY_FAILURE,
	RESTORE_SINGLE_ACCESSORY_REQUEST,
	RESTORE_SINGLE_ACCESSORY_SUCCESS,
	SOFT_DELETE_SINGLE_ACCESSORY_FAILURE,
	SOFT_DELETE_SINGLE_ACCESSORY_REQUEST,
	SOFT_DELETE_SINGLE_ACCESSORY_SUCCESS,
	UPDATE_ACCESSORY_FAILURE,
	UPDATE_ACCESSORY_REQUEST,
	UPDATE_ACCESSORY_SUCCESS,
} from "./accessory.actionType";

// GET REQUEST
export const requestGetAllAccessories = () => {
	return {
		type: GET_ACCESSORIES_REQUEST,
	};
};
export const getAllAccessoriesSuccess = (data) => {
	return {
		type: GET_ACCESSORIES_SUCCESS,
		data,
	};
};
export const getAllAccessoriesFailure = (message) => {
	return {
		type: GET_ACCESSORIES_FAILURE,
		message,
	};
};

// Get All Accessories
export const getAllAccessories = () => {
	return (dispatch) => {
		dispatch(requestGetAllAccessories());
		return accessoryApi
			.getAllAccessories()
			.then((resp) => {
				const data = resp.data.data;
				dispatch(getAllAccessoriesSuccess(data));
			})
			.catch((err) => {
				const errorMessage =
					(err?.response && err?.response?.data?.message) || "Something went wrong!";
				dispatch(getAllAccessoriesFailure(errorMessage));
			});
	};
};

export const requestGetSingleAccessory = () => {
	return {
		type: GET_SINGLE_ACCESSORY_REQUEST,
	};
};

export const getSingleAccessorySuccess = (data) => {
	return {
		type: GET_SINGLE_ACCESSORY_SUCCESS,
		data,
	};
};

export const getSingleAccessoryFailure = (message) => {
	return {
		type: GET_SINGLE_ACCESSORY_FAILURE,
		message,
	};
};

export const getSingleAccessory = (id, softdelete) => {
	return (dispatch) => {
		dispatch(requestGetSingleAccessory());
		return accessoryApi
			.getSingleAccessory(id, softdelete)
			.then((resp) => {
				const data = resp.data.data;
				dispatch(getSingleAccessorySuccess(data));
			})
			.catch((err) => {
				const errorMessage =
					(err?.response && err?.response?.data?.message) || "Something went wrong";
				dispatch(getSingleAccessoryFailure(errorMessage));
				throw errorMessage;
			});
	};
};

export const requestSoftDeleteSingleAccessory = () => {
	return {
		type: SOFT_DELETE_SINGLE_ACCESSORY_REQUEST,
	};
};

export const softDeleteSingleAccessorySuccess = (data) => {
	return {
		type: SOFT_DELETE_SINGLE_ACCESSORY_SUCCESS,
		data,
	};
};

export const softDeleteSingleAccessoryFailure = (message) => {
	return {
		type: SOFT_DELETE_SINGLE_ACCESSORY_FAILURE,
		message,
	};
};

export const softDeleteSingleAccessory = (id, reason) => {
	return (dispatch) => {
		dispatch(requestSoftDeleteSingleAccessory());
		return accessoryApi
			.softDeleteSingleAccessory(id, reason)
			.then((resp) => {
				dispatch(softDeleteSingleAccessorySuccess(id));
				Notification("success", resp.data.message, "", "topRight");
				return;
			})
			.catch((err) => {
				const errorMessage =
					(err?.response && err?.response?.data?.message) || "Something went wrong";
				dispatch(softDeleteSingleAccessoryFailure(errorMessage));
				Notification("error", errorMessage, "", "topRight");
				return;
			});
	};
};

export const requestAccessoryRestore = () => {
	return {
		type: RESTORE_SINGLE_ACCESSORY_REQUEST,
	};
};

export const restoreAccessorySuccess = () => {
	return {
		type: RESTORE_SINGLE_ACCESSORY_SUCCESS,
	};
};

export const restoreAccessoryFailure = (message) => {
	return {
		type: RESTORE_SINGLE_ACCESSORY_FAILURE,
		message,
	};
};

export const restoreAccessory = (id) => {
	return (dispatch) => {
		dispatch(requestAccessoryRestore());
		return accessoryApi
			.restoreAccessory(id)
			.then((resp) => {
				dispatch(restoreAccessorySuccess(id));
				Notification("success", resp.data.message, "", "topRight");
			})
			.catch((err) => {
				const errorMessage = (err.response && err.response.data.message) || "Something went wrong!";
				dispatch(restoreAccessoryFailure(errorMessage));
			});
	};
};

export const requestAccessoryAdd = () => {
	return {
		type: ADD_ACCESSORY_REQUEST,
	};
};

export const AccessoryAddSuccess = (data) => {
	return {
		type: ADD_ACCESSORY_SUCCESS,
		data,
	};
};

export const AccessoryAddFailure = (message) => {
	return {
		type: ADD_ACCESSORY_FAILURE,
		message,
	};
};

export const addAccessory = (data) => {
	return (dispatch) => {
		dispatch(requestAccessoryAdd());
		return accessoryApi
			.addAccessory(data)
			.then((resp) => {
				const data = resp.data.data;
				return data;
			})
			.catch((err) => {
				const errorMessage = err.response && err.response.data.message;
				dispatch(AccessoryAddFailure(errorMessage));
				Notification("error", "Error Occurred", errorMessage);
			});
	};
};

//FOR UPDATE Accessory
export const requestUpdateAccessory = () => {
	return {
		type: UPDATE_ACCESSORY_REQUEST,
	};
};

export const updateAccessorySuccess = (data) => {
	return {
		type: UPDATE_ACCESSORY_SUCCESS,
		data,
	};
};

export const updateAccessoryFailure = (message) => {
	return {
		type: UPDATE_ACCESSORY_FAILURE,
		message,
	};
};

export const updateAccessory = (id, data) => {
	return (dispatch) => {
		dispatch(requestUpdateAccessory());
		return accessoryApi
			.updateAccessory(id, data)
			.then((resp) => {
				const data = resp.data;
				return data;
			})
			.catch((err) => {
				const errorMessage = err.response && err.response.data.message;
				dispatch(updateAccessoryFailure(errorMessage));
				throw err;
			});
	};
};

export const updateAccessoryFromTable = (id, data) => {
	return (dispatch) => {
		dispatch(requestUpdateAccessory());
		return accessoryApi
			.updateAccessory(id, data)
			.then(async(resp) => {
				await dispatch(getAllAccessories());
				dispatch(updateAccessorySuccess());
				const message = resp.data.message;
				return Notification("success", "", message);
			})
			.catch((err) => {
				const errorMessage = err.response && err.response.data.message;
				dispatch(updateAccessoryFailure(errorMessage));
				throw err;
			});
	};
};

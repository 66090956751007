import Notification from "../../../../components/common/Notification";
import * as implementsApi from "../../../../services/implements";
import {
	ADD_IMPLEMENTS_FAILURE,
	ADD_IMPLEMENTS_REQUEST,
	ADD_IMPLEMENTS_SUCCESS,
	GET_IMPLEMENTS_FAILURE,
	GET_IMPLEMENTS_REQUEST,
	GET_IMPLEMENTS_SUCCESS,
	GET_SINGLE_IMPLEMENTS_FAILURE,
	GET_SINGLE_IMPLEMENTS_REQUEST,
	GET_SINGLE_IMPLEMENTS_SUCCESS,
	RESTORE_SINGLE_IMPLEMENTS_FAILURE,
	RESTORE_SINGLE_IMPLEMENTS_REQUEST,
	RESTORE_SINGLE_IMPLEMENTS_SUCCESS,
	SOFT_DELETE_SINGLE_IMPLEMENTS_FAILURE,
	SOFT_DELETE_SINGLE_IMPLEMENTS_REQUEST,
	SOFT_DELETE_SINGLE_IMPLEMENTS_SUCCESS,
	UPDATE_IMPLEMENTS_FAILURE,
	UPDATE_IMPLEMENTS_REQUEST,
	UPDATE_IMPLEMENTS_SUCCESS,
} from "./implements.actionType";

export const requestImplementsAdd = () => {
	return {
		type: ADD_IMPLEMENTS_REQUEST,
	};
};

export const ImplementsAddSuccess = (data) => {
	return {
		type: ADD_IMPLEMENTS_SUCCESS,
		data,
	};
};

export const ImplementsAddFailure = (message) => {
	return {
		type: ADD_IMPLEMENTS_FAILURE,
		message,
	};
};

export const addImplements = (data) => {
	return (dispatch) => {
		dispatch(requestImplementsAdd());
		return implementsApi
			.addImplements(data)
			.then((resp) => {
				const data = resp.data.data;
				return data;
			})
			.catch((err) => {
				const errorMessage = err.response && err.response.data.message;
				dispatch(ImplementsAddFailure(errorMessage));
				Notification("error", "Error Occurred", errorMessage);
			});
	};
};

//FOR UPDATE Implements
export const requestUpdateImplements = () => {
	return {
		type: UPDATE_IMPLEMENTS_REQUEST,
	};
};

export const updateImplementsSuccess = (data) => {
	return {
		type: UPDATE_IMPLEMENTS_SUCCESS,
		data,
	};
};

export const updateImplementsFailure = (message) => {
	return {
		type: UPDATE_IMPLEMENTS_FAILURE,
		message,
	};
};

export const updateImplements = (id, data) => {
	return (dispatch) => {
		dispatch(requestUpdateImplements());
		return implementsApi
			.updateImplements(id, data)
			.then((resp) => {
				const data = resp.data;
				return data;
			})
			.catch((err) => {
				const errorMessage = err.response && err.response.data.message;
				dispatch(updateImplementsFailure(errorMessage));
				throw err;
			});
	};
};

// GET REQUEST
export const requestGetAllImplements = () => {
	return {
		type: GET_IMPLEMENTS_REQUEST,
	};
};
export const getAllImplementsSuccess = (data) => {
	return {
		type: GET_IMPLEMENTS_SUCCESS,
		data,
	};
};
export const getAllImplementsFailure = (message) => {
	return {
		type: GET_IMPLEMENTS_FAILURE,
		message,
	};
};

// Get All Implements
export const getAllImplements = () => {
	return (dispatch) => {
		dispatch(requestGetAllImplements());
		return implementsApi
			.getAllImplements()
			.then((resp) => {
				const data = resp.data.data;
				dispatch(getAllImplementsSuccess(data));
			})
			.catch((err) => {
				const errorMessage =
					(err?.response && err?.response?.data?.message) || "Something went wrong!";
				dispatch(getAllImplementsFailure(errorMessage));
			});
	};
};

export const updateImplementFromTable = (id, data) => {
	return (dispatch) => {
		dispatch(requestUpdateImplements());
		return implementsApi
			.updateImplements(id, data)
			.then(async(resp) => {
				await dispatch(getAllImplements());
				dispatch(updateImplementsSuccess());
				const message = resp.data.message;
				return Notification("success", "", message);
			})
			.catch((err) => {
				const errorMessage = err.response && err.response.data.message;
				dispatch(updateImplementsFailure(errorMessage));
				throw err;
			});
	};
};

export const requestGetSingleImplements = () => {
	return {
		type: GET_SINGLE_IMPLEMENTS_REQUEST,
	};
};

export const getSingleImplementsSuccess = (data) => {
	return {
		type: GET_SINGLE_IMPLEMENTS_SUCCESS,
		data,
	};
};

export const getSingleImplementsFailure = (message) => {
	return {
		type: GET_SINGLE_IMPLEMENTS_FAILURE,
		message,
	};
};

export const getSingleImplements = (id, softdelete) => {
	return (dispatch) => {
		dispatch(requestGetSingleImplements());
		return implementsApi
			.getSingleImplements(id, softdelete)
			.then((resp) => {
				const data = resp.data.data;
				dispatch(getSingleImplementsSuccess(data));
			})
			.catch((err) => {
				const errorMessage =
					(err?.response && err?.response?.data?.message) || "Something went wrong";
				dispatch(getSingleImplementsFailure(errorMessage));
				throw errorMessage;
			});
	};
};

export const requestSoftDeleteSingleImplements = () => {
	return {
		type: SOFT_DELETE_SINGLE_IMPLEMENTS_REQUEST,
	};
};

export const softDeleteSingleImplementsSuccess = (data) => {
	return {
		type: SOFT_DELETE_SINGLE_IMPLEMENTS_SUCCESS,
		data,
	};
};

export const softDeleteSingleImplementsFailure = (message) => {
	return {
		type: SOFT_DELETE_SINGLE_IMPLEMENTS_FAILURE,
		message,
	};
};

export const softDeleteSingleImplements = (id, reason) => {
	return (dispatch) => {
		dispatch(requestSoftDeleteSingleImplements());
		return implementsApi
			.softDeleteSingleImplements(id, reason)
			.then((resp) => {
				dispatch(softDeleteSingleImplementsSuccess(id));
				return Notification("success", resp.data.message, "", "topRight");
			})
			.catch((err) => {
				const errorMessage =
					(err?.response && err?.response?.data?.message) || "Something went wrong";
				dispatch(softDeleteSingleImplementsFailure(errorMessage));
			});
	};
};

export const requestImplementsRestore = () => {
	return {
		type: RESTORE_SINGLE_IMPLEMENTS_REQUEST,
	};
};

export const restoreImplementsSuccess = () => {
	return {
		type: RESTORE_SINGLE_IMPLEMENTS_SUCCESS,
	};
};

export const restoreImplementsFailure = (message) => {
	return {
		type: RESTORE_SINGLE_IMPLEMENTS_FAILURE,
		message,
	};
};

export const restoreImplements = (id) => {
	return (dispatch) => {
		dispatch(requestImplementsRestore());
		return implementsApi
			.restoreImplements(id)
			.then((resp) => {
				dispatch(restoreImplementsSuccess(id));
				Notification("success", resp.data.message, "", "topRight");
			})
			.catch((err) => {
				const errorMessage = (err.response && err.response.data.message) || "Something went wrong!";
				dispatch(restoreImplementsFailure(errorMessage));
			});
	};
};

import { invokeApi } from "../utils/api";
import { $axios } from "../utils/axios";

export const getAllTemplates = () =>
	invokeApi($axios, "/template", {}, "GET")
		.then((resp) => resp)
		.catch((err) => {
			throw err;
		});

export const getSingleTemplate = (templateId, type) =>
	invokeApi($axios, `/template/${type === "true" ? "soft-delete/" : ""}${templateId}`, {}, "GET")
		.then((resp) => resp)
		.catch((err) => {
			throw err;
		});

export const softDeleteSingleTemplate = (templateId, reason) =>
	invokeApi($axios, `/template/soft-delete/${templateId}?reason=${reason}`, {}, "DELETE")
		.then((resp) => resp)
		.catch((err) => {
			throw err;
		});

export const restoreTemplate = (templateId) =>
	invokeApi($axios, `/template/restore/${templateId}`, {}, "PUT")
		.then((resp) => resp)
		.catch((err) => {
			throw err;
		});

export const addTemplate = (data) =>
	invokeApi($axios, "/template/", data, "POST")
		.then((resp) => resp)
		.catch((err) => {
			throw err;
		});

export const updateTemplate = (id, data) =>
	invokeApi($axios, `/template/${id}`, data, "PUT")
		.then((resp) => resp)
		.catch((err) => {
			throw err;
		});

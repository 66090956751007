import { UserOutlined } from "@ant-design/icons";
import { Button, Card, Col, Form, message, Row, Space, Typography } from "antd";
import axios from "axios";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useDropzone } from "react-dropzone";
import { ErrorBoundary } from "react-error-boundary";
import { useDispatch, useSelector } from "react-redux";
import { NavLink, useHistory, useLocation } from "react-router-dom";
import BasicLayout from "../../../components/BasicLayout";
import Center from "../../../components/common/Center";
import FallBack from "../../../components/common/FallBack/FallBack";
import Notification from "../../../components/common/Notification";
import Spinner from "../../../components/common/Spinner";
import { WordCountDisplayer } from "../../../components/common/WordCountDisplayer";
import InputField from "../../../components/Form/InputField";
import Upload from "../../../components/Form/Upload";
import {
	addAccessory,
	getSingleAccessory,
	updateAccessory,
	updateAccessoryFailure,
} from "../../../store/actions/products/Accessory/accessory.action";
import { getAllHSNCodes } from "../../../store/actions/products/HSNCode/hsnCode.action";
import { formatFileName } from "../../../utils/helper";
import { aspectRatioDependancy } from "../../../utils/imageAspectRatio";
import { imageSizeDependancy } from "../../../utils/imageSize";
import CommonSelect from "../../../components/Form/Select";
import "./accessoryForm.css";

const AccessoryForm = (props) => {
	const history = useHistory();
	const dispatch = useDispatch();
	const [file, setFile] = useState([]);
	const { Title } = Typography;
	const [form] = Form.useForm();
	const accessory = useSelector((state) => state.accessory.accessory);
	const hsnCodes = useSelector((state) => state.hsnCode.hsnCodes || []);
	const [uploadMedia, setUploadMedia] = useState("");
	const admin = useSelector((state) => state?.admin?.admin || {});
	const [isHSNCodeLoading, setIsHSNCodeLoading] = useState(false);

	const accessoryId = +props.match.params.id;

	const currentLocation = props.location.pathname;
	const location = useLocation();
	const softdelete = new URLSearchParams(location.search).get("soft-delete");
	const onImageDrop = useCallback(async (acceptedFiles, fileRejections) => {
		try {
			imageSizeDependancy(fileRejections);

			await aspectRatioDependancy(acceptedFiles, setFile);
		} catch (error) {
			const errorMessage = error?.message || "Something went wrong";
			Notification("error", errorMessage, "", "topRight");
		}
	}, []);

	const {
		getRootProps: getImageRootProps,
		getInputProps: getImageInputProps,
		isDragActive: isImageDragActive,
	} = useDropzone({
		onDrop: onImageDrop,
		accept: "image/png",
		multiple: false,
		maxSize: "1000000",
	});

	const handleError = (error) => {
		message.error(error.message);
	};
	useEffect(() => {
		async function fetchData() {
			setIsHSNCodeLoading(true);
			await dispatch(getAllHSNCodes());
			setIsHSNCodeLoading(false);
		}
		fetchData();
	}, [dispatch]);

	useEffect(() => {
		if (accessoryId) {
			dispatch(getSingleAccessory(accessoryId, softdelete)).catch((err) => {
				Notification("error", err, "", "topRight");
				history.push("/products/accessory");
			});
		}
	}, [accessoryId, history, softdelete, dispatch]);

	const HSNCodeChildren = useMemo(
		() =>
			hsnCodes?.map((hsnCode) => ({
				key: `${hsnCode.code} `,
				value: hsnCode.id,
				title: hsnCode.code,
			})),
		[hsnCodes]
	);

	const onFinish = async (values) => {
		try {
			if (currentLocation === "/products/accessory/add") {
				if (file.length === 0) {
					Notification("error", "Please upload a valid Part diagram", "", "topRight");
					return;
				}
				const fileName = formatFileName(
					file[0].name.split(".")[0],
					file[0].name.split(".").pop(),
					admin
				);
				const fileType = file[0].type;
				const data = { ...values, fileName, fileType };
				const resp = await dispatch(addAccessory(data));
				if (resp && resp.signedUrl) {
					const options = {
						headers: {
							"Content-Type": uploadMedia[0].type,
						},
					};
					try {
						await axios.put(resp.signedUrl, uploadMedia[0], options);
						history.push("/products/accessory");
						Notification("success", "Accessory has been succesfully added.", "", "topRight");
					} catch (error) {
						const errorMessage = error.message || "Something went wrong";
						Notification("error", errorMessage, "", "top");
					}
				}
			} else {
				if (file.length === 0) {
					try {
						const resp = await dispatch(updateAccessory(accessoryId, values));
						const message = resp.message || "Updated successfully";
						history.push("/products/accessory");
						Notification("success", message, "", "topRight");
					} catch (err) {
						const errorMessage =
							(err?.response && err?.response?.data?.message) || "Some thing went wrong";
						dispatch(updateAccessoryFailure(errorMessage));
						Notification("error", "Error Occurred", errorMessage, "topRight");
					} finally {
						setFile([]);
					}
				} else {
					const fileName = formatFileName(
						file[0].name.split(".")[0],
						file[0].name.split(".").pop(),
						admin
					);
					const fileType = file[0].type;
					const data = { ...values, fileName, fileType };
					const resp = await dispatch(updateAccessory(accessoryId, data));

					if (resp && resp.data.signedUrl) {
						const options = {
							headers: {
								"Content-Type": uploadMedia[0].type,
							},
						};
						try {
							await axios.put(resp.data.signedUrl, uploadMedia[0], options);
							history.push("/products/accessory");
							Notification("success", "Accessory has been succesfully updated.", "", "topRight");
						} catch (error) {
							const errorMessage = error.message || "Something went wrong";
							Notification("error", "", errorMessage, "topRight");
						}
					}
				}
			}
		} catch (err) {
			if (err.message) {
				return Notification("error", "Error Occurred", err.message, "topRight");
			}
			const errorMessage =
				(err?.response && err?.response?.data?.message) || "Something went wrong";
			return Notification("error", "Error Occurred", errorMessage, "topRight");
		}
	};

	const defaultValues = useMemo(
		() => ({
			code: accessoryId ? accessory.code : "",
			name: accessoryId ? accessory.name : "",
			price: accessoryId ? accessory.price : undefined,
			stock: accessoryId ? accessory.stock : undefined,
			discount: accessoryId ? accessory.discount : undefined,
			hsnCodeId: accessoryId ? accessory?.hsnCode?.id : undefined,
		}),
		[
			accessoryId,
			accessory.code,
			accessory.name,
			accessory.price,
			accessory.stock,
			accessory.discount,
			accessory?.hsnCode?.id,
		]
	);

	useEffect(() => {
		form.setFieldsValue(defaultValues);
	}, [form, defaultValues]);

	const handleCancel = () => {
		Notification("info", "Accessory action has been cancelled", "", "topRight");
	};

	if (isHSNCodeLoading) {
		return (
			<Center>
				<Spinner />
			</Center>
		);
	}

	return (
		<BasicLayout accessLevel={props.accessLevel}>
			<ErrorBoundary FallbackComponent={FallBack} onError={handleError}>
				<Row type="flex" justify="center" align="middle" id="form-container">
					<Center height="4rem">
						{accessoryId ? (
							<Title level={2} className="update-or-add-title">
								Update Accessory
							</Title>
						) : (
							<Title level={2} className="update-or-add-title">
								Add New Accessory
							</Title>
						)}
					</Center>
					<Col>
						<Card className="form-card">
							<Form
								form={form}
								name="basic"
								layout="vertical"
								onFinish={onFinish}
								initialValues={defaultValues}
							>
								<InputField
									rules={[
										{
											required: true,
										},
										{
											min: 1,
											message: "Accessory Name be minimum 1 character.",
										},
										{
											max: 40,
											message: "Accessory Name be minimum 40 character.",
										},
									]}
									label=" Accessory Name"
									name="name"
									placeholder="Please enter Accessory name"
									prefix={<UserOutlined />}
								/>
								<CommonSelect
									rules={[{ required: true, message: "HSN/SAC Code is required" }]}
									label="Select HSN/SAC Code"
									name="hsnCodeId"
									placeholder="please Select HSN/SAC Code"
									options={HSNCodeChildren}
								/>
								<InputField
									rules={[
										{
											required: true,
										},
										{ min: 10, message: "Code must be minimum 10 characters." },
										{ max: 10, message: "Code must be maximum 10 characters." },
									]}
									label="Code"
									name="code"
									placeholder="Please enter code"
									prefix={<UserOutlined />}
								/>
								<div className="accessoryFormTextGroup">
									<div className="accessoryFormTextGroupChild">
										<InputField
											rules={[
												{
													required: true,
													message: "Price is required",
												},
												{
													type: "number",
													min: 1,
													message: "Price minimum = 1",
												},
											]}
											label="Price"
											name="price"
											inputType="number"
											placeholder="Please enter Price."
											prefix={<UserOutlined />}
										/>
									</div>
									<div className="accessoryFormTextGroupChild">
										<InputField
											rules={[
												{
													required: true,
													message: "Stock is required",
												},
												{
													type: "number",
													max: 100000,
													message: "Stock maximum=100000",
												},
											]}
											label="Stock"
											name="stock"
											inputType="number"
											placeholder="Please enter Stock."
											prefix={<UserOutlined />}
										/>
									</div>
									<div className="accessoryFormTextGroupChild">
										<InputField
											rules={[
												{
													required: true,
													message: "Discount is required",
												},
												{
													type: "number",
													min: 0,
													max: 99,
													message: "Discount maximum 100% and minimum 0%",
												},
											]}
											label="Discount ( % )"
											name="discount"
											inputType="number"
											placeholder="Please enter discount."
											prefix={<UserOutlined />}
										/>
									</div>
								</div>
								<Title level={5}>
									<span
										style={{
											color: "#f08080",
											fontWeight: "thin",
											fontSize: "16px",
										}}
									>
										*
									</span>{" "}
									Part Diagram
								</Title>
								<Upload
									getInputProps={getImageInputProps}
									getRootProps={getImageRootProps}
									type="image"
									isDragActive={isImageDragActive}
									file={file}
									url={accessoryId ? accessory.url : ""}
									setUploadMedia={setUploadMedia}
								/>
								{accessoryId ? (
									<div style={{ marginTop: "15px" }}>
										<WordCountDisplayer
											label="Update Reason"
											name="updateReason"
											placeholder="Please enter reason"
											rules={[
												{
													required: true,
												},
												{
													message: "update reason must be minimum 10 words.",
												},
											]}
										/>
									</div>
								) : null}

								<Form.Item style={{ marginTop: "20px" }}>
									<Center height="0px">
										<Space>
											<NavLink to="/products/accessory">
												<Button danger type="primary" onClick={handleCancel}>
													Cancel
												</Button>
											</NavLink>
											<Button type="primary" htmlType="submit">
												{accessoryId ? "Update" : "Add"}
											</Button>
										</Space>
									</Center>
								</Form.Item>
							</Form>
						</Card>
					</Col>
				</Row>
			</ErrorBoundary>
		</BasicLayout>
	);
};

export default AccessoryForm;

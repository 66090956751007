import { useEffect, useMemo, useState } from "react";
import { UserOutlined } from "@ant-design/icons";
import { Button, Card, Col, Form, message, Row, Space, Typography } from "antd";
import { ErrorBoundary } from "react-error-boundary";
import { useDispatch, useSelector } from "react-redux";
import { NavLink, useHistory, useLocation } from "react-router-dom";
import BasicLayout from "../../../components/BasicLayout";
import Center from "../../../components/common/Center";
import FallBack from "../../../components/common/FallBack/FallBack";
import Notification from "../../../components/common/Notification";
import InputField from "../../../components/Form/InputField";
import {
	getSingleHSNCode,
	updateHSNCode,
	addHSNCode,
} from "../../../store/actions/products/HSNCode/hsnCode.action";
import { useUpdateReason } from "../../../components/Hooks/useUpdateReason";

const HSNCodeForm = (props) => {
	const history = useHistory();
	const dispatch = useDispatch();
	const { Title } = Typography;
	const [onFinishValues, setOnFinishValues] = useState();
	const { modelToggle, UpdateModel } = useUpdateReason();
	const [form] = Form.useForm();
	const hsnCode = useSelector((state) => state?.hsnCode?.hsnCode || {});
	const hsnCodeId = +props.match.params.id;
	const currentLocation = props.location.pathname;
	const location = useLocation();
	const softdelete = new URLSearchParams(location.search).get("soft-delete");

	const handleError = (error) => {
		message.error(error.message);
	};

	useEffect(() => {
		if (hsnCodeId) {
			dispatch(getSingleHSNCode(hsnCodeId, softdelete)).catch((err) => {
				Notification("error", err, "", "topRight");
				history.push("/products/hsncode");
			});
		}
	}, [hsnCodeId, history, softdelete, dispatch]);

	const onFinish = async (values) => {
		try {
			if (currentLocation === "/products/hsncode/add") {
				await dispatch(addHSNCode({ ...values }));
				history.push("/products/hsncode");
				return;
			}
			setOnFinishValues(values);
			await modelToggle(true);
		} catch (err) {
			if (err?.message) {
				return Notification("error", "Error Occurred", err?.message, "topRight");
			}
			const errorMessage = err?.message
				? err?.message
				: (err?.response && err?.response?.data?.message) || "Soemthing went wrong";
			return Notification("error", "", errorMessage);
		}
	};

	const defaultValues = useMemo(
		() => ({
			code: hsnCodeId ? hsnCode.code : "",
			igst: hsnCodeId ? hsnCode.igst : "",
			cgst: hsnCodeId ? hsnCode.cgst : "",
			hgst: hsnCodeId ? hsnCode.hgst : "",
		}),
		[hsnCodeId, hsnCode.code, hsnCode.igst, hsnCode.cgst, hsnCode.hgst]
	);

	useEffect(() => {
		form.setFieldsValue(defaultValues);
	}, [form, defaultValues]);

	const handleCancel = () => {
		Notification("info", "Job action has been cancelled", "", "topRight");
	};

	return (
		<BasicLayout accessLevel={props.accessLevel}>
			<ErrorBoundary FallbackComponent={FallBack} onError={handleError}>
				<Row type="flex" justify="center" align="middle" id="form-container">
					<Center height="4rem">
						{hsnCodeId ? (
							<Title level={2} className="update-or-add-title">
								Update HSN Code
							</Title>
						) : (
							<Title level={2} className="update-or-add-title">
								Add New HSN Code
							</Title>
						)}
					</Center>
					<Col>
						<Card className="form-card">
							<Form
								form={form}
								name="basic"
								layout="vertical"
								onFinish={onFinish}
								initialValues={defaultValues}
							>
								<InputField
									rules={[
										{
											required: true,
										},
										{
											min: 4,
											message: "Code must be minimum 04 characters.",
										},
										{
											max: 14,
											message: "Code must be maximum 14 characters.",
										},
									]}
									label=" HSN/SAC Code"
									name="code"
									placeholder="Please enter HSN/SAC Code"
									prefix={<UserOutlined />}
								/>
								<InputField
									rules={[
										{
											required: true,
											message: "IGST is required",
										},
										{
											type: "number",
											min: 0,
											max: 99,
											message: "IGST maximum 100% and minimum 0%",
										},
									]}
									label="IGST ( % )"
									name="igst"
									inputType="number"
									placeholder="Please enter IGST."
									prefix={<UserOutlined />}
								/>
								<InputField
									rules={[
										{
											required: true,
											message: "CGST is required",
										},
										{
											type: "number",
											min: 0,
											max: 99,
											message: "CGST maximum 100% and minimum 0%",
										},
									]}
									label="CGST ( % )"
									name="cgst"
									inputType="number"
									placeholder="Please enter CGST."
									prefix={<UserOutlined />}
								/>
								<InputField
									rules={[
										{
											required: true,
											message: "SGST is required",
										},
										{
											type: "number",
											min: 0,
											max: 99,
											message: "SGST maximum 100% and minimum 0%",
										},
									]}
									label="SGST ( % )"
									name="hgst"
									inputType="number"
									placeholder="Please enter SGST."
									prefix={<UserOutlined />}
								/>
								<UpdateModel
									id={hsnCodeId}
									action={updateHSNCode}
									valuesWithoutReason={onFinishValues}
									redirectUrl="/products/hsncode"
									placeholder="Enter reason for update HSN Code"
								/>
								<Form.Item style={{ marginTop: "20px" }}>
									<Center height="0px">
										<Space>
											<NavLink to="/products/hsncode">
												<Button danger type="primary" onClick={handleCancel}>
													Cancel
												</Button>
											</NavLink>
											<Button type="primary" htmlType="submit">
												{hsnCodeId ? "Update" : "Add"}
											</Button>
										</Space>
									</Center>
								</Form.Item>
							</Form>
						</Card>
					</Col>
				</Row>
			</ErrorBoundary>
		</BasicLayout>
	);
};

export default HSNCodeForm;

import { Card, Image, message } from "antd";
import React from "react";
import { ErrorBoundary } from "react-error-boundary";
import { useDispatch, useSelector } from "react-redux";
import { NavLink, useHistory, useLocation } from "react-router-dom";
import BasicLayout from "../../../components/BasicLayout/index";
import FallBack from "../../../components/common/FallBack/FallBack";
import Notification from "../../../components/common/Notification";
import { CustomTab } from "../../../components/common/Tab/Tab";
import { getSingleGallerySubmission } from "../../../store/actions/gallery/gallery.action";
import { checkIfObjectEmpty } from "../../../utils/helper";
import "./index.css";

const ViewGallery = (props) => {
	const galleryId = +props.match.params.id;

	const dispatch = useDispatch();
	const gallerySubmission = useSelector((state) => state.gallery.gallerySubmission || {});
	const isEmpty = checkIfObjectEmpty(gallerySubmission);

	const history = useHistory();
	const location = useLocation();
	const softdelete = new URLSearchParams(location.search).get("soft-delete");

	React.useEffect(() => {
		dispatch(getSingleGallerySubmission(galleryId, softdelete)).catch((err) => {
			Notification("error", err, "", "topRight");
			history.push("/gallery/submissions");
		});
	}, [dispatch, history, softdelete, galleryId]);

	let data = [];
	let isOrderEmpty = Object.keys(gallerySubmission).length === 0;
	if (!isOrderEmpty) {
		data = [
			{ title: "Id", description: gallerySubmission.id },
			{
				title: "Name",
				description: gallerySubmission.name,
			},
			{
				title: "Experience Title",
				description: gallerySubmission.title,
			},
			{
				title: "Experience Description",
				description: gallerySubmission.description,
			},
			{
				title: "Experience Link",
				description: gallerySubmission?.link || "NA",
			},
			{ title: "State", description: gallerySubmission.state },
			{
				title: "District",
				description: gallerySubmission.district,
			},
			{
				title: "Taluka",
				description: gallerySubmission.taluka,
			},
			{
				title: "Village",
				description: gallerySubmission.village,
			},
			{
				title: "Image",
				description: (
					<div className="gallery-images">
						{!isEmpty && gallerySubmission?.imageUrls
							? gallerySubmission.imageUrls.map((image, index) => {
									return (
										<div
											key={index}
											style={{
												display: "flex",
												flexDirection: "column",
												alignContent: "center",
												justifyContent: "center",
												alignItems: "center",
											}}
										>
											<div className="image-holder-container">
												<Image width={250} height={180} src={image} />
											</div>
											<NavLink
												to={`/gallery/submissions/${galleryId}/assign?type=image&url=${image}`}
											>
												Assign
											</NavLink>
										</div>
									);
							  })
							: "NA"}
					</div>
				),
			},
			{
				title: "Video",
				description: (
					<div className="gallery-images">
						{!isEmpty && gallerySubmission?.videoUrls
							? gallerySubmission.videoUrls.map((video, index) => {
									return (
										<div
											key={index}
											style={{
												display: "flex",
												flexDirection: "column",
												alignContent: "center",
												justifyContent: "center",
												alignItems: "center",
											}}
										>
											<div className="image-holder-container">
												<video width="320" height="240" controls>
													<source src={video} type="video/mp4"></source>
												</video>
											</div>
											<NavLink
												to={`/gallery/submissions/${galleryId}/assign?type=video&url=${video}`}
											>
												Assign
											</NavLink>
										</div>
									);
							  })
							: "NA"}
					</div>
				),
			},
			{
				title: "Received",
				description: gallerySubmission.createdAt,
			},
		];
	}
	// Error Boundary Error Handling
	const handleError = (error) => {
		message.error(error.message);
	};

	let operationData = {
		deletedBy: gallerySubmission?.deletedBy,
		updatedBy: gallerySubmission?.updatedBy,
		restoredBy: gallerySubmission?.restoredBy,
	};

	return (
		<BasicLayout accessLevel={props.accessLevel}>
			<ErrorBoundary FallbackComponent={FallBack} onError={handleError}>
				<Card className="innovation-detail-card" data-testid="gallery-detail-card">
					<CustomTab
						data={data}
						operationData={operationData}
						redirectUrl={`/gallery/submissions/update/${galleryId}`}
						update={false}
					/>
				</Card>
			</ErrorBoundary>
		</BasicLayout>
	);
};

export default ViewGallery;

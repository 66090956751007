import {
	ADD_USER_SUCCESS,
	GET_SINGLE_USER_SUCCESS,
	GET_USERS_SUCCESS,
	RESTORE_SINGLE_USER_SUCCESS,
	SOFT_DELETE_SINGLE_USER_SUCCESS,
	UPDATE_USER_SUCCESS,
} from "../actions/user/user.actionType";
import globalState from "../state";

const requestRegex = /_REQUEST/;
const errorRegex = /_FAILURE/;
const successRegex = /_SUCCESS/;

const initialState = {
	errorMessage: "",
	isLoading: false,
	users: [],
	user: {},
	...globalState,
};

const UserReducer = (state = initialState, action) => {
	const isRequest = requestRegex.test(action.type);
	const isError = errorRegex.test(action.type);
	const isSuccess = successRegex.test(action.type);

	if (isRequest) {
		return { ...state, isLoading: true, errorMessage: "" };
	}
	if (isError) {
		return { ...state, isLoading: false, errorMessage: action.message };
	}
	if (isSuccess) {
		state = { ...state, isLoading: false, errorMessage: "" };
	}

	switch (action.type) {
		case GET_USERS_SUCCESS:
			return { ...state, users: action.data };

		case GET_SINGLE_USER_SUCCESS:
			return { ...state, user: action.data };

		case SOFT_DELETE_SINGLE_USER_SUCCESS:
			let softDeletedUserId = action.data;
			let afterSoftDeletedData = state.users.filter(
				(data) => data.id.toString() !== softDeletedUserId.toString()
			);
			return { ...state, users: afterSoftDeletedData };

		case ADD_USER_SUCCESS:
			return { ...state };

		case RESTORE_SINGLE_USER_SUCCESS:
			return { ...state };

		case UPDATE_USER_SUCCESS:
			return { ...state };

		default:
			return state;
	}
};

export default UserReducer;

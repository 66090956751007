import Notification from "../../../../components/common/Notification";
import * as hsnCodesApi from "../../../../services/hsnCode";
import {
	ADD_HSN_CODE_FAILURE,
	ADD_HSN_CODE_REQUEST,
	ADD_HSN_CODE_SUCCESS,
	GET_SINGLE_HSN_CODE_FAILURE,
	GET_SINGLE_HSN_CODE_REQUEST,
	GET_SINGLE_HSN_CODE_SUCCESS,
	GET_HSN_CODES_FAILURE,
	GET_HSN_CODES_REQUEST,
	GET_HSN_CODES_SUCCESS,
	RESTORE_SINGLE_HSN_CODE_FAILURE,
	RESTORE_SINGLE_HSN_CODE_REQUEST,
	RESTORE_SINGLE_HSN_CODE_SUCCESS,
	SOFT_DELETE_SINGLE_HSN_CODE_FAILURE,
	SOFT_DELETE_SINGLE_HSN_CODE_REQUEST,
	SOFT_DELETE_SINGLE_HSN_CODE_SUCCESS,
	UPDATE_HSN_CODE_FAILURE,
	UPDATE_HSN_CODE_REQUEST,
	UPDATE_HSN_CODE_SUCCESS,
} from "./hsnCode.actionType";

// GET REQUEST
export const requestGetAllHSNCodes = () => {
	return {
		type: GET_HSN_CODES_REQUEST,
	};
};
export const getAllHSNCodesSuccess = (data) => {
	return {
		type: GET_HSN_CODES_SUCCESS,
		data,
	};
};
export const getAllHSNCodesFailure = (message) => {
	return {
		type: GET_HSN_CODES_FAILURE,
		message,
	};
};

// Get All HSN Codes
export const getAllHSNCodes = () => {
	return (dispatch) => {
		dispatch(requestGetAllHSNCodes());
		return hsnCodesApi
			.getAllHSNCodes()
			.then((resp) => {
				const data = resp?.data?.data;
				dispatch(getAllHSNCodesSuccess(data));
			})
			.catch((err) => {
				const errorMessage =
					(err?.response && err?.response?.data?.message) || "Something went wrong!";
				dispatch(getAllHSNCodesFailure(errorMessage));
			});
	};
};

export const requestGetSingleHSNCode = () => {
	return {
		type: GET_SINGLE_HSN_CODE_REQUEST,
	};
};

export const getSingleHSNCodeSuccess = (data) => {
	return {
		type: GET_SINGLE_HSN_CODE_SUCCESS,
		data,
	};
};

export const getSingleHSNCodeFailure = (message) => {
	return {
		type: GET_SINGLE_HSN_CODE_FAILURE,
		message,
	};
};

export const getSingleHSNCode = (id, type) => {
	return (dispatch) => {
		dispatch(requestGetSingleHSNCode());
		return hsnCodesApi
			.getSingleHSNCode(id, type)
			.then((resp) => {
				const data = resp?.data?.data;
				dispatch(getSingleHSNCodeSuccess(data));
			})
			.catch((err) => {
				const errorMessage =
					(err?.response && err?.response?.data?.message) || "Something went wrong";
				dispatch(getSingleHSNCodeFailure(errorMessage));
				throw errorMessage;
			});
	};
};

export const requestSoftDeleteSingleHSNCode = () => {
	return {
		type: SOFT_DELETE_SINGLE_HSN_CODE_REQUEST,
	};
};

export const softDeleteSingleHSNCodeSuccess = (data) => {
	return {
		type: SOFT_DELETE_SINGLE_HSN_CODE_SUCCESS,
		data,
	};
};

export const softDeleteSingleHSNCodeFailure = (message) => {
	return {
		type: SOFT_DELETE_SINGLE_HSN_CODE_FAILURE,
		message,
	};
};

export const softDeleteSingleHSNCode = (id, reason) => {
	return (dispatch) => {
		dispatch(requestSoftDeleteSingleHSNCode());
		return hsnCodesApi
			.softDeleteSingleHSNCode(id, reason)
			.then((resp) => {
				dispatch(softDeleteSingleHSNCodeSuccess(id));
				return Notification("success", resp?.data?.message, "", "topRight");
			})
			.catch((err) => {
				const errorMessage =
					(err?.response && err?.response?.data?.message) || "Something went wrong";
				dispatch(softDeleteSingleHSNCodeFailure(errorMessage));
				throw errorMessage;
			});
	};
};

export const requestHSNCodeRestore = () => {
	return {
		type: RESTORE_SINGLE_HSN_CODE_REQUEST,
	};
};

export const restoreHSNCodeSuccess = () => {
	return {
		type: RESTORE_SINGLE_HSN_CODE_SUCCESS,
	};
};

export const restoreHSNCodeFailure = (message) => {
	return {
		type: RESTORE_SINGLE_HSN_CODE_FAILURE,
		message,
	};
};

export const restoreHSNCode = (id) => {
	return (dispatch) => {
		dispatch(requestHSNCodeRestore());
		return hsnCodesApi
			.restoreHSNCode(id)
			.then((resp) => {
				dispatch(restoreHSNCodeSuccess(id));
				Notification("success", resp?.data?.message, "", "topRight");
			})
			.catch((err) => {
				const errorMessage = (err?.response && err?.response?.data?.message) || "Something went wrong!";
				dispatch(restoreHSNCodeFailure(errorMessage));
			});
	};
};

export const requestHSNCodeAdd = () => {
	return {
		type: ADD_HSN_CODE_REQUEST,
	};
};

export const hsnCodeAddSuccess = (data) => {
	return {
		type: ADD_HSN_CODE_SUCCESS,
		data,
	};
};

export const hsnCodeAddFailure = (message) => {
	return {
		type: ADD_HSN_CODE_FAILURE,
		message,
	};
};

export const addHSNCode = (data) => {
	return (dispatch) => {
		dispatch(requestHSNCodeAdd());
		return hsnCodesApi
			.addHSNCode(data)
			.then((resp) => {
				const message = resp?.data?.message;
				dispatch(hsnCodeAddSuccess());
				Notification("success", "", message);
			})
			.catch((err) => {
				const errorMessage = err?.response && err?.response?.data?.message;
				dispatch(hsnCodeAddFailure(errorMessage));
				Notification("error", "Error Occurred", errorMessage);
			});
	};
};

//FOR UPDATE HSN Code
export const requestUpdateHSNCode = () => {
	return {
		type: UPDATE_HSN_CODE_REQUEST,
	};
};

export const updateHSNCodeSuccess = () => {
	return {
		type: UPDATE_HSN_CODE_SUCCESS,
	};
};

export const updateHSNCodeFailure = (message) => {
	return {
		type: UPDATE_HSN_CODE_FAILURE,
		message,
	};
};

export const updateHSNCode = (id, data) => {
	return (dispatch) => {
		dispatch(requestUpdateHSNCode());
		return hsnCodesApi
			.updateHSNCode(id, data)
			.then((resp) => {
				const message = resp?.data?.message;
				dispatch(updateHSNCodeSuccess());
				Notification("success", "", message);
			})
			.catch((err) => {
				const errorMessage = err?.response && err?.response?.data?.message;
				dispatch(updateHSNCodeFailure(errorMessage));
				throw err;
			});
	};
};

import { Button, Image, message, Typography } from "antd";
import BasicLayout from "../../components/BasicLayout";
import { ErrorBoundary } from "react-error-boundary";
import "./style.css";
import FallBack from "../../components/common/FallBack/FallBack";

const { Title } = Typography;

const NotFound404 = (props) => {
	// Error Boundary Error Handling
	const handleError = (error) => {
		message.error(error.message);
	};
	return (
		<BasicLayout accessLevel={props.accessLevel}>
			<ErrorBoundary FallbackComponent={FallBack} onError={handleError}>
				<div className="parent-box-of-pagenotfound" data-testid="pagenotfound">
					<Image
						width={300}
						height={400}
						src={`${window.location.origin}/assets/image/404img.png`}
					/>
					<div className="box-of-text-not-found">
						<Title>Sorry! Page Not Found</Title>
						<Title level={3}>Please Go Back!</Title>
						<Button type="link" href="/customer/enquiry" className="go-back">
							Go Back
						</Button>
					</div>
				</div>
			</ErrorBoundary>
		</BasicLayout>
	);
};

export default NotFound404;

import {
	GET_PRIMARY_RELATED_SECONDARY_CATEGORIES_SUCCESS,
	GET_PRODUCT_SECONDARY_CATEGORIES_SUCCESS,
	GET_SINGLE_PRODUCT_SECONDARY_CATEGORY_SUCCESS,
	RESTORE_SINGLE_PRODUCT_SECONDARY_CATEGORY_SUCCESS,
	SOFT_DELETE_SINGLE_PRODUCT_SECONDARY_CATEGORY_SUCCESS,
} from "../actions/products/secondaryCategory/categories.actionType";
import globalState from "../state";

const requestRegex = /_REQUEST/;
const errorRegex = /_FAILURE/;
const successRegex = /_SUCCESS/;

const initialState = {
	errorMessage: "",
	isLoading: false,
	categories: [],
	category: {},
	primaryRelatedSecondaryCategories: [],
	...globalState,
};

const CategoryReducer = (state = initialState, action) => {
	const isRequest = requestRegex.test(action.type);
	const isError = errorRegex.test(action.type);
	const isSuccess = successRegex.test(action.type);

	if (isRequest) {
		return { ...state, isLoading: true, errorMessage: "" };
	}
	if (isError) {
		return { ...state, isLoading: false, errorMessage: action.message };
	}
	if (isSuccess) {
		state = { ...state, isLoading: false, errorMessage: "" };
	}

	switch (action.type) {
		case GET_PRODUCT_SECONDARY_CATEGORIES_SUCCESS:
			return { ...state, categories: action.data };

		case GET_SINGLE_PRODUCT_SECONDARY_CATEGORY_SUCCESS:
			return { ...state, category: action.data };

		case SOFT_DELETE_SINGLE_PRODUCT_SECONDARY_CATEGORY_SUCCESS:
			let softDeletedCategoryId = action.data;
			let afterSoftDeletedData = state.categories.filter(
				(data) => data.id.toString() !== softDeletedCategoryId.toString()
			);
			return { ...state, categories: afterSoftDeletedData };

		case GET_PRIMARY_RELATED_SECONDARY_CATEGORIES_SUCCESS:
			return { ...state, primaryRelatedSecondaryCategories: action.data };

		case RESTORE_SINGLE_PRODUCT_SECONDARY_CATEGORY_SUCCESS:
			return { ...state };

		default:
			return state;
	}
};

export default CategoryReducer;

import Axios from "axios";

const v1Url = process.env.REACT_APP_V1_API_URL;

const timeoutInMilliSeconds = 300000;

export const $axios = Axios.create({
	headers: {
		"Content-Type": "application/json;charset=UTF-8",
		apiKey: process.env.REACT_APP_V1_API_KEY,
		client: "web",
	},
	timeout: timeoutInMilliSeconds,
	baseURL: v1Url,
});

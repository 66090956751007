export const accessNotAllowedMsg =
	"Access to this resource is not allowed. Please contact super admin for the allowing the access.";

export const Resources = [
	{
		id: 1,
		node: "Customer",
		key: "customer",
	},
	{
		id: 2,
		node: "Products",
		key: "products",
	},
	{
		id: 3,
		node: "Distributor",
		key: "distributor",
	},
	{
		id: 4,
		node: "Dealer",
		key: "dealer",
	},
	{
		id: 5,
		node: "Subdealer",
		key: "subDealer",
	},
	{
		id: 6,
		node: "Service Center",
		key: "serviceCenter",
	},
	{
		id: 7,
		node: "Sales Point",
		key: "salesPoint",
	},
	{
		id: 8,
		node: "Careers",
		key: "career",
	},
	{
		id: 9,
		node: "Gallery",
		key: "gallery",
	},
	{
		id: 10,
		node: "Other",
		key: "other",
	},
];

export const subResources = [
	{
		id: 1,
		value: "customerEnquiry",
		tree: "Customer",
		title: "Customer Enquiry",
	},
	{
		id: 2,
		value: "dealerEnquiry",
		tree: "Dealer",
		title: "Dealer Enquiry",
	},
	{
		id: 3,
		value: "subdealerEnquiry",
		tree: "Subdealer",
		title: "Subdealer Enquiry",
	},
	{
		id: 4,
		value: "distributorEnquiry",
		tree: "Distributor",
		title: "Distributor Enquiry",
	},
	{
		id: 5,
		value: "salespointEnquiry",
		tree: "Sales Point",
		title: "Sales Point Enquiry",
	},
	{
		id: 6,
		value: "serviceCenterEnquiry",
		tree: "Service Center",
		title: "Service Center Enquiry",
	},
	{
		id: 7,
		value: "added",
		tree: "Gallery",
		title: "Added",
	},
	{
		id: 8,
		value: "gallerySubmissions",
		tree: "Gallery",
		title: "Gallery Submissions",
	},
	{
		id: 9,
		value: "jobs",
		tree: "Careers",
		title: "Jobs",
	},
	{
		id: 10,
		value: "applications",
		tree: "Careers",
		title: "Applications",
	},
	{
		id: 11,
		value: "categories",
		tree: "Careers",
		title: "Categories",
	},
	{
		id: 12,
		value: "innovation",
		tree: "Other",
		title: "Innovation",
	},
	{
		id: 13,
		value: "newsletter",
		tree: "Other",
		title: "Newsletter",
	},
	{
		id: 14,
		value: "recycleBin",
		tree: "Other",
		title: "Recycle Bin",
	},
	{
		id: 15,
		value: "customerInfo",
		tree: "Customer",
		title: "Customer Info",
	},
	{
		id: 16,
		value: "brands",
		tree: "Products",
		title: "Brands",
	},
	{
		id: 17,
		value: "productCategories",
		tree: "Products",
		title: "Products Categories",
	},
	{
		id: 18,
		value: "productSecondaryCategories",
		tree: "Products",
		title: "Products Secondary Categories",
	},
	{
		id: 19,
		value: "productTechnicalSpecifications",
		tree: "Products",
		title: "Products Technical Specifications",
	},
	{
		id: 20,
		value: "implementesSubAssembly",
		tree: "Products",
		title: "Implementes Sub Assembly",
	},
	{
		id: 21,
		value: "implements",
		tree: "Products",
		title: "Implements",
	},
	{
		id: 22,
		value: "subAssembly",
		tree: "Products",
		title: "Sub Assembly",
	},
	{
		id: 23,
		value: "accessory",
		tree: "Products",
		title: "Accessory",
	},
	{
		id: 24,
		value: "model",
		tree: "Products",
		title: "Model",
	},
	{
		id: 25,
		value: "sparePart",
		tree: "Products",
		title: "Spare Part",
	},
	{
		id: 26,
		value: "hsnCode",
		tree: "Products",
		title: "HSN Code",
	},
];

import Notification from "../../../../components/common/Notification";
import * as modelApi from "../../../../services/model";
import {
    ADD_MODEL_FAILURE,
    ADD_MODEL_REQUEST,
    ADD_MODEL_SUCCESS,
    GET_MODELS_FAILURE,
    GET_MODELS_REQUEST,
    GET_MODELS_SUCCESS,
    GET_SINGLE_MODEL_FAILURE,
    GET_SINGLE_MODEL_REQUEST,
    GET_SINGLE_MODEL_SUCCESS,
    MAKE_SINGLE_MODEL_EMPTY,
    RESTORE_SINGLE_MODEL_FAILURE,
    RESTORE_SINGLE_MODEL_REQUEST,
    RESTORE_SINGLE_MODEL_SUCCESS,
    SOFT_DELETE_SINGLE_MODEL_FAILURE,
    SOFT_DELETE_SINGLE_MODEL_REQUEST,
    SOFT_DELETE_SINGLE_MODEL_SUCCESS,
    UPDATE_MODEL_FAILURE,
    UPDATE_MODEL_REQUEST,
    UPDATE_MODEL_SUCCESS,
} from "./model.actionType";

// GET REQUEST
export const requestGetAllModels = () => {
    return {
        type: GET_MODELS_REQUEST,
    };
};
export const getAllModelsSuccess = (data) => {
    return {
        type: GET_MODELS_SUCCESS,
        data,
    };
};
export const getAllModelsFailure = (message) => {
    return {
        type: GET_MODELS_FAILURE,
        message,
    };
};

// Get All Models
export const getAllModels = () => {
    return (dispatch) => {
        dispatch(requestGetAllModels());
        return modelApi
            .getAllModels()
            .then((resp) => {
                const data = resp.data.data;
                dispatch(getAllModelsSuccess(data));
            })
            .catch((err) => {
                const errorMessage =
                    (err?.response && err?.response?.data?.message) ||
                    "Something went wrong!";
                dispatch(getAllModelsFailure(errorMessage));
            });
    };
};

export const requestGetSingleModel = () => {
    return {
        type: GET_SINGLE_MODEL_REQUEST,
    };
};

export const getSingleModelSuccess = (data) => {
    return {
        type: GET_SINGLE_MODEL_SUCCESS,
        data,
    };
};

export const getSingleModelFailure = (message) => {
    return {
        type: GET_SINGLE_MODEL_FAILURE,
        message,
    };
};

export const getSingleModel = (id, softdelete) => {
    return (dispatch) => {
        dispatch(requestGetSingleModel());
        return modelApi
            .getSingleModel(id, softdelete)
            .then((resp) => {
                const data = resp.data.data;
                dispatch(getSingleModelSuccess(data));
            })
            .catch((err) => {
                const errorMessage =
                    (err?.response && err?.response?.data?.message) ||
                    "Something went wrong";
                dispatch(getSingleModelFailure(errorMessage));
                throw errorMessage;
            });
    };
};

export const requestSoftDeleteSingleModel = () => {
    return {
        type: SOFT_DELETE_SINGLE_MODEL_REQUEST,
    };
};

export const softDeleteSingleModelSuccess = (data) => {
    return {
        type: SOFT_DELETE_SINGLE_MODEL_SUCCESS,
        data,
    };
};

export const softDeleteSingleModelFailure = (message) => {
    return {
        type: SOFT_DELETE_SINGLE_MODEL_FAILURE,
        message,
    };
};

export const softDeleteSingleModel = (id, reason) => {
    return (dispatch) => {
        dispatch(requestSoftDeleteSingleModel());
        return modelApi
            .softDeleteSingleModel(id, reason)
            .then((resp) => {
                dispatch(softDeleteSingleModelSuccess(id));
                return Notification(
                    "success",
                    resp.data.message,
                    "",
                    "topRight"
                );
            })
            .catch((err) => {
                const errorMessage =
                    (err?.response && err?.response?.data?.message) ||
                    "Something went wrong";
                dispatch(softDeleteSingleModelFailure(errorMessage));
            });
    };
};

export const requestModelRestore = () => {
    return {
        type: RESTORE_SINGLE_MODEL_REQUEST,
    };
};

export const restoreModelSuccess = () => {
    return {
        type: RESTORE_SINGLE_MODEL_SUCCESS,
    };
};

export const restoreModelFailure = (message) => {
    return {
        type: RESTORE_SINGLE_MODEL_FAILURE,
        message,
    };
};

export const restoreModel = (id) => {
    return (dispatch) => {
        dispatch(requestModelRestore());
        return modelApi
            .restoreModel(id)
            .then((resp) => {
                dispatch(restoreModelSuccess(id));
                Notification("success", resp.data.message, "", "topRight");
            })
            .catch((err) => {
                const errorMessage =
                    (err.response && err.response.data.message) ||
                    "Something went wrong!";
                dispatch(restoreModelFailure(errorMessage));
            });
    };
};

export const requestModelAdd = () => {
    return {
        type: ADD_MODEL_REQUEST,
    };
};

export const ModelAddSuccess = () => {
    return {
        type: ADD_MODEL_SUCCESS,
    };
};

export const ModelAddFailure = (message) => {
    return {
        type: ADD_MODEL_FAILURE,
        message,
    };
};

export const addModel = (data) => {
    return (dispatch) => {
        dispatch(requestModelAdd());
        return modelApi
            .addModel(data)
            .then((resp) => {
                const data = resp.data.data;
                return data;
            })
            .catch((err) => {
                const errorMessage = err.response && err.response.data.message;
                dispatch(ModelAddFailure(errorMessage));
                Notification("error", "Error Occurred", errorMessage);
            });
    };
};

//FOR UPDATE Model
export const requestUpdateModel = () => {
    return {
        type: UPDATE_MODEL_REQUEST,
    };
};

export const updateModelSuccess = () => {
    return {
        type: UPDATE_MODEL_SUCCESS,
    };
};

export const updateModelFailure = (message) => {
    return {
        type: UPDATE_MODEL_FAILURE,
        message,
    };
};

export const updateModel = (id, data) => {
    return (dispatch) => {
        dispatch(requestUpdateModel());
        return modelApi
            .updateModel(id, data)
            .then((resp) => {
                // We will not dispatch completed action in this section becasuse of update form need some logic.
                const data = resp.data;
                return data;
            })
            .catch((err) => {
                const errorMessage = err.response && err.response.data.message;
                dispatch(updateModelFailure(errorMessage));
                throw err;
            });
    };
};

export const updateModelFromTable = (id, data) => {
    return (dispatch) => {
        dispatch(requestUpdateModel());
        return modelApi
            .updateModel(id, data)
            .then((resp) => {
                dispatch(updateModelSuccess());
                dispatch(getAllModels());
                const message = resp.data.message;
                return Notification("success", "", message);
            })
            .catch((err) => {
                const errorMessage = err.response && err.response.data.message;
                dispatch(updateModelFailure(errorMessage));
                throw err;
            });
    };
};

export const makeModelDataEmpty = () => {
    return {
        type: MAKE_SINGLE_MODEL_EMPTY,
        data: [],
    };
};

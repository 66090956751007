import { invokeApi } from "../utils/api";
import { $axios } from "../utils/axios";

export const getAllProductSecondaryCategories = () =>
	invokeApi($axios, "/category/secondary", {}, "GET")
		.then((resp) => resp)
		.catch((err) => {
			throw err;
		});

export const getSingleProductSecondaryCategory = (categoryId, softdelete) =>
	invokeApi(
		$axios,
		`/category/secondary/${softdelete === "true" ? "soft-delete/" : ""}${categoryId}`,
		{},
		"GET"
	)
		.then((resp) => resp)
		.catch((err) => {
			throw err;
		});

export const softDeleteSingleProductSecondaryCategory = (categoryId, reason) =>
	invokeApi($axios, `/category/secondary/soft-delete/${categoryId}?reason=${reason}`, {}, "DELETE")
		.then((resp) => resp)
		.catch((err) => {
			throw err;
		});

export const restoreProductSecondaryCategory = (categoryId) =>
	invokeApi($axios, `/category/secondary/restore/${categoryId}`, {}, "PUT")
		.then((resp) => resp)
		.catch((err) => {
			throw err;
		});

export const addProductSecondaryCategory = (data) =>
	invokeApi($axios, "/category/secondary", data, "POST")
		.then((resp) => resp)
		.catch((err) => {
			throw err;
		});

export const updateProductSecondaryCategory = (id, data) =>
	invokeApi($axios, `/category/secondary/${id}`, data, "PUT")
		.then((resp) => resp)
		.catch((err) => {
			throw err;
		});

export const getPrimaryRelatedSecondaryCategories = (primaryCategoryId) =>
	invokeApi($axios, `/category/secondary/filter/${primaryCategoryId}`, {}, "GET")
		.then((resp) => resp)
		.catch((err) => {
			throw err;
		});

import { Card, Image, message } from "antd";
import { useEffect, useMemo } from "react";
import { ErrorBoundary } from "react-error-boundary";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import BasicLayout from "../../../components/BasicLayout";
import FallBack from "../../../components/common/FallBack/FallBack";
import Notification from "../../../components/common/Notification";
import { CustomTab } from "../../../components/common/Tab/Tab";
import { getSingleSparePart } from "../../../store/actions/products/spareparts/spareparts.action";

const ViewSparePart = (props) => {
	const sparePartId = +props.match.params.id;
	const dispatch = useDispatch();
	const sparepart = useSelector((state) => state.sparepart.sparepart || {});
	const history = useHistory();

	const admin = useSelector((state) => state.admin.admin || []);

	const permissionArr = useMemo(() => {
		if (admin && admin.template) {
			const { permissions } = admin.template;
			return permissions;
		} else if (admin && admin.accessLevel === "superAdmin") {
			return "all";
		}
		return [];
	}, [admin]);

	const location = useLocation();
	const softdelete = new URLSearchParams(location.search).get("soft-delete");

	useEffect(() => {
		dispatch(getSingleSparePart(sparePartId, softdelete)).catch((err) => {
			Notification("error", err, "", "topRight");
			history.push("/products/sparepart");
		});
	}, [dispatch, history, softdelete, sparePartId]);

	let data = [];
	let isSparePartEmpty = Object.keys(sparepart).length === 0;
	if (!isSparePartEmpty) {
		data = [
			{ title: "Id", description: sparepart.id },
			{
				title: "Name",
				description: sparepart.name ? sparepart.name : "NA",
			},
			{
				title: "Spare Part Number",
				description: sparepart.sparePartNumber ? sparepart.sparePartNumber : "NA",
			},
			{
				title: "Price",
				description: sparepart.price ? sparepart.price : "NA",
			},
			{
				title: "Stock",
				description: sparepart.stock ? sparepart.stock : "NA",
			},
			{
				title: "Discount",
				description: sparepart.discount ? `${sparepart.discount}%` : "NA",
			},
			{
				title: "HSN/SAC Code",
				description: sparepart?.hsnCode ? sparepart?.hsnCode?.code : "NA",
			},
			{
				title: "Image",
				description: sparepart.imageUrl ? (
					<div className="gallery-images">
						<div className="image-holder-container">
							<Image width={250} height={180} src={sparepart?.imageUrl} />
						</div>
					</div>
				) : (
					"NA"
				),
			},
			{
				title: "Created At",
				description: new Date(sparepart.createdAt).toLocaleString(),
			},
		];
	}

	const handleError = (error) => {
		message.error(error.message);
	};
	let operationData = {
		deletedBy: sparepart?.deletedBy,
		updatedBy: sparepart?.updatedBy,
		restoredBy: sparepart?.restoredBy,
	};
	return (
		<BasicLayout accessLevel={props.accessLevel}>
			<ErrorBoundary FallbackComponent={FallBack} onError={handleError}>
				<Card
					className="enqiury-detail-card"
					data-testid="enqiury-detail-card"
					id="view-sparepart-box"
				>
					<CustomTab
						update={
							permissionArr === "all"
								? "true"
								: permissionArr?.products?.sparePart.filter((item) => item === "update")?.length > 0
								? "true"
								: false
						}
						data={data}
						operationData={operationData}
						redirectUrl={`/products/sparepart/update/${sparePartId}`}
					/>
				</Card>
			</ErrorBoundary>
		</BasicLayout>
	);
};

export default ViewSparePart;

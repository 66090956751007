import {
	GET_DEALER_CONTACTS_SUCCESS,
	GET_DEALER_SINGLE_CONTACT_SUCCESS,
	SOFTDELETE_DEALER_SINGLE_CONTACT_SUCCESS,
} from "../actions/dealer/dealer.actionType";
import globalState from "../state";

const requestRegex = /_REQUEST/;
const errorRegex = /_FAILURE/;
const successRegex = /_SUCCESS/;

const initialState = {
	errorMessage: "",
	isLoading: false,
	enquiries: [],
	enquiry: {},
	...globalState,
};

const DealerReducer = (state = initialState, action) => {
	const isRequest = requestRegex.test(action.type);
	const isError = errorRegex.test(action.type);
	const isSuccess = successRegex.test(action.type);

	if (isRequest) {
		return { ...state, isLoading: true, errorMessage: "" };
	}
	if (isError) {
		return { ...state, isLoading: false, errorMessage: action.message };
	}
	if (isSuccess) {
		state = { ...state, isLoading: false, errorMessage: "" };
	}

	switch (action.type) {
		case GET_DEALER_CONTACTS_SUCCESS:
			return {
				...state,
				enquiries: action.data,
			};

		case GET_DEALER_SINGLE_CONTACT_SUCCESS:
			return {
				...state,
				enquiry: action.data,
			};

		case SOFTDELETE_DEALER_SINGLE_CONTACT_SUCCESS:
			const enquiriesDeletionId = action.data;
			const nonDeletedenquiries = state.enquiries.filter(
				(enquiries) => enquiries.id !== enquiriesDeletionId
			);
			return {
				...state,
				enquiries: nonDeletedenquiries,
			};

		default:
			return state;
	}
};

export default DealerReducer;

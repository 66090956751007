import { invokeApi } from "../utils/api";
import { $axios } from "../utils/axios";

export const getAllSubAssemblies = () =>
	invokeApi($axios, "/sub-assembly", {}, "GET")
		.then((resp) => resp)
		.catch((err) => {
			throw err;
		});

export const getSingleSubAssembly = (subassemblyId, softdelete) =>
	invokeApi(
		$axios,
		`/sub-assembly/${softdelete === "true" ? "soft-delete/" : ""}${subassemblyId}`,
		{},
		"GET"
	)
		.then((resp) => resp)
		.catch((err) => {
			throw err;
		});

export const softDeleteSingleSubAssembly = (subassemblyId, reason) =>
	invokeApi($axios, `/sub-assembly/soft-delete/${subassemblyId}?reason=${reason}`, {}, "DELETE")
		.then((resp) => resp)
		.catch((err) => {
			throw err;
		});

export const restoreSubAssembly = (subassemblyId) =>
	invokeApi($axios, `/sub-assembly/restore/${subassemblyId}`, {}, "PUT")
		.then((resp) => resp)
		.catch((err) => {
			throw err;
		});

export const addSubAssembly = (data) =>
	invokeApi($axios, "/sub-assembly", data, "POST")
		.then((resp) => resp)
		.catch((err) => {
			throw err;
		});

export const updateSubAssembly = (id, data) =>
	invokeApi($axios, `/sub-assembly/${id}`, data, "PUT")
		.then((resp) => resp)
		.catch((err) => {
			throw err;
		});

export const getModelRelatedSubAssemblies = (modelId) =>
	invokeApi($axios, `/sub-assembly/model-related-sub-assembly/${modelId}`, {}, "GET")
		.then((resp) => resp)
		.catch((err) => {
			throw err;
		});

import { Card, message } from "antd";
import { useEffect } from "react";
import { ErrorBoundary } from "react-error-boundary";
import { useDispatch, useSelector } from "react-redux";
import { NavLink, useHistory, useLocation } from "react-router-dom";
import BasicLayout from "../../../components/BasicLayout/index";
import FallBack from "../../../components/common/FallBack/FallBack";
import Notification from "../../../components/common/Notification";
import { CustomTab } from "../../../components/common/Tab/Tab";
import { getSingleApplication } from "../../../store/actions/Careers/careers.action";
import { convertEpochToDate } from "../../../utils/helper";
import "./index.css";

const ViewApplication = (props) => {
	const applicationId = +props.match.params.id;

	const dispatch = useDispatch();
	const application = useSelector((state) => state.careers.application || {});

	const location = useLocation();
	const softdelete = new URLSearchParams(location.search).get("soft-delete");
	const history = useHistory();

	useEffect(() => {
		dispatch(getSingleApplication(applicationId, softdelete)).catch((err) => {
			Notification("error", err, "", "topRight");
			history.push("/careers/applications");
		});
	}, [dispatch, history, softdelete, applicationId]);

	let data = [];
	let isOrderEmpty = Object.keys(application).length === 0;
	if (!isOrderEmpty) {
		data = [
			{ title: "Id", description: application.id },

			{
				title: "Name",
				description: application.name,
			},
			{
				title: "Email",
				description: application.email,
			},
			{
				title: "State",
				description: application.state,
			},
			{
				title: "District",
				description: application.district,
			},
			{
				title: "Taluka",
				description: application.taluka,
			},
			{
				title: "Applied For",
				description: (
					<NavLink to={`/careers/jobs/${application?.job?.id}`}>
						{application?.job?.position}
					</NavLink>
				),
			},
			{
				title: "Available After",
				description: convertEpochToDate(application.availableAfter),
			},
			{
				title: "Resume",
				description: (
					<a href={application.resumeUrl} target="_blank" rel="noreferrer">
						View
					</a>
				),
			},
			{
				title: "LinkedIn Url",
				description: (
					<a href={application.linkedInUrl} target="_blank" rel="noreferrer">
						View
					</a>
				),
			},
			{
				title: "Received",
				description: application.createdAt,
			},
		];
	}
	
	const handleError = (error) => {
		message.error(error.message);
	};

	let operationData = {
		deletedBy: application?.deletedBy,
		restoredBy: application?.restoredBy,
	};

	return (
		<BasicLayout accessLevel={props.accessLevel}>
			<ErrorBoundary FallbackComponent={FallBack} onError={handleError}>
				<Card className="job-detail-card" data-testid="job-detail-card">
					<CustomTab data={data} operationData={operationData} redirectUrl={``} update={false} />
				</Card>
			</ErrorBoundary>
		</BasicLayout>
	);
};

export default ViewApplication;

import React, { useState } from "react";
import { wordCounterRealTime } from "../../utils/wordCount";
import TextArea from "../Form/TextArea/index";
// import Notification from "./Notification";

export function WordCountDisplayer({ placeholder, label, name, rules }) {
    const [word, setWord] = useState();

    return (
        <div style={{ minHeight: "120px", marginTop: "20px" }}>
            <TextArea
                rules={rules}
                label={label}
                name={name}
                isPasteActive={true}
                handleOnChange={(e) => setWord(e.target.value)}
                placeholder={placeholder}
                extra={`Character count: ${wordCounterRealTime(word)} out of 10`}
            />
        </div>
    );
}

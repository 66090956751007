import { Button, Card, Col, Form, message, Row, Space, Typography, Radio } from "antd";
import { formatFileName } from "../../utils/helper";
import axios from "axios";

import { useCallback, useEffect, useState, useMemo } from "react";
import { useDropzone } from "react-dropzone";
import { ErrorBoundary } from "react-error-boundary";
import { NavLink, useHistory, useLocation } from "react-router-dom";
import BasicLayout from "../../components/BasicLayout";
import Center from "../../components/common/Center";
import FallBack from "../../components/common/FallBack/FallBack";
import Notification from "../../components/common/Notification";

import { useIndianStates } from "../../components/Hooks/useIndianStates";
import { useIndianMultipleDistricts } from "../../components/Hooks/useIndianMultipleDistricts";
import { useIndianMultipleSubDistricts } from "../../components/Hooks/useMultipleIndianSubDistrict";

import { useDispatch, useSelector } from "react-redux";
import { getAllTemplates } from "../../store/actions/template/template.action";
import { getSingleUser, updateUser, updateUserFailure } from "../../store/actions/user/user.action";
import { wordCount } from "../../utils/wordCount";
import { imageSizeDependancy } from "../../utils/imageSize";
import "./User.css";
import Spinner from "../../components/common/Spinner";
import { EmployeeForm } from "./Forms/employeeForm";
import { OtherCategoryForm } from "./Forms/otherCategoryForm";

const UserUpdateForm = (props) => {
	const { Title } = Typography;
	const [form] = Form.useForm();
	const dispatch = useDispatch();
	const location = useLocation();

	const softdelete = new URLSearchParams(location.search).get("soft-delete");

	const history = useHistory();

	const userId = +props.match.params.id;
	const currentLocation = props.location.pathname;

	const [districtList, setDistrictList] = useState([]);
	const [subDistrictList, setSubDistrictList] = useState([]);
	const admin = useSelector((state) => state.admin.admin || {});

	const [selectedStateIds, setSelectedStateIds] = useState([1]);
	const [stateDefaultValueChanged, setStateDefaultValueChanged] = useState(false);
	const [selectedDistrictIds, setSelectedDistrictIds] = useState([1]);
	// eslint-disable-next-line
	const [selectedSubDistrictIds, setSelectedSubDistrictIds] = useState([1]);
	const [uploadMedia, setUploadMedia] = useState("");

	const [isTemplateLoading, setIsTemplateLoading] = useState(false);

	const { getIndianStates } = useIndianStates();
	const { getIndianMultipleDistricts } = useIndianMultipleDistricts();
	const { getIndianMultipleSubDistricts } = useIndianMultipleSubDistricts();

	const templates = useSelector((state) => state.template.templates || []);
	const user = useSelector((state) => state?.user?.user || {});

	const onChangeState = (value) => {
		const arrOfNum = value.map((str) => {
			return Number(str);
		});
		const data = {
			stateIds: arrOfNum,
		};
		setStateDefaultValueChanged(true);
		form.setFieldsValue({
			district: undefined,
			subDistrict: undefined,
		});
		setSelectedStateIds(data);
		setSelectedDistrictIds("");
		setSelectedSubDistrictIds("");
	};

	useEffect(() => {
		const tempState = getIndianStates().filter((state) => {
			if (user?.state?.includes(state["State Name(In English)"])) {
				return state["State Code"];
			} else {
				return false;
			}
		});
		const finalStateIds = tempState.map((item) => item["State Code"]);

		setSelectedStateIds({
			stateIds: finalStateIds,
		});

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [user]);

	useEffect(() => {
		const tempDistrict = districtList?.filter((district) => {
			if (user?.district?.includes(district["District Name(In English)"])) {
				return true;
			} else {
				return false;
			}
		});
		const finalDistrictIds = tempDistrict.map((item) => item["District Code"]);
		setSelectedDistrictIds(finalDistrictIds);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [user, districtList]);

	useEffect(() => {
		if (selectedStateIds) {
			getIndianMultipleDistricts(selectedStateIds)
				.then((resp) => {
					if (JSON.stringify(resp) !== JSON.stringify(districtList)) {
						setDistrictList(resp);
					}
				})
				.catch((err) => {
					return "wrong";
				});
		}
		if (selectedDistrictIds) {
			const data = {
				districtIds: selectedDistrictIds,
			};
			getIndianMultipleSubDistricts(data)
				.then((resp) => {
					setSubDistrictList(resp);
				})
				.catch((err) => {
					return "wrong";
				});
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [selectedStateIds, selectedDistrictIds]);

	useEffect(() => {
		async function fetchData() {
			setIsTemplateLoading(true);
			await dispatch(getAllTemplates());
			setIsTemplateLoading(false);
		}
		fetchData();
	}, [dispatch]);

	const onChangeDistrict = (value) => {
		setSelectedDistrictIds(value);
	};
	const onChangeSubDistrict = (value) => setSelectedSubDistrictIds(value);

	// eslint-disable-next-line react-hooks/exhaustive-deps
	const status = useMemo(() => [
		{
			name: "Private Limited",
		},
		{
			name: "Public Limited",
		},
		{
			name: "Propertiery",
		},
		{
			name: "Partnership",
		},
		{
			name: "Partnership LLC",
		},
	]);

	const [categoryValue, setCategoryValue] = useState();
	const [value, setValue] = useState();
	const [subUserValue, setSubUserValue] = useState();

	const [ruleIsRequired, setRuleIsRequired] = useState(true);

	useEffect(() => {
		if (categoryValue === 5 || user.userCategory === "employee") {
			setCategoryValue("employee");
			setRuleIsRequired(false);
		} else {
			setRuleIsRequired(true);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [ruleIsRequired, categoryValue, user?.userCategory]);

	const [gstTypeValue, setgstTypeValue] = useState();

	const onGSTTypeRadioChange = (e) => {
		setgstTypeValue(e.target.value);
	};

	const [companyStatusValue, setCompanyStatusValue] = useState(undefined);

	const [cinNumberIsRequired, setCNINumberIsRequired] = useState(false);

	useEffect(() => {
		if (
			companyStatusValue === "Private Limited" ||
			companyStatusValue === "Public Limited" ||
			user.companyStatus === "Public Limited" ||
			user.companyStatus === " Private Limited"
		) {
			setCNINumberIsRequired(true);
		} else {
			setCNINumberIsRequired(false);
		}
	}, [user.companyStatus, companyStatusValue]);

	useEffect(() => {
		setCompanyStatusValue(user.companyStatus);
	}, [user.companyStatus]);

	const statusOptions2 = useMemo(
		() =>
			status.map((access) => ({
				key: `${access.name}`,
				value: access.name,
				title: access.name,
			})),
		[status]
	);

	const onRadioChange = (e) => {
		setValue(e.target.value);
	};
	const onUserCategoryChange = (e) => {
		setCategoryValue(e.target.value);
	};

	const onSubUsersRadioChange = (e) => {
		setSubUserValue(e.target.value);
	};

	const handleError = (error) => {
		message.error(error.message);
	};

	useEffect(() => {
		if (currentLocation !== "/usermanagement/user/add") {
			setValue(user?.gstNumber ? 3 : 4);
			setSubUserValue(user?.allowToAddSubusers ? 1 : 2);
		} else {
			setValue();
			setSubUserValue();
		}
	}, [user?.gstNumber, user?.allowToAddSubusers, currentLocation]);

	useEffect(() => {
		if (userId) {
			dispatch(getSingleUser(userId, softdelete)).catch((err) => {
				Notification("error", err, "", "topRight");
				history.push("/usermanagement/user");
			});
		}
	}, [userId, history, softdelete, dispatch]);

	const handleCancel = () => {
		Notification("info", "Job action has been cancelled", "", "topRight");
	};

	const [file, setFile] = useState([]);

	const onImageDrop = useCallback((acceptedFiles, fileRejections) => {
		try {
			setFile(acceptedFiles || []);
			imageSizeDependancy(fileRejections);
		} catch (_err) {}
	}, []);

	const {
		getRootProps: getImageRootProps,
		getInputProps: getImageInputProps,
		isDragActive: isImageDragActive,
	} = useDropzone({
		onDrop: onImageDrop,
		accept: "image/png",
		multiple: false,
		maxSize: "1000000",
	});

	const [identityDocFile, setidentityDocFile] = useState([]);

	const onidentityDocDrop = useCallback((acceptedFiles) => {
		try {
			setidentityDocFile(acceptedFiles);
		} catch (_err) {}
	}, []);

	const {
		getRootProps: getidentityDocRootProps,
		getInputProps: getidentityDocInputProps,
		isDragActive: isidentityDocDragActive,
	} = useDropzone({
		onDrop: onidentityDocDrop,
		accept: ["image/png", ".pdf"],
		multiple: false,
		maxSize: "1000000",
	});

	const [gstCertificateFile, setGstCertificateFile] = useState(undefined);

	const onGstCertificateDrop = useCallback((acceptedFiles) => {
		try {
			setGstCertificateFile(acceptedFiles);
		} catch (_err) {}
	}, []);

	const {
		getRootProps: getGstCertificateRootProps,
		getInputProps: getGstCertificateInputProps,
		isDragActive: isGstCertificateDragActive,
	} = useDropzone({
		onDrop: onGstCertificateDrop,
		accept: ".pdf",
		multiple: false,
		maxSize: "5000000",
	});

	const [registrationFile, setRegistrationFile] = useState(undefined);

	const onRegistrationPdfDrop = useCallback((acceptedFiles) => {
		try {
			setRegistrationFile(acceptedFiles);
		} catch (_err) {}
	}, []);

	const {
		getRootProps: getRegistrationPdfRootProps,
		getInputProps: getRegistrationPdfInputProps,
		isDragActive: isRegistrationPdfDragActive,
	} = useDropzone({
		onDrop: onRegistrationPdfDrop,
		accept: ".pdf",
		multiple: false,
		maxSize: "5000000",
	});

	const TemplateChildren = useMemo(
		() =>
			templates.map((template) => ({
				key: `${template.name} `,
				value: template.id,
				title: template.name,
			})),
		[templates]
	);

	const onFinish = async (values) => {
		try {
			let companyLogoFile = file;

			let tempgst = values?.gstNumber;
			if (tempgst) {
				tempgst = tempgst?.replace(/\s/g, "");
				if (tempgst.length !== 13) {
					Notification("error", "Remove Space Between GST", "", "topRight");
					return;
				}
			}
			if (value === 4) {
				values = { ...values, gstNumber: null };
			} else {
				values = { ...values, gstNumber: tempgst };
			}

			if (value === 4) {
				values = { ...values, gstType: null };
			} else {
				values = { ...values, gstType: values?.gstType };
			}

			if (subUserValue === 2) {
				values = { ...values, allowToAddSubusers: null };
			} else {
				values = {
					...values,
					allowToAddSubusers: values?.allowToAddSubusers,
				};
			}

			if (values.companyStatus === "Private Limited" || values.companyStatus === "Public Limited") {
			} else if (
				values.companyStatus !== "Private Limited" ||
				values.companyStatus !== "Public Limited"
			) {
				delete values.companyCINNumber;
			}

			values = {
				...values,
				officePincode: values.officePincode.toString(),
			};

			const state = getIndianStates().filter((data) => values?.state?.includes(data["State Code"]));
			const stateName = state.map((item) => item["State Name(In English)"]);

			const districtArr = districtList.filter((data) =>
				values?.district?.includes(data["District Code"])
			);

			const districtName = districtArr.map((item) => item["District Name(In English)"]);

			const subDistrictArr = subDistrictList?.filter((data) => {
				if (
					values?.subDistrict?.includes(data["Sub District Code"]) ||
					values?.subDistrict?.includes(data["Sub District Name"])
				) {
					return true;
				}
				return false;
			});

			const subDistrictName = subDistrictArr.map((item) => item["Sub District Name"]);

			values = {
				...values,
				state: stateName,
				district: districtName,
				subDistrict: subDistrictName,
			};

			await wordCount(values.updateReason, 10);

			if (
				companyLogoFile?.length === 0 &&
				identityDocFile?.length === 0 &&
				gstCertificateFile?.length === 0 &&
				registrationFile?.length === 0
			) {
				try {
					if (values?.userCategory === "employee") {
						delete values?.ownerName;
						delete values?.companyStatus;
						delete values?.companyCINNumber;
						delete values?.companyLogoFileName;
						delete values?.companyLogoFileType;
						delete values?.companyRegisterPdfFileName;
						delete values?.companyRegisterPdfFileType;
					}
					const resp = await dispatch(updateUser(userId, { ...values }));
					const message = resp.message || "User Updated successfully";
					history.push("/usermanagement/user");
					Notification("success", message, "", "topRight");
				} catch (err) {
					const errorMessage =
						(err?.response && err?.response?.data?.message) || "Some thing went wrong";
					dispatch(updateUserFailure(errorMessage));
					Notification("error", "Error Occurred", errorMessage, "topRight");
				} finally {
					setFile([]);
					setidentityDocFile([]);
					setGstCertificateFile([]);
					setRegistrationFile([]);
				}
			} else {
				if (companyLogoFile && companyLogoFile?.length > 0) {
					const companyLogoFileName =
						companyLogoFile?.length > 0 &&
						formatFileName(
							companyLogoFile[0].name.split(".")[0],
							companyLogoFile[0].name.split(".").pop(),
							admin
						);
					const companyLogoFileType = companyLogoFile?.length > 0 && companyLogoFile[0].type;
					values = {
						...values,
						companyLogoFileName,
						companyLogoFileType,
					};
				}

				if (identityDocFile && identityDocFile?.length > 0) {
					const identityDocFileName = formatFileName(
						identityDocFile[0].name.split(".")[0],
						identityDocFile[0].name.split(".").pop(),
						admin
					);

					const identityDocFileType = identityDocFile[0].type;

					values = {
						...values,
						identityDocFileName,
						identityDocFileType,
					};
				}

				if (gstCertificateFile && gstCertificateFile?.length > 0) {
					const gstCertificatePdfFileName =
						gstCertificateFile &&
						formatFileName(
							gstCertificateFile[0].name.split(".")[0],
							gstCertificateFile[0].name.split(".").pop(),
							admin
						);

					const gstCertificatePdfFileType = gstCertificateFile && gstCertificateFile[0].type;
					values = {
						...values,
						gstCertificatePdfFileName,
						gstCertificatePdfFileType,
					};
				}

				if (registrationFile && registrationFile?.length > 0) {
					const companyRegisterPdfFileName =
						registrationFile &&
						formatFileName(
							registrationFile[0].name.split(".")[0],
							registrationFile[0].name.split(".").pop(),
							admin
						);

					const companyRegisterPdfFileType = registrationFile && registrationFile[0].type;

					values = {
						...values,
						companyRegisterPdfFileName,
						companyRegisterPdfFileType,
					};
				}
				if (values?.userCategory === "employee") {
					delete values?.companyStatus;
					delete values?.ownerName;
					delete values?.companyCINNumber;
					delete values?.companyLogoFileName;
					delete values?.companyLogoFileType;
					delete values?.companyRegisterPdfFileName;
					delete values?.companyRegisterPdfFileType;
				}
				const resp = await dispatch(updateUser(userId, { ...values }));

				if (resp && resp?.companyLogoSignedUrl) {
					const optionsForCompanyLogo = {
						headers: {
							"Content-Type": uploadMedia[0].type,
						},
					};
					await axios.put(resp?.companyLogoSignedUrl, uploadMedia[0], optionsForCompanyLogo);
				}

				if (resp && resp?.identityDocSignedUrl) {
					const optionsForidentityDoc = {
						headers: {
							"Content-Type": identityDocFile[0].type,
						},
					};
					await axios.put(resp?.identityDocSignedUrl, identityDocFile[0], optionsForidentityDoc);
				}

				if (resp && resp?.gstCertificateSignedUrl) {
					const optionsForGSTCertificatePdf = {
						headers: {
							"Content-Type": gstCertificateFile[0].type,
						},
					};
					await axios.put(
						resp?.gstCertificateSignedUrl,
						gstCertificateFile[0],
						optionsForGSTCertificatePdf
					);
				}

				if (resp && resp?.companyRegisterSignedUrl) {
					const optionsForCompanyRegisterPdf = {
						headers: {
							"Content-Type": registrationFile[0].type,
						},
					};
					await axios.put(
						resp?.companyRegisterSignedUrl,
						registrationFile[0],
						optionsForCompanyRegisterPdf
					);
				}

				history.push("/usermanagement/user");
				Notification("success", "User has been succesfully updated.", "", "topRight");
			}
		} catch (error) {
			if (error.message) {
				return Notification("error", "Error Occurred", error.message, "topRight");
			}
			const errorMessage = error.message || "Something went wrong";
			Notification("error", "", errorMessage, "topRight");
		}
	};

	const finalStateIds = useMemo(() => {
		const tempState = getIndianStates().filter((state) => {
			if (user?.state?.includes(state["State Name(In English)"])) {
				return state["State Code"];
			} else {
				return false;
			}
		});
		const tempFinalStateIds = tempState.map((item) => item["State Code"]);
		return tempFinalStateIds;

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [user]);

	const finalDistrctIds = useMemo(() => {
		if (selectedDistrictIds) {
			const tempDistrict = districtList?.filter((district) => {
				if (user?.district?.includes(district["District Name(In English)"])) {
					return district["District Code"];
				} else {
					return false;
				}
			});
			const tempFinalDistrictIds = tempDistrict.map((item) => item["District Code"]);
			return tempFinalDistrictIds;
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [user, districtList]);

	const defaultValues = useMemo(
		() => ({
			userCategory: userId ? user.userCategory : "",
			userName: userId ? user.userName : "",
			description: userId ? user.description : "",
			allowToAddSubusers: userId ? user.allowToAddSubusers : "",
			companyName: userId ? user.companyName : "",
			ownerName: userId ? user.ownerName : "",
			companyStatus: userId ? user.companyStatus : "",
			state: userId ? finalStateIds : undefined,
			district: userId ? finalDistrctIds : "",
			subDistrict: userId ? user.subDistrict : "",
			village: userId ? user.village : "",
			officeAddress: userId ? user.officeAddress : "",
			godownAddress: userId ? user.godownAddress : "",
			gstNumber: userId ? user.gstNumber : "",
			officePincode: userId ? user.officePincode : "",
			tdsNumber: userId ? user.tdsNumber : "",
			phoneNumber: userId ? user.phoneNumber : "",
			email: userId ? user.email : "",
			password: userId ? "" : "",
			templateId: userId ? user.template?.id : "",
			gstType: userId ? user.gstType : "",
			companyCINNumber: userId ? user.companyCINNumber : "",
			companyPANCardNumber: userId ? user.companyPANCardNumber : "",
		}),
		[
			userId,
			user.userCategory,
			user.userName,
			user.description,
			user.allowToAddSubusers,
			user.companyName,
			user.ownerName,
			user.companyStatus,
			finalStateIds,
			finalDistrctIds,
			user.subDistrict,
			user.village,
			user.officeAddress,
			user.godownAddress,
			user.gstNumber,
			user.officePincode,
			user.tdsNumber,
			user.phoneNumber,
			user.email,
			user.template?.id,
			user.gstType,
			user.companyCINNumber,
			user.companyPANCardNumber,
		]
	);

	useEffect(() => {
		if (!stateDefaultValueChanged) {
			form.setFieldsValue(defaultValues);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [form, defaultValues]);

	const validate = () => {
		form
			.validateFields()
			.then(() => {})
			.catch((errorInfo) => {
				const message = errorInfo?.errorFields;
				const errorArray = message.filter((field) => field.errors.length > 0);
				const errr = errorArray.map((field) => field.errors);
				for (let i = 0; i < errr.length; i++)
					if (errr.length > 0) {
						Notification("error", errr[i], "", "topRight");
					}
			});
	};

	if (isTemplateLoading) {
		return (
			<Center>
				<Spinner />
			</Center>
		);
	}

	return (
		<BasicLayout accessLevel={props.accessLevel}>
			<ErrorBoundary FallbackComponent={FallBack} onError={handleError}>
				<Row type="flex" justify="center" align="middle" id="form-container">
					<Center height="4rem">
						<Title level={2} className="update-or-add-title">
							Update User
						</Title>
					</Center>
					<Col>
						<Card className="form-card">
							<Form
								form={form}
								scrollToFirstError
								name="basic"
								layout="vertical"
								onFinish={onFinish}
								initialValues={defaultValues}
							>
								<Form.Item
									rules={[
										{
											required: true,
										},
									]}
									label="Select User Category"
									name="userCategory"
								>
									<Radio.Group
										defaultValue={null}
										onChange={onUserCategoryChange}
										value={categoryValue}
									>
										<Radio value={"employee"}>Employee</Radio>
										<Radio value={"distributor"}>Distributor</Radio>
										<Radio value={"dealer"}>Dealer</Radio>
										<Radio value={"subDealer"}>Sub Dealer</Radio>
									</Radio.Group>
								</Form.Item>

								{categoryValue === "employee" && (
									<EmployeeForm
										ruleIsRequired={ruleIsRequired}
										onChangeState={onChangeState}
										getIndianStates={() => getIndianStates()}
										selectedStateIds={selectedStateIds}
										districtList={districtList}
										onChangeDistrict={onChangeDistrict}
										onChangeSubDistrict={onChangeSubDistrict}
										selectedDistrictIds={selectedDistrictIds}
										subDistrictList={subDistrictList}
										TemplateChildren={TemplateChildren}
										userId={userId}
										user={user}
										getidentityDocInputProps={getidentityDocInputProps}
										getidentityDocRootProps={getidentityDocRootProps}
										isidentityDocDragActive={isidentityDocDragActive}
										identityDocFile={identityDocFile}
									/>
								)}
								{categoryValue !== "employee" && (
									<OtherCategoryForm
										ruleIsRequired={ruleIsRequired}
										onChangeState={onChangeState}
										getIndianStates={() => getIndianStates()}
										selectedStateIds={selectedStateIds}
										districtList={districtList}
										onChangeDistrict={onChangeDistrict}
										onChangeSubDistrict={onChangeSubDistrict}
										selectedDistrictIds={selectedDistrictIds}
										subDistrictList={subDistrictList}
										TemplateChildren={TemplateChildren}
										userId={userId}
										user={user}
										getidentityDocInputProps={getidentityDocInputProps}
										getidentityDocRootProps={getidentityDocRootProps}
										isidentityDocDragActive={isidentityDocDragActive}
										identityDocFile={identityDocFile}
										onSubUsersRadioChange={onSubUsersRadioChange}
										subUserValue={subUserValue}
										setCompanyStatusValue={setCompanyStatusValue}
										statusOptions2={statusOptions2}
										companyStatusValue={companyStatusValue}
										cinNumberIsRequired={cinNumberIsRequired}
										onRadioChange={onRadioChange}
										value={value}
										onGSTTypeRadioChange={onGSTTypeRadioChange}
										gstTypeValue={gstTypeValue}
										setUploadMedia={setUploadMedia}
										file={file}
										isImageDragActive={isImageDragActive}
										getImageInputProps={getImageInputProps}
										getImageRootProps={getImageRootProps}
										getRegistrationPdfInputProps={getRegistrationPdfInputProps}
										getRegistrationPdfRootProps={getRegistrationPdfRootProps}
										isRegistrationPdfDragActive={isRegistrationPdfDragActive}
										registrationFile={registrationFile}
										getGstCertificateInputProps={getGstCertificateInputProps}
										getGstCertificateRootProps={getGstCertificateRootProps}
										isGstCertificateDragActive={isGstCertificateDragActive}
										gstCertificateFile={gstCertificateFile}
									/>
								)}

								<Form.Item style={{ marginTop: "20px" }}>
									<Center height="0px">
										<Space>
											<NavLink to="/usermanagement/user">
												<Button danger type="primary" onClick={handleCancel}>
													Cancel
												</Button>
											</NavLink>
											<Button type="primary" htmlType="submit" onClick={validate}>
												Update
											</Button>
										</Space>
									</Center>
								</Form.Item>
							</Form>
						</Card>
					</Col>
				</Row>
			</ErrorBoundary>
		</BasicLayout>
	);
};

export default UserUpdateForm;

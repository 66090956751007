import { Button, Card, Col, Form, message, Row, Space, Typography, Radio } from "antd";
import { formatFileName } from "../../utils/helper";
import axios from "axios";
import { useCallback, useEffect, useState, useMemo } from "react";
import { useDropzone } from "react-dropzone";
import { ErrorBoundary } from "react-error-boundary";
import { NavLink, useHistory, useLocation } from "react-router-dom";
import BasicLayout from "../../components/BasicLayout";
import Center from "../../components/common/Center";
import FallBack from "../../components/common/FallBack/FallBack";
import Notification from "../../components/common/Notification";
import { useIndianStates } from "../../components/Hooks/useIndianStates";
import { useIndianMultipleDistricts } from "../../components/Hooks/useIndianMultipleDistricts";
import { useIndianMultipleSubDistricts } from "../../components/Hooks/useMultipleIndianSubDistrict";
import { useDispatch, useSelector } from "react-redux";
import { getAllTemplates } from "../../store/actions/template/template.action";
import { addUser, getSingleUser, userAddSuccess } from "../../store/actions/user/user.action";
import { imageSizeDependancy } from "../../utils/imageSize";
import Spinner from "../../components/common/Spinner";
import { EmployeeForm } from "./Forms/employeeForm";
import { OtherCategoryForm } from "./Forms/otherCategoryForm";
import "./User.css";

const UserForm = (props) => {
	const { Title } = Typography;
	const [form] = Form.useForm();
	const dispatch = useDispatch();
	const location = useLocation();

	const softdelete = new URLSearchParams(location.search).get("soft-delete");
	const admin = useSelector((state) => state.admin.admin || {});

	const history = useHistory();

	const userId = +props.match.params.id;
	const currentLocation = props.location.pathname;

	const [districtList, setDistrictList] = useState([]);
	const [subDistrictList, setSubDistrictList] = useState([]);

	const [selectedStateIds, setSelectedStateIds] = useState([1]);
	const [selectedDistrictIds, setSelectedDistrictIds] = useState([1]);
	// eslint-disable-next-line
	const [selectedSubDistrictIds, setSelectedSubDistrictIds] = useState([1]);
	const [uploadMedia, setUploadMedia] = useState("");

	const [isTemplateLoading, setIsTemplateLoading] = useState(false);

	const { getIndianStates } = useIndianStates();
	const { getIndianMultipleDistricts } = useIndianMultipleDistricts();
	const { getIndianMultipleSubDistricts } = useIndianMultipleSubDistricts();

	const templates = useSelector((state) => state.template.templates || []);
	const user = useSelector((state) => state?.user?.user || {});

	const onChangeState = (value) => {
		const arrOfNum = value.map((str) => {
			return Number(str);
		});
		const data = {
			stateIds: arrOfNum,
		};

		setSelectedStateIds(data);
		setSelectedDistrictIds("");
		setSelectedSubDistrictIds("");
	};

	useEffect(() => {
		if (selectedStateIds) {
			getIndianMultipleDistricts(selectedStateIds)
				.then((resp) => {
					setDistrictList(resp);
				})
				.catch((err) => {
					return "wrong";
				});
		}
		if (selectedDistrictIds) {
			const data = {
				districtIds: selectedDistrictIds,
			};
			getIndianMultipleSubDistricts(data)
				.then((resp) => {
					setSubDistrictList(resp);
				})
				.catch((err) => {
					return "wrong";
				});
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [selectedStateIds, selectedDistrictIds]);

	useEffect(() => {
		async function fetchData() {
			setIsTemplateLoading(true);
			await dispatch(getAllTemplates());
			setIsTemplateLoading(false);
		}
		fetchData();
	}, [dispatch]);

	const onChangeDistrict = (value) => setSelectedDistrictIds(value);
	const onChangeSubDistrict = (value) => setSelectedSubDistrictIds(value);

	// eslint-disable-next-line react-hooks/exhaustive-deps
	const status = useMemo(() => [
		{
			name: "Private Limited",
		},
		{
			name: "Public Limited",
		},
		{
			name: "Propertiery",
		},
		{
			name: "Partnership",
		},
		{
			name: "Partnership LLC",
		},
	]);

	const [categoryValue, setCategoryValue] = useState("employee");
	const [value, setValue] = useState();
	const [subUserValue, setSubUserValue] = useState();

	const [ruleIsRequired, setRuleIsRequired] = useState(true);

	useEffect(() => {
		if (categoryValue === 5) {
			setRuleIsRequired(false);
		} else {
			setRuleIsRequired(true);
		}
	}, [categoryValue]);

	const [gstTypeValue, setgstTypeValue] = useState();

	const onGSTTypeRadioChange = (e) => {
		setgstTypeValue(e.target.value);
	};

	const [companyStatusValue, setCompanyStatusValue] = useState(undefined);

	const [cinNumberIsRequired, setCNINumberIsRequired] = useState(false);

	useEffect(() => {
		if (companyStatusValue === "Private Limited" || companyStatusValue === "Public Limited") {
			setCNINumberIsRequired(true);
		} else {
			setCNINumberIsRequired(false);
		}
	}, [companyStatusValue]);

	const statusOptions2 = useMemo(
		() =>
			status.map((access) => ({
				key: `${access.name}`,
				value: access.name,
				title: access.name,
			})),
		[status]
	);

	const onRadioChange = (e) => {
		setValue(e.target.value);
	};
	const onUserCategoryChange = (e) => {
		setCategoryValue(e.target.value);
	};

	const onSubUsersRadioChange = (e) => {
		setSubUserValue(e.target.value);
	};

	const handleError = (error) => {
		message.error(error.message);
	};

	useEffect(() => {
		if (currentLocation !== "/usermanagement/user/add") {
			setValue(user?.gstNumber ? 3 : 4);
			setSubUserValue(user?.allowToAddSubusers ? 1 : 2);
		} else {
			setValue();
			setSubUserValue();
		}
	}, [user?.gstNumber, user?.allowToAddSubusers, currentLocation]);

	useEffect(() => {
		if (userId) {
			dispatch(getSingleUser(userId, softdelete)).catch((err) => {
				Notification("error", err, "", "topRight");
				history.push("/usermanagement/user");
			});
		}
	}, [userId, history, softdelete, dispatch]);

	const handleCancel = () => {
		Notification("info", "Job action has been cancelled", "", "topRight");
	};

	const [file, setFile] = useState([]);

	const onImageDrop = useCallback((acceptedFiles, fileRejections) => {
		try {
			setFile(acceptedFiles || []);
			imageSizeDependancy(fileRejections);
		} catch (_err) {}
	}, []);

	const {
		getRootProps: getImageRootProps,
		getInputProps: getImageInputProps,
		isDragActive: isImageDragActive,
	} = useDropzone({
		onDrop: onImageDrop,
		accept: "image/png",
		multiple: false,
		maxSize: "1000000",
	});

	const [identityDocFile, setidentityDocFile] = useState([]);

	const onidentityDocDrop = useCallback((acceptedFiles) => {
		try {
			setidentityDocFile(acceptedFiles);
		} catch (_err) {}
	}, []);

	const {
		getRootProps: getidentityDocRootProps,
		getInputProps: getidentityDocInputProps,
		isDragActive: isidentityDocDragActive,
	} = useDropzone({
		onDrop: onidentityDocDrop,
		accept: ["image/png", ".pdf"],
		multiple: false,
		maxSize: "1000000",
	});

	const [gstCertificateFile, setGstCertificateFile] = useState(undefined);

	const onGstCertificateDrop = useCallback((acceptedFiles) => {
		try {
			setGstCertificateFile(acceptedFiles);
		} catch (_err) {}
	}, []);

	const {
		getRootProps: getGstCertificateRootProps,
		getInputProps: getGstCertificateInputProps,
		isDragActive: isGstCertificateDragActive,
	} = useDropzone({
		onDrop: onGstCertificateDrop,
		accept: ".pdf",
		multiple: false,
		maxSize: "5000000",
	});

	const [registrationFile, setRegistrationFile] = useState(undefined);

	const onRegistrationPdfDrop = useCallback((acceptedFiles) => {
		try {
			setRegistrationFile(acceptedFiles);
		} catch (_err) {}
	}, []);

	const {
		getRootProps: getRegistrationPdfRootProps,
		getInputProps: getRegistrationPdfInputProps,
		isDragActive: isRegistrationPdfDragActive,
	} = useDropzone({
		onDrop: onRegistrationPdfDrop,
		accept: ".pdf",
		multiple: false,
		maxSize: "5000000",
	});

	const TemplateChildren = useMemo(
		() =>
			templates.map((template) => ({
				key: `${template.name} `,
				value: template.id,
				title: template.name,
			})),
		[templates]
	);

	const onFinish = async (values) => {
		try {
			if (identityDocFile.length === 0) {
				Notification("error", "Please upload a valid Identity Proof", "", "topRight");
				return;
			}
			if (!values.gstNumber) {
				delete values.gstNumber;
			}

			const companyLogoFileName =
				file?.length > 0 &&
				formatFileName(file[0].name.split(".")[0], file[0].name.split(".").pop(), admin);
			const companyLogoFileType = file?.length > 0 && file[0].type;

			const identityDocFileName = formatFileName(
				identityDocFile[0].name.split(".")[0],
				identityDocFile[0].name.split(".").pop(),
				admin
			);

			const identityDocFileType = identityDocFile[0].type;

			const gstCertificatePdfFileName =
				gstCertificateFile &&
				formatFileName(
					gstCertificateFile[0].name.split(".")[0],
					gstCertificateFile[0].name.split(".").pop(),
					admin
				);

			const gstCertificatePdfFileType = gstCertificateFile && gstCertificateFile[0].type;

			const companyRegisterPdfFileName =
				registrationFile &&
				formatFileName(
					registrationFile[0].name.split(".")[0],
					registrationFile[0].name.split(".").pop(),
					admin
				);

			const companyRegisterPdfFileType = registrationFile && registrationFile[0].type;

			let state = [];
			for (let i = 0; i < values.state.length; i++) {
				getIndianStates().forEach((data) => {
					const num = values.state[i];
					if (data["State Code"].toString() === num.toString()) {
						state.push(data);
					}
				});
			}

			let stateName = [];
			for (let i = 0; i < state?.length; i++) {
				stateName.push(state[i]["State Name(In English)"]);
			}

			let districtArr = [];
			for (let i = 0; i < values.district.length; i++) {
				districtList.forEach((data) => {
					const num = values.district[i];

					if (data["District Code"].toString() === num.toString()) {
						districtArr.push(data);
					}
				});
			}

			let districtName = [];
			for (let i = 0; i < districtArr?.length; i++) {
				districtName.push(districtArr[i]["District Name(In English)"]);
			}

			let subDistrictArr = [];
			for (let i = 0; i < values.subDistrict.length; i++) {
				subDistrictList.forEach((data) => {
					const num = values.subDistrict[i];

					if (data["Sub District Code"].toString() === num.toString()) {
						subDistrictArr.push(data);
					}
				});
			}

			let subDistrictName = [];
			for (let i = 0; i < subDistrictArr?.length; i++) {
				subDistrictName.push(subDistrictArr[i]["Sub District Name"]);
			}

			let tempgst = values?.gstNumber;
			if (tempgst) {
				tempgst = tempgst?.replace(/\s/g, "");
				if (tempgst.length !== 13) {
					Notification("error", "Remove Space Between GST", "", "topRight");
					return;
				}
			}

			let tempOfficePincode = values?.officePincode;
			if (tempOfficePincode) {
				tempOfficePincode = tempOfficePincode?.replace(/\s/g, "");
				if (tempOfficePincode.length !== 6) {
					Notification("error", "Remove Space Between Office Pincode", "", "topRight");
					return;
				}
			}

			let temptdsNumber = values?.tdsNumber;
			if (temptdsNumber) {
				temptdsNumber = temptdsNumber?.replace(/\s/g, "");
				if (temptdsNumber.length !== 10) {
					Notification("error", "Remove Space Between TDS Number", "", "topRight");
					return;
				}
			}

			let uploadedData;
			if (gstCertificatePdfFileName && gstCertificatePdfFileType) {
				uploadedData = {
					gstCertificatePdfFileType,
					gstCertificatePdfFileName,
				};
			}

			if (companyRegisterPdfFileType && companyRegisterPdfFileName) {
				uploadedData = {
					...uploadedData,
					companyRegisterPdfFileType,
					companyRegisterPdfFileName,
				};
			}

			if (companyLogoFileType && companyLogoFileName) {
				uploadedData = {
					...uploadedData,
					companyLogoFileType,
					companyLogoFileName,
				};
			}

			let data = {
				...values,
				...uploadedData,
				identityDocFileName,
				identityDocFileType,
				state: stateName,
				district: districtName,
				subDistrict: subDistrictName,
				gstNumber: tempgst,
				tdsNumber: temptdsNumber,
				officePincode: tempOfficePincode,
			};

			if (data?.userCategory === "employee") {
				delete data?.companyLogoFileName;
				delete data?.companyLogoFileType;
				delete data?.companyRegisterPdfFileName;
				delete data?.companyRegisterPdfFileType;
			}

			const resp = await dispatch(addUser(data));

			if (resp && resp?.companyLogoSignedUrl) {
				const optionsForCompanyLogo = {
					headers: {
						"Content-Type": uploadMedia[0].type,
					},
				};
				await axios.put(resp?.companyLogoSignedUrl, uploadMedia[0], optionsForCompanyLogo);
			}

			if (resp && resp?.identityDocSignedUrl) {
				const optionsForidentityDoc = {
					headers: {
						"Content-Type": identityDocFile[0].type,
					},
				};
				await axios.put(resp?.identityDocSignedUrl, identityDocFile[0], optionsForidentityDoc);
			}

			if (resp && resp?.gstCertificateSignedUrl) {
				const optionsForGSTCertificatePdf = {
					headers: {
						"Content-Type": gstCertificateFile[0].type,
					},
				};
				await axios.put(
					resp?.gstCertificateSignedUrl,
					gstCertificateFile[0],
					optionsForGSTCertificatePdf
				);
			}

			if (resp && resp?.companyRegisterSignedUrl) {
				const optionsForCompanyRegisterPdf = {
					headers: {
						"Content-Type": registrationFile[0].type,
					},
				};
				await axios.put(
					resp?.companyRegisterSignedUrl,
					registrationFile[0],
					optionsForCompanyRegisterPdf
				);
			}

			await dispatch(userAddSuccess(resp));
			history.push("/usermanagement/user");
			Notification("success", "User has been succesfully added.", "", "topRight");
		} catch (err) {
			const errorMessage =
				(err?.response && err?.response?.data?.message) || "Something went wrong";
			return Notification("error", "Error Occurred", errorMessage, "topRight");
		}
	};

	const validate = () => {
		form
			.validateFields()
			.then(() => {})
			.catch((errorInfo) => {
				const message = errorInfo?.errorFields;
				const errorArray = message.filter((field) => field.errors.length > 0);
				const errr = errorArray.map((field) => field.errors);
				for (let i = 0; i < errr.length; i++)
					if (errr.length > 0) {
						Notification("error", errr[i], "", "topRight");
					}
			});
	};

	if (isTemplateLoading) {
		return (
			<Center>
				<Spinner />
			</Center>
		);
	}

	return (
		<BasicLayout accessLevel={props.accessLevel}>
			<ErrorBoundary FallbackComponent={FallBack} onError={handleError}>
				<Row type="flex" justify="center" align="middle" id="form-container">
					<Center height="4rem">
						{userId ? (
							<Title level={2} className="update-or-add-title">
								Update User
							</Title>
						) : (
							<Title level={2} className="update-or-add-title">
								Add New User
							</Title>
						)}
					</Center>
					<Col>
						<Card className="form-card">
							<Form
								form={form}
								scrollToFirstError
								name="basic"
								layout="vertical"
								onFinish={onFinish}
							>
								<Form.Item
									rules={[
										{
											required: true,
										},
									]}
									label="Select User Category"
									name="userCategory"
								>
									<Radio.Group
										defaultValue={null}
										onChange={onUserCategoryChange}
										value={categoryValue}
									>
										<Radio value={"employee"}>Employee</Radio>
										<Radio value={"distributor"}>Distributor</Radio>
										<Radio value={"dealer"}>Dealer</Radio>
										<Radio value={"subDealer"}>Sub Dealer</Radio>
									</Radio.Group>
								</Form.Item>
								{categoryValue === "employee" && (
									<EmployeeForm
										ruleIsRequired={ruleIsRequired}
										onChangeState={onChangeState}
										getIndianStates={() => getIndianStates()}
										selectedStateIds={selectedStateIds}
										districtList={districtList}
										onChangeDistrict={onChangeDistrict}
										onChangeSubDistrict={onChangeSubDistrict}
										selectedDistrictIds={selectedDistrictIds}
										subDistrictList={subDistrictList}
										TemplateChildren={TemplateChildren}
										userId={userId}
										user={user}
										getidentityDocInputProps={getidentityDocInputProps}
										getidentityDocRootProps={getidentityDocRootProps}
										isidentityDocDragActive={isidentityDocDragActive}
										identityDocFile={identityDocFile}
									/>
								)}
								{categoryValue !== "employee" && (
									<OtherCategoryForm
										ruleIsRequired={ruleIsRequired}
										onChangeState={onChangeState}
										getIndianStates={() => getIndianStates()}
										selectedStateIds={selectedStateIds}
										districtList={districtList}
										onChangeDistrict={onChangeDistrict}
										onChangeSubDistrict={onChangeSubDistrict}
										selectedDistrictIds={selectedDistrictIds}
										subDistrictList={subDistrictList}
										TemplateChildren={TemplateChildren}
										userId={userId}
										user={user}
										getidentityDocInputProps={getidentityDocInputProps}
										getidentityDocRootProps={getidentityDocRootProps}
										isidentityDocDragActive={isidentityDocDragActive}
										identityDocFile={identityDocFile}
										onSubUsersRadioChange={onSubUsersRadioChange}
										subUserValue={subUserValue}
										setCompanyStatusValue={setCompanyStatusValue}
										statusOptions2={statusOptions2}
										companyStatusValue={companyStatusValue}
										cinNumberIsRequired={cinNumberIsRequired}
										onRadioChange={onRadioChange}
										value={value}
										onGSTTypeRadioChange={onGSTTypeRadioChange}
										gstTypeValue={gstTypeValue}
										setUploadMedia={setUploadMedia}
										file={file}
										isImageDragActive={isImageDragActive}
										getImageInputProps={getImageInputProps}
										getImageRootProps={getImageRootProps}
										getRegistrationPdfInputProps={getRegistrationPdfInputProps}
										getRegistrationPdfRootProps={getRegistrationPdfRootProps}
										isRegistrationPdfDragActive={isRegistrationPdfDragActive}
										registrationFile={registrationFile}
										getGstCertificateInputProps={getGstCertificateInputProps}
										getGstCertificateRootProps={getGstCertificateRootProps}
										isGstCertificateDragActive={isGstCertificateDragActive}
										gstCertificateFile={gstCertificateFile}
									/>
								)}

								{/* <InputField
									rules={[
										{
											required: true,
										},
										{
											min: 1,
											message: "User Name be minimum 1 character.",
										},
										{
											max: 100,
											message: "User Name be minimum 100 character.",
										},
									]}
									type="string"
									label="User Name"
									name="userName"
									placeholder="Please enter user name"
									prefix={<UserOutlined />}
								/> */}

								{/* <WordCountDisplayer
									label="Description"
									name="description"
									placeholder="Please enter Description"
									rules={[
										{
											message: "Description must be minimum 10 words.",
										},
									]}
								/> */}
								{/* {categoryValue !== "employee" && (
									<> */}
								{/* <Title
											level={5}
											style={{
												fontWeight: "400",
												fontSize: "18px",
												marginTop: "25px",
												marginBottom: "20px",
											}}
										>
											<span
												style={{
													color: "#f08080",
													fontWeight: "thin",
													fontSize: "16px",
												}}
											>
												*
											</span>{" "}
											Can company add subusers
										</Title>
										<Radio.Group
											defaultValue={null}
											onChange={onSubUsersRadioChange}
											value={subUserValue}
											style={{
												marginBottom: "20px",
											}}
										>
											<Radio value={1}>Yes</Radio>
											<Radio value={2}>No</Radio>
										</Radio.Group> */}
								{/* {subUserValue === 1 && (
											<InputField
												label="How many subusers can user add"
												name="allowToAddSubusers"
												placeholder="Please enter How many subusers can user add"
												prefix={<UserOutlined />}
											/>
										)} */}
								{/* <InputField
											rules={[
												{
													required: ruleIsRequired,
												},
												{ min: 1, message: "Company Name be minimum 1 character" },
												{
													max: 100,
													message: "Company Name be minimum 100 character",
												},
											]}
											label="Company Name"
											name="companyName"
											placeholder="Please enter company name"
											prefix={<UserOutlined />}
										/> */}
								{/* <Title
											level={5}
											style={{
												fontWeight: "400",
												fontSize: "18px",
												marginTop: "25px",
											}}
										>
											<span
												style={{
													color: "#f08080",
													fontWeight: "thin",
													fontSize: "16px",
												}}
											>
												*
											</span>
											Upload Company Logo
										</Title>
										<Upload
											getRootProps={getImageRootProps}
											getInputProps={getImageInputProps}
											type="image"
											isDragActive={isImageDragActive}
											file={file ? file : []}
											url={userId ? user?.companyLogoUrl : ""}
											setUploadMedia={setUploadMedia}
										/> */}
								{/* <InputField
											style={{ marginTop: "30px" }}
											rules={[
												{
													required: ruleIsRequired,
												},
												{ min: 1, message: "Owner Name be minimum 01 character" },
												{
													max: 28,
													message: "Owner Name be minimum 28 character",
												},
											]}
											label="Owner Name"
											name="ownerName"
											placeholder="Please enter Owner name"
											prefix={<UserOutlined />}
										/> */}
								{/* <CommonSelect
											rules={[
												{
													required: ruleIsRequired,
													message: "Company Status is required",
												},
											]}
											label="Company Status "
											name="companyStatus"
											placeholder="Please enter company status name "
											options={statusOptions2}
										/> */}
								{/* <Form.Item
											rules={[
												{
													required: ruleIsRequired,
													message: "Company Status is required",
												},
											]}
											label="Company Status"
											name="companyStatus"
										>
											<Select
												placeholder="Please enter company status "
												onChange={(value) => {
													setCompanyStatusValue(value);
												}}
											>
												{statusOptions2.map((option, index) => (
													<Option value={option.value} key={index} title={option.key}>
														{option.key}
													</Option>
												))}
											</Select>
										</Form.Item> */}
								{/* </>
								)} */}

								{/* {(companyStatusValue === "Private Limited" ||
									companyStatusValue === "Public Limited") && (
									<InputField
										style={{ marginTop: "20px" }}
										rules={[
											{
												required: cinNumberIsRequired,
												message: "Company CIN Number is required",
											},
										]}
										label="Company CIN Number"
										name="companyCINNumber"
										placeholder="Please enter Company CIN Number."
										prefix={<UserOutlined />}
									/>
								)} */}
								{/* <Form.Item
									rules={[
										{
											required: true,
										},
									]}
									label="Select State"
									name="state"
									defaultValue={undefined}
								>
									<Select
										allowClear
										mode="multiple"
										style={{ width: "100%" }}
										onChange={onChangeState}
										defaultValue={undefined}
										placeholder="Please select states"
										filterOption={(input, option) => {
											return option?.children?.toLowerCase().indexOf(input?.toLowerCase()) >= 0;
										}}
									>
										{getIndianStates().map((state, index) => (
											<Option value={state["State Code"]} key={index}>
												{state["State Name(In English)"]}
											</Option>
										))}
									</Select>
								</Form.Item>
								<Form.Item
									rules={[
										{
											required: false,
										},
									]}
									label="Select District"
									name="district"
									defaultValue={undefined}
								>
									<Select
										placeholder={
											selectedStateIds
												? "Please select a Districts"
												: "Please select a States first"
										}
										allowClear
										mode="multiple"
										onChange={onChangeDistrict}
										style={{ width: "100%" }}
										filterOption={(input, option) => {
											return option?.children?.toLowerCase().indexOf(input?.toLowerCase()) >= 0;
										}}
									>
										{selectedStateIds &&
											districtList?.map((district, index) => (
												<Option value={district["District Code"]} key={index}>
													{district["District Name(In English)"]}
												</Option>
											))}
									</Select>
								</Form.Item>
								<Form.Item
									rules={[
										{
											required: false,
										},
									]}
									label="Select Sub District"
									name="subDistrict"
									defaultValue={undefined}
								>
									<Select
										allowClear
										mode="multiple"
										onChange={onChangeSubDistrict}
										style={{ width: "100%" }}
										placeholder={
											selectedDistrictIds
												? "Please select a Sub-Districts"
												: "Please select a Districts first"
										}
										filterOption={(input, option) => {
											return option?.children?.toLowerCase().indexOf(input?.toLowerCase()) >= 0;
										}}
									>
										{selectedDistrictIds &&
											subDistrictList?.map((district, index) => (
												<Option value={district["Sub District Code"]} key={index}>
													{district["Sub District Name"]}
												</Option>
											))}
									</Select>
								</Form.Item> */}
								{/* <InputField
									rules={[
										{
											required: true,
										},
									]}
									label="Village"
									name="village"
									placeholder="Please enter village"
									prefix={<UserOutlined />}
								/> */}
								{/* <div style={{ minHeight: "120px", marginTop: "20px" }}>
									<TextArea
										rules={[{ required: true }]}
										label="Office Address"
										name="officeAddress"
										placeholder="Please enter Office Address "
									/>
								</div> */}
								{/* <InputField
									rules={[
										{
											required: true,
											message: "Office Pincode is required",
										},
										{ min: 6, max: 6, message: "Office Pincode must be 6 characters." },
									]}
									label="Office Pincode"
									name="officePincode"
									placeholder="Please enter Office Pincode."
									prefix={<UserOutlined />}
								/> */}
								{/* {categoryValue !== "employee" && (
									<div style={{ minHeight: "120px", marginTop: "20px" }}> */}
								{/* <TextArea
											rules={[{ required: ruleIsRequired }]}
											label="Godown Address"
											name="godownAddress"
											placeholder="Please enter Godown Address"
										/>
									</div> */}
								{/* )} */}
								{categoryValue !== "employee" && (
									<>
										{/* <Title
											level={5}
											style={{
												fontWeight: "400",
												fontSize: "18px",
												marginTop: "20px",
											}}
										>
											<span
												style={{
													color: "#f08080",
													fontWeight: "thin",
													fontSize: "16px",
												}}
											>
												*
											</span>{" "}
											GST Available :
										</Title> */}
										{/* <Radio.Group
											defaultValue={null}
											onChange={onRadioChange}
											value={value}
											style={{
												marginBottom: "20px",
											}}
										>
											<Radio value={3}>Yes</Radio>
											<Radio value={4}>No</Radio>
										</Radio.Group> */}
									</>
								)}

								{/* {value === 3 && (
									<>
										{/* <Form.Item label="Is GST Composite" name="gstType">
											<Radio.Group
												defaultValue={null}
												onChange={onGSTTypeRadioChange}
												value={gstTypeValue}
											>
												<Radio value={"yes"}>Yes</Radio>
												<Radio value={"no"}>No</Radio>
											</Radio.Group>
										</Form.Item> */}
								{/* <InputField
											rules={[
												{
													min: 13,
													max: 13,
													message: "GST Number must be 13 characters.",
												},
											]}
											label="GST Number"
											name="gstNumber"
											placeholder="Please enter GST Number."
											prefix={<UserOutlined />}
										/> */}
								{/* <Title
											level={5}
											style={{
												fontWeight: "400",
												fontSize: "18px",
												marginTop: "20px",
											}}
										>
											Upload GST Certificate :
										</Title> */}
								{/* <UploadPDF
											style={{
												marginBottom: "25px",
											}}
											url={userId ? user?.gstCertificateUrl : ""}
											getInputProps={getGstCertificateInputProps}
											getRootProps={getGstCertificateRootProps}
											isDragActive={isGstCertificateDragActive}
											pdf={gstCertificateFile} */}
								{/* // /> */}
								{/* </> */}
								{/* {categoryValue !== "employee" && (
									<InputField
										rules={[
											{
												min: 10,
												max: 10,
												message: "TDS Number must be 10 characters.",
											},
										]}
										label="TDS Number"
										name="tdsNumber"
										placeholder="Please enter TDS Number."
										prefix={<UserOutlined />}
									/>
								)} */}
								{/* <InputField
									style={{ marginTop: "20px" }}
									rules={[
										{
											required: true,
											message: "Company PAN Card Number is required",
										},
										{
											min: 6,
											max: 14,
											message:
												"Company PAN Card Number must be minimumu 6 characters & maxmimum 14 characters.",
										},
									]}
									label="Company PAN Card Number"
									name="companyPANCardNumber"
									placeholder="Please enter Company PAN Card Number."
									prefix={<UserOutlined />}
								/> */}
								{/* <Title
									level={5}
									style={{
										fontWeight: "400",
										fontSize: "18px",
										marginTop: "25px",
									}}
								>
									<span
										style={{
											color: "#f08080",
											fontWeight: "thin",
											fontSize: "16px",
										}}
									>
										*
									</span>
									Upload Pan Card / Aadhar Card / Driving License :
								</Title>
								<UploadFILE
									url={userId ? user?.identityDocUrl : ""}
									getInputProps={getidentityDocInputProps}
									getRootProps={getidentityDocRootProps}
									isDragActive={isidentityDocDragActive}
									file={identityDocFile}
								/> */}
								{categoryValue !== "employee" && (
									<>
										{/* <Title
											level={5}
											style={{
												fontWeight: "400",
												fontSize: "18px",
												marginTop: "20px",
											}}
										>
											<span
												style={{
													color: "#f08080",
													fontWeight: "thin",
													fontSize: "16px",
												}}
											>
												*
											</span>{" "}
											Upload Company Registration Document :
										</Title>
										<UploadPDF
											url={userId ? user?.companyRegisterUrl : ""}
											getInputProps={getRegistrationPdfInputProps}
											getRootProps={getRegistrationPdfRootProps}
											isDragActive={isRegistrationPdfDragActive}
											pdf={registrationFile}
										/> */}
									</>
								)}
								{/* <div className="userFormTextGroup">
									<div className="userFormTextGroupChild" style={{ marginRight: "10px" }}>
										<InputField
											rules={[
												{
													required: true,
													message: "Phone Number is required",
												},
												{
													min: 10,
													message: "Phone Number must be minimum 10 characters.",
												},
												{
													max: 10,
													message: "Phone Number must be maximum 10 characters.",
												},
											]}
											label="Phone Number"
											name="phoneNumber"
											placeholder="Please enter Phone Number"
											prefix={<MobileOutlined />}
										/>
									</div>
									<div className="userFormTextGroupChild">
										<InputField
											rules={[
												{
													required: true,
													message: "Email is required",
												},
												{
													min: 1,
													message: "Email minimum = 1",
												},
											]}
											label="Email"
											name="email"
											placeholder="Please enter Email."
											prefix={<MailOutlined />}
										/>
									</div>
									<div className="userFormTextGroupChild">
										<InputField
											rules={[
												{
													required: true,
													message: "Password is required",
												},
												{
													max: 100000,
													message: "Password maximum=100000",
												},
											]}
											label="Password"
											name="password"
											inputType="password"
											placeholder="Please enter Password."
											autoComplete="current-password"
											prefix={<RiLockPasswordLine />}
										/>
									</div> */}
								{/* </div> */}
								{/* <CommonSelect
									rules={[{ required: true, message: "Template is required" }]}
									label="Select Template"
									name="templateId"
									placeholder="please Select Templates"
									options={TemplateChildren}
								/> */}

								<Form.Item style={{ marginTop: "20px" }}>
									<Center height="0px">
										<Space>
											<NavLink to="/usermanagement/user">
												<Button danger type="primary" onClick={handleCancel}>
													Cancel
												</Button>
											</NavLink>
											<Button type="primary" htmlType="submit" onClick={validate}>
												Add
											</Button>
										</Space>
									</Center>
								</Form.Item>
							</Form>
						</Card>
					</Col>
				</Row>
			</ErrorBoundary>
		</BasicLayout>
	);
};

export default UserForm;

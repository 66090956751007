import { invokeApi } from "../utils/api";
import { $axios } from "../utils/axios";

export const getStates = () =>
	invokeApi($axios, "/get-states", {}, "GET")
		.then((resp) => resp)
		.catch((err) => {
			throw err;
		});

export const getAllDistricts = () =>
	invokeApi($axios, "/alldistricts", {}, "GET")
		.then((resp) => resp)
		.catch((err) => {
			throw err;
		});

export const getAllSubDistricts = () =>
	invokeApi($axios, "/allsubdistricts", {}, "GET")
		.then((resp) => resp)
		.catch((err) => {
			throw err;
		});

export const getDistricts = (stateId) =>
	invokeApi($axios, `/get-districts/${stateId}`, {}, "GET")
		.then((resp) => resp)
		.catch((err) => {
			throw err;
		});

export const getSubDistricts = (districtId) =>
	invokeApi($axios, `/get-subdistricts/${districtId}`, {}, "GET")
		.then((resp) => resp)
		.catch((err) => {
			throw err;
		});

export const getMultipleDistricts = (stateIds) =>
	invokeApi($axios, `/getDistricts`, stateIds, "POST")
		.then((resp) => resp?.data?.data)
		.catch((err) => {
			throw err;
		});

export const getMultipleSubDistricts = (districtIds) =>
	invokeApi($axios, `/getSub-Districts`, districtIds, "POST")
		.then((resp) => resp?.data?.data)
		.catch((err) => {
			throw err;
		});

import { useDispatch } from "react-redux";
import Notification from "../common/Notification";

export const useDeleteAction = () => {
	const dispatch = useDispatch();
	const deleteData = async (id, reason, action, setIsModalVisible) => {
		try {
			if (reason) {
				const wordCount = reason?.replace(/\s/g, '').length;
				if (reason === "" || wordCount < 10) {
					throw Error("Reason Minimum 10 Characters required");
				}
				await dispatch(action(id, reason));
				setIsModalVisible(false);
			} else {
				setIsModalVisible(false);
				throw Error("Reason Minimum 10 Characters required");
			}
		} catch (err) {
			let message = err.message ? err.message : err ? err : "Something went wrong";
			return Notification("error", message, "", "topRight");
		}
	};
	return { deleteData };
};

import { message } from "antd";
import { useEffect, useState } from "react";
import { ErrorBoundary } from "react-error-boundary";
import BasicLayout from "../../components/BasicLayout/index";
import Tables from "../../components/common/Table/Table";
import { useDeleteAction } from "../../components/Hooks/useDeleteAction";
import useTableSearch from "../../components/Hooks/useTableSearch";
import { getAllUsers, softDeleteSingleUser } from "../../store/actions/user/user.action";
import { DeleteModel } from "../../utils/DeleteModel";
import FallBack from "../../components/common/FallBack/FallBack";
import ButtonAsLink from "../../components/common/ButtonAsLink";
import { useDispatch, useSelector } from "react-redux";

const UsersPage = (props) => {
	const [getColumnSearchProps] = useTableSearch();
	const { deleteData } = useDeleteAction();
	const [deleteId, setDeleteId] = useState(undefined);
	const [isModalVisible, setIsModalVisible] = useState(false);
	const dispatch = useDispatch();
	const users = useSelector((state) => state.user.users || []);

	useEffect(() => {
		dispatch(getAllUsers());
	}, [dispatch]);

	let dataSource = [];
	if (users.length > 0) {
		dataSource = users.sort(function (a, b) {
			return new Date(b.updatedAt) - new Date(a.updatedAt);
		});
		dataSource = dataSource.map((row, index) => ({
			...row,
			createdAt: new Date(row.createdAt).toLocaleString(),
			key: row.id,
			templateName: row.template?.name,
		}));
	}

	async function confirm(id, reason) {
		await deleteData(id, reason, softDeleteSingleUser, setIsModalVisible);
	}

	const columns = [
		{
			title: "Id",
			dataIndex: "id",
			key: "id",
		},
		{
			title: "Name",
			dataIndex: "userName",
			key: "userName",
			...getColumnSearchProps("userName"),
		},
		{
			title: "Email",
			dataIndex: "email",
			key: "email",
			...getColumnSearchProps("email"),
		},
		{
			title: "Phone Number",
			dataIndex: "phoneNumber",
			key: "phoneNumber",
			...getColumnSearchProps("phoneNumber"),
		},
		{
			title: "Category",
			dataIndex: "userCategory",
			key: "userCategory",
			...getColumnSearchProps("userCategory"),
		},
		{
			title: "Template",
			key: "templateName",
			...getColumnSearchProps("templateName"),
			render: (_text, record) => (
				<a href={`/usermanagement/template/${record?.template?.id}`}> {record?.template?.name}</a>
			),
		},
		{
			title: "Action",
			key: "action",
			render: (_text, record) => (
				<DeleteModel
					record={record}
					confirm={confirm}
					setIsModalVisible={setIsModalVisible}
					isModalVisible={isModalVisible}
					cancelMessage="User Removing has been cancelled"
					linkUrl={`/usermanagement/user`}
					placeholder="Please enter reason to delete User"
					deleteId={deleteId}
					setDeleteId={setDeleteId}
				/>
			),
		},
	];

	const handleError = (error) => {
		message.error(error.message);
	};

	return (
		<BasicLayout accessLevel={props.accessLevel}>
			<ErrorBoundary FallbackComponent={FallBack} onError={handleError}>
				<div className="button-style-to-right">
					<ButtonAsLink text="Add New User" link="/usermanagement/user/add" />
				</div>

				<Tables
					columns={columns}
					dataSource={dataSource}
					footerText={`Total Users: ${users.length}`}
				/>
			</ErrorBoundary>
		</BasicLayout>
	);
};

export default UsersPage;

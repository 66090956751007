import { Typography } from "antd";
import React from "react";

const { Text } = Typography;

const Index = ({ getRootProps, url, getInputProps, isDragActive, pdf, type ,style }) => {
	return (
		<div className="upload-root-container" style={style}>
			{url ? (
				<a href={url} target="_blank" rel="noreferrer">
					View
				</a>
			) : null}
			<div {...getRootProps()}>
				<input {...getInputProps()} />
				{isDragActive ? (
					<Text>Drop the {type} here ...</Text>
				) : (
					<div className="upload-area">
						{pdf && pdf?.length !== 0 ? (
							<Text style={{ fontWeight: "bold" }}>File Loaded Successfully</Text>
						) : null}
						<Text>Click or drag {type} to this area to upload</Text>
					</div>
				)}
			</div>
		</div>
	);
};
export default Index;

import Notification from "../../../../components/common/Notification";
import * as implementsSubAssembliesApi from "../../../../services/implementssubassemblies";
import {
	ADD_IMPLEMENTS_SUB_ASSEMBLY_FAILURE,
	ADD_IMPLEMENTS_SUB_ASSEMBLY_REQUEST,
	ADD_IMPLEMENTS_SUB_ASSEMBLY_SUCCESS,
	GET_IMPLEMENTS_SUB_ASSEMBLIES_FAILURE,
	GET_IMPLEMENTS_SUB_ASSEMBLIES_REQUEST,
	GET_IMPLEMENTS_SUB_ASSEMBLIES_SUCCESS,
	GET_IMPLEMENT_RELATED_IMPLEMENTS_SUB_ASSEMBLIES_FAILURE,
	GET_IMPLEMENT_RELATED_IMPLEMENTS_SUB_ASSEMBLIES_REQUEST,
	GET_IMPLEMENT_RELATED_IMPLEMENTS_SUB_ASSEMBLIES_SUCCESS,
	GET_SINGLE_IMPLEMENTS_SUB_ASSEMBLY_FAILURE,
	GET_SINGLE_IMPLEMENTS_SUB_ASSEMBLY_REQUEST,
	GET_SINGLE_IMPLEMENTS_SUB_ASSEMBLY_SUCCESS,
	RESTORE_SINGLE_IMPLEMENTS_SUB_ASSEMBLY_FAILURE,
	RESTORE_SINGLE_IMPLEMENTS_SUB_ASSEMBLY_REQUEST,
	RESTORE_SINGLE_IMPLEMENTS_SUB_ASSEMBLY_SUCCESS,
	SOFT_DELETE_SINGLE_IMPLEMENTS_SUB_ASSEMBLY_FAILURE,
	SOFT_DELETE_SINGLE_IMPLEMENTS_SUB_ASSEMBLY_REQUEST,
	SOFT_DELETE_SINGLE_IMPLEMENTS_SUB_ASSEMBLY_SUCCESS,
	UPDATE_IMPLEMENTS_SUB_ASSEMBLY_FAILURE,
	UPDATE_IMPLEMENTS_SUB_ASSEMBLY_REQUEST,
	UPDATE_IMPLEMENTS_SUB_ASSEMBLY_SUCCESS,
} from "./implementsSubAssemblies.actionType";

// GET REQUEST
export const requestGetAllImplementsSubAssemblies = () => {
	return {
		type: GET_IMPLEMENTS_SUB_ASSEMBLIES_REQUEST,
	};
};
export const getAllImplementsSubAssembliesSuccess = (data) => {
	return {
		type: GET_IMPLEMENTS_SUB_ASSEMBLIES_SUCCESS,
		data,
	};
};
export const getAllImplementsSubAssembliesFailure = (message) => {
	return {
		type: GET_IMPLEMENTS_SUB_ASSEMBLIES_FAILURE,
		message,
	};
};

// Get All ImplementsSubAssemblies
export const getAllImplementsSubAssemblies = () => {
	return (dispatch) => {
		dispatch(requestGetAllImplementsSubAssemblies());
		return implementsSubAssembliesApi
			.getAllImplementsSubAssemblies()
			.then((resp) => {
				const data = resp.data.data;
				dispatch(getAllImplementsSubAssembliesSuccess(data));
			})
			.catch((err) => {
				const errorMessage =
					(err?.response && err?.response?.data?.message) || "Something went wrong!";
				dispatch(getAllImplementsSubAssembliesFailure(errorMessage));
			});
	};
};

export const requestGetSingleImplementsSubAssembly = () => {
	return {
		type: GET_SINGLE_IMPLEMENTS_SUB_ASSEMBLY_REQUEST,
	};
};

export const getSingleImplementsSubAssemblySuccess = (data) => {
	return {
		type: GET_SINGLE_IMPLEMENTS_SUB_ASSEMBLY_SUCCESS,
		data,
	};
};

export const getSingleImplementsSubAssemblyFailure = (message) => {
	return {
		type: GET_SINGLE_IMPLEMENTS_SUB_ASSEMBLY_FAILURE,
		message,
	};
};

export const getSingleImplementsSubAssembly = (id, softdelete) => {
	return (dispatch) => {
		dispatch(requestGetSingleImplementsSubAssembly());
		return implementsSubAssembliesApi
			.getSingleImplementsSubAssembly(id, softdelete)
			.then((resp) => {
				const data = resp.data.data;
				dispatch(getSingleImplementsSubAssemblySuccess(data));
			})
			.catch((err) => {
				const errorMessage =
					(err?.response && err?.response?.data?.message) || "Something went wrong";
				dispatch(getSingleImplementsSubAssemblyFailure(errorMessage));
				throw errorMessage;
			});
	};
};

export const requestSoftDeleteSingleImplementsSubAssembly = () => {
	return {
		type: SOFT_DELETE_SINGLE_IMPLEMENTS_SUB_ASSEMBLY_REQUEST,
	};
};

export const softDeleteSingleImplementsSubAssemblySuccess = (data) => {
	return {
		type: SOFT_DELETE_SINGLE_IMPLEMENTS_SUB_ASSEMBLY_SUCCESS,
		data,
	};
};

export const softDeleteSingleImplementsSubAssemblyFailure = (message) => {
	return {
		type: SOFT_DELETE_SINGLE_IMPLEMENTS_SUB_ASSEMBLY_FAILURE,
		message,
	};
};

export const softDeleteSingleImplementsSubAssembly = (id, reason) => {
	return (dispatch) => {
		dispatch(requestSoftDeleteSingleImplementsSubAssembly());
		return implementsSubAssembliesApi
			.softDeleteSingleImplementsSubAssembly(id, reason)
			.then((resp) => {
				dispatch(softDeleteSingleImplementsSubAssemblySuccess(id));
				return Notification("success", resp.data.message, "", "topRight");
			})
			.catch((err) => {
				const errorMessage =
					(err?.response && err?.response?.data?.message) || "Something went wrong";
				dispatch(softDeleteSingleImplementsSubAssemblyFailure(errorMessage));
				return Notification("error", errorMessage, "", "topRight");
			});
	};
};

export const requestImplementsSubAssemblyRestore = () => {
	return {
		type: RESTORE_SINGLE_IMPLEMENTS_SUB_ASSEMBLY_REQUEST,
	};
};

export const restoreImplementsSubAssemblySuccess = () => {
	return {
		type: RESTORE_SINGLE_IMPLEMENTS_SUB_ASSEMBLY_SUCCESS,
	};
};

export const restoreImplementsSubAssemblyFailure = (message) => {
	return {
		type: RESTORE_SINGLE_IMPLEMENTS_SUB_ASSEMBLY_FAILURE,
		message,
	};
};

export const restoreImplementsSubAssembly = (id) => {
	return (dispatch) => {
		dispatch(requestImplementsSubAssemblyRestore());
		return implementsSubAssembliesApi
			.restoreImplementsSubAssembly(id)
			.then((resp) => {
				dispatch(restoreImplementsSubAssemblySuccess(id));
				Notification("success", resp.data.message, "", "topRight");
			})
			.catch((err) => {
				const errorMessage = (err.response && err.response.data.message) || "Something went wrong!";
				dispatch(restoreImplementsSubAssemblyFailure(errorMessage));
			});
	};
};

export const requestImplementsSubAssemblyAdd = () => {
	return {
		type: ADD_IMPLEMENTS_SUB_ASSEMBLY_REQUEST,
	};
};

export const ImplementsSubAssemblyAddSuccess = (data) => {
	return {
		type: ADD_IMPLEMENTS_SUB_ASSEMBLY_SUCCESS,
		data,
	};
};

export const ImplementsSubAssemblyAddFailure = (message) => {
	return {
		type: ADD_IMPLEMENTS_SUB_ASSEMBLY_FAILURE,
		message,
	};
};

export const addImplementsSubAssembly = (data) => {
	return (dispatch) => {
		dispatch(requestImplementsSubAssemblyAdd());
		return implementsSubAssembliesApi
			.addImplementsSubAssembly(data)
			.then((resp) => {
				const data = resp.data.data;
				return data;
			})
			.catch((err) => {
				const errorMessage = err.response && err.response.data.message;
				dispatch(ImplementsSubAssemblyAddFailure(errorMessage));
				Notification("error", "Error Occurred", errorMessage);
			});
	};
};

//FOR UPDATE ImplementsSubAssembly
export const requestUpdateImplementsSubAssembly = () => {
	return {
		type: UPDATE_IMPLEMENTS_SUB_ASSEMBLY_REQUEST,
	};
};

export const updateImplementsSubAssemblySuccess = (data) => {
	return {
		type: UPDATE_IMPLEMENTS_SUB_ASSEMBLY_SUCCESS,
		data,
	};
};

export const updateImplementsSubAssemblyFailure = (message) => {
	return {
		type: UPDATE_IMPLEMENTS_SUB_ASSEMBLY_FAILURE,
		message,
	};
};

export const updateImplementsSubAssembly = (id, data) => {
	return (dispatch) => {
		dispatch(requestUpdateImplementsSubAssembly());
		return implementsSubAssembliesApi
			.updateImplementsSubAssembly(id, data)
			.then((resp) => {
				const data = resp.data;
				return data;
			})
			.catch((err) => {
				const errorMessage = err.response && err.response.data.message;
				dispatch(updateImplementsSubAssemblyFailure(errorMessage));
				throw err;
			});
	};
};

export const requestGetAllImplementRelatedImplementsSubAssemblies = () => {
	return {
		type: GET_IMPLEMENT_RELATED_IMPLEMENTS_SUB_ASSEMBLIES_REQUEST,
	};
};
export const getAllImplementRelatedImplementsSubAssembliesSuccess = (data) => {
	return {
		type: GET_IMPLEMENT_RELATED_IMPLEMENTS_SUB_ASSEMBLIES_SUCCESS,
		data,
	};
};
export const getAllImplementRelatedImplementsSubAssembliesFailure = (message) => {
	return {
		type: GET_IMPLEMENT_RELATED_IMPLEMENTS_SUB_ASSEMBLIES_FAILURE,
		message,
	};
};

// Get All ImplementRelatedImplementsSubAssemblies
export const getAllImplementRelatedImplementsSubAssemblies = (data) => {
	return (dispatch) => {
		dispatch(requestGetAllImplementRelatedImplementsSubAssemblies());
		return implementsSubAssembliesApi
			.getImplementsRelatedImplementSubAssembly(data)
			.then((resp) => {
				const data = resp.data.data;
				dispatch(getAllImplementRelatedImplementsSubAssembliesSuccess(data));
			})
			.catch((err) => {
				const errorMessage =
					(err?.response && err?.response?.data?.message) || "Something went wrong!";
				dispatch(getAllImplementsSubAssembliesFailure(errorMessage));
			});
	};
};

export const GET_CATEGORIES_REQUEST = "GET_CATEGORIES_REQUEST",
	GET_CATEGORIES_SUCCESS = "GET_CATEGORIES_SUCCESS",
	GET_CATEGORIES_FAILURE = "GET_CATEGORIES_FAILURE";

export const GET_BRAND_CATEGORIES_REQUEST = "GET_BRAND_CATEGORIES_REQUEST",
	GET_BRAND_CATEGORIES_SUCCESS = "GET_BRAND_CATEGORIES_SUCCESS",
	GET_BRAND_CATEGORIES_FAILURE = "GET_BRAND_CATEGORIES_FAILURE";

export const GET_SINGLE_CATEGORY_REQUEST = "GET_SINGLE_CATEGORY_REQUEST",
	GET_SINGLE_CATEGORY_SUCCESS = "GET_SINGLE_CATEGORY_SUCCESS",
	GET_SINGLE_CATEGORY_FAILURE = "GET_SINGLE_CATEGORY_FAILURE";

export const SOFT_DELETE_SINGLE_CATEGORY_REQUEST = "SOFT_DELETE_SINGLE_CATEGORY_REQUEST",
	SOFT_DELETE_SINGLE_CATEGORY_SUCCESS = "SOFT_DELETE_SINGLE_CATEGORY_SUCCESS",
	SOFT_DELETE_SINGLE_CATEGORY_FAILURE = "SOFT_DELETE_SINGLE_CATEGORY_FAILURE";

export const RESTORE_SINGLE_CATEGORY_REQUEST = "RESTORE_SINGLE_CATEGORY_REQUEST",
	RESTORE_SINGLE_CATEGORY_SUCCESS = "RESTORE_SINGLE_CATEGORY_SUCCESS",
	RESTORE_SINGLE_CATEGORY_FAILURE = "RESTORE_SINGLE_CATEGORY_FAILURE";

export const ADD_CATEGORY_REQUEST = "ADD_CATEGORY_REQUEST",
	ADD_CATEGORY_SUCCESS = "ADD_CATEGORY_SUCCESS",
	ADD_CATEGORY_FAILURE = "ADD_CATEGORY_FAILURE";

export const UPDATE_CATEGORY_REQUEST = "UPDATE_CATEGORY_REQUEST",
	UPDATE_CATEGORY_SUCCESS = "UPDATE_CATEGORY_SUCCESS",
	UPDATE_CATEGORY_FAILURE = "UPDATE_CATEGORY_FAILURE";

import { getMultipleSubDistricts } from "../../services/place";

export const useIndianMultipleSubDistricts = (districtIds) => {
	const getIndianMultipleSubDistricts = async (districtIds) => {
		const data = await getMultipleSubDistricts(districtIds);
		return data;
	};

	return { getIndianMultipleSubDistricts };
};

import { HomeOutlined, MailOutlined, MobileOutlined, UserOutlined } from "@ant-design/icons";
import {
	Card,
	Col,
	Form,
	message,
	Row,
	Typography,
	Select,
	Space,
	Button,
	Radio,
	DatePicker,
} from "antd";
import { useState, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";

import { ErrorBoundary } from "react-error-boundary";
import BasicLayout from "../../components/BasicLayout";
import Center from "../../components/common/Center";
import FallBack from "../../components/common/FallBack/FallBack";
import InputField from "../../components/Form/InputField";
import { useIndianStates } from "../../components/Hooks/useIndianStates";
import { useIndianDistricts } from "../../components/Hooks/useIndianDistricts";
import { useIndianSubDistricts } from "../../components/Hooks/useIndianSubDistricts";
import { WordCountDisplayer } from "../../components/common/WordCountDisplayer";
import { NavLink, useHistory } from "react-router-dom";
import {
	getCustomerSingleContact,
	updateCustomerEnquiry,
	updateCustomerEnquiryFailure,
	updateCustomerEnquirySuccess,
} from "../../store/actions/customer/customer.action";
import Notification from "../../components/common/Notification";
import { getAllModels } from "../../store/actions/products/model/model.action";
import { getAllSpareParts } from "../../store/actions/products/spareparts/spareparts.action";
import { wordCount } from "../../utils/wordCount";
import { useLocation } from "react-router-dom";
import moment from "moment";
import { getAllAccessories } from "../../store/actions/products/Accessory/accessory.action";
import { getAllImplements } from "../../store/actions/products/implements/implements.action";
import { getAllSubAssemblies } from "../../store/actions/subAssembly/subAssembly.action";
import Spinner from "../../components/common/Spinner";

const EnquiryUpdateForm = (props) => {
	const { Title } = Typography;
	const [form] = Form.useForm();
	const modelIds = Form.useWatch("modelId", form);

	const { Option } = Select;
	const dispatch = useDispatch();
	const history = useHistory();

	const enquiryId = +props.match.params.id;
	const currentLocation = props.location.pathname;

	const handleError = (error) => {
		message.error(error.message);
	};
	const [districtList, setDistrictList] = useState([]);
	const [subDistrictList, setSubDistrictList] = useState([]);

	const [selectedStateId, setSelectedStateId] = useState("");
	const [selectedDistrictId, setSelectedDistrictId] = useState("");
	// eslint-disable-next-line no-unused-vars
	const [selectedSubDistrictId, setSelectedSubDistrictId] = useState("");
	const [isModelsLoading, setIsModelsLoading] = useState(false);
	const [isSparePartsLoading, setIsSparePartsLoading] = useState(false);
	const [isAccessoriesLoading, setIsAccessoriesLoading] = useState(false);
	const [isImplementsLoading, setIsImplementsLoading] = useState(false);
	const [isSubAssembliesLoading, setIsSubAssembliesLoading] = useState(false);

	const location = useLocation();

	const softdelete = new URLSearchParams(location.search).get("soft-delete");

	const enquiry = useSelector((state) => state?.customer?.enquiry);
	const model = useSelector((state) => state?.model?.models || []);

	const sparepart = useSelector((state) => state?.sparepart?.spareparts || []);
	const sparePartIds = Form.useWatch("sparepartId", form);

	const { getIndianStates } = useIndianStates();

	const { getIndianDistricts } = useIndianDistricts();
	const { getIndianSubDistricts } = useIndianSubDistricts();

	const onChangeState = (value) => {
		setSelectedStateId(value);
		setSelectedDistrictId("");
		setSelectedSubDistrictId("");
	};

	const accessories = useSelector((state) => state.accessory.accessories || []);
	const accesssoryIds = Form.useWatch("accessoryId", form);

	const selectedAccessoryData = useMemo(() => {
		if (accesssoryIds?.length > 0 && accessories) {
			return accessories?.filter((singleAccessory) => {
				return accesssoryIds?.includes(singleAccessory?.id?.toString());
			});
		}
	}, [accesssoryIds, accessories]);

	const multipleImplements = useSelector((state) => state.implements.multipleImplements);

	const implementIds = Form.useWatch("implementId", form);

	const selectedImplementData = useMemo(() => {
		if (implementIds?.length > 0 && multipleImplements) {
			return multipleImplements?.filter((singleImplement) => {
				return implementIds?.includes(singleImplement?.id?.toString());
			});
		}
	}, [implementIds, multipleImplements]);

	const subAssemblies = useSelector((state) => state.subAssembly.subAssemblies || []);

	const subAssemblyIds = Form.useWatch("subAssemblyId", form);

	const selectedSubAssemblyData = useMemo(() => {
		if (subAssemblyIds?.length > 0 && subAssemblies) {
			return subAssemblies?.filter((singleSubAssembly) => {
				return subAssemblyIds?.includes(singleSubAssembly?.id?.toString());
			});
		}
	}, [subAssemblyIds, subAssemblies]);

	const AccessoryChildren = [];
	for (let i = 0; i < accessories?.length; i++) {
		AccessoryChildren.push(
			<Option key={accessories[i]?.id} title={accessories[i]?.name}>
				{accessories[i]?.name} ({accessories[i]?.code})
			</Option>
		);
	}

	const Implementchildren = [];

	for (let i = 0; i < multipleImplements?.length; i++) {
		Implementchildren?.push(
			<Option key={multipleImplements[i]?.id} title={multipleImplements[i]?.name}>
				{multipleImplements[i]?.name} ({multipleImplements[i]?.code})
			</Option>
		);
	}

	const SubAssemblyChildren = [];
	for (let i = 0; i < subAssemblies?.length; i++) {
		SubAssemblyChildren.push(
			<Option key={subAssemblies[i]?.id} title={subAssemblies[i]?.name}>
				{subAssemblies[i]?.name} ({subAssemblies[i]?.code})
			</Option>
		);
	}

	const [gstSelectionValue, setgstSelectionValue] = useState();

	const onGSTRadioChange = (e) => {
		setgstSelectionValue(e.target.value);
	};

	useEffect(() => {
		setgstSelectionValue(enquiry.isCustomerOutOfState);
	}, [enquiry]);

	const [value, setValue] = useState();

	const onRadioChange = (e) => {
		setValue(e.target.value);
	};

	useEffect(() => {
		if (currentLocation !== "/customer/admin/enquiry/add") {
			setValue(enquiry?.gstnumber ? 1 : 2);
		} else {
			setValue();
		}
	}, [enquiry?.gstnumber, currentLocation]);

	useEffect(() => {
		async function fetchData() {
			setIsSparePartsLoading(true);
			dispatch(getAllSpareParts());
			setIsSparePartsLoading(false);
		}
		fetchData();
	}, [dispatch]);

	useEffect(() => {
		async function fetchData() {
			setIsModelsLoading(true);
			dispatch(getAllModels());
			setIsModelsLoading(false);
		}
		fetchData();
	}, [dispatch]);

	useEffect(() => {
		async function fetchData() {
			setIsAccessoriesLoading(true);
			await dispatch(getAllAccessories());
			setIsAccessoriesLoading(false);
		}
		fetchData();
	}, [dispatch]);

	useEffect(() => {
		async function fetchData() {
			setIsImplementsLoading(true);
			await dispatch(getAllImplements());
			setIsImplementsLoading(false);
		}
		fetchData();
	}, [dispatch]);

	useEffect(() => {
		async function fetchData() {
			setIsSubAssembliesLoading(true);
			await dispatch(getAllSubAssemblies());
			setIsSubAssembliesLoading(false);
		}
		fetchData();
	}, [dispatch]);

	useEffect(() => {
		if (selectedStateId) {
			getIndianDistricts(selectedStateId)
				.then((resp) => {
					setDistrictList(resp);
				})
				.catch((err) => {
					return "wrong";
				});
		}
		if (selectedDistrictId) {
			getIndianSubDistricts(selectedDistrictId)
				.then((resp) => {
					setSubDistrictList(resp);
				})
				.catch((err) => {
					return "wrong";
				});
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [selectedStateId, selectedDistrictId]);

	const onChangeDistrict = (value) => setSelectedDistrictId(value);
	const onChangeSubDistrict = (value) => setSelectedSubDistrictId(value);

	const handleCancel = () => {
		Notification("info", "Job action has been cancelled", "", "topRight");
	};
	useEffect(() => {
		if (enquiryId) {
			dispatch(getCustomerSingleContact(enquiryId, softdelete)).catch((err) => {
				Notification("error", err, "", "topRight");
				history.push("/customer/enquiry");
			});
		}
	}, [enquiryId, history, softdelete, dispatch]);

	const onFinish = async (values) => {
		try {
			let modelDetailed = [];
			modelIds?.forEach((item) => {
				const modelName = model?.filter((data) => data?.id?.toString() === item.toString())[0];

				let tmp = {
					modelId: item,
					modelName: modelName?.primaryCategory?.name,
					modelNumber: modelName?.name,
					modelHSNCode: modelName?.hsnCode?.code ? modelName?.hsnCode?.code : "NA",
					modelIGST: modelName?.hsnCode?.igst ? modelName?.hsnCode?.igst : 0,
					modelCGST: modelName?.hsnCode?.cgst ? modelName?.hsnCode?.cgst : 0,
					modelSGST: modelName?.hsnCode?.hgst ? modelName?.hsnCode?.hgst : 0,
					modelStock: values[`modelStock${item}`],
					modelPrice: values[`modelPrice${item}`],
				};
				delete values[`modelStock${item}`];
				delete values[`modelPrice${item}`];
				modelDetailed.push(tmp);
			});
			if (modelDetailed?.length > 0) {
				values.modelDetailed = modelDetailed;
			}

			let sparepartDetailed = [];
			sparePartIds?.forEach((item1) => {
				const sparepartName = sparepart?.filter(
					(data) => data?.id?.toString() === item1.toString()
				)[0];

				let tmp = {
					sparepartId: item1,
					sparepartName: sparepartName?.name,
					sparepartHSNCode: sparepartName?.hsnCode?.code
						? sparepartName?.hsnCode?.code
						: "NA",
					sparepartIGST: sparepartName?.hsnCode?.igst ? sparepartName?.hsnCode?.igst : 0,
					sparepartCGST: sparepartName?.hsnCode?.cgst ? sparepartName?.hsnCode?.cgst : 0,
					sparepartSGST: sparepartName?.hsnCode?.hgst ? sparepartName?.hsnCode?.hgst : 0,
					sparepartStock: values[`sparepartStock${item1}`],
					sparepartPrice: values[`sparepartPrice${item1}`],
				};
				delete values[`sparepartStock${item1}`];
				delete values[`sparepartPrice${item1}`];
				sparepartDetailed.push(tmp);
			});

			if (sparepartDetailed?.length > 0) {
				values.sparepartDetailed = sparepartDetailed;
			}

			let accessoryDetailed = [];
			accesssoryIds?.forEach((item1) => {
				const accessoryName = accessories?.filter(
					(data) => data?.id?.toString() === item1?.toString()
				)[0];

				let tmp = {
					accessoryId: item1,
					accessoryName: accessoryName?.name,
					accessoryHSNCode: accessoryName?.hsnCode?.code
						? accessoryName?.hsnCode?.code
						: "NA",
					accessoryIGST: accessoryName?.hsnCode?.igst ? accessoryName?.hsnCode?.igst : 0,
					accessoryCGST: accessoryName?.hsnCode?.cgst ? accessoryName?.hsnCode?.cgst : 0,
					accessorySGST: accessoryName?.hsnCode?.hgst ? accessoryName?.hsnCode?.hgst : 0,
					accessoryStock: values[`accessoryStock${item1}`],
					accessoryPrice: values[`accessoryPrice${item1}`],
				};
				delete values[`accessoryStock${item1}`];
				delete values[`accessoryPrice${item1}`];
				accessoryDetailed.push(tmp);
			});

			if (accessoryDetailed?.length > 0) {
				values.accessoryDetailed = accessoryDetailed;
			}

			let implementDetailed = [];

			implementIds?.forEach((item1) => {
				const implementName = multipleImplements?.filter(
					(data) => data?.id?.toString() === item1?.toString()
				)[0];

				let tmp = {
					implementId: item1,
					implementName: implementName?.name,
					implementHSNCode: implementName?.hsnCode?.code
						? implementName?.hsnCode?.code
						: "NA",
					implementIGST: implementName?.hsnCode?.igst ? implementName?.hsnCode?.igst : 0,
					implementCGST: implementName?.hsnCode?.cgst ? implementName?.hsnCode?.cgst : 0,
					implementSGST: implementName?.hsnCode?.hgst ? implementName?.hsnCode?.hgst : 0,
					implementStock: values[`implementStock${item1}`],
					implementPrice: values[`implementPrice${item1}`],
				};
				delete values[`implementStock${item1}`];
				delete values[`implementPrice${item1}`];
				implementDetailed?.push(tmp);
			});

			if (implementDetailed?.length > 0) {
				values.implementDetailed = implementDetailed;
			}

			let subAssemblyDetailed = [];

			subAssemblyIds?.forEach((item1) => {
				const subAssemblyName = subAssemblies?.filter(
					(data) => data?.id?.toString() === item1?.toString()
				)[0];

				let tmp = {
					subAssemblyId: item1,
					subAssemblyName: subAssemblyName?.name,
					subAssemblyHSNCode: subAssemblyName?.hsnCode?.code
						? subAssemblyName?.hsnCode?.code
						: "NA",
					subAssemblyIGST: subAssemblyName?.hsnCode?.igst ? subAssemblyName?.hsnCode?.igst : 0,
					subAssemblyCGST: subAssemblyName?.hsnCode?.cgst ? subAssemblyName?.hsnCode?.cgst : 0,
					subAssemblySGST: subAssemblyName?.hsnCode?.hgst ? subAssemblyName?.hsnCode?.hgst : 0,
					subAssemblyStock: values[`subAssemblyStock${item1}`],
					subAssemblyPrice: values[`subAssemblyPrice${item1}`],
				};
				delete values[`subAssemblyStock${item1}`];
				delete values[`subAssemblyPrice${item1}`];
				subAssemblyDetailed?.push(tmp);
			});

			if (subAssemblyDetailed?.length > 0) {
				values.subAssemblyDetailed = subAssemblyDetailed;
			}

			if (values?.state === "" || values?.state === undefined) {
				return Notification("error", "Please Select State", "", "topRight");
			}

			// eslint-disable-next-line array-callback-return
			const state = getIndianStates()?.filter((data) => {
				if (data["State Code"] === values?.state) {
					return true;
				} else if (data["State Name(In English)"] === values?.state) {
					return true;
				}
			});

			const stateName = state.length > 0 ? state[0]["State Name(In English)"] : values?.state;

			if (values.district === "" || values?.district === undefined) {
				return Notification("error", "Please Select district", "", "topRight");
			}

			// eslint-disable-next-line array-callback-return
			const districtArr = districtList?.filter((data) => {
				if (data["District Code"] === values?.district) {
					return true;
				} else if (data["District Name(In English)"] === values?.district) {
					return true;
				}
			});

			const districtName =
				districtArr.length > 0 ? districtArr[0]["District Name(In English)"] : values?.district;

			if (values.taluka === "" || values?.taluka === undefined) {
				return Notification("error", "Please Select Taluka", "", "topRight");
			}

			const subDistrictArr = subDistrictList?.filter(
				(data) => data["Sub District Code"] === values?.taluka
			);

			const subDistrictName =
				subDistrictArr.length > 0 ? subDistrictArr[0]["Sub District Name"] : values?.taluka;

			let tempgst = values?.gstnumber;
			if (tempgst) {
				tempgst = tempgst?.replace(/\s/g, "");
				if (tempgst.length !== 15) {
					Notification("error", "Remove Space Between GST", "", "topRight");
					return;
				}
			}

			if (value === 2) {
				values = { ...values, gstnumber: null };
			} else {
				values = { ...values, gstnumber: tempgst };
			}

			await wordCount(values.updateReason, 10);

			const enquiry = {
				...values,
				state: stateName,
				district: districtName,
				taluka: subDistrictName,

				hasAccepted: true,
			};

			const resp = await dispatch(updateCustomerEnquiry(enquiryId, enquiry, history));
			await dispatch(updateCustomerEnquirySuccess(resp));
			history.push("/customer/enquiry");
			const message = resp.message || "Updated Successfully";
			return Notification("success", message, "", "topRight");
		} catch (err) {
			const errorMessage =
				(err?.response && err?.response?.data?.message) || "Something went wrong";
			dispatch(updateCustomerEnquiryFailure(errorMessage));
			return Notification("error", "Error Occurred", errorMessage, "topRight");
		}
	};

	const modelchildren = [];
	for (let i = 0; i < model?.length; i++) {
		modelchildren.push(
			<Option key={model[i]?.id} title={model[i]?.name}>
				{model[i]?.name} &#40;{model[i].productNumber}&#41;
			</Option>
		);
	}

	const sparepartchildern = [];
	for (let i = 0; i < sparepart?.length; i++) {
		sparepartchildern.push(
			<Option key={sparepart[i]?.id} title={sparepart[i]?.name}>
				{sparepart[i]?.name}&#40;{sparepart[i]?.sparePartNumber}&#41;
			</Option>
		);
	}

	let defaultMultipleSparePartsIds = useMemo(
		() => enquiry?.spareParts?.map((data) => data.id.toString()),
		[enquiry]
	);

	let defaultMultipleModelsIds = useMemo(
		() => enquiry?.models?.map((data) => data.id.toString()),
		[enquiry]
	);

	let defaultMultipleAccessoriesIds = useMemo(
		() => enquiry?.accessories?.map((data) => data.id.toString()),
		[enquiry]
	);

	let defaultMultipleImplementIds = useMemo(
		() => enquiry?.implements?.map((data) => data.id.toString()),
		[enquiry]
	);

	let defaultMultipleSubAssembliesIds = useMemo(
		() => enquiry?.subAssemblies?.map((data) => data.id.toString()),
		[enquiry]
	);

	const selectedModelData = useMemo(() => {
		if (modelIds?.length > 0 && model) {
			return model?.filter((singleModel) => {
				return modelIds?.includes(singleModel?.id?.toString());
			});
		}
	}, [modelIds, model]);

	const selectedSparePartData = useMemo(() => {
		if (sparePartIds?.length > 0 && sparepart) {
			return sparepart?.filter((singleSparePart) => {
				return sparePartIds?.includes(singleSparePart?.id?.toString());
			});
		}
	}, [sparePartIds, sparepart]);

	const defaultValues = useMemo(() => {
		let modelStockAndPricing = {};
		let sparePartStockAndPricing = {};
		let accessoryStockAndPricing = {};
		let implementStockAndPricing = {};
		let suAssemblyStockAndPricing = {};

		if (enquiry) {
			enquiry?.modelDetailed?.forEach((model) => {
				modelStockAndPricing[`modelPrice${model?.modelId}`] = model?.modelPrice;
				modelStockAndPricing[`modelStock${model?.modelId}`] = model?.modelStock;
				});

			enquiry?.sparepartDetailed?.forEach((sparepart) => {
				sparePartStockAndPricing[`sparepartPrice${sparepart?.sparepartId}`] =
					sparepart?.sparepartPrice;
				sparePartStockAndPricing[`sparepartStock${sparepart?.sparepartId}`] =
					sparepart?.sparepartStock;
				});

			enquiry?.accessoryDetailed?.forEach((accessory) => {
				accessoryStockAndPricing[`accessoryPrice${accessory?.accessoryId}`] =
					accessory?.accessoryPrice;
				accessoryStockAndPricing[`accessoryStock${accessory?.accessoryId}`] =
					accessory?.accessoryStock;
					});

			enquiry?.implementDetailed?.forEach((implement) => {
				implementStockAndPricing[`implementPrice${implement?.implementId}`] =
					implement?.implementPrice;
				implementStockAndPricing[`implementStock${implement?.implementId}`] =
					implement?.implementStock;
			});

			enquiry?.subAssemblyDetailed?.forEach((subAssembly) => {
				suAssemblyStockAndPricing[`subAssemblyPrice${subAssembly?.subAssemblyId}`] =
					subAssembly?.subAssemblyPrice;
				suAssemblyStockAndPricing[`subAssemblyStock${subAssembly?.subAssemblyId}`] =
					subAssembly?.subAssemblyStock;
				});
		}

		let tmp = {
			name: enquiryId ? enquiry.name : "",
			email: enquiryId ? enquiry.email : "",
			phonenumber: enquiryId ? enquiry.phonenumber : undefined,
			address: enquiryId ? enquiry.address : "",
			state: enquiryId ? enquiry.state : "",
			district: enquiryId ? enquiry.district : "",
			taluka: enquiryId ? enquiry.taluka : "",
			village: enquiryId ? enquiry.village : "",
			gstnumber: enquiryId ? enquiry.gstnumber : "",
			isCustomerOutOfState: enquiryId ? enquiry.isCustomerOutOfState : "",

			modelId: enquiryId ? defaultMultipleModelsIds : undefined,
			...modelStockAndPricing,
			sparepartId: enquiryId ? defaultMultipleSparePartsIds : undefined,
			...sparePartStockAndPricing,
			accessoryId: enquiryId ? defaultMultipleAccessoriesIds : undefined,
			...accessoryStockAndPricing,
			implementId: enquiryId ? defaultMultipleImplementIds : undefined,
			...implementStockAndPricing,

			subAssemblyId: enquiryId ? defaultMultipleSubAssembliesIds : undefined,
			...suAssemblyStockAndPricing,

			finance: enquiryId ? enquiry.finance : "",
			subsidy: enquiryId ? enquiry.subsidy : "",
			description: enquiryId ? enquiry.description : "",
		};
		return tmp;
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [
		enquiryId,
		enquiry.name,
		enquiry.email,
		enquiry.phonenumber,
		enquiry.address,
		enquiry.state,
		enquiry.district,
		enquiry.taluka,
		enquiry.village,
		enquiry.gstnumber,
		enquiry.isCustomerOutOfState,
		defaultMultipleModelsIds,
		defaultMultipleSparePartsIds,
		defaultMultipleAccessoriesIds,
		defaultMultipleSubAssembliesIds,
		defaultMultipleImplementIds,
		enquiry.finance,
		enquiry.subsidy,
		enquiry?.description,
		enquiry?.modelDetailed,
		enquiry?.sparepartDetailed,
		enquiry?.accessoryDetailed,
		enquiry?.implementDetailed,
		enquiry?.subAssemblyDetailed,
	]);

	useEffect(() => {
		form.setFieldsValue(defaultValues);
	}, [form, defaultValues]);

	const dateFormat = "YYYY/MM/DD";

	if (
		isImplementsLoading ||
		isSubAssembliesLoading ||
		isAccessoriesLoading ||
		isModelsLoading ||
		isSparePartsLoading
	) {
		return (
			<Center>
				<Spinner />
			</Center>
		);
	}

	return (
		<BasicLayout accessLevel={props.accessLevel}>
			<ErrorBoundary FallbackComponent={FallBack} onError={handleError}>
				<Row type="flex" justify="center" align="middle" id="form-container">
					<Center height="4rem">
						<Title level={2} className="update-or-add-title">
							Update Enquiry
						</Title>
					</Center>
					<Col>
						<Card className="form-card">
							<Form form={form} name="basic" layout="vertical" onFinish={onFinish}>
								<InputField
									rules={[
										{
											required: true,
										},
										{
											min: 1,
											message: "Customer Name be minimum 1 charachter.",
										},
										{
											max: 28,
											message: "Customer Name be minimum 28 charachter.",
										},
									]}
									label="Customer Name"
									name="name"
									placeholder="Please enter Enquiry Name"
									prefix={<UserOutlined />}
								/>
								<InputField
									rules={[
										{
											required: true,
										},
										{
											min: 10,
											message: "Enter valid Email Id.",
										},
										{
											max: 40,
											message: "Enter valid Email Id",
										},
									]}
									label=" Customer Email "
									name="email"
									placeholder="Please Enter Email Address"
									prefix={<MailOutlined />}
								/>
								<InputField
									rules={[
										{
											required: true,
										},
										{
											min: 10,
											message: "Enter 10 digit number.",
										},
										{
											max: 10,
											message: "Enter 10 digit number.",
										},
									]}
									label="Customer Contact Number"
									type="number"
									name="phonenumber"
									placeholder="Please Enter Customer Contact Number"
									prefix={<MobileOutlined />}
								/>
								<InputField
									label="Customer Address"
									name="address"
									placeholder="Please Enter Address"
									prefix={<HomeOutlined />}
								/>
								<Form.Item label="Select State" name="state">
									<Select
										style={{ width: "100%" }}
										onChange={onChangeState}
										placeholder="Please select state"
										showSearch
										filterOption={(input, option) => {
											return option?.children?.toLowerCase().indexOf(input?.toLowerCase()) >= 0;
										}}
									>
										{getIndianStates().map((state, index) => (
											<Option value={state["State Code"]} key={index}>
												{state["State Name(In English)"]}
											</Option>
										))}
									</Select>
								</Form.Item>
								<Form.Item label="Select District" name="district">
									<Select
										placeholder={
											selectedStateId ? "Please select a District" : "Please select a State first"
										}
										onChange={onChangeDistrict}
										style={{ width: "100%" }}
										showSearch
										filterOption={(input, option) => {
											return option?.children?.toLowerCase().indexOf(input?.toLowerCase()) >= 0;
										}}
									>
										{selectedStateId &&
											districtList.map((district, index) => (
												<Option value={district["District Code"]} key={index}>
													{district["District Name(In English)"]}
												</Option>
											))}
									</Select>
								</Form.Item>
								<Form.Item label="Select Taluka" name="taluka">
									<Select
										onChange={onChangeSubDistrict}
										style={{ width: "100%" }}
										placeholder={
											selectedDistrictId
												? "Please select a Sub-District"
												: "Please select a District first"
										}
										showSearch
										filterOption={(input, option) => {
											return option?.children?.toLowerCase().indexOf(input?.toLowerCase()) >= 0;
										}}
									>
										{selectedDistrictId &&
											subDistrictList?.map((district, index) => (
												<Option value={district["Sub District Code"]} key={index}>
													{district["Sub District Name"]}
												</Option>
											))}
									</Select>
								</Form.Item>
								<InputField
									rules={[
										{
											required: true,
										},
										{
											min: 1,
											message: "Village/City Name be minimum 1 charachter.",
										},
										{
											max: 28,
											message: "Village/City Name be minimum 28 charachter.",
										},
									]}
									label="City/Village"
									name="village"
									placeholder="Enter your City/Village Name"
									prefix={<HomeOutlined />}
								/>
								<Title
									level={5}
									style={{
										fontWeight: "400",
										fontSize: "18px",
										marginTop: "25px",
										marginBottom: "20px",
									}}
								>
									<span
										style={{
											color: "#f08080",
											fontWeight: "thin",
											fontSize: "16px",
										}}
									>
										*
									</span>
									GST Number is Available?
								</Title>
								<Radio.Group
									defaultValue={null}
									onChange={onRadioChange}
									value={value}
									style={{
										marginBottom: "20px",
									}}
								>
									<Radio value={1}>Yes</Radio>
									<Radio value={2}>No</Radio>
								</Radio.Group>

								{value === 1 && (
									<InputField
										rules={[
											{
												min: 15,
												message: "Gst Number minimum 1 charachter.",
											},
											{
												max: 15,
												message: "Gst Number maximum 15 charachter.",
											},
										]}
										label="Gst Number "
										name="gstnumber"
										placeholder="Enter Your Gst Number "
										prefix={<UserOutlined />}
									/>
								)}
								<Form.Item label="Customer is from another state" name="isCustomerOutOfState">
									<Radio.Group
										defaultValue={null}
										onChange={onGSTRadioChange}
										value={gstSelectionValue}
									>
										<Radio value={"yes"}>Yes</Radio>
										<Radio value={"no"}>No</Radio>
									</Radio.Group>
								</Form.Item>
								<Form.Item name="modelId" label="Select Model">
									<Select
										mode="multiple"
										allowClear
										style={{ width: "100%" }}
										placeholder="Please select models"
										filterOption={(input, option) => {
											return (
												option.key.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
												option.title.toLowerCase().indexOf(input.toLowerCase()) >= 0
											);
										}}
									>
										{modelchildren}
									</Select>
								</Form.Item>
								<div
									style={{
										display: "flex",
										maxHeight: "400px",
										overflowY: "scroll",
										flexWrap: "wrap",
										justifyContent: "flex-start",
										borderTop: "1px solid  #f0f0f0",
										borderBottom: "1px solid #f0f0f0",
										marginBottom: "10px",
									}}
								>
									{selectedModelData &&
										selectedModelData?.length > 0 &&
										selectedModelData?.map((singleModel) => {
											return (
												<>
													<InputField
														rules={[
															{
																required: true,
																message: "Model Price Required",
															},
															{
																type: "number",
																min: 1,
															},
														]}
														inputType="number"
														label={`Price for ${singleModel.name}`}
														name={`modelPrice${singleModel?.id}`}
														prefix={<UserOutlined />}
														style={{ marginRight: "20px", width: "241px" }}
													/>
													<InputField
														rules={[
															{
																required: true,
																message: "Model Stock Required",
															},

															{
																type: "number",
																min: 1,
															},
														]}
														inputType="number"
														label="Stock"
														name={`modelStock${singleModel?.id}`}
														prefix={<UserOutlined />}
														style={{ marginRight: "20px", width: "241px" }}
													/>
													{gstSelectionValue === "yes" && (
														<InputField
															disabled={true}
															inputType="number"
															defaultValue={` ${
																singleModel?.hsnCode?.igst ? singleModel?.hsnCode?.igst : "0"
															} `}
															label="IGST in %"
															name={`modelIGST${singleModel?.id}`}
															style={{ marginRight: "20px", width: "241px" }}
														/>
													)}
													{gstSelectionValue === "no" && (
														<>
															<InputField
																disabled={true}
																inputType="number"
																defaultValue={` ${
																	singleModel?.hsnCode?.hgst ? singleModel?.hsnCode?.hgst : "0"
																} `}
																label="SGST  in %"
																name={`modelSGST${singleModel?.id}`}
																style={{ marginRight: "20px", width: "241px" }}
															/>
															<InputField
																disabled={true}
																inputType="number"
																defaultValue={` ${
																	singleModel?.hsnCode?.cgst ? singleModel?.hsnCode?.cgst : "0"
																} `}
																label="CGST in %"
																name={`modelCGST${singleModel?.id}`}
																style={{ marginRight: "20px", width: "241px" }}
															/>
														</>
													)}
												</>
											);
										})}
								</div>
								<Form.Item name="sparepartId" label="Select Spare Parts">
									<Select
										mode="multiple"
										allowClear
										style={{ width: "100%" }}
										placeholder="Please select Spare Parts"
										filterOption={(input, option) => {
											return (
												option.key.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
												option.title.toLowerCase().indexOf(input.toLowerCase()) >= 0
											);
										}}
									>
										{sparepartchildern}
									</Select>
								</Form.Item>
								<div
									style={{
										display: "flex",
										maxHeight: "400px",
										overflowY: "scroll",
										flexWrap: "wrap",
										justifyContent: "flex-start",
										borderTop: "1px solid  #f0f0f0",
										borderBottom: "1px solid #f0f0f0",
										marginBottom: "10px",
									}}
								>
									{selectedSparePartData &&
										selectedSparePartData?.length > 0 &&
										selectedSparePartData?.map((singleSparePart) => {
											return (
												<>
													<InputField
														rules={[
															{
																required: true,
																message: "Spare part Price Required",
															},
															{
																type: "number",
																min: 1,
															},
														]}
														inputType="number"
														label={`Price for ${singleSparePart.name}`}
														name={`sparepartPrice${singleSparePart?.id}`}
														prefix={<UserOutlined />}
														style={{ marginRight: "20px", width: "241px" }}
													/>
													<InputField
														rules={[
															{
																required: true,
																message: "Spare part Stock Required",
															},

															{
																type: "number",
																min: 1,
															},
														]}
														inputType="number"
														label="Stock"
														name={`sparepartStock${singleSparePart?.id}`}
														prefix={<UserOutlined />}
														style={{ marginRight: "20px", width: "241px" }}
													/>

													{gstSelectionValue === "yes" && (
														<InputField
															disabled={true}
															inputType="number"
															defaultValue={` ${
																singleSparePart?.hsnCode?.igst
																	? singleSparePart?.hsnCode?.igst
																	: "0"
															} `}
															label="IGST in %"
															name={`sparepartIGST${singleSparePart?.id}`}
															style={{ marginRight: "20px", width: "241px" }}
														/>
													)}
													{gstSelectionValue === "no" && (
														<>
															<InputField
																disabled={true}
																inputType="number"
																defaultValue={` ${
																	singleSparePart?.hsnCode?.hgst
																		? singleSparePart?.hsnCode?.hgst
																		: "0"
																} `}
																label="SGST in %"
																name={`sparepartSGST${singleSparePart?.id}`}
																style={{ marginRight: "20px", width: "241px" }}
															/>
															<InputField
																disabled={true}
																inputType="number"
																defaultValue={` ${
																	singleSparePart?.hsnCode?.cgst
																		? singleSparePart?.hsnCode?.cgst
																		: "0"
																} `}
																label="CGST in %"
																name={`sparepartCGST${singleSparePart?.id}`}
																style={{ marginRight: "20px", width: "241px" }}
															/>
														</>
													)}
												</>
											);
										})}
								</div>
								<Form.Item name="accessoryId" label="Select Accessories">
									<Select
										mode="multiple"
										allowClear
										style={{ width: "100%" }}
										placeholder="Please select Accessories"
										filterOption={(input, option) => {
											return (
												option.key.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
												option.title.toLowerCase().indexOf(input.toLowerCase()) >= 0
											);
										}}
									>
										{AccessoryChildren}
									</Select>
								</Form.Item>
								<div
									style={{
										display: "flex",
										maxHeight: "400px",
										overflowY: "scroll",
										flexWrap: "wrap",
										justifyContent: "flex-start",
										borderTop: "1px solid  #f0f0f0",
										borderBottom: "1px solid #f0f0f0",
										marginBottom: "10px",
									}}
								>
									{selectedAccessoryData &&
										selectedAccessoryData?.length > 0 &&
										selectedAccessoryData?.map((singleAccessory) => {
											return (
												<>
													<InputField
														rules={[
															{
																required: true,
																message: "Accessory Price Required",
															},
															{
																type: "number",
																min: 1,
															},
														]}
														inputType="number"
														label={`Price for ${singleAccessory.name}`}
														name={`accessoryPrice${singleAccessory?.id}`}
														prefix={<UserOutlined />}
														style={{ marginRight: "20px", width: "241px" }}
													/>
													<InputField
														rules={[
															{
																required: true,
																message: "Accessory Stock Required",
															},

															{
																type: "number",
																min: 1,
															},
														]}
														inputType="number"
														label="Stock"
														name={`accessoryStock${singleAccessory?.id}`}
														prefix={<UserOutlined />}
														style={{ marginRight: "20px", width: "241px" }}
													/>
													{gstSelectionValue === "yes" && (
														<InputField
															disabled={true}
															inputType="number"
															defaultValue={` ${
																singleAccessory?.hsnCode?.igst
																	? singleAccessory?.hsnCode?.igst
																	: "0"
															} `}
															label="IGST in %"
															name={`accessoryIGST${singleAccessory?.id}`}
															style={{ marginRight: "20px", width: "241px" }}
														/>
													)}
													{gstSelectionValue === "no" && (
														<>
															<InputField
																disabled={true}
																inputType="number"
																defaultValue={` ${
																	singleAccessory?.hsnCode?.hgst
																		? singleAccessory?.hsnCode?.hgst
																		: "0"
																} `}
																label="SGST in %"
																name={`accessorySGST${singleAccessory?.id}`}
																style={{ marginRight: "20px", width: "241px" }}
															/>
															<InputField
																disabled={true}
																inputType="number"
																defaultValue={` ${
																	singleAccessory?.hsnCode?.cgst
																		? singleAccessory?.hsnCode?.cgst
																		: "0"
																} `}
																label="CGST in %"
																name={`accessoryCGST${singleAccessory?.id}`}
																style={{ marginRight: "20px", width: "241px" }}
															/>
														</>
													)}
												</>
											);
										})}
								</div>
								<Form.Item name="implementId" label="Select Implements">
									<Select
										mode="multiple"
										allowClear
										style={{ width: "100%" }}
										placeholder="Please select Implements"
										filterOption={(input, option) => {
											return (
												option.key.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
												option.title.toLowerCase().indexOf(input.toLowerCase()) >= 0
											);
										}}
									>
										{Implementchildren}
									</Select>
								</Form.Item>
								<div
									style={{
										display: "flex",
										maxHeight: "400px",
										overflowY: "scroll",
										flexWrap: "wrap",
										justifyContent: "flex-start",
										borderTop: "1px solid  #f0f0f0",
										borderBottom: "1px solid #f0f0f0",
										marginBottom: "10px",
									}}
								>
									{selectedImplementData &&
										selectedImplementData?.length > 0 &&
										selectedImplementData?.map((singleImplement) => {
											return (
												<>
													<InputField
														rules={[
															{
																required: true,
																message: "Implement Price Required",
															},
															{
																type: "number",
																min: 1,
															},
														]}
														inputType="number"
														label={`Price for ${singleImplement?.name}`}
														name={`implementPrice${singleImplement?.id}`}
														prefix={<UserOutlined />}
														style={{ marginRight: "20px", width: "241px" }}
													/>
													<InputField
														rules={[
															{
																required: true,
																message: "Implement Stock Required",
															},

															{
																type: "number",
																min: 1,
															},
														]}
														inputType="number"
														label="Stock"
														name={`implementStock${singleImplement?.id}`}
														prefix={<UserOutlined />}
														style={{ marginRight: "20px", width: "241px" }}
													/>
													{gstSelectionValue === "yes" && (
														<InputField
															disabled={true}
															inputType="number"
															defaultValue={` ${
																singleImplement?.hsnCode?.igst
																	? singleImplement?.hsnCode?.igst
																	: "0"
															} `}
															label="IGST in %"
															name={`implementIGST${singleImplement?.id}`}
															style={{ marginRight: "20px", width: "241px" }}
														/>
													)}
													{gstSelectionValue === "no" && (
														<>
															<InputField
																disabled={true}
																inputType="number"
																defaultValue={` ${
																	singleImplement?.hsnCode?.hgst
																		? singleImplement?.hsnCode?.hgst
																		: "0"
																} `}
																label="SGST in %"
																name={`implementSGST${singleImplement?.id}`}
																style={{ marginRight: "20px", width: "241px" }}
															/>
															<InputField
																disabled={true}
																inputType="number"
																defaultValue={` ${
																	singleImplement?.hsnCode?.cgst
																		? singleImplement?.hsnCode?.cgst
																		: "0"
																} `}
																label="CGST in %"
																name={`implementCGST${singleImplement?.id}`}
																style={{ marginRight: "20px", width: "241px" }}
															/>
														</>
													)}
												</>
											);
										})}
								</div>
								<Form.Item name="subAssemblyId" label="Select Sub Assemblies">
									<Select
										mode="multiple"
										allowClear
										style={{ width: "100%" }}
										placeholder="Please select Sub Assemblies"
										filterOption={(input, option) => {
											return (
												option?.key?.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
												option?.title?.toLowerCase().indexOf(input.toLowerCase()) >= 0
											);
										}}
									>
										{SubAssemblyChildren}
									</Select>
								</Form.Item>
								<div
									style={{
										display: "flex",
										maxHeight: "400px",
										overflowY: "scroll",
										flexWrap: "wrap",
										justifyContent: "flex-start",
										borderTop: "1px solid  #f0f0f0",
										borderBottom: "1px solid #f0f0f0",
										marginBottom: "10px",
									}}
								>
									{selectedSubAssemblyData &&
										selectedSubAssemblyData?.length > 0 &&
										selectedSubAssemblyData?.map((singleSubAssembly) => {
											return (
												<>
													<InputField
														rules={[
															{
																required: true,
																message: "Sub Assembly Price Required",
															},
															{
																type: "number",
																min: 1,
															},
														]}
														inputType="number"
														label={`Price for ${singleSubAssembly.name}`}
														name={`subAssemblyPrice${singleSubAssembly?.id}`}
														prefix={<UserOutlined />}
														style={{ marginRight: "20px", width: "241px" }}
													/>
													<InputField
														rules={[
															{
																required: true,
																message: "Sub Assembly Stock Required",
															},

															{
																type: "number",
																min: 1,
															},
														]}
														inputType="number"
														label="Stock"
														name={`subAssemblyStock${singleSubAssembly?.id}`}
														prefix={<UserOutlined />}
														style={{ marginRight: "20px", width: "241px" }}
													/>
													{gstSelectionValue === "yes" && (
														<InputField
															disabled={true}
															inputType="number"
															defaultValue={` ${
																singleSubAssembly?.hsnCode?.igst
																	? singleSubAssembly?.hsnCode?.igst
																	: "0"
															} `}
															label="IGST in %"
															name={`subAssemblyIGST${singleSubAssembly?.id}`}
															style={{ marginRight: "20px", width: "241px" }}
														/>
													)}
													{gstSelectionValue === "no" && (
														<>
															<InputField
																disabled={true}
																inputType="number"
																defaultValue={` ${
																	singleSubAssembly?.hsnCode?.hgst
																		? singleSubAssembly?.hsnCode?.hgst
																		: "0"
																} `}
																label="SGST in %"
																name={`subAssemblySGST${singleSubAssembly?.id}`}
																style={{ marginRight: "20px", width: "241px" }}
															/>
															<InputField
																disabled={true}
																inputType="number"
																defaultValue={` ${
																	singleSubAssembly?.hsnCode?.cgst
																		? singleSubAssembly?.hsnCode?.cgst
																		: "0"
																} `}
																label="CGST in %"
																name={`subAssemblyCGST${singleSubAssembly?.id}`}
																style={{ marginRight: "20px", width: "241px" }}
															/>
														</>
													)}
												</>
											);
										})}
								</div>
								<Form.Item name="purchasedate" label=" Expected Purchase Date">
									{enquiryId ? (
										<DatePicker
											name="purchasedate"
											defaultValue={moment(enquiry?.purchasedate, dateFormat)}
											format={dateFormat}
										/>
									) : (
										<DatePicker name="purchasedate" />
									)}
								</Form.Item>
								<Form.Item label="Finance Required " name="finance">
									<Radio.Group>
										<Radio value={"Yes"}>yes</Radio>
										<Radio value={"No"}>no</Radio>
									</Radio.Group>
								</Form.Item>
								<Form.Item label="Do you want govt subsidy" name="subsidy">
									<Radio.Group>
										<Radio value={"Yes"}>yes</Radio>
										<Radio value={"No"}>no</Radio>
									</Radio.Group>
								</Form.Item>
								<WordCountDisplayer
									label="Customer Remark"
									name="description"
									placeholder="Please Enter Your Enquiry"
									rules={[
										{
											required: true,
										},
										{
											message: "Enquiry must be minimum 10 words.",
										},
									]}
								/>
								{enquiryId ? (
									<WordCountDisplayer
										label="Update Reason"
										name="updateReason"
										placeholder="Please enter reason"
										rules={[
											{
												required: true,
											},
											{
												message: "update reason must be minimum 10 words.",
											},
										]}
									/>
								) : null}
								<Form.Item style={{ marginTop: "20px" }}>
									<Center height="0px">
										<Space>
											<NavLink to="/customer/enquiry">
												<Button danger type="primary" onClick={handleCancel}>
													Cancel
												</Button>
											</NavLink>
											<Button type="primary" htmlType="submit">
												Update
											</Button>
										</Space>
									</Center>
								</Form.Item>
							</Form>
						</Card>
					</Col>
				</Row>
			</ErrorBoundary>
		</BasicLayout>
	);
};

export default EnquiryUpdateForm;

import Notification from "../../../components/common/Notification";
import * as adminApi from "../../../services/admin";
import {
	ADD_LOGIN_FAILURE,
	ADD_LOGIN_REQUEST,
	ADD_LOGIN_SUCCESS,
	ADMIN_ME_SUCCESS,
	LOGOUT_FAILURE,
	LOGOUT_REQUEST,
	LOGOUT_SUCCESS,
	ADMIN_ME_REQUEST,
	ADMIN_ME_FAILURE,
	GET_ADMIN_SUCCESS,
	GET_ADMIN_REQUEST,
	GET_ADMIN_FAILURE,
	GET_USERS_REQUEST,
	GET_USERS_SUCCESS,
	GET_USERS_FAILURE,
} from "./auth.actionType";

export const requestlogin = () => {
	return {
		type: ADD_LOGIN_REQUEST,
	};
};

export const loginSuccess = () => {
	return {
		type: ADD_LOGIN_SUCCESS,
	};
};

export const loginFailure = (message) => {
	return {
		type: ADD_LOGIN_FAILURE,
		message,
	};
};

export const loginAdmin = (data) => {
	return (dispatch) => {
		dispatch(requestlogin());
		return adminApi
			.adminLogin(data)
			.then((_resp) => {
				dispatch(me());
				dispatch(loginSuccess());
			})
			.catch((err) => {
				const errorMessage = err.response && err.response.data.message;
				dispatch(loginFailure(errorMessage));
			});
	};
};

export const requestMe = () => {
	return {
		type: ADMIN_ME_REQUEST,
	};
};

export const meSuccess = (data) => {
	return {
		type: ADMIN_ME_SUCCESS,
		data,
	};
};

export const meFailure = (message) => {
	return {
		type: ADMIN_ME_FAILURE,
		message,
	};
};

export const me = () => {
	return (dispatch) => {
		dispatch(requestMe());
		return adminApi
			.me()
			.then((resp) => {
				const data = resp.data.data;
				dispatch(meSuccess(data));
				return data;
			})
			.catch((err) => {
				const errorMessage = "";
				dispatch(meFailure(errorMessage));
				throw err;
			});
	};
};

export const requestLogout = () => {
	return {
		type: LOGOUT_REQUEST,
	};
};

export const logoutSuccess = () => {
	return {
		type: LOGOUT_SUCCESS,
	};
};

export const logoutFailure = () => {
	return {
		type: LOGOUT_FAILURE,
	};
};

export const logoutAdmin = () => {
	return (dispatch) => {
		dispatch(requestLogout());
		return adminApi
			.adminLogout()
			.then((_resp) => {
				dispatch(logoutSuccess());
			})
			.catch((err) => {
				Notification("error", "Something went wrong", "", "bottomRight");
				dispatch(logoutFailure());
				throw err;
			});
	};
};

export const requestGetAdmins = () => {
	return {
		type: GET_ADMIN_REQUEST,
	};
};

export const getAdminSuccess = (data) => {
	return {
		type: GET_ADMIN_SUCCESS,
		data,
	};
};

export const getAdminFailure = () => {
	return {
		type: GET_ADMIN_FAILURE,
	};
};

export const getAdmins = (adminIdsArray) => {
	return (dispatch) => {
		dispatch(requestGetAdmins());
		return adminApi
			.getAdmins(adminIdsArray)
			.then((resp) => {
				const data = resp.data.data;
				dispatch(getAdminSuccess(data));
				return data;
			})
			.catch((err) => {
				dispatch(getAdminFailure());
				throw err;
			});
	};
};

export const requestGetUsers = () => {
	return {
		type: GET_USERS_REQUEST,
	};
};

export const getUserSuccess = (data) => {
	return {
		type: GET_USERS_SUCCESS,
		data,
	};
};

export const getUserFailure = () => {
	return {
		type: GET_USERS_FAILURE,
	};
};

export const getUsers = (adminIdsArray) => {
	return (dispatch) => {
		dispatch(requestGetUsers());
		return adminApi
			.getUsers(adminIdsArray)
			.then((resp) => {
				const data = resp?.data?.data;
				dispatch(getUserSuccess(data));
				return data;
			})
			.catch((err) => {
				dispatch(getUserFailure());
				throw err;
			});
	};
};

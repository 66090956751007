import { Button, message } from "antd";
import React, { useEffect, useMemo, useState } from "react";

import { ErrorBoundary } from "react-error-boundary";
import { useDispatch, useSelector } from "react-redux";
import BasicLayout from "../../components/BasicLayout/index";
import FallBack from "../../components/common/FallBack/FallBack";
import Tables from "../../components/common/Table/Table";
import { useDeleteAction } from "../../components/Hooks/useDeleteAction";
import useExportCsv from "../../components/Hooks/useExportCsv";
import useTableSearch from "../../components/Hooks/useTableSearch";
import {
	getCustomerContacts,
	softDeleteCustomerSingleContact,
} from "../../store/actions/customer/customer.action";
import { DeleteModel } from "../../utils/DeleteModel";
import { formatFileName } from "../../utils/helper";
import ButtonAsLink from "../../components/common/ButtonAsLink";
import { useIndianStates } from "../../components/Hooks/useIndianStates";
import { useIndianAllDistricts } from "../../components/Hooks/useIndianDistricts";
import { useIndianAllSubDistricts } from "../../components/Hooks/useIndianSubDistricts";

const Enquiry = (props) => {
	const { getIndianStates } = useIndianStates();
	const { getIndianAllDistricts } = useIndianAllDistricts();
	const dispatch = useDispatch();

	const { getIndianAllSubDistricts } = useIndianAllSubDistricts();

	const [getColumnSearchProps] = useTableSearch();
	const [exportToCSV] = useExportCsv();
	const { deleteData } = useDeleteAction();
	// const [isLoadingVisible, setIsLoadingVisible] = useState(false);

	const [deleteId, setDeleteId] = useState(undefined);
	const [isModalVisible, setIsModalVisible] = useState(false);
	const enquiries = useSelector((state) => state?.customer?.enquiries || []);

	const admin = useSelector((state) => state.admin.admin || {});

	const permissionArr = useMemo(() => {
		if (admin && admin.template) {
			const { permissions } = admin.template;
			return permissions;
		} else if (admin && admin.accessLevel === "superAdmin") {
			return "all";
		}
		return [];
	}, [admin]);

	useEffect(() => {
		dispatch(getCustomerContacts());
	}, [dispatch]);

	let dataSource = [];
	if (enquiries.length > 0) {
		dataSource = enquiries?.sort(function (a, b) {
			return new Date(b.updatedAt) - new Date(a.updatedAt);
		});
		dataSource = dataSource?.map((row, index) => ({
			...row,
			createdAt: row?.createdAt.toLocaleString().slice(0, -14),
			key: row.id,
		}));
		dataSource = dataSource?.map((row, index) => ({
			...row,
			purchasedate: row?.purchasedate?.toLocaleString().substring(0, 10),
			key: row.id,
		}));
		dataSource = dataSource?.map((row, index) => ({
			...row,
			district: row?.district?.toLocaleString().toUpperCase(),
			key: row.id,
		}));
		dataSource = dataSource?.map((row, index) => ({
			...row,
			taluka: row?.taluka?.toLocaleString().toUpperCase(),
			key: row.id,
		}));
	}
	async function confirm(id, reason) {
		await deleteData(id, reason, softDeleteCustomerSingleContact, setIsModalVisible);
	}

	const columns = [
		{
			title: "Id",
			dataIndex: "id",
			key: "id",
		},
		{
			title: "Name",
			dataIndex: "name",
			key: "name",
			...getColumnSearchProps("name"),
		},
		{
			title: "State",
			dataIndex: "state",
			key: "state",
			filters: getIndianStates()?.map((data) => ({
				text: <span>{data?.["State Name(In English)"]}</span>,
				value: `${data?.["State Name(In English)"]}`,
			})),

			filterSearch: (value, record) => {
				return record?.value?.toLowerCase().includes(value);
			},
			onFilter: (value, record) => {
				return record?.state?.startsWith(value);
			},
			width: "15%",
		},
		{
			title: "District",
			dataIndex: "district",
			key: "district",
			filters: getIndianAllDistricts()?.map((data) => ({
				text: <span>{data?.["District Name(In English)"]}</span>,
				value: `${data?.["District Name(In English)"]}`,
			})),
			filterSearch: (value, record) => {
				return record?.value?.toLowerCase().includes(value);
			},
			onFilter: (value, record) => record?.district?.startsWith(value),
			width: "13%",
		},
		{
			title: "Sub District",
			dataIndex: "taluka",
			key: "taluka",
			filters: getIndianAllSubDistricts()?.map((data) => ({
				text: <span>{data?.["Sub District Name"]}</span>,
				value: `${data?.["Sub District Name"]}`,
			})),
			filterSearch: (value, record) => {
				return record?.value?.toLowerCase().includes(value);
			},
			onFilter: (value, record) => record?.taluka?.startsWith(value),
			width: "13%",
		},
		{
			title: "Purchase Date",
			dataIndex: "purchasedate",
			key: "purchasedate",
			sorter: (a, b) => a.purchasedate?.localeCompare(b.purchasedate),
			...getColumnSearchProps("purchasedate"),
		},
		{
			title: "Received",
			dataIndex: "createdAt",
			key: "createdAt",
		},
		{
			title: "Action",
			key: "action",
			render: (_text, record) => {
				return (
					<>
						<DeleteModel
							record={record}
							confirm={confirm}
							setIsModalVisible={setIsModalVisible}
							isModalVisible={isModalVisible}
							cancelMessage="Customer enquiry Removing has been cancelled"
							linkUrl={`/customer/enquiry`}
							placeholder="Please enter reason to delete customer enquiry"
							deleteId={deleteId}
							setDeleteId={setDeleteId}
							update={
								record?.addedBy || record?.addedByUser
									? permissionArr === "all"
										? "true"
										: permissionArr?.customer?.customerEnquiry.filter((item) => item === "update")
												?.length > 0
										? "true"
										: false
									: false
							}
							isdelete={
								permissionArr === "all"
									? "true"
									: permissionArr?.customer?.customerEnquiry.filter((item) => item === "delete")
											?.length > 0
									? "true"
									: false
							}
							view={
								permissionArr === "all"
									? "true"
									: permissionArr?.customer?.customerEnquiry.filter((item) => item === "read")
											?.length > 0
									? "true"
									: false
							}
						/>
					</>
				);
			},
		},
	];

	const fileName = formatFileName("customer enquiries", "xlsx", admin);

	let downloadData = useMemo(() => {
		return enquiries?.map((data) => {
			let tempModelsArr = [];
			data?.models?.forEach((element) => {
				tempModelsArr?.push(element?.name);
			});

			let tempSparePartArr = [];
			data?.spareParts?.forEach((element) => {
				tempSparePartArr?.push(element?.name);
			});

			let newData = {
				"Date of Enquiry": data.createdAt,
				"Model Name": tempModelsArr ? tempModelsArr.toString() : "NA",
				"Sparepart Name": tempSparePartArr ? tempSparePartArr.toString() : "NA",
				"Customer Name": data.name,
				"Custom Address": data.address,
				"Village Name": data.village,
				"Sub District Name": data.taluka,
				"District Name": data.district,
				"State Name": data.state,
				"Expected Purchase Date": data.purchasedate,
				Email: data.email,
				"Phone Number": data.phonenumber,
				"Finance Needed": data.finance,
				"Subsidy Needed": data.subsidy,
			};
			return newData;
		});
	}, [enquiries]);

	const handleError = (error) => {
		message.error(error.message);
	};
	const [filteredDownloadData, setFilteredDownloadData] = useState([]);

	useEffect(() => {
		filteredDownloadData.length === 0 && downloadData && setFilteredDownloadData(downloadData);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [downloadData]);

	const handleChange = (pagination, filters, sorter, extra) => {
		const tmpData = extra.currentDataSource.map((item) => {
			let tempModelsArr = [];
			item?.models?.forEach((element) => {
				tempModelsArr?.push(element?.name);
			});

			let tempSparePartArr = [];
			item?.spareParts?.forEach((element) => {
				tempSparePartArr?.push(element?.name);
			});

			return {
				"Date of Enquiry": item.createdAt,
				"Model Name": tempModelsArr ? tempModelsArr.toString() : "NA",
				"Sparepart Name": tempSparePartArr ? tempSparePartArr.toString() : "NA",
				"Customer Name": item.name,
				"Custom Address": item.address,
				"Village Name": item.village,
				"Sub District Name": item.taluka,
				"District Name": item.district,
				"State Name": item.state,
				"Expected Purchase Date": item.purchasedate,
				Email: item.email,
				"Phone Number": item.phonenumber,
				"Finance Needed": item.finance,
				"Subsidy Needed": item.subsidy,
			};
		});

		setFilteredDownloadData(tmpData);
	};

	return (
		<BasicLayout accessLevel={props.accessLevel}>
			<ErrorBoundary FallbackComponent={FallBack} onError={handleError}>
				<div className="button-style-to-right">
					{permissionArr === "all" ? (
						<Button
							className="download-btn"
							onClick={(e) => exportToCSV(filteredDownloadData, fileName)}
						>
							Export
						</Button>
					) : permissionArr?.customer?.customerEnquiry?.filter((item) => item === "export")
							?.length > 0 && downloadData?.length !== 0 ? (
						<Button
							className="download-btn"
							onClick={(e) => exportToCSV(filteredDownloadData, fileName)}
						>
							Export
						</Button>
					) : null}

					{permissionArr === "all" ? (
						<ButtonAsLink text="Add New Enquiry" link="/customer/admin/enquiry/add" />
					) : permissionArr?.customer?.customerEnquiry?.filter((item) => item === "write")?.length >
					  0 ? (
						<ButtonAsLink text="Add New Enquiry" link="/customer/admin/enquiry/add" />
					) : null}
				</div>
				<Tables
					handleChange={handleChange}
					dataSource={dataSource}
					columns={columns}
					footerText={`Total Enquiries: ${filteredDownloadData?.length}`}
				/>
			</ErrorBoundary>
		</BasicLayout>
	);
};

export default Enquiry;

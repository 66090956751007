import { FileImageOutlined, VideoCameraOutlined } from "@ant-design/icons";
import { Image, Typography } from "antd";
import React, { useEffect, useState } from "react";
import Notification from "../../common/Notification";
import { resizeImage } from "../../common/ResizeFile";
import "./index.css";

const { Text } = Typography;

const Index = ({ getRootProps, getInputProps, isDragActive, type, file, setUploadMedia, url }) => {
	const [media, setMedia] = useState("");
	const Icon = type === "image" ? FileImageOutlined : VideoCameraOutlined;

	useEffect(() => {
		if (url !== "" && url && url !== undefined && url !== null) {
			setMedia(url);
		}
		if (file.length > 0) {
			if (type === "image") {
				setMedia([]);
				setUploadMedia([]);
				for (let i = 0; i < file.length; i++) {
					resizeImage(file[i])
						.then((resp) => {
							setMedia((oldBlobFile) => [...oldBlobFile, resp.blobFile]);
							setUploadMedia((oldfile) => [...oldfile, resp.compressedFile]);
						})
						.catch((error) => {
 							const errorMessage = error.message || "Something went wrong";
							Notification("error", errorMessage, "", "topRight");
						});
				}
			}
			if (type === "video") {
				setMedia(
					file.map((file) =>
						Object.assign(file, {
							preview: URL.createObjectURL(file),
						})
					)
				);
			}
		}
	}, [file, url, setUploadMedia, type]);
	return (
		<div className="upload-root-container">
			{url && file.length === 0 ? (
				url !== "" &&
				url &&
				url !== undefined &&
				url !== null &&
				media &&
				media !== "" &&
				Array.isArray(media) ? (
					media.map((data, index) =>
						type === "video" ? (
							<div className="file-holder" key={index}>
								<video width="320" height="240" controls>
									<source src={data} type="video/mp4"></source>
								</video>
							</div>
						) : (
							<div className="file-holder" key={index}>
								<Image src={data} height="100px" width="150px" style={{ margin: "5px" }} />
							</div>
						)
					)
				) : type === "video" && media !== "" ? (
					<video width="320" height="240" controls>
						<source src={media} type="video/mp4"></source>
					</video>
				) : media !== "" ? (
					<div className="file-holder">
						<Image src={media} height="100px" width="150px" style={{ margin: "5px" }} />
					</div>
				) : null
			) : null}
			{file.length > 0 &&
				(type === "image" ? (
					!Array.isArray(media) ? null : (
						<div className="file-holder">
							{media.map((data, index) => (
								<Image
									src={data}
									key={index}
									height="100px"
									width="150px"
									style={{ padding: "10px" }}
								/>
							))}
						</div>
					)
				) : (
					<video width="320" height="240" controls style={{ padding: "30px" }}>
						<source src={media[0]?.preview} type="video/mp4"></source>
					</video>
				))}
			<div {...getRootProps()}>
				<input {...getInputProps()} />
				{isDragActive ? (
					<Text>Drop the {type} here ...</Text>
				) : (
					<div className="upload-area">
						{file.length === 0 && <Icon className="icon-container" />}
						<Text>Click or drag {type} to this area to upload</Text>
					</div>
				)}
			</div>
		</div>
	);
};
export default Index;

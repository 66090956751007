export const wordCount = (reason, minWords) => {
	try {
		if (reason) {
			const wordCount = reason?.replace(/\s/g, "").length;

			if (reason === "" || wordCount < minWords) {
				throw Error(`Reason Minimum ${minWords} characters required`);
			}
		} else {
			throw Error(`Reason Minimum ${minWords} characters required`);
		}
	} catch (err) {
		throw err;
	}
};

export const wordCounterRealTime = (words) => {
	let charCountWithoutWhitespace = 0;

	if (words) {
		charCountWithoutWhitespace = words?.replace(/\s/g, "").length; //10
	}
	return charCountWithoutWhitespace;
};

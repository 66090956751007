import { notification } from "antd";

const Notification = (type, message, description, placement) => {
	notification[type]({
		message: message,
		description: description,
		placement,
	});
};

export default Notification;

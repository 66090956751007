import Notification from "../../../components/common/Notification";
import * as customerApi from "../../../services/customer";
import {
	ADD_CUSTOMER_ENQUIRY_REQUEST,
	ADD_CUSTOMER_ENQUIRY_SUCCESS,
	ADD_CUSTOMER_ENQUIRY_FAILURE,
	GET_CUSTOMERS_INFO_FAILURE,
	GET_CUSTOMERS_INFO_REQUEST,
	GET_CUSTOMERS_INFO_SUCCESS,
	GET_CUSTOMER_CONTACTS_FAILURE,
	GET_CUSTOMER_CONTACTS_REQUEST,
	GET_CUSTOMER_CONTACTS_SUCCESS,
	GET_CUSTOMER_SINGLE_CONTACT_FAILURE,
	GET_CUSTOMER_SINGLE_CONTACT_REQUEST,
	GET_CUSTOMER_SINGLE_CONTACT_SUCCESS,
	GET_SINGLE_CUSTOMER_FAILURE,
	GET_SINGLE_CUSTOMER_REQUEST,
	GET_SINGLE_CUSTOMER_SUCCESS,
	RESTORE_CUSTOMER_SINGLE_CONTACT_FAILURE,
	RESTORE_CUSTOMER_SINGLE_CONTACT_REQUEST,
	RESTORE_CUSTOMER_SINGLE_CONTACT_SUCCESS,
	SOFTDELETE_CUSTOMER_SINGLE_CONTACT_FAILURE,
	SOFTDELETE_CUSTOMER_SINGLE_CONTACT_REQUEST,
	SOFTDELETE_CUSTOMER_SINGLE_CONTACT_SUCCESS,
	SOFTDELETE_SINGLE_CUSTOMER_FAILURE,
	SOFTDELETE_SINGLE_CUSTOMER_REQUEST,
	SOFTDELETE_SINGLE_CUSTOMER_SUCCESS,
	UPDATE_SINGLE_CUSTOMER_FAILURE,
	UPDATE_SINGLE_CUSTOMER_REQUEST,
	UPDATE_SINGLE_CUSTOMER_SUCCESS,
	UPDATE_CUSTOMER_ENQUIRY_REQUEST,
	UPDATE_CUSTOMER_ENQUIRY_SUCCESS,
	UPDATE_CUSTOMER_ENQUIRY_FAILURE,
} from "./customer.actionType";

export const requestCustomerContacts = () => {
	return {
		type: GET_CUSTOMER_CONTACTS_REQUEST,
	};
};

export const getCustomerContactsSuccess = (data) => {
	return {
		type: GET_CUSTOMER_CONTACTS_SUCCESS,
		data,
	};
};

export const getCustomerContactsFailure = (message) => {
	return {
		type: GET_CUSTOMER_CONTACTS_FAILURE,
		message,
	};
};

export const getCustomerContacts = () => {
	return (dispatch) => {
		dispatch(requestCustomerContacts());
		return customerApi
			.customerContact()
			.then((resp) => {
				const data = resp.data.data;

				dispatch(getCustomerContactsSuccess(data));
			})
			.catch((err) => {
				const errorMessage = err.response && err.response.data.message;
				dispatch(getCustomerContactsFailure(errorMessage));
			});
	};
};

export const requestCustomerSingleContactData = () => {
	return {
		type: GET_CUSTOMER_SINGLE_CONTACT_REQUEST,
	};
};

export const getCustomerSingleContactSuccess = (data) => {
	return {
		type: GET_CUSTOMER_SINGLE_CONTACT_SUCCESS,
		data,
	};
};

export const getCustomerSingleContactFailure = (message) => {
	return {
		type: GET_CUSTOMER_SINGLE_CONTACT_FAILURE,
		message,
	};
};

export const getCustomerSingleContact = (id, softdelete) => {
	return (dispatch) => {
		dispatch(requestCustomerSingleContactData());
		return customerApi
			.customerSingleContact(id, softdelete)
			.then((resp) => {
				const data = resp.data.data;
				dispatch(getCustomerSingleContactSuccess(data));
			})
			.catch((err) => {
				const errorMessage = err.response && err.response.data.message;
				dispatch(getCustomerSingleContactFailure(errorMessage));
				throw errorMessage;
			});
	};
};

export const requestCustomerSingleContactSoftDelete = () => {
	return {
		type: SOFTDELETE_CUSTOMER_SINGLE_CONTACT_REQUEST,
	};
};

export const softDeleteCustomerSingleContactSuccess = (data) => {
	return {
		type: SOFTDELETE_CUSTOMER_SINGLE_CONTACT_SUCCESS,
		data,
	};
};

export const softDeleteCustomerSingleContactFailure = (message) => {
	return {
		type: SOFTDELETE_CUSTOMER_SINGLE_CONTACT_FAILURE,
		message,
	};
};

export const softDeleteCustomerSingleContact = (id, reason) => {
	return (dispatch) => {
		dispatch(requestCustomerSingleContactSoftDelete());
		return customerApi
			.customerSingleContactSoftDelete(id, reason)
			.then((resp) => {
				dispatch(softDeleteCustomerSingleContactSuccess(id));
				Notification("success", resp.data.message, "", "topRight");
			})
			.catch((err) => {
				const errorMessage = err.response && err.response.data.message;
				dispatch(getCustomerSingleContactFailure(errorMessage));
			});
	};
};

export const requestCustomerSingleContactrestore = () => {
	return {
		type: RESTORE_CUSTOMER_SINGLE_CONTACT_REQUEST,
	};
};

export const restoreCustomerSingleContactSuccess = () => {
	return {
		type: RESTORE_CUSTOMER_SINGLE_CONTACT_SUCCESS,
	};
};

export const restoreCustomerSingleContactFailure = (message) => {
	return {
		type: RESTORE_CUSTOMER_SINGLE_CONTACT_FAILURE,
		message,
	};
};

export const restoreCustomerSingleContact = (id) => {
	return (dispatch) => {
		dispatch(requestCustomerSingleContactSoftDelete());
		return customerApi
			.customerSingleContactRestore(id)
			.then((resp) => {
				dispatch(restoreCustomerSingleContactSuccess(id));
				Notification("success", resp.data.message, "", "topRight");
			})
			.catch((err) => {
				const errorMessage = err.response && err.response.data.message;
				dispatch(getCustomerSingleContactFailure(errorMessage));
			});
	};
};

export const requestCustomersInfo = () => {
	return {
		type: GET_CUSTOMERS_INFO_REQUEST,
	};
};

export const getCustomersInfoSuccess = (data) => {
	return {
		type: GET_CUSTOMERS_INFO_SUCCESS,
		data,
	};
};

export const getCustomersInfoFailure = (message) => {
	return {
		type: GET_CUSTOMERS_INFO_FAILURE,
		message,
	};
};

export const getCustomersInfo = () => {
	return (dispatch) => {
		dispatch(requestCustomersInfo());
		return customerApi
			.customersInfo()
			.then((resp) => {
				const data = resp.data.data;
				dispatch(getCustomersInfoSuccess(data));
			})
			.catch((err) => {
				const errorMessage = err.response && err.response.data.message;
				dispatch(getCustomersInfoFailure(errorMessage));
			});
	};
};

export const requestSingleCustomer = () => {
	return {
		type: GET_SINGLE_CUSTOMER_REQUEST,
	};
};

export const getSingleCustomerSuccess = (data) => {
	return {
		type: GET_SINGLE_CUSTOMER_SUCCESS,
		data,
	};
};

export const getSingleCustomerFailure = (message) => {
	return {
		type: GET_SINGLE_CUSTOMER_FAILURE,
		message,
	};
};

export const getSingleCustomer = (id, softdelete) => {
	return (dispatch) => {
		dispatch(requestSingleCustomer());
		return customerApi
			.getSingleCustomer(id, softdelete)
			.then((resp) => {
				const data = resp.data.data;
				dispatch(getSingleCustomerSuccess(data));
			})
			.catch((err) => {
				const errorMessage = err.response && err.response.data.message;
				dispatch(getSingleCustomerFailure(errorMessage));
				throw errorMessage;
			});
	};
};

export const requestSingleCustomerSoftDelete = () => {
	return {
		type: SOFTDELETE_SINGLE_CUSTOMER_REQUEST,
	};
};

export const softDeleteSingleCustomerSuccess = (data) => {
	return {
		type: SOFTDELETE_SINGLE_CUSTOMER_SUCCESS,
		data,
	};
};

export const softDeleteSingleCustomerFailure = (message) => {
	return {
		type: SOFTDELETE_SINGLE_CUSTOMER_FAILURE,
		message,
	};
};

export const softDeleteSingleCustomer = (id, reason) => {
	return (dispatch) => {
		dispatch(requestSingleCustomerSoftDelete());
		return customerApi
			.singleCustomerSoftDelete(id, reason)
			.then((resp) => {
				dispatch(softDeleteSingleCustomerSuccess(id));
				Notification("success", resp.data.message, "", "topRight");
				return;
			})
			.catch((err) => {
				const errorMessage = err.response && err.response.data.message;
				dispatch(softDeleteSingleCustomerFailure(errorMessage));
			});
	};
};

//FOR UPDATE JOBS
export const requestUpdateSingleCustomer = () => {
	return {
		type: UPDATE_SINGLE_CUSTOMER_REQUEST,
	};
};

export const updateSingleCustomerSuccess = (data) => {
	return {
		type: UPDATE_SINGLE_CUSTOMER_SUCCESS,
		data,
	};
};

export const updateSingleCustomerFailure = (message) => {
	return {
		type: UPDATE_SINGLE_CUSTOMER_FAILURE,
		message,
	};
};

export const updateSingleCustomer = (id, data, history) => {
	return (dispatch) => {
		dispatch(requestUpdateSingleCustomer());
		return customerApi
			.updateSingleCustomer(id, data)
			.then((_resp) => {
				dispatch(updateSingleCustomerSuccess({ id, ...data }));
				history.push("/customer/info");
				Notification("success", "Customer Updated SuccesFully", "", "topRight");
			})
			.catch((err) => {
				const errorMessage = err.response && err.response.data.message;
				dispatch(updateSingleCustomerFailure(errorMessage));
				throw err;
			});
	};
};

export const requestCustomerEnquiryAdd = () => {
	return {
		type: ADD_CUSTOMER_ENQUIRY_REQUEST,
	};
};

export const customerEnquiryAddSuccess = (data) => {
	return {
		type: ADD_CUSTOMER_ENQUIRY_SUCCESS,
		data,
	};
};

export const customerEnquiryAddFailure = (message) => {
	return {
		type: ADD_CUSTOMER_ENQUIRY_FAILURE,
		message,
	};
};

export const addCustomerEnquiry = (data) => {
	return (dispatch) => {
		dispatch(requestCustomerEnquiryAdd());
		return customerApi
			.addCustomerEnquiry(data)
			.then((resp) => {
				const data = resp?.data?.data;
				return data;
			})
			.catch((err) => {
				const errorMessage =
					(err.response && err?.response?.data?.message) || "Something went wrong";
				dispatch(customerEnquiryAddFailure(errorMessage));
				throw err;
			});
	};
};

// FOR UPDATE CUSTOMER ENQUIRY VIA ADMIN

export const requestUpdateCustomerEnquiry = () => {
	return {
		type: UPDATE_CUSTOMER_ENQUIRY_REQUEST,
	};
};

export const updateCustomerEnquirySuccess = (data) => {
	return {
		type: UPDATE_CUSTOMER_ENQUIRY_SUCCESS,
		data,
	};
};

export const updateCustomerEnquiryFailure = (message) => {
	return {
		type: UPDATE_CUSTOMER_ENQUIRY_FAILURE,
		message,
	};
};

export const updateCustomerEnquiry = (id, data) => {
	return (dispatch) => {
		dispatch(requestUpdateCustomerEnquiry());
		return customerApi
			.updateCustomerEnquiry(id, data)

			.then((resp) => {
				const data = resp.data;
				return data;
			})
			.catch((err) => {
				const errorMessage = err.response && err.response.data.message;
				dispatch(updateCustomerEnquiryFailure(errorMessage));
				throw err;
			});
	};
};

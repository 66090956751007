import Notification from "../../../components/common/Notification";
import * as placeApi from "../../../services/place";
import {
	GET_DISTRICTS_SUCCESS,
	GET_STATES_FAILURE,
	GET_STATES_REQUEST,
	GET_STATES_SUCCESS,
	GET_DISTRICTS_REQUEST,
	GET_DISTRICTS_FAILURE,
	GET_SUB_DISTRICTS_REQUEST,
	GET_SUB_DISTRICTS_SUCCESS,
	GET_SUB_DISTRICTS_FAILURE,
	GET_ALL_DISTRICTS_REQUEST,
	GET_ALL_DISTRICTS_SUCCESS,
	GET_ALL_DISTRICTS_FAILURE,
	GET_ALL_SUB_DISTRICTS_FAILURE,
	GET_ALL_SUB_DISTRICTS_SUCCESS,
	GET_ALL_SUB_DISTRICTS_REQUEST,
} from "./place.actionType";

export const requestGetStates = () => {
	return {
		type: GET_STATES_REQUEST,
	};
};

export const getStatesSuccess = (data) => {
	return {
		type: GET_STATES_SUCCESS,
		data,
	};
};
export const getStatesFailure = (message) => {
	return {
		type: GET_STATES_FAILURE,
		message,
	};
};

export const getStates = () => {
	return (dispatch) => {
		dispatch(requestGetStates());
		return placeApi
			.getStates()
			.then((resp) => {
				const data = resp.data.data;
				dispatch(getStatesSuccess(data));
			})
			.catch((err) => {
				const errorMessage = err.response && err.response.data.message;
				dispatch(getStatesFailure());
				return Notification(errorMessage, "", "", "topRight");
			});
	};
};

export const requestGetDistricts = () => {
	return {
		type: GET_DISTRICTS_REQUEST,
	};
};

export const getDistrictsSuccess = (data) => {
	return {
		type: GET_DISTRICTS_SUCCESS,
		data,
	};
};
export const getDistrictsFailure = (message) => {
	return {
		type: GET_DISTRICTS_FAILURE,
		message,
	};
};

export const getDistricts = (stateCode) => {
	return (dispatch) => {
		dispatch(requestGetDistricts());
		return placeApi
			.getDistricts(stateCode)
			.then((resp) => {
				const data = resp?.data?.data;
				dispatch(getDistrictsSuccess(data));
				return data;
			})
			.catch((err) => {
				dispatch(getDistrictsFailure());
			});
	};
};

export const requestGetSubDistricts = () => {
	return {
		type: GET_SUB_DISTRICTS_REQUEST,
	};
};

export const getSubDistrictsSuccess = () => {
	return {
		type: GET_SUB_DISTRICTS_SUCCESS,
	};
};
export const getSubDistrictsFailure = (message) => {
	return {
		type: GET_SUB_DISTRICTS_FAILURE,
		message,
	};
};

export const getSubDistricts = (districtCode) => {
	return (dispatch) => {
		dispatch(requestGetSubDistricts());
		return placeApi
			.getSubDistricts(districtCode)
			.then((resp) => {
				const data = resp.data.data;
				dispatch(getSubDistrictsSuccess());
				return data;
			})
			.catch((err) => {
				dispatch(getSubDistrictsFailure());
			});
	};
};

export const requestGetAllDistricts = () => {
	return {
		type: GET_ALL_DISTRICTS_REQUEST,
	};
};

export const getAllDistrictsSuccess = (data) => {
	return {
		type: GET_ALL_DISTRICTS_SUCCESS,
		data,
	};
};

export const getAllDistrictsFailure = (message) => {
	return {
		type: GET_ALL_DISTRICTS_FAILURE,
		message,
	};
};

export const getAllDistricts = () => {
	return (dispatch) => {
		dispatch(requestGetAllDistricts());
		return placeApi
			.getAllDistricts()
			.then((resp) => {
				const data = resp?.data?.data;
				dispatch(getAllDistrictsSuccess(data));
			})
			.catch((err) => {
				const errorMessage = err.response && err.response.data.message;
				dispatch(getAllDistrictsFailure());
				return Notification(errorMessage, "", "", "topRight");
			});
	};
};

export const requestGetAllSubDistricts = () => {
	return {
		type: GET_ALL_SUB_DISTRICTS_REQUEST,
	};
};

export const getAllSubDistrictsSuccess = (data) => {
	return {
		type: GET_ALL_SUB_DISTRICTS_SUCCESS,
		data,
	};
};

export const getAllSubDistrictsFailure = (message) => {
	return {
		type: GET_ALL_SUB_DISTRICTS_FAILURE,
		message,
	};
};

export const getAllSubDistricts = () => {
	return (dispatch) => {
		dispatch(requestGetAllSubDistricts());
		return placeApi
			.getAllSubDistricts()
			.then((resp) => {
				const data = resp?.data?.data;
				dispatch(getAllSubDistrictsSuccess(data));
			})
			.catch((err) => {
				const errorMessage = err.response && err.response.data.message;
				dispatch(getAllSubDistrictsFailure());
				return Notification(errorMessage, "", "", "topRight");
			});
	};
};

export const GET_STATES_SUCCESS = "GET_STATES_SUCCESS",
	GET_STATES_REQUEST = "GET_STATES_REQUEST",
	GET_STATES_FAILURE = "GET_STATES_FAILURE";

export const GET_DISTRICTS_REQUEST = "GET_DISTRICTS_REQUEST",
	GET_DISTRICTS_SUCCESS = "GET_DISTRICTS_SUCCESS",
	GET_DISTRICTS_FAILURE = "GET_DISTRICTS_FAILURE";

export const GET_SUB_DISTRICTS_REQUEST = "GET_SUB_DISTRICTS_REQUEST",
	GET_SUB_DISTRICTS_SUCCESS = "GET_SUB_DISTRICTS_SUCCESS",
	GET_SUB_DISTRICTS_FAILURE = "GET_SUB_DISTRICTS_FAILURE";

export const GET_ALL_DISTRICTS_REQUEST = "GET_ALL_DISTRICTS_REQUEST",
	GET_ALL_DISTRICTS_SUCCESS = "GET_ALL_DISTRICTS_SUCCESS",
	GET_ALL_DISTRICTS_FAILURE = "GET_ALL_DISTRICTS_FAILURE";

export const GET_ALL_SUB_DISTRICTS_REQUEST = "GET_ALL_SUB_DISTRICTS_REQUEST",
	GET_ALL_SUB_DISTRICTS_SUCCESS = "GET_ALL_SUB_DISTRICTS_SUCCESS",
	GET_ALL_SUB_DISTRICTS_FAILURE = "GET_ALL_SUB_DISTRICTS_FAILURE";

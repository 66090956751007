import { Form, Select, Typography } from "antd";
import { MailOutlined, MobileOutlined, UserOutlined } from "@ant-design/icons";
import { RiLockPasswordLine } from "react-icons/ri";
import TextArea from "../../../components/Form/TextArea";
import { WordCountDisplayer } from "../../../components/common/WordCountDisplayer";
import InputField from "../../../components/Form/InputField";
import UploadFILE from "../../../components/common/uploadImagNpdf";

export const EmployeeForm = ({
	ruleIsRequired,
	onChangeState,
	getIndianStates,
	selectedStateIds,
	districtList,
	onChangeDistrict,
	onChangeSubDistrict,
	selectedDistrictIds,
	subDistrictList,
	TemplateChildren,
	userId,
	user,
	getidentityDocInputProps,
	getidentityDocRootProps,
	isidentityDocDragActive,
	identityDocFile,
}) => {
	const { Title } = Typography;
	const { Option } = Select;

	return (
		<div>
			<div className="userFormInputGroup">
				<div className="userFormInputChild" style={{ marginRight: "15px" }}>
					<InputField
						rules={[
							{
								required: true,
							},
							{
								min: 1,
								message: "User Name be minimum 1 character.",
							},
							{
								max: 100,
								message: "User Name be minimum 100 character.",
							},
						]}
						className="responsive"
						type="string"
						label="User Name"
						name="userName"
						placeholder="Please enter user name"
						prefix={<UserOutlined />}
					/>
				</div>
				<div className="userFormInputChild" style={{ marginRight: "15px" }}>
					<InputField
						rules={[
							{
								required: ruleIsRequired,
							},
							{ min: 1, message: "Company Name be minimum 1 character" },
							{
								max: 100,
								message: "Company Name be minimum 100 character",
							},
						]}
						label="Company Name"
						name="companyName"
						placeholder="Please enter company name"
						prefix={<UserOutlined />}
					/>
				</div>
			</div>
			<div className="userFormInputGroup">
				<div className="userFormInputChild" style={{ marginRight: "15px" }}>
					<WordCountDisplayer
						label="Description"
						name="description"
						placeholder="Please enter Description"
						rules={[
							{
								message: "Description must be minimum 10 words.",
							},
						]}
					/>
				</div>

				<div className="userFormInputChild" style={{ marginRight: "15px" }}>
					<div className="adderres">
						<TextArea
							rules={[{ required: true }]}
							label="Office Address"
							name="officeAddress"
							placeholder="Please enter Office Address"
						/>
					</div>
				</div>
			</div>
			<div className="userFormInputGroup">
				<div className="userFormInputChild" style={{ marginRight: "15px" }}>
					<div style={{ marginTop: "10px" }}>
						<Form.Item
							rules={[
								{
									required: true,
								},
							]}
							label="Select State"
							name="state"
							defaultValue={undefined}
						>
							<Select
								allowClear
								mode="multiple"
								style={{ width: "100%" }}
								onChange={onChangeState}
								defaultValue={undefined}
								placeholder="Please select states"
								filterOption={(input, option) => {
									return option?.children?.toLowerCase().indexOf(input?.toLowerCase()) >= 0;
								}}
							>
								{getIndianStates &&
									getIndianStates()?.map((state, index) => (
										<Option value={state["State Code"]} key={index}>
											{state["State Name(In English)"]}
										</Option>
									))}
							</Select>
						</Form.Item>
					</div>
				</div>
				<div className="userFormInputChild" style={{ marginRight: "15px" }}>
					<div className="userDistrict" style={{ marginTop: "10px" }}>
						<Form.Item
							rules={[
								{
									required: false,
								},
							]}
							label="Select District"
							name="district"
							defaultValue={undefined}
						>
							<Select
								placeholder={
									selectedStateIds ? "Please select a Districts" : "Please select a States first"
								}
								allowClear
								mode="multiple"
								onChange={onChangeDistrict}
								style={{ width: "100%" }}
								filterOption={(input, option) => {
									return option?.children?.toLowerCase().indexOf(input?.toLowerCase()) >= 0;
								}}
							>
								{selectedStateIds &&
									districtList?.map((district, index) => (
										<Option value={district["District Code"]} key={index}>
											{district["District Name(In English)"]}
										</Option>
									))}
							</Select>
						</Form.Item>
					</div>
				</div>
			</div>
			<div className="userFormInputGroup">
				<div className="userFormInputChild" style={{ marginRight: "15px" }}>
					<div style={{ marginTop: "10px" }}>
						<Form.Item
							rules={[
								{
									required: false,
								},
							]}
							label="Select Sub District"
							name="subDistrict"
							defaultValue={undefined}
						>
							<Select
								allowClear
								mode="multiple"
								onChange={onChangeSubDistrict}
								style={{ width: "100%" }}
								placeholder={
									selectedDistrictIds
										? "Please select a Sub-Districts"
										: "Please select a Districts first"
								}
								filterOption={(input, option) => {
									return option?.children?.toLowerCase().indexOf(input?.toLowerCase()) >= 0;
								}}
							>
								{selectedDistrictIds &&
									subDistrictList?.map((district, index) => (
										<Option value={district["Sub District Code"]} key={index}>
											{district["Sub District Name"]}
										</Option>
									))}
							</Select>
						</Form.Item>
					</div>
				</div>

				<div className="userFormInputChild" style={{ marginRight: "15px" }}>
					<div className="userTemplate" style={{ marginTop: "10px" }}>
						<Form.Item
							name="templateId"
							label="Select Template"
							rules={[{ required: true, message: "Template is required" }]}
						>
							<Select
								showSearch
								placeholder="please Select Templates"
								filterOption={(input, option) =>
									option.title.toLowerCase().indexOf(input.toLowerCase()) >= 0
								}
							>
								{TemplateChildren?.map((option, index) => (
									<Option value={option.value} key={index} title={option.key}>
										{option.key}
									</Option>
								))}
							</Select>
						</Form.Item>
					</div>
				</div>
			</div>
			<div className="userFormTextGroup">
				<div className="userFormTextGroupChild" style={{ marginRight: "15px" }}>
					<div style={{ marginTop: "10px" }}>
						<InputField
							rules={[
								{
									required: true,
								},
							]}
							label="Village"
							name="village"
							placeholder="Please enter village"
							prefix={<UserOutlined />}
						/>
					</div>
				</div>
				<div className="userFormTextGroupChild">
					<InputField
						style={{ marginTop: "10px" }}
						rules={[
							{
								required: true,
								message: "Company PAN Card Number is required",
							},
							{
								min: 6,
								max: 14,
								message:
									"Company PAN Card Number must be minimumu 6 characters & maxmimum 14 characters.",
							},
						]}
						label="Company PAN Card Number"
						name="companyPANCardNumber"
						placeholder="Please enter Company PAN Card Number."
						prefix={<UserOutlined />}
					/>
				</div>
				<div className="userFormTextGroupChild">
					<InputField
						style={{ marginTop: "10px" }}
						rules={[
							{
								required: true,
								message: "Office Pincode is required",
							},
							{ min: 6, max: 6, message: "Office Pincode must be 6 characters." },
						]}
						label="Office Pincode"
						name="officePincode"
						placeholder="Please enter Office Pincode."
						prefix={<UserOutlined />}
					/>
				</div>
			</div>
			<div className="userFormTextGroup">
				<div className="userFormTextGroupChild" style={{ marginRight: "15px" }}>
					<InputField
						rules={[
							{
								required: true,
								message: "Phone Number is required",
							},
							{
								min: 10,
								message: "Phone Number must be minimum 10 characters.",
							},
							{
								max: 10,
								message: "Phone Number must be maximum 10 characters.",
							},
						]}
						label="Phone Number"
						name="phoneNumber"
						placeholder="Please enter Phone Number"
						prefix={<MobileOutlined />}
					/>
				</div>
				<div className="userFormTextGroupChild">
					<InputField
						rules={[
							{
								required: true,
								message: "Email is required",
							},
							{
								min: 1,
								message: "Email minimum = 1",
							},
						]}
						label="Email"
						name="email"
						placeholder="Please enter Email."
						prefix={<MailOutlined />}
					/>
				</div>
				<div className="userFormTextGroupChild">
					<InputField
						rules={[
							{
								required: true,
								message: "Password is required",
							},
							{
								max: 100000,
								message: "Password maximum=100000",
							},
						]}
						label="Password"
						name="password"
						inputType="password"
						placeholder="Please enter Password."
						autoComplete="current-password"
						prefix={<RiLockPasswordLine />}
					/>
				</div>
			</div>
			<Title
				level={5}
				style={{
					fontWeight: "400",
					fontSize: "18px",
					marginTop: "10px",
				}}
			>
				<span
					style={{
						color: "#f08080",
						fontWeight: "thin",
						fontSize: "16px",
					}}
				>
					*
				</span>
				Upload Pan Card / Aadhar Card / Driving License :
			</Title>
			<UploadFILE
				url={userId ? user?.identityDocUrl : ""}
				getInputProps={getidentityDocInputProps}
				getRootProps={getidentityDocRootProps}
				isDragActive={isidentityDocDragActive}
				file={identityDocFile}
			/>
			{userId ? (
				<WordCountDisplayer
					label="Update Reason"
					name="updateReason"
					placeholder="Please enter reason"
					rules={[
						{
							required: true,
						},
						{
							message: "update reason must be minimum 10 words.",
						},
					]}
				/>
			) : null}
		</div>
	);
};

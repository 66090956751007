import {
	ADD_HSN_CODE_SUCCESS,
	GET_SINGLE_HSN_CODE_SUCCESS,
	GET_HSN_CODES_SUCCESS,
	RESTORE_SINGLE_HSN_CODE_SUCCESS,
	SOFT_DELETE_SINGLE_HSN_CODE_SUCCESS,
	UPDATE_HSN_CODE_SUCCESS,
} from "../actions/products/HSNCode/hsnCode.actionType";
import globalState from "../state";

const requestRegex = /_REQUEST/;
const errorRegex = /_FAILURE/;
const successRegex = /_SUCCESS/;

const initialState = {
	errorMessage: "",
	isLoading: false,
	hsnCodes: [],
	hsnCode: {},
	...globalState,
};

const HSNCodeReducer = (state = initialState, action) => {
	const isRequest = requestRegex.test(action.type);
	const isError = errorRegex.test(action.type);
	const isSuccess = successRegex.test(action.type);

	if (isRequest) {
		return { ...state, isLoading: true, errorMessage: "" };
	}
	if (isError) {
		return { ...state, isLoading: false, errorMessage: action?.message };
	}
	if (isSuccess) {
		state = { ...state, isLoading: false, errorMessage: "" };
	}

	switch (action.type) {
		case GET_HSN_CODES_SUCCESS:
			return { ...state, hsnCodes: action?.data };

		case GET_SINGLE_HSN_CODE_SUCCESS:
			return { ...state, hsnCode: action?.data };

		case SOFT_DELETE_SINGLE_HSN_CODE_SUCCESS:
			let softDeletedHSNCodeId = action?.data;
			let afterSoftDeletedData = state?.hsnCodes?.filter(
				(data) => data?.id.toString() !== softDeletedHSNCodeId.toString()
			);
			return { ...state, hsnCodes: afterSoftDeletedData };

		case ADD_HSN_CODE_SUCCESS:
			return { ...state };

		case RESTORE_SINGLE_HSN_CODE_SUCCESS:
			return { ...state };

		case UPDATE_HSN_CODE_SUCCESS:
			return { ...state };

		default:
			return state;
	}
};

export default HSNCodeReducer;

import dayjs from "dayjs";
import { removeEmptyFromArray } from "./removeEmptyFromArray";

export const __prod__ = process.env.NODE_ENV === "production";

// returns true if empty
export const checkIfObjectEmpty = (value) => {
	return Object.keys(value).length === 0 && value.constructor === Object;
};

// returns true if empty
export const checkIfArrayEmpty = (value) => {
	return value.length === 0;
};

// returns true if equal
export const isEqual = (valueOne, valueTwo) => {
	return valueOne === valueTwo;
};

export const convertEpochToDate = (epochTime) => {
	return new Date(+epochTime).toDateString();
};

export const formatFileName = (filename, ext,admin) => {
	const user = admin?.username ? admin?.username : admin?.userName;
	const currDate = Date.now();
	const date = dayjs(currDate).format("YYYYMMDD");
	const randomString = Math.random().toString(36).substring(2, 7);
	const cleanFileName = filename.toLowerCase().replace(/[^a-z0-9]/g, "-");
	const newFileName = `${user}-${date}-${randomString}-${cleanFileName}`;
	return `${newFileName.substring(0, 60)}.${ext}`;
};

// export const formatFileName = (filename, ext) => {
// 	const currDate = Date.now();
// 	const date = dayjs(currDate).format("YYYYMMDD");
// 	const randomString = Math.random().toString(36).substring(2, 7);
// 	const cleanFileName = filename.toLowerCase().replace(/[^a-z0-9]/g, "-");
// 	const newFileName = ` ${date}-${randomString}-${cleanFileName}`;
// 	return `${newFileName.substring(0, 60)}.${ext}`;
// };

export const formmaterForSparePartFileArray = (data) => {
	try {
		if (data) {
			let resp;
			if (data && typeof data !== "number") {
				resp = removeEmptyFromArray(data?.split(","));
			} else {
				resp = [data?.toString()];
			}
			return resp;
		}
		return [];
	} catch (err) {
		throw err;
	}
};

import { Form, Input, InputNumber, Table } from "antd";
import React from "react";

const EditableCell = ({
	editing,
	dataIndex,
	title,
	inputType,
	record,
	index,
	min,
	max,
	children,
	...restProps
}) => {
	const inputNode =
		inputType === "number" ? (
			<InputNumber
				min={min}
				max={max}
				rules={[
					{
						required: true,
						message: `Please Input ${title}!`,
					},
				]}
			/>
		) : (
			<Input />
		);
	return (
		<td {...restProps}>
			{editing ? (
				<Form.Item
					name={dataIndex}
					style={{
						margin: 0,
					}}
					rules={[
						{
							required: true,
							message: `Please Input ${title}!`,
						},
					]}
				>
					{inputNode}
				</Form.Item>
			) : (
				children
			)}
		</td>
	);
};

const EditableTable = ({ data, isEditing, form, footerText, columns }) => {
	const mergedColumns = columns.map((col) => {
		if (!col.editable) {
			return col;
		}
		return {
			...col,
			onCell: (record) => ({
				record,
				inputType: col.dataIndex === "stock" || "price" || "discount" ? "number" : "text",
				dataIndex: col.dataIndex,
				title: col.title,
				min: col.min,
				max: col.max,
				editing: isEditing(record),
			}),
		};
	});

	return (
		<Form form={form} component={false}>
			<Table
				components={{
					body: {
						cell: EditableCell,
					},
				}}
				bordered
				dataSource={data}
				columns={mergedColumns}
				rowClassName="editable-row"
				footer={() => footerText}
			/>
		</Form>
	);
};

export default EditableTable;

export const GET_IMPLEMENTS_REQUEST = "GET_IMPLEMENTS_REQUEST",
	GET_IMPLEMENTS_SUCCESS = "GET_IMPLEMENTS_SUCCESS",
	GET_IMPLEMENTS_FAILURE = "GET_IMPLEMENTS_FAILURE";

export const GET_SINGLE_IMPLEMENTS_REQUEST = "GET_SINGLE_IMPLEMENTS_REQUEST",
	GET_SINGLE_IMPLEMENTS_SUCCESS = "GET_SINGLE_IMPLEMENTS_SUCCESS",
	GET_SINGLE_IMPLEMENTS_FAILURE = "GET_SINGLE_IMPLEMENTS_FAILURE";

export const SOFT_DELETE_SINGLE_IMPLEMENTS_REQUEST = "SOFT_DELETE_SINGLE_IMPLEMENTS_REQUEST",
	SOFT_DELETE_SINGLE_IMPLEMENTS_SUCCESS = "SOFT_DELETE_SINGLE_IMPLEMENTS_SUCCESS",
	SOFT_DELETE_SINGLE_IMPLEMENTS_FAILURE = "SOFT_DELETE_SINGLE_IMPLEMENTS_FAILURE";

export const RESTORE_SINGLE_IMPLEMENTS_REQUEST = "RESTORE_SINGLE_IMPLEMENTS_REQUEST",
	RESTORE_SINGLE_IMPLEMENTS_SUCCESS = "RESTORE_SINGLE_IMPLEMENTS_SUCCESS",
	RESTORE_SINGLE_IMPLEMENTS_FAILURE = "RESTORE_SINGLE_IMPLEMENTS_FAILURE";

export const ADD_IMPLEMENTS_REQUEST = "ADD_IMPLEMENTS_REQUEST",
	ADD_IMPLEMENTS_SUCCESS = "ADD_IMPLEMENTS_SUCCESS",
	ADD_IMPLEMENTS_FAILURE = "ADD_IMPLEMENTS_FAILURE";

export const UPDATE_IMPLEMENTS_REQUEST = "UPDATE_IMPLEMENTS_REQUEST",
	UPDATE_IMPLEMENTS_SUCCESS = "UPDATE_IMPLEMENTS_SUCCESS",
	UPDATE_IMPLEMENTS_FAILURE = "UPDATE_IMPLEMENTS_FAILURE";

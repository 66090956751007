import { invokeApi } from "../utils/api";
import { $axios } from "../utils/axios";

export const getAllImplementsSubAssemblies = () =>
	invokeApi($axios, "/implements-sub-assembly", {}, "GET")
		.then((resp) => resp)
		.catch((err) => {
			throw err;
		});

export const getSingleImplementsSubAssembly = (implementsSubAssemblyId, softdelete) =>
	invokeApi(
		$axios,
		`/implements-sub-assembly/${
			softdelete === "true" ? "soft-delete/" : ""
		}${implementsSubAssemblyId}`,
		{},
		"GET"
	)
		.then((resp) => resp)
		.catch((err) => {
			throw err;
		});

export const softDeleteSingleImplementsSubAssembly = (implementsSubAssemblyId, reason) =>
	invokeApi(
		$axios,
		`/implements-sub-assembly/soft-delete/${implementsSubAssemblyId}?reason=${reason}`,
		{},
		"DELETE"
	)
		.then((resp) => resp)
		.catch((err) => {
			throw err;
		});

export const restoreImplementsSubAssembly = (implementsSubAssemblyId) =>
	invokeApi($axios, `/implements-sub-assembly/restore/${implementsSubAssemblyId}`, {}, "PUT")
		.then((resp) => resp)
		.catch((err) => {
			throw err;
		});

export const addImplementsSubAssembly = (data) =>
	invokeApi($axios, "/implements-sub-assembly", data, "POST")
		.then((resp) => resp)
		.catch((err) => {
			throw err;
		});

export const updateImplementsSubAssembly = (id, data) =>
	invokeApi($axios, `/implements-sub-assembly/${id}`, data, "PUT")
		.then((resp) => resp)
		.catch((err) => {
			throw err;
		});

export const getImplementsRelatedImplementSubAssembly = (data) =>
	invokeApi(
		$axios,
		"/implements-sub-assembly/implements-related-implements-sub-assembly",
		data,
		"POST"
	)
		.then((resp) => resp)
		.catch((err) => {
			throw err;
		});

export const GET_DISTRIBUTOR_CONTACTS_REQUEST = "GET_DISTRIBUTOR_CONTACTS_REQUEST",
	GET_DISTRIBUTOR_CONTACTS_SUCCESS = "GET_DISTRIBUTOR_CONTACTS_SUCCESS",
	GET_DISTRIBUTOR_CONTACTS_FAILURE = "GET_DISTRIBUTOR_CONTACTS_FAILURE";

export const GET_DISTRIBUTOR_SINGLE_CONTACT_REQUEST = "GET_DISTRIBUTOR_SINGLE_CONTACT_REQUEST",
	GET_DISTRIBUTOR_SINGLE_CONTACT_SUCCESS = "GET_DISTRIBUTOR_SINGLE_CONTACT_SUCCESS",
	GET_DISTRIBUTOR_SINGLE_CONTACT_FAILURE = "GET_DISTRIBUTOR_SINGLE_CONTACT_FAILURE";

export const SOFTDELETE_DISTRIBUTOR_SINGLE_CONTACT_REQUEST =
		"SOFTDELETE_DISTRIBUTOR_SINGLE_CONTACT_REQUEST",
	SOFTDELETE_DISTRIBUTOR_SINGLE_CONTACT_SUCCESS = "SOFTDELETE_DISTRIBUTOR_SINGLE_CONTACT_SUCCESS",
	SOFTDELETE_DISTRIBUTOR_SINGLE_CONTACT_FAILURE = "SOFTDELETE_DISTRIBUTOR_SINGLE_CONTACT_FAILURE";

import { invokeApi } from "../utils/api";
import { $axios } from "../utils/axios";

export const me = (data) =>
	invokeApi($axios, "/admin/me")
		.then((resp) => resp)
		.catch((err) => {
			throw err;
		});

export const adminLogin = (data) =>
	invokeApi($axios, "/admin/login", data, "POST")
		.then((resp) => resp)
		.catch((err) => {
			throw err;
		});

export const adminLogout = () =>
	invokeApi($axios, "/admin/logout", {}, "PUT")
		.then((resp) => resp)
		.catch((err) => {
			throw err;
		});

export const getAdmins = (adminIdsArray) =>
	invokeApi(
		$axios,
		`/admin?${adminIdsArray.map((n, index) => `ids[${index}]=${n}`).join("&")}`,
		{},
		"GET"
	)
		.then((resp) => resp)
		.catch((err) => {
			throw err;
		});

export const getUsers = (adminIdsArray) =>
	invokeApi(
		$axios,
		`/user/all?${adminIdsArray.map((n, index) => `ids[${index}]=${n}`).join("&")}`,
		{},
		"GET"
	)
		.then((resp) => resp)
		.catch((err) => {
			throw err;
		});

import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getStates } from "../../store/actions/place/place.action";

export const useIndianStates = () => {
	const dispatch = useDispatch();
	const States = useSelector((state) => state.place.states);

	useEffect(() => {
		if (States.length === 0) dispatch(getStates());
	}, [dispatch, States.length]);

	const getIndianStates = () => {
		return States;
	};

	return { getIndianStates };
};

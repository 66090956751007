export const aspectRatioDependancy = async (acceptedFiles, setFile, files = []) => {
	try {
		for (let i = 0; i < acceptedFiles.length; i++) {
			const image = new Image();
			image.src = URL.createObjectURL(acceptedFiles[i]);

			await new Promise((resolve, reject) => {
				image.addEventListener("load", async () => {
					try {
						const ratio = `${image.width}` / ` ${image.height}`;
						if (ratio === 1 || ratio === 0.9981884057971014) {
							setFile([...files, acceptedFiles[i]]);
							resolve();
						} else {
							reject({ message: "Image allow to upload must be 200 X 200" });
						}
					} catch (err) {
						reject(err);
					}
				});
			});
		}
	} catch (error) {
		throw error;
	}
};

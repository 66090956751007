import { useCallback, useEffect, useMemo, useState } from "react";
import { UserOutlined } from "@ant-design/icons";
import { Button, Card, Col, Form, message, Row, Space, Typography } from "antd";
import axios from "axios";
import { useDropzone } from "react-dropzone";
import { ErrorBoundary } from "react-error-boundary";
import { useDispatch, useSelector } from "react-redux";
import { NavLink, useHistory, useLocation } from "react-router-dom";
import BasicLayout from "../../../components/BasicLayout";
import Center from "../../../components/common/Center";
import FallBack from "../../../components/common/FallBack/FallBack";
import Notification from "../../../components/common/Notification";
import { WordCountDisplayer } from "../../../components/common/WordCountDisplayer";
import InputField from "../../../components/Form/InputField";
import Upload from "../../../components/Form/Upload";
import UploadPDF from "../../../components/Form/UploadPDF";
import {
	addBrand,
	brandAddSuccess,
	getSingleBrand,
	updateBrand,
	updateBrandFailure,
} from "../../../store/actions/products/brands/brand.action";
import { formatFileName } from "../../../utils/helper";
import { imageSizeDependancy } from "../../../utils/imageSize";
import { wordCount } from "../../../utils/wordCount";

const BrandForm = (props) => {
	const history = useHistory();
	const dispatch = useDispatch();
	const [file, setFile] = useState([]);
	const { Title } = Typography;
	const [form] = Form.useForm();
	const brand = useSelector((state) => state.brand.brand);
	const admin = useSelector((state) => state?.admin?.admin || []);

	const [uploadMedia, setUploadMedia] = useState("");

	const brandId = +props.match.params.id;

	const currentLocation = props.location.pathname;
	const location = useLocation();
	const softdelete = new URLSearchParams(location.search).get("soft-delete");
	const onImageDrop = useCallback((acceptedFiles, fileRejections) => {
		try {
			setFile(acceptedFiles);
			imageSizeDependancy(fileRejections);
		} catch (_err) {}
	}, []);

	const {
		getRootProps: getImageRootProps,
		getInputProps: getImageInputProps,
		isDragActive: isImageDragActive,
	} = useDropzone({
		onDrop: onImageDrop,
		accept: "image/png",
		multiple: false,
		maxSize: "1000000",
	});
	const [productCatelogueFile, setProductCatelogueFile] = useState(undefined);

	const onPdfDrop = useCallback((acceptedFiles) => {
		try {
			setProductCatelogueFile(acceptedFiles);
		} catch (_err) {}
	}, []);

	const {
		getRootProps: getPdfRootProps,
		getInputProps: getPdfInputProps,
		isDragActive: isPdfDragActive,
	} = useDropzone({
		onDrop: onPdfDrop,
		accept: ".pdf",
		multiple: false,
		maxSize: "5000000",
	});

	const handleError = (error) => {
		message.error(error.message);
	};
	useEffect(() => {
		if (brandId) {
			dispatch(getSingleBrand(brandId, softdelete)).catch((err) => {
				Notification("error", err, "", "topRight");
				history.push("/products/brands");
			});
		}
	}, [brandId, history, softdelete, dispatch]);

	const onFinish = async (values) => {
		try {
			if (currentLocation === "/products/brands/add") {
				if (file.length === 0) {
					Notification("error", "Please upload a valid media", "", "topRight");
					return;
				}
				const fileName = formatFileName(
					file[0].name.split(".")[0],
					file[0].name.split(".").pop(),
					admin
				);
				const fileType = file[0].type;

				const productPdfFileName = formatFileName(
					productCatelogueFile[0].name.split(".")[0],
					productCatelogueFile[0].name.split(".").pop(),
					admin
				);
				const productPdfFileType = productCatelogueFile[0].type;
				const data = {
					...values,
					fileName,
					fileType,
					productPdfFileType,
					productPdfFileName,
				};
				const resp = await dispatch(addBrand(data));
				if (resp && resp?.signedUrl && resp?.productCatalogueSignedUrl) {
					const options = {
						headers: {
							"Content-Type": uploadMedia[0].type,
						},
					};
					const optionsForProductCateloguePdf = {
						headers: {
							"Content-Type": productCatelogueFile[0].type,
						},
					};
					try {
						await axios.put(
							resp.signedUrl,

							uploadMedia[0],
							options
						);
						await axios.put(
							resp.productCatalogueSignedUrl,

							productCatelogueFile[0],
							optionsForProductCateloguePdf
						);
						history.push("/products/brands");
						Notification("success", "Media has been succesfully added.", "", "topRight");
					} catch (error) {
						const errorMessage = error?.message || "Something went wrong";
						Notification("error", errorMessage, "", "top");
					}
					await dispatch(brandAddSuccess(resp));
				}
			} else {
				try {
					await wordCount(values.updateReason, 10);

					if (file?.length === 0 && productCatelogueFile?.length === 0) {
						try {
							const resp = await dispatch(updateBrand(brandId, values));
							const message = resp.message || "Updated successfully";
							history.push("/products/brands");
							Notification("success", message, "", "topRight");
						} catch (err) {
							const errorMessage =
								(err?.response && err?.response?.data?.message) || "Some thing went wrong";
							dispatch(updateBrandFailure(errorMessage));
							Notification("error", "Error Occurred", errorMessage, "topRight");
						} finally {
							setFile([]);
						}
					} else {
						if (file && file?.length > 0) {
							const fileName = formatFileName(
								file[0].name.split(".")[0],
								file[0].name.split(".").pop(),
								admin
							);
							const fileType = file[0].type;
							values = {
								...values,
								fileName,
								fileType,
							};
						}

						if (productCatelogueFile && productCatelogueFile?.length > 0) {
							const productPdfFileName = formatFileName(
								productCatelogueFile[0].name.split(".")[0],
								productCatelogueFile[0].name.split(".").pop(),
								admin
							);
							const productPdfFileType = productCatelogueFile[0].type;
							values = {
								...values,
								productPdfFileName,
								productPdfFileType,
							};
						}
						const resp = await dispatch(updateBrand(brandId, values));

						if (resp && resp?.data?.signedUrl) {
							const options = {
								headers: {
									"Content-Type": uploadMedia[0].type,
								},
							};
							await axios.put(resp.data.signedUrl, uploadMedia[0], options);
						}

						if (resp && resp?.data?.productCatalogueSignedUrl) {
							const optionsForProductCateloguePdf = {
								headers: {
									"Content-Type": productCatelogueFile[0].type,
								},
							};
							await axios.put(
								resp?.data?.productCatalogueSignedUrl,
								productCatelogueFile[0],
								optionsForProductCateloguePdf
							);
						}

						history.push("/products/brands");
						Notification("success", "Media has been succesfully updated.", "", "topRight");
					}
				} catch (error) {
					if (error.message) {
						return Notification("error", "Error Occurred", error.message, "topRight");
					}
					const errorMessage = error.message || "Something went wrong";
					Notification("error", "", errorMessage, "topRight");
				}
			}
		} catch (err) {
			const errorMessage =
				(err?.response && err?.response?.data?.message) || "Something went wrong";
			return Notification("error", "Error Occurred", errorMessage, "topRight");
		}
	};

	const defaultValues = useMemo(
		() => ({
			code: brandId ? brand.code : "",
			name: brandId ? brand.name : "",
		}),
		[brandId, brand.code, brand.name]
	);

	useEffect(() => {
		form.setFieldsValue(defaultValues);
	}, [form, defaultValues]);

	const handleCancel = () => {
		Notification("info", "Job action has been cancelled", "", "topRight");
	};

	return (
		<BasicLayout accessLevel={props.accessLevel}>
			<ErrorBoundary FallbackComponent={FallBack} onError={handleError}>
				<Row type="flex" justify="center" align="middle" id="form-container">
					<Center height="4rem">
						{brandId ? (
							<Title level={2} className="update-or-add-title">
								Update Brand
							</Title>
						) : (
							<Title level={2} className="update-or-add-title">
								Add New Brand
							</Title>
						)}
					</Center>
					<Col>
						<Card className="form-card">
							<Form
								form={form}
								name="basic"
								layout="vertical"
								onFinish={onFinish}
								initialValues={defaultValues}
							>
								<InputField
									rules={[
										{
											required: true,
										},
										{
											min: 1,
											message: "Brand Name be minimum 1 character.",
										},
										{
											max: 40,
											message: "Brand Name be minimum 40 character.",
										},
									]}
									label="Brand Name"
									name="name"
									placeholder="Please enter brand name"
									prefix={<UserOutlined />}
								/>

								<InputField
									rules={[
										{
											required: true,
										},
										{
											min: 10,
											message: "Code must be minimum 10 characters.",
										},
										{
											max: 10,
											message: "Code must be maximum 10 characters.",
										},
									]}
									label="Code"
									name="code"
									placeholder="Please enter code"
									prefix={<UserOutlined />}
								/>

								<Title
									level={5}
									style={{
										fontWeight: "400",
										fontSize: "18px",
									}}
								>
									<span
										style={{
											color: "#f08080",
											fontWeight: "thin",
											fontSize: "16px",
										}}
									>
										*
									</span>{" "}
									Upload Products PDF
								</Title>
								<UploadPDF
									url={brandId ? brand.productCatalogueUrl : ""}
									getInputProps={getPdfInputProps}
									getRootProps={getPdfRootProps}
									isDragActive={isPdfDragActive}
									pdf={productCatelogueFile}
								/>
								<br></br>
								<br></br>
								<br></br>

								<Upload
									getInputProps={getImageInputProps}
									getRootProps={getImageRootProps}
									type="image"
									isDragActive={isImageDragActive}
									file={file}
									url={brandId ? brand.url : ""}
									setUploadMedia={setUploadMedia}
								/>
								{brandId ? (
									<WordCountDisplayer
										label="Update Reason"
										name="updateReason"
										placeholder="Please enter reason"
										rules={[
											{
												required: true,
											},
											{
												message: "update reason must be minimum 10 words.",
											},
										]}
									/>
								) : null}

								<Form.Item style={{ marginTop: "20px" }}>
									<Center height="0px">
										<Space>
											<NavLink to="/products/brands">
												<Button danger type="primary" onClick={handleCancel}>
													Cancel
												</Button>
											</NavLink>
											<Button type="primary" htmlType="submit">
												{brandId ? "Update" : "Add"}
											</Button>
										</Space>
									</Center>
								</Form.Item>
							</Form>
						</Card>
					</Col>
				</Row>
			</ErrorBoundary>
		</BasicLayout>
	);
};

export default BrandForm;

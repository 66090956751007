import {
	ADD_SPARE_PART_SUCCESS,
	GET_SPARE_PARTS_SUCCESS,
	GET_SINGLE_SPARE_PART_SUCCESS,
	MAKE_SINGLE_SPARE_PART_EMPTY,
	RESTORE_SINGLE_SPARE_PART_SUCCESS,
	SOFT_DELETE_SINGLE_SPARE_PART_SUCCESS,
	UPDATE_SPARE_PART_SUCCESS,
	GET_SINGLE_SPARE_PART_FAILURE,
} from "../actions/products/spareparts/spareparts.actionType";
import globalState from "../state";

const requestRegex = /_REQUEST/;
const errorRegex = /_FAILURE/;
const successRegex = /_SUCCESS/;

const initialState = {
	errorMessage: "",
	isLoading: false,
	spareparts: [],
	sparepart: {},
	...globalState,
};

const SparePartReducer = (state = initialState, action) => {
	const isRequest = requestRegex.test(action.type);
	const isError = errorRegex.test(action.type);
	const isSuccess = successRegex.test(action.type);

	if (isRequest) {
		return { ...state, isLoading: true, errorMessage: "" };
	}
	if (isError) {
		return { ...state, isLoading: false, errorMessage: action.message };
	}
	if (isSuccess) {
		state = { ...state, isLoading: false, errorMessage: "" };
	}

	switch (action.type) {
		case GET_SPARE_PARTS_SUCCESS:
			return { ...state, spareparts: action.data };

		case GET_SINGLE_SPARE_PART_SUCCESS:
			return { ...state, sparepart: action.data };

		case ADD_SPARE_PART_SUCCESS:
			return { ...state };

		case SOFT_DELETE_SINGLE_SPARE_PART_SUCCESS:
			let softDeletedSparePartId = action.data;
			let afterSoftDeletedData = state.spareparts.filter(
				(data) => data.id.toString() !== softDeletedSparePartId.toString()
			);
			return { ...state, spareparts: afterSoftDeletedData };

		case MAKE_SINGLE_SPARE_PART_EMPTY:
			return { ...state, sparepart: action.data };

		case RESTORE_SINGLE_SPARE_PART_SUCCESS:
			return { ...state };

		case UPDATE_SPARE_PART_SUCCESS:
			return { ...state };

		case GET_SINGLE_SPARE_PART_FAILURE:
			return { ...state, sparepart: {} };

		default:
			return state;
	}
};

export default SparePartReducer;

import {
	ADD_JOB_SUCCESS,
	ADD_SINGLE_CATEGORY_SUCCESS,
	DELETE_CATEGORIES_SUCCESS,
	DELETE_SINGLE_JOB_SUCCESS,
	GET_APPLICATION_SUCCESS,
	GET_CATEGORIES_SUCCESS,
	GET_JOB_SUCCESS,
	GET_SINGLE_APPLICATION_SUCCESS,
	GET_SINGLE_CATEGORY_SUCCESS,
	GET_SINGLE_JOB_SUCCESS,
	SOFT_DELETE_CATEGORIES_SUCCESS,
	SOFT_DELETE_SINGLE_APPLICATION_SUCCESS,
	SOFT_DELETE_SINGLE_JOB_SUCCESS,
	UPDATE_SINGLE_CATEGORY_SUCCESS,
	UPDATE_SINGLE_JOB_SUCCESS,
} from "../actions/Careers/careers.actionType";
import globalState from "../state";

const requestRegex = /_REQUEST/;
const errorRegex = /_FAILURE/;
const successRegex = /_SUCCESS/;

const initialState = {
	errorMessage: "",
	isLoading: false,
	jobs: [],
	job: {},
	applications: [],
	application: {},
	categories: [],
	category: {},
	...globalState,
};

const CareersReducer = (state = initialState, action) => {
	const isRequest = requestRegex.test(action.type);
	const isError = errorRegex.test(action.type);
	const isSuccess = successRegex.test(action.type);

	if (isRequest) {
		return { ...state, isLoading: true, errorMessage: "" };
	}
	if (isError) {
		return { ...state, isLoading: false, errorMessage: action.message };
	}
	if (isSuccess) {
		state = { ...state, isLoading: false, errorMessage: "" };
	}
	switch (action.type) {
		case GET_JOB_SUCCESS:
			return {
				...state,
				jobs: action.data,
			};

		case GET_SINGLE_JOB_SUCCESS:
			return {
				...state,
				job: action.data,
			};

		case ADD_JOB_SUCCESS:
			const newJobs = state.jobs;
			newJobs.push(action.data);
			return {
				...state,
				jobs: newJobs,
			};

		case UPDATE_SINGLE_JOB_SUCCESS:
			let resp = { ...state };
			const id = action.data.id;
			const jobData = action.data;
			if (state.jobs.length > 0) {
				const index = state.jobs.findIndex((job) => job.id === id);
				const newArray = [...state.jobs];
				newArray[index] = {
					...jobData,
					createdAt: state.jobs[index].createdAt,
					updatedAt: Date.now(),
				};
				resp.jobs = newArray;
			}
			const newObject = {
				jobData,
				createdAt: state.job.createdAt,
				updatedAt: Date.now(),
			};
			resp.job = newObject;
			return resp;

		case DELETE_SINGLE_JOB_SUCCESS:
			const deletionId = action.data;
			const nonDeletedJobs = state.jobs.filter((job) => job.id !== deletionId);
			return {
				...state,
				jobs: nonDeletedJobs,
			};

		case GET_APPLICATION_SUCCESS:
			return {
				...state,
				applications: action.data,
			};

		case GET_SINGLE_APPLICATION_SUCCESS:
			return {
				...state,
				application: action.data,
			};

		case GET_CATEGORIES_SUCCESS:
			return {
				...state,
				categories: action.data,
			};

		case GET_SINGLE_CATEGORY_SUCCESS:
			return {
				...state,
				category: action.data,
			};

		case ADD_SINGLE_CATEGORY_SUCCESS:
			const newCategories = state.categories;
			newCategories.push(action.data);
			return {
				...state,
				categories: newCategories,
			};

		case UPDATE_SINGLE_CATEGORY_SUCCESS:
			let categoryResp = { ...state };
			const categoryId = action.data.id;
			const categoryData = action.data;
			if (state.categories.length > 0) {
				const index = state.categories.findIndex((category) => category.id === categoryId);
				const newArray = [...state.categories];
				newArray[index] = {
					...categoryData,
					createdAt: state.categories[index].createdAt,
					updatedAt: Date.now(),
				};
				categoryResp.categories = newArray;
			}
			const newCategoryObject = {
				categoryData,
				createdAt: state.categories.createdAt,
				updatedAt: Date.now(),
			};
			categoryResp.category = newCategoryObject;
			return categoryResp;

		case DELETE_CATEGORIES_SUCCESS:
			const categoryDeletionId = action.data;
			const nonDeletedCategories = state.categories.filter(
				(category) => category.id !== categoryDeletionId
			);
			return {
				...state,
				categories: nonDeletedCategories,
			};

		case SOFT_DELETE_CATEGORIES_SUCCESS:
			const categorySoftDeletionId = action.data;
			const nonSoftDeletedCategories = state.categories.filter(
				(category) => category.id !== categorySoftDeletionId
			);
			return {
				...state,
				categories: nonSoftDeletedCategories,
			};

		case SOFT_DELETE_SINGLE_JOB_SUCCESS:
			const softDeletionId = action.data;
			const nonSoftDeletedJobs = state.jobs.filter((job) => job.id !== softDeletionId);
			return {
				...state,
				jobs: nonSoftDeletedJobs,
			};

		case SOFT_DELETE_SINGLE_APPLICATION_SUCCESS:
			const softApplicationDeletionId = action.data;
			const nonSoftDeletedApplications = state.applications.filter(
				(application) => application.id !== softApplicationDeletionId
			);
			return {
				...state,
				applications: nonSoftDeletedApplications,
			};

		default:
			return state;
	}
};

export default CareersReducer;

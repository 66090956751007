import Notification from "../../../components/common/Notification";
import * as salesPointApi from "../../../services/salesPoint";
import {
	GET_SALESPOINT_CONTACTS_FAILURE,
	GET_SALESPOINT_CONTACTS_REQUEST,
	GET_SALESPOINT_CONTACTS_SUCCESS,
	GET_SALESPOINT_SINGLE_CONTACT_FAILURE,
	GET_SALESPOINT_SINGLE_CONTACT_REQUEST,
	GET_SALESPOINT_SINGLE_CONTACT_SUCCESS,
	SOFTDELETE_SALESPOINT_SINGLE_CONTACT_FAILURE,
	SOFTDELETE_SALESPOINT_SINGLE_CONTACT_REQUEST,
	SOFTDELETE_SALESPOINT_SINGLE_CONTACT_SUCCESS,
} from "./salesPoint.actionType";

export const requestSalesPointContacts = () => {
	return {
		type: GET_SALESPOINT_CONTACTS_REQUEST,
	};
};

export const getSalesPointContactsSuccess = (data) => {
	return {
		type: GET_SALESPOINT_CONTACTS_SUCCESS,
		data,
	};
};

export const getSalesPointContactsFailure = (message) => {
	return {
		type: GET_SALESPOINT_CONTACTS_FAILURE,
		message,
	};
};

export const getSalesPointContacts = () => {
	return (dispatch) => {
		dispatch(requestSalesPointContacts());
		return salesPointApi
			.salesPointContact()
			.then((resp) => {
				const data = resp.data.data;
				dispatch(getSalesPointContactsSuccess(data));
			})
			.catch((err) => {
				const errorMessage = err.response && err.response.data.message;
				dispatch(getSalesPointContactsFailure(errorMessage));
			});
	};
};

export const requestSalesPointSingleContactData = () => {
	return {
		type: GET_SALESPOINT_SINGLE_CONTACT_REQUEST,
	};
};

export const getSalesPointSingleContactSuccess = (data) => {
	return {
		type: GET_SALESPOINT_SINGLE_CONTACT_SUCCESS,
		data,
	};
};

export const getSalesPointSingleContactFailure = (message) => {
	return {
		type: GET_SALESPOINT_SINGLE_CONTACT_FAILURE,
		message,
	};
};

export const getSalesPointSingleContact = (id, softdelete) => {
	return (dispatch) => {
		dispatch(requestSalesPointSingleContactData());
		return salesPointApi
			.salesPointSingleContact(id, softdelete)
			.then((resp) => {
				const data = resp.data.data;
				dispatch(getSalesPointSingleContactSuccess(data));
			})
			.catch((err) => {
				const errorMessage = err.response && err.response.data.message;
				dispatch(getSalesPointSingleContactFailure(errorMessage));
				throw errorMessage;
			});
	};
};

export const requestSalesPointSingleContactSoftDelete = () => {
	return {
		type: SOFTDELETE_SALESPOINT_SINGLE_CONTACT_REQUEST,
	};
};

export const softDeleteSalesPointSingleContactSuccess = (data) => {
	return {
		type: SOFTDELETE_SALESPOINT_SINGLE_CONTACT_SUCCESS,
		data,
	};
};

export const softDeleteSalesPointSingleContactFailure = (message) => {
	return {
		type: SOFTDELETE_SALESPOINT_SINGLE_CONTACT_FAILURE,
		message,
	};
};

export const softDeleteSalesPointSingleContact = (id, reason) => {
	return (dispatch) => {
		dispatch(requestSalesPointSingleContactSoftDelete());
		return salesPointApi
			.softDeleteSalesPointSingleContact(id, reason)
			.then((resp) => {
				dispatch(softDeleteSalesPointSingleContactSuccess(id));
				Notification("success", resp.data.message, "", "topRight");
			})
			.catch((err) => {
				const errorMessage = err.response && err.response.data.message;
				dispatch(softDeleteSalesPointSingleContactFailure(errorMessage));
				Notification("error", errorMessage, "", "topRight");
			});
	};
};

import { Card, Image, message } from "antd";
import { useEffect, useMemo } from "react";
import { ErrorBoundary } from "react-error-boundary";
import { useDispatch, useSelector } from "react-redux";
import { NavLink, useHistory, useLocation } from "react-router-dom";
import BasicLayout from "../../../components/BasicLayout";
import FallBack from "../../../components/common/FallBack/FallBack";
import Notification from "../../../components/common/Notification";
import { CustomTab } from "../../../components/common/Tab/Tab";
import { getSingleImplements } from "../../../store/actions/products/implements/implements.action";

const ViewImplement = (props) => {
	const implementsId = +props.match.params.id;
	const dispatch = useDispatch();
	const implement = useSelector((state) => state.implements.implement || {});

	const location = useLocation();
	const softdelete = new URLSearchParams(location.search).get("soft-delete");
	const history = useHistory();
	const admin = useSelector((state) => state.admin.admin || []);

	const permissionArr = useMemo(() => {
		if (admin && admin.template) {
			const { permissions } = admin.template;
			return permissions;
		} else if (admin && admin.accessLevel === "superAdmin") {
			return "all";
		}
		return [];
	}, [admin]);

	useEffect(() => {
		dispatch(getSingleImplements(implementsId, softdelete)).catch((err) => {
			Notification("error", err, "", "topRight");
			history.push("/products/implements");
		});
	}, [dispatch, history, softdelete, implementsId]);

	let data = [];
	let isOrderEmpty = Object.keys(implement).length === 0;
	if (!isOrderEmpty) {
		data = [
			{ title: "Id", description: implement?.id },
			{
				title: "Name",
				description: implement?.name,
			},
			{
				title: "Code",
				description: implement?.code,
			},
			{
				title: "Price",
				description: implement.price ? implement.price : "NA",
			},
			{
				title: "Stock", 
				description: implement.stock ? implement.stock : "NA",
			},
			{
				title: "Discount",
				description: implement.discount ? `${implement.discount}%` : "NA",
			},
			{
				title: "HSN/SAC Code",
				description: implement?.hsnCode ? implement?.hsnCode?.code : "NA",
			},
			{
				title: "Received",
				description: new Date(implement.createdAt).toLocaleString(),
			},
			{
				title: "Implement Sub Assembly",
				description:
					implement?.implementsSubAssemblies?.length !== 0 ? (
						implement?.implementsSubAssemblies?.map((data, index) => (
							<p key={index}>
								<NavLink to={`/products/implement/sub-assembly/${data.id}`}>{data.name}</NavLink>
							</p>
						))
					) : (
						<p>NA</p>
					),
			},
			{
				title: "Image",
				description: (
					<div className="gallery-images">
						<div className="image-holder-container">
							<Image width={250} height={180} src={implement.url} />
						</div>
					</div>
				),
			},
		];
	}

	const handleError = (error) => {
		message.error(error.message);
	};

	let operationData = {
		deletedBy: implement?.deletedBy,
		updatedBy: implement?.updatedBy,
		restoredBy: implement?.restoredBy,
	};

	return (
		<BasicLayout accessLevel={props.accessLevel}>
			<ErrorBoundary FallbackComponent={FallBack} onError={handleError}>
				<Card className="enqiury-detail-card" data-testid="enqiury-detail-card">
					<CustomTab
						update={
							permissionArr === "all"
								? "true"
								: permissionArr?.products?.implements.filter((item) => item === "update")?.length >
								  0
								? "true"
								: false
						}
						data={data}
						operationData={operationData}
						redirectUrl={`/products/implements/update/${implementsId}`}
					/>
				</Card>
			</ErrorBoundary>
		</BasicLayout>
	);
};

export default ViewImplement;

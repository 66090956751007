import {
	ADD_TECHNICAL_SPECIFICATION_SUCCESS,
	GET_SINGLE_TECHNICAL_SPECIFICATION_SUCCESS,
	GET_TECHNICAL_SPECIFICATIONS_SUCCESS,
	RESTORE_SINGLE_TECHNICAL_SPECIFICATION_SUCCESS,
	SOFT_DELETE_SINGLE_TECHNICAL_SPECIFICATION_SUCCESS,
	UPDATE_TECHNICAL_SPECIFICATION_SUCCESS,
} from "../actions/products/technicalspecifications/technicalspecification.actionType";
import globalState from "../state";

const requestRegex = /_REQUEST/;
const errorRegex = /_FAILURE/;
const successRegex = /_SUCCESS/;

const initialState = {
	errorMessage: "",
	isLoading: false,
	technicalspecifications: [],
	technicalspecification: {},
	...globalState,
};

const TechnicalSpecificationReducer = (state = initialState, action) => {
	const isRequest = requestRegex.test(action.type);
	const isError = errorRegex.test(action.type);
	const isSuccess = successRegex.test(action.type);

	if (isRequest) {
		return { ...state, isLoading: true, errorMessage: "" };
	}
	if (isError) {
		return { ...state, isLoading: false, errorMessage: action.message };
	}
	if (isSuccess) {
		state = { ...state, isLoading: false, errorMessage: "" };
	}

	switch (action.type) {
		case GET_TECHNICAL_SPECIFICATIONS_SUCCESS:
			return { ...state, technicalspecifications: action.data };

		case GET_SINGLE_TECHNICAL_SPECIFICATION_SUCCESS:
			return { ...state, technicalspecification: action.data };

		case SOFT_DELETE_SINGLE_TECHNICAL_SPECIFICATION_SUCCESS:
			let softDeletedTechnicalSpecificationId = action.data;
			let afterSoftDeletedData = state.technicalspecifications.filter(
				(data) => data.id.toString() !== softDeletedTechnicalSpecificationId.toString()
			);
			return { ...state, technicalspecifications: afterSoftDeletedData };

		case ADD_TECHNICAL_SPECIFICATION_SUCCESS:
			return { ...state };

		case RESTORE_SINGLE_TECHNICAL_SPECIFICATION_SUCCESS:
			return { ...state };

		case UPDATE_TECHNICAL_SPECIFICATION_SUCCESS:
			return { ...state };

		default:
			return state;
	}
};

export default TechnicalSpecificationReducer;

import { invokeApi } from "../utils/api";
import { $axios } from "../utils/axios";

export const getAllModels = () =>
    invokeApi($axios, "/product/model", {}, "GET")
        .then((resp) => resp)
        .catch((err) => {
            throw err;
        });

export const getSingleModel = (modelId, softdelete) =>
    invokeApi(
        $axios,
        `/product/model/${
            softdelete === "true" ? "soft-delete/" : ""
        }${modelId}`,
        {},
        "GET"
    )
        .then((resp) => resp)
        .catch((err) => {
            throw err;
        });

export const softDeleteSingleModel = (modelId, reason) =>
    invokeApi(
        $axios,
        `/product/model/soft-delete/${modelId}?reason=${reason}`,
        {},
        "DELETE"
    )
        .then((resp) => resp)
        .catch((err) => {
            throw err;
        });

export const restoreModel = (modelId) =>
    invokeApi($axios, `/product/model/restore/${modelId}`, {}, "PUT")
        .then((resp) => resp)
        .catch((err) => {
            throw err;
        });

export const addModel = (data) =>
    invokeApi($axios, "/product/model", data, "POST")
        .then((resp) => resp)
        .catch((err) => {
            throw err;
        });

export const updateModel = (id, data) =>
    invokeApi($axios, `/product/model/${id}`, data, "PUT")
        .then((resp) => resp)
        .catch((err) => {
            throw err;
        });


import { UserOutlined } from "@ant-design/icons";
import { Button, Card, Col, Form, message, Row, Select, Space, Typography } from "antd";
import axios from "axios";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useDropzone } from "react-dropzone";
import { ErrorBoundary } from "react-error-boundary";
import { useDispatch, useSelector } from "react-redux";
import { NavLink, useHistory, useLocation } from "react-router-dom";
import BasicLayout from "../../../components/BasicLayout";
import Center from "../../../components/common/Center";
import FallBack from "../../../components/common/FallBack/FallBack";
import Notification from "../../../components/common/Notification";
import { WordCountDisplayer } from "../../../components/common/WordCountDisplayer";
import InputField from "../../../components/Form/InputField";
import Upload from "../../../components/Form/Upload";
import {
	addImplements,
	getSingleImplements,
	ImplementsAddFailure,
	updateImplements,
	updateImplementsFailure,
} from "../../../store/actions/products/implements/implements.action";
import { getAllImplementsSubAssemblies } from "../../../store/actions/products/implementsSubAssembly/implementsSubAssemblies.action";
import { formatFileName } from "../../../utils/helper";
import { aspectRatioDependancy } from "../../../utils/imageAspectRatio";
import { imageSizeDependancy } from "../../../utils/imageSize";
import { wordCount } from "../../../utils/wordCount";
import CommonSelect from "../../../components/Form/Select";
import Spinner from "../../../components/common/Spinner";
import "./implementForm.css";
import { getAllHSNCodes } from "../../../store/actions/products/HSNCode/hsnCode.action";

const ImplementsForm = (props) => {
	const history = useHistory();
	const dispatch = useDispatch();
	const [file, setFile] = useState([]);
	const location = useLocation();
	const softdelete = new URLSearchParams(location.search).get("soft-delete");
	const [selectedImplementsSubAssembliesValue, setSelectedImplementsSubAssembliesValue] = useState(
		[]
	);

	const { Title } = Typography;
	const [form] = Form.useForm();
	const admin = useSelector((state) => state?.admin?.admin || {});
	const hsnCodes = useSelector((state) => state.hsnCode.hsnCodes || []);
	const implement = useSelector((state) => state.implements.implement);
	const implementsSubAssemblies = useSelector(
		(state) => state.implementsSubAssembly.implementsSubAssemblies || []
	);
	const [isHSNCodeLoading, setIsHSNCodeLoading] = useState(false);

	const [uploadMedia, setUploadMedia] = useState("");

	const implementsId = +props.match.params.id;

	const currentLocation = props.location.pathname;

	const onImageDrop = useCallback(async (acceptedFiles, fileRejections) => {
		try {
			imageSizeDependancy(fileRejections);

			await aspectRatioDependancy(acceptedFiles, setFile);
		} catch (error) {
			const errorMessage = error?.message || "Something went wrong";
			Notification("error", errorMessage, "", "topRight");
		}
	}, []);

	const {
		getRootProps: getImageRootProps,
		getInputProps: getImageInputProps,
		isDragActive: isImageDragActive,
	} = useDropzone({
		onDrop: onImageDrop,
		accept: " image/png",
		multiple: false,
		maxSize: "1000000",
	});

	const handleError = (error) => {
		message.error(error.message);
	};

	useEffect(() => {
		async function fetchData() {
			setIsHSNCodeLoading(true);
			await dispatch(getAllHSNCodes());
			setIsHSNCodeLoading(false);
		}
		fetchData();
	}, [dispatch]);

	useEffect(() => {
		if (implementsId) {
			dispatch(getSingleImplements(implementsId, softdelete)).catch((err) => {
				Notification("error", err, "", "topRight");
				history.push("/products/implements");
			});
		}
	}, [implementsId, history, dispatch, softdelete]);

	const HSNCodeChildren = useMemo(
		() =>
			hsnCodes?.map((hsnCode) => ({
				key: `${hsnCode.code} `,
				value: hsnCode.id,
				title: hsnCode.code,
			})),
		[hsnCodes]
	);

	const onFinish = async (values) => {
		try {
			if (currentLocation === "/products/implements/add") {
				if (file.length === 0) {
					Notification("error", "Please upload a valid Part diagram", "", "topRight");
					return;
				}
				const fileName = formatFileName(
					file[0].name.split(".")[0],
					file[0].name.split(".").pop(),
					admin
				);
				const fileType = file[0].type;
				const data = {
					...values,

					fileName,
					fileType,
					implementsSubAssemblies: selectedImplementsSubAssembliesValue || [],
				};
				const resp = await dispatch(addImplements(data));
				if (resp && resp.signedUrl) {
					const options = {
						headers: {
							"Content-Type": uploadMedia[0].type,
						},
					};
					try {
						await axios.put(resp.signedUrl, uploadMedia[0], options);
						history.push("/products/implements");
						Notification("success", "Implements has been succesfully added.", "", "topRight");
					} catch (error) {
						const errorMessage = error.message || "Something went wrong";
						dispatch(ImplementsAddFailure(errorMessage));
						Notification("error", errorMessage, "", "top");
					}
				}
			} else {
				await wordCount(values.updateReason, 10);
				if (file.length === 0) {
					try {
						let data = {
							...values,
							implementsSubAssemblies: selectedImplementsSubAssembliesValue || [],
						};
						const resp = await dispatch(updateImplements(implementsId, data));
						const message = resp.message || "Updated successfully";
						history.push("/products/implements");
						Notification("success", message, "", "topRight");
					} catch (err) {
						const errorMessage =
							(err?.response && err?.response?.data?.message) || "Some thing went wrong";
						dispatch(updateImplementsFailure(errorMessage));
						Notification("error", "Error Occurred", errorMessage, "topRight");
					} finally {
						setFile([]);
					}
				} else {
					const fileName = formatFileName(
						file[0].name.split(".")[0],
						file[0].name.split(".").pop(),
						admin
					);
					const fileType = file[0].type;
					const data = {
						...values,
						fileName,
						fileType,
						implementsSubAssemblies: selectedImplementsSubAssembliesValue || [],
					};
					const resp = await dispatch(updateImplements(implementsId, data));

					if (resp && resp.data.signedUrl) {
						const options = {
							headers: {
								"Content-Type": uploadMedia[0].type,
							},
						};
						try {
							await axios.put(resp.data.signedUrl, uploadMedia[0], options);
							history.push("/products/implements");
							Notification("success", "Implements has been succesfully updated.", "", "topRight");
						} catch (error) {
							const errorMessage = error.message || "Something went wrong";
							Notification("error", "", errorMessage, "topRight");
						}
					}
				}
			}
		} catch (err) {
			if (err.message) {
				return Notification("error", "Error Occurred", err.message, "topRight");
			}
			const errorMessage =
				(err?.response && err?.response?.data?.message) || "Something went wrong";
			return Notification("error", "Error Occurred", errorMessage, "topRight");
		}
	};

	const defaultValues = useMemo(
		() => ({
			code: implementsId ? implement.code : "",
			name: implementsId ? implement.name : "",
			price: implementsId ? implement.price : undefined,
			stock: implementsId ? implement.stock : undefined,
			discount: implementsId ? implement.discount : undefined,
			hsnCodeId: implementsId ? implement?.hsnCode?.id : undefined,
		}),
		[
			implementsId,
			implement.code,
			implement.name,
			implement.discount,
			implement.stock,
			implement.price,
			implement?.hsnCode?.id,
		] 
	);

	useEffect(() => {
		form.setFieldsValue(defaultValues);
	}, [form, defaultValues]);

	const handleCancel = () => {
		Notification("info", "Implements action has been cancelled", "", "topRight");
	};

	useEffect(() => {
		dispatch(getAllImplementsSubAssemblies());
	}, [dispatch]);

	// For Select Sub Assemblies
	const { Option } = Select;
	const children = [];

	for (let i = 0; i < implementsSubAssemblies?.length; i++) {
		children.push(
			<Option key={implementsSubAssemblies[i]?.id} title={implementsSubAssemblies[i]?.name}>
				{implementsSubAssemblies[i]?.name} ({implementsSubAssemblies[i]?.code})
			</Option>
		);
	}

	function handleChange(value) {
		setSelectedImplementsSubAssembliesValue(value);
	}
	if (isHSNCodeLoading) {
		return (
			<Center>
				<Spinner />
			</Center>
		);
	}

	return (
		<BasicLayout accessLevel={props.accessLevel}>
			<ErrorBoundary FallbackComponent={FallBack} onError={handleError}>
				<Row type="flex" justify="center" align="middle" id="form-container">
					<Center height="4rem">
						{implementsId ? (
							<Title level={2} className="update-or-add-title">
								Update Implements
							</Title>
						) : (
							<Title level={2} className="update-or-add-title">
								Add New Implements
							</Title>
						)}
					</Center>
					<Col>
						<Card className="form-card">
							<Form
								form={form}
								name="basic"
								layout="vertical"
								onFinish={onFinish}
								initialValues={defaultValues}
							>
								<InputField
									rules={[
										{
											required: true,
										},
										{
											min: 1,
											message: "Implements  Name be minimum 1 character.",
										},
										{
											max: 40,
											message: "Implements  Name be minimum 40 character.",
										},
									]}
									label="Implements  Name"
									name="name"
									placeholder="Please enter Implements  name"
									prefix={<UserOutlined />}
								/>

								<InputField
									rules={[
										{
											required: true,
										},
										{ min: 10, message: "Code must be minimum 10 characters." },
										{ max: 10, message: "Code must be maximum 10 characters." },
									]}
									label="Code"
									name="code"
									placeholder="Please enter code"
									prefix={<UserOutlined />}
								/>
								<CommonSelect
									rules={[{ required: true, message: "HSN/SAC Code is required" }]}
									label="Select HSN/SAC Code"
									name="hsnCodeId"
									placeholder="please Select HSN/SAC Code"
									options={HSNCodeChildren}
								/>
								<div className="implementFormTextGroup">
									<div className="implementFormTextGroupChild">
										<InputField
											rules={[
												{
													required: true,
													message: "Price is required",
												},
												{
													type: "number",
													min: 1,
													message: "Price minimum = 1",
												},
											]}
											label="Price"
											name="price"
											inputType="number"
											placeholder="Please enter Price."
											prefix={<UserOutlined />}
										/>
									</div>
									<div className="implementFormTextGroupChild">
										<InputField
											rules={[
												{
													required: true,
													message: "Stock is required",
												},
												{
													type: "number",
													max: 100000,
													message: "Stock maximum=100000",
												},
											]}
											label="Stock"
											name="stock"
											inputType="number"
											placeholder="Please enter Stock."
											prefix={<UserOutlined />}
										/>
									</div>
									<div className="implementFormTextGroupChild">
										<InputField
											rules={[
												{
													required: true,
													message: "Discount is required",
												},
												{
													type: "number",
													min: 0,
													max: 99,
													message: "Discount maximum 100% and minimum 0%",
												},
											]}
											label="Discount ( % )"
											name="discount"
											inputType="number"
											placeholder="Please enter discount."
											prefix={<UserOutlined />}
										/>
									</div>
								</div>
								<Title level={5} style={{ fontSize: "16px" }}>
									<span
										style={{
											color: "#f08080",
											fontWeight: "thin",
											fontSize: "16px",
										}}
									>
										*
									</span>{" "}
									Select Implement Sub Assembly
								</Title>
								<Select
									mode="multiple"
									allowClear
									style={{ width: "100%" }}
									placeholder="Please select implements sub assembly"
									onChange={handleChange}
									defaultValue={
										implementsId
											? implement?.implementsSubAssemblies?.map((data) => data?.id.toString())
											: undefined
									}
									filterOption={(input, option) => {
										return (
											option.key.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
											option.title.toLowerCase().indexOf(input.toLowerCase()) >= 0
										);
									}}
								>
									{children}
								</Select>

								<Title level={5} style={{ marginTop: "20px" }}>
									<span
										style={{
											color: "#f08080",
											fontWeight: "thin",
											fontSize: "16px",
										}}
									>
										*
									</span>{" "}
									Part Diagram
								</Title>
								<Upload
									getInputProps={getImageInputProps}
									getRootProps={getImageRootProps}
									type="image"
									isDragActive={isImageDragActive}
									file={file}
									url={implementsId ? implement.url : ""}
									setUploadMedia={setUploadMedia}
								/>
								{implementsId ? (
									<WordCountDisplayer
										label="Update Reason"
										name="updateReason"
										placeholder="Please enter reason"
										rules={[
											{
												required: true,
											},
											{
												message: "update reason must be minimum 10 words.",
											},
										]}
									/>
								) : null}
								<Form.Item style={{ marginTop: "20px" }}>
									<Center height="0px">
										<Space>
											<NavLink to="/products/implements">
												<Button danger type="primary" onClick={handleCancel}>
													Cancel
												</Button>
											</NavLink>
											<Button type="primary" htmlType="submit">
												{implementsId ? "Update" : "Add"}
											</Button>
										</Space>
									</Center>
								</Form.Item>
							</Form>
						</Card>
					</Col>
				</Row>
			</ErrorBoundary>
		</BasicLayout>
	);
};

export default ImplementsForm;

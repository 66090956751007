import { Typography } from "antd";
import React from "react";

const { Text } = Typography;

const Index = ({ getRootProps, url, getInputProps, isDragActive, file}) => {
  

  return (
    <div className="upload-root-container">
			{url ? (
				<a href={url} target="_blank" rel="noreferrer">
					View
				</a>
			) : null}
    <div {...getRootProps()}>
      <input {...getInputProps()} />
      {isDragActive ? (
					<Text>Drop the file here ...</Text>
				) : (
					<div className="upload-area">
						{file && file?.length !== 0 ? (
							<Text style={{ fontWeight: "bold" }}>File Loaded Successfully</Text>
						) : null}
						<Text>Click or drag file to this area to upload</Text>
					</div>
          				)}

    </div>
    </div>

  )
}

export default Index;


export const GET_BRANDS_REQUEST = "GET_BRANDS_REQUEST",
	GET_BRANDS_SUCCESS = "GET_BRANDS_SUCCESS",
	GET_BRANDS_FAILURE = "GET_BRANDS_FAILURE";

export const GET_SINGLE_BRAND_REQUEST = "GET_SINGLE_BRAND_REQUEST",
	GET_SINGLE_BRAND_SUCCESS = "GET_SINGLE_BRAND_SUCCESS",
	GET_SINGLE_BRAND_FAILURE = "GET_SINGLE_BRAND_FAILURE";

export const SOFT_DELETE_SINGLE_BRAND_REQUEST = "SOFT_DELETE_SINGLE_BRAND_REQUEST",
	SOFT_DELETE_SINGLE_BRAND_SUCCESS = "SOFT_DELETE_SINGLE_BRAND_SUCCESS",
	SOFT_DELETE_SINGLE_BRAND_FAILURE = "SOFT_DELETE_SINGLE_BRAND_FAILURE";

export const RESTORE_SINGLE_BRAND_REQUEST = "RESTORE_SINGLE_BRAND_REQUEST",
	RESTORE_SINGLE_BRAND_SUCCESS = "RESTORE_SINGLE_BRAND_SUCCESS",
	RESTORE_SINGLE_BRAND_FAILURE = "RESTORE_SINGLE_BRAND_FAILURE";

export const ADD_BRAND_REQUEST = "ADD_BRAND_REQUEST",
	ADD_BRAND_SUCCESS = "ADD_BRAND_SUCCESS",
	ADD_BRAND_FAILURE = "ADD_BRAND_FAILURE";

export const UPDATE_BRAND_REQUEST = "UPDATE_BRAND_REQUEST",
	UPDATE_BRAND_SUCCESS = "UPDATE_BRAND_SUCCESS",
	UPDATE_BRAND_FAILURE = "UPDATE_BRAND_FAILURE";

import { Button, Col, List, Steps, Tabs } from "antd";
import Text from "antd/lib/typography/Text";
import { useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { NavLink, useHistory, useLocation } from "react-router-dom";
import { dateFormatCoverter } from "../../../utils/dateFormatCoverter";
import CustomSelect from "../../Form/Select/index";
import { useGetAdmins } from "../../Hooks/useGetAdmins";
import Notification from "../Notification";
import { NoHistoryPresent } from "./NoHistoryPresent";
import "./tab.css";

export function CustomTab({ data, operationData, redirectUrl, update = "true" }) {
	const { Step } = Steps;
	const { getAdmin, getUser } = useGetAdmins();

	const { TabPane } = Tabs;
	const history = useHistory();
	const location = useLocation();
	const tabs = new URLSearchParams(location.search).get("tabs");
	const softdelete = new URLSearchParams(location.search).get("soft-delete");

	const admins = useSelector((state) => state?.admin?.admins);
	const users = useSelector((state) => state?.admin?.users);

	const [optionValue, setOptionValue] = useState(update === false ? "deletedBy" : "updatedBy");

	const adminIds = useMemo(() => {
		let d1 = [];
		for (let i = 0; i < operationData?.deletedBy?.length; i++) {
			if (operationData?.deletedBy[i]?.id) {
				d1.push(operationData?.deletedBy[i]?.id);
			}
		}

		let d2 = [];
		for (let i = 0; i < operationData?.updatedBy?.length; i++) {
			if (operationData?.updatedBy[i]?.id) {
				d2.push(operationData?.updatedBy[i]?.id);
			}
		}

		let d3 = [];
		for (let i = 0; i < operationData?.restoredBy?.length; i++) {
			if (operationData?.restoredBy[i]?.id) {
				d3.push(operationData?.restoredBy[i]?.id);
			}
		}
		return [...d1, ...d2, ...d3];
	}, [operationData]);

	const uniqueAdminIds = useMemo(() => [...new Set(adminIds)], [adminIds]);
	let options = [
		{
			key: "Delete History",
			value: "deletedBy",
		},
		{
			key: "Restore History",
			value: "restoredBy",
		},
	];
	if (update === "true") {
		options.push({
			key: "Update History",
			value: "updatedBy",
		});
	}

		const handleChangeTab = async (e) => {
		try {
			if (e?.toString() === "2") {
				if (softdelete === "true") {
					history.push(`${location.pathname}?tabs=history&soft-delete=true`);
					if (uniqueAdminIds.length !== 0) {
						await getAdmin(uniqueAdminIds);
						await getUser(uniqueAdminIds);
					}
				} else {
					history.push(`${location.pathname}?tabs=history`);
					if (uniqueAdminIds.length !== 0) {
						await getAdmin(uniqueAdminIds);

						await getUser(uniqueAdminIds);
					}
				}
			} else {
				if (softdelete === "true") {
					history.push(`${location.pathname}?tabs=info&soft-delete=true`);
				} else {
					history.push(`${location.pathname}?tabs=info`);
				}
			}
		} catch (error) {
			const errorMessage = error?.response?.data?.message || "Something went wrong";
			Notification("error", errorMessage, "", "top");
		}
	};

	return (
		<>
			{update === "true" && !softdelete ? (
				<div style={{ width: "95%", margin: "auto" }}>
					<Button className="ant-btn-primary tab-edit-btn">
						<NavLink to={redirectUrl}>Edit</NavLink>
					</Button>
				</div>
			) : null}
			<Tabs
				defaultActiveKey={tabs === "history" ? "2" : "1"}
				onChange={handleChangeTab}
				style={{ marginTop: update === "true" ? 0 : "15px" }}
			>
				<TabPane tab="Info" key="1">
					<List
						itemLayout="horizontal"
						dataSource={data}
						renderItem={(item) => (
							<List.Item>
								<List.Item.Meta title={item.title} description={item.description} />
							</List.Item>
						)}
					/>
				</TabPane>
				<TabPane tab="History" key="2">
					<div className="tab-content-width">
						<CustomSelect
							defaultValue={update === "true" ? "updatedBy" : "deletedBy"}
							options={options}
							onChange={(e) => setOptionValue(e)}
							label="Please select history type"
						/>
					</div>
					{optionValue === "updatedBy" ? (
						<Col className="tab-content-width">
							{operationData?.updatedBy && operationData?.updatedBy?.length > 0 ? (
								<Steps progressDot current={1} direction="vertical" className="updated-by-tabpane">
									{operationData?.updatedBy?.map((item, index) => {
										let user = [];
										if (item?.updatedBy !== "user") {
											user = admins.filter((admin) => admin?.id === item?.id);
										} else {
											user = users.filter((admin) => admin?.id === item?.id);
										}
										return (
											<Step
												key={index}
												title={
													<h3 className="tab-username">{user[0]?.username || user[0]?.userName}</h3>
												}
												description={
													<p>
														{item.updateReason}
														<br></br>
														<Text className="tab-date">
															{dateFormatCoverter(
																item.updatedAt.slice(0, 10),
																item.updatedAt.slice(11, 19)
															)}
														</Text>
													</p>
												}
											/>
										);
									})}
								</Steps>
							) : (
								<NoHistoryPresent />
							)}
						</Col>
					) : optionValue === "deletedBy" ? (
						<Col className="tab-content-width">
							{operationData?.deletedBy && operationData?.deletedBy?.length > 0 ? (
								<Steps progressDot current={1} direction="vertical" className="deleted-by-tabpane">
									{operationData?.deletedBy?.map((item, index) => {
										let user = [];
										if (item?.deletedBy !== "user") {
											user = admins.filter((admin) => admin?.id === item?.id);
										} else {
											user = users.filter((admin) => admin?.id === item?.id);
										}
										return (
											<Step
												key={index}
												title={
													<h3 className="tab-username">{user[0]?.username || user[0]?.userName}</h3>
												}
												description={
													<p>
														{item.deleteReason}
														<br></br>
														<Text className="tab-date">
															{dateFormatCoverter(
																item.deletedAt.slice(0, 10),
																item.deletedAt.slice(11, 19)
															)}
														</Text>
													</p>
												}
											/>
										);
									})}
								</Steps>
							) : (
								<NoHistoryPresent />
							)}
						</Col>
					) : optionValue === "restoredBy" ? (
						<Col className="tab-col tab-content-width">
							{operationData?.restoredBy && operationData?.restoredBy?.length > 0 ? (
								<Steps progressDot current={1} direction="vertical" className="restored-by-tabpane">
									{operationData?.restoredBy?.map((item, index) => {
										let user = [];
										if (item?.restoredBy !== "user") {
											user = admins.filter((admin) => admin?.id === item?.id);
										} else {
											user = users.filter((admin) => admin?.id === item?.id);
										}
										return (
											<Step
												key={index}
												title={
													<h3 className="tab-username">{user[0]?.username || user[0]?.userName}</h3>
												}
												description={
													<p>
														{item.restoreReason}
														<br></br>
														<Text className="tab-date">
															{dateFormatCoverter(
																item.restoredAt.slice(0, 10),
																item.restoredAt.slice(11, 19)
															)}
														</Text>
													</p>
												}
											/>
										);
									})}
								</Steps>
							) : (
								<NoHistoryPresent />
							)}
						</Col>
					) : null}
				</TabPane>
			</Tabs>
		</>
	);
}

export const GET_USERS_REQUEST = "GET_USERS_REQUEST",
	GET_USERS_SUCCESS = "GET_USERS_SUCCESS",
	GET_USERS_FAILURE = "GET_USERS_FAILURE";

export const GET_SINGLE_USER_REQUEST = "GET_SINGLE_USER_REQUEST",
	GET_SINGLE_USER_SUCCESS = "GET_SINGLE_USER_SUCCESS",
	GET_SINGLE_USER_FAILURE = "GET_SINGLE_USER_FAILURE";

export const SOFT_DELETE_SINGLE_USER_REQUEST = "SOFT_DELETE_SINGLE_USER_REQUEST",
	SOFT_DELETE_SINGLE_USER_SUCCESS = "SOFT_DELETE_SINGLE_USER_SUCCESS",
	SOFT_DELETE_SINGLE_USER_FAILURE = "SOFT_DELETE_SINGLE_USER_FAILURE";

export const RESTORE_SINGLE_USER_REQUEST = "RESTORE_SINGLE_USER_REQUEST",
	RESTORE_SINGLE_USER_SUCCESS = "RESTORE_SINGLE_USER_SUCCESS",
	RESTORE_SINGLE_USER_FAILURE = "RESTORE_SINGLE_USER_FAILURE";

export const ADD_USER_REQUEST = "ADD_USER_REQUEST",
	ADD_USER_SUCCESS = "ADD_USER_SUCCESS",
	ADD_USER_FAILURE = "ADD_USER_FAILURE";

export const UPDATE_USER_REQUEST = "UPDATE_USER_REQUEST",
	UPDATE_USER_SUCCESS = "UPDATE_USER_SUCCESS",
	UPDATE_USER_FAILURE = "UPDATE_USER_FAILURE";

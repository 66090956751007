import {
	GET_IMPLEMENTS_SUB_ASSEMBLIES_SUCCESS,
	GET_IMPLEMENT_RELATED_IMPLEMENTS_SUB_ASSEMBLIES_SUCCESS,
	GET_SINGLE_IMPLEMENTS_SUB_ASSEMBLY_SUCCESS,
	RESTORE_SINGLE_IMPLEMENTS_SUB_ASSEMBLY_SUCCESS,
	SOFT_DELETE_SINGLE_IMPLEMENTS_SUB_ASSEMBLY_SUCCESS,
} from "../actions/products/implementsSubAssembly/implementsSubAssemblies.actionType";
import globalState from "../state";

const requestRegex = /_REQUEST/;
const errorRegex = /_FAILURE/;
const successRegex = /_SUCCESS/;

const initialState = {
	errorMessage: "",
	isLoading: false,
	implementsSubAssemblies: [],
	implementRelatedimplementsSubAssemblies: [],
	implementsSubAssembly: {},
	...globalState,
};

const ImplementsSubAssemblyReducer = (state = initialState, action) => {
	const isRequest = requestRegex.test(action.type);
	const isError = errorRegex.test(action.type);
	const isSuccess = successRegex.test(action.type);

	if (isRequest) {
		return { ...state, isLoading: true, errorMessage: "" };
	}
	if (isError) {
		return { ...state, isLoading: false, errorMessage: action.message };
	}
	if (isSuccess) {
		state = { ...state, isLoading: false, errorMessage: "" };
	}

	switch (action.type) {
		case GET_IMPLEMENTS_SUB_ASSEMBLIES_SUCCESS:
			return { ...state, implementsSubAssemblies: action.data };

		case GET_IMPLEMENT_RELATED_IMPLEMENTS_SUB_ASSEMBLIES_SUCCESS:
			return { ...state, implementRelatedimplementsSubAssemblies: action.data };

		case GET_SINGLE_IMPLEMENTS_SUB_ASSEMBLY_SUCCESS:
			return { ...state, implementsSubAssembly: action.data };

		case SOFT_DELETE_SINGLE_IMPLEMENTS_SUB_ASSEMBLY_SUCCESS:
			let softDeletedimplementsSubAssemblyId = action.data;
			let afterSoftDeletedData = state.implementsSubAssemblies.filter(
				(data) => data.id.toString() !== softDeletedimplementsSubAssemblyId.toString()
			);
			return { ...state, implementsSubAssemblies: afterSoftDeletedData };

		case RESTORE_SINGLE_IMPLEMENTS_SUB_ASSEMBLY_SUCCESS:
			return { ...state };

		default:
			return state;
	}
};

export default ImplementsSubAssemblyReducer;

import { Button, message } from "antd";
import React, { useEffect, useState, useMemo } from "react";
import { ErrorBoundary } from "react-error-boundary";
import { useDispatch, useSelector } from "react-redux";
import BasicLayout from "../../../components/BasicLayout";
import ButtonAsLink from "../../../components/common/ButtonAsLink";
import FallBack from "../../../components/common/FallBack/FallBack";
import Tables from "../../../components/common/Table/Table";
import { useDeleteAction } from "../../../components/Hooks/useDeleteAction";
import useExportCsv from "../../../components/Hooks/useExportCsv";
import useTableSearch from "../../../components/Hooks/useTableSearch";
import { getJob, softDeleteJob } from "../../../store/actions/Careers/careers.action";
import { DeleteModel } from "../../../utils/DeleteModel";
import { formatFileName } from "../../../utils/helper";
import "./jobs.css";

const Jobs = (props) => {
	const [getColumnSearchProps] = useTableSearch();
	const dispatch = useDispatch();
	const [exportToCSV] = useExportCsv();
	const { deleteData } = useDeleteAction();
	const [deleteId, setDeleteId] = useState(undefined);
	const [isModalVisible, setIsModalVisible] = useState(false);
	const jobs = useSelector((state) => state.careers.jobs || []);
	const admin = useSelector((state) => state.admin.admin || {});

	const permissionArr = useMemo(() => {
		if (admin && admin.template) {
			const { permissions } = admin.template;
			return permissions;
		} else if (admin && admin.accessLevel === "superAdmin") {
			return "all";
		}
		return [];
	}, [admin]);

	useEffect(() => {
		dispatch(getJob());
	}, [dispatch]);

	let dataSource = [];
	if (jobs.length > 0) {
		dataSource = jobs.sort(function (a, b) {
			return new Date(b.updatedAt) - new Date(a.updatedAt);
		});
		dataSource = dataSource.map((row) => ({
			...row,
			createdAt: new Date(row.createdAt).toLocaleString(),
			status: row.status ? "Yes" : "no",
			key: row.id,
			experience: row.experience + " " + row.experienceUnit,
			category: row.category ? row?.category?.title : "",
		}));
	}

	async function confirm(id, reason) {
		await deleteData(id, reason, softDeleteJob, setIsModalVisible);
	}
	const columns = [
		{
			title: "Id",
			dataIndex: "id",
			key: "id",
		},
		{
			title: "Position",
			dataIndex: "position",
			key: "position",
			...getColumnSearchProps("position"),
		},
		{
			title: "Category",
			dataIndex: "category",
			key: "category",
			...getColumnSearchProps("category"),
		},
		{
			title: "Experience Required",
			dataIndex: "experience",
			key: "experience",
			...getColumnSearchProps("experience"),
		},
		{
			title: "Job Type",
			dataIndex: "type",
			key: "type",
			...getColumnSearchProps("type"),
		},
		{
			title: "Received",
			dataIndex: "createdAt",
			key: "createdAt",
		},
		{
			title: "Action",
			key: "action",
			render: (_text, record) => (
				<DeleteModel
					record={record}
					confirm={confirm}
					setIsModalVisible={setIsModalVisible}
					isModalVisible={isModalVisible}
					cancelMessage="Job Removing has been cancelled"
					linkUrl={`/careers/jobs`}
					placeholder="Please enter reason to delete Job"
					deleteId={deleteId}
					setDeleteId={setDeleteId}
					update={
						permissionArr === "all"
							? "true"
							: permissionArr?.career?.jobs.filter((item) => item === "update")?.length > 0
							? "true"
							: false
					}
					isdelete={
						permissionArr === "all"
							? "true"
							: permissionArr?.career?.jobs.filter((item) => item === "delete")?.length > 0
							? "true"
							: false
					}
					view={
						permissionArr === "all"
							? "true"
							: permissionArr?.career?.jobs.filter((item) => item === "read")?.length > 0
							? "true"
							: false
					}
				/>
			),
		},
	];

	const fileName = formatFileName("jobs", "xlsx",admin);
	let downloadData = [];
	if (dataSource.length > 0) {
		downloadData = jobs.map((data) => {
			return Object.assign({}, data, {
				hasAccepted: "true",
				category: data.category ? data.category.title : "",
			});
		});
	}

	const handleError = (error) => {
		message.error(error.message);
	};
	return (
		<BasicLayout accessLevel={props.accessLevel}>
			<ErrorBoundary FallbackComponent={FallBack} onError={handleError}>
				<div className="button-style-to-right">
					{permissionArr === "all" ? (
						<Button className="download-btn" onClick={(e) => exportToCSV(downloadData, fileName)}>
							Export
						</Button>
					) : permissionArr?.career?.jobs?.filter((item) => item === "export")?.length > 0 &&
					  downloadData?.length !== 0 ? (
						<Button className="download-btn" onClick={(e) => exportToCSV(downloadData, fileName)}>
							Export
						</Button>
					) : null}

					{permissionArr === "all" ? (
						<ButtonAsLink text="Add New Job" link="/careers/jobs/add" />
					) : permissionArr?.career?.jobs?.filter((item) => item === "write")?.length > 0 ? (
						<ButtonAsLink text="Add New Job" link="/careers/jobs/add" />
					) : null}
				</div>

				<Tables
					dataSource={dataSource}
					columns={columns}
					footerText={`Total Job Posted:
        ${jobs.length}`}
				/>
			</ErrorBoundary>
		</BasicLayout>
	);
};

export default Jobs;

import { UserOutlined } from "@ant-design/icons";
import { Button, Card, Col, Form, message, Row, Space, Typography } from "antd";
import axios from "axios";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useDropzone } from "react-dropzone";
import { ErrorBoundary } from "react-error-boundary";
import { useDispatch, useSelector } from "react-redux";
import { NavLink, useHistory, useLocation } from "react-router-dom";
import BasicLayout from "../../../components/BasicLayout";
import Center from "../../../components/common/Center";
import FallBack from "../../../components/common/FallBack/FallBack";
import Notification from "../../../components/common/Notification";
import { WordCountDisplayer } from "../../../components/common/WordCountDisplayer";
import InputField from "../../../components/Form/InputField";
import Upload from "../../../components/Form/Upload";
import {
	addImplementsSubAssembly,
	getSingleImplementsSubAssembly,
	updateImplementsSubAssembly,
	updateImplementsSubAssemblyFailure,
} from "../../../store/actions/products/implementsSubAssembly/implementsSubAssemblies.action";
import { formatFileName } from "../../../utils/helper";
import { aspectRatioDependancy } from "../../../utils/imageAspectRatio";
import { imageSizeDependancy } from "../../../utils/imageSize";
import { wordCount } from "../../../utils/wordCount";

const ImplementsSubAssemblyForm = (props) => {
	const history = useHistory();
	const dispatch = useDispatch();
	const [file, setFile] = useState([]);
	const { Title } = Typography;
	const [form] = Form.useForm();
	const implementsSubAssembly = useSelector(
		(state) => state.implementsSubAssembly.implementsSubAssembly
	);

	const location = useLocation();
	const softdelete = new URLSearchParams(location.search).get("soft-delete");
	const admin = useSelector((state) => state?.admin?.admin || {});

	const [uploadMedia, setUploadMedia] = useState("");

	const implementsSubAssemblyId = +props.match.params.id;

	const currentLocation = props.location.pathname;

	const onImageDrop = useCallback(async (acceptedFiles, fileRejections) => {
		try {
			imageSizeDependancy(fileRejections);

			await aspectRatioDependancy(acceptedFiles, setFile);
		} catch (error) {
			const errorMessage = error?.message || "Something went wrong";
			Notification("error", errorMessage, "", "topRight");
		}
	}, []);

	const {
		getRootProps: getImageRootProps,
		getInputProps: getImageInputProps,
		isDragActive: isImageDragActive,
	} = useDropzone({
		onDrop: onImageDrop,
		accept: " image/png",
		multiple: false,
		maxSize: "1000000",
	});

	const handleError = (error) => {
		message.error(error.message);
	};
	useEffect(() => {
		if (implementsSubAssemblyId) {
			dispatch(getSingleImplementsSubAssembly(implementsSubAssemblyId, softdelete)).catch((err) => {
				Notification("error", err, "", "topRight");
				history.push("/products/implement/sub-assembly");
			});
		}
	}, [implementsSubAssemblyId, history, softdelete, dispatch]);

	const onFinish = async (values) => {
		try {
			if (currentLocation === "/products/implement/sub-assembly/add") {
				if (file.length === 0) {
					Notification("error", "Please upload a valid Part diagram", "", "topRight");
					return;
				}
				const fileName = formatFileName(
					file[0].name.split(".")[0],
					file[0].name.split(".").pop(),
					admin
				);
				const fileType = file[0].type;
				const data = { ...values, fileName, fileType };
				const resp = await dispatch(addImplementsSubAssembly(data));
				if (resp && resp.signedUrl) {
					const options = {
						headers: {
							"Content-Type": uploadMedia[0].type,
						},
					};
					try {
						await axios.put(resp.signedUrl, uploadMedia[0], options);
						history.push("/products/implement/sub-assembly");
						Notification(
							"success",
							"ImplementsSubAssembly has been succesfully added.",
							"",
							"topRight"
						);
					} catch (error) {
						const errorMessage = error.message || "Something went wrong";
						Notification("error", errorMessage, "", "top");
					}
				}
			} else {
				await wordCount(values.updateReason, 10);
				if (file.length === 0) {
					try {
						const resp = await dispatch(
							updateImplementsSubAssembly(implementsSubAssemblyId, values)
						);
						const message = resp.message || "Updated successfully";
						history.push("/products/implement/sub-assembly");
						Notification("success", message, "", "topRight");
					} catch (err) {
						const errorMessage =
							(err?.response && err?.response?.data?.message) || "Some thing went wrong";
						dispatch(updateImplementsSubAssemblyFailure(errorMessage));
						return Notification("error", "Error Occurred", errorMessage, "topRight");
					} finally {
						setFile([]);
					}
				} else {
					try {
						const fileName = formatFileName(
							file[0].name.split(".")[0],
							file[0].name.split(".").pop(),
							admin
						);
						const fileType = file[0].type;
						const data = { ...values, fileName, fileType };
						const resp = await dispatch(updateImplementsSubAssembly(implementsSubAssemblyId, data));

						if (resp && resp.data.signedUrl) {
							const options = {
								headers: {
									"Content-Type": uploadMedia[0].type,
								},
							};
							await axios.put(resp.data.signedUrl, uploadMedia[0], options);
							history.push("/products/implement/sub-assembly");
							Notification(
								"success",
								"ImplementsSubAssembly has been succesfully updated.",
								"",
								"topRight"
							);
						}
					} catch (err) {
						const errorMessage =
							(err?.response && err?.response?.data?.message) || "Some thing went wrong";
						dispatch(updateImplementsSubAssemblyFailure(errorMessage));
						return Notification("error", "Error Occurred", errorMessage, "topRight");
					}
				}
			}
		} catch (err) {
			if (err.message) {
				return Notification("error", "Error Occurred", err.message, "topRight");
			}
			const errorMessage =
				(err?.response && err?.response?.data?.message) || "Something went wrong";
			return Notification("error", "Error Occurred", errorMessage, "topRight");
		}
	};

	const defaultValues = useMemo(
		() => ({
			code: implementsSubAssemblyId ? implementsSubAssembly.code : "",
			name: implementsSubAssemblyId ? implementsSubAssembly.name : "",
		}),
		[implementsSubAssemblyId, implementsSubAssembly.code, implementsSubAssembly.name]
	);

	useEffect(() => {
		form.setFieldsValue(defaultValues);
	}, [form, defaultValues]);

	const handleCancel = () => {
		Notification("info", "Implement Sub Assembly action has been cancelled", "", "topRight");
	};
	return (
		<BasicLayout accessLevel={props.accessLevel}>
			<ErrorBoundary FallbackComponent={FallBack} onError={handleError}>
				<Row type="flex" justify="center" align="middle" id="form-container">
					<Center height="4rem">
						{implementsSubAssemblyId ? (
							<Title level={2} className="update-or-add-title">
								Update Implements Sub Assembly
							</Title>
						) : (
							<Title level={2} className="update-or-add-title">
								Add New Implements Sub Assembly
							</Title>
						)}
					</Center>
					<Col>
						<Card className="form-card">
							<Form
								form={form}
								name="basic"
								layout="vertical"
								onFinish={onFinish}
								initialValues={defaultValues}
							>
								<InputField
									rules={[
										{
											required: true,
										},
										{
											min: 1,
											message: "Implements Sub Assembly Name be minimum 1 character.",
										},
										{
											max: 40,
											message: "Implements Sub Assembly Name be minimum 40 character.",
										},
									]}
									label="Implements Sub Assembly Name"
									name="name"
									placeholder="Please enter Implements Sub Assembly name"
									prefix={<UserOutlined />}
								/>

								<InputField
									rules={[
										{
											required: true,
										},
										{ min: 10, message: "Code must be minimum 10 characters." },
										{ max: 10, message: "Code must be maximum 10 characters." },
									]}
									label="Code"
									name="code"
									placeholder="Please enter code"
									prefix={<UserOutlined />}
								/>
								<Title level={5}>
									<span
										style={{
											color: "#f08080",
											fontWeight: "thin",
											fontSize: "16px",
										}}
									>
										*
									</span>{" "}
									Part Diagram
								</Title>
								<Upload
									getInputProps={getImageInputProps}
									getRootProps={getImageRootProps}
									type="image"
									isDragActive={isImageDragActive}
									file={file}
									url={implementsSubAssemblyId ? implementsSubAssembly.url : ""}
									setUploadMedia={setUploadMedia}
								/>

								{implementsSubAssemblyId ? (
									<WordCountDisplayer
										label="Update Reason"
										name="updateReason"
										placeholder="Please enter reason"
										rules={[
											{
												required: true,
											},
											{
												message: "update reason must be minimum 10 words.",
											},
										]}
									/>
								) : null}

								<Form.Item style={{ marginTop: "20px" }}>
									<Center height="0px">
										<Space>
											<NavLink to="/products/implement/sub-assembly">
												<Button danger type="primary" onClick={handleCancel}>
													Cancel
												</Button>
											</NavLink>
											<Button type="primary" htmlType="submit">
												{implementsSubAssemblyId ? "Update" : "Add"}
											</Button>
										</Space>
									</Center>
								</Form.Item>
							</Form>
						</Card>
					</Col>
				</Row>
			</ErrorBoundary>
		</BasicLayout>
	);
};

export default ImplementsSubAssemblyForm;

import { Card, message } from "antd";
import { useEffect, useMemo } from "react";
import { ErrorBoundary } from "react-error-boundary";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import BasicLayout from "../../../components/BasicLayout";
import FallBack from "../../../components/common/FallBack/FallBack";
import Notification from "../../../components/common/Notification";
import { CustomTab } from "../../../components/common/Tab/Tab";
import { getSingleHSNCode } from "../../../store/actions/products/HSNCode/hsnCode.action";

const ViewHSNCode = (props) => {
	const dispatch = useDispatch();
	const hsnCode = useSelector((state) => state?.hsnCode?.hsnCode || {});
	const hsnCodeId = +props.match.params.id;
	const history = useHistory();

	const admin = useSelector((state) => state.admin.admin || []);

	const permissionArr = useMemo(() => {
		if (admin && admin.template) {
			const { permissions } = admin.template;
			return permissions;
		} else if (admin && admin.accessLevel === "superAdmin") {
			return "all";
		}
		return [];
	}, [admin]);

	const location = useLocation();
	const softdelete = new URLSearchParams(location.search).get("soft-delete");

	useEffect(() => {
		dispatch(getSingleHSNCode(hsnCodeId, softdelete)).catch((err) => {
			Notification("error", err, "", "topRight");
			history.push("/products/hsncode");
		});
	}, [dispatch, softdelete, history, hsnCodeId]);

	let data = [];
	let isOrderEmpty = Object.keys(hsnCode)?.length === 0;
	if (!isOrderEmpty) {
		data = [
			{ title: "Id", description: hsnCode.id },
			{
				title: "HSN/SAC Code",
				description: hsnCode.code,
			},
			{
				title: "IGST",
				description: hsnCode?.igst ? `${hsnCode?.igst}%` : "NA",
			},
			{
				title: "CGST",
				description: hsnCode?.cgst ? `${hsnCode?.cgst}%` : "NA",
			},
			{
				title: "SGST",
				description: hsnCode?.hgst ? `${hsnCode?.hgst}%` : "NA",
			},
			{
				title: "Received",
				description: new Date(hsnCode?.createdAt).toLocaleString(),
			},
		];
	}

	const handleError = (error) => {
		message.error(error.message);
	};
	let operationData = {
		deletedBy: hsnCode?.deletedBy,
		updatedBy: hsnCode?.updatedBy,
		restoredBy: hsnCode?.restoredBy,
	};
	return (
		<BasicLayout accessLevel={props.accessLevel}>
			<ErrorBoundary FallbackComponent={FallBack} onError={handleError}>
				<Card className="enqiury-detail-card" data-testid="enqiury-detail-card">
					<CustomTab
						update={
							permissionArr === "all"
								? "true"
								: permissionArr?.products?.hsnCode.filter(
										(item) => item === "update"
								  )?.length > 0
								? "true"
								: false
						}
						data={data}
						operationData={operationData}
						redirectUrl={`/products/hsncode/update/${hsnCodeId}`}
					/>
				</Card>
			</ErrorBoundary>
		</BasicLayout>
	);
};

export default ViewHSNCode;

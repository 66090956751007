export const GET_PRODUCT_SECONDARY_CATEGORIES_REQUEST = "GET_PRODUCT_SECONDARY_CATEGORIES_REQUEST",
	GET_PRODUCT_SECONDARY_CATEGORIES_SUCCESS = "GET_PRODUCT_SECONDARY_CATEGORIES_SUCCESS",
	GET_PRODUCT_SECONDARY_CATEGORIES_FAILURE = "GET_PRODUCT_SECONDARY_CATEGORIES_FAILURE";

export const GET_SINGLE_PRODUCT_SECONDARY_CATEGORY_REQUEST =
		"GET_SINGLE_PRODUCT_SECONDARY_CATEGORY_REQUEST",
	GET_SINGLE_PRODUCT_SECONDARY_CATEGORY_SUCCESS = "GET_SINGLE_PRODUCT_SECONDARY_CATEGORY_SUCCESS",
	GET_SINGLE_PRODUCT_SECONDARY_CATEGORY_FAILURE = "GET_SINGLE_PRODUCT_SECONDARY_CATEGORY_FAILURE";

export const SOFT_DELETE_SINGLE_PRODUCT_SECONDARY_CATEGORY_REQUEST =
		"SOFT_DELETE_SINGLE_PRODUCT_SECONDARY_CATEGORY_REQUEST",
	SOFT_DELETE_SINGLE_PRODUCT_SECONDARY_CATEGORY_SUCCESS =
		"SOFT_DELETE_SINGLE_PRODUCT_SECONDARY_CATEGORY_SUCCESS",
	SOFT_DELETE_SINGLE_PRODUCT_SECONDARY_CATEGORY_FAILURE =
		"SOFT_DELETE_SINGLE_PRODUCT_SECONDARY_CATEGORY_FAILURE";

export const RESTORE_SINGLE_PRODUCT_SECONDARY_CATEGORY_REQUEST =
		"RESTORE_SINGLE_PRODUCT_SECONDARY_CATEGORY_REQUEST",
	RESTORE_SINGLE_PRODUCT_SECONDARY_CATEGORY_SUCCESS =
		"RESTORE_SINGLE_PRODUCT_SECONDARY_CATEGORY_SUCCESS",
	RESTORE_SINGLE_PRODUCT_SECONDARY_CATEGORY_FAILURE =
		"RESTORE_SINGLE_PRODUCT_SECONDARY_CATEGORY_FAILURE";

export const ADD_PRODUCT_SECONDARY_CATEGORY_REQUEST = "ADD_PRODUCT_SECONDARY_CATEGORY_REQUEST",
	ADD_PRODUCT_SECONDARY_CATEGORY_SUCCESS = "ADD_PRODUCT_SECONDARY_CATEGORY_SUCCESS",
	ADD_PRODUCT_SECONDARY_CATEGORY_FAILURE = "ADD_PRODUCT_SECONDARY_CATEGORY_FAILURE";

export const UPDATE_PRODUCT_SECONDARY_CATEGORY_REQUEST =
		"UPDATE_PRODUCT_SECONDARY_CATEGORY_REQUEST",
	UPDATE_PRODUCT_SECONDARY_CATEGORY_SUCCESS = "UPDATE_PRODUCT_SECONDARY_CATEGORY_SUCCESS",
	UPDATE_PRODUCT_SECONDARY_CATEGORY_FAILURE = "UPDATE_PRODUCT_SECONDARY_CATEGORY_FAILURE";

export const GET_PRIMARY_RELATED_SECONDARY_CATEGORIES_REQUEST =
		"GET_PRIMARY_RELATED_SECONDARY_CATEGORIES_REQUEST",
	GET_PRIMARY_RELATED_SECONDARY_CATEGORIES_SUCCESS =
		"GET_PRIMARY_RELATED_SECONDARY_CATEGORIES_SUCCESS",
	GET_PRIMARY_RELATED_SECONDARY_CATEGORIES_FAILURE =
		"GET_PRIMARY_RELATED_SECONDARY_CATEGORIES_FAILURE";

import { Button, message } from "antd";
import { useEffect, useState } from "react";
import { ErrorBoundary } from "react-error-boundary";
import { useDispatch, useSelector } from "react-redux";
import BasicLayout from "../../components/BasicLayout/index";
import ButtonAsLink from "../../components/common/ButtonAsLink";
import FallBack from "../../components/common/FallBack/FallBack";
import Tables from "../../components/common/Table/Table";
import { useDeleteAction } from "../../components/Hooks/useDeleteAction";
import useExportCsv from "../../components/Hooks/useExportCsv";
import useTableSearch from "../../components/Hooks/useTableSearch";
import {
	getAllTemplates,
	softDeleteSingleTemplate,
} from "../../store/actions/template/template.action";
import { DeleteModel } from "../../utils/DeleteModel";
import { formatFileName } from "../../utils/helper";

const Templats = (props) => {
	const [getColumnSearchProps] = useTableSearch();
	const [exportToCSV] = useExportCsv();
	const [deleteId, setDeleteId] = useState(undefined);
	const [isModalVisible, setIsModalVisible] = useState(false);

	const { deleteData } = useDeleteAction();

	const dispatch = useDispatch();
	const templates = useSelector((state) => state.template.templates || []);
	const admin = useSelector((state) => state.admin.admin || {});

	useEffect(() => {
		dispatch(getAllTemplates());
	}, [dispatch]);

	let dataSource = [];
	if (templates.length > 0) {
		dataSource = templates.sort(function (a, b) {
			return new Date(b.updatedAt) - new Date(a.updatedAt);
		});
		dataSource = dataSource.map((row, index) => ({
			...row,
			createdAt: new Date(row.createdAt).toLocaleString(),
			key: row.id,
		}));
	}
	async function confirm(id, reason) {
		await deleteData(id, reason, softDeleteSingleTemplate, setIsModalVisible);
	}

	const columns = [
		{
			title: "Id",
			dataIndex: "id",
			key: "id",
		},
		{
			title: "Name",
			dataIndex: "name",
			key: "name",
			...getColumnSearchProps("name"),
		},

		{
			title: "Action",
			key: "action",
			render: (_text, record) => (
				<DeleteModel
					record={record}
					confirm={confirm}
					setIsModalVisible={setIsModalVisible}
					isModalVisible={isModalVisible}
					cancelMessage="Templates Removing has been cancelled"
					linkUrl={`/usermanagement/template`}
					placeholder="Please enter reason to delete template"
					deleteId={deleteId}
					setDeleteId={setDeleteId}
				/>
			),
		},
	];

	const fileName = formatFileName("user-templates", "xlsx",admin);

	let downloadData = templates?.map((data) => {
		let newData = {
			name: data?.name,
			permissions: data?.permissions,
			url: data?.url,
			createdAt: data?.createdAt,
		};
		return newData;
	});

	const handleError = (error) => {
		message.error(error.message);
	};
	return (
		<BasicLayout accessLevel={props.accessLevel}>
			<ErrorBoundary FallbackComponent={FallBack} onError={handleError}>
				<div className="button-style-to-right">
					{downloadData.length !== 0 ? (
						<Button className="download-btn" onClick={(e) => exportToCSV(downloadData, fileName)}>
							Export
						</Button>
					) : null}
					<ButtonAsLink text="Add New Template" link="/usermanagement/template/add" />
				</div>
				<Tables
					dataSource={dataSource}
					columns={columns}
					footerText={`Total Templates: ${templates.length}`}
				/>
			</ErrorBoundary>
		</BasicLayout>
	);
};

export default Templats;

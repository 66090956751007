import { invokeApi } from "../utils/api";
import { $axios } from "../utils/axios";

export const getGallerySubmission = () =>
	invokeApi($axios, "/gallery/submission", {}, "GET")
		.then((resp) => resp)
		.catch((err) => {
			throw err;
		});

export const getSingleGallerySubmission = (id, softdelete) =>
	invokeApi(
		$axios,
		`/gallery/submission/${softdelete === "true" ? "soft-delete/" : ""}${id}`,
		{},
		"GET"
	)
		.then((resp) => resp)
		.catch((err) => {
			throw err;
		});

export const deleteSingleGallerySubmission = (id) =>
	invokeApi($axios, `/gallery/submission/${id}`, {}, "DELETE")
		.then((resp) => resp)
		.catch((err) => {
			throw err;
		});

export const addGallery = (data) =>
	invokeApi($axios, "/gallery", data, "POST")
		.then((resp) => resp)
		.catch((err) => {
			throw err;
		});

export const assignGallery = (data) =>
	invokeApi($axios, "/gallery/assign", data, "POST")
		.then((resp) => resp)
		.catch((err) => {
			throw err;
		});

export const getGallery = () =>
	invokeApi($axios, "/gallery", {}, "GET")
		.then((resp) => resp)
		.catch((err) => {
			throw err;
		});

export const getSingleGallery = (id, softdelete) =>
	invokeApi($axios, `/gallery/${softdelete === "true" ? "soft-delete/" : ""}${id}`, {}, "GET")
		.then((resp) => resp)
		.catch((err) => {
			throw err;
		});

export const updateSingleGallery = (id, data) =>
	invokeApi($axios, `/gallery/${id}`, data, "PUT")
		.then((resp) => resp)
		.catch((err) => {
			throw err;
		});

export const deleteSingleGallery = (id) =>
	invokeApi($axios, `/gallery/${id}`, {}, "DELETE")
		.then((resp) => resp)
		.catch((err) => {
			throw err;
		});

export const softDeleteSingleGallery = (id, reason) =>
	invokeApi($axios, `/gallery/soft-delete/${id}?reason=${reason}`, {}, "DELETE")
		.then((resp) => resp)
		.catch((err) => {
			throw err;
		});

export const softDeleteSingleGallerySubmission = (id, reason) =>
	invokeApi($axios, `/gallery/submission/soft-delete/${id}?reason=${reason}`, {}, "DELETE")
		.then((resp) => resp)
		.catch((err) => {
			throw err;
		});

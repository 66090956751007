import { Button, message } from "antd";
import React, { useEffect, useMemo, useState } from "react";
import { ErrorBoundary } from "react-error-boundary";
import { useDispatch, useSelector } from "react-redux";
import BasicLayout from "../../../components/BasicLayout/index";
import ButtonAsLink from "../../../components/common/ButtonAsLink";
import FallBack from "../../../components/common/FallBack/FallBack";
import Tables from "../../../components/common/Table/Table";
import { useDeleteAction } from "../../../components/Hooks/useDeleteAction";
import useExportCsv from "../../../components/Hooks/useExportCsv";
import useTableSearch from "../../../components/Hooks/useTableSearch";
import {
	getAllHSNCodes,
	softDeleteSingleHSNCode,
} from "../../../store/actions/products/HSNCode/hsnCode.action";
import { DeleteModel } from "../../../utils/DeleteModel";
import { formatFileName } from "../../../utils/helper";

const HSNCode = (props) => {
	const [getColumnSearchProps] = useTableSearch();
	const [exportToCSV] = useExportCsv();
	const [deleteId, setDeleteId] = useState(undefined);
	const [isModalVisible, setIsModalVisible] = useState(false);

	const { deleteData } = useDeleteAction();

	const dispatch = useDispatch();
	const hsnCodes = useSelector((state) => state.hsnCode.hsnCodes || []);

	const admin = useSelector((state) => state.admin.admin || []);

	const permissionArr = useMemo(() => {
		if (admin && admin.template) {
			const { permissions } = admin.template;
			return permissions;
		} else if (admin && admin.accessLevel === "superAdmin") {
			return "all";
		}
		return [];
	}, [admin]);

	useEffect(() => {
		dispatch(getAllHSNCodes());
	}, [dispatch]);

	let dataSource = [];
	if (hsnCodes?.length > 0) {
		dataSource = hsnCodes.sort(function (a, b) {
			return new Date(b.updatedAt) - new Date(a.updatedAt);
		});
		dataSource = dataSource.map((row, index) => ({
			...row,
			createdAt: new Date(row.createdAt).toLocaleString(),
			key: row.id,
		}));
	}
	async function confirm(id, reason) {
		await deleteData(id, reason, softDeleteSingleHSNCode, setIsModalVisible);
	}

	const columns = [
		{
			title: "Id",
			dataIndex: "id",
			key: "id",
		},
		{
			title: "HSN Code",
			dataIndex: "code",
			key: "code",
			...getColumnSearchProps("code"),
		},
		{
			title: "IGST",
			dataIndex: "igst",
			key: "igst",
			...getColumnSearchProps("igst"),
		},
		{
			title: "CGST",
			dataIndex: "cgst",
			key: "cgst",
			...getColumnSearchProps("cgst"),
		},
		{
			title: "SGST",
			dataIndex: "hgst",
			key: "hgst",
			...getColumnSearchProps("hgst"),
		},
		{
			title: "Action",
			key: "action",
			render: (_text, record) => (
				<DeleteModel
					record={record}
					confirm={confirm}
					setIsModalVisible={setIsModalVisible}
					isModalVisible={isModalVisible}
					cancelMessage="HSN Code Removing has been cancelled"
					linkUrl={`/products/hsncode`}
					placeholder="Please enter reason to delete HSN Code"
					deleteId={deleteId}
					setDeleteId={setDeleteId}
					update={
						permissionArr === "all"
							? "true"
							: permissionArr?.products?.hsnCode.filter((item) => item === "update")?.length > 0
							? "true"
							: false
					}
					isdelete={
						permissionArr === "all"
							? "true"
							: permissionArr?.products?.hsnCode.filter((item) => item === "delete")?.length > 0
							? "true"
							: false
					}
					view={
						permissionArr === "all"
							? "true"
							: permissionArr?.products?.hsnCode.filter((item) => item === "read")?.length > 0
							? "true"
							: false
					}
				/>
			),
		},
	];

	const fileName = formatFileName("HSN-Codes", "xlsx", admin);
	let downloadData = hsnCodes?.map((data) => {
		let newData = {
			"HSN/SAC Code": data.code,
			IGST: data.igst,
			CGST: data.cgst,
			SGST: data.hgst,
			"Generated At": data.createdAt,
		};
		return newData;
	});

	const handleError = (error) => {
		message.error(error.message);
	};
	return (
		<BasicLayout accessLevel={props.accessLevel}>
			<ErrorBoundary FallbackComponent={FallBack} onError={handleError}>
				<div className="button-style-to-right">
					{permissionArr === "all" ? (
						<Button className="download-btn" onClick={(e) => exportToCSV(downloadData, fileName)}>
							Export
						</Button>
					) : permissionArr?.products?.hsnCode?.filter((item) => item === "export")?.length > 0 &&
					  downloadData?.length !== 0 ? (
						<Button className="download-btn" onClick={(e) => exportToCSV(downloadData, fileName)}>
							Export
						</Button>
					) : null}
					{permissionArr === "all" ? (
						<ButtonAsLink text="Add New HSN Code" link="/products/hsncode/add" />
					) : permissionArr?.products?.hsnCode?.filter((item) => item === "write")?.length > 0 ? (
						<ButtonAsLink text="Add New HSN Code" link="/products/hsncode/add" />
					) : null}
				</div>
				<Tables
					dataSource={dataSource}
					columns={columns}
					footerText={`Total HSN Codes: ${hsnCodes?.length}`}
				/>
			</ErrorBoundary>
		</BasicLayout>
	);
};

export default HSNCode;

import Notification from "../../../components/common/Notification";
import * as careerApi from "../../../services/careers";
import {
	ADD_JOB_FAILURE,
	ADD_JOB_REQUEST,
	ADD_JOB_SUCCESS,
	DELETE_SINGLE_JOB_FAILURE,
	DELETE_SINGLE_JOB_REQUEST,
	DELETE_SINGLE_JOB_SUCCESS,
	GET_APPLICATION_FAILURE,
	GET_APPLICATION_REQUEST,
	GET_APPLICATION_SUCCESS,
	GET_JOB_FAILURE,
	GET_JOB_REQUEST,
	GET_JOB_SUCCESS,
	GET_SINGLE_APPLICATION_FAILURE,
	GET_SINGLE_APPLICATION_REQUEST,
	GET_SINGLE_APPLICATION_SUCCESS,
	GET_SINGLE_JOB_FAILURE,
	GET_SINGLE_JOB_REQUEST,
	GET_SINGLE_JOB_SUCCESS,
	UPDATE_SINGLE_JOB_FAILURE,
	UPDATE_SINGLE_JOB_REQUEST,
	UPDATE_SINGLE_JOB_SUCCESS,
	GET_CATEGORIES_SUCCESS,
	GET_CATEGORIES_REQUEST,
	GET_CATEGORIES_FAILURE,
	GET_SINGLE_CATEGORY_REQUEST,
	GET_SINGLE_CATEGORY_SUCCESS,
	GET_SINGLE_CATEGORY_FAILURE,
	DELETE_CATEGORIES_REQUEST,
	DELETE_CATEGORIES_SUCCESS,
	DELETE_CATEGORIES_FAILURE,
	UPDATE_SINGLE_CATEGORY_REQUEST,
	UPDATE_SINGLE_CATEGORY_SUCCESS,
	UPDATE_SINGLE_CATEGORY_FAILURE,
	ADD_SINGLE_CATEGORY_REQUEST,
	ADD_SINGLE_CATEGORY_SUCCESS,
	ADD_SINGLE_CATEGORY_FAILURE,
	SOFT_DELETE_SINGLE_JOB_REQUEST,
	SOFT_DELETE_SINGLE_JOB_SUCCESS,
	SOFT_DELETE_SINGLE_JOB_FAILURE,
	SOFT_DELETE_SINGLE_APPLICATION_REQUEST,
	SOFT_DELETE_SINGLE_APPLICATION_SUCCESS,
	SOFT_DELETE_SINGLE_APPLICATION_FAILURE,
	SOFT_DELETE_CATEGORIES_REQUEST,
	SOFT_DELETE_CATEGORIES_SUCCESS,
	SOFT_DELETE_CATEGORIES_FAILURE,
} from "./careers.actionType";

// GET REQUEST
export const requestJob = () => {
	return {
		type: GET_JOB_REQUEST,
	};
};
export const getJobSuccess = (data) => {
	return {
		type: GET_JOB_SUCCESS,
		data,
	};
};
export const getJobFailure = (message) => {
	return {
		type: GET_JOB_FAILURE,
		message,
	};
};

// Get Jobs Data Action Creator
export const getJob = () => {
	return (dispatch) => {
		dispatch(requestJob());
		return careerApi
			.getJob()
			.then((resp) => {
				const data = resp.data.data;
				dispatch(getJobSuccess(data));
			})
			.catch((err) => {
				const errorMessage = err.response && err.response.data.message;
				dispatch(getJobFailure(errorMessage));
			});
	};
};

// POST REQUEST
export const requestAddJob = () => {
	return {
		type: ADD_JOB_REQUEST,
	};
};
export const addJobSuccess = (data) => {
	return {
		type: ADD_JOB_SUCCESS,
		data,
	};
};
export const addJobFailure = (message) => {
	return {
		type: ADD_JOB_FAILURE,
		message,
	};
};

export const addJob = (data, history) => {
	return (dispatch) => {
		dispatch(requestAddJob());
		return careerApi
			.addJob(data)
			.then((resp) => {
				const serverResp = resp.data.data;
				dispatch(addJobSuccess(serverResp));
				history.push("/careers/jobs");
				Notification("success", "Job has been added succesfully", "", "topRight");
			})
			.catch((err) => {
				const errorMessage = err.response && err.response.data.message;
				dispatch(addJobFailure(errorMessage));
				Notification("error", "Error Occurred", errorMessage, "topRight");
			});
	};
};

//FOR UPDATE JOBS
export const requestUpdateJob = () => {
	return {
		type: UPDATE_SINGLE_JOB_REQUEST,
	};
};

export const updateJobSuccess = (data) => {
	return {
		type: UPDATE_SINGLE_JOB_SUCCESS,
		data,
	};
};

export const updateJobFailure = (message) => {
	return {
		type: UPDATE_SINGLE_JOB_FAILURE,
		message,
	};
};

export const updateJob = (id, data, history) => {
	return (dispatch) => {
		dispatch(requestUpdateJob());
		return careerApi
			.updateSingleJob(id, data)
			.then((_resp) => {
				dispatch(updateJobSuccess({ id, ...data }));
				history.push("/careers/jobs");
				Notification("success", "Job Updated SuccesFully", "", "topRight");
			})
			.catch((err) => {
				const errorMessage = err.response && err.response.data.message;
				dispatch(updateJobFailure(errorMessage));
				Notification("error", "Error Occurred", errorMessage, "topRight");
			});
	};
};

export const requestDeleteJob = () => {
	return {
		type: DELETE_SINGLE_JOB_REQUEST,
	};
};
export const deleteJobSuccess = (data) => {
	return {
		type: DELETE_SINGLE_JOB_SUCCESS,
		data,
	};
};
export const deleteJobFailure = (message) => {
	return {
		type: DELETE_SINGLE_JOB_FAILURE,
		message,
	};
};

export const deleteJob = (id) => {
	return (dispatch) => {
		dispatch(requestDeleteJob());
		return careerApi
			.deleteSingleJob(id)
			.then((_resp) => {
				dispatch(deleteJobSuccess(id));
				Notification("success", "Job has been deleted succesfully", "", "topRight");
			})
			.catch((err) => {
				const errorMessage = err.response && err.response.data.message;
				dispatch(deleteJobFailure(errorMessage));
				Notification("error", "Some thing went wrong! Please try again.", errorMessage, "topRight");
			});
	};
};

export const requestSoftDeleteJob = () => {
	return {
		type: SOFT_DELETE_SINGLE_JOB_REQUEST,
	};
};
export const softDeleteJobSuccess = (data) => {
	return {
		type: SOFT_DELETE_SINGLE_JOB_SUCCESS,
		data,
	};
};
export const softDeleteJobFailure = (message) => {
	return {
		type: SOFT_DELETE_SINGLE_JOB_FAILURE,
		message,
	};
};

export const softDeleteJob = (id, reason) => {
	return (dispatch) => {
		dispatch(requestSoftDeleteJob());
		return careerApi
			.softDeleteSingleJob(id, reason)
			.then((resp) => {
				dispatch(softDeleteJobSuccess(id));
				Notification("success", resp.data.message, "", "topRight");
			})
			.catch((err) => {
				const errorMessage = err.response && err.response.data.message;
				dispatch(softDeleteJobFailure(errorMessage));
				Notification("error", errorMessage, "", "topRight");
			});
	};
};

// GET SINGLE JOB REQUESTs
export const requestSingleJob = () => {
	return {
		type: GET_SINGLE_JOB_REQUEST,
	};
};

export const getSingleJobSuccess = (data) => {
	return {
		type: GET_SINGLE_JOB_SUCCESS,
		data,
	};
};

export const getSingleJobFailure = (message) => {
	return {
		type: GET_SINGLE_JOB_FAILURE,
		message,
	};
};

export const getSingleJob = (id, softdelete) => {
	return (dispatch) => {
		dispatch(requestSingleJob());
		return careerApi
			.getSingleJob(id, softdelete)
			.then((resp) => {
				const data = resp.data.data;
				dispatch(getSingleJobSuccess(data));
			})
			.catch((err) => {
				const errorMessage = err.response && err.response.data.message;
				dispatch(getSingleJobFailure(errorMessage));
				throw errorMessage;
			});
	};
};

// GET REQUEST FOR APPLICATION
export const requestApplication = () => {
	return {
		type: GET_APPLICATION_REQUEST,
	};
};
export const getApplicationSuccess = (data) => {
	return {
		type: GET_APPLICATION_SUCCESS,
		data,
	};
};
export const getApplicationFailure = (message) => {
	return {
		type: GET_APPLICATION_FAILURE,
		message,
	};
};
// Get Application Data Action Creator
export const getApplication = () => {
	return (dispatch) => {
		dispatch(requestApplication());
		return careerApi
			.getApplication()
			.then((resp) => {
				const data = resp.data.data;
				dispatch(getApplicationSuccess(data));
			})
			.catch((err) => {
				const errorMessage = err.response && err.response.data.message;
				dispatch(getApplicationFailure(errorMessage));
			});
	};
};

// GET SINGLE APPLICATION REQUESTs
export const requestSingleApplication = () => {
	return {
		type: GET_SINGLE_APPLICATION_REQUEST,
	};
};
export const getSingleApplicationSuccess = (data) => {
	return {
		type: GET_SINGLE_APPLICATION_SUCCESS,
		data,
	};
};
export const getSingleApplicationFailure = (message) => {
	return {
		type: GET_SINGLE_APPLICATION_FAILURE,
		message,
	};
};
// Action Creator For Single Job View
export const getSingleApplication = (id, softdelete) => {
	return (dispatch) => {
		dispatch(requestSingleApplication());
		return careerApi
			.getSingleApplication(id, softdelete)
			.then((resp) => {
				const data = resp.data.data;
				dispatch(getSingleApplicationSuccess(data));
			})
			.catch((err) => {
				const errorMessage = err.response && err.response.data.message;
				dispatch(getSingleApplicationFailure(errorMessage));
				throw errorMessage;
			});
	};
};

export const requestSoftDeleteSingleApplication = () => {
	return {
		type: SOFT_DELETE_SINGLE_APPLICATION_REQUEST,
	};
};

export const softDeleteSingleApplicationSuccess = (data) => {
	return {
		type: SOFT_DELETE_SINGLE_APPLICATION_SUCCESS,
		data,
	};
};

export const softDeleteSingleApplicationFailure = (message) => {
	return {
		type: SOFT_DELETE_SINGLE_APPLICATION_FAILURE,
		message,
	};
};

export const softDeleteSingleApplication = (id, reason) => {
	return (dispatch) => {
		dispatch(requestSoftDeleteSingleApplication());
		return careerApi
			.softDeleteSingleApplication(id, reason)
			.then((resp) => {
				dispatch(softDeleteSingleApplicationSuccess(id));
				Notification("success", resp.data.message, "", "topRight");
			})
			.catch((err) => {
				const errorMessage = err.response && err.response.data.message;
				dispatch(softDeleteSingleApplicationFailure(errorMessage));
				Notification("error", errorMessage, "", "topRight");
			});
	};
};

export const requestGetCategory = () => {
	return {
		type: GET_CATEGORIES_REQUEST,
	};
};
export const getCategorySuccess = (data) => {
	return {
		type: GET_CATEGORIES_SUCCESS,
		data,
	};
};
export const getCategoryFailure = (message) => {
	return {
		type: GET_CATEGORIES_FAILURE,
		message,
	};
};
export const getCategories = () => {
	return (dispatch) => {
		dispatch(requestGetCategory());
		return careerApi
			.getCategories()
			.then((resp) => {
				const data = resp.data.data;
				dispatch(getCategorySuccess(data));
			})
			.catch((err) => {
				const errorMessage = err.response && err.response.data.message;
				dispatch(getCategoryFailure(errorMessage));
			});
	};
};

export const requestGetSingleCategory = () => {
	return {
		type: GET_SINGLE_CATEGORY_REQUEST,
	};
};
export const getSingleCategorySuccess = (data) => {
	return {
		type: GET_SINGLE_CATEGORY_SUCCESS,
		data,
	};
};
export const getSingleCategoryFailure = (message) => {
	return {
		type: GET_SINGLE_CATEGORY_FAILURE,
		message,
	};
};
export const getSingleCategory = (id, softdelete) => {
	return (dispatch) => {
		dispatch(requestGetSingleCategory());
		return careerApi
			.getSingleCategory(id, softdelete)
			.then((resp) => {
				const data = resp.data.data;
				dispatch(getSingleCategorySuccess(data));
			})
			.catch((err) => {
				const errorMessage = err.response && err.response.data.message;
				dispatch(getSingleCategoryFailure(errorMessage));
				throw errorMessage;
			});
	};
};

export const requestAddCategory = () => {
	return {
		type: ADD_SINGLE_CATEGORY_REQUEST,
	};
};
export const addCategorySuccess = (data) => {
	return {
		type: ADD_SINGLE_CATEGORY_SUCCESS,
		data,
	};
};
export const addCategoryFailure = (message) => {
	return {
		type: ADD_SINGLE_CATEGORY_FAILURE,
		message,
	};
};
export const addSingleCategory = (data, history) => {
	return (dispatch) => {
		dispatch(requestAddCategory());
		return careerApi
			.addCategory(data)
			.then((resp) => {
				const data = resp.data.data;
				dispatch(addCategorySuccess(data));
				history.push("/careers/categories");
				Notification("success", "Category has been succesfully added.", "", "topRight");
			})
			.catch((err) => {
				const errorMessage = err.response && err.response.data.message;
				dispatch(addCategoryFailure(errorMessage));
				Notification("error", "Error Occurred", errorMessage, "topRight");
			});
	};
};

export const requestUpdateSingleCategory = () => {
	return {
		type: UPDATE_SINGLE_CATEGORY_REQUEST,
	};
};
export const updateSingleCategorySuccess = (data) => {
	return {
		type: UPDATE_SINGLE_CATEGORY_SUCCESS,
		data,
	};
};
export const updateCategoryFailure = (message) => {
	return {
		type: UPDATE_SINGLE_CATEGORY_FAILURE,
		message,
	};
};
export const updateSingleCategory = (id, data, history) => {
	return (dispatch) => {
		dispatch(requestUpdateSingleCategory());
		return careerApi
			.updateSingleCategory(id, data)
			.then((_resp) => {
				dispatch(updateSingleCategorySuccess({ id, ...data }));
				history.push("/careers/categories");
				Notification("success", "Category has been succesfully updated.", "", "topRight");
			})
			.catch((err) => {
				const errorMessage = err.response && err.response.data.message;
				dispatch(updateCategoryFailure(errorMessage));
				Notification("error", "Error Occurred", errorMessage, "topRight");
			});
	};
};

export const requestDeleteCategory = () => {
	return {
		type: DELETE_CATEGORIES_REQUEST,
	};
};
export const deleteCategorySuccess = (data) => {
	return {
		type: DELETE_CATEGORIES_SUCCESS,
		data,
	};
};
export const deleteCategoryFailure = (message) => {
	return {
		type: DELETE_CATEGORIES_FAILURE,
		message,
	};
};
export const deleteCategory = (id) => {
	return (dispatch) => {
		dispatch(requestDeleteCategory());
		return careerApi
			.deleteSingleCategory(id)
			.then((_resp) => {
				dispatch(deleteCategorySuccess(id));
				Notification("success", "Category has been succesfully deleted.", "", "topRight");
			})
			.catch((err) => {
				const errorMessage = err.response && err.response.data.message;
				dispatch(deleteCategoryFailure(errorMessage));
				Notification("error", "Something wen wrong! Please try Again..", "", "topRight");
			});
	};
};

export const requestSoftDeleteCategory = () => {
	return {
		type: SOFT_DELETE_CATEGORIES_REQUEST,
	};
};
export const softDeleteCategorySuccess = (data) => {
	return {
		type: SOFT_DELETE_CATEGORIES_SUCCESS,
		data,
	};
};
export const softDeleteCategoryFailure = (message) => {
	return {
		type: SOFT_DELETE_CATEGORIES_FAILURE,
		message,
	};
};
export const softDeleteCategory = (id, reason) => {
	return (dispatch) => {
		dispatch(requestSoftDeleteCategory());
		return careerApi
			.softDeleteSingleCategory(id, reason)
			.then((resp) => {
				dispatch(softDeleteCategorySuccess(id));
				Notification("success", resp.data.message, "", "topRight");
			})
			.catch((err) => {
				const errorMessage = err.response && err.response.data.message;
				dispatch(softDeleteCategoryFailure(errorMessage));
				Notification("error", errorMessage, "", "topRight");
			});
	};
};

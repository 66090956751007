import { Card, Image, message, Anchor, Tag } from "antd";
import { useEffect } from "react";
import { ErrorBoundary } from "react-error-boundary";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import BasicLayout from "../../components/BasicLayout";
import FallBack from "../../components/common/FallBack/FallBack";
import Notification from "../../components/common/Notification";
import { CustomTab } from "../../components/common/Tab/Tab";
import { getSingleUser } from "../../store/actions/user/user.action";

const ViewBrand = (props) => {
	const userId = +props.match.params.id;
	const dispatch = useDispatch();
	const user = useSelector((state) => state.user.user || {});
	const { Link } = Anchor;
	const history = useHistory();

	const location = useLocation();
	const softdelete = new URLSearchParams(location.search).get("soft-delete");
	useEffect(() => {
		dispatch(getSingleUser(userId, softdelete)).catch((err) => {
			Notification("error", err, "", "topRight");
			history.push("/usermanagement/user");
		});
	}, [dispatch, history, softdelete, userId]);

	if (user?.state?.length > 0) {
	}

	let data = [];
	let isOrderEmpty = Object.keys(user).length === 0;
	if (!isOrderEmpty) {
		data = [
			{ title: "Id", description: user.id },
			{
				title: "Category",
				description: user?.userCategory,
			},
			{
				title: "Name",
				description: user?.userName,
			},
			{
				title: "Company Name",
				description: user?.companyName
					? user?.companyName
					: "Not Provided Cause of user consists employee category",
			},
			{
				title: "Company PAN Card Number",
				description: user?.companyPANCardNumber ? user?.companyPANCardNumber : "NA",
			},
			{
				title: "Owner Name",
				description: user?.ownerName
					? user?.ownerName
					: "Not Provided Cause of user consists employee category",
			},
			{
				title: "Email",
				description: user?.email,
			},
			{
				title: "Phone Number",
				description: user?.phoneNumber,
			},
			{
				title: "Description",
				description: user?.description ? user?.description : "Not Provided",
			},
			{
				title: "State",
				description:
					user?.state.length > 0 ? (
						<>
							{user?.state?.map((state) => {
								return (
									<Tag color="green" key={state}>
										{state.toUpperCase()}
									</Tag>
								);
							})}
						</>
					) : (
						user.state
					),
			},
			{
				title: "District",
				description:
					user?.district.length > 0 ? (
						<>
							{user?.district?.map((district) => {
								return (
									<Tag color="pink" key={district}>
										{district.toUpperCase()}
									</Tag>
								);
							})}
						</>
					) : (
						user.district
					),
			},
			{
				title: "Sub District",
				description:
					user?.subDistrict.length > 0 ? (
						<>
							{user?.subDistrict?.map((subDistrict) => {
								return (
									<Tag color="blue" key={subDistrict}>
										{subDistrict.toUpperCase()}
									</Tag>
								);
							})}
						</>
					) : (
						user.subDistrict
					),
			},
			{
				title: "Village",
				description: user?.village,
			},

			{
				title: "TDS Number",
				description: user?.tdsNumber,
			},
			{
				title: "GST Number",
				description: user?.gstNumber ? user?.gstNumber : "Not Provided",
			},
			{
				title: "Is GST Composite",
				description: user?.gstType ? user?.gstType : "NA",
			},
			{
				title: "Office Address",
				description: user?.officeAddress,
			},
			{
				title: "Godown Address",
				description: user?.godownAddress ? user?.godownAddress : "Not Provided",
			},
			{
				title: "How Many User Can Add Subusers",
				description: user?.allowToAddSubusers
					? user?.allowToAddSubusers
					: "Not Allow To Add Subuser's",
			},
			{
				title: "Company Status",
				description: user?.companyStatus
					? user?.companyStatus
					: " Not Provided cause of user category is Employee",
			},
			{
				title: "Company CIN Number",
				description: user?.companyCINNumber ? user?.companyCINNumber : "NA",
			},
			{
				title: "Template Name",
				description: user?.template?.name,
			},

			{
				title: "Received",
				description: new Date(user?.createdAt).toLocaleString(),
			},

			{
				title: "Company Logo",
				description: user?.companyLogoUrl ? (
					<div className="gallery-images">
						<div className="image-holder-container">
							<Image width={250} height={180} src={user?.companyLogoUrl} />
						</div>
					</div>
				) : (
					" Not Provided cause of user category is Employee"
				),
			},

			{
				title: "Identity Document",
				description: user?.identityDocUrl ? (
					<Anchor>
						<Link href={user?.identityDocUrl} title="View" target="_blank"></Link>
					</Anchor>
				) : (
					"NA"
				),
			},
			{
				title: "GST Certificate PDF",
				description: user?.gstCertificateUrl ? (
					<Anchor>
						<Link href={user?.gstCertificateUrl} title="View" target="_blank"></Link>
					</Anchor>
				) : (
					"NA"
				),
			},
			{
				title: "Company Registration Certificate PDF",
				description: user?.companyRegisterUrl ? (
					<Anchor>
						<Link href={user?.companyRegisterUrl} title="View" target="_blank"></Link>
					</Anchor>
				) : (
					"NA"
				),
			},
		];

		if (user?.userCategory === "employee") {
			data = data.filter((element) => {
				if (
					element.title === "Company Name" ||
					element.title === "Company Status" ||
					element.title === "Company Registration Certificate PDF" ||
					element.title === "Company Logo" ||
					element.title === "Owner Name" ||
					element.title === "TDS Number" ||
					element.title === "GST Certificate PDF" ||
					element.title === "Godown Address"
				) {
					return false;
				} else {
					return true;
				}
			});
		}

		if (user?.gstNumber === null) {
			data = data.filter((element) => {
				if (element.title === "GST Number" || element.title === "GST Certificate PDF") {
					return false;
				} else {
					return true;
				}
			});
		}

		if (user?.district.length === 0) {
			data = data.filter((element) => {
				if (element.title === "District") {
					return false;
				} else {
					return true;
				}
			});
		}

		if (user?.subDistrict.length === 0) {
			data = data.filter((element) => {
				if (element.title === "Sub District") {
					return false;
				} else {
					return true;
				}
			});
		}

		if (user?.allowToAddSubusers === null) {
			data = data.filter((element) => {
				if (element.title === "How Many User Can Add Subusers") {
					return false;
				} else {
					return true;
				}
			});
		}
		if (user?.companyCINNumber === null) {
			data = data.filter((element) => {
				if (element.title === "Company CIN Number") {
					return false;
				} else {
					return true;
				}
			});
		}
		if (user?.gstType === null) {
			data = data.filter((element) => {
				if (element.title === "Is GST Composite") {
					return false;
				} else {
					return true;
				}
			});
		}
	}

	const handleError = (error) => {
		message.error(error.message);
	};

	let operationData = {
		deletedBy: user?.deletedBy,
		updatedBy: user?.updatedBy,
		restoredBy: user?.restoredBy,
	};

	return (
		<BasicLayout accessLevel={props.accessLevel}>
			<ErrorBoundary FallbackComponent={FallBack} onError={handleError}>
				<Card className="enqiury-detail-card" data-testid="enqiury-detail-card">
					<CustomTab
						data={data}
						operationData={operationData}
						redirectUrl={`/usermanagement/user/update/${userId}`}
					/>
				</Card>
			</ErrorBoundary>
		</BasicLayout>
	);
};

export default ViewBrand;

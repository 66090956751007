import Notification from "../../../components/common/Notification";
import * as subAssemblyApi from "../../../services/subassemblies";
import {
	ADD_SUB_ASSEMBLY_FAILURE,
	ADD_SUB_ASSEMBLY_REQUEST,
	ADD_SUB_ASSEMBLY_SUCCESS,
	GET_SUB_ASSEMBLIES_FAILURE,
	GET_SUB_ASSEMBLIES_REQUEST,
	GET_SUB_ASSEMBLIES_SUCCESS,
	GET_SINGLE_SUB_ASSEMBLY_FAILURE,
	GET_SINGLE_SUB_ASSEMBLY_REQUEST,
	GET_SINGLE_SUB_ASSEMBLY_SUCCESS,
	RESTORE_SINGLE_SUB_ASSEMBLY_FAILURE,
	RESTORE_SINGLE_SUB_ASSEMBLY_REQUEST,
	RESTORE_SINGLE_SUB_ASSEMBLY_SUCCESS,
	SOFT_DELETE_SINGLE_SUB_ASSEMBLY_FAILURE,
	SOFT_DELETE_SINGLE_SUB_ASSEMBLY_REQUEST,
	SOFT_DELETE_SINGLE_SUB_ASSEMBLY_SUCCESS,
	UPDATE_SUB_ASSEMBLY_FAILURE,
	UPDATE_SUB_ASSEMBLY_REQUEST,
	UPDATE_SUB_ASSEMBLY_SUCCESS,
	GET_MODEL_RELATED_SUB_ASSEMBLIES_REQUEST,
	GET_MODEL_RELATED_SUB_ASSEMBLIES_SUCCESS,
	GET_MODEL_RELATED_SUB_ASSEMBLIES_FAILURE,
} from "./subAssembly.actionType";

// GET REQUEST
export const requestGetAllSubAssemblies = () => {
	return {
		type: GET_SUB_ASSEMBLIES_REQUEST,
	};
};
export const getAllSubAssembliesSuccess = (data) => {
	return {
		type: GET_SUB_ASSEMBLIES_SUCCESS,
		data,
	};
};
export const getAllSubAssembliesFailure = (message) => {
	return {
		type: GET_SUB_ASSEMBLIES_FAILURE,
		message,
	};
};

// Get All SubAssemblies
export const getAllSubAssemblies = () => {
	return (dispatch) => {
		dispatch(requestGetAllSubAssemblies());
		return subAssemblyApi
			.getAllSubAssemblies()
			.then((resp) => {
				const data = resp.data.data;
				dispatch(getAllSubAssembliesSuccess(data));
			})
			.catch((err) => {
				const errorMessage =
					(err?.response && err?.response?.data?.message) || "Something went wrong!";
				dispatch(getAllSubAssembliesFailure(errorMessage));
			});
	};
};

export const requestGetSingleSubAssembly = () => {
	return {
		type: GET_SINGLE_SUB_ASSEMBLY_REQUEST,
	};
};

export const getSingleSubAssemblySuccess = (data) => {
	return {
		type: GET_SINGLE_SUB_ASSEMBLY_SUCCESS,
		data,
	};
};

export const getSingleSubAssemblyFailure = (message) => {
	return {
		type: GET_SINGLE_SUB_ASSEMBLY_FAILURE,
		message,
	};
};

export const getSingleSubAssembly = (id, softdelete) => {
	return (dispatch) => {
		dispatch(requestGetSingleSubAssembly());
		return subAssemblyApi
			.getSingleSubAssembly(id, softdelete)
			.then((resp) => {
				const data = resp.data.data;
				dispatch(getSingleSubAssemblySuccess(data));
			})
			.catch((err) => {
				const errorMessage =
					(err?.response && err?.response?.data?.message) || "Something went wrong";
				dispatch(getSingleSubAssemblyFailure(errorMessage));
				throw errorMessage;
			});
	};
};

export const requestSoftDeleteSingleSubAssembly = () => {
	return {
		type: SOFT_DELETE_SINGLE_SUB_ASSEMBLY_REQUEST,
	};
};

export const softDeleteSingleSubAssemblySuccess = (data) => {
	return {
		type: SOFT_DELETE_SINGLE_SUB_ASSEMBLY_SUCCESS,
		data,
	};
};

export const softDeleteSingleSubAssemblyFailure = (message) => {
	return {
		type: SOFT_DELETE_SINGLE_SUB_ASSEMBLY_FAILURE,
		message,
	};
};

export const softDeleteSingleSubAssembly = (id, reason) => {
	return (dispatch) => {
		dispatch(requestSoftDeleteSingleSubAssembly());
		return subAssemblyApi
			.softDeleteSingleSubAssembly(id, reason)
			.then((resp) => {
				dispatch(softDeleteSingleSubAssemblySuccess(id));
				return Notification("success", resp.data.message, "", "topRight");
			})
			.catch((err) => {
				const errorMessage =
					(err?.response && err?.response?.data?.message) || "Something went wrong";
				dispatch(softDeleteSingleSubAssemblyFailure(errorMessage));
				throw errorMessage;
			});
	};
};

export const requestSubAssemblyRestore = () => {
	return {
		type: RESTORE_SINGLE_SUB_ASSEMBLY_REQUEST,
	};
};

export const restoreSubAssemblySuccess = () => {
	return {
		type: RESTORE_SINGLE_SUB_ASSEMBLY_SUCCESS,
	};
};

export const restoreSubAssemblyFailure = (message) => {
	return {
		type: RESTORE_SINGLE_SUB_ASSEMBLY_FAILURE,
		message,
	};
};

export const restoreSubAssembly = (id) => {
	return (dispatch) => {
		dispatch(requestSubAssemblyRestore());
		return subAssemblyApi
			.restoreSubAssembly(id)
			.then((resp) => {
				dispatch(restoreSubAssemblySuccess(id));
				Notification("success", resp.data.message, "", "topRight");
			})
			.catch((err) => {
				const errorMessage = (err.response && err.response.data.message) || "Something went wrong!";
				dispatch(restoreSubAssemblyFailure(errorMessage));
			});
	};
};

export const requestSubAssemblyAdd = () => {
	return {
		type: ADD_SUB_ASSEMBLY_REQUEST,
	};
};

export const SubAssemblyAddSuccess = (data) => {
	return {
		type: ADD_SUB_ASSEMBLY_SUCCESS,
		data,
	};
};

export const SubAssemblyAddFailure = (message) => {
	return {
		type: ADD_SUB_ASSEMBLY_FAILURE,
		message,
	};
};

export const addSubAssembly = (data) => {
	return (dispatch) => {
		dispatch(requestSubAssemblyAdd());
		return subAssemblyApi
			.addSubAssembly(data)
			.then((resp) => {
				const data = resp.data.data;
				return data;
			})
			.catch((err) => {
				const errorMessage = err.response && err.response.data.message;
				dispatch(SubAssemblyAddFailure(errorMessage));
				Notification("error", "Error Occurred", errorMessage);
			});
	};
};

//FOR UPDATE SubAssembly
export const requestUpdateSubAssembly = () => {
	return {
		type: UPDATE_SUB_ASSEMBLY_REQUEST,
	};
};

export const updateSubAssemblySuccess = (data) => {
	return {
		type: UPDATE_SUB_ASSEMBLY_SUCCESS,
		data,
	};
};

export const updateSubAssemblyFailure = (message) => {
	return {
		type: UPDATE_SUB_ASSEMBLY_FAILURE,
		message,
	};
};

export const updateSubAssembly = (id, data) => {
	return (dispatch) => {
		dispatch(requestUpdateSubAssembly());
		return subAssemblyApi
			.updateSubAssembly(id, data)
			.then((resp) => {
				const data = resp.data;
				return data;
			})
			.catch((err) => {
				const errorMessage = err.response && err.response.data.message;
				dispatch(updateSubAssemblyFailure(errorMessage));
				throw err;
			});
	};
};

export const updateSubAssemblyFromTable = (id, data) => {
	return (dispatch) => {
		dispatch(requestUpdateSubAssembly());
		return subAssemblyApi
			.updateSubAssembly(id, data)
			.then(async(resp) => {
				await dispatch(getAllSubAssemblies());
				dispatch(updateSubAssemblySuccess());
				const message = resp.data.message;
				return Notification("success", "", message);
			})
			.catch((err) => {
				const errorMessage = err.response && err.response.data.message;
				dispatch(updateSubAssemblyFailure(errorMessage));
				throw err;
			});
	};
};

export const requestGetModelRelatedSubAssemblies = () => {
	return {
		type: GET_MODEL_RELATED_SUB_ASSEMBLIES_REQUEST,
	};
};

export const getModelRelatedSubAssembliesSuccess = (data) => {
	return {
		type: GET_MODEL_RELATED_SUB_ASSEMBLIES_SUCCESS,
		data,
	};
};

export const getModelRelatedSubAssembliesFailure = (message) => {
	return {
		type: GET_MODEL_RELATED_SUB_ASSEMBLIES_FAILURE,
		message,
	};
};

export const getModelRelatedSubAssemblies = (id) => {
	return (dispatch) => {
		dispatch(requestGetModelRelatedSubAssemblies());
		return subAssemblyApi
			.getModelRelatedSubAssemblies(id)
			.then((resp) => {
				const data = resp.data.data;
				dispatch(getModelRelatedSubAssembliesSuccess(data));
			})
			.catch((err) => {
				const errorMessage =
					(err?.response && err?.response?.data?.message) || "Something went wrong";
				dispatch(getModelRelatedSubAssembliesFailure(errorMessage));
			});
	};
};

import { UserOutlined } from "@ant-design/icons";
import { Button, Card, Col, Form, message, Radio, Row, Select, Space, Typography } from "antd";
import axios from "axios";
import React, { useCallback, useEffect, useState } from "react";
import { useMemo } from "react";
import { useDropzone } from "react-dropzone";
import { ErrorBoundary } from "react-error-boundary";
import { useDispatch, useSelector } from "react-redux";
import { NavLink, useHistory, useLocation } from "react-router-dom";
import BasicLayout from "../../../components/BasicLayout";
import Center from "../../../components/common/Center";
import FallBack from "../../../components/common/FallBack/FallBack";
import Notification from "../../../components/common/Notification";
import Spinner from "../../../components/common/Spinner";
import InputField from "../../../components/Form/InputField";
import Upload from "../../../components/Form/Upload";
import {
	getAllImplementRelatedImplementsSubAssemblies,
	getAllImplementRelatedImplementsSubAssembliesSuccess,
} from "../../../store/actions/products/implementsSubAssembly/implementsSubAssemblies.action";
import { getAllModels } from "../../../store/actions/products/model/model.action";
import { getAllHSNCodes } from "../../../store/actions/products/HSNCode/hsnCode.action";
import {
	addSparePart,
	getSingleSparePart,
	makeSparePartDataEmpty,
	SparePartAddSuccess,
} from "../../../store/actions/products/spareparts/spareparts.action";
import { formatFileName } from "../../../utils/helper";
import { aspectRatioDependancy } from "../../../utils/imageAspectRatio";
import { imageSizeDependancy } from "../../../utils/imageSize";
import CommonSelect from "../../../components/Form/Select";

import "./SparePart.css";

const SparePartForm = (props) => {
	const [form] = Form.useForm();
	const { Title, Text } = Typography;
	const currentLocation = props.location.pathname;
	const location = useLocation();
	const softdelete = new URLSearchParams(location.search).get("soft-delete");
	const history = useHistory();
	const dispatch = useDispatch();
	const admin = useSelector((state) => state.admin.admin || {});

	const onImagesDrop = useCallback(async (acceptedFiles, fileRejections) => {
		try {
			setFile([]);
			setCompressedImages([]);
			imageSizeDependancy(fileRejections);
			await aspectRatioDependancy(acceptedFiles, setFile);
		} catch (error) {
			const errorMessage = error?.message || "Something went wrong";
			Notification("error", errorMessage, "", "topRight");
		}
	}, []);

	const {
		getRootProps: getImageRootProps,
		getInputProps: getImageInputProps,
		isDragActive: isImageDragActive,
	} = useDropzone({
		onDrop: onImagesDrop,
		accept: " image/png",
		multiple: false,
		maxSize: "1000000",
	});

	const handleError = (error) => {
		message.error(error.message);
	};

	const handleCancel = () => {
		Notification("info", "Spare part action has been cancelled", "", "topRight");
	};

	const sparePartId = +props.match.params.id;
	const sparepart = useSelector((state) => state.sparepart.sparepart);
	const hsnCodes = useSelector((state) => state.hsnCode.hsnCodes || []);

	const [modelId, setModelId] = useState(undefined);
	const models = useSelector((state) => state.model.models || []);

	const [implementsId, setImplementsId] = useState(undefined);
	const [imagesForSparePart, setFile] = useState([]);
	const [compressedImages, setCompressedImages] = useState([]);
	const [modelType, setModelType] = useState("ModelName");
	const [isSparePartLoading, setIsSparePartLoading] = useState(false);
	const [isHSNCodeLoading, setIsHSNCodeLoading] = useState(false);

	const implementRelatedimplementsSubAssemblies = useSelector(
		(state) => state.implementsSubAssembly.implementRelatedimplementsSubAssemblies
	);

	const subAssmebliesRelatedToModels = useMemo(() => {
		if (!modelId) {
			return [];
		}
		const selectedModels = models?.filter((model) => {
			return modelId?.includes(model?.id?.toString());
		});
		if (selectedModels?.length > 0) {
			let tempSubAssmebliesArr = [];

			selectedModels.forEach((model) => {
				tempSubAssmebliesArr.push(...model?.multipleSubAssemblies);
			});
			return tempSubAssmebliesArr;
		}
		return [];
	}, [models, modelId]);

	const finalSubAssmebliesRelatedToModels = subAssmebliesRelatedToModels.filter(
		(v, i, a) => a.findIndex((v2) => v2.id === v.id) === i
	);

	const implementsRelatedToModels = useMemo(() => {
		if (!modelId) {
			return [];
		}
		const selectedModels = models?.filter((model) => {
			return modelId?.includes(model?.id?.toString());
		});
		if (selectedModels?.length > 0) {
			let tempImplementsArr = [];

			selectedModels.forEach((model) => {
				tempImplementsArr.push(...model?.multipleImplements);
			});
			return tempImplementsArr;
		}
		return [];
	}, [models, modelId]);

	const finalImplementsRelatedToModels = implementsRelatedToModels.filter(
		(v, i, a) => a.findIndex((v2) => v2.id === v.id) === i
	);

	useEffect(() => {
		async function fetchData() {
			setIsHSNCodeLoading(true);
			await dispatch(getAllHSNCodes());
			setIsHSNCodeLoading(false);
		}
		fetchData();
	}, [dispatch]);

	useEffect(() => {
		dispatch(getAllModels());
	}, [dispatch]);

	useEffect(() => {
		if (sparepart?.userModelType) {
			setModelType(sparepart.userModelType);
		}
	}, [sparepart]);

	useEffect(() => {
		if (sparePartId) {
			dispatch(getSingleSparePart(sparePartId, softdelete)).catch((err) => {});
		}
	}, [dispatch, sparePartId, softdelete]);

	useEffect(() => {
		if (currentLocation !== "/products/sparepart/add" && sparepart) {
			if (sparepart?.models) {
				if (sparepart?.models[0]?.id) {
					setModelId(sparepart?.models[0]?.id);
				}
			}
		}
	}, [currentLocation, sparepart]);

	// This useeffect sets mulipleiimplements ids is usestate
	useEffect(() => {
		if (currentLocation !== "/products/sparepart/add" && sparepart) {
			if (sparepart.multipleImplements) {
				if (sparepart.multipleImplements.length > 0) {
					try {
						let implementArr = [];
						for (let i = 0; i < sparepart.multipleImplements.length; i++) {
							implementArr.push(sparepart.multipleImplements[i].id.toString());
						}
						setImplementsId(implementArr);
					} catch (err) {
						return Notification("error", err.message || "Something went wrong", "", "topRight");
					}
				}
			}
		}
	}, [currentLocation, sparepart]);

	useEffect(() => {
		if (implementsId) {
			let data = { multipleimplements: implementsId };
			dispatch(getAllImplementRelatedImplementsSubAssemblies(data));
		}
	}, [dispatch, implementsId]);

	useEffect(() => {
		async function fetchData() {
			try {
				if (currentLocation !== "/products/sparepart/add" && sparePartId) {
					setIsSparePartLoading(true);
					await dispatch(getSingleSparePart(sparePartId, softdelete));
					setIsSparePartLoading(false);
				} else {
					await dispatch(makeSparePartDataEmpty());
				}
			} catch (error) {
				setIsSparePartLoading(false);
				const errorMessage = error?.response?.data?.message || "Something went wrong";
				Notification("error", errorMessage, "", "topRight");
				history.push("/products/sparepart");
			}
		}
		fetchData();
	}, [sparePartId, dispatch, history, currentLocation, softdelete]);

	const { Option } = Select;
	const modelChildren = [];
	for (let i = 0; i < models?.length; i++) {
		modelChildren.push(
			<Option key={models[i]?.id} title={models[i]?.name}>
				{models[i]?.name} ({models[i]?.productNumber})
			</Option>
		);
	}

	const modelNumberChildren = [];
	for (let i = 0; i < models?.length; i++) {
		modelNumberChildren.push(
			<Option key={models[i]?.id} title={models[i]?.productNumber}>
				{models[i]?.productNumber}
			</Option>
		);
	}
	const children = [];
	for (let i = 0; i < finalImplementsRelatedToModels?.length; i++) {
		children.push(
			<Option
				key={finalImplementsRelatedToModels[i]?.id}
				title={finalImplementsRelatedToModels[i]?.name}
			>
				{finalImplementsRelatedToModels[i]?.name} ({finalImplementsRelatedToModels[i]?.code})
			</Option>
		);
	}

	const SubAssemblyChildren = [];
	for (let i = 0; i < finalSubAssmebliesRelatedToModels?.length; i++) {
		SubAssemblyChildren.push(
			<Option
				key={finalSubAssmebliesRelatedToModels[i]?.id}
				title={finalSubAssmebliesRelatedToModels[i]?.name}
			>
				{finalSubAssmebliesRelatedToModels[i]?.name} ({finalSubAssmebliesRelatedToModels[i]?.code})
			</Option>
		);
	}

	const ImplementsSubAssembliesChildren = [];
	for (let i = 0; i < implementRelatedimplementsSubAssemblies?.length; i++) {
		ImplementsSubAssembliesChildren.push(
			<Option
				key={implementRelatedimplementsSubAssemblies[i]?.id}
				title={implementRelatedimplementsSubAssemblies[i]?.name}
			>
				{implementRelatedimplementsSubAssemblies[i]?.name} (
				{implementRelatedimplementsSubAssemblies[i]?.code})
			</Option>
		);
	}

	const imageUrl = sparePartId ? sparepart?.imageUrl : undefined;

	const handleModelValueChange = async (e) => {
		await dispatch(getAllImplementRelatedImplementsSubAssembliesSuccess([]));
		setModelId(e);
		form.setFieldsValue({
			models: e,
		});
	};

	const HSNCodeChildren = useMemo(
		() =>
			hsnCodes?.map((hsnCode) => ({
				key: `${hsnCode.code} `,
				value: hsnCode.id,
				title: hsnCode.code,
			})),
		[hsnCodes]
	);

	const onFinish = async (values) => {
		try {
			values = {
				...values,
				userModelType: modelType,
			};
			if (
				!values.multipleImplements &&
				!values.multipleSubAssemblies &&
				!values.subAssemblyImplements
			) {
				Notification(
					"error",
					"Please choose implements or sub assembly or implement sub assembly",
					"",
					"topRight"
				);
				return;
			}
			if (
				values.multipleImplements?.length === 0 &&
				values.multipleSubAssemblies?.length === 0 &&
				values.subAssemblyImplements?.length === 0
			) {
				Notification(
					"error",
					"Please choose implements or sub assembly or implement sub assembly",
					"",
					"topRight"
				);
				return;
			}
			if (!values.multipleImplements) {
				values = { ...values, multipleImplements: [] };
			}
			if (!values.multipleSubAssemblies) {
				values = { ...values, multipleSubAssemblies: [] };
			}
			if (!values.subAssemblyImplements) {
				values = { ...values, subAssemblyImplements: [] };
			}
			if (currentLocation === "/products/sparepart/add") {
				if (compressedImages.length === 0) {
					Notification("error", "Please upload a valid images", "", "topRight");
					return;
				}
				// For Images
				let fileName = formatFileName(
					compressedImages[0].name.split(".")[0],
					compressedImages[0].name.split(".").pop(),
					admin
				);

				let filetype = compressedImages[0].type;
				values = {
					...values,
					fileName,
					filetype,
				};

				const resp = await dispatch(addSparePart(values));

				if (resp) {
					const options = {
						headers: {
							"Content-Type": compressedImages[0].type,
						},
					};
					try {
						await axios.put(resp, compressedImages[0], options);
						Notification("success", "Spare part has been succesfully added.", "", "topRight");
						await dispatch(SparePartAddSuccess());
						history.push("/products/sparepart");
						return;
					} catch (error) {
						const errorMessage = error.message || "Something went wrong";
						Notification("error", errorMessage, "", "topRight");
					}
				} else {
					Notification("error", "Something went wrong", "", "topRight");
				}
			}
		} catch (err) {
			if (err.message) {
				return Notification("error", "Error Occurred", err.message, "topRight");
			}
			const errorMessage =
				(err?.response && err?.response?.data?.message) || "Something went wrong";
			return Notification("error", "Error Occurred", errorMessage, "topRight");
		}
	};

	if (isSparePartLoading || isHSNCodeLoading) {
		return (
			<Center>
				<Spinner />
			</Center>
		);
	}
	return (
		<BasicLayout accessLevel={props.accessLevel}>
			<ErrorBoundary FallbackComponent={FallBack} onError={handleError}>
				<Row type="flex" justify="center" align="middle" id="form-container">
					<Center height="4rem">
						<Title level={2} className="update-or-add-title">
							Add New Spare Part
						</Title>
					</Center>
					<Col>
						<Card className="form-card">
							<Form form={form} name="basic" layout="vertical" onFinish={onFinish}>
								<Radio.Group
									onChange={async (e) => {
										setModelType(e.target.value);
										if (e.target.value === sparepart?.userModelType) {
											form.setFieldsValue({
												models: sparepart?.models[0]?.id.toString(),
											});
											setModelId(sparepart?.models[0]?.id.toString());
											handleModelValueChange(sparepart?.models[0]?.id);
											if (implementsId) {
												let data = {
													multipleimplements: implementsId,
												};
												dispatch(getAllImplementRelatedImplementsSubAssemblies(data));
											}
										} else {
											form.setFieldsValue({
												models: undefined,
											});
											setModelId(undefined);
											handleModelValueChange(undefined);
										}
									}}
									value={modelType}
									style={{ margin: "10px 0" }}
								>
									<Radio value="ModelName">Model Name</Radio>
									<Radio value="ModelNumber">Model Number</Radio>
									<Radio value="excelsheet">Excel Sheet</Radio>
								</Radio.Group>

								{modelType === "ModelName" ? (
									<Form.Item
										name="models"
										label="Select Select Model Name"
										rules={[
											{
												required: true,
												message: "Model is Required",
											},
										]}
									>
										<Select
											mode="multiple"
											allowClear
											style={{ width: "100%" }}
											placeholder="Please select model name"
											filterOption={(input, option) => {
												return (
													option.key.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
													option.title.toLowerCase().indexOf(input.toLowerCase()) >= 0
												);
											}}
											onChange={(e) => handleModelValueChange(e)}
										>
											{modelChildren}
										</Select>
									</Form.Item>
								) : (
									<Form.Item
										name="models"
										label="Select Model Number"
										rules={[
											{
												required: true,
												message: "Model is Required",
											},
										]}
									>
										<Select
											mode="multiple"
											allowClear
											style={{ width: "100%" }}
											placeholder="Please select model number"
											filterOption={(input, option) => {
												return (
													option.key.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
													option.title.toLowerCase().indexOf(input.toLowerCase()) >= 0
												);
											}}
											onChange={(e) => handleModelValueChange(e)}
										>
											{modelNumberChildren}
										</Select>
									</Form.Item>
								)}

								<Form.Item
									name="multipleSubAssemblies"
									label="Select Sub Assembly"
									rules={[{ required: false }]}
								>
									<Select
										mode="multiple"
										allowClear
										style={{ width: "100%" }}
										placeholder="Please select sub assemblies"
										filterOption={(input, option) => {
											return (
												option.key.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
												option.title.toLowerCase().indexOf(input.toLowerCase()) >= 0
											);
										}}
									>
										{SubAssemblyChildren}
									</Select>
								</Form.Item>
								<Form.Item name="multipleImplements" label="Select Implements">
									<Select
										mode="multiple"
										allowClear
										style={{ width: "100%" }}
										placeholder="Please select implements"
										filterOption={(input, option) => {
											return (
												option.key.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
												option.title.toLowerCase().indexOf(input.toLowerCase()) >= 0
											);
										}}
										onChange={(e) => {
											setImplementsId(e);
											form.setFieldsValue({
												subAssemblyImplements: undefined,
											});
										}}
									>
										{children}
									</Select>
								</Form.Item>
								<Form.Item name="subAssemblyImplements" label="Select Implements Sub Assemblies">
									<Select
										mode="multiple"
										allowClear
										style={{ width: "100%" }}
										placeholder="Please select implements sub assemblies"
										filterOption={(input, option) => {
											return (
												option.key.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
												option.title.toLowerCase().indexOf(input.toLowerCase()) >= 0
											);
										}}
									>
										{ImplementsSubAssembliesChildren}
									</Select>
								</Form.Item>
								<div
									style={{
										marginTop: "-10px",
										marginBottom: "10px",
									}}
								>
									<Text type="secondary">
										Please select {modelType} or Sub Assembly or Implements
									</Text>
								</div>
								<CommonSelect
									rules={[{ required: true, message: "HSN/SAC Code is required" }]}
									label="Select HSN/SAC Code"
									name="hsnCodeId"
									placeholder="please Select HSN/SAC Code"
									options={HSNCodeChildren}
								/>
								<InputField
									rules={[
										{
											required: true,
											message: "Spare part name is required",
										},
										{
											min: 1,
											message: "Spare part name be minimum 1 character.",
										},
										{
											max: 40,
											message: "Spare part name be maximum 40 character.",
										},
									]}
									label=" Spare Part Name"
									name="name"
									placeholder="Please enter spare part name"
									prefix={<UserOutlined />}
								/>
								<div className="modelFormTextGroup">
									<div className="modelFormTextGroupChild" style={{ marginRight: "10px" }}>
										<InputField
											rules={[
												{
													required: true,
													message: "Spare part number is required",
												},
												{
													min: 10,
													message: "Spare part number must be minimum 10 characters.",
												},
												{
													max: 10,
													message: "Spare part number must be maximum 10 characters.",
												},
											]}
											label="Spare part number"
											name="sparePartNumber"
											placeholder="Please enter Spare part number"
											prefix={<UserOutlined />}
										/>
									</div>
									<div className="modelFormTextGroupChild">
										<InputField
											rules={[
												{
													required: true,
													message: "Price is required",
												},
												{
													type: "number",
													min: 1,
													message: "Price minimum = 1",
												},
											]}
											label="Price"
											name="price"
											inputType="number"
											placeholder="Please enter Price."
											prefix={<UserOutlined />}
										/>
									</div>
									<div className="modelFormTextGroupChild">
										<InputField
											rules={[
												{
													required: true,
													message: "Stock is required",
												},
												{
													type: "number",
													max: 100000,
													message: "Stock maximum=100000",
												},
											]}
											label="Stock"
											name="stock"
											inputType="number"
											placeholder="Please enter Stock."
											prefix={<UserOutlined />}
										/>
									</div>
									<div className="modelFormTextGroupChild">
										<InputField
											rules={[
												{
													required: true,
													message: "Discount is required",
												},
												{
													type: "number",
													min: 0,
													max: 99,
													message: "Discount maximum 100% and minimum 0%",
												},
											]}
											label="Discount ( % )"
											name="discount"
											inputType="number"
											placeholder="Please enter discount."
											prefix={<UserOutlined />}
										/>
									</div>
								</div>
								<Title
									level={5}
									style={{
										marginTop: "15px",
										fontSize: "18px",
										fontWeight: "400",
									}}
								>
									<span
										style={{
											color: "#f08080",
											fontWeight: "thin",
											fontSize: "16px",
										}}
									>
										*{" "}
									</span>
									Upload Image
								</Title>
								<Upload
									getInputProps={getImageInputProps}
									getRootProps={getImageRootProps}
									type="image"
									isDragActive={isImageDragActive}
									file={imagesForSparePart}
									url={imageUrl}
									setUploadMedia={setCompressedImages}
								/>
								
								<Form.Item style={{ marginTop: "20px" }}>
									<Center height="0px">
										<Space>
											<NavLink to="/products/sparepart">
												<Button danger type="primary" onClick={handleCancel}>
													Cancel
												</Button>
											</NavLink>
											<Button type="primary" htmlType="submit">
												{sparePartId ? "Update" : "Add"}
											</Button>
										</Space>
									</Center>
								</Form.Item>
							</Form>
						</Card>
					</Col>
				</Row>
			</ErrorBoundary>
		</BasicLayout>
	);
};

export default SparePartForm;

import { applyMiddleware, combineReducers, createStore } from "redux";
import { composeWithDevTools } from "redux-devtools-extension/developmentOnly";
import thunk from "redux-thunk";
import { __prod__ } from "../utils/helper";
import AdminReducer from "./reducers/admin.reducer";
import BrandReducer from "./reducers/brand.reducer";
import CareersReducer from "./reducers/careers.reducer";
import CategoryReducer from "./reducers/category.reducer";
import CustomerReducer from "./reducers/customer.reducer";
import DealerReducer from "./reducers/dealer.reducer";
import DistributorReducer from "./reducers/distributor.reducer";
import GalleryReducer from "./reducers/gallery.reducer";
import ImplementsReducer from "./reducers/implements.reducer";
import ImplementsSubAssemblyReducer from "./reducers/implementsSubAssembly.reducer";
import OtherReducer from "./reducers/other.reducer";
import PlaceReducer from "./reducers/place.reducer";
import ProductSecondaryCategoryReducer from "./reducers/productSecondaryCategory.reducer";
import SalesPointReducer from "./reducers/salespoint.reducer";
import ServiceCenterReducer from "./reducers/serviceCenter.reducer";
import subAssemblyReducer from "./reducers/subAssembly.reducer";
import SubDealerReducer from "./reducers/subdealer.reducer";
import TechnicalSpecificationReducer from "./reducers/technicalspecification.reducer";
import AccessoryReducer from "./reducers/accessory.reducer";
import ModelReducer from "./reducers/model.reducer";
import SparePartReducer from "./reducers/sparepart.reducer";
import TemplateReducer from "./reducers/template.reducer";
import UserReducer from "./reducers/user.reducer";
import HSNCodeReducer from "./reducers/hsnCode.reducer";


const middleware = [thunk];

let middleWares;
if (__prod__) {
	middleWares = applyMiddleware(...middleware);
} else {
	middleWares = composeWithDevTools(applyMiddleware(...middleware));
}

const rootReducers = combineReducers({
	admin: AdminReducer,
	customer: CustomerReducer,
	dealer: DealerReducer,
	distributor: DistributorReducer,
	subDealer: SubDealerReducer,
	serviceCenter: ServiceCenterReducer,
	salesPoint: SalesPointReducer,
	other: OtherReducer,
	careers: CareersReducer,
	gallery: GalleryReducer,
	place: PlaceReducer,
	brand: BrandReducer,
	category: CategoryReducer,
	productSecondaryCategory: ProductSecondaryCategoryReducer,
	technicalspecification: TechnicalSpecificationReducer,
	implementsSubAssembly: ImplementsSubAssemblyReducer,
	implements: ImplementsReducer,
	subAssembly: subAssemblyReducer,
	accessory: AccessoryReducer,
	model: ModelReducer,
	sparepart: SparePartReducer,
	template: TemplateReducer,
	user: UserReducer,
	hsnCode: HSNCodeReducer,

});

const store = createStore(rootReducers, middleWares);

export default store;

import { UserOutlined } from "@ant-design/icons";
import { Button, Card, Form, Image, message, Row, Space, Typography } from "antd";
import React from "react";
import { ErrorBoundary } from "react-error-boundary";
import { useDispatch } from "react-redux";
import { NavLink, useHistory } from "react-router-dom";
import BasicLayout from "../../../components/BasicLayout";
import Center from "../../../components/common/Center";
import FallBack from "../../../components/common/FallBack/FallBack";
import Notification from "../../../components/common/Notification";
import InputField from "../../../components/Form/InputField";
import useQuery from "../../../components/Hooks/useQuery";
import { assignGallery } from "../../../store/actions/gallery/gallery.action";

const { Title } = Typography;

const Assign = ({ accessLevel, ...props }) => {
	const history = useHistory();
	const dispatch = useDispatch();
	const query = useQuery();

	const galleryId = +props.match.params.id;
	const url = query.get("url");
	const type = query.get("type");

	const Rules = [
		{
			required: true,
		},
	];

	const onFinish = async (values) => {
		await dispatch(
			assignGallery({ url, ...values, type, gallerySubmissionId: +galleryId }, history)
		);
	};

	const handleCancel = () => {
		if (type === "image") {
			Notification("error", "Assigning Image has been Cancelled!", "", "topRight");
		} else {
			Notification("error", "Assigning Video has been Cancelled!", "", "topRight");
		}
	};

	const handleError = (error) => {
		message.error(error.message);
	};
	return (
		<BasicLayout accessLevel={accessLevel}>
			<ErrorBoundary FallbackComponent={FallBack} onError={handleError}>
				<Row type="flex" justify="center" align="middle" id="form-container">
					<Center height="4rem">
						<Title level={2} style={{ margin: "2rem" }}>
							{type === "image" ? "Add Image" : "Update Image"}
						</Title>
					</Center>
					<Card className="form-card">
						<Form name="basic" layout="vertical" onFinish={onFinish}>
							<InputField
								rules={Rules}
								label="Name"
								name="name"
								placeholder="Please enter name"
								prefix={<UserOutlined />}
							/>
							<InputField
								rules={Rules}
								label="Category"
								name="category"
								placeholder="Please enter category"
								prefix={<UserOutlined />}
							/>
							<div
								style={{
									marginBottom: "20px",
									display: "flex",
									flexDirection: "column",
								}}
							>
								<label style={{ marginBottom: "10px" }}>
									<span style={{ color: "red" }}>*</span> {type === "image" ? "Image" : "Video"}
								</label>
								{type === "image" ? (
									<Image src={url} width={250} height={180} />
								) : (
									<video width="320" height="240" controls>
										<source src={url} type="video/mp4"></source>
									</video>
								)}
							</div>

							<Form.Item>
								<Center height="0px">
									<Space>
										<Button type="primary" htmlType="submit">
											Add
										</Button>
										<NavLink to={`/gallery/submissions/${galleryId}`}>
											<Button danger type="primary" onClick={handleCancel}>
												Cancel
											</Button>
										</NavLink>
									</Space>
								</Center>
							</Form.Item>
						</Form>
					</Card>
				</Row>
			</ErrorBoundary>
		</BasicLayout>
	);
};

export default Assign;

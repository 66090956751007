import {
    ADD_MODEL_SUCCESS,
    GET_MODELS_SUCCESS,
    GET_SINGLE_MODEL_SUCCESS,
    MAKE_SINGLE_MODEL_EMPTY,
    RESTORE_SINGLE_MODEL_SUCCESS,
    SOFT_DELETE_SINGLE_MODEL_SUCCESS,
    UPDATE_MODEL_SUCCESS,
} from "../actions/products/model/model.actionType";
import globalState from "../state";

const requestRegex = /_REQUEST/;
const errorRegex = /_FAILURE/;
const successRegex = /_SUCCESS/;

const initialState = {
    errorMessage: "",
    isLoading: false,
    models: [],
    model: {},
    ...globalState,
};

const ModelReducer = (state = initialState, action) => {
    const isRequest = requestRegex.test(action.type);
    const isError = errorRegex.test(action.type);
    const isSuccess = successRegex.test(action.type);

    if (isRequest) {
        return { ...state, isLoading: true, errorMessage: "" };
    }
    if (isError) {
        return { ...state, isLoading: false, errorMessage: action.message };
    }
    if (isSuccess) {
        state = { ...state, isLoading: false, errorMessage: "" };
    }

    switch (action.type) {
        case GET_MODELS_SUCCESS:
            return { ...state, models: action.data };


        case UPDATE_MODEL_SUCCESS:
            return { ...state };

        case GET_SINGLE_MODEL_SUCCESS:
            return { ...state, model: action.data };

        case ADD_MODEL_SUCCESS:
            return { ...state };

        case SOFT_DELETE_SINGLE_MODEL_SUCCESS:
            let softDeletedModelId = action.data;
            let afterSoftDeletedData = state.models.filter(
                (data) => data.id.toString() !== softDeletedModelId.toString()
            );
            return { ...state, models: afterSoftDeletedData };

        case MAKE_SINGLE_MODEL_EMPTY:
            return { ...state, model: action.data };

        case RESTORE_SINGLE_MODEL_SUCCESS:
            return { ...state };

        default:
            return state;
    }
};

export default ModelReducer;

export const GET_SALESPOINT_CONTACTS_REQUEST = "GET_SALESPOINT_CONTACTS_REQUEST",
	GET_SALESPOINT_CONTACTS_SUCCESS = "GET_SALESPOINT_CONTACTS_SUCCESS",
	GET_SALESPOINT_CONTACTS_FAILURE = "GET_SALESPOINT_CONTACTS_FAILURE";

export const GET_SALESPOINT_SINGLE_CONTACT_REQUEST = "GET_SALESPOINT_SINGLE_CONTACT_REQUEST",
	GET_SALESPOINT_SINGLE_CONTACT_SUCCESS = "GET_SALESPOINT_SINGLE_CONTACT_SUCCESS",
	GET_SALESPOINT_SINGLE_CONTACT_FAILURE = "GET_SALESPOINT_SINGLE_CONTACT_FAILURE";

export const SOFTDELETE_SALESPOINT_SINGLE_CONTACT_REQUEST =
		"SOFTDELETE_SALESPOINT_SINGLE_CONTACT_REQUEST",
	SOFTDELETE_SALESPOINT_SINGLE_CONTACT_SUCCESS = "SOFTDELETE_SALESPOINT_SINGLE_CONTACT_SUCCESS",
	SOFTDELETE_SALESPOINT_SINGLE_CONTACT_FAILURE = "SOFTDELETE_SALESPOINT_SINGLE_CONTACT_FAILURE";

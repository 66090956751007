import { Button, message } from "antd";
import React, { useEffect, useMemo, useState } from "react";
import { ErrorBoundary } from "react-error-boundary";
import { useDispatch, useSelector } from "react-redux";
import BasicLayout from "../../../components/BasicLayout";
import FallBack from "../../../components/common/FallBack/FallBack";
import Tables from "../../../components/common/Table/Table";
import { useDeleteAction } from "../../../components/Hooks/useDeleteAction";
import useExportCsv from "../../../components/Hooks/useExportCsv";
import useTableSearch from "../../../components/Hooks/useTableSearch";
import {
	getGallerySubmissions,
	softDeleteSingleGallerySubmission,
} from "../../../store/actions/gallery/gallery.action";
import { DeleteModel } from "../../../utils/DeleteModel";
import { formatFileName } from "../../../utils/helper";
import "./index.css";

const Index = (props) => {
	const [getColumnSearchProps] = useTableSearch();
	const dispatch = useDispatch();
	const [exportToCSV] = useExportCsv();
	const { deleteData } = useDeleteAction();
	const gallerySubmissions = useSelector((state) => state?.gallery?.gallerySubmissions || []);
	const [deleteId, setDeleteId] = useState(undefined);
	const [isModalVisible, setIsModalVisible] = useState(false);

	const admin = useSelector((state) => state.admin.admin || []);

	const permissionArr = useMemo(() => {
		if (admin && admin.template) {
			const { permissions } = admin.template;
			return permissions;
		} else if (admin && admin.accessLevel === "superAdmin") {
			return "all";
		}
		return [];
	}, [admin]);

	useEffect(() => {
		dispatch(getGallerySubmissions());
	}, [dispatch]);

	let dataSource = [];
	if (gallerySubmissions.length > 0) {
		dataSource = gallerySubmissions.sort(function (a, b) {
			return new Date(b.updatedAt) - new Date(a.updatedAt);
		});
		dataSource = dataSource.map((row, index) => ({
			...row,
			createdAt: new Date(row.createdAt).toLocaleString(),
			key: row.id,
		}));
	}

	async function confirm(id, reason) {
		await deleteData(id, reason, softDeleteSingleGallerySubmission, setIsModalVisible);
	}
	const columns = [
		{
			title: "Id",
			dataIndex: "id",
			key: "id",
		},
		{
			title: "Name",
			dataIndex: "name",
			key: "name",
			...getColumnSearchProps("name"),
		},
		{
			title: "Experience Title",
			dataIndex: "title",
			key: "title",
			...getColumnSearchProps("title"),
		},
		{
			title: "Received",
			dataIndex: "createdAt",
			key: "createdAt",
		},
		{
			title: "Action",
			key: "action",
			render: (_text, record) => (
				<DeleteModel
					record={record}
					confirm={confirm}
					update="false"
					setIsModalVisible={setIsModalVisible}
					isModalVisible={isModalVisible}
					cancelMessage="Gallery submission Removing has been cancelled"
					linkUrl={`/gallery/submissions`}
					placeholder="Please enter reason to delete gallery submission"
					deleteId={deleteId}
					setDeleteId={setDeleteId}
					isdelete={
						permissionArr === "all"
							? "true"
							: permissionArr?.gallery?.gallerySubmissions.filter((item) => item === "delete")
									?.length > 0
							? "true"
							: false
					}
					view={
						permissionArr === "all"
							? "true"
							: permissionArr?.gallery?.gallerySubmissions.filter((item) => item === "read")
									?.length > 0
							? "true"
							: false
					}
				/>
			),
		},
	];

	const fileName = formatFileName("submitted-galleries", "xlsx",admin);
	let downloadData = gallerySubmissions.map((gallerySubmission) => {
		if (gallerySubmission.imageUrls) {
			gallerySubmission.imageUrls.map((image, index) => {
				return Object.assign({}, (gallerySubmission[`imageUrls ${index}`] = image));
			});
			delete gallerySubmission.imageUrls;
		}
		if (gallerySubmission.videoUrls) {
			gallerySubmission.videoUrls.map((video, index) => {
				return Object.assign({}, (gallerySubmission[`videoUrls ${index}`] = video));
			});
			delete gallerySubmission.videoUrls;
		}
		return Object.assign({}, gallerySubmission, {
			hasAccepted: "true",
		});
	});
	const handleError = (error) => {
		message.error(error.message);
	};
	return (
		<BasicLayout accessLevel={props.accessLevel}>
			<ErrorBoundary FallbackComponent={FallBack} onError={handleError}>
				<div className="button-style-to-right">
					{permissionArr === "all" ? (
						<Button className="download-btn" onClick={(e) => exportToCSV(downloadData, fileName)}>
							Export
						</Button>
					) : permissionArr?.gallery?.gallerySubmissions?.filter((item) => item === "export")
							?.length > 0 && downloadData?.length !== 0 ? (
						<Button className="download-btn" onClick={(e) => exportToCSV(downloadData, fileName)}>
							Export
						</Button>
					) : null}
				</div>

				<Tables
					dataSource={dataSource}
					columns={columns}
					footerText={`Total Submission Received: ${gallerySubmissions.length}`}
				/>
			</ErrorBoundary>
		</BasicLayout>
	);
};

export default Index;

import { Card, message } from "antd";
import { useEffect } from "react";
import { ErrorBoundary } from "react-error-boundary";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import BasicLayout from "../../components/BasicLayout";
import FallBack from "../../components/common/FallBack/FallBack";
import Notification from "../../components/common/Notification";
import { CustomTab } from "../../components/common/Tab/Tab";
import { getServiceCenterSingleContact } from "../../store/actions/serviceCenter/serviceCenter.action";
import { checkIfObjectEmpty, isEqual } from "../../utils/helper";

const EnquiryDetail = (props) => {
	const enquiryId = +props.match.params.id;
	const dispatch = useDispatch();
	const enquiry = useSelector((state) => state.serviceCenter.enquiry || {});

	const location = useLocation();
	const softdelete = new URLSearchParams(location.search).get("soft-delete");
	const history = useHistory();

	const isEmpty = checkIfObjectEmpty(enquiry);
	useEffect(() => {
		if (isEmpty || !isEqual(enquiry?.id, enquiryId)) {
			dispatch(getServiceCenterSingleContact(enquiryId, softdelete)).catch((err) => {
				Notification("error", err, "", "topRight");
				history.push("/service-center/enquiry");
			});
		}
	}, [dispatch, isEmpty, softdelete, history, enquiryId, enquiry?.id]);

	let data = [];
	let isOrderEmpty = Object.keys(enquiry).length === 0;
	if (!isOrderEmpty) {
		data = [
			{ title: "Id", description: enquiry.id },
			{
				title: "Name",
				description: enquiry.name,
			},
			{
				title: "Contact Person",
				description: enquiry.serviceCenterContactPersonName,
			},
			{
				title: "Email",
				description: enquiry.email,
			},
			{
				title: "Phone Number",
				description: enquiry.phonenumber,
			},
			{
				title: "State",
				description: enquiry.state,
			},
			{
				title: "District",
				description: enquiry.district,
			},
			{
				title: "Taluka",
				description: enquiry.taluka,
			},
			{
				title: "Village",
				description: enquiry.village,
			},
			{
				title: "Description",
				description: enquiry.description,
			},
			{
				title: "Received",
				description: new Date(enquiry.createdAt).toLocaleString(),
			},
		];
	}
	// Error Boundary Error Handling
	const handleError = (error) => {
		message.error(error.message);
	};

	let operationData = {
		deletedBy: enquiry?.deletedBy,
		restoredBy: enquiry?.restoredBy,
	};

	return (
		<BasicLayout accessLevel={props.accessLevel}>
			<ErrorBoundary FallbackComponent={FallBack} onError={handleError}>
				<Card className="enqiury-detail-card" data-testid="enqiury-detail-card">
					<CustomTab data={data} operationData={operationData} redirectUrl="" update={false} />
				</Card>
			</ErrorBoundary>
		</BasicLayout>
	);
};

export default EnquiryDetail;

import { Button, Form, Image, message, Popconfirm, Select, Typography } from "antd";
import Modal from "antd/lib/modal/Modal";
import axios from "axios";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useDropzone } from "react-dropzone";
import { ErrorBoundary } from "react-error-boundary";
import { useDispatch, useSelector } from "react-redux";
import BasicLayout from "../../../components/BasicLayout/index";
import ButtonAsLink from "../../../components/common/ButtonAsLink";
import Center from "../../../components/common/Center";
import FallBack from "../../../components/common/FallBack/FallBack";
import Notification from "../../../components/common/Notification";
import { resizeImage } from "../../../components/common/ResizeFile";
import Spinner from "../../../components/common/Spinner";
import EditableTable from "../../../components/common/Table/EditableTable";
import { useDeleteAction } from "../../../components/Hooks/useDeleteAction";
import useExportCsv from "../../../components/Hooks/useExportCsv";
import useTableSearch from "../../../components/Hooks/useTableSearch";
import { useUpdateReason } from "../../../components/Hooks/useUpdateReason";
import {
	addBulkSparePartsApi,
	addExternalSparepartImage,
	updateBulkSparePartsApi,
} from "../../../services/sparepart";
import {
	getAllSpareParts,
	softDeleteSingleSparePart,
	updateSparePartFromTable,
} from "../../../store/actions/products/spareparts/spareparts.action";
import { DeleteModel } from "../../../utils/DeleteModel";
import { formatFileName } from "../../../utils/helper";
import { aspectRatioDependancy } from "../../../utils/imageAspectRatio";
import { imageSizeDependancy } from "../../../utils/imageSize";

const Sparepart = (props) => {
	const [getColumnSearchProps] = useTableSearch();
	const { deleteData } = useDeleteAction();
	const [exportToCSV] = useExportCsv();
	const [form] = Form.useForm();

	const { modelToggle, UpdateModel } = useUpdateReason();
	const [onSaveValues, setOnSaveValues] = useState();
	const [updateSparePartId, setUpdateSparePartId] = useState();

	const dispatch = useDispatch();
	const spareparts = useSelector((state) => state.sparepart.spareparts || []);
	const [data, setData] = useState([]);
	const [deleteId, setDeleteId] = useState(undefined);
	const [isModalVisible, setIsModalVisible] = useState(false);
	const [editingKey, setEditingKey] = useState("");
	const isEditing = (record) => record.key === editingKey;

	const admin = useSelector((state) => state.admin.admin || {});

	const permissionArr = useMemo(() => {
		if (admin && admin.template) {
			const { permissions } = admin.template;
			return permissions;
		} else if (admin && admin.accessLevel === "superAdmin") {
			return "all";
		}
		return [];
	}, [admin]);

	useEffect(() => {
		dispatch(getAllSpareParts());
	}, [dispatch]);

	function originDataUpdate(accepetedData) {
		let tempdata = [];
		for (let i = 0; i < accepetedData.length; i++) {
			tempdata.push({
				id: accepetedData[i].id,
				userModelType: accepetedData[i].userModelType,
				key: accepetedData[i].key,
				name: accepetedData[i].name,
				sparePartNumber: accepetedData[i].sparePartNumber,
				stock: accepetedData[i].stock,
				price: accepetedData[i].price,
				discount: accepetedData[i].discount,
				hsnCode: accepetedData[i]?.hsnCode,
			});
		}

		return tempdata;
	}

	function dataSourceUpdate(accepetedData) {
		let tempDataSource = [];
		if (accepetedData.length > 0) {
			tempDataSource = accepetedData.sort(function (a, b) {
				return new Date(b.updatedAt) - new Date(a.updatedAt);
			});
			tempDataSource = tempDataSource?.map((row) => ({
				...row,
				createdAt: new Date(row.createdAt).toLocaleString(),
				key: row.id,
				hsnCode: row?.hsnCode,
			}));
		}
		return tempDataSource;
	}

	const dataSource = useMemo(() => {
		return dataSourceUpdate(spareparts);
	}, [spareparts]);

	const originData = useMemo(() => {
		return originDataUpdate(dataSource);
	}, [dataSource]);

	async function confirm(id, reason) {
		await deleteData(id, reason, softDeleteSingleSparePart, setIsModalVisible);
	}

	useEffect(() => {
		setData(originData);
	}, [originData]);

	const save = async (key) => {
		try {
			setUpdateSparePartId(key);
			const row = await form.validateFields();
			const newData = [...data];
			const index = newData.findIndex((item) => key === item.key);
			if (index > -1) {
				const item = newData[index];
				newData.splice(index, 1, { ...item, ...row });
				let { discount, stock, price, userModelType } = newData[index];
				let data = {
					discount,
					stock,
					price,
					userModelType,
				};
				setOnSaveValues(data);
				modelToggle(true);
				setEditingKey("");
			} else {
				setEditingKey("");
			}
		} catch (errInfo) {
			return message.error(`Something went wrong`);
		}
	};
	const columns = [
		{
			title: "Id",
			dataIndex: "id",
			key: "id",
		},
		{
			title: "Name",
			dataIndex: "name",
			key: "name",
			...getColumnSearchProps("name"),
		},
		{
			title: "Spare Part Number",
			dataIndex: "sparePartNumber",
			key: "sparePartNumber",
			...getColumnSearchProps("sparePartNumber"),
		},
		{
			title: "Stock",
			dataIndex: "stock",
			key: "stock",
			editable: true,
			...getColumnSearchProps("stock"),
		},
		{
			title: "Price",
			dataIndex: "price",
			key: "price",
			editable: true,
			...getColumnSearchProps("price"),
		},
		{
			title: "Discount",
			dataIndex: "discount",
			key: "discount",
			min: 0,
			max: 100,
			editable: true,
			...getColumnSearchProps("discount"),
		},
		{
			title: "HSN/SAC Code",
			key: "hsnCode",
			...getColumnSearchProps("hsnCode"),
			render: (_text, record) => (
				<a href={`/products/hsncode/${record?.hsnCode?.id}`}> {record?.hsnCode?.code}</a>
			),
		},
		{
			title: "Action",
			key: "action",
			render: (_text, record) => (
				<DeleteModel
					record={record}
					confirm={confirm}
					setIsModalVisible={setIsModalVisible}
					isModalVisible={isModalVisible}
					cancelMessage="Sparepart Removing has been cancelled"
					linkUrl={`/products/sparepart`}
					placeholder="Please enter reason to delete sparepart"
					deleteId={deleteId}
					setDeleteId={setDeleteId}
					update={
						permissionArr === "all"
							? "true"
							: permissionArr?.products?.sparePart.filter((item) => item === "update")?.length > 0
							? "true"
							: false
					}
					isdelete={
						permissionArr === "all"
							? "true"
							: permissionArr?.products?.sparePart.filter((item) => item === "delete")?.length > 0
							? "true"
							: false
					}
					view={
						permissionArr === "all"
							? "true"
							: permissionArr?.products?.sparePart.filter((item) => item === "read")?.length > 0
							? "true"
							: false
					}
				/>
			),
		},
		{
			title: "operation",
			dataIndex: "operation",
			render: (_, record) => {
				const editable = isEditing(record);
				return editable ? (
					<div style={{ width: "100%", display: "flex" }}>
						<span
							onClick={() => save(record.key)}
							style={{
								display: "inline-block",
								marginRight: "10px",
								color: "#1890ff",
								cursor: "pointer",
							}}
						>
							Save
						</span>
						<Popconfirm title="Sure to cancel?" onConfirm={() => setEditingKey("")}>
							<span style={{ color: "#1890ff", cursor: "pointer" }}>Cancel</span>
						</Popconfirm>
					</div>
				) : (
					<Typography.Link disabled={editingKey !== ""} onClick={() => edit(record)}>
						Edit
					</Typography.Link>
				);
			},
		},
	];

	const edit = (record) => {
		form.setFieldsValue({
			...record,
		});
		setEditingKey(record.key);
	};

	const fileName = formatFileName("spareparts", "xlsx", admin);
	let downloadData = spareparts.map((data) => {
		const subAssemblies = data?.multipleSubAssemblies;
		const downloadSubAsssembliesCode = subAssemblies.map((subAssembliesCodedata) => {
			return subAssembliesCodedata.code;
		});

		const downloadSubAsssembliesIds = subAssemblies?.map((subAssembliesIdsdata) => {
			return subAssembliesIdsdata?.id;
		});

		const models = data?.models;
		const downloadModelCode = models?.map((modelsCodedata) => {
			return modelsCodedata?.productNumber;
		});

		const downloadModelIds = models?.map((modelsIdsdata) => {
			return modelsIdsdata?.id;
		});

		let newData = {
			"SPARE PART ID": data.id,
			"SPARE PART NAME": data.name,
			"SPARE PART CODE": data.sparePartNumber,
			"SUB ASSY CODE": downloadSubAsssembliesCode.toString(),
			"SUB ASSY ID'S": downloadSubAsssembliesIds.toString(),
			"PRODUCT NUMBER": downloadModelCode.toString(),
			"PRODUCT ID'S": downloadModelIds.toString(),
			"HSC CODE": data?.hsnCode?.code,
			"HSN CODE ID": data?.hsnCode?.id,
			STOCK: data.stock,
			PRICE: data.price,
			DISCOUNT: data.discount,
		};
		return newData;
	});
	const handleError = (error) => {
		message.error(error.message);
	};

	const { Option } = Select;
	const filterspareParts = spareparts.filter((item) => !item.imageUrl);
	const sparePartOptions = [];
	for (let i = 0; i < filterspareParts?.length; i++) {
		sparePartOptions?.push(
			<Option key={filterspareParts[i]?.id} title={filterspareParts[i]?.name}>
				{filterspareParts[i]?.name} ({filterspareParts[i]?.sparePartNumber})
			</Option>
		);
	}

	const [isLoading, setIsLoading] = useState(false);
	const [isErrorModalVisible, setIsErrorModalVisible] = useState(false);
	const [isImageAddModalVisible, setIsImageAddModalVisible] = useState(false);

	const [errorUrl, setErrorUrl] = useState();

	const showErrorModal = () => {
		setIsErrorModalVisible(true);
	};

	const handleCancel = () => {
		setIsErrorModalVisible(false);
		setIsImageAddModalVisible(false);
	};

	const showImageAddModal = () => {
		setIsImageAddModalVisible(true);
	};

	const onDrop = async (acceptedFiles) => {
		setIsLoading(true);
		const file = acceptedFiles[0];
		let data = new FormData();
		data.append("bulkupload", file);
		try {
			setIsLoading(true);
			await addBulkSparePartsApi(data);
			dispatch(getAllSpareParts());
			const message = "Spareparts added successfully ";
			Notification("success", message, "", "topRight");
			setIsLoading(false);
		} catch (error) {
			const url = error?.response?.data?.url;
			setErrorUrl(url);
			setIsLoading(false);
			showErrorModal();
		}
	};

	const onUpdateCSVDrop = async (acceptedFiles) => {
		setIsLoading(true);
		const file = acceptedFiles[0];
		let data = new FormData();
		data.append("bulkupdate", file);
		try {
			setIsLoading(true);
			await updateBulkSparePartsApi(data);
			dispatch(getAllSpareParts());
			const message = "Spareparts update successfully ";
			Notification("success", message, "", "topRight");
			setIsLoading(false);
		} catch (error) {
			const url = error?.response?.data?.url;
			setErrorUrl(url);
			setIsLoading(false);
			showErrorModal();
		}
	};

	const [imagesForSparePart, setImagesForSparePart] = useState([]);
	const [sparePartMedia, setSparePartMedia] = useState([]);

	const onImagesDrop = useCallback(async (acceptedFiles, fileRejections) => {
		try {
			setImagesForSparePart([]);
			setSparePartMedia([]);
			const file = acceptedFiles[0];
			imageSizeDependancy(fileRejections);

			await aspectRatioDependancy(acceptedFiles, setImagesForSparePart);

			resizeImage(file)
				.then((resp) => {
					setImagesForSparePart(file);
					setSparePartMedia(resp?.blobFile);
				})

				.catch((error) => {
					const errorMessage = error?.message || "Something went wrong";
					Notification("error", errorMessage, "", "topRight");
				});
		} catch (error) {
			const errorMessage = error?.message || "Something went wrong";
			Notification("error", errorMessage, "", "topRight");
		}
	}, []);

	const { getRootProps, getInputProps } = useDropzone({
		onDrop,
		accept: ".csv",
		multiple: false,
	});

	const { getRootProps: getUpdateCSVRootProps, getInputProps: getUpdateCSVInputProps } =
		useDropzone({
			onDrop: onUpdateCSVDrop,
			accept: ".csv",
			multiple: false,
		});

	const { getRootProps: getImageRootProps, getInputProps: getImageInputProps } = useDropzone({
		onDrop: onImagesDrop,
		accept: "image/png",
		multiple: false,
		maxSize: "1000000",
	});

	const onFinish = async (values) => {
		try {
			values = {
				...values,
				fileName: imagesForSparePart.name,
				filetype: imagesForSparePart.type,
			};

			const resp = await addExternalSparepartImage(values);
			setIsLoading(true);

			if (resp && resp.data?.data?.signedUrl) {
				let optionsForImageUrls = {
					headers: {
						"Content-Type": imagesForSparePart.type,
					},
				};
				await axios.put(resp.data?.data?.signedUrl, imagesForSparePart, optionsForImageUrls);
			}
			setIsImageAddModalVisible(false);
			Notification("success", "Media has been succesfully added.", "", "topRight");
			setIsLoading(false);
		} catch (error) {
			const errorMessage = error?.message || "Something went wrong";
			Notification("error", errorMessage, "", "top");
		} finally {
			setSparePartMedia([]);
			setImagesForSparePart([]);
		}
	};

	if (isLoading) {
		return (
			<Center>
				<Spinner />
				<h3>Don't Refresh while data is processing</h3>
			</Center>
		);
	}

	return (
		<BasicLayout accessLevel={props.accessLevel}>
			<ErrorBoundary FallbackComponent={FallBack} onError={handleError}>
				<div className="button-style-to-right">
					{permissionArr === "all" && spareparts.length !== 0 ? (
						<Button
							className="download-btn"
							onClick={() => {
								showImageAddModal();
							}}
						>
							Add Image
						</Button>
					) : permissionArr?.products?.sparePart?.filter((item) => item === "update")?.length > 0 &&
					  spareparts.length !== 0 ? (
						<Button
							className="download-btn"
							onClick={() => {
								showImageAddModal();
							}}
						>
							Add Image
						</Button>
					) : null}

					{permissionArr === "all" ? (
						<div>
							<Button className="download-btn" {...getUpdateCSVRootProps()}>
								Update Spare Parts
								<input {...getUpdateCSVInputProps()} />
							</Button>
						</div>
					) : permissionArr?.products?.sparePart?.filter((item) => item === "update")?.length >
					  0 ? (
						<div>
							<Button className="download-btn" {...getUpdateCSVRootProps()}>
								Update Spare Parts
								<input {...getUpdateCSVInputProps()} />
							</Button>
						</div>
					) : null}

					{isLoading ? null : (
						<div>
							{permissionArr === "all" ? (
								<Button className="download-btn" {...getRootProps()}>
									Add Spare Parts
									<input {...getInputProps()} />
								</Button>
							) : permissionArr?.products?.sparePart?.filter((item) => item === "write")?.length >
							  0 ? (
								<Button className="download-btn" {...getRootProps()}>
									Add Spare Parts
									<input {...getInputProps()} />
								</Button>
							) : null}
						</div>
					)}
					{permissionArr === "all" && spareparts.length !== 0 ? (
						<Button className="download-btn" onClick={(e) => exportToCSV(downloadData, fileName)}>
							Export
						</Button>
					) : permissionArr?.products?.sparePart?.filter((item) => item === "export")?.length > 0 &&
					  downloadData?.length !== 0 ? (
						<Button className="download-btn" onClick={(e) => exportToCSV(downloadData, fileName)}>
							Export
						</Button>
					) : null}
					{permissionArr === "all" ? (
						<ButtonAsLink text="Add New Sparepart" link="/products/sparepart/add" />
					) : permissionArr?.products?.sparePart?.filter((item) => item === "write")?.length > 0 ? (
						<ButtonAsLink text="Add New Sparepart" link="/products/sparepart/add" />
					) : null}
				</div>
				<EditableTable
					form={form}
					columns={columns}
					data={data}
					isEditing={isEditing}
					footerText={`Total SpareParts: ${spareparts.length}`}
				/>
				<UpdateModel
					id={updateSparePartId}
					action={updateSparePartFromTable}
					valuesWithoutReason={onSaveValues}
					redirectUrl="/products/sparepart"
					placeholder="Enter reason for update sparepart"
				/>
				<Modal
					title="Download Error File "
					visible={isErrorModalVisible}
					onCancel={handleCancel}
					footer={null}
				>
					<Button
						className="download-btn"
						onClick={(e) => {
							window.open(errorUrl);
						}}
					>
						Download
					</Button>
				</Modal>
				{isLoading ? null : (
					<Modal
						title="Select SpareParts for Image Add"
						centered={true}
						visible={isImageAddModalVisible}
						onCancel={handleCancel}
						footer={null}
						width={900}
						style={{ height: "100%", marginTop: "20px" }}
					>
						<Form
							name="basic"
							labelCol={{ span: 8 }}
							wrapperCol={{ span: 16 }}
							initialValues={{ remember: true }}
							onFinish={onFinish}
							autoComplete="off"
						>
							<Form.Item name="sparePartIds" label="Select SpareParts" rules={[{ required: true }]}>
								{sparePartOptions && (
									<Select
										mode="multiple"
										allowClear
										style={{ width: "100%" }}
										placeholder="Please select  SpareParts"
										filterOption={(input, option) => {
											return (
												option?.key?.toLowerCase().indexOf(input?.toLowerCase()) >= 0 ||
												option?.title?.toLowerCase().indexOf(input?.toLowerCase()) >= 0
											);
										}}
									>
										{sparePartOptions}
									</Select>
								)}
							</Form.Item>
							<Button className="download-btn" {...getImageRootProps()}>
								Select image <input {...getImageInputProps()} />
							</Button>

							<div className="file-holder">
								<Image
									src={sparePartMedia}
									height="100px"
									width="150px"
									style={{ padding: "10px" }}
								/>
							</div>
							{sparePartMedia?.length !== 0 && (
								<Button className="download-btn" htmlType="submit">
									Add Image To Spareparts
								</Button>
							)}
						</Form>
					</Modal>
				)}
			</ErrorBoundary>
		</BasicLayout>
	);
};

export default Sparepart;

export const GET_SERVICECENTER_CONTACTS_REQUEST = "GET_SERVICECENTER_CONTACTS_REQUEST",
	GET_SERVICECENTER_CONTACTS_SUCCESS = "GET_SERVICECENTER_CONTACTS_SUCCESS",
	GET_SERVICECENTER_CONTACTS_FAILURE = "GET_SERVICECENTER_CONTACTS_FAILURE";

export const GET_SERVICECENTER_SINGLE_CONTACT_REQUEST = "GET_SERVICECENTER_SINGLE_CONTACT_REQUEST",
	GET_SERVICECENTER_SINGLE_CONTACT_SUCCESS = "GET_SERVICECENTER_SINGLE_CONTACT_SUCCESS",
	GET_SERVICECENTER_SINGLE_CONTACT_FAILURE = "GET_SERVICECENTER_SINGLE_CONTACT_FAILURE";

export const SOFTDELETE_SERVICECENTER_SINGLE_CONTACT_REQUEST =
		"SOFTDELETE_SERVICECENTER_SINGLE_CONTACT_REQUEST",
	SOFTDELETE_SERVICECENTER_SINGLE_CONTACT_SUCCESS =
		"SOFTDELETE_SERVICECENTER_SINGLE_CONTACT_SUCCESS",
	SOFTDELETE_SERVICECENTER_SINGLE_CONTACT_FAILURE =
		"SOFTDELETE_SERVICECENTER_SINGLE_CONTACT_FAILURE";

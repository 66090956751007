import { UserOutlined } from "@ant-design/icons";
import { Button, Card, Col, Form, Input, message, Row, Select, Space, Typography } from "antd";
import axios from "axios";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useDropzone } from "react-dropzone";
import { ErrorBoundary } from "react-error-boundary";
import { useDispatch, useSelector } from "react-redux";
import { NavLink, useHistory, useLocation } from "react-router-dom";
import BasicLayout from "../../../components/BasicLayout";
import Center from "../../../components/common/Center";
import FallBack from "../../../components/common/FallBack/FallBack";
import Notification from "../../../components/common/Notification";
import Spinner from "../../../components/common/Spinner";
import InputField from "../../../components/Form/InputField";
import CustomSelect from "../../../components/Form/Select";
import Upload from "../../../components/Form/Upload";
import UploadPDF from "../../../components/Form/UploadPDF";
import { getAllImplements } from "../../../store/actions/products/implements/implements.action";
import { getAllAccessories } from "../../../store/actions/products/Accessory/accessory.action";
import { getAllBrands } from "../../../store/actions/products/brands/brand.action";
import { getAllBrandCategories } from "../../../store/actions/products/category/categories.action";
import {
	addModel,
	getSingleModel,
	makeModelDataEmpty,
	ModelAddSuccess,
	updateModel,
	updateModelFailure,
} from "../../../store/actions/products/model/model.action";
import {
	getAllPrimaryRelatedSecondaryCategoriesSuccess,
	getAllProductSecondaryCategories,
	getPrimaryRelatedSecondaryCategories,
} from "../../../store/actions/products/secondaryCategory/categories.action";
import { getAllTechnicalSpecifications } from "../../../store/actions/products/technicalspecifications/technicalspecification.action";
import { getAllSubAssemblies } from "../../../store/actions/subAssembly/subAssembly.action";
import { formatFileName } from "../../../utils/helper";
import "./modelform.css";
import { wordCount } from "../../../utils/wordCount";
import { WordCountDisplayer } from "../../../components/common/WordCountDisplayer";
import { aspectRatioDependancy } from "../../../utils/imageAspectRatio";
import { imageSizeDependancy } from "../../../utils/imageSize";
import { getAllHSNCodes } from "../../../store/actions/products/HSNCode/hsnCode.action";

const ModelForm = (props) => {
	const history = useHistory();
	const dispatch = useDispatch();

	const location = useLocation();
	const softdelete = new URLSearchParams(location.search).get("soft-delete");
	const admin = useSelector((state) => state?.admin?.admin || {});

	const [productsPdf, setProductsPdf] = useState([]);
	const [sparePartsPdf, setSparePartsPdf] = useState([]);
	const [noOfTechnicalSpecsPair, setNoOfTechnicalSpecsPair] = useState([""]);
	const [videoForModel, setVideoForModel] = useState([]);
	const [files, setFile] = useState([]);
	const [compressedImages, setCompressedImages] = useState([]);
	const [selectedTechnicalNameKey, setSelectedTechnicalNameKey] = useState([]);
	const [selectedTechnicalNameValue, setSelectedTechnicalNameValue] = useState([]);
	const { Title } = Typography;
	const [form] = Form.useForm();
	const [isBrandsLoading, setIsBrandsLoading] = useState(false);
	const [isSecondaryCategoriesLoading, setIsSecondaryCategoriesLoading] = useState(false);

	const [primaryCategory, setPrimaryCategory] = useState(undefined);
	const [brandID, setBrandID] = useState(undefined);
	const [secondaryCategory, setSecondaryCategory] = useState(undefined);
	const [isImplementsLoading, setIsImplementsLoading] = useState(false);
	const [isAssembliesLoading, setIsAssembliesLoading] = useState(false);
	const [isModelLoading, setIsModelLoading] = useState(false);
	const [isAccessoriesLoading, setIsAccessoriesLoading] = useState(false);
	const [isBrandCategoriesLoading, setIsBrandCategoriesLoading] = useState(false);
	const [
		isPrimaryRelatedSecondaryCategoriesLoading,
		setIsPrimaryRelatedSecondaryCategoriesLoading,
	] = useState(false);
	const [isTechnicalSpecificationsLoading, setIsTechnicalSpecificationsLoading] = useState(false);
	const [isHSNCodeLoading, setIsHSNCodeLoading] = useState(false);

	const model = useSelector((state) => state.model.model);
	const brands = useSelector((state) => state.brand.brands || []);

	const categories = useSelector((state) => state.category.brandCategories || []);

	const secondaryCategories = useSelector(
		(state) => state.productSecondaryCategory.primaryRelatedSecondaryCategories || []
	);
	const hsnCodes = useSelector((state) => state.hsnCode.hsnCodes || []);

	const multipleImplements = useSelector((state) => state.implements.multipleImplements);

	const subAssemblies = useSelector((state) => state.subAssembly.subAssemblies || []);

	const accessories = useSelector((state) => state.accessory.accessories || []);

	const technicalspecifications = useSelector(
		(state) => state.technicalspecification.technicalspecifications || []
	);

	const modelId = +props.match.params.id;

	const currentLocation = props.location.pathname;

	const onDropProductsPdf = useCallback((acceptedFiles) => {
		setProductsPdf(acceptedFiles);
		setProductsPdfUrl(undefined);
	}, []);

	const {
		getRootProps: getProductPdfRootProps,
		getInputProps: getProductPdfInputProps,
		isDragActive: isProductPdfActive,
	} = useDropzone({
		onDrop: onDropProductsPdf,
		accept: ".pdf",
		multiple: false,
		maxSize: "5097152",
	});

	const onDropSparePartPdf = useCallback((acceptedFiles) => {
		setSparePartsPdf(acceptedFiles);
		setSparePartsPdfUrl(undefined);
	}, []);

	const {
		getRootProps: getSparePartPDFRootProps,
		getInputProps: getSparePartPDFInputProps,
		isDragActive: isSparePartPDFActive,
	} = useDropzone({
		onDrop: onDropSparePartPdf,
		accept: ".pdf",
		multiple: false,
		maxSize: "5097152",
	});

	const onVideoDrop = useCallback((acceptedFiles) => {
		setVideoForModel(acceptedFiles);
	}, []);

	const {
		getRootProps: getVideoRootProps,
		getInputProps: getVideoInputProps,
		isDragActive: isVideoDragActive,
	} = useDropzone({
		onDrop: onVideoDrop,
		accept: "video/*",
		multiple: false,
		maxSize: "22020096",
	});

	const onImagesDrop = useCallback(
		async (acceptedFiles, fileRejections) => {
			try {
				setFile([]);
				setCompressedImages([]);
				imageSizeDependancy(fileRejections);

				await aspectRatioDependancy(acceptedFiles, setFile, files);
			} catch (error) {
				const errorMessage = error?.message || "Something went wrong";
				Notification("error", errorMessage, "", "topRight");
			}
		},
		[files]
	);

	const {
		getRootProps: getImagesRootProps,
		getInputProps: getImagesInputProps,
		isDragActive: isImagesDragActive,
	} = useDropzone({
		onDrop: onImagesDrop,
		accept: " image/png",
		maxFiles: 8,
		maxSize: "1000000",
	});
	const handleError = (error) => {
		message.error(error.message);
	};

	useEffect(() => {
		async function fetchData() {
			setIsBrandsLoading(true);
			await dispatch(getAllBrands());
			setIsBrandsLoading(false);
		}
		fetchData();
	}, [dispatch]);

	useEffect(() => {
		async function fetchData() {
			setIsSecondaryCategoriesLoading(true);
			await dispatch(getAllProductSecondaryCategories());
			setIsSecondaryCategoriesLoading(false);
		}
		fetchData();
	}, [dispatch]);

	useEffect(() => {
		async function fetchData() {
			setIsImplementsLoading(true);
			await dispatch(getAllImplements());
			setIsImplementsLoading(false);
		}
		fetchData();
	}, [dispatch]);

	useEffect(() => {
		async function fetchData() {
			setIsAssembliesLoading(true);
			await dispatch(getAllSubAssemblies());
			setIsAssembliesLoading(false);
		}
		fetchData();
	}, [dispatch]);

	useEffect(() => {
		async function fetchData() {
			setIsAccessoriesLoading(true);
			await dispatch(getAllAccessories());
			setIsAccessoriesLoading(false);
		}
		fetchData();
	}, [dispatch]);

	useEffect(() => {
		async function fetchData() {
			setIsTechnicalSpecificationsLoading(true);
			await dispatch(getAllTechnicalSpecifications());
			setIsTechnicalSpecificationsLoading(false);
		}
		fetchData();
	}, [dispatch]);

	useEffect(() => {
		async function fetchData() {
			setIsHSNCodeLoading(true);
			await dispatch(getAllHSNCodes());
			setIsHSNCodeLoading(false);
		}
		fetchData();
	}, [dispatch]);

	useEffect(() => {
		if (model.brandId) {
			async function fetchBrandCategories() {
				try {
					setIsBrandCategoriesLoading(true);
					await dispatch(getAllBrandCategories(model.brandId));
					setIsBrandCategoriesLoading(false);
				} finally {
					setIsBrandCategoriesLoading(false);
				}
			}
			fetchBrandCategories();
		}
		if (model.primaryCategoryId) {
			async function fetchPrimaryCategories() {
				try {
					setIsPrimaryRelatedSecondaryCategoriesLoading(true);
					await dispatch(getPrimaryRelatedSecondaryCategories(model.primaryCategoryId));
					setIsPrimaryRelatedSecondaryCategoriesLoading(false);
				} finally {
					setIsPrimaryRelatedSecondaryCategoriesLoading(false);
				}
			}
			fetchPrimaryCategories();
		}
	}, [model, dispatch]);

	useEffect(() => {
		async function fetchData() {
			try {
				if (currentLocation !== "/products/model/add" && modelId) {
					setIsModelLoading(true);
					await dispatch(getSingleModel(modelId, softdelete));
					setIsModelLoading(false);
				} else {
					await dispatch(makeModelDataEmpty());
				}
			} catch (error) {
				setIsModelLoading(false);
				const errorMessage = error?.response?.data?.message || "Something went wrong";
				Notification("error", errorMessage, "", "topRight");
				history.push("/products/model");
			}
		}
		fetchData();
	}, [modelId, softdelete, dispatch, history, currentLocation]);

	useEffect(() => {
		if (modelId && model) {
			setPrimaryCategory(model?.primaryCategoryId);
			setSecondaryCategory(model?.secondaryCategoryId);
			setBrandID(model?.brandId);
		}
	}, [model, modelId]);

	useEffect(() => {
		if (modelId && model.technicalName !== undefined) {
			setNoOfTechnicalSpecsPair(model.technicalName);
			setSelectedTechnicalNameKey([]);
			setSelectedTechnicalNameValue([]);
			model.technicalName.map((object) => {
				let key = Object.keys(object);
				let value = Object.values(object);
				setSelectedTechnicalNameKey((oldkeys) => [...oldkeys, key[0]]);
				setSelectedTechnicalNameValue((oldkeys) => [...oldkeys, value[0]]);
				return object;
			});
		}
	}, [modelId, model]);
	let defaultMultipleImplementsIds = useMemo(
		() => model?.multipleImplements?.map((data) => data.id.toString()),
		[model]
	);

	let defaultMultipleSubAssembliesIds = useMemo(
		() => model?.multipleSubAssemblies?.map((data) => data.id.toString()),
		[model]
	);

	let defaultMultipleAccessoriesIds = useMemo(
		() => model?.multipleAccessories?.map((data) => data.id.toString()),
		[model]
	);

	const defaultValues = useMemo(
		() => ({
			brandId: brandID,
			name: modelId ? model.name : undefined,
			primaryCategoryId: primaryCategory,
			secondaryCategoryId: secondaryCategory,
			multipleImplements: modelId ? defaultMultipleImplementsIds : undefined,
			multipleSubAssemblies: modelId ? defaultMultipleSubAssembliesIds : undefined,
			multipleAccessories: modelId ? defaultMultipleAccessoriesIds : undefined,
			productNumber: modelId ? model.productNumber : undefined,
			price: modelId ? model.price : undefined,
			stock: modelId ? model.stock : undefined,
			discount: modelId ? model.discount : undefined,
			hsnCodeId: modelId ? model?.hsnCode?.id : undefined,
		}),
		[
			modelId,
			brandID,
			model.name,
			primaryCategory,
			secondaryCategory,
			defaultMultipleImplementsIds,
			defaultMultipleSubAssembliesIds,
			defaultMultipleAccessoriesIds,
			model.productNumber,
			model.price,
			model.stock,
			model.discount,
			model?.hsnCode?.id,
		]
	);
	useEffect(() => {
		form.setFieldsValue(defaultValues);
	}, [form, defaultValues]);

	const handleCancel = () => {
		Notification("info", "Model action has been cancelled", "", "topRight");
	};

	const brandOptions = useMemo(
		() =>
			brands.map((brand) => ({
				key: `${brand.name} (${brand.code})`,
				value: brand.id,
				title: brand.name,
			})),
		[brands]
	);

	const categoryOptions = useMemo(
		() =>
			categories.map((category) => ({
				key: `${category.name} (${category.code})`,
				value: category.id,
				title: category.name,
			})),
		[categories]
	);

	const secondaryCategoryOptions = useMemo(
		() =>
			secondaryCategories.map((category) => ({
				key: `${category.name} (${category.code})`,
				value: category.id,
				title: category.name,
			})),
		[secondaryCategories]
	);

	const HSNCodeChildren = useMemo(
		() =>
			hsnCodes?.map((hsnCode) => ({
				key: `${hsnCode.code} `,
				value: hsnCode.id,
				title: hsnCode.code,
			})),
		[hsnCodes]
	);

	const { Option } = Select;
	const children = [];

	for (let i = 0; i < multipleImplements?.length; i++) {
		children.push(
			<Option key={multipleImplements[i]?.id} title={multipleImplements[i]?.name}>
				{multipleImplements[i]?.name} ({multipleImplements[i]?.code})
			</Option>
		);
	}

	const SubAssemblyChildren = [];
	for (let i = 0; i < subAssemblies?.length; i++) {
		SubAssemblyChildren.push(
			<Option
				key={subAssemblies[i]?.id}
				title={subAssemblies[i]?.name}
				code={subAssemblies[i]?.code}
			>
				{subAssemblies[i]?.name} ({subAssemblies[i]?.code})
			</Option>
		);
	}
	const technicalSpecificationsChildren = [];
	for (let i = 0; i < technicalspecifications?.length; i++) {
		technicalSpecificationsChildren.push(
			<Option key={technicalspecifications[i]?.name} title={technicalspecifications[i]?.name}>
				{technicalspecifications[i]?.name}
			</Option>
		);
	}

	let filteredTechnicalSpecification = technicalSpecificationsChildren.filter(
		(item) => !selectedTechnicalNameKey?.includes(item.props.title)
	);

	const AccessoryChildren = [];
	for (let i = 0; i < accessories?.length; i++) {
		AccessoryChildren.push(
			<Option key={accessories[i]?.id} title={accessories[i]?.name}>
				{accessories[i]?.name} ({accessories[i]?.code})
			</Option>
		);
	}
	const imageUrls = modelId ? model?.imageUrls : undefined;
	const videoUrls = modelId ? model?.videoUrls : undefined;

	const [productsPdfUrl, setProductsPdfUrl] = useState(undefined);
	useEffect(() => {
		setProductsPdfUrl(model?.productsPdfUrl);
	}, [model]);

	const [sparePartsPdfUrl, setSparePartsPdfUrl] = useState(undefined);
	useEffect(() => {
		setSparePartsPdfUrl(model?.sparePartsPdfUrl);
	}, [model]);

	const handleBrandChange = async (e) => {
		await dispatch(getAllBrandCategories(e));
		await dispatch(getAllPrimaryRelatedSecondaryCategoriesSuccess([]));
		setBrandID(e);
		setPrimaryCategory(undefined);
		setSecondaryCategory(undefined);
	};
	const handlePrimaryCategoryChange = async (e) => {
		await dispatch(getPrimaryRelatedSecondaryCategories(e));
		setSecondaryCategory(undefined);
		setPrimaryCategory(e);
	};
	const onFinish = async (values) => {
		try {
			if (
				selectedTechnicalNameKey.length === 0 ||
				selectedTechnicalNameKey.length < noOfTechnicalSpecsPair.length ||
				selectedTechnicalNameKey.length !== selectedTechnicalNameValue.length
			) {
				return Notification("error", "Please input technical name", "", "topRight");
			}
			if (currentLocation === "/products/model/add") {
				// to Store Technical Name Key Value Pair
				if (selectedTechnicalNameValue.length === 0) {
					Notification("error", "Please input technical name", "", "topRight");
					return;
				}
				let technicalName = [];
				for (let i = 0; i < noOfTechnicalSpecsPair.length; i++) {
					let tempValue = selectedTechnicalNameValue[i];
					let tempKey = selectedTechnicalNameKey[i];
					let technicalNameObjects = { [tempKey]: tempValue };
					technicalName.push(technicalNameObjects);
				}

				values = {
					...values,
					technicalName,
					brandId: values.brandId.toString(),
					primaryCategoryId: values.primaryCategoryId.toString(),
					secondaryCategoryId: values.secondaryCategoryId.toString(),
				};
				if (productsPdf.length === 0) {
					Notification("error", "Please upload a valid products Pdf", "", "topRight");
					return;
				}
				if (sparePartsPdf.length === 0) {
					Notification("error", "Please upload a valid spare parts Pdf", "", "topRight");
					return;
				}
				if (videoForModel.length === 0) {
					Notification("error", "Please upload a valid Video File", "", "topRight");
					return;
				}
				if (compressedImages.length === 0) {
					Notification("error", "Please upload a valid images", "", "topRight");
					return;
				}
				const productsPdfFileName = formatFileName(
					productsPdf[0].name.split(".")[0],
					productsPdf[0].name.split(".").pop(),
					admin
				);
				const productsPdfFileType = productsPdf[0].type;
				const sparePartsPdfFileName = formatFileName(
					sparePartsPdf[0].name.split(".")[0],
					sparePartsPdf[0].name.split(".").pop(),
					admin
				);
				const sparePartsFileType = sparePartsPdf[0].type;
				// For Video
				const uploadVideoMediaFileName = formatFileName(
					videoForModel[0].name.split(".")[0],
					videoForModel[0].name.split(".").pop(),
					admin
				);
				const uploadVideoMediaFileType = videoForModel[0].type;
				// For Images
				let imagesData = [];
				for (let i = 0; i < compressedImages.length; i++) {
					let fileName = formatFileName(
						compressedImages[i].name.split(".")[0],
						compressedImages[i].name.split(".").pop(),
						admin
					);
					let filetype = compressedImages[i].type;
					let data = {
						fileName,
						filetype,
					};
					imagesData.push(data);
				}
				const data = {
					...values,
					brandId: values.brandId.toString(),
					primaryCategoryId: values.primaryCategoryId.toString(),
					secondaryCategoryId: values.secondaryCategoryId.toString(),
					productsPdf: {
						fileName: productsPdfFileName,
						filetype: productsPdfFileType,
					},
					sparePartsPdf: {
						fileName: sparePartsPdfFileName,
						filetype: sparePartsFileType,
					},
					images: imagesData,
					videos: [
						{
							fileName: uploadVideoMediaFileName,
							filetype: uploadVideoMediaFileType,
						},
					],
				};
				const resp = await dispatch(addModel(data));
				if (
					resp &&
					resp.productsPdfSignedUrl &&
					resp.sparePartsPdfSignedUrl &&
					resp.signedVideoUrls &&
					resp.signedImageUrls
				) {
					const optionsForProducts = {
						headers: {
							"Content-Type": productsPdf[0].type,
						},
					};
					const optionsForSparePartPdf = {
						headers: {
							"Content-Type": sparePartsPdf[0].type,
						},
					};
					const optionsForVideoUrls = {
						headers: {
							"Content-Type": videoForModel[0].type,
						},
					};
					try {
						for (let i = 0; i < compressedImages.length; i++) {
							let optionsForImageUrls = {
								headers: {
									"Content-Type": compressedImages[i].type,
								},
							};
							await axios.put(resp.signedImageUrls[i], compressedImages[i], optionsForImageUrls);
						}
						await axios.put(resp.productsPdfSignedUrl, productsPdf[0], optionsForProducts);
						await axios.put(resp.sparePartsPdfSignedUrl, sparePartsPdf[0], optionsForSparePartPdf);
						await axios.put(resp.signedVideoUrls[0], videoForModel[0], optionsForVideoUrls);
						Notification("success", "Model has been succesfully added.", "", "topRight");
						await dispatch(ModelAddSuccess());
						history.push("/products/model");
					} catch (error) {
						const errorMessage = error.message || "Something went wrong";
						Notification("error", errorMessage, "", "topRight");
					}
				}
			} else {
				await wordCount(values.updateReason, 10);
				// For Updateing model
				let technicalName = [];
				if (
					noOfTechnicalSpecsPair.length !== 0 &&
					selectedTechnicalNameValue.length !== 0 &&
					selectedTechnicalNameKey.length !== 0
				) {
					for (let i = 0; i < noOfTechnicalSpecsPair.length; i++) {
						let tempValue = selectedTechnicalNameValue[i];
						let tempKey = selectedTechnicalNameKey[i];
						let technicalNameObjects = { [tempKey]: tempValue };
						technicalName.push(technicalNameObjects);
					}
				}
				// For Only Text Information update
				if (
					productsPdf.length === 0 &&
					sparePartsPdf.length === 0 &&
					compressedImages.length === 0 &&
					videoForModel.length === 0
				) {
					try {
						let data = {
							...values,
							technicalName,
							brandId: values.brandId.toString(),
							primaryCategoryId: values.primaryCategoryId.toString(),
							secondaryCategoryId: values.secondaryCategoryId.toString(),
						};
						if (technicalName.length === 0) {
							delete data.technicalName;
						}
						const resp = await dispatch(updateModel(modelId, data));
						const message = resp.message || "Updated successfully";
						history.push("/products/model");
						Notification("success", message, "", "topRight");
					} catch (err) {
						const errorMessage =
							(err?.response && err?.response?.data?.message) || "Some thing went wrong";
						dispatch(updateModelFailure(errorMessage));
						Notification("error", "Error Occurred", errorMessage, "topRight");
					}
				} else {
					let fileObject = {};
					if (productsPdf !== null && productsPdf !== undefined && productsPdf.length !== 0) {
						fileObject["productsPdf"] = productsPdf;
					}
					if (sparePartsPdf !== null && sparePartsPdf !== undefined && sparePartsPdf.length !== 0) {
						fileObject["sparePartsPdf"] = sparePartsPdf;
					}

					if (videoForModel !== null && videoForModel !== undefined && videoForModel.length !== 0) {
						fileObject["videos"] = [...videoForModel];
					}

					if (
						compressedImages !== null &&
						compressedImages !== undefined &&
						compressedImages.length !== 0
					) {
						fileObject["images"] = [...compressedImages];
					}
					let data = {};
					if (Object.keys(fileObject).length !== 0) {
						let pdfUploadText = {};
						for (const [key, value] of Object.entries(fileObject)) {
							if (key === "images") {
								let fileDataArr = [];
								value.map((val) => {
									const fileName = formatFileName(
										val.name.split(".")[0],
										val.name.split(".").pop(),
										admin
									);
									const fileType = val.type;
									fileDataArr.push({
										fileName,
										filetype: fileType,
									});
									return val;
								});
								pdfUploadText[key] = fileDataArr;
							} else {
								const fileName = formatFileName(
									value[0].name.split(".")[0],
									value[0].name.split(".").pop(),
									admin
								);
								const fileType = value[0].type;
								let fileData = {
									fileName,
									filetype: fileType,
								};
								pdfUploadText[key] = fileData;
							}
						}
						data = {
							...values,
							technicalName,
							brandId: values.brandId.toString(),
							primaryCategoryId: values.primaryCategoryId.toString(),
							secondaryCategoryId: values.secondaryCategoryId.toString(),
							...pdfUploadText,
						};
					} else {
						data = {
							...values,
							technicalName,
							brandId: values.brandId.toString(),
							primaryCategoryId: values.primaryCategoryId.toString(),
							secondaryCategoryId: values.secondaryCategoryId.toString(),
						};
					}
					if (videoForModel !== null && videoForModel !== undefined && videoForModel.length !== 0) {
						data.videos = [data.videos];
					}
					const resp = await dispatch(updateModel(modelId, data));
					if (resp && resp.data) {
						try {
							for (const [key, value] of Object.entries(resp.data)) {
								if (key === "signedImageUrls") {
									for (let i = 0; i < value.length; i++) {
										const options = {
											headers: {
												"Content-Type": value[i].type,
											},
										};

										let fileToUpload = compressedImages[i];
										await axios.put(value[i], fileToUpload, options);
									}
								} else {
									const options = {
										headers: {
											"Content-Type":
												key === "productsPdfSignedUrl"
													? productsPdf[0].type
													: key === "sparePartsSignedUrl"
													? sparePartsPdf[0].type
													: key === "signedVideoUrls"
													? videoForModel[0].type
													: "",
										},
									};
									let fileToUpload =
										key === "productsPdfSignedUrl"
											? productsPdf[0]
											: key === "sparePartsSignedUrl"
											? sparePartsPdf[0]
											: key === "signedVideoUrls"
											? videoForModel[0]
											: "";
									await axios.put(value, fileToUpload, options);
								}
							}
							history.push("/products/model");
							Notification("success", "Model has been succesfully updated.", "", "topRight");
							return;
						} catch (error) {
							setFile([]);
							setVideoForModel([]);
							setCompressedImages([]);
							setProductsPdf([]);
							setSparePartsPdf([]);
							const errorMessage = error?.response?.data?.message || "Something went wrong";
							Notification("error", "", errorMessage, "topRight");
						}
					}
				}
			}
		} catch (err) {
			if (err.message) {
				return Notification("error", "Error Occurred", err.message, "topRight");
			}
			const errorMessage =
				(err?.response && err?.response?.data?.message) || "Something went wrong";
			return Notification("error", "Error Occurred", errorMessage, "topRight");
		}
	};
	if (
		isBrandsLoading ||
		isSecondaryCategoriesLoading ||
		isImplementsLoading ||
		isAssembliesLoading ||
		isAccessoriesLoading ||
		isTechnicalSpecificationsLoading ||
		isBrandCategoriesLoading ||
		isPrimaryRelatedSecondaryCategoriesLoading ||
		isHSNCodeLoading ||
		isModelLoading
	) {
		return (
			<Center>
				<Spinner />
			</Center>
		);
	}
	return (
		<BasicLayout accessLevel={props.accessLevel}>
			<ErrorBoundary FallbackComponent={FallBack} onError={handleError}>
				<Row type="flex" justify="center" align="middle" id="form-container">
					<Center height="4rem">
						{modelId ? (
							<Title level={2} className="update-or-add-title">
								Update Model
							</Title>
						) : (
							<Title level={2} className="update-or-add-title">
								Add New Model
							</Title>
						)}
					</Center>
					<Col>
						<Card className="form-card">
							<Form
								form={form}
								name="basic"
								layout="vertical"
								onFinish={onFinish}
								initialValues={defaultValues}
							>
								<CustomSelect
									rules={[
										{
											required: true,
											message: "Brand is required",
										},
									]}
									label="Brand "
									name="brandId"
									placeholder="Please enter brand name "
									options={brandOptions}
									onChange={handleBrandChange}
								/>
								<CustomSelect
									rules={[
										{
											required: true,
											message: "Primary Category is required",
										},
									]}
									label="Select Primary Category"
									name="primaryCategoryId"
									placeholder="Please select primary category"
									options={categoryOptions}
									onChange={handlePrimaryCategoryChange}
								/>
								<CustomSelect
									rules={[
										{
											required: true,
											message: "Secondary category is required",
										},
									]}
									label="Select Secondary Category"
									name="secondaryCategoryId"
									placeholder="Please select secondary category"
									options={secondaryCategoryOptions}
								/>
								<CustomSelect
									rules={[{ required: true, message: "HSN/SAC Code is required" }]}
									label="Select HSN/SAC Code"
									name="hsnCodeId"
									placeholder="please Select HSN/SAC Code"
									options={HSNCodeChildren}
								/>
								<Form.Item name="multipleImplements" label="Select Implements">
									<Select
										mode="multiple"
										allowClear
										style={{ width: "100%" }}
										placeholder="Please select implements"
										filterOption={(input, option) => {
											return (
												option.key.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
												option.title.toLowerCase().indexOf(input.toLowerCase()) >= 0
											);
										}}
									>
										{children}
									</Select>
								</Form.Item>
								<Form.Item
									name="multipleSubAssemblies"
									label="Sub Assembly"
									rules={[
										{
											required: true,
											message: "Sub Assemblies is required",
										},
									]}
								>
									<Select
										mode="multiple"
										allowClear
										style={{ width: "100%" }}
										placeholder="Please select  "
										filterOption={(input, option) => {
											return (
												option.key.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
												option.title.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
												option.code.toLowerCase().indexOf(input.toLowerCase()) >= 0
											);
										}}
									>
										{SubAssemblyChildren}
									</Select>
								</Form.Item>
								<Form.Item name="multipleAccessories" label="Select Accessories">
									<Select
										mode="multiple"
										allowClear
										style={{ width: "100%" }}
										placeholder="Please select accessories"
										filterOption={(input, option) => {
											return (
												option.key.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
												option.title.toLowerCase().indexOf(input.toLowerCase()) >= 0
											);
										}}
									>
										{AccessoryChildren}
									</Select>
								</Form.Item>
								<div
									style={{
										display: "flex",
										flexDirection: "column",
									}}
								>
									<Title
										level={5}
										style={{
											fontWeight: "400",
											fontSize: "18px",
										}}
									>
										<span
											style={{
												color: "#f08080",
												fontWeight: "thin",
												fontSize: "18px",
											}}
										>
											*
										</span>
										Technical Specification
									</Title>
									{noOfTechnicalSpecsPair.map((data, index) => (
										<div key={index}>
											<div className="technicalSpecsParent">
												<div className="technicalSpecsChild">
													<Select
														value={selectedTechnicalNameKey[index]}
														onChange={(e) => {
															if (index > noOfTechnicalSpecsPair.length) {
																setSelectedTechnicalNameKey((old) => [...old, e]);
															} else {
																let newArr = [...selectedTechnicalNameKey];
																newArr[index] = e;
																setSelectedTechnicalNameKey(newArr);
															}
														}}
														allowClear
														style={{
															width: "100%",
														}}
														placeholder="Please select technical specs"
														filterOption={(input, option) => {
															return (
																option.key.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
																option.title.toLowerCase().indexOf(input.toLowerCase()) >= 0
															);
														}}
													>
														{filteredTechnicalSpecification}
													</Select>
												</div>
												<div className="technicalSpecsChild">
													<Input
														defaultValue={selectedTechnicalNameValue[index]}
														onChange={(e) => {
															if (index > noOfTechnicalSpecsPair.length) {
																setSelectedTechnicalNameValue((old) => [...old, e.target.value]);
															} else {
																let newArr = [...selectedTechnicalNameValue];
																newArr[index] = e.target.value;
																setSelectedTechnicalNameValue(newArr);
															}
														}}
														placeholder="Please enter technical value"
													></Input>
												</div>
											</div>
										</div>
									))}
								</div>
								<span className="addSubButtonForTechnicalSpec">
									<Button
										style={{ margin: "10px 5px" }}
										onClick={() => {
											if (technicalSpecificationsChildren.length <= noOfTechnicalSpecsPair.length) {
												return Notification("error", "limit exceeds", "", "topRight");
											}
											setNoOfTechnicalSpecsPair((oldElements) => [...oldElements, ""]);
										}}
									>
										+
									</Button>
									<Button
										style={{ margin: "0px 5px" }}
										onClick={() => {
											if (noOfTechnicalSpecsPair.length === 1) {
												return Notification("error", "Cannot be less than 1", "", "topRight");
											}

											setNoOfTechnicalSpecsPair((oldElements) => {
												let data = [...oldElements];
												data.pop();
												return data;
											});
										}}
									>
										-
									</Button>
								</span>
								<InputField
									rules={[
										{
											required: true,
											message: "Model Name is required",
										},
										{
											min: 1,
											message: "Model Name be minimum 1 character.",
										},
										{
											max: 40,
											message: "Model Name be minimum 40 character.",
										},
									]}
									label=" Model Name"
									name="name"
									placeholder="Please enter Model name"
									prefix={<UserOutlined />}
								/>
								<div className="modelFormTextGroup">
									<div className="modelFormTextGroupChild" style={{ marginRight: "10px" }}>
										<InputField
											rules={[
												{
													required: true,
													message: "Product Number is required",
												},
												{
													min: 10,
													message: "Product Number must be minimum 10 characters.",
												},
												{
													max: 10,
													message: "Product Number must be maximum 10 characters.",
												},
											]}
											label="Product Number"
											name="productNumber"
											placeholder="Please enter Product Number"
											prefix={<UserOutlined />}
										/>
									</div>
									<div className="modelFormTextGroupChild">
										<InputField
											rules={[
												{
													required: true,
													message: "Price is required",
												},
											]}
											label="Price"
											name="price"
											inputType="number"
											placeholder="Please enter Price."
											prefix={<UserOutlined />}
										/>
									</div>
									<div className="modelFormTextGroupChild">
										<InputField
											rules={[
												{
													required: true,
													message: "Stock is required",
												},
												{
													type: "number",
													max: 100000,
													message: "Stock maximum=100000",
												},
											]}
											label="Stock"
											name="stock"
											inputType="number"
											placeholder="Please enter Stock."
											prefix={<UserOutlined />}
										/>
									</div>
									<div className="modelFormTextGroupChild">
										<InputField
											rules={[
												{
													required: true,
													message: "Discount is required",
												},
												{
													type: "number",
													min: 0,
													max: 99,
													message: "Discount maximum 100% and minimum 0%",
												},
											]}
											label="Discount ( % )"
											name="discount"
											inputType="number"
											placeholder="Please enter discount."
											prefix={<UserOutlined />}
										/>
									</div>
								</div>
								<Title
									level={5}
									style={{
										fontWeight: "400",
										fontSize: "18px",
									}}
								>
									<span
										style={{
											color: "#f08080",
											fontWeight: "thin",
											fontSize: "16px",
										}}
									>
										*
									</span>{" "}
									Upload Products PDF
								</Title>
								<UploadPDF
									url={productsPdfUrl}
									getInputProps={getProductPdfInputProps}
									getRootProps={getProductPdfRootProps}
									isDragActive={isProductPdfActive}
									pdf={productsPdf}
								/>
								<Title
									level={5}
									style={{
										marginTop: "15px",
										fontWeight: "400",
										fontSize: "18px",
									}}
								>
									<span
										style={{
											color: "#f08080",
											fontWeight: "thin",
											fontSize: "16px",
										}}
									>
										*
									</span>{" "}
									Upload Spare Part PDF
								</Title>
								<UploadPDF
									url={sparePartsPdfUrl}
									getInputProps={getSparePartPDFInputProps}
									getRootProps={getSparePartPDFRootProps}
									isDragActive={isSparePartPDFActive}
									pdf={sparePartsPdf}
								/>
								<Title
									level={5}
									style={{
										marginTop: "15px",
										fontWeight: "400",
										fontSize: "18px",
									}}
								>
									<span
										style={{
											color: "#f08080",
											fontWeight: "thin",
											fontSize: "16px",
										}}
									>
										*
									</span>{" "}
									Upload Video
								</Title>
								<Upload
									getInputProps={getVideoInputProps}
									getRootProps={getVideoRootProps}
									type="video"
									isDragActive={isVideoDragActive}
									file={videoForModel}
									url={videoUrls}
									setUploadMedia={setVideoForModel}
								/>
								<Title
									level={5}
									style={{
										marginTop: "15px",
										fontWeight: "400",
										fontSize: "18px",
									}}
								>
									<span
										style={{
											color: "#f08080",
											fontWeight: "thin",
											fontSize: "16px",
										}}
									>
										*{" "}
									</span>
									Upload Images
								</Title>
								<Upload
									getInputProps={getImagesInputProps}
									getRootProps={getImagesRootProps}
									type="image"
									isDragActive={isImagesDragActive}
									file={files}
									url={imageUrls}
									setUploadMedia={setCompressedImages}
								/>
								{modelId ? (
									<WordCountDisplayer
										label="Update Reason"
										name="updateReason"
										placeholder="Please enter reason"
										rules={[
											{
												required: true,
											},
											{
												message: "update reason must be minimum 10 words.",
											},
										]}
									/>
								) : null}
								<Form.Item style={{ marginTop: "20px" }}>
									<Center height="0px">
										<Space>
											<NavLink to="/products/model">
												<Button danger type="primary" onClick={handleCancel}>
													Cancel
												</Button>
											</NavLink>
											<Button type="primary" htmlType="submit">
												{modelId ? "Update" : "Add"}
											</Button>
										</Space>
									</Center>
								</Form.Item>
							</Form>
						</Card>
					</Col>
				</Row>
			</ErrorBoundary>
		</BasicLayout>
	);
};

export default ModelForm;

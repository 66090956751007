import { UserOutlined } from "@ant-design/icons";
import { Button, Card, Col, Form, Image, message, Row, Space, Typography } from "antd";
import axios from "axios";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useDropzone } from "react-dropzone";
import { useDispatch, useSelector } from "react-redux";
import { NavLink, useHistory } from "react-router-dom";
import BasicLayout from "../../../components/BasicLayout";
import Center from "../../../components/common/Center";
import Notification from "../../../components/common/Notification";
import InputField from "../../../components/Form/InputField";
import Radiobutton from "../../../components/Form/Radiobutton";
import Upload from "../../../components/Form/Upload";
import {
	addGallery,
	galleryAssignFailure,
	galleryAssignSuccess,
	getSingleGallery,
	updateSingleGallery,
} from "../../../store/actions/gallery/gallery.action";
import { checkIfObjectEmpty, formatFileName } from "../../../utils/helper";
import { ErrorBoundary } from "react-error-boundary";
import FallBack from "../../../components/common/FallBack/FallBack";
import { wordCount } from "../../../utils/wordCount";
import { WordCountDisplayer } from "../../../components/common/WordCountDisplayer";
const { Title } = Typography;

const GalleryForm = ({ accessLevel, ...props }) => {
	const galleryId = +props.match.params.id;
	const currentLocation = props.location.pathname;
	const history = useHistory();
	const admin = useSelector((state) => state.admin.admin || {});

	const [uploadMedia, setUploadMedia] = useState("");
	const [type, setType] = useState("");
	const [file, setFile] = useState([]);

	const [form] = Form.useForm();

	const dispatch = useDispatch();
	const gallery = useSelector((state) => state.gallery.gallery || {});
	const isEmpty = checkIfObjectEmpty(gallery);

	const onImageDrop = useCallback((acceptedFiles) => {
		setFile(acceptedFiles);
	}, []);

	const onVideoDrop = useCallback((acceptedFiles) => {
		setFile(acceptedFiles);
	}, []);

	const {
		getRootProps: getImageRootProps,
		getInputProps: getImageInputProps,
		isDragActive: isImageDragActive,
	} = useDropzone({
		onDrop: onImageDrop,
		accept: "image/png",
		multiple: false,
		maxSize: "5097152",
	});
	const {
		getRootProps: getVideoRootProps,
		getInputProps: getVideoInputProps,
		isDragActive: isVideoDragActive,
	} = useDropzone({
		onDrop: onVideoDrop,
		accept: "video/mp4",
		multiple: false,
		maxSize: "20971520",
	});
	const defaultValues = useMemo(
		() => ({
			name: galleryId ? gallery.name : "",
			category: galleryId ? gallery.category : "",
		}),
		[galleryId, gallery.name, gallery.category]
	);
	useEffect(() => {
		if (currentLocation !== "/gallery/add") {
			dispatch(getSingleGallery(galleryId)).catch((err) => {
				Notification("error", err, "", "topRight");
				history.push("/gallery/add");
			});
		}
	}, [currentLocation, history, dispatch, galleryId]);
	useEffect(() => {
		form.setFieldsValue(defaultValues);
	}, [form, defaultValues]);
	const Rules = [
		{
			required: true,
		},
	];
	const onFinish = async (values) => {
		try {
			if (currentLocation === "/gallery/add") {
				if (file.length === 0) {
					Notification("error", "Please upload a valid media", "", "topRight");
					return;
				}
				const fileName = formatFileName(file[0].name.split(".")[0], file[0].name.split(".").pop(),admin);
				const fileType = file[0].type;
				const data = { ...values, fileName, fileType };
				const resp = await dispatch(addGallery(data));
				if (resp && resp.signedUrl) {
					const options = {
						headers: {
							"Content-Type": uploadMedia[0].type,
						},
					};
					try {
						if (type === "image") {
							await axios.put(resp.signedUrl, uploadMedia[0], options);
						} else {
							await axios.put(resp.signedUrl, file[0], options);
						}
						await dispatch(galleryAssignSuccess({ ...values, url: resp.url }));
						history.push("/gallery/added");
						Notification("success", "Media has been succesfully added.", "", "topRight");
					} catch (error) {
						const errorMessage = error.message || "Something went wrong";
						dispatch(galleryAssignFailure(errorMessage));
						Notification("error", errorMessage, "", "top");
					}
				}
			} else {
				await wordCount(values.updateReason, 10);
				const data = {
					...values,
					type: gallery.type,
					gallerySubmissionId: +galleryId,
					url: gallery.url,
				};
				await dispatch(updateSingleGallery(galleryId, data, history));
			}
		} catch (err) {
			if (err.message) {
				return Notification("error", "Error Occurred", err.message, "topRight");
			}
			const errorMessage =
				(err?.response && err?.response?.data?.message) || "Something went wrong";
			return Notification("error", "Error Occurred", errorMessage, "topRight");
		}
	};
	const handleCancel = () => {
		if (currentLocation === "/gallery/add") {
			Notification("error", "Media adding has been cancelled", "", "topRight");
		} else {
			Notification("error", "Media updation has been cancelled", "", "topRight");
		}
	};
	const options = [
		{
			key: "Image",
			value: "image",
		},
		{
			key: "Video",
			value: "video",
		},
	];

	// Error Boundary Error Handling
	const handleError = (error) => {
		message.error(error.message);
	};
	return (
		<BasicLayout accessLevel={accessLevel}>
			<ErrorBoundary FallbackComponent={FallBack} onError={handleError}>
				<Row type="flex" justify="center" align="middle" id="form-container">
					<Center height="4rem">
						{galleryId ? (
							<Title level={2} className="update-or-add-title">
								Update Media
							</Title>
						) : (
							<Title level={2} className="update-or-add-title">
								Add New Media
							</Title>
						)}
					</Center>
					<Col>
						<Card className="form-card">
							<Form
								form={form}
								name="basic"
								layout="vertical"
								onFinish={onFinish}
								initialValues={defaultValues}
							>
								<InputField
									rules={Rules}
									label="Name"
									name="name"
									placeholder="Please enter Name"
									prefix={<UserOutlined />}
								/>
								<InputField
									rules={Rules}
									label="Category"
									name="category"
									placeholder="Please enter Category"
									prefix={<UserOutlined />}
								/>
								<div className="gallery-images">
									{galleryId ? (
										!isEmpty && gallery?.type === "image" ? (
											<div className="image-holder-container">
												<Image width={250} height={180} src={gallery.url} />
											</div>
										) : (
											<video width="320" height="240" controls>
												<source src={gallery.url} type="video/mp4"></source>
											</video>
										)
									) : (
										<Col>
											<Radiobutton
												label="Select media type"
												name="type"
												rules={Rules}
												options={options}
												onChange={(event) => {
													setType(event.target.value);
												}}
											/>
											<div>
												{type === "image" ? (
													<Upload
														getInputProps={getImageInputProps}
														getRootProps={getImageRootProps}
														type="image"
														isDragActive={isImageDragActive}
														file={file}
														setUploadMedia={setUploadMedia}
													/>
												) : type === "video" ? (
													<Upload
														getInputProps={getVideoInputProps}
														getRootProps={getVideoRootProps}
														type="video"
														isDragActive={isVideoDragActive}
														file={file}
														setUploadMedia={setUploadMedia}
													/>
												) : (
													<></>
												)}
											</div>
										</Col>
									)}
								</div>
								{galleryId ? (
									<WordCountDisplayer
										label="Update Reason"
										name="updateReason"
										placeholder="Please enter reason"
										rules={[
											{
												required: true,
											},
											{
												message: "update reason must be minimum 10 words.",
											},
										]}
									/>
								) : null}
								<Form.Item className="gallery-update-add-button">
									<Center height="0px">
										<Space>
											<NavLink to="/gallery/added">
												<Button danger type="primary" onClick={handleCancel}>
													Cancel
												</Button>
											</NavLink>
											<Button type="primary" htmlType="submit">
												{galleryId ? "Update" : "Add"}
											</Button>
										</Space>
									</Center>
								</Form.Item>
							</Form>
						</Card>
					</Col>
				</Row>
			</ErrorBoundary>
		</BasicLayout>
	);
};
export default GalleryForm;

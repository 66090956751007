export const GET_TECHNICAL_SPECIFICATIONS_REQUEST = "GET_TECHNICAL_SPECIFICATIONS_REQUEST",
	GET_TECHNICAL_SPECIFICATIONS_SUCCESS = "GET_TECHNICAL_SPECIFICATIONS_SUCCESS",
	GET_TECHNICAL_SPECIFICATIONS_FAILURE = "GET_TECHNICAL_SPECIFICATIONS_FAILURE";

export const GET_SINGLE_TECHNICAL_SPECIFICATION_REQUEST =
		"GET_SINGLE_TECHNICAL_SPECIFICATION_REQUEST",
	GET_SINGLE_TECHNICAL_SPECIFICATION_SUCCESS = "GET_SINGLE_TECHNICAL_SPECIFICATION_SUCCESS",
	GET_SINGLE_TECHNICAL_SPECIFICATION_FAILURE = "GET_SINGLE_TECHNICAL_SPECIFICATION_FAILURE";

export const SOFT_DELETE_SINGLE_TECHNICAL_SPECIFICATION_REQUEST =
		"SOFT_DELETE_SINGLE_TECHNICAL_SPECIFICATION_REQUEST",
	SOFT_DELETE_SINGLE_TECHNICAL_SPECIFICATION_SUCCESS =
		"SOFT_DELETE_SINGLE_TECHNICAL_SPECIFICATION_SUCCESS",
	SOFT_DELETE_SINGLE_TECHNICAL_SPECIFICATION_FAILURE =
		"SOFT_DELETE_SINGLE_TECHNICAL_SPECIFICATION_FAILURE";

export const RESTORE_SINGLE_TECHNICAL_SPECIFICATION_REQUEST =
		"RESTORE_SINGLE_TECHNICAL_SPECIFICATION_REQUEST",
	RESTORE_SINGLE_TECHNICAL_SPECIFICATION_SUCCESS = "RESTORE_SINGLE_TECHNICAL_SPECIFICATION_SUCCESS",
	RESTORE_SINGLE_TECHNICAL_SPECIFICATION_FAILURE = "RESTORE_SINGLE_TECHNICAL_SPECIFICATION_FAILURE";

export const ADD_TECHNICAL_SPECIFICATION_REQUEST = "ADD_TECHNICAL_SPECIFICATION_REQUEST",
	ADD_TECHNICAL_SPECIFICATION_SUCCESS = "ADD_TECHNICAL_SPECIFICATION_SUCCESS",
	ADD_TECHNICAL_SPECIFICATION_FAILURE = "ADD_TECHNICAL_SPECIFICATION_FAILURE";

export const UPDATE_TECHNICAL_SPECIFICATION_REQUEST = "UPDATE_TECHNICAL_SPECIFICATION_REQUEST",
	UPDATE_TECHNICAL_SPECIFICATION_SUCCESS = "UPDATE_TECHNICAL_SPECIFICATION_SUCCESS",
	UPDATE_TECHNICAL_SPECIFICATION_FAILURE = "UPDATE_TECHNICAL_SPECIFICATION_FAILURE";

import { Button, Form, message, Popconfirm, Typography } from "antd";
import React, { useEffect, useMemo, useState } from "react";
import { ErrorBoundary } from "react-error-boundary";
import { useDispatch, useSelector } from "react-redux";
import BasicLayout from "../../../components/BasicLayout/index";
import ButtonAsLink from "../../../components/common/ButtonAsLink";
import FallBack from "../../../components/common/FallBack/FallBack";
import EditableTable from "../../../components/common/Table/EditableTable";
import { useDeleteAction } from "../../../components/Hooks/useDeleteAction";
import useExportCsv from "../../../components/Hooks/useExportCsv";
import useTableSearch from "../../../components/Hooks/useTableSearch";
import { useUpdateReason } from "../../../components/Hooks/useUpdateReason";
import {
	getAllModels,
	softDeleteSingleModel,
	updateModelFromTable,
} from "../../../store/actions/products/model/model.action";
import { DeleteModel } from "../../../utils/DeleteModel";
import { formatFileName } from "../../../utils/helper";

const Model = (props) => {
	const [getColumnSearchProps] = useTableSearch();
	const [exportToCSV] = useExportCsv();
	const { deleteData } = useDeleteAction();
	const [deleteId, setDeleteId] = useState(undefined);
	const [isModalVisible, setIsModalVisible] = useState(false);
	const [form] = Form.useForm();

	const { modelToggle, UpdateModel } = useUpdateReason();
	const [onSaveValues, setOnSaveValues] = useState();
	const [updateModelId, setUpdateModelId] = useState();
	const dispatch = useDispatch();
	const models = useSelector((state) => state.model.models || []);
	const [data, setData] = useState([]);
	const [editingKey, setEditingKey] = useState("");
	const isEditing = (record) => record.key === editingKey;
	const admin = useSelector((state) => state.admin.admin || []);

	const permissionArr = useMemo(() => {
		if (admin && admin.template) {
			const { permissions } = admin.template;
			return permissions;
		} else if (admin && admin.accessLevel === "superAdmin") {
			return "all";
		}
		return [];
	}, [admin]);

	useEffect(() => {
		dispatch(getAllModels());
	}, [dispatch]);

	function originDataUpdate(accepetedData) {
		let tempdata = [];
		for (let i = 0; i < accepetedData.length; i++) {
			tempdata.push({
				id: accepetedData[i].id,
				key: accepetedData[i].key,
				name: accepetedData[i].name,
				productNumber: accepetedData[i].productNumber,
				stock: accepetedData[i].stock,
				price: accepetedData[i].price,
				discount: accepetedData[i].discount,
				hsnCode: accepetedData[i]?.hsnCode,
			});
		}
		return tempdata;
	}

	function dataSourceUpdate(accepetedData) {
		let tempDataSource = [];
		if (accepetedData?.length > 0) {
			tempDataSource = accepetedData.sort(function (a, b) {
				return new Date(b.updatedAt) - new Date(a.updatedAt);
			});
			tempDataSource = tempDataSource?.map((row) => ({
				...row,
				createdAt: new Date(row.createdAt).toLocaleString(),
				key: row.id,
				hsnCode: row?.hsnCode,
			}));
		}
		return tempDataSource;
	}

	const dataSource = useMemo(() => {
		return dataSourceUpdate(models);
	}, [models]);

	const originData = useMemo(() => {
		return originDataUpdate(dataSource);
	}, [dataSource]);

	async function confirm(id, reason) {
		await deleteData(id, reason, softDeleteSingleModel, setIsModalVisible);
	}

	useEffect(() => {
		// if (data?.length !== originData?.length) {
		setData(originData);
		// }
	}, [originData]);

	const save = async (key) => {
		try {
			setUpdateModelId(key);
			const row = await form.validateFields();
			const newData = [...data];
			const index = newData.findIndex((item) => key === item.key);
			if (index > -1) {
				const item = newData[index];
				newData.splice(index, 1, { ...item, ...row });
				let { discount, stock, price } = newData[index];
				let data = {
					discount,
					stock,
					price,
				};
				setOnSaveValues(data);
				modelToggle(true);
				setEditingKey("");
			} else {
				setEditingKey("");
			}
		} catch (err) {
			const errorMessage = err?.response?.data?.message || "Something went wrong";
			return message.error(errorMessage);
		}
	};
	const columns = [
		{
			title: "Id",
			dataIndex: "id",
			key: "id",
		},
		{
			title: "Name",
			dataIndex: "name",
			key: "name",
			...getColumnSearchProps("name"),
		},
		{
			title: "Product Number",
			dataIndex: "productNumber",
			key: "productNumber",
			...getColumnSearchProps("productNumber"),
		},
		{
			title: "Stock",
			dataIndex: "stock",
			key: "stock",
			editable: true,
			...getColumnSearchProps("stock"),
		},
		{
			title: "Price",
			dataIndex: "price",
			key: "price",
			editable: true,
			...getColumnSearchProps("price"),
		},
		{
			title: "Discount",
			dataIndex: "discount",
			key: "discount",
			editable: true,
			...getColumnSearchProps("discount"),
		},
		{
			title: "HSN/SAC Code",
			key: "hsnCode",
			...getColumnSearchProps("hsnCode"),
			render: (_text, record) => (
				<a href={`/products/hsncode/${record?.hsnCode?.id}`}> {record?.hsnCode?.code}</a>
			),
		},
		{
			title: "Action",
			key: "action",
			render: (_text, record) => (
				<DeleteModel
					record={record}
					confirm={confirm}
					setIsModalVisible={setIsModalVisible}
					isModalVisible={isModalVisible}
					cancelMessage="Models Removing has been cancelled"
					linkUrl={`/products/model`}
					placeholder="Please enter reason to delete models"
					deleteId={deleteId}
					setDeleteId={setDeleteId}
					update={
						permissionArr === "all"
							? "true"
							: permissionArr?.products?.model.filter((item) => item === "update")?.length > 0
							? "true"
							: false
					}
					isdelete={
						permissionArr === "all"
							? "true"
							: permissionArr?.products?.model.filter((item) => item === "delete")?.length > 0
							? "true"
							: false
					}
					view={
						permissionArr === "all"
							? "true"
							: permissionArr?.products?.model.filter((item) => item === "read")?.length > 0
							? "true"
							: false
					}
				/>
			),
		},
		{
			title: "Operation",
			dataIndex: "operation",
			render: (_, record) => {
				const editable = isEditing(record);
				return editable ? (
					<div style={{ width: "100%", display: "flex" }}>
						<span
							onClick={() => save(record.key)}
							style={{
								display: "inline-block",
								marginRight: "10px",
								color: "#1890ff",
								cursor: "pointer",
							}}
						>
							Save
						</span>
						<Popconfirm title="Sure to cancel?" onConfirm={() => setEditingKey("")}>
							<span style={{ color: "#1890ff", cursor: "pointer" }}>Cancel</span>
						</Popconfirm>
					</div>
				) : (
					<Typography.Link disabled={editingKey !== ""} onClick={() => edit(record)}>
						Edit
					</Typography.Link>
				);
			},
		},
	];

	const edit = (record) => {
		form.setFieldsValue({
			...record,
		});
		setEditingKey(record.key);
	};

	const fileName = formatFileName("models", "xlsx", admin);
	let downloadData = models?.map((data) => {
		const subassemblyCode = data && data?.multipleSubAssemblies;

		const subassemblyDownloadCode = subassemblyCode?.map((subAss) => {
			return subAss.code;
		});

		let newData = {
			"PRODUCT ID": data?.id,
			"PRODUCT NAME": data?.name,
			"PRODUCT CODE": data?.productNumber,
			"SUB ASSY CODE": subassemblyDownloadCode.toString(),
			"HSC CODE": data?.hsnCode?.code,
			"HSN CODE ID": data?.hsnCode?.id,
			STOCK: data?.stock,
			PRICE: data?.price,
			DISCOUNT: data?.discount,
		};
		return newData;
	});

	const handleError = (error) => {
		message.error(error.message);
	};

	return (
		<BasicLayout accessLevel={props.accessLevel}>
			<ErrorBoundary FallbackComponent={FallBack} onError={handleError}>
				<div className="button-style-to-right">
					{permissionArr === "all" ? (
						<Button className="download-btn" onClick={(e) => exportToCSV(downloadData, fileName)}>
							Export
						</Button>
					) : permissionArr?.products?.model?.filter((item) => item === "export")?.length > 0 &&
					  downloadData?.length !== 0 ? (
						<Button className="download-btn" onClick={(e) => exportToCSV(downloadData, fileName)}>
							Export
						</Button>
					) : null}
					{permissionArr === "all" ? (
						<ButtonAsLink text="Add New Model" link="/products/model/add" />
					) : permissionArr?.products?.model?.filter((item) => item === "write")?.length > 0 ? (
						<ButtonAsLink text="Add New Model" link="/products/model/add" />
					) : null}
				</div>
				<EditableTable
					form={form}
					columns={columns}
					data={data}
					isEditing={isEditing}
					footerText={`Total Models: ${models.length}`}
				/>
				<UpdateModel
					id={updateModelId}
					action={updateModelFromTable}
					valuesWithoutReason={onSaveValues}
					redirectUrl="/products/model"
					placeholder="Enter reason for update model"
				/>
			</ErrorBoundary>
		</BasicLayout>
	);
};

export default Model;

import LoginPage from "../container/auth";
import ViewApplication from "../container/Careers/Applications/ViewApplication";
import CategoryFormPage from "../container/Careers/Categories/CategoryForm";
import ViewCategoryPage from "../container/Careers/Categories/ViewCategory";
import JobFormContainer from "../container/Careers/Jobs/JobForm";
import ViewJobs from "../container/Careers/Jobs/ViewJob";
import CustomerInfoDetailPage from "../container/Customer/customerDetail";
import CustomerUpdateForm from "../container/Customer/customerUpdateForm";
import CustomerEnquiryDetailPage from "../container/Customer/enquiryDetail";
import DealerEnquiryDetailPage from "../container/Dealer/enquiryDetail";
import DistributorEnquiryDetailPage from "../container/Distributor/enquiryDetail";
import GalleryFormPage from "../container/Gallery/Added/GalleryForm";
import ViewAddedGalleryPage from "../container/Gallery/Added/ViewAddedGallery";
import GalleryAssignPage from "../container/Gallery/Submissions/assign";
import ViewGallerySubmissionPage from "../container/Gallery/Submissions/ViewGallery";
import NotFound404Page from "../container/NotFound404/NotFound404";
import ViewInnovationPage from "../container/Other/Innovation/ViewInnovation";
import AccessoryForm from "../container/Products/Accessory/AccessoryForm";
import ViewAccessoryPage from "../container/Products/Accessory/ViewAccessory";
import BrandForm from "../container/Products/Brands/BrandForm";
import ViewBrand from "../container/Products/Brands/ViewBrand";
import CategoryForm from "../container/Products/Category/CategoryForm";
import ViewCategory from "../container/Products/Category/ViewCategory";
import ImplementForm from "../container/Products/Implements/ImplementsForm";
import ViewImplement from "../container/Products/Implements/ViewImplements";
import ImplementSubAssemblyForm from "../container/Products/ImplementsSubAssembly/ImplementSubAssemblyForm";
import ViewImplementSubAssembly from "../container/Products/ImplementsSubAssembly/ViewImplementSubAssembly";
import ModelForm from "../container/Products/Model/ModelForm";
import ViewModelPage from "../container/Products/Model/ViewModel";
import ProductSecondaryCategoryForm from "../container/Products/SecondaryCategory/CategoryForm";
import ViewProductSecondaryCategory from "../container/Products/SecondaryCategory/ViewCategory";
import SparePartForm from "../container/Products/SparePart/SparePartForm";
import SparePartUpdateForm from "../container/Products/SparePart/sparepartUpdateForm";
import ViewSparePartPage from "../container/Products/SparePart/ViewSparePart";
import SubAssemblyForm from "../container/Products/SubAssembly/SubAssemblyForm";
import ViewSubAssemblyPage from "../container/Products/SubAssembly/ViewSubAssembly";
import TechnicalSpecificationForm from "../container/Products/TechnicalSpecifications/TechnicalSpecificationForm";
import ViewTechnicalSpecification from "../container/Products/TechnicalSpecifications/ViewTechnicalSpecification";
import SalesPointEnquiryDetailPage from "../container/SalesPoint/enquiryDetail";
import ServiceCenterEnquiryDetailPage from "../container/ServiceCenter/enquiryDetail";
import SubDealerEnquiryDetailPage from "../container/SubDealer/enquiryDetail";
import UserTemplateForm from "../container/Templates/userTemplateForm";
import UserForm from "../container/User/userForm";
import ViewUser from "../container/User/ViewUser";

import ViewTemplate from "../container/Templates/ViewTemplate";
import CustomerEnquiryForm from "../container/Customer/EnquiryForm";

import datatable from "./datatable";
import UserUpdateForm from "../container/User/userUpdateForm";
import EnquiryUpdateForm from "../container/Customer/enquiryUpdateForm";
import HSNCodeForm from "../container/Products/HSNCode/HSNCodeForm";
import ViewHSNCode from "../container/Products/HSNCode/ViewHSNCode";

let routesData = [];
datatable.forEach((data) => data?.subMenu?.forEach((sub) => routesData?.push(sub)));

const routes = [
	...routesData,
	{ path: "/login", component: LoginPage, exact: true, value: "login" },
	{
		path: "/usermanagement/user/add",
		component: UserForm,
		exact: true,
		accessLevel: ["superAdmin"],
		value: "",
		menu: "",
	},
	{
		path: "/usermanagement/user/update/:id",
		component: UserUpdateForm,
		exact: true,
		accessLevel: ["superAdmin"],
	},
	{
		path: "/usermanagement/user/:id",
		component: ViewUser,
		exact: true,
		accessLevel: ["superAdmin"],
	},
	{
		path: "/usermanagement/template/add",
		component: UserTemplateForm,
		exact: true,
		accessLevel: ["superAdmin"],
	},
	{
		path: "/usermanagement/template/update/:id",
		component: UserTemplateForm,
		exact: true,
		accessLevel: ["superAdmin"],
	},
	{
		path: "/usermanagement/template/:id",
		component: ViewTemplate,
		exact: true,
		accessLevel: ["superAdmin"],
	},
	{
		path: "/customer/admin/enquiry/add",
		component: CustomerEnquiryForm,
		exact: true,
		accessLevel: ["superAdmin"],
		value: "customerEnquiry",
		menu: "customer",
		type: "write",
	},
	{
		path: "/customer/enquiry/update/:id",
		component: EnquiryUpdateForm,
		exact: true,
		accessLevel: ["superAdmin"],
		value: "customerEnquiry",
		menu: "customer",
		type: "update",
	},
	{
		path: "/customer/enquiry/:id",
		component: CustomerEnquiryDetailPage,
		exact: true,
		accessLevel: ["superAdmin"],
		value: "customerEnquiry",
		menu: "customer",
		type: "read",
	},
	{
		path: "/customer/info/:id",
		component: CustomerInfoDetailPage,
		exact: true,
		accessLevel: ["superAdmin"],
		value: "customerInfo",
		menu: "customer",
		type: "read",
	},
	{
		path: "/customer/info/update/:id",
		component: CustomerUpdateForm,
		exact: true,
		accessLevel: ["superAdmin"],
		value: "customerInfo",
		menu: "customer",
		type: "update",
	},
	{
		path: "/dealer/enquiry/:id",
		component: DealerEnquiryDetailPage,
		exact: true,
		accessLevel: ["superAdmin"],
		value: "dealerEnquiry",
		menu: "dealer",
		type: "read",
	},
	{
		path: "/distributor/enquiry/:id",
		component: DistributorEnquiryDetailPage,
		exact: true,
		accessLevel: ["superAdmin"],
		value: "distributorEnquiry",
		menu: "distributor",
		type: "read",
	},
	{
		path: "/sub-dealer/enquiry/:id",
		component: SubDealerEnquiryDetailPage,
		exact: true,
		accessLevel: ["superAdmin"],
		value: "subdealerEnquiry",
		menu: "subDealer",
		type: "read",
	},
	{
		path: "/service-center/enquiry/:id",
		component: ServiceCenterEnquiryDetailPage,
		exact: true,
		accessLevel: ["superAdmin"],
		value: "serviceCenterEnquiry",
		menu: "serviceCenter",
		type: "read",
	},
	{
		path: "/sales-point/enquiry/:id",
		component: SalesPointEnquiryDetailPage,
		exact: true,
		accessLevel: ["superAdmin"],
		value: "salespointEnquiry",
		menu: "salesPoint",
		type: "read",
	},
	{
		path: "/other/innovation/:id",
		component: ViewInnovationPage,
		exact: true,
		accessLevel: ["superAdmin"],
		value: "innovation",
		menu: "other",
		type: "read",
	},
	{
		path: "/gallery/submissions/:id",
		component: ViewGallerySubmissionPage,
		exact: true,
		accessLevel: ["superAdmin"],
		value: "gallerySubmissions",
		menu: "gallery",
		type: "read",
	},
	{
		path: "/gallery/submissions/:id/assign",
		component: GalleryAssignPage,
		exact: true,
		accessLevel: ["superAdmin"],
		value: "gallerySubmissions",
		menu: "gallery",
		type: "read",
	},
	{
		path: "/gallery/add",
		component: GalleryFormPage,
		exact: true,
		accessLevel: ["superAdmin"],
		value: "added",
		menu: "gallery",
		type: "write",
	},
	{
		path: "/gallery/added/:id",
		component: ViewAddedGalleryPage,
		exact: true,
		accessLevel: ["superAdmin"],
		value: "added",
		menu: "gallery",
		type: "read",
	},
	{
		path: "/gallery/added/update/:id",
		component: GalleryFormPage,
		exact: true,
		accessLevel: ["superAdmin"],
		value: "added",
		menu: "gallery",
		type: "update",
	},
	{
		path: "/careers/jobs/add",
		component: JobFormContainer,
		exact: true,
		accessLevel: ["superAdmin"],
		value: "jobs",
		menu: "career",
		type: "write",
	},
	{
		path: "/careers/jobs/update/:id",
		component: JobFormContainer,
		exact: true,
		accessLevel: ["superAdmin"],
		value: "jobs",
		menu: "career",
		type: "update",
	},
	{
		path: "/careers/jobs/:id",
		component: ViewJobs,
		exact: true,
		accessLevel: ["superAdmin"],
		value: "jobs",
		menu: "career",
		type: "read",
	},
	{
		path: "/careers/applications/:id",
		component: ViewApplication,
		exact: true,
		accessLevel: ["superAdmin"],
		value: "applications",
		menu: "career",
		type: "read",
	},
	{
		path: "/careers/categories/add",
		component: CategoryFormPage,
		exact: true,
		accessLevel: ["superAdmin"],
		value: "categories",
		menu: "career",
		type: "write",
	},
	{
		path: "/careers/categories/:id",
		component: ViewCategoryPage,
		exact: true,
		accessLevel: ["superAdmin"],
		value: "categories",
		menu: "career",
		type: "read",
	},
	{
		path: "/careers/categories/update/:id",
		component: CategoryFormPage,
		exact: true,
		accessLevel: ["superAdmin"],
		value: "categories",
		menu: "career",
		type: "update",
	},
	{
		path: "/products/brands/add",
		component: BrandForm,
		exact: true,
		accessLevel: ["superAdmin"],
		value: "brands",
		menu: "products",
		type: "write",
	},
	{
		path: "/products/brands/:id",
		component: ViewBrand,
		exact: true,
		accessLevel: ["superAdmin"],
		value: "brands",
		menu: "products",
		type: "read",
	},
	{
		path: "/products/brands/update/:id",
		component: BrandForm,
		exact: true,
		accessLevel: ["superAdmin"],
		value: "brands",
		menu: "products",
		type: "update",
	},
	{
		path: "/products/categories/add",
		component: CategoryForm,
		exact: true,
		accessLevel: ["superAdmin"],
		value: "productCategories",
		menu: "products",
		type: "write",
	},
	{
		path: "/products/categories/update/:id",
		component: CategoryForm,
		exact: true,
		accessLevel: ["superAdmin"],
		value: "productCategories",
		menu: "products",
		type: "update",
	},
	{
		path: "/products/categories/:id",
		component: ViewCategory,
		exact: true,
		accessLevel: ["superAdmin"],
		value: "productCategories",
		menu: "products",
		type: "read",
	},
	{
		path: "/products/secondary/categories/add",
		component: ProductSecondaryCategoryForm,
		exact: true,
		accessLevel: ["superAdmin"],
		value: "productSecondaryCategories",
		menu: "products",
		type: "write",
	},
	{
		path: "/products/secondary/categories/update/:id",
		component: ProductSecondaryCategoryForm,
		exact: true,
		accessLevel: ["superAdmin"],
		value: "productSecondaryCategories",
		menu: "products",
		type: "update",
	},
	{
		path: "/products/secondary/categories/:id",
		component: ViewProductSecondaryCategory,
		exact: true,
		accessLevel: ["superAdmin"],
		value: "productSecondaryCategories",
		menu: "products",
		type: "read",
	},
	{
		path: "/products/technicalspecifications/add",
		component: TechnicalSpecificationForm,
		exact: true,
		accessLevel: ["superAdmin"],
		value: "productTechnicalSpecifications",
		menu: "products",
		type: "write",
	},
	{
		path: "/products/technicalspecifications/update/:id",
		component: TechnicalSpecificationForm,
		exact: true,
		accessLevel: ["superAdmin"],
		value: "productTechnicalSpecifications",
		menu: "products",
		type: "update",
	},
	{
		path: "/products/technicalspecifications/:id",
		component: ViewTechnicalSpecification,
		exact: true,
		accessLevel: ["superAdmin"],
		value: "productTechnicalSpecifications",
		menu: "products",
		type: "read",
	},
	{
		path: "/products/implement/sub-assembly/add",
		component: ImplementSubAssemblyForm,
		exact: true,
		accessLevel: ["superAdmin"],
		value: "implementesSubAssembly",
		menu: "products",
		type: "write",
	},
	{
		path: "/products/implement/sub-assembly/:id",
		component: ViewImplementSubAssembly,
		exact: true,
		accessLevel: ["superAdmin"],
		value: "implementesSubAssembly",
		menu: "products",
		type: "read",
	},
	{
		path: "/products/implement/sub-assembly/update/:id",
		component: ImplementSubAssemblyForm,
		exact: true,
		accessLevel: ["superAdmin"],
		value: "implementesSubAssembly",
		menu: "products",
		type: "update",
	},
	{
		path: "/products/implements/add",
		component: ImplementForm,
		exact: true,
		accessLevel: ["superAdmin"],
		value: "implements",
		menu: "products",
		type: "write",
	},
	{
		path: "/products/implements/:id",
		component: ViewImplement,
		exact: true,
		accessLevel: ["superAdmin"],
		value: "implements",
		menu: "products",
		type: "read",
	},
	{
		path: "/products/implements/update/:id",
		component: ImplementForm,
		exact: true,
		accessLevel: ["superAdmin"],
		value: "implements",
		menu: "products",
		type: "update",
	},

	{
		path: "/products/sub-assembly/add",
		component: SubAssemblyForm,
		exact: true,
		accessLevel: ["superAdmin"],
		value: "subAssembly",
		menu: "products",
		type: "write",
	},
	{
		path: "/products/sub-assembly/:id",
		component: ViewSubAssemblyPage,
		exact: true,
		accessLevel: ["superAdmin"],
		value: "subAssembly",
		menu: "products",
		type: "read",
	},
	{
		path: "/products/sub-assembly/update/:id",
		component: SubAssemblyForm,
		exact: true,
		accessLevel: ["superAdmin"],
		value: "subAssembly",
		menu: "products",
		type: "update",
	},
	{
		path: "/products/accessory/add",
		component: AccessoryForm,
		exact: true,
		accessLevel: ["superAdmin"],
		value: "accessory",
		menu: "products",
		type: "write",
	},
	{
		path: "/products/accessory/:id",
		component: ViewAccessoryPage,
		exact: true,
		accessLevel: ["superAdmin"],
		value: "accessory",
		menu: "products",
		type: "read",
	},
	{
		path: "/products/accessory/update/:id",
		component: AccessoryForm,
		exact: true,
		accessLevel: ["superAdmin"],
		value: "accessory",
		menu: "products",
		type: "update",
	},
	{
		path: "/products/model/add",
		component: ModelForm,
		exact: true,
		accessLevel: ["superAdmin"],
		value: "model",
		menu: "products",
		type: "write",
	},
	{
		path: "/products/model/:id",
		component: ViewModelPage,
		exact: true,
		accessLevel: ["superAdmin"],
		value: "model",
		menu: "products",
		type: "read",
	},
	{
		path: "/products/model/update/:id",
		component: ModelForm,
		exact: true,
		accessLevel: ["superAdmin"],
		value: "model",
		menu: "products",
		type: "update",
	},
	{
		path: "/products/sparepart/add",
		component: SparePartForm,
		exact: true,
		accessLevel: ["superAdmin"],
		value: "sparePart",
		menu: "products",
		type: "write",
	},
	{
		path: "/products/sparepart/:id",
		component: ViewSparePartPage,
		exact: true,
		accessLevel: ["superAdmin"],
		value: "sparePart",
		menu: "products",
		type: "read",
	},
	{
		path: "/products/sparepart/update/:id",
		component: SparePartUpdateForm,
		exact: true,
		accessLevel: ["superAdmin"],
		value: "sparePart",
		menu: "products",
		type: "update",
	},
	{
		path: "/products/hsncode/add",
		component: HSNCodeForm,
		exact: true,
		accessLevel: ["superAdmin"],
		value: "hsnCode",
		menu: "products",
		type: "write",
	},
	{
		path: "/products/hsncode/:id",
		component: ViewHSNCode,
		exact: true,
		accessLevel: ["superAdmin"],
		value: "hsnCode",
		menu: "products",
		type: "read",
	},
	{
		path: "/products/hsncode/update/:id",
		component: HSNCodeForm,
		exact: true,
		accessLevel: ["superAdmin"],
		value: "hsnCode",
		menu: "products",
		type: "update",
	},

	{ component: NotFound404Page, accessLevel: ["superAdmin"] },
];

export default routes;

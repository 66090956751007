import React from "react";
import { Typography } from "antd";
import styles from "./FallBackStyle.module.css";

const { Title } = Typography;

const FallBack = (props) => {
	const { error } = props;

	return (
		<div className={styles.fallbackParent}>
			<Title level={4} className={styles.headingStyles}>
				Ooops error occured : {error.message}
			</Title>
			<Title level={5}>Please try again!</Title>
		</div>
	);
};

export default FallBack;

import { invokeApi } from "../utils/api";
import { $axios } from "../utils/axios";

export const salesPointContact = () =>
	invokeApi($axios, "/salespoint/contact", {}, "GET")
		.then((resp) => resp)
		.catch((err) => {
			throw err;
		});

export const salesPointSingleContact = (id, softdelete) =>
	invokeApi(
		$axios,
		`/salespoint/contact/${softdelete === "true" ? "soft-delete/" : ""}${id}`,
		{},
		"GET"
	)
		.then((resp) => resp)
		.catch((err) => {
			throw err;
		});

export const softDeleteSalesPointSingleContact = (id, reason) =>
	invokeApi($axios, `/salespoint/contact/soft-delete/${id}?reason=${reason}`, {}, "DELETE")
		.then((resp) => resp)
		.catch((err) => {
			throw err;
		});

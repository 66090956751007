import { UserOutlined } from "@ant-design/icons";
import { Button, Card, Col, Form, message, Row, Space, Typography } from "antd";
import axios from "axios";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useDropzone } from "react-dropzone";
import { ErrorBoundary } from "react-error-boundary";
import { useDispatch, useSelector } from "react-redux";
import { NavLink, useHistory, useLocation } from "react-router-dom";
import BasicLayout from "../../../components/BasicLayout";
import Center from "../../../components/common/Center";
import FallBack from "../../../components/common/FallBack/FallBack";
import Notification from "../../../components/common/Notification";
import InputField from "../../../components/Form/InputField";
import Upload from "../../../components/Form/Upload";
import {
	addCategory,
	getSingleCategory,
	updateCategory,
	updateCategoryFailure,
} from "../../../store/actions/products/category/categories.action";
import { formatFileName } from "../../../utils/helper";
import Select from "../../../components/Form/Select";
import { getAllBrands } from "../../../store/actions/products/brands/brand.action";
import { wordCount } from "../../../utils/wordCount";
import { WordCountDisplayer } from "../../../components/common/WordCountDisplayer";
import { aspectRatioDependancy } from "../../../utils/imageAspectRatio";
import { imageSizeDependancy } from "../../../utils/imageSize";

const BrandForm = (props) => {
	const history = useHistory();
	const dispatch = useDispatch();
	const [file, setFile] = useState([]);
	const { Title } = Typography;
	const [form] = Form.useForm();
	const category = useSelector((state) => state.category.category);
	const brands = useSelector((state) => state.brand.brands || []);
	const [uploadMedia, setUploadMedia] = useState("");
	const admin = useSelector((state) => state?.admin?.admin || {});

	const location = useLocation();
	const softdelete = new URLSearchParams(location.search).get("soft-delete");

	const categoryId = +props.match.params.id;
	const currentLocation = props.location.pathname;

	const onImageDrop = useCallback(async (acceptedFiles, fileRejections) => {
		try {
			imageSizeDependancy(fileRejections);

			await aspectRatioDependancy(acceptedFiles, setFile);
		} catch (error) {
			const errorMessage = error?.message || "Something went wrong";
			Notification("error", errorMessage, "", "topRight");
		}
	}, []);

	const {
		getRootProps: getImageRootProps,
		getInputProps: getImageInputProps,
		isDragActive: isImageDragActive,
	} = useDropzone({
		onDrop: onImageDrop,
		accept: "image/png",
		multiple: false,
		maxSize: "1000000",
	});

	const handleError = (error) => {
		message.error(error.message);
	};
	useEffect(() => {
		if (categoryId) {
			dispatch(getSingleCategory(categoryId, softdelete)).catch((err) => {
				Notification("error", err, "", "topRight");
				history.push("/products/categories");
			});
		}
	}, [categoryId, history, softdelete, dispatch]);

	useEffect(() => {
		dispatch(getAllBrands());
	}, [dispatch]);

	const brandOptions = useMemo(
		() =>
			brands?.map((brand) => ({
				key: `${brand.name} (${brand.code})`,
				value: brand.id,
			})),
		[brands]
	);

	const onFinish = async (values) => {
		try {
			if (currentLocation === "/products/categories/add") {
				if (file.length === 0) {
					Notification("error", "Please upload a valid media", "", "topRight");
					return;
				}
				const fileName = formatFileName(file[0].name.split(".")[0], file[0].name.split(".").pop(),admin);
				const fileType = file[0].type;
				const data = { ...values, fileName, fileType };
				const resp = await dispatch(addCategory(data));
				if (resp && resp.signedUrl) {
					const options = {
						headers: {
							"Content-Type": uploadMedia[0].type,
						},
					};
					try {
						await axios.put(resp.signedUrl, uploadMedia[0], options);
						history.push("/products/categories");
						Notification("success", "Media has been succesfully added.", "", "topRight");
					} catch (error) {
						const errorMessage = error.message || "Something went wrong";
						Notification("error", errorMessage, "", "top");
					}
				}
			} else {
				await wordCount(values.updateReason, 10);
				if (file.length === 0) {
					try {
						const resp = await dispatch(updateCategory(categoryId, values));
						const message = resp.message || "Updated successfully";
						history.push("/products/categories");
						Notification("success", message, "", "topRight");
					} catch (err) {
						const errorMessage =
							(err?.response && err?.response?.data?.message) || "Some thing went wrong";
						dispatch(updateCategoryFailure(errorMessage));
						Notification("error", "Error Occurred", errorMessage, "topRight");
					} finally {
						setFile([]);
					}
				} else {
					const fileName = formatFileName(
						file[0].name.split(".")[0],
						file[0].name.split(".").pop(),
						admin
					);
					const fileType = file[0].type;
					const data = { ...values, fileName, fileType };
					const resp = await dispatch(updateCategory(categoryId, data));

					if (resp && resp.data.signedUrl) {
						const options = {
							headers: {
								"Content-Type": uploadMedia[0].type,
							},
						};
						try {
							await axios.put(resp.data.signedUrl, uploadMedia[0], options);
							history.push("/products/categories");
							Notification("success", "Media has been succesfully updated.", "", "topRight");
						} catch (error) {
							const errorMessage = error.message || "Something went wrong";
							Notification("error", "", errorMessage, "topRight");
						}
					}
				}
			}
		} catch (err) {
			if (err.message) {
				return Notification("error", "Error Occurred", err.message, "topRight");
			}
			const errorMessage =
				(err?.response && err?.response?.data?.message) || "Something went wrong";
			return Notification("error", "Error Occurred", errorMessage, "topRight");
		}
	};

	const defaultValues = useMemo(
		() => ({
			code: categoryId ? category.code : "",
			name: categoryId ? category.name : "",
			brandId: categoryId ? category.brandId : undefined,
		}),
		[categoryId, category.code, category.name, category.brandId]
	);

	useEffect(() => {
		form.setFieldsValue(defaultValues);
	}, [form, defaultValues]);

	const handleCancel = () => {
		Notification("info", "Job action has been cancelled", "", "topRight");
	};
	return (
		<BasicLayout accessLevel={props.accessLevel}>
			<ErrorBoundary FallbackComponent={FallBack} onError={handleError}>
				<Row type="flex" justify="center" align="middle" id="form-container">
					<Center height="4rem">
						{categoryId ? (
							<Title level={2} className="update-or-add-title">
								Update Primary Category
							</Title>
						) : (
							<Title level={2} className="update-or-add-title">
								Add New Primary Category
							</Title>
						)}
					</Center>
					<Col>
						<Card className="form-card">
							<Form
								form={form}
								name="basic"
								layout="vertical"
								onFinish={onFinish}
								initialValues={defaultValues}
							>
								<InputField
									rules={[
										{
											required: true,
										},
										{
											min: 1,
											message: "Category Name be minimum 1 character.",
										},
										{
											max: 40,
											message: "Category Name be minimum 40 character.",
										},
									]}
									label="Category Name"
									name="name"
									placeholder="Please enter primary category name"
									prefix={<UserOutlined />}
								/>
								<Select
									rules={[{ required: true }]}
									label="Select Brand"
									name="brandId"
									placeholder="Please enter brand"
									options={brandOptions}
								/>
								<InputField
									rules={[
										{
											required: true,
										},
										{
											min: 10,
											message: "Code must be minimum 10 characters.",
										},
										{
											max: 10,
											message: "Code  must be maximum 10 characters.",
										},
									]}
									label="Code"
									name="code"
									placeholder="Please enter code"
									prefix={<UserOutlined />}
								/>
								<Upload
									getInputProps={getImageInputProps}
									getRootProps={getImageRootProps}
									type="image"
									isDragActive={isImageDragActive}
									file={file}
									url={categoryId ? category.url : ""}
									setUploadMedia={setUploadMedia}
								/>
								{categoryId ? (
									<WordCountDisplayer
										label="Update Reason"
										name="updateReason"
										placeholder="Please enter reason"
										rules={[
											{
												required: true,
											},
											{
												message: "update reason must be minimum 10 words.",
											},
										]}
									/>
								) : null}
								<Form.Item style={{ marginTop: "20px" }}>
									<Center height="0px">
										<Space>
											<NavLink to="/products/categories">
												<Button danger type="primary" onClick={handleCancel}>
													Cancel
												</Button>
											</NavLink>
											<Button type="primary" htmlType="submit">
												{categoryId ? "Update" : "Add"}
											</Button>
										</Space>
									</Center>
								</Form.Item>
							</Form>
						</Card>
					</Col>
				</Row>
			</ErrorBoundary>
		</BasicLayout>
	);
};

export default BrandForm;

import { invokeApi } from "../utils/api";
import { $axios } from "../utils/axios";

export const getAllImplements = () =>
	invokeApi($axios, "/implements", {}, "GET")
		.then((resp) => resp)
		.catch((err) => {
			throw err;
		});

export const getSingleImplements = (implementsId, softdelete) =>
	invokeApi(
		$axios,
		`/implements/${softdelete === "true" ? "soft-delete/" : ""}${implementsId}`,
		{},
		"GET"
	)
		.then((resp) => resp)
		.catch((err) => {
			throw err;
		});

export const softDeleteSingleImplements = (implementsId, reason) =>
	invokeApi($axios, `/implements/soft-delete/${implementsId}?reason=${reason}`, {}, "DELETE")
		.then((resp) => resp)
		.catch((err) => {
			throw err;
		});

export const restoreImplements = (implementsId) =>
	invokeApi($axios, `/implements/restore/${implementsId}`, {}, "PUT")
		.then((resp) => resp)
		.catch((err) => {
			throw err;
		});

export const addImplements = (data) =>
	invokeApi($axios, "/implements", data, "POST")
		.then((resp) => resp)
		.catch((err) => {
			throw err;
		});

export const updateImplements = (id, data) =>
	invokeApi($axios, `/implements/${id}`, data, "PUT")
		.then((resp) => resp)
		.catch((err) => {
			throw err;
		});

import React, { useState } from "react";
import { AlignLeftOutlined, CloseOutlined, LoginOutlined } from "@ant-design/icons";
import { Button, Drawer, Menu } from "antd";
import SubMenu from "antd/lib/menu/SubMenu";
import { useDispatch, useSelector } from "react-redux";
import { NavLink } from "react-router-dom";
import { toggleDrawer } from "../../store/actions/other/other.action";
import { SET_DRAWER_DEFAULT } from "../../store/actions/other/other.actionType";
import "./index.css";

const Index = ({ menuData, menuKey, openKey, isLoading, onLogout }) => {
	const admin = useSelector((state) => state?.admin?.admin || {});

	const dispatch = useDispatch();
	let intitialStateForVisible = false;
	
	if (process.env.NODE_ENV === "test") {
		intitialStateForVisible = true;
	}

	const [visible, setVisible] = useState(intitialStateForVisible);
	// To show drawer on click
	const ShowDrawer = () => {
		setVisible(true);
		dispatch(toggleDrawer());
	};
	// To Hide drawer on click
	const onClose = () => {
		setVisible(false);
		dispatch(toggleDrawer());
	};

	// To hide drawer and set drawer visibility false on click
	function setDrawerFalse() {
		return {
			type: SET_DRAWER_DEFAULT,
		};
	}
	const handleRouteChange = () => {
		dispatch(setDrawerFalse());
	};

	const user = admin?.username ? admin?.username : admin?.userName;
	const capitalUser = user?.charAt(0).toUpperCase() + user?.slice(1);

	const userCompanyName = admin?.companyName ? admin?.companyName : "MHASWADKAR AUTOLINES PVT.LTD.";
	const capitalCompanyName = userCompanyName?.toUpperCase();

	return (
		<>
			<button className="drawer-button" onClick={ShowDrawer}>
				<AlignLeftOutlined style={{ fontSize: 25 }} />
			</button>
			<Drawer
				placement="top"
				background="red"
				height="100vh"
				closable={false}
				onClose={onClose}
				visible={visible}
			>
				<div className="root-div" id="root-div-Id">
					<div className="subroot-div">
						<button className="drawer-button subroot-button" onClick={onClose}>
							<CloseOutlined />
						</button>
						<div className="image-container" style={{ marginLeft: "25px" }}>
							<img src={`${window.location.origin}/assets/image/logo.svg`} alt="MAPL Logo"></img>
						</div>
						<Button
							onClick={onLogout}
							type="link"
							danger
							loading={isLoading}
							size="large"
							className="user-logout-button"
						>
							<LoginOutlined />
						</Button>
					</div>
					<Menu
						className="menu"
						theme=""
						defaultSelectedKeys={menuKey.current}
						defaultOpenKeys={openKey.current}
						mode="inline"
					>
						{menuData?.map((menu, index) => {
							return (
								<SubMenu key={menu?.identifier + index} icon={<menu.icon />} title={menu.title}>
									{menu?.subMenu.map((sub, index) => (
										<Menu.Item key={menu?.identifier + index} icon={<sub.icon />}>
											<button
												onClick={handleRouteChange}
												style={{ border: "none", background: "inherit" }}
											>
												{" "}
												<NavLink to={sub.path}>{sub.title}</NavLink>
											</button>
										</Menu.Item>
									))}
								</SubMenu>
							);
						})}
					</Menu>
				</div>

				<div style={{ display: "flex" }}>
					<div className="user-feild-mobile">
						<h3 className="user-name-mobile">{capitalUser}</h3>
						<h4 className="user-companyname-mobile">{capitalCompanyName} </h4>
					</div>
				</div>
				<footer className="modal-footer">MAPL ©{new Date().getFullYear()}</footer>
			</Drawer>
		</>
	);
};

export default Index;



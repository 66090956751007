export const ADD_LOGIN_REQUEST = "ADD_LOGIN_REQUEST",
	ADD_LOGIN_SUCCESS = "ADD_LOGIN_SUCCESS",
	ADD_LOGIN_FAILURE = "ADD_LOGIN_FAILURE";

export const ADMIN_ME_REQUEST = "ADMIN_ME_REQUEST",
	ADMIN_ME_SUCCESS = "ADMIN_ME_SUCCESS",
	ADMIN_ME_FAILURE = "ADMIN_ME_FAILURE";

export const LOGOUT_REQUEST = "LOGOUT_REQUEST",
	LOGOUT_SUCCESS = "LOGOUT_SUCCESS",
	LOGOUT_FAILURE = "LOGOUT_FAILURE";

export const GET_ADMIN_REQUEST = "GET_ADMIN_REQUEST",
	GET_ADMIN_SUCCESS = "GET_ADMIN_SUCCESS",
	GET_ADMIN_FAILURE = "GET_ADMIN_FAILURE";

export const GET_USERS_REQUEST = "GET_USERS_REQUEST",
	GET_USERS_SUCCESS = "GET_USERS_SUCCESS",
	GET_USERS_FAILURE = "GET_USERS_FAILURE";

export const GET_SUBDEALER_CONTACTS_REQUEST = "GET_SUBDEALER_CONTACTS_REQUEST",
	GET_SUBDEALER_CONTACTS_SUCCESS = "GET_SUBDEALER_CONTACTS_SUCCESS",
	GET_SUBDEALER_CONTACTS_FAILURE = "GET_SUBDEALER_CONTACTS_FAILURE";

export const GET_SUBDEALER_SINGLE_CONTACT_REQUEST = "GET_SUBDEALER_SINGLE_CONTACT_REQUEST",
	GET_SUBDEALER_SINGLE_CONTACT_SUCCESS = "GET_SUBDEALER_SINGLE_CONTACT_SUCCESS",
	GET_SUBDEALER_SINGLE_CONTACT_FAILURE = "GET_SUBDEALER_SINGLE_CONTACT_FAILURE";

export const SOFTDELETE_SUBDEALER_SINGLE_CONTACT_REQUEST =
		"SOFTDELETE_SUBDEALER_SINGLE_CONTACT_REQUEST",
	SOFTDELETE_SUBDEALER_SINGLE_CONTACT_SUCCESS = "SOFTDELETE_SUBDEALER_SINGLE_CONTACT_SUCCESS",
	SOFTDELETE_SUBDEALER_SINGLE_CONTACT_FAILURE = "SOFTDELETE_SUBDEALER_SINGLE_CONTACT_FAILURE";

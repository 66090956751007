import { Card, message } from "antd";
import { useMemo,useEffect } from "react";
import { ErrorBoundary } from "react-error-boundary";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import BasicLayout from "../../../components/BasicLayout/index";
import FallBack from "../../../components/common/FallBack/FallBack";
import Notification from "../../../components/common/Notification";
import { CustomTab } from "../../../components/common/Tab/Tab";
import { getSingleCategory } from "../../../store/actions/Careers/careers.action";
import { checkIfObjectEmpty, isEqual } from "../../../utils/helper";
import "./index.css";

const ViewCategory = (props) => {
	const categoryId = +props.match.params.id;
	const dispatch = useDispatch();
	const category = useSelector((state) => state.careers.category || {});
	const isEmpty = checkIfObjectEmpty(category);
	const location = useLocation();
	const softdelete = new URLSearchParams(location.search).get("soft-delete");
	const history = useHistory();
	const admin = useSelector((state) => state.admin.admin || []);

	const permissionArr = useMemo(() => {
		if (admin && admin.template) {
			const { permissions } = admin.template;
			return permissions;
		} else if (admin && admin.accessLevel === "superAdmin") {
			return "all";
		}
		return [];
	}, [admin]);
	
	useEffect(() => {
		if (isEmpty || !isEqual(category?.id, categoryId)) {
			dispatch(getSingleCategory(categoryId, softdelete)).catch((err) => {
				Notification("error", err, "", "topRight");
				history.push("/careers/categories");
			});
		}
	}, [dispatch, history, softdelete, isEmpty, categoryId, category?.id]);

	let data = [];
	let isOrderEmpty = Object.keys(category).length === 0;
	if (!isOrderEmpty) {
		data = [
			{ title: "Id", description: category.id },
			{
				title: "title",
				description: category.title,
			},
			{
				title: "Description",
				description: category.description,
			},
			{
				title: "Received",
				description: category.createdAt,
			},
		];
	}
	const handleError = (error) => {
		message.error(error.message);
	};

	let operationData = {
		deletedBy: category?.deletedBy,
		updatedBy: category?.updatedBy,
		restoredBy: category?.restoredBy,
	};

	return (
		<BasicLayout accessLevel={props.accessLevel}>
			<ErrorBoundary FallbackComponent={FallBack} onError={handleError}>
				<Card className="job-detail-card" data-testid="category-detail-card">
					<CustomTab
						update={
							permissionArr === "all"
								? "true"
								: permissionArr?.career?.categories.filter((item) => item === "update")?.length > 0
								? "true"
								: false
						}
						data={data}
						operationData={operationData}
						redirectUrl={`/careers/categories/update/${categoryId}`}
					/>
				</Card>
			</ErrorBoundary>
		</BasicLayout>
	);
};

export default ViewCategory;

import { invokeApi } from "../utils/api";
import { $axios } from "../utils/axios";

export const dealerContact = () =>
	invokeApi($axios, "/dealer/contact", {}, "GET")
		.then((resp) => resp)
		.catch((err) => {
			throw err;
		});

export const dealerSingleContact = (id, softdelete) =>
	invokeApi(
		$axios,
		`/dealer/contact/${softdelete === "true" ? "soft-delete/" : ""}${id}`,
		{},
		"GET"
	)
		.then((resp) => resp)
		.catch((err) => {
			throw err;
		});

export const softDeleteDealerSingleContact = (id, reason) =>
	invokeApi($axios, `/dealer/contact/soft-delete/${id}?reason=${reason}`, {}, "DELETE")
		.then((resp) => resp)
		.catch((err) => {
			throw err;
		});

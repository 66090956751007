import { Button, Form, message, Popconfirm, Typography } from "antd";
import React, { useEffect, useMemo, useState } from "react";
import { ErrorBoundary } from "react-error-boundary";
import { useDispatch, useSelector } from "react-redux";
import BasicLayout from "../../../components/BasicLayout/index";
import ButtonAsLink from "../../../components/common/ButtonAsLink";
import FallBack from "../../../components/common/FallBack/FallBack";
import EditableTable from "../../../components/common/Table/EditableTable";
import { useDeleteAction } from "../../../components/Hooks/useDeleteAction";
import useExportCsv from "../../../components/Hooks/useExportCsv";
import useTableSearch from "../../../components/Hooks/useTableSearch";
import { useUpdateReason } from "../../../components/Hooks/useUpdateReason";
import {
	getAllAccessories,
	softDeleteSingleAccessory,
	updateAccessoryFromTable,
} from "../../../store/actions/products/Accessory/accessory.action";
import { DeleteModel } from "../../../utils/DeleteModel";
import { formatFileName } from "../../../utils/helper";

const Accessory = (props) => {
	const [getColumnSearchProps] = useTableSearch();
	const [exportToCSV] = useExportCsv();
	const { deleteData } = useDeleteAction();
	const [deleteId, setDeleteId] = useState(undefined);
	const [isModalVisible, setIsModalVisible] = useState(false);
	const [onSaveValues, setOnSaveValues] = useState();
	const [updateAccessoryId, setUpdateAcessoryId] = useState();
	const [data, setData] = useState([]);
	const [form] = Form.useForm();
	const { modelToggle, UpdateModel } = useUpdateReason();

	const [editingKey, setEditingKey] = useState("");
	const isEditing = (record) => record.key === editingKey;

	const dispatch = useDispatch();
	const accessories = useSelector((state) => state.accessory.accessories || []);

	const admin = useSelector((state) => state?.admin?.admin || {});

	const permissionArr = useMemo(() => {
		if (admin && admin?.template) {
			const { permissions } = admin?.template;
			return permissions;
		} else if (admin && admin?.accessLevel === "superAdmin") {
			return "all";
		}
		return [];
	}, [admin]);

	useEffect(() => {
		dispatch(getAllAccessories());
	}, [dispatch]);

	function originDataUpdate(accepetedData) {
		let tempdata = [];
		for (let i = 0; i < accepetedData.length; i++) {
			tempdata.push({
				id: accepetedData[i].id,
				key: accepetedData[i].key,
				name: accepetedData[i].name,
				code: accepetedData[i].code,
				stock: accepetedData[i].stock,
				price: accepetedData[i].price,
				discount: accepetedData[i].discount,
				hsnCode: accepetedData[i]?.hsnCode,
			});
		}

		return tempdata;
	}

	function dataSourceUpdate(accepetedData) {
		let tempDataSource = [];
		if (accepetedData.length > 0) {
			tempDataSource = accepetedData.sort(function (a, b) {
				return new Date(b.updatedAt) - new Date(a.updatedAt);
			});
			tempDataSource = tempDataSource.map((row) => ({
				...row,
				createdAt: new Date(row.createdAt).toLocaleString(),
				key: row.id,
				hsnCode: row?.hsnCode,
			}));
		}
		return tempDataSource;
	}

	const dataSource = useMemo(() => {
		return dataSourceUpdate(accessories);
	}, [accessories]);

	const originData = useMemo(() => {
		return originDataUpdate(dataSource);
	}, [dataSource]);

	async function confirm(id, reason) {
		await deleteData(id, reason, softDeleteSingleAccessory, setIsModalVisible);
	}

	useEffect(() => {
		setData(originData);
	}, [originData]);

	const save = async (key) => {
		try {
			setUpdateAcessoryId(key);
			const row = await form.validateFields();
			const newData = [...data];
			const index = newData.findIndex((item) => key === item.key);
			if (index > -1) {
				const item = newData[index];
				newData.splice(index, 1, { ...item, ...row });
				let { discount, stock, price } = newData[index];
				let data = {
					discount,
					stock,
					price,
				};
				setOnSaveValues(data);
				modelToggle(true);
				setEditingKey("");
			} else {
				setEditingKey("");
			}
		} catch (errInfo) {
			return message.error(`Something went wrong`);
		}
	};

	const columns = [
		{
			title: "Id",
			dataIndex: "id",
			key: "id",
		},
		{
			title: "Name",
			dataIndex: "name",
			key: "name",
			...getColumnSearchProps("name"),
		},
		{
			title: "Code",
			dataIndex: "code",
			key: "code",
			...getColumnSearchProps("code"),
		},
		{
			title: "Stock",
			dataIndex: "stock",
			key: "stock",
			min: 0,
			editable: true,
			...getColumnSearchProps("stock"),
		},
		{
			title: "Price",
			dataIndex: "price",
			key: "price",
			min: 1,
			editable: true,
			...getColumnSearchProps("price"),
		},
		{
			title: "Discount",
			dataIndex: "discount",
			key: "discount",
			min: 0,
			max: 100,
			editable: true,
			...getColumnSearchProps("discount"),
		},
		{
			title: "HSN/SAC Code",
			key: "hsnCode",
			...getColumnSearchProps("hsnCode"),
			render: (_text, record) => (
				<a href={`/products/hsncode/${record?.hsnCode?.id}`}> {record?.hsnCode?.code}</a>
			),
		},
		{
			title: "Action",
			key: "action",
			render: (_text, record) => (
				<DeleteModel
					record={record}
					confirm={confirm}
					setIsModalVisible={setIsModalVisible}
					isModalVisible={isModalVisible}
					cancelMessage="Accessory Removing has been cancelled"
					linkUrl={`/products/accessory`}
					placeholder="Please enter reason to delete accessory"
					deleteId={deleteId}
					setDeleteId={setDeleteId}
					update={
						permissionArr === "all"
							? "true"
							: permissionArr?.products?.accessory?.filter((item) => item === "update")?.length > 0
							? "true"
							: false
					}
					isdelete={
						permissionArr === "all"
							? "true"
							: permissionArr?.products?.accessory?.filter((item) => item === "delete")?.length > 0
							? "true"
							: false
					}
					view={
						permissionArr === "all"
							? "true"
							: permissionArr?.products?.accessory?.filter((item) => item === "read")?.length > 0
							? "true"
							: false
					}
				/>
			),
		},
		{
			title: "operation",
			dataIndex: "operation",
			render: (_, record) => {
				const editable = isEditing(record);
				return editable ? (
					<div style={{ width: "100%", display: "flex" }}>
						<span
							onClick={() => save(record.key)}
							style={{
								display: "inline-block",
								marginRight: "10px",
								color: "#1890ff",
								cursor: "pointer",
							}}
						>
							Save
						</span>
						<Popconfirm title="Sure to cancel?" onConfirm={() => setEditingKey("")}>
							<span style={{ color: "#1890ff", cursor: "pointer" }}>Cancel</span>
						</Popconfirm>
					</div>
				) : (
					<Typography.Link disabled={editingKey !== ""} onClick={() => edit(record)}>
						Edit
					</Typography.Link>
				);
			},
		},
	];

	const edit = (record) => {
		form.setFieldsValue({
			...record,
		});
		setEditingKey(record.key);
	};

	const fileName = formatFileName("accessories", "xlsx", admin);
	let downloadData = accessories?.map((data) => {
		let newData = {
			"ACCESSORY NAME": data.name,
			"ACCESSORY CODE": data.code,
			"ACCESSORY STOCK": data?.stock,
			"ACCESSORY PRICE": data?.price,
			"ACCESSORY DISCOUNT": data?.discount,
			"HSC CODE": data?.hsnCode?.code,
			"HSN CODE ID": data?.hsnCode?.id,
			createdAt: data.createdAt,
		};
		return newData;
	});

	const handleError = (error) => {
		message?.error(error?.message);
	};
	return (
		<BasicLayout accessLevel={props.accessLevel}>
			<ErrorBoundary FallbackComponent={FallBack} onError={handleError}>
				<div className="button-style-to-right">
					{permissionArr === "all" ? (
						<Button className="download-btn" onClick={(e) => exportToCSV(downloadData, fileName)}>
							Export
						</Button>
					) : permissionArr?.products?.accessory?.filter((item) => item === "export")?.length > 0 &&
					  downloadData?.length !== 0 ? (
						<Button className="download-btn" onClick={(e) => exportToCSV(downloadData, fileName)}>
							Export
						</Button>
					) : null}
					{permissionArr === "all" ? (
						<ButtonAsLink text="Add New Accessory" link="/products/accessory/add" />
					) : permissionArr?.products?.brands?.filter((item) => item === "write")?.length > 0 ? (
						<ButtonAsLink text="Add New Accessory" link="/products/accessory/add" />
					) : null}
				</div>
				<EditableTable
					form={form}
					columns={columns}
					data={data}
					isEditing={isEditing}
					footerText={`Total Models: ${accessories?.length}`}
				/>
				<UpdateModel
					id={updateAccessoryId}
					action={updateAccessoryFromTable}
					valuesWithoutReason={onSaveValues}
					redirectUrl="/products/accessory"
					placeholder="Enter reason for update accessory"
				/>
			</ErrorBoundary>
		</BasicLayout>
	);
};

export default Accessory;

import { Button, message } from "antd";
import React, { useEffect, useMemo, useState } from "react";
import { ErrorBoundary } from "react-error-boundary";
import { useDispatch, useSelector } from "react-redux";
import BasicLayout from "../../components/BasicLayout/index";
import FallBack from "../../components/common/FallBack/FallBack";
import Tables from "../../components/common/Table/Table";
import { useDeleteAction } from "../../components/Hooks/useDeleteAction";
import useExportCsv from "../../components/Hooks/useExportCsv";
import useTableSearch from "../../components/Hooks/useTableSearch";
import {
	getDistributorContacts,
	softDeleteDistributorSingleContact,
} from "../../store/actions/distributor/distributor.action";
import { DeleteModel } from "../../utils/DeleteModel";
import { formatFileName } from "../../utils/helper";

const Enquiry = (props) => {
	const [getColumnSearchProps] = useTableSearch();
	const [exportToCSV] = useExportCsv();
	const { deleteData } = useDeleteAction();
	const [deleteId, setDeleteId] = useState(undefined);
	const [isModalVisible, setIsModalVisible] = useState(false);
	const dispatch = useDispatch();
	const enquiries = useSelector((state) => state.distributor.enquiries || []);

	const admin = useSelector((state) => state.admin.admin || []);

	const permissionArr = useMemo(() => {
		if (admin && admin.template) {
			const { permissions } = admin.template;
			return permissions;
		} else if (admin && admin.accessLevel === "superAdmin") {
			return "all";
		}
		return [];
	}, [admin]);

	useEffect(() => {
		dispatch(getDistributorContacts());
	}, [dispatch]);

	let dataSource = [];
	if (enquiries.length > 0) {
		dataSource = enquiries.sort(function (a, b) {
			return new Date(b.updatedAt) - new Date(a.updatedAt);
		});
		dataSource = dataSource.map((row, index) => ({
			...row,
			createdAt: new Date(row.createdAt).toLocaleString(),
			key: row.id,
		}));
	}

	async function confirm(id, reason) {
		await deleteData(id, reason, softDeleteDistributorSingleContact, setIsModalVisible);
	}

	const columns = [
		{
			title: "Id",
			dataIndex: "id",
			key: "id",
		},
		{
			title: "Name",
			dataIndex: "name",
			key: "name",
			...getColumnSearchProps("name"),
		},
		{
			title: "Contact Person",
			dataIndex: "distributorContactPersonName",
			key: "distributorContactPersonName",
			...getColumnSearchProps("distributorContactPersonName"),
		},
		{
			title: "Email",
			dataIndex: "email",
			key: "email",
			...getColumnSearchProps("email"),
		},
		{
			title: "Phone Number",
			dataIndex: "phonenumber",
			key: "phonenumber",
			...getColumnSearchProps("phonenumber"),
		},
		{
			title: "State",
			dataIndex: "state",
			key: "state",
		},
		{
			title: "District",
			dataIndex: "district",
			key: "district",
		},
		{
			title: "Received",
			dataIndex: "createdAt",
			key: "createdAt",
		},
		{
			title: "Action",
			key: "action",
			render: (_text, record) => (
				<DeleteModel
					record={record}
					confirm={confirm}
					update="false"
					setIsModalVisible={setIsModalVisible}
					isModalVisible={isModalVisible}
					cancelMessage="Distributor Removing has been cancelled"
					linkUrl={`/distributor/enquiry`}
					placeholder="Please enter reason to delete distributor"
					deleteId={deleteId}
					setDeleteId={setDeleteId}
					isdelete={
						permissionArr === "all"
							? "true"
							: permissionArr?.distributor?.distributorEnquiry.filter((item) => item === "delete")
									?.length > 0
							? "true"
							: false
					}
					
					view={
						permissionArr === "all"
							? "true"
							: permissionArr?.distributor?.distributorEnquiry.filter((item) => item === "read")
									?.length > 0
							? "true"
							: false
					}
				/>
			),
		},
	];

	const fileName = formatFileName("distributors-enquiries", "xlsx",admin);
	let downloadData = enquiries.map((data) => {
		return Object.assign({}, data, {
			hasAccepted: "true",
		});
	});

	const handleError = (error) => {
		message.error(error.message);
	};
	return (
		<BasicLayout accessLevel={props.accessLevel}>
			<ErrorBoundary FallbackComponent={FallBack} onError={handleError}>
				<div className="button-style-to-right">
					{permissionArr === "all" ? (
						<Button className="download-btn" onClick={(e) => exportToCSV(downloadData, fileName)}>
							Export
						</Button>
					) : permissionArr?.distributor?.distributorEnquiry?.filter((item) => item === "export")
							?.length > 0 && downloadData?.length !== 0 ? (
						<Button className="download-btn" onClick={(e) => exportToCSV(downloadData, fileName)}>
							Export
						</Button>
					) : null}
				</div>

				<Tables
					dataSource={dataSource}
					columns={columns}
					footerText={`Total Enquires: ${enquiries.length}`}
				/>
			</ErrorBoundary>
		</BasicLayout>
	);
};

export default Enquiry;

import { invokeApi } from "../utils/api";
import { $axios } from "../utils/axios";

export const getAllHSNCodes = () =>
	invokeApi($axios, "/product/hsncode", {}, "GET")
		.then((resp) => resp)
		.catch((err) => {
			throw err;
		});

export const getSingleHSNCode = (hsnCodeId, type) =>
	invokeApi(
		$axios,
		`/product/hsncode/${
			type === "true" ? "soft-delete/" : ""
		}${hsnCodeId}`,
		{},
		"GET"
	)
		.then((resp) => resp)
		.catch((err) => {
			throw err;
		});

export const softDeleteSingleHSNCode = (hsnCodeId, reason) =>
	invokeApi(
		$axios,
		`/product/hsncode/soft-delete/${hsnCodeId}?reason=${reason}`,
		{},
		"DELETE"
	)
		.then((resp) => resp)
		.catch((err) => {
			throw err;
		});

export const restoreHSNCode = (hsnCodeId) =>
	invokeApi(
		$axios,
		`/product/hsncode/restore/${hsnCodeId}`,
		{},
		"PUT"
	)
		.then((resp) => resp)
		.catch((err) => {
			throw err;
		});

export const addHSNCode = (data) =>
	invokeApi($axios, "/product/hsncode", data, "POST")
		.then((resp) => resp)
		.catch((err) => {
			throw err;
		});

export const updateHSNCode = (id, data) =>
	invokeApi($axios, `/product/hsncode/${id}`, data, "PUT")
		.then((resp) => resp)
		.catch((err) => {
			throw err;
		});

import { Card, message } from "antd";
import { useEffect, useMemo } from "react";
import { ErrorBoundary } from "react-error-boundary";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import BasicLayout from "../../components/BasicLayout";
import FallBack from "../../components/common/FallBack/FallBack";
import Notification from "../../components/common/Notification";
import { CustomTab } from "../../components/common/Tab/Tab";
import Tables from "../../components/common/Table/Table";
import { getCustomerSingleContact } from "../../store/actions/customer/customer.action";

const EnquiryDetail = (props) => {
	const enquiryId = +props.match.params.id;
	const dispatch = useDispatch();
	const enquiry = useSelector((state) => state.customer.enquiry || {});

	const location = useLocation();
	const tab2 = location.search;
	const softdelete = new URLSearchParams(location.search).get("soft-delete");
	const history = useHistory();
	const admin = useSelector((state) => state.admin.admin || []);

	const permissionArr = useMemo(() => {
		if (admin && admin.template) {
			const { permissions } = admin.template;
			return permissions;
		} else if (admin && admin.accessLevel === "superAdmin") {
			return "all";
		}
		return [];
	}, [admin]);

	useEffect(() => {
		dispatch(getCustomerSingleContact(enquiryId, softdelete)).catch((err) => {
			Notification("error", err, "", "topRight");
			history.push("/customer/enquiry");
		});
	}, [dispatch, history, softdelete, enquiryId]);

	const modelDetailTable = enquiry?.modelDetailed;

	const sparepartDetailTable = enquiry?.sparepartDetailed;

	const accessoryDetailTable = enquiry?.accessoryDetailed;

	const implementDetailTable = enquiry?.implementDetailed;

	const subAssemblyDetailTable = enquiry?.subAssemblyDetailed;

	let data = [];

	let isOrderEmpty = Object.keys(enquiry).length === 0;
	if (!isOrderEmpty) {
		data = [
			{ title: "Id", description: enquiry.id },
			{
				title: "Name",
				description: enquiry.name,
			},
			{
				title: "Email",
				description: enquiry.email,
			},
			{
				title: "Phone Number",
				description: enquiry.phonenumber,
			},
			{
				title: "Address",
				description: enquiry.address ? enquiry.address : "NA",
			},
			{
				title: "State",
				description: enquiry.state,
			},
			{
				title: "District",
				description: enquiry.district,
			},
			{
				title: "Taluka",
				description: enquiry.taluka,
			},
			{
				title: "Village/City",
				description: enquiry.village,
			},
			{
				title: "Status",
				description: enquiry.statusId ? enquiry.statusId : "NA",
			},
			{
				title: "GST Number",
				description: enquiry?.gstnumber ? enquiry?.gstnumber : "NA",
			},

			{
				title: "Expected Purchase Date",
				description: enquiry.purchasedate ? new Date(enquiry.purchasedate).toLocaleString() : "NA",
			},

			{
				title: "Finance Required",
				description: enquiry.finance ? enquiry.finance : "NA",
			},

			{
				title: "Subsidy",
				description: enquiry.subsidy ? enquiry.subsidy : "NA",
			},
			{
				title: "Customer Remark",
				description: enquiry.description ? enquiry.description : "NA",
			},
			{
				title: "Received",
				description: new Date(enquiry.createdAt).toLocaleString(),
			},
			{
				title: "Total Amount Rechargable",
				description: enquiry.overAllFinalTotal ? enquiry.overAllFinalTotal : "NA",
			},
		];
	}
	const handleError = (error) => {
		message.error(error.message);
	};

	let operationData = {
		deletedBy: enquiry?.deletedBy,
		updatedBy: enquiry?.updatedBy,
		restoredBy: enquiry?.restoredBy,
	};

	const conditionalColumns = useMemo(() => {
		return columns?.filter((colomn) => {
			if (enquiry?.isCustomerOutOfState === "no") {
				if (colomn.key === "totalAmountIncludeIGST") {
					return false;
				} else if (colomn.key === "modelIGST") {
					return false;
				} else {
					return true;
				}
			} else {
				if (colomn.key === "totalAmountIncludeBothGST") {
					return false;
				} else if (colomn.key === "modelCGST") {
					return false;
				} else if (colomn.key === "modelSGST") {
					return false;
				} else {
					return true;
				}
			}
		});
	}, [enquiry]);

	const conditionalSparePartsColumns = useMemo(() => {
		return columnsForSparepart?.filter((colomn) => {
			if (enquiry?.isCustomerOutOfState === "no") {
				if (colomn.key === "totalAmountIncludeIGST") {
					return false;
				} else if (colomn.key === "sparepartIGST") {
					return false;
				} else {
					return true;
				}
			} else {
				if (colomn.key === "totalAmountIncludeBothGST") {
					return false;
				} else if (colomn.key === "sparepartCGST") {
					return false;
				} else if (colomn.key === "sparepartSGST") {
					return false;
				} else {
					return true;
				}
			}
		});
	}, [enquiry]);

	const conditionalAccessoriesColumns = useMemo(() => {
		return columnsForAccessory?.filter((colomn) => {
			if (enquiry?.isCustomerOutOfState === "no") {
				if (colomn.key === "totalAmountIncludeIGST") {
					return false;
				} else if (colomn.key === "accessoryIGST") {
					return false;
				} else {
					return true;
				}
			} else {
				if (colomn.key === "totalAmountIncludeBothGST") {
					return false;
				} else if (colomn.key === "accessoryCGST") {
					return false;
				} else if (colomn.key === "accessorySGST") {
					return false;
				} else {
					return true;
				}
			}
		});
	}, [enquiry]);

	const conditionalImplementsColumns = useMemo(() => {
		return columnsForImplements?.filter((colomn) => {
			if (enquiry?.isCustomerOutOfState === "no") {
				if (colomn.key === "totalAmountIncludeIGST") {
					return false;
				} else if (colomn.key === "implementIGST") {
					return false;
				} else {
					return true;
				}
			} else {
				if (colomn.key === "totalAmountIncludeBothGST") {
					return false;
				} else if (colomn.key === "implementCGST") {
					return false;
				} else if (colomn.key === "implementSGST") {
					return false;
				} else {
					return true;
				}
			}
		});
	}, [enquiry]);

	const conditionalSubAssembliesColumns = useMemo(() => {
		return columnsForSubAssemblies?.filter((colomn) => {
			if (enquiry?.isCustomerOutOfState === "no") {
				if (colomn.key === "totalAmountIncludeIGST") {
					return false;
				} else if (colomn.key === "subAssemblyIGST") {
					return false;
				} else {
					return true;
				}
			} else {
				if (colomn.key === "totalAmountIncludeBothGST") {
					return false;
				} else if (colomn.key === "subAssemblyCGST") {
					return false;
				} else if (colomn.key === "subAssemblySGST") {
					return false;
				} else {
					return true;
				}
			}
		});
	}, [enquiry]);

	return (
		<BasicLayout accessLevel={props.accessLevel}>
			<ErrorBoundary FallbackComponent={FallBack} onError={handleError}>
				<Card className="enqiury-detail-card" data-testid="enqiury-detail-card">
					<CustomTab
						data={data}
						operationData={operationData}
						redirectUrl={
							enquiry?.addedBy
								? `/customer/enquiry/update/${enquiryId}`
								: `/customer/enquiry/${enquiryId}`
						}
						update={
							enquiry?.addedBy || enquiry?.addedByUser
								? permissionArr === "all"
									? "true"
									: permissionArr?.customer?.customerEnquiry.filter((item) => item === "update")
											?.length > 0
									? "true"
									: false
								: false
						}
					/>
					{tab2 !== "?tabs=history" && modelDetailTable?.length > 0 && (
						<Tables
							columns={conditionalColumns}
							dataSource={modelDetailTable}
							pagination={false}
							footerText={`Total Amount Pay for Models: ${enquiry?.modelsFinalTotal}`}
						/>
					)}
					{tab2 !== "?tabs=history" && sparepartDetailTable?.length > 0 && (
						<Tables
							columns={conditionalSparePartsColumns}
							dataSource={sparepartDetailTable}
							pagination={false}
							footerText={`Total Amount Pay for SpareParts: ${enquiry?.sparePartsFinalTotal}`}
						/>
					)}
					{tab2 !== "?tabs=history" && accessoryDetailTable?.length > 0 && (
						<Tables
							columns={conditionalAccessoriesColumns}
							dataSource={accessoryDetailTable}
							pagination={false}
							footerText={`Total Amount Pay for Accessories: ${enquiry?.accesoriesFinalTotal}`}
						/>
					)}
					{tab2 !== "?tabs=history" && implementDetailTable?.length > 0 && (
						<Tables
							columns={conditionalImplementsColumns}
							dataSource={implementDetailTable}
							pagination={false}
							footerText={`Total Amount Pay for Implements: ${enquiry?.implementsFinalTotal}`}
						/>
					)}
					{tab2 !== "?tabs=history" && subAssemblyDetailTable?.length > 0 && (
						<Tables
							columns={conditionalSubAssembliesColumns}
							dataSource={subAssemblyDetailTable}
							pagination={false}
							footerText={`Total Amount Pay for Sub Assemblies: ${enquiry?.subAssembliesFinalTotal}`}
						/>
					)}
				</Card>
			</ErrorBoundary>
		</BasicLayout>
	);
};

export default EnquiryDetail;

const columns = [
	{ title: "Sr NO", key: "sno", width: "15px", render: (text, object, index) => index + 1 },
	{
		title: "Model Name",
		dataIndex: "modelName",
		key: "modelName",
		width: "25%",
	},
	{
		title: "Model Number",
		dataIndex: "modelNumber",
		key: "modelNumber",
		render: (_text, record) => (
			<a href={`/products/model/${record?.modelId}`}> {record?.modelNumber}</a>
		),
		width: "20%",
	},
	{
		title: "HSN/SAC",
		dataIndex: "modelHSNCode",
		key: "modelHSNCode",
		width: "15%",
	},
	{
		title: "Quantity",
		dataIndex: "modelStock",
		key: "modelStock",
		width: "3%",
	},
	{
		title: "Price",
		dataIndex: "modelPrice",
		key: "modelPrice",
		width: "10px",
	},

	{
		title: "IGST(%)",
		dataIndex: "modelIGST",
		key: "modelIGST",
		width: "10px",
	},
	{
		title: "CGST(%)",
		dataIndex: "modelCGST",
		key: "modelCGST",
	},
	{
		title: "SGST(%)",
		dataIndex: "modelSGST",
		key: "modelSGST",
	},
	{
		title: "Total with GST",
		dataIndex: "totalAmountIncludeBothGST",
		key: "totalAmountIncludeBothGST",
		width: "20%",
	},
	{
		title: "Total with GST",
		dataIndex: "totalAmountIncludeIGST",
		key: "totalAmountIncludeIGST",
		width: "20%",
	},
];

const columnsForSparepart = [
	{ title: "Sr NO", key: "sno", width: "15px", render: (text, object, index) => index + 1 },

	{
		title: "Sparepart Name",
		dataIndex: "sparepartName",
		key: "sparepartName",
		render: (_text, record) => (
			<a href={`/products/sparepart/${record?.sparepartId}`}> {record?.sparepartName}</a>
		),
		width: "25%",
	},
	{
		title: "HSN/SAC",
		dataIndex: "sparepartHSNCode",
		key: "sparepartHSNCode",
		width: "15%",
	},

	{
		title: " Quantity",
		dataIndex: "sparepartStock",
		key: "sparepartStock",
		width: "3%",
	},
	{
		title: " Price",
		dataIndex: "sparepartPrice",
		key: "sparepartPrice",
		width: "10px",
	},
	{
		title: "IGST(%)",
		dataIndex: "sparepartIGST",
		key: "sparepartIGST",
		width: "10px",
	},
	{
		title: "CGST(%)",
		dataIndex: "sparepartCGST",
		key: "sparepartCGST",
	},
	{
		title: "SGST(%)",
		dataIndex: "sparepartSGST",
		key: "sparepartSGST",
	},
	{
		title: "Total with GST",
		dataIndex: "totalAmountIncludeBothGST",
		key: "totalAmountIncludeBothGST",
		width: "20%",
	},
	{
		title: "Total with GST",
		dataIndex: "totalAmountIncludeIGST",
		key: "totalAmountIncludeIGST",
		width: "20%",
	},
];

const columnsForAccessory = [
	{ title: "Sr NO", key: "sno", width: "15px", render: (text, object, index) => index + 1 },

	{
		title: "Accessory Name",
		dataIndex: "accessoryName",
		key: "accessoryName",
		render: (_text, record) => (
			<a href={`/products/accessory/${record?.accessoryId}`}> {record?.accessoryName}</a>
		),
		width: "25%",
	},
	{
		title: "HSN/SAC",
		dataIndex: "accessoryHSNCode",
		key: "accessoryHSNCode",
		width: "15%",
	},

	{
		title: " Quantity",
		dataIndex: "accessoryStock",
		key: "accessoryStock",
		width: "3%",
	},
	{
		title: " Price",
		dataIndex: "accessoryPrice",
		key: "accessoryPrice",
		width: "10px",
	},
	{
		title: "IGST(%)",
		dataIndex: "accessoryIGST",
		key: "accessoryIGST",
		width: "10px",
	},
	{
		title: "CGST(%)",
		dataIndex: "accessoryCGST",
		key: "accessoryCGST",
	},
	{
		title: "SGST(%)",
		dataIndex: "accessorySGST",
		key: "accessorySGST",
	},
	{
		title: "Total with GST",
		dataIndex: "totalAmountIncludeBothGST",
		key: "totalAmountIncludeBothGST",
		width: "20%",
	},
	{
		title: "Total with GST",
		dataIndex: "totalAmountIncludeIGST",
		key: "totalAmountIncludeIGST",
		width: "20%",
	},
];

const columnsForImplements = [
	{ title: "Sr NO", key: "sno", width: "15px", render: (text, object, index) => index + 1 },

	{
		title: "Implement Name",
		dataIndex: "implementName",
		key: "implementName",
		render: (_text, record) => (
			<a href={`/products/implements/${record?.implementId}`}> {record?.implementName}</a>
		),
		width: "25%",
	},
	{
		title: "HSN/SAC",
		dataIndex: "implementHSNCode",
		key: "implementHSNCode",
		width: "15%",
	},

	{
		title: " Quantity",
		dataIndex: "implementStock",
		key: "implementStock",
		width: "3%",
	},
	{
		title: " Price",
		dataIndex: "implementPrice",
		key: "implementPrice",
		width: "10px",
	},
	{
		title: "IGST(%)",
		dataIndex: "implementIGST",
		key: "implementIGST",
		width: "10px",
	},
	{
		title: "CGST(%)",
		dataIndex: "implementCGST",
		key: "implementCGST",
	},
	{
		title: "SGST(%)",
		dataIndex: "implementSGST",
		key: "implementSGST",
	},
	{
		title: "Total with GST",
		dataIndex: "totalAmountIncludeBothGST",
		key: "totalAmountIncludeBothGST",
		width: "20%",
	},
	{
		title: "Total with GST",
		dataIndex: "totalAmountIncludeIGST",
		key: "totalAmountIncludeIGST",
		width: "20%",
	},
];

const columnsForSubAssemblies = [
	{ title: "Sr NO", key: "sno", width: "15px", render: (text, object, index) => index + 1 },

	{
		title: "Sub Assembly Name",
		dataIndex: "subAssemblyName",
		key: "subAssemblyName",
		render: (_text, record) => (
			<a href={`/products/sub-assembly/${record?.subAssemblyId}`}> {record?.subAssemblyName}</a>
		),
		width: "25%",
	},
	{
		title: "HSN/SAC",
		dataIndex: "subAssemblyHSNCode",
		key: "subAssemblyHSNCode",
		width: "15%",
	},

	{
		title: " Quantity",
		dataIndex: "subAssemblyStock",
		key: "subAssemblyStock",
		width: "3%",
	},
	{
		title: " Price",
		dataIndex: "subAssemblyPrice",
		key: "subAssemblyPrice",
		width: "10px",
	},
	{
		title: "IGST(%)",
		dataIndex: "subAssemblyIGST",
		key: "subAssemblyIGST",
		width: "10px",
	},
	{
		title: "CGST(%)",
		dataIndex: "subAssemblyCGST",
		key: "subAssemblyCGST",
	},
	{
		title: "SGST(%)",
		dataIndex: "subAssemblySGST",
		key: "subAssemblySGST",
	},
	{
		title: "Total with GST",
		dataIndex: "totalAmountIncludeBothGST",
		key: "totalAmountIncludeBothGST",
		width: "20%",
	},
	{
		title: "Total with GST",
		dataIndex: "totalAmountIncludeIGST",
		key: "totalAmountIncludeIGST",
		width: "20%",
	},
];

import  { useMemo, useRef } from "react";
import { LoginOutlined } from "@ant-design/icons";
import { Button, Layout, Menu } from "antd";
import { connect, useSelector } from "react-redux";
import { useMediaQuery } from "react-responsive";
import { NavLink, useLocation, withRouter } from "react-router-dom";
import dataTable from "../../routes/datatable";
import { logoutAdmin } from "../../store/actions/admin/auth.action";
import { Resources, subResources } from "../../utils/constant";
import Center from "../common/Center";
import Spinner from "../common/Spinner";
import Modal from "../Modal/index";
import "./basicLayout.css";

const { Header, Content, Footer, Sider } = Layout;
const { SubMenu } = Menu;

const BasicLayout = (props) => {
	const isLargerThan901px = useMediaQuery({
		query: "(min-device-width: 901px)",
	});

	const drawerIsActive = useSelector((state) => state.other.drawer); //To Grab State of Drawer To hide and show table in basic layout due to scroll bar is shown on drawer if drawer is active

	const { logoutAdmin, isLoading, admin, children } = props;
	const location = useLocation();

	const onLogout = () => {
		logoutAdmin();
	};

	const menuData = useMemo(() => {
		if (admin && admin?.accessLevel && admin?.accessLevel === "superAdmin") {
			return dataTable;
		} else {
			let newDataTable = [];
			let newResources = [];
			for (let index = 0; index < Resources.length; index++) {
				const resource = Resources[index];
				const multipleSubResources = subResources.filter(
					(subResource) => subResource.tree === resource.node
				);
				newResources.push({ ...resource, subResources: multipleSubResources });
			}
			if (admin.template) {
				const { permissions } = admin.template;
				const permissionsArra = Object.keys(permissions);
				for (let index = 0; index < permissionsArra.length; index++) {
					const permission = permissionsArra[index];
					const resource = newResources.find((resource) => resource?.key === permission);
					for (let index = 0; index < dataTable.length; index++) {
						const element = dataTable[index]; // this is menu
						if (resource?.node === element?.title) {
							let newSubMenu = [];
							for (let index = 0; index < resource?.subResources.length; index++) {
								const subResource = resource.subResources[index];
								for (let index = 0; index < permissionsArra.length; index++) {
									const permission = permissionsArra[index];
									if (permissions[permission][subResource?.value]) {
										const subPermissionArr = Object.keys(permissions[permission]);
										for (let index = 0; index < subPermissionArr.length; index++) {
											const singleSubPermission = subPermissionArr[index];
											const subMenuMatched = element?.subMenu.find(
												(subMenu) => subMenu?.value === singleSubPermission
											);
											if (subMenuMatched) {
												if (!newSubMenu?.includes(subMenuMatched)) {
													newSubMenu.push(subMenuMatched);
												}
											}
										}
									}
								}
							}
							newDataTable.push({ ...element, subMenu: newSubMenu });
						}
					}
				}
			}
			return newDataTable;
		}
	}, [admin]);

	const menuKey = useRef([""]);
	const openKey = useRef([""]);
	// eslint-disable-next-line
	menuData.map((menu, menuIndex) => {
		const currentLocation = `/${location.pathname.split("/")[1]}`;
		if (menu.path === currentLocation) {
			// eslint-disable-next-line
			menu.subMenu.map((sub, subMenuIndex) => {
				if (location.pathname.startsWith(sub.path)) {
					menuKey.current = [`${menu.identifier}${subMenuIndex}`];
					openKey.current = [`${menu.identifier}${menuIndex}`];
				}
			});
		}
	});
	const readyToMount = !isLoading && menuKey.current.length > 0 && openKey.current.length > 0;

	const user = admin?.username ? admin?.username : admin?.userName;
	const capitalUser = user?.charAt(0).toUpperCase() + user?.slice(1);

	const userCompanyName = admin?.companyName ? admin?.companyName : "MHASWADKAR AUTOLINES PVT.LTD.";
	const capitalCompanyName = userCompanyName?.toUpperCase();

	if (isLoading) {
		return (
			<Center>
				<Spinner />
			</Center>
		);
	}
	return (
		<>
			{readyToMount && (
				<>
					<Layout className="layout-container" data-testid="layout-container">
						{/* Media Query for Sidebar while screen size is greater than 900px   */}
						{isLargerThan901px ? (
							<Sider theme="light" className="basic-layout-sider width-for-sider">
								<Header>
									<div className="image-container desktop-image-container">
										<img
											src={`${window.location.origin}/assets/image/logo.svg`}
											alt="MAPL Logo"
										></img>
									</div>
								</Header>
								<Menu
									theme="light"
									defaultSelectedKeys={menuKey.current}
									defaultOpenKeys={openKey.current}
									mode="inline"
									className="menu basic-layout-menu"
								>
									{menuData.map((menu, index) => {
										return (
											<SubMenu
												key={menu.identifier + index}
												icon={<menu.icon />}
												title={menu.title}
											>
												{menu.subMenu.map((sub, index) => (
													<Menu.Item key={menu.identifier + index} icon={<sub.icon />}>
														<NavLink to={sub.path}>{sub.title}</NavLink>
													</Menu.Item>
												))}
											</SubMenu>
										);
									})}
								</Menu>
							</Sider>
						) : null}
						<Layout className="site-layout">
							{/* Code For Header Means Logo Of Mapl And Logout Button */}
							<div className="header-user-div-customized">
								{isLargerThan901px ? null : (
									<>
										<Modal
											onLogout={onLogout}
											isLoading={isLoading}
											openKey={openKey}
											menuKey={menuKey}
											menuData={menuData}
										/>

										<div className="image-container">
											<img
												src={`${window.location.origin}/assets/image/logo.svg`}
												alt="MAPL Logo"
											></img>
										</div>
									</>
								)}
								<div style={{ display: "flex" }}>
									<div className="user-feild">
										<h3 className="user-name">{capitalUser}</h3>
										<h4 className="user-companyname">{capitalCompanyName} </h4>
									</div>
								</div>
								<div className="user-logo-and-name-and-logout">
									<Button
										onClick={onLogout}
										type="link"
										loading={isLoading}
										size="large"
										className="user-logout-button"
									>
										<LoginOutlined />
									</Button>
								</div>
							</div>
							{drawerIsActive ? null : (
								<Content className="site-content">
									<div className="site-layout-background">
										<div className="basiclayout-margin">{children}</div>
									</div>
								</Content>
							)}

							<Footer className="footer-text">MAPL ©{new Date().getFullYear()}</Footer>
						</Layout>
					</Layout>
				</>
			)}
		</>
	);
};

const mapStateToProps = (state) => {
	return {
		isLoading: state.admin.isLoading,
		isAuthenticated: state.admin.isAuthenticated,
		admin: state.admin.admin,
	};
};

const mapDispatchToProps = {
	logoutAdmin,
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(BasicLayout));

import { useCallback, useEffect, useMemo } from "react";
import { connect } from "react-redux";
import { Route, Switch, useLocation, withRouter } from "react-router-dom";
import routes from "./routes";
import { me } from "./store/actions/admin/auth.action";
import { checkIfObjectEmpty } from "./utils/helper";
import LoginPage from "./container/auth";
import NotFound404Page from "./container/NotFound404/NotFound404";

const App = (props) => {
	const { isAuthenticated, isLoading, admin, history, me } = props;
	const location = useLocation();
	// useEffect(() => {
	// 	// if (location.pathname !== "/login") {
	// 		const isEmpty = checkIfObjectEmpty(admin);
	// 		if (isEmpty) {
	// 			meRequest()
	// 				.then(() => {
	// 					const path=newRoutes?.length>0?newRoutes[0].path:"/customer/enquiry"

	// 					if (location.pathname === "/") {
	// 						history.push(path);
	// 					}
	// 				})
	// 				.catch(() => {
	// 					if (!isLoading && !isAuthenticated) {
	// 						history.push("/login");
	// 					}
	// 				});
	// 		}
	// 	// }
	// }, [isAuthenticated,newRoutes]); // eslint-disable-line react-hooks/exhaustive-deps

	const newRoutes = useMemo(() => {
		if (admin && admin?.accessLevel && admin?.accessLevel === "superAdmin") {
			return routes;
		} else {
			const permissions = admin?.template?.permissions;
			let tempRoute = [];
			if (permissions) {
				// eslint-disable-next-line array-callback-return
				let newTempRoute = routes?.filter((route) => {
					if (route?.value === "login") {
						return true;
					}
					for (const [key, value] of Object.entries(permissions)) {
						if (route?.menu === key) {
							for (const [subMenuKey, subMenuKeyValue] of Object.entries(value)) {
								if (route?.value === subMenuKey) {
									if (!route?.type) {
										return true;
									}
									if (subMenuKeyValue?.find((element) => element === route?.type)) {
										return true;
									}
								}
							}
						}
					}
				});
				tempRoute = newTempRoute;
			}
			tempRoute.push(
				{ path: "/login", component: LoginPage, exact: true, value: "login" },
				{ component: NotFound404Page, accessLevel: ["superAdmin"] }
			);
			return tempRoute;
		}
	}, [admin]);

	useEffect(() => {
		const isEmpty = checkIfObjectEmpty(admin);
		if (isEmpty) {
			meRequest().catch(() => {
				if (!isLoading && !isAuthenticated) {
					history.push("/login");
				}
			});
		} else {
			const path = newRoutes?.length > 0 ? newRoutes[0].path : "/customer/enquiry";
			if (location.pathname === "/") {
				history.push(path);
			}
		}
	}, [isAuthenticated, newRoutes]); // eslint-disable-line react-hooks/exhaustive-deps

	const meRequest = useCallback(async () => {
		await me();
	}, [me, newRoutes]); // eslint-disable-line react-hooks/exhaustive-deps

	return (
		<Switch>
			{newRoutes?.map((route, i) => (
				<Route
					path={route.path}
					render={(props) => {
						return <route.component accessLevel={route?.accessLevel} {...props} admin={admin} />;
					}}
					exact={route.exact}
					key={i}
				/>
			))}
		</Switch>
	);
};

const mapStateToProps = (state) => {
	return {
		admin: state?.admin?.admin,
		isLoading: state?.admin?.isLoading,
		isAuthenticated: state?.admin?.isAuthenticated,
	};
};

const mapDispatchToProps = { me };

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(App));

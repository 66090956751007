import { useDispatch } from "react-redux";
import { getAdmins, getUsers } from "../../store/actions/admin/auth.action";

export const useGetAdmins = () => {
	const dispatch = useDispatch();

	const getAdmin = async (adminIds) => {
		await dispatch(getAdmins(adminIds));
	};

	const getUser = async (adminIds) => {
		await dispatch(getUsers(adminIds));
	};

	return { getAdmin ,getUser};
};

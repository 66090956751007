export const GET_TEMPLATES_REQUEST = "GET_TEMPLATES_REQUEST",
	GET_TEMPLATES_SUCCESS = "GET_TEMPLATES_SUCCESS",
	GET_TEMPLATES_FAILURE = "GET_TEMPLATES_FAILURE";

export const GET_SINGLE_TEMPLATE_REQUEST = "GET_SINGLE_TEMPLATE_REQUEST",
	GET_SINGLE_TEMPLATE_SUCCESS = "GET_SINGLE_TEMPLATE_SUCCESS",
	GET_SINGLE_TEMPLATE_FAILURE = "GET_SINGLE_TEMPLATE_FAILURE";

export const SOFT_DELETE_SINGLE_TEMPLATE_REQUEST = "SOFT_DELETE_SINGLE_TEMPLATE_REQUEST",
	SOFT_DELETE_SINGLE_TEMPLATE_SUCCESS = "SOFT_DELETE_SINGLE_TEMPLATE_SUCCESS",
	SOFT_DELETE_SINGLE_TEMPLATE_FAILURE = "SOFT_DELETE_SINGLE_TEMPLATE_FAILURE";

export const RESTORE_SINGLE_TEMPLATE_REQUEST = "RESTORE_SINGLE_TEMPLATE_REQUEST",
	RESTORE_SINGLE_TEMPLATE_SUCCESS = "RESTORE_SINGLE_TEMPLATE_SUCCESS",
	RESTORE_SINGLE_TEMPLATE_FAILURE = "RESTORE_SINGLE_TEMPLATE_FAILURE";

export const ADD_TEMPLATE_REQUEST = "ADD_TEMPLATE_REQUEST",
	ADD_TEMPLATE_SUCCESS = "ADD_TEMPLATE_SUCCESS",
	ADD_TEMPLATE_FAILURE = "ADD_TEMPLATE_FAILURE";

export const UPDATE_TEMPLATE_REQUEST = "UPDATE_TEMPLATE_REQUEST",
	UPDATE_TEMPLATE_SUCCESS = "UPDATE_TEMPLATE_SUCCESS",
	UPDATE_TEMPLATE_FAILURE = "UPDATE_TEMPLATE_FAILURE";

import * as serviceCenterApi from "../../../services/serviceCenter";
import Notification from "../../../components/common/Notification";
import {
	GET_SERVICECENTER_CONTACTS_FAILURE,
	GET_SERVICECENTER_CONTACTS_REQUEST,
	GET_SERVICECENTER_CONTACTS_SUCCESS,
	GET_SERVICECENTER_SINGLE_CONTACT_FAILURE,
	GET_SERVICECENTER_SINGLE_CONTACT_REQUEST,
	GET_SERVICECENTER_SINGLE_CONTACT_SUCCESS,
	SOFTDELETE_SERVICECENTER_SINGLE_CONTACT_FAILURE,
	SOFTDELETE_SERVICECENTER_SINGLE_CONTACT_REQUEST,
	SOFTDELETE_SERVICECENTER_SINGLE_CONTACT_SUCCESS,
} from "./serviceCenter.actionType";

export const requestServiceCenterContacts = () => {
	return {
		type: GET_SERVICECENTER_CONTACTS_REQUEST,
	};
};

export const getServiceCenterContactsSuccess = (data) => {
	return {
		type: GET_SERVICECENTER_CONTACTS_SUCCESS,
		data,
	};
};

export const getServiceCenterContactsFailure = (message) => {
	return {
		type: GET_SERVICECENTER_CONTACTS_FAILURE,
		message,
	};
};

export const getServiceCenterContacts = () => {
	return (dispatch) => {
		dispatch(requestServiceCenterContacts());
		return serviceCenterApi
			.serviceCenterContact()
			.then((resp) => {
				const data = resp.data.data;
				dispatch(getServiceCenterContactsSuccess(data));
			})
			.catch((err) => {
				const errorMessage = err.response && err.response.data.message;
				dispatch(getServiceCenterContactsFailure(errorMessage));
			});
	};
};

export const requestServiceCenterSingleContactData = () => {
	return {
		type: GET_SERVICECENTER_SINGLE_CONTACT_REQUEST,
	};
};

export const getServiceCenterSingleContactSuccess = (data) => {
	return {
		type: GET_SERVICECENTER_SINGLE_CONTACT_SUCCESS,
		data,
	};
};

export const getServiceCenterSingleContactFailure = (message) => {
	return {
		type: GET_SERVICECENTER_SINGLE_CONTACT_FAILURE,
		message,
	};
};

export const getServiceCenterSingleContact = (id, softdelete) => {
	return (dispatch) => {
		dispatch(requestServiceCenterSingleContactData());
		return serviceCenterApi
			.serviceCenterSingleContact(id, softdelete)
			.then((resp) => {
				const data = resp.data.data;
				dispatch(getServiceCenterSingleContactSuccess(data));
			})
			.catch((err) => {
				const errorMessage = err.response && err.response.data.message;
				dispatch(getServiceCenterSingleContactFailure(errorMessage));
				throw errorMessage;
			});
	};
};

export const requestServiceCenterSingleContactSoftDelete = () => {
	return {
		type: SOFTDELETE_SERVICECENTER_SINGLE_CONTACT_REQUEST,
	};
};

export const softDeleteServiceCenterSingleContactSuccess = (data) => {
	return {
		type: SOFTDELETE_SERVICECENTER_SINGLE_CONTACT_SUCCESS,
		data,
	};
};

export const softDeleteServiceCenterSingleContactFailure = (message) => {
	return {
		type: SOFTDELETE_SERVICECENTER_SINGLE_CONTACT_FAILURE,
		message,
	};
};

export const softDeleteServiceCenterSingleContact = (id, reason) => {
	return (dispatch) => {
		dispatch(requestServiceCenterSingleContactSoftDelete());
		return serviceCenterApi
			.softDeleteServiceCenterSingleContact(id, reason)
			.then((resp) => {
				dispatch(softDeleteServiceCenterSingleContactSuccess(id));
				Notification("success", resp.data.message, "", "topRight");
			})
			.catch((err) => {
				const errorMessage = err.response && err.response.data.message;
				dispatch(softDeleteServiceCenterSingleContactFailure(errorMessage));
				Notification("error", errorMessage, "", "topRight");
			});
	};
};

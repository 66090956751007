import {
	GET_GALLERY_SUBMISSION_SUCCESS,
	GET_SINGLE_GALLERY_SUBMISSION_SUCCESS,
	DELETE_GALLERY_SUBMISSION_SUCCESS,
	ASSIGN_GALLERY_SUCCESS,
	GET_GALLERY_SUCCESS,
	GET_SINGLE_GALLERY_SUCCESS,
	UPDATE_SINGLE_GALLERY_SUCCESS,
	DELETE_SINGLE_GALLERY_SUCCESS,
	ADD_GALLERY_SUCCESS,
	SOFTDELETE_SINGLE_GALLERY_SUCCESS,
	SOFTDELETE_SINGLE_GALLERY_SUBMISISSION_SUCCESS,
} from "../actions/gallery/gallery.actionType";
import globalState from "../state";

const requestRegex = /_REQUEST/;
const errorRegex = /_FAILURE/;
const successRegex = /_SUCCESS/;

const initialState = {
	gallerySubmissions: [],
	gallerySubmission: {},
	galleries: [],
	gallery: {},
	...globalState,
};

const GalleryReducer = (state = initialState, action) => {
	const isRequest = requestRegex.test(action.type);
	const isError = errorRegex.test(action.type);
	const isSuccess = successRegex.test(action.type);

	if (isRequest) {
		return { ...state, isLoading: true, errorMessage: "" };
	}
	if (isError) {
		return { ...state, isLoading: false, errorMessage: action.message };
	}
	if (isSuccess) {
		state = { ...state, isLoading: false, errorMessage: "" };
	}

	switch (action.type) {
		case GET_GALLERY_SUBMISSION_SUCCESS:
			return {
				...state,
				gallerySubmissions: action.data,
			};
		case GET_SINGLE_GALLERY_SUBMISSION_SUCCESS:
			return {
				...state,
				gallerySubmission: action.data,
			};
		case DELETE_GALLERY_SUBMISSION_SUCCESS:
			const gallerySubmissionDeletionId = action.data;
			const nonDeletedGallerySubmissions = state.gallerySubmissions.filter(
				(gallerySubmission) => gallerySubmission.id !== gallerySubmissionDeletionId
			);
			return {
				...state,
				gallerySubmissions: nonDeletedGallerySubmissions,
			};
		case ASSIGN_GALLERY_SUCCESS:
			return {
				...state,
			};
		case ADD_GALLERY_SUCCESS:
			return {
				...state,
			};
		case GET_GALLERY_SUCCESS:
			return {
				...state,
				galleries: action.data,
			};
		case GET_SINGLE_GALLERY_SUCCESS:
			return {
				...state,
				gallery: action.data,
			};
		case UPDATE_SINGLE_GALLERY_SUCCESS:
			return {
				...state,
			};
		case DELETE_SINGLE_GALLERY_SUCCESS:
			const galleryDeletionId = action.data;
			const nonDeletedGalleries = state.galleries.filter(
				(gallery) => gallery.id !== galleryDeletionId
			);
			return {
				...state,
				galleries: nonDeletedGalleries,
			};

		case SOFTDELETE_SINGLE_GALLERY_SUCCESS:
			const gallerySoftDeletionId = action.data;
			const nonSoftDeletedGalleries = state.galleries.filter(
				(gallery) => gallery.id !== gallerySoftDeletionId
			);
			return {
				...state,
				galleries: nonSoftDeletedGalleries,
			};

		case SOFTDELETE_SINGLE_GALLERY_SUBMISISSION_SUCCESS:
			const gallerySubmissionSoftDeletionId = action.data;
			const nonSoftDeletedGallerySubmissions = state.gallerySubmissions.filter(
				(gallerySubmission) => gallerySubmission.id !== gallerySubmissionSoftDeletionId
			);
			return {
				...state,
				gallerySubmissions: nonSoftDeletedGallerySubmissions,
			};

		default:
			return state;
	}
};

export default GalleryReducer;

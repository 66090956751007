import { invokeApi } from "../utils/api";
import { $axios } from "../utils/axios";

export const getAllBrands = () =>
	invokeApi($axios, "/brand", {}, "GET")
		.then((resp) => resp)
		.catch((err) => {
			throw err;
		});

export const getSingleBrand = (brandId, softdelete) =>
	invokeApi($axios, `/brand/${softdelete === "true" ? "soft-delete/" : ""}${brandId}`, {}, "GET")
		.then((resp) => resp)
		.catch((err) => {
			throw err;
		});

export const softDeleteSingleBrand = (brandId, reason) =>
	invokeApi($axios, `/brand/soft-delete/${brandId}?reason=${reason}`, {}, "DELETE")
		.then((resp) => resp)
		.catch((err) => {
			throw err;
		});

export const restoreBrand = (brandId) =>
	invokeApi($axios, `/brand/restore/${brandId}`, {}, "PUT")
		.then((resp) => resp)
		.catch((err) => {
			throw err;
		});

export const addBrand = (data) =>
	invokeApi($axios, "/brand", data, "POST")
		.then((resp) => resp)
		.catch((err) => {
			throw err;
		});

export const updateBrand = (id, data) =>
	invokeApi($axios, `/brand/${id}`, data, "PUT")
		.then((resp) => resp)
		.catch((err) => {
			throw err;
		});

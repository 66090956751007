import { getMultipleDistricts } from "../../services/place";

export const useIndianMultipleDistricts = (stateIds) => {
	const getIndianMultipleDistricts = async (stateIds) => {
		const data = await getMultipleDistricts(stateIds);
		return data;
	};

	return { getIndianMultipleDistricts };
};

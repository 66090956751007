import { Form, Input, InputNumber } from "antd";
import React from "react";
const InputField = ({
	label,
	name,
	rules,
	placeholder,
	prefix,
	className,
	autoComplete,
	defaultValue,
	style,
	disabled = false,
	inputType = "text",
}) => {
	return (
		<Form.Item label={label} name={name} rules={rules} style={style}>
			{inputType === "password" ? (
				<Input.Password
					placeholder={placeholder}
					prefix={prefix}
					className={className ? className : ""}
					autoComplete={autoComplete ? autoComplete : ""}
				/>
			) : inputType === "number" ? (
				<InputNumber
					defaultValue={defaultValue ? defaultValue : ""}
					disabled={disabled}
					size="medium"
					style={{ width: "100%" }}
				/>
			) : (
				<Input
					type={inputType}
					placeholder={placeholder}
					prefix={prefix}
					className={className ? className : ""}
					autoComplete={autoComplete ? autoComplete : ""}
				/>
			)}
		</Form.Item>
	);
};
export default InputField;

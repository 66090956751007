export const GET_CUSTOMER_CONTACTS_REQUEST = "GET_CUSTOMER_CONTACTS_REQUEST",
	GET_CUSTOMER_CONTACTS_SUCCESS = "GET_CUSTOMER_CONTACTS_SUCCESS",
	GET_CUSTOMER_CONTACTS_FAILURE = "GET_CUSTOMER_CONTACTS_FAILURE";

export const GET_CUSTOMER_SINGLE_CONTACT_REQUEST = "GET_CUSTOMER_SINGLE_CONTACT_REQUEST",
	GET_CUSTOMER_SINGLE_CONTACT_SUCCESS = "GET_CUSTOMER_SINGLE_CONTACT_SUCCESS",
	GET_CUSTOMER_SINGLE_CONTACT_FAILURE = "GET_CUSTOMER_SINGLE_CONTACT_FAILURE";

export const SOFTDELETE_CUSTOMER_SINGLE_CONTACT_REQUEST =
		"SOFTDELETE_CUSTOMER_SINGLE_CONTACT_REQUEST",
	SOFTDELETE_CUSTOMER_SINGLE_CONTACT_SUCCESS = "SOFTDELETE_CUSTOMER_SINGLE_CONTACT_SUCCESS",
	SOFTDELETE_CUSTOMER_SINGLE_CONTACT_FAILURE = "SOFTDELETE_CUSTOMER_SINGLE_CONTACT_FAILURE";

export const RESTORE_CUSTOMER_SINGLE_CONTACT_REQUEST = "RESTORE_CUSTOMER_SINGLE_CONTACT_REQUEST",
	RESTORE_CUSTOMER_SINGLE_CONTACT_SUCCESS = "RESTORE_CUSTOMER_SINGLE_CONTACT_SUCCESS",
	RESTORE_CUSTOMER_SINGLE_CONTACT_FAILURE = "RESTORE_CUSTOMER_SINGLE_CONTACT_FAILURE";

export const GET_CUSTOMERS_INFO_REQUEST = "GET_CUSTOMERS_INFO_REQUEST",
	GET_CUSTOMERS_INFO_SUCCESS = "GET_CUSTOMERS_INFO_SUCCESS",
	GET_CUSTOMERS_INFO_FAILURE = "GET_CUSTOMERS_INFO_FAILURE";

export const GET_SINGLE_CUSTOMER_REQUEST = "GET_SINGLE_CUSTOMER_REQUEST",
	GET_SINGLE_CUSTOMER_SUCCESS = "GET_SINGLE_CUSTOMER_SUCCESS",
	GET_SINGLE_CUSTOMER_FAILURE = "GET_SINGLE_CUSTOMER_FAILURE";

export const SOFTDELETE_SINGLE_CUSTOMER_REQUEST = "SOFTDELETE_SINGLE_CUSTOMER_REQUEST",
	SOFTDELETE_SINGLE_CUSTOMER_SUCCESS = "SOFTDELETE_SINGLE_CUSTOMER_SUCCESS",
	SOFTDELETE_SINGLE_CUSTOMER_FAILURE = "SOFTDELETE_SINGLE_CUSTOMER_FAILURE";

export const UPDATE_SINGLE_CUSTOMER_REQUEST = "UPDATE_SINGLE_CUSTOMER_REQUEST",
	UPDATE_SINGLE_CUSTOMER_SUCCESS = "UPDATE_SINGLE_CUSTOMER_SUCCESS",
	UPDATE_SINGLE_CUSTOMER_FAILURE = "UPDATE_SINGLE_CUSTOMER_FAILURE";

export const ADD_CUSTOMER_ENQUIRY_REQUEST = "ADD_CUSTOMER_ENQUIRY_REQUEST",
	ADD_CUSTOMER_ENQUIRY_SUCCESS = "ADD_CUSTOMER_ENQUIRY_SUCCESS",
	ADD_CUSTOMER_ENQUIRY_FAILURE = "ADD_CUSTOMER_ENQUIRY_FAILURE";

export const UPDATE_CUSTOMER_ENQUIRY_REQUEST = "UPDATE_CUSTOMER_ENQUIRY_REQUEST",
	UPDATE_CUSTOMER_ENQUIRY_SUCCESS = "UPDATE_CUSTOMER_ENQUIRY_SUCCESS",
	UPDATE_CUSTOMER_ENQUIRY_FAILURE = "UPDATE_CUSTOMER_ENQUIRY_FAILURE";

import React from "react";
import { Form, Radio } from "antd";

const index = ({ label, name, rules, options, ...props }) => {
	const noChange = () => {};
	return (
		<Form.Item name={name} label={label} rules={rules}>
			<Radio.Group>
				{options.map((option, index) => (
					<Radio value={option.value} key={index} onChange={props.onChange || noChange}>
						{option.key}
					</Radio>
				))}
			</Radio.Group>
		</Form.Item>
	);
};

export default index;

import { invokeApi } from "../utils/api";
import { $axios } from "../utils/axios";

export const getJob = () =>
	invokeApi($axios, "/careers/jobs", {}, "GET")
		.then((resp) => resp)
		.catch((err) => {
			throw err;
		});

export const getSingleJob = (id, softdelete) =>
	invokeApi($axios, `/careers/jobs/${softdelete === "true" ? "soft-delete/" : ""}${id}`, {}, "GET")
		.then((resp) => resp)
		.catch((err) => {
			throw err;
		});

export const updateSingleJob = (id, data) =>
	invokeApi($axios, `/careers/jobs/${id}`, data, "PUT", false)
		.then((resp) => resp)
		.catch((err) => {
			throw err;
		});

export const deleteSingleJob = (id) =>
	invokeApi($axios, `/careers/jobs/${id}`, {}, "DELETE", false)
		.then((resp) => resp)
		.catch((err) => {
			throw err;
		});

export const softDeleteSingleJob = (id, reason) =>
	invokeApi($axios, `/careers/jobs/soft-delete/${id}?reason=${reason}`, {}, "DELETE", false)
		.then((resp) => resp)
		.catch((err) => {
			throw err;
		});
export const addJob = (data) =>
	invokeApi($axios, "/careers/jobs", data, "POST", false)
		.then((resp) => resp)
		.catch((err) => {
			throw err;
		});

// For Applications
export const getApplication = () =>
	invokeApi($axios, "/careers/applications", {}, "GET")
		.then((resp) => resp)
		.catch((err) => {
			throw err;
		});

export const getSingleApplication = (id, softdelete) =>
	invokeApi(
		$axios,
		`/careers/applications/${softdelete === "true" ? "soft-delete/" : ""}${id}`,
		{},
		"GET"
	)
		.then((resp) => resp)
		.catch((err) => {
			throw err;
		});

export const softDeleteSingleApplication = (id, reason) =>
	invokeApi($axios, `/careers/applications/soft-delete/${id}?reason=${reason}`, {}, "DELETE", false)
		.then((resp) => resp)
		.catch((err) => {
			throw err;
		});

export const getCategories = () =>
	invokeApi($axios, "/careers/category", {}, "GET")
		.then((resp) => resp)
		.catch((err) => {
			throw err;
		});

export const getSingleCategory = (id, softdelete) =>
	invokeApi(
		$axios,
		`/careers/category/${softdelete === "true" ? "soft-delete/" : ""}${id}`,
		{},
		"GET"
	)
		.then((resp) => resp)
		.catch((err) => {
			throw err;
		});

export const addCategory = (data) =>
	invokeApi($axios, "/careers/category", data, "POST")
		.then((resp) => resp)
		.catch((err) => {
			throw err;
		});

export const updateSingleCategory = (id, data) =>
	invokeApi($axios, `/careers/category/${id}`, data, "PUT")
		.then((resp) => resp)
		.catch((err) => {
			throw err;
		});

export const deleteSingleCategory = (id) =>
	invokeApi($axios, `/careers/category/${id}`, {}, "DELETE")
		.then((resp) => resp)
		.catch((err) => {
			throw err;
		});

export const softDeleteSingleCategory = (id, reason) =>
	invokeApi($axios, `/careers/category/soft-delete/${id}?reason=${reason}`, {}, "DELETE")
		.then((resp) => resp)
		.catch((err) => {
			throw err;
		});

export const GET_MODELS_REQUEST = "GET_MODELS_REQUEST",
    GET_MODELS_SUCCESS = "GET_MODELS_SUCCESS",
    GET_MODELS_FAILURE = "GET_MODELS_FAILURE";

export const GET_SINGLE_MODEL_REQUEST = "GET_SINGLE_MODEL_REQUEST",
    GET_SINGLE_MODEL_SUCCESS = "GET_SINGLE_MODEL_SUCCESS",
    GET_SINGLE_MODEL_FAILURE = "GET_SINGLE_MODEL_FAILURE";

export const SOFT_DELETE_SINGLE_MODEL_REQUEST =
        "SOFT_DELETE_SINGLE_MODEL_REQUEST",
    SOFT_DELETE_SINGLE_MODEL_SUCCESS = "SOFT_DELETE_SINGLE_MODEL_SUCCESS",
    SOFT_DELETE_SINGLE_MODEL_FAILURE = "SOFT_DELETE_SINGLE_MODEL_FAILURE";

export const RESTORE_SINGLE_MODEL_REQUEST = "RESTORE_SINGLE_MODEL_REQUEST",
    RESTORE_SINGLE_MODEL_SUCCESS = "RESTORE_SINGLE_MODEL_SUCCESS",
    RESTORE_SINGLE_MODEL_FAILURE = "RESTORE_SINGLE_MODEL_FAILURE";

export const ADD_MODEL_REQUEST = "ADD_MODEL_REQUEST",
    ADD_MODEL_SUCCESS = "ADD_MODEL_SUCCESS",
    ADD_MODEL_FAILURE = "ADD_MODEL_FAILURE";

export const UPDATE_MODEL_REQUEST = "UPDATE_MODEL_REQUEST",
    UPDATE_MODEL_SUCCESS = "UPDATE_MODEL_SUCCESS",
    UPDATE_MODEL_FAILURE = "UPDATE_MODEL_FAILURE";

export const MAKE_SINGLE_MODEL_EMPTY = "MAKE_SINGLE_MODEL_EMPTY";


import { Card, Image, message } from "antd";
import { useEffect,useMemo } from "react";
import { ErrorBoundary } from "react-error-boundary";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import BasicLayout from "../../../components/BasicLayout";
import FallBack from "../../../components/common/FallBack/FallBack";
import Notification from "../../../components/common/Notification";
import { CustomTab } from "../../../components/common/Tab/Tab";
import { getSingleGallery } from "../../../store/actions/gallery/gallery.action";
import { checkIfObjectEmpty } from "../../../utils/helper";
import "./index.css";

const ViewAddedGallery = ({ accessLevel, ...props }) => {
	const galleryId = +props.match.params.id;

	const dispatch = useDispatch();
	const gallery = useSelector((state) => state.gallery.gallery || {});
	const isEmpty = checkIfObjectEmpty(gallery);
	const admin = useSelector((state) => state.admin.admin || []);

	const permissionArr = useMemo(() => {
		if (admin && admin.template) {
			const { permissions } = admin.template;
			return permissions;
		}
		return [];
	}, [admin]);
	const history = useHistory();
	const location = useLocation();
	const softdelete = new URLSearchParams(location.search).get("soft-delete");

	useEffect(() => {
		dispatch(getSingleGallery(galleryId, softdelete)).catch((err) => {
			Notification("error", err, "", "topRight");
			history.push("/gallery/added");
		});
	}, [dispatch, history, softdelete, galleryId]);

	let data = [];
	let isOrderEmpty = Object.keys(gallery).length === 0;
	if (!isOrderEmpty) {
		data = [
			{ title: "Id", description: gallery.id },
			{
				title: "Name",
				description: gallery.name,
			},
			{
				title: "Category",
				description: gallery.category,
			},
			{
				title: "Received",
				description: gallery.createdAt,
			},
			{
				title: gallery?.type === "image" ? "Image" : "Video",
				description: (
					<div className="gallery-images">
						{!isEmpty && gallery?.type === "image" ? (
							<div className="image-holder-container">
								<Image width={250} height={180} src={gallery.url} />
							</div>
						) : (
							<video width="320" height="240" controls>
								<source src={gallery.url} type="video/mp4"></source>
							</video>
						)}
					</div>
				),
			},
		];
	}
	// Error Boundary Error Handling
	const handleError = (error) => {
		message.error(error.message);
	};
	let operationData = {
		deletedBy: gallery?.deletedBy,
		updatedBy: gallery?.updatedBy,
		restoredBy: gallery?.restoredBy,
	};
	return (
		<BasicLayout accessLevel={accessLevel}>
			<ErrorBoundary FallbackComponent={FallBack} onError={handleError}>
				<Card className="innovation-detail-card" data-testid="added-gallery-detail-card">
					<CustomTab
					update={
						permissionArr === "all"
							? "true"
							: permissionArr?.gallery?.added.filter((item) => item === "update")?.length > 0
							? "true"
							: false
					}
						data={data}
						operationData={operationData}
						redirectUrl={`/gallery/added/update/${galleryId}`}
					/>
				</Card>
			</ErrorBoundary>
		</BasicLayout>
	);
};

export default ViewAddedGallery;

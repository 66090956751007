export const GET_NEWSLETTER_REQUEST = "GET_NEWSLETTER_REQUEST",
	GET_NEWSLETTER_SUCCESS = "GET_NEWSLETTER_SUCCESS",
	GET_NEWSLETTER_FAILURE = "GET_NEWSLETTER_FAILURE";

export const SOFTDELETE_SINGLE_NEWSLETTER_REQUEST = "SOFTDELETE_SINGLE_NEWSLETTER_REQUEST",
	SOFTDELETE_SINGLE_NEWSLETTER_SUCCESS = "SOFTDELETE_SINGLE_NEWSLETTER_SUCCESS",
	SOFTDELETE_SINGLE_NEWSLETTER_FAILURE = "SOFTDELETE_SINGLE_NEWSLETTER_FAILURE";

export const GET_INNOVATION_REQUEST = "GET_INNOVATION_REQUEST",
	GET_INNOVATION_SUCCESS = "GET_INNOVATION_SUCCESS",
	GET_INNOVATION_FAILURE = "GET_INNOVATION_FAILURE";

export const GET_SINGLE_INNOVATION_REQUEST = "GET_SINGLE_INNOVATION_REQUEST",
	GET_SINGLE_INNOVATION_SUCCESS = "GET_SINGLE_INNOVATION_SUCCESS",
	GET_SINGLE_INNOVATION_FAILURE = "GET_SINGLE_INNOVATION_FAILURE";

export const SOFTDELETE_SINGLE_INNOVATION_REQUEST = "SOFTDELETE_SINGLE_INNOVATION_REQUEST",
	SOFTDELETE_SINGLE_INNOVATION_SUCCESS = "SOFTDELETE_SINGLE_INNOVATION_SUCCESS",
	SOFTDELETE_SINGLE_INNOVATION_FAILURE = "SOFTDELETE_SINGLE_INNOVATION_FAILURE";

export const GET_SOFT_DELETED_ITEMS_REQUEST = "GET_SOFT_DELETED_ITEMS_REQUEST",
	GET_SOFT_DELETED_ITEMS_SUCCESS = "GET_SOFT_DELETED_ITEMS_SUCCESS",
	GET_SOFT_DELETED_ITEMS_FAILURE = "GET_SOFT_DELETED_ITEMS_FAILURE";

export const PERMANENT_DELETE_ITEM_REQUEST = "PERMANENT_DELETE_ITEM_REQUEST",
	PERMANENT_DELETE_ITEM_SUCCESS = "PERMANENT_DELETE_ITEM_SUCCESS",
	PERMANENT_DELETE_ITEM_FAILURE = "PERMANENT_DELETE_ITEM_FAILURE";

export const RESTORE_ITEM_REQUEST = "RESTORE_ITEM_REQUEST",
	RESTORE_ITEM_SUCCESS = "RESTORE_ITEM_SUCCESS",
	RESTORE_ITEM_FAILURE = "RESTORE_ITEM_FAILURE";

export const GET_DRAWER_TOGGLE = "GET_DRAWER_TOGGLE";
export const SET_DRAWER_DEFAULT = "SET_DRAWER_DEFAULT";

export const UPDATE_DELETED_ITEMS_AS_EMPTY_SUCCESS = "UPDATE_DELETED_ITEMS_AS_EMPTY_SUCCESS";

import {
	GET_ALL_DISTRICTS_SUCCESS,
	GET_ALL_SUB_DISTRICTS_SUCCESS,
	GET_DISTRICTS_SUCCESS,
	GET_STATES_SUCCESS,
	GET_SUB_DISTRICTS_SUCCESS,
} from "../actions/place/place.actionType";
import globalState from "../state";

const requestRegex = /_REQUEST/;
const errorRegex = /_FAILURE/;
const successRegex = /_SUCCESS/;

const initialState = {
	errorMessage: "",
	isLoading: false,
	...globalState,
	states: [],
	districts: [],
	subDistricts: [],
	allDistricts: [],
	allSubDistricts: [],
};

const PlaceReducer = (state = initialState, action) => {
	const isRequest = requestRegex.test(action.type);
	const isError = errorRegex.test(action.type);
	const isSuccess = successRegex.test(action.type);

	if (isRequest) {
		return { ...state, isLoading: true, errorMessage: "" };
	}
	if (isError) {
		return { ...state, isLoading: false, errorMessage: action.message };
	}
	if (isSuccess) {
		state = { ...state, isLoading: false, errorMessage: "" };
	}

	switch (action.type) {
		case GET_STATES_SUCCESS:
			return { ...state, states: action.data };
		case GET_DISTRICTS_SUCCESS:
			return { ...state, districts: action.data };

		case GET_SUB_DISTRICTS_SUCCESS:
			return { ...state, subDistricts: action.data };
		case GET_ALL_DISTRICTS_SUCCESS:
			return { ...state, allDistricts: action.data };

		case GET_ALL_SUB_DISTRICTS_SUCCESS:
			return { ...state, allSubDistricts: action.data };

		default:
			return state;
	}
};

export default PlaceReducer;

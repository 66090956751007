import { invokeApi } from "../utils/api";
import { $axios } from "../utils/axios";

export const getAllAccessories = () =>
	invokeApi($axios, "/product/accessory", {}, "GET")
		.then((resp) => resp)
		.catch((err) => {
			throw err;
		});

export const getSingleAccessory = (accessoryId, softdelete) =>
	invokeApi(
		$axios,
		`/product/accessory/${softdelete === "true" ? "soft-delete/" : ""}${accessoryId}`,
		{},
		"GET"
	)
		.then((resp) => resp)
		.catch((err) => {
			throw err;
		});

export const softDeleteSingleAccessory = (accessoryId, reason) =>
	invokeApi($axios, `/product/accessory/soft-delete/${accessoryId}?reason=${reason}`, {}, "DELETE")
		.then((resp) => resp)
		.catch((err) => {
			throw err;
		});

export const restoreAccessory = (accessoryId) =>
	invokeApi($axios, `/product/accessory/restore/${accessoryId}`, {}, "PUT")
		.then((resp) => resp)
		.catch((err) => {
			throw err;
		});

export const addAccessory = (data) =>
	invokeApi($axios, "/product/accessory", data, "POST")
		.then((resp) => resp)
		.catch((err) => {
			throw err;
		});

export const updateAccessory = (id, data) =>
	invokeApi($axios, `/product/accessory/${id}`, data, "PUT")
		.then((resp) => resp)
		.catch((err) => {
			throw err;
		});

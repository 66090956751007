import { Card, message } from "antd";
import { useMemo,useEffect } from "react";
import { ErrorBoundary } from "react-error-boundary";
import HtmlParser from "react-html-parser";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import BasicLayout from "../../../components/BasicLayout/index";
import FallBack from "../../../components/common/FallBack/FallBack";
import Notification from "../../../components/common/Notification";
import { CustomTab } from "../../../components/common/Tab/Tab";
import { getSingleJob } from "../../../store/actions/Careers/careers.action";
import "./jobs.css";

const ViewJobs = (props) => {
	const jobsId = +props.match.params.id;
	const dispatch = useDispatch();
	const jobs = useSelector((state) => state.careers.job || {});

	const location = useLocation();
	const softdelete = new URLSearchParams(location.search).get("soft-delete");
	const history = useHistory();
	const admin = useSelector((state) => state.admin.admin || []);

	const permissionArr = useMemo(() => {
		if (admin && admin.template) {
			const { permissions } = admin.template;
			return permissions;
		} else if (admin && admin.accessLevel === "superAdmin") {
			return "all";
		}
		return [];
	}, [admin]);

	useEffect(() => {
		dispatch(getSingleJob(jobsId, softdelete)).catch((err) => {
			Notification("error", err, "", "topRight");
			history.push("/careers/jobs");
		});
	}, [history, softdelete, jobsId, dispatch]);

	let data = [];
	let isOrderEmpty = Object.keys(jobs).length === 0;
	if (!isOrderEmpty) {
		data = [
			{ title: "Id", description: jobs.id },
			{
				title: "Position",
				description: jobs.position,
			},
			{
				title: "Description",
				description: HtmlParser(jobs.description),
			},
			{
				title: "Category",
				description: jobs?.category?.title,
			},
			{
				title: "Experience Required",
				description: jobs.experience + " " + jobs.experienceUnit,
			},
			{
				title: "Type",
				description: jobs.type,
			},
			{
				title: "Received",
				description: jobs.createdAt,
			},
		];
	}
	const handleError = (error) => {
		message.error(error.message);
	};
	let operationData = {
		deletedBy: jobs?.deletedBy,
		updatedBy: jobs?.updatedBy,
		restoredBy: jobs?.restoredBy,
	};
	return (
		<BasicLayout accessLevel={props.accessLevel}>
			<ErrorBoundary FallbackComponent={FallBack} onError={handleError}>
				<Card className="job-detail-card" data-testid="job-detail-card">
					<CustomTab
					update={
						permissionArr === "all"
							? "true"
							: permissionArr?.career?.jobs.filter((item) => item === "update")?.length > 0
							? "true"
							: false
					}
						data={data}
						operationData={operationData}
						redirectUrl={`/careers/jobs/update/${jobsId}`}
					/>
				</Card>
			</ErrorBoundary>
		</BasicLayout>
	);
};

export default ViewJobs;

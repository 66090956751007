import React from "react";
import styles from "./TechnicalSpecsDetailsTable.module.css";

export function TechnicalSpecsDetailsTable({ technicalName }) {
	return (
		<table className={styles.table}>
			<tbody>
				<tr>
					<th className={styles.th}>Name</th>
					<th className={styles.th}>Value</th>
				</tr>
				{technicalName?.map((item) => {
					if (item && Object.keys(item).length > 0) {
						for (const [prop, val] of Object.entries(item)) {
							return (
								<tr key={prop}>
									<td className={styles.td}>{prop}</td>
									<td className={styles.td}>{val}</td>
								</tr>
							);
						}
					}
					return item;
				})}
			</tbody>
		</table>
	);
}

import { Card, message } from "antd";
import React from "react";
import { ErrorBoundary } from "react-error-boundary";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import BasicLayout from "../../../components/BasicLayout";
import FallBack from "../../../components/common/FallBack/FallBack";
import Notification from "../../../components/common/Notification";
import { CustomTab } from "../../../components/common/Tab/Tab";
import { getSingleInnovation } from "../../../store/actions/other/other.action";
import { checkIfObjectEmpty, isEqual } from "../../../utils/helper";
import "./index.css";

const ViewInnovation = (props) => {
	const innovationId = +props.match.params.id;
	const dispatch = useDispatch();
	const innovation = useSelector((state) => state.other.innovation || {});

	const location = useLocation();
	const softdelete = new URLSearchParams(location.search).get("soft-delete");
	const history = useHistory();

	const isEmpty = checkIfObjectEmpty(innovation);
	React.useEffect(() => {
		if (isEmpty || !isEqual(innovation?.id, innovationId)) {
			dispatch(getSingleInnovation(innovationId, softdelete)).catch((err) => {
				Notification("error", err, "", "topRight");
				history.push("/other/innovation");
			});
		}
	}, [dispatch, isEmpty, history, softdelete, innovationId, innovation?.id]);

	let data = [];
	let isOrderEmpty = Object.keys(innovation).length === 0;
	if (!isOrderEmpty) {
		data = [
			{ title: "Id", description: innovation.id },
			{
				title: "Name",
				description: innovation.name,
			},
			{
				title: "Phone Number",
				description: innovation.contact,
			},
			{
				title: "State",
				description: innovation.state,
			},
			{
				title: "District",
				description: innovation.district,
			},
			{
				title: "Taluka",
				description: innovation.taluka || "NA",
			},
			{
				title: "Village",
				description: innovation.village,
			},
			{
				title: "Email",
				description: innovation.email,
			},
			{
				title: "Description",
				description: innovation.description,
			},
			{
				title: "Files",
				description:
					!isEmpty &&
					innovation.urls.map((url, index) => (
						<a href={url} key={index} target="_blank" rel="noreferrer">
							File {index + 1}
							{index !== innovation.urls.length - 1 && ", "}
						</a>
					)),
			},
			{
				title: "Received",
				description: new Date(innovation.createdAt).toLocaleString(),
			},
		];
	}

	// Error Boundary Error Handling
	const handleError = (error) => {
		message.error(error.message);
	};
	let operationData = {
		deletedBy: innovation?.deletedBy,
		restoredBy: innovation?.restoredBy,
	};
	return (
		<BasicLayout accessLevel={props.accessLevel}>
			<ErrorBoundary FallbackComponent={FallBack} onError={handleError}>
				<Card className="innovation-detail-card" data-testid="innovation-detail-card">
					<CustomTab data={data} operationData={operationData} redirectUrl={``} update={false} />
				</Card>
			</ErrorBoundary>
		</BasicLayout>
	);
};

export default ViewInnovation;

import { invokeApi } from "../utils/api";
import { $axios } from "../utils/axios";

export const customerContact = () =>
	invokeApi($axios, "/customer/contact", {}, "GET")
		.then((resp) => resp)
		.catch((err) => {
			throw err;
		});

export const customerSingleContact = (id, softdelete) =>
	invokeApi(
		$axios,
		`/customer/contact/${softdelete === "true" ? "soft-delete/" : ""}${id}`,
		{},
		"GET"
	)
		.then((resp) => resp)
		.catch((err) => {
			throw err;
		});

export const customerSingleContactSoftDelete = (id, reason) =>
	invokeApi($axios, `/customer/contact/soft-delete/${id}?reason=${reason}`, {}, "DELETE")
		.then((resp) => resp)
		.catch((err) => {
			throw err;
		});

export const customerSingleContactRestore = (id) =>
	invokeApi($axios, `/customer/contact/restore/${id}`, {}, "PUT")
		.then((resp) => resp)
		.catch((err) => {
			throw err;
		});

export const customersInfo = () =>
	invokeApi($axios, "/customer", {}, "GET")
		.then((resp) => resp)
		.catch((err) => {
			throw err;
		});

export const getSingleCustomer = (id, softdelete) =>
	invokeApi($axios, `/customer/${softdelete === "true" ? "soft-delete/" : ""}${id}`, {}, "GET")
		.then((resp) => resp)
		.catch((err) => {
			throw err;
		});

export const singleCustomerSoftDelete = (id, reason) =>
	invokeApi($axios, `/customer/soft-delete/${id}?reason=${reason}`, {}, "DELETE")
		.then((resp) => resp)
		.catch((err) => {
			throw err;
		});

export const updateSingleCustomer = (id, data) =>
	invokeApi($axios, `/customer/${id}`, data, "PUT")
		.then((resp) => resp)
		.catch((err) => {
			throw err;
		});

export const addCustomerEnquiry = (data) =>
	invokeApi($axios, "customer/admin/enquiry", data, "POST", false)
		.then((resp) => resp)
		.catch((err) => {
			throw err;
		});

export const updateCustomerEnquiry = (enquiryId, data) =>
	invokeApi($axios, `customer/admin/enquiry/${enquiryId}`, data, "PUT")
		.then((resp) => resp)

		.catch((err) => {
			throw err;
		});

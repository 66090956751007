import Notification from "../../../../components/common/Notification";
import {
	ADD_BRAND_FAILURE,
	ADD_BRAND_REQUEST,
	ADD_BRAND_SUCCESS,
	GET_BRANDS_FAILURE,
	GET_BRANDS_REQUEST,
	GET_BRANDS_SUCCESS,
	GET_SINGLE_BRAND_FAILURE,
	GET_SINGLE_BRAND_REQUEST,
	GET_SINGLE_BRAND_SUCCESS,
	RESTORE_SINGLE_BRAND_FAILURE,
	RESTORE_SINGLE_BRAND_REQUEST,
	RESTORE_SINGLE_BRAND_SUCCESS,
	SOFT_DELETE_SINGLE_BRAND_FAILURE,
	SOFT_DELETE_SINGLE_BRAND_REQUEST,
	SOFT_DELETE_SINGLE_BRAND_SUCCESS,
	UPDATE_BRAND_FAILURE,
	UPDATE_BRAND_REQUEST,
	UPDATE_BRAND_SUCCESS,
} from "./brands.actionType";
import * as brandApi from "../../../../services/brands";

// GET REQUEST
export const requestGetAllBrands = () => {
	return {
		type: GET_BRANDS_REQUEST,
	};
};
export const getAllBrandsSuccess = (data) => {
	return {
		type: GET_BRANDS_SUCCESS,
		data,
	};
};
export const getAllBrandsFailure = (message) => {
	return {
		type: GET_BRANDS_FAILURE,
		message,
	};
};

// Get All Brands
export const getAllBrands = () => {
	return (dispatch) => {
		dispatch(requestGetAllBrands());
		return brandApi
			.getAllBrands()
			.then((resp) => {
				const data = resp.data.data;
				dispatch(getAllBrandsSuccess(data));
			})
			.catch((err) => {
				const errorMessage =
					(err?.response && err?.response?.data?.message) || "Something went wrong!";
				dispatch(getAllBrandsFailure(errorMessage));
			});
	};
};

export const requestGetSingleBrand = () => {
	return {
		type: GET_SINGLE_BRAND_REQUEST,
	};
};

export const getSingleBrandSuccess = (data) => {
	return {
		type: GET_SINGLE_BRAND_SUCCESS,
		data,
	};
};

export const getSingleBrandFailure = (message) => {
	return {
		type: GET_SINGLE_BRAND_FAILURE,
		message,
	};
};

export const getSingleBrand = (id, softdelete) => {
	return (dispatch) => {
		dispatch(requestGetSingleBrand());
		return brandApi
			.getSingleBrand(id, softdelete)
			.then((resp) => {
				const data = resp.data.data;
				dispatch(getSingleBrandSuccess(data));
			})
			.catch((err) => {
				const errorMessage =
					(err?.response && err?.response?.data?.message) || "Something went wrong";
				dispatch(getSingleBrandFailure(errorMessage));
				throw errorMessage;
			});
	};
};

export const requestSoftDeleteSingleBrand = () => {
	return {
		type: SOFT_DELETE_SINGLE_BRAND_REQUEST,
	};
};

export const softDeleteSingleBrandSuccess = (data) => {
	return {
		type: SOFT_DELETE_SINGLE_BRAND_SUCCESS,
		data,
	};
};

export const softDeleteSingleBrandFailure = (message) => {
	return {
		type: SOFT_DELETE_SINGLE_BRAND_FAILURE,
		message,
	};
};

export const softDeleteSingleBrand = (id, reason) => {
	return (dispatch) => {
		dispatch(requestSoftDeleteSingleBrand());
		return brandApi
			.softDeleteSingleBrand(id, reason)
			.then((resp) => {
				dispatch(softDeleteSingleBrandSuccess(id));
				return Notification("success", resp.data.message, "", "topRight");
			})
			.catch((err) => {
				const errorMessage =
					(err?.response && err?.response?.data?.message) || "Something went wrong";
				dispatch(softDeleteSingleBrandFailure(errorMessage));
				return Notification("error", errorMessage, "", "topRight");
			});
	};
};

export const requestBrandRestore = () => {
	return {
		type: RESTORE_SINGLE_BRAND_REQUEST,
	};
};

export const restoreBrandSuccess = () => {
	return {
		type: RESTORE_SINGLE_BRAND_SUCCESS,
	};
};

export const restoreBrandFailure = (message) => {
	return {
		type: RESTORE_SINGLE_BRAND_FAILURE,
		message,
	};
};

export const restoreBrand = (id) => {
	return (dispatch) => {
		dispatch(requestBrandRestore());
		return brandApi
			.restoreBrand(id)
			.then((resp) => {
				dispatch(restoreBrandSuccess(id));
				Notification("success", resp.data.message, "", "topRight");
			})
			.catch((err) => {
				const errorMessage = (err.response && err.response.data.message) || "Something went wrong!";
				dispatch(restoreBrandFailure(errorMessage));
			});
	};
};

export const requestBrandAdd = () => {
	return {
		type: ADD_BRAND_REQUEST,
	};
};

export const brandAddSuccess = (data) => {
	return {
		type: ADD_BRAND_SUCCESS,
		data,
	};
};

export const brandAddFailure = (message) => {
	return {
		type: ADD_BRAND_FAILURE,
		message,
	};
};

export const addBrand = (data) => {
	return (dispatch) => {
		dispatch(requestBrandAdd());
		return brandApi
			.addBrand(data)
			.then((resp) => {
				const data = resp.data.data;

				return data;
			})
			.catch((err) => {
				const errorMessage = err.response && err.response.data.message;
				dispatch(brandAddFailure(errorMessage));
				Notification("error", "Error Occurred", errorMessage);
			});
	};
};

//FOR UPDATE Brand
export const requestUpdateBrand = () => {
	return {
		type: UPDATE_BRAND_REQUEST,
	};
};

export const updateBrandSuccess = (data) => {
	return {
		type: UPDATE_BRAND_SUCCESS,
		data,
	};
};

export const updateBrandFailure = (message) => {
	return {
		type: UPDATE_BRAND_FAILURE,
		message,
	};
};

export const updateBrand = (id, data) => {
	return (dispatch) => {
		dispatch(requestUpdateBrand());
		return brandApi
			.updateBrand(id, data)
			.then((resp) => {
				const data = resp.data;
				return data;
			})
			.catch((err) => {
				const errorMessage = err.response && err.response.data.message;
				dispatch(updateBrandFailure(errorMessage));
				throw err;
			});
	};
};

import {
	GET_ACCESSORIES_SUCCESS,
	GET_SINGLE_ACCESSORY_SUCCESS,
	RESTORE_SINGLE_ACCESSORY_SUCCESS,
	SOFT_DELETE_SINGLE_ACCESSORY_SUCCESS,
} from "../actions/products/Accessory/accessory.actionType";
import globalState from "../state";

const requestRegex = /_REQUEST/;
const errorRegex = /_FAILURE/;
const successRegex = /_SUCCESS/;

const initialState = {
	errorMessage: "",
	isLoading: false,
	accessories: [],
	accessory: {},
	...globalState,
};

const AccessoryReducer = (state = initialState, action) => {
	const isRequest = requestRegex.test(action.type);
	const isError = errorRegex.test(action.type);
	const isSuccess = successRegex.test(action.type);

	if (isRequest) {
		return { ...state, isLoading: true, errorMessage: "" };
	}
	if (isError) {
		return { ...state, isLoading: false, errorMessage: action.message };
	}
	if (isSuccess) {
		state = { ...state, isLoading: false, errorMessage: "" };
	}

	switch (action.type) {
		case GET_ACCESSORIES_SUCCESS:
			return { ...state, accessories: action.data };

		case GET_SINGLE_ACCESSORY_SUCCESS:
			return { ...state, accessory: action.data };

		case SOFT_DELETE_SINGLE_ACCESSORY_SUCCESS:
			let softDeletedAccessoryId = action.data;
			let afterSoftDeletedData = state.accessories.filter(
				(data) => data.id.toString() !== softDeletedAccessoryId.toString()
			);
			return { ...state, accessories: afterSoftDeletedData };

		case RESTORE_SINGLE_ACCESSORY_SUCCESS:
			return { ...state };

		default:
			return state;
	}
};

export default AccessoryReducer;

import { Button, Form, Modal, Space } from "antd";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { wordCount, wordCounterRealTime } from "../../utils/wordCount";
import Center from "../common/Center";
import Notification from "../common/Notification";
import TextArea from "../Form/TextArea";

export const useUpdateReason = () => {
	const dispatch = useDispatch();
	const [showModel, setShowModel] = useState(false);
	const history = useHistory();

	// For model toggle
	const modelToggle = (value) => {
		setShowModel(value);
	};

	const updateAction = async (id, action, data, redirectUrl) => {
		try {
			await wordCount(data.updateReason, 10);
			await dispatch(action(id, data));
			modelToggle(false);
			history.push(redirectUrl);
		} catch (err) {
			throw err;
		}
	};

	// For Return Model for update Reason
	const UpdateModel = ({ id, action, valuesWithoutReason, redirectUrl, placeholder }) => {
		const [form] = Form.useForm();
		const handleOnFinish = async (updateReason) => {
			try {
				let data = {
					...valuesWithoutReason,
					...updateReason,
				};
				await updateAction(id, action, data, redirectUrl);
			} catch (err) {
				const errorMessage = err.message
					? err.message
					: (err?.response && err.response?.data?.message) || "Soemthing went wrong";
				return Notification("error", "", errorMessage);
			}
		};

		const handleCancel = async () => {
			await modelToggle(false);
		};
		const [word, setWord] = useState(undefined);

		if (showModel) {
			return (
				<Modal visible={showModel} onCancel={handleCancel} footer={null}>
					<Form
						form={form}
						name="basic"
						layout="vertical"
						onFinish={(updateReason) => handleOnFinish(updateReason)}
						initialValues={{ updateReason: "" }}
					>
						<div style={{ minHeight: "120px" }}>
							<TextArea
								rules={[
									{
										required: true,
									},
								]}
								label="Reason"
								name="updateReason"
								handleOnChange={(e) => setWord(e.target.value)}
								placeholder={placeholder}
								extra={`Character count: ${wordCounterRealTime(word)} out of 10`}
							/>
						</div>
						<Form.Item style={{ marginTop: "20px" }}>
							<Center height="0px">
								<Space>
									<Button danger type="primary" onClick={handleCancel}>
										Cancel
									</Button>
									<Button type="primary" htmlType="submit">
										Update
									</Button>
								</Space>
							</Center>
						</Form.Item>
					</Form>
				</Modal>
			);
		} else {
			return <> </>;
		}
	};

	return { updateAction, modelToggle, UpdateModel };
};

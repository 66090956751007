import Notification from "../../../components/common/Notification";
import * as distributorApi from "../../../services/distributor";

import {
	GET_DISTRIBUTOR_CONTACTS_FAILURE,
	GET_DISTRIBUTOR_CONTACTS_REQUEST,
	GET_DISTRIBUTOR_CONTACTS_SUCCESS,
	GET_DISTRIBUTOR_SINGLE_CONTACT_FAILURE,
	GET_DISTRIBUTOR_SINGLE_CONTACT_REQUEST,
	GET_DISTRIBUTOR_SINGLE_CONTACT_SUCCESS,
	SOFTDELETE_DISTRIBUTOR_SINGLE_CONTACT_FAILURE,
	SOFTDELETE_DISTRIBUTOR_SINGLE_CONTACT_REQUEST,
	SOFTDELETE_DISTRIBUTOR_SINGLE_CONTACT_SUCCESS,
} from "./distributor.actionType";

export const requestDistributorContacts = () => {
	return {
		type: GET_DISTRIBUTOR_CONTACTS_REQUEST,
	};
};

export const getDistributorContactsSuccess = (data) => {
	return {
		type: GET_DISTRIBUTOR_CONTACTS_SUCCESS,
		data,
	};
};

export const getDistributorContactsFailure = (message) => {
	return {
		type: GET_DISTRIBUTOR_CONTACTS_FAILURE,
		message,
	};
};

export const getDistributorContacts = () => {
	return (dispatch) => {
		dispatch(requestDistributorContacts());
		return distributorApi
			.distributorContact()
			.then((resp) => {
				const data = resp.data.data;
				dispatch(getDistributorContactsSuccess(data));
			})
			.catch((err) => {
				const errorMessage = err.response && err.response.data.message;
				dispatch(getDistributorContactsFailure(errorMessage));
			});
	};
};

export const requestDistributorSingleContactData = () => {
	return {
		type: GET_DISTRIBUTOR_SINGLE_CONTACT_REQUEST,
	};
};

export const getDistributorSingleContactSuccess = (data) => {
	return {
		type: GET_DISTRIBUTOR_SINGLE_CONTACT_SUCCESS,
		data,
	};
};

export const getDistributorSingleContactFailure = (message) => {
	return {
		type: GET_DISTRIBUTOR_SINGLE_CONTACT_FAILURE,
		message,
	};
};

export const getDistributorSingleContact = (id, softdelete) => {
	return (dispatch) => {
		dispatch(requestDistributorSingleContactData());
		return distributorApi
			.distributorSingleContact(id, softdelete)
			.then((resp) => {
				const data = resp.data.data;
				dispatch(getDistributorSingleContactSuccess(data));
			})
			.catch((err) => {
				const errorMessage = err.response && err.response.data.message;
				dispatch(getDistributorSingleContactFailure(errorMessage));
				throw errorMessage;
			});
	};
};

export const requestDistributorSingleContactSoftDelete = () => {
	return {
		type: SOFTDELETE_DISTRIBUTOR_SINGLE_CONTACT_REQUEST,
	};
};

export const softDeleteDistributorSingleContactSuccess = (data) => {
	return {
		type: SOFTDELETE_DISTRIBUTOR_SINGLE_CONTACT_SUCCESS,
		data,
	};
};

export const softDeleteDistributorSingleContactFailure = (message) => {
	return {
		type: SOFTDELETE_DISTRIBUTOR_SINGLE_CONTACT_FAILURE,
		message,
	};
};

export const softDeleteDistributorSingleContact = (id, reason) => {
	return (dispatch) => {
		dispatch(requestDistributorSingleContactSoftDelete());
		return distributorApi
			.softDeleteDistributorSingleContact(id, reason)
			.then((resp) => {
				dispatch(softDeleteDistributorSingleContactSuccess(id));
				Notification("success", resp.data.message, "", "topRight");
			})
			.catch((err) => {
				const errorMessage = err.response && err.response.data.message;
				dispatch(getDistributorSingleContactFailure(errorMessage));
				Notification("error", errorMessage, "", "topRight");
			});
	};
};

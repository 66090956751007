export const GET_ACCESSORIES_REQUEST = "GET_ACCESSORIES_REQUEST",
	GET_ACCESSORIES_SUCCESS = "GET_ACCESSORIES_SUCCESS",
	GET_ACCESSORIES_FAILURE = "GET_ACCESSORIES_FAILURE";

export const GET_SINGLE_ACCESSORY_REQUEST = "GET_SINGLE_ACCESSORY_REQUEST",
	GET_SINGLE_ACCESSORY_SUCCESS = "GET_SINGLE_ACCESSORY_SUCCESS",
	GET_SINGLE_ACCESSORY_FAILURE = "GET_SINGLE_ACCESSORY_FAILURE";

export const SOFT_DELETE_SINGLE_ACCESSORY_REQUEST = "SOFT_DELETE_SINGLE_ACCESSORY_REQUEST",
	SOFT_DELETE_SINGLE_ACCESSORY_SUCCESS = "SOFT_DELETE_SINGLE_ACCESSORY_SUCCESS",
	SOFT_DELETE_SINGLE_ACCESSORY_FAILURE = "SOFT_DELETE_SINGLE_ACCESSORY_FAILURE";

export const RESTORE_SINGLE_ACCESSORY_REQUEST = "RESTORE_SINGLE_ACCESSORY_REQUEST",
	RESTORE_SINGLE_ACCESSORY_SUCCESS = "RESTORE_SINGLE_ACCESSORY_SUCCESS",
	RESTORE_SINGLE_ACCESSORY_FAILURE = "RESTORE_SINGLE_ACCESSORY_FAILURE";

export const ADD_ACCESSORY_REQUEST = "ADD_ACCESSORY_REQUEST",
	ADD_ACCESSORY_SUCCESS = "ADD_ACCESSORY_SUCCESS",
	ADD_ACCESSORY_FAILURE = "ADD_ACCESSORY_FAILURE";

export const UPDATE_ACCESSORY_REQUEST = "UPDATE_ACCESSORY_REQUEST",
	UPDATE_ACCESSORY_SUCCESS = "UPDATE_ACCESSORY_SUCCESS",
	UPDATE_ACCESSORY_FAILURE = "UPDATE_ACCESSORY_FAILURE";

import { Button, Card, Col, Form, Row, Typography } from "antd";
import { useEffect, useMemo } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import CustomAlert from "../../components/common/CustomAlert";
import InputField from "../../components/Form/InputField";
import routes from "../../routes";
import LoginPage from "../../container/auth";
import NotFound404Page from "../../container/NotFound404/NotFound404";

import { loginAdmin } from "../../store/actions/admin/auth.action";
import "./auth.css";

const { Title } = Typography;

const emailRules = [
	{
		required: true,
		message: "Please input your email!",
	},
	{
		type: "email",
		message: "The input is not valid E-mail!",
	},
];

const passwordRules = [
	{
		required: true,
		message: "Please input your password!",
	},
];

const Login = ({ isLoading, loginAdmin, errorMessage, isAuthenticated, admin, history }) => {
	const newRoutes = useMemo(() => {
		if (admin && admin?.accessLevel && admin?.accessLevel === "superAdmin") {
			return routes;
		} else {
			const permissions = admin?.template?.permissions;
			let tempRoute = [];
			if (permissions) {
				// eslint-disable-next-line array-callback-return
				let newTempRoute = routes?.filter((route) => {
					if (route?.value === "login") {
						return true;
					}
					for (const [key, value] of Object.entries(permissions)) {
						if (route?.menu === key) {
							for (const [subMenuKey, subMenuKeyValue] of Object.entries(value)) {
								if (route?.value === subMenuKey) {
									if (!route?.type) {
										return true;
									}
									if (subMenuKeyValue?.find((element) => element === route?.type)) {
										return true;
									}
								}
							}
						}
					}
				});
				tempRoute = newTempRoute;
			}
			tempRoute.push(
				{ path: "/login", component: LoginPage, exact: true, value: "login" },
				{ component: NotFound404Page, accessLevel: ["superAdmin"] }
			);
			return tempRoute;
		}
	}, [admin]);

	useEffect(() => {
		if (isAuthenticated && admin) {
			const path = newRoutes?.length > 0 ? newRoutes[0].path : "/customer/enquiry";

			history.push(path);
		}
	}, [isAuthenticated, admin, newRoutes]); // eslint-disable-line react-hooks/exhaustive-deps

	const onFinish = (values) => {
		if (!values.email || !values.password) {
			return;
		}
		loginAdmin(values);
	};

	return (
		<Row type="flex" align="middle" className="login-container">
			<Col className="logo-and-text-in-login-form">
				<div className="logo-image-container">
					<img src={`${window.location.origin}/assets/image/logo.svg`} alt="MAPL Logo" />
				</div>
				<Title level={1} className="sign-in-title">
					Sign in to your Account
				</Title>
				<Card className="login-card">
					{!isLoading && errorMessage && (
						<div className="error-alert">
							<CustomAlert message={errorMessage} type="error" />
						</div>
					)}
					<Form name="basic" layout="vertical" onFinish={onFinish} validateTrigger="onSubmit">
						<InputField
							label="Email Address"
							name="email"
							rules={emailRules}
							placeholder="Please enter your email id"
							autoComplete="email"
						/>
						<InputField
							label="Password"
							name="password"
							rules={passwordRules}
							placeholder="Please enter your assigned password"
							inputType="password"
							autoComplete="current-password"
						/>
						<Form.Item>
							<Button
								type="primary"
								htmlType="submit"
								className="login-form-button"
								loading={isLoading}
							>
								Login
							</Button>
						</Form.Item>
					</Form>
				</Card>
			</Col>
		</Row>
	);
};

const mapStateToProps = (state) => {
	return {
		isLoading: state?.admin?.isLoading,
		errorMessage: state?.admin?.errorMessage,
		isAuthenticated: state?.admin?.isAuthenticated,
		admin: state?.admin?.admin,
	};
};

const mapDispatchToProps = { loginAdmin };

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Login));

import imageCompression from "browser-image-compression";

export const resizeImage = async (imageFile) => {
	const options = {
		maxSizeMB: 1,
		maxWidthOrHeight: 1920,
		useWebWorker: true,
	};
	try {
		let compressedFile = await imageCompression(imageFile, options);
		let blobFile = await imageCompression.getDataUrlFromFile(compressedFile);
		return { compressedFile, blobFile };
	} catch (error) {
		throw error;
	}
};

import Notification from "../../../components/common/Notification";
import * as dealerApi from "../../../services/dealer";

import {
	GET_DEALER_CONTACTS_FAILURE,
	GET_DEALER_CONTACTS_REQUEST,
	GET_DEALER_CONTACTS_SUCCESS,
	GET_DEALER_SINGLE_CONTACT_REQUEST,
	GET_DEALER_SINGLE_CONTACT_SUCCESS,
	GET_DEALER_SINGLE_CONTACT_FAILURE,
	SOFTDELETE_DEALER_SINGLE_CONTACT_FAILURE,
	SOFTDELETE_DEALER_SINGLE_CONTACT_SUCCESS,
	SOFTDELETE_DEALER_SINGLE_CONTACT_REQUEST,
} from "./dealer.actionType";

export const requestDealerContacts = () => {
	return {
		type: GET_DEALER_CONTACTS_REQUEST,
	};
};

export const getDealerContactsSuccess = (data) => {
	return {
		type: GET_DEALER_CONTACTS_SUCCESS,
		data,
	};
};

export const getDealerContactsFailure = (message) => {
	return {
		type: GET_DEALER_CONTACTS_FAILURE,
		message,
	};
};

export const getDealerContacts = () => {
	return (dispatch) => {
		dispatch(requestDealerContacts());
		return dealerApi
			.dealerContact()
			.then((resp) => {
				const data = resp.data.data;
				dispatch(getDealerContactsSuccess(data));
			})
			.catch((err) => {
				const errorMessage = err.response && err.response.data.message;
				dispatch(getDealerContactsFailure(errorMessage));
			});
	};
};

export const requestDealerSingleContactData = () => {
	return {
		type: GET_DEALER_SINGLE_CONTACT_REQUEST,
	};
};

export const getDealerSingleContactSuccess = (data) => {
	return {
		type: GET_DEALER_SINGLE_CONTACT_SUCCESS,
		data,
	};
};

export const getDealerSingleContactFailure = (message) => {
	return {
		type: GET_DEALER_SINGLE_CONTACT_FAILURE,
		message,
	};
};

export const getDealerSingleContact = (id, softdelete) => {
	return (dispatch) => {
		dispatch(requestDealerSingleContactData());
		return dealerApi
			.dealerSingleContact(id, softdelete)
			.then((resp) => {
				const data = resp.data.data;
				dispatch(getDealerSingleContactSuccess(data));
			})
			.catch((err) => {
				const errorMessage = err.response && err.response.data.message;
				dispatch(getDealerSingleContactFailure(errorMessage));
				throw errorMessage;
			});
	};
};

export const requestDealerSingleContactSoftDelete = () => {
	return {
		type: SOFTDELETE_DEALER_SINGLE_CONTACT_REQUEST,
	};
};

export const softDeleteDealerSingleContactSuccess = (data) => {
	return {
		type: SOFTDELETE_DEALER_SINGLE_CONTACT_SUCCESS,
		data,
	};
};

export const softDeleteDealerSingleContactFailure = (message) => {
	return {
		type: SOFTDELETE_DEALER_SINGLE_CONTACT_FAILURE,
		message,
	};
};

export const softDeleteDealerSingleContact = (id, reason) => {
	return (dispatch) => {
		dispatch(requestDealerSingleContactSoftDelete());
		return dealerApi
			.softDeleteDealerSingleContact(id, reason)
			.then((resp) => {
				dispatch(softDeleteDealerSingleContactSuccess(id));
				Notification("success", resp.data.message, "", "topRight");
			})
			.catch((err) => {
				const errorMessage = err.response && err.response.data.message;
				dispatch(softDeleteDealerSingleContactFailure(errorMessage));
				Notification("error", errorMessage, "", "topRight");
			});
	};
};

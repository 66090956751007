import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAllDistricts, getDistricts } from "../../store/actions/place/place.action";

export const useIndianDistricts = (stateIds) => {
	const dispatch = useDispatch();

	const getIndianDistricts = async (stateIds) => {
		const data = await dispatch(getDistricts(stateIds));
		return data;
	};

	return { getIndianDistricts };
};

export const useIndianAllDistricts = () => {
	const dispatch = useDispatch();
	const Districts = useSelector((state) => state?.place?.allDistricts);

	useEffect(() => {
		if (Districts.length === 0) dispatch(getAllDistricts());
	}, [dispatch, Districts.length]);

	const getIndianAllDistricts = () => {
		return Districts;
	};

	return { getIndianAllDistricts };
};

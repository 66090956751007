import {
	ContainerOutlined,
	CustomerServiceOutlined,
	DeleteOutlined,
	FileDoneOutlined,
	FolderOpenOutlined,
	FolderOutlined,
	TeamOutlined,
	UserOutlined,
	UserSwitchOutlined,
	VideoCameraAddOutlined,
} from "@ant-design/icons";
import { AiOutlineApartment, AiOutlineForm } from "react-icons/ai";
import { RiExchangeFundsFill } from "react-icons/ri";
import { BsBoundingBoxCircles, BsFillBriefcaseFill, BsNewspaper } from "react-icons/bs";
import { CgProductHunt } from "react-icons/cg";
import { GiArchiveResearch } from "react-icons/gi";
import { GoSettings } from "react-icons/go";
import { GrSettingsOption, GrUserSettings } from "react-icons/gr";
import { IoDocumentTextSharp } from "react-icons/io5";
import { HiViewGridAdd, HiOutlineTemplate } from "react-icons/hi";
import { SiAudiotechnica, SiBrandfolder, SiGoogleclassroom } from "react-icons/si";
import ApplicationPage from "../container/Careers/Applications/Application";
import CategoriesPage from "../container/Careers/Categories";
import JobPage from "../container/Careers/Jobs/Jobs";
import CustomerInfoPage from "../container/Customer/customer";
import CustomerEnquiryPage from "../container/Customer/enquiry";
import DealerEnquiryPage from "../container/Dealer/enquiry";
import DistributorEnquiryPage from "../container/Distributor/enquiry";
import GalleryAddedPage from "../container/Gallery/Added";
import GallerySubmissionPage from "../container/Gallery/Submissions";
import ImplementsPage from "../container/Products/Implements/implements";
import ImplementsSubAssemblyPage from "../container/Products/ImplementsSubAssembly/implementSubAssembly";
import InnovationPage from "../container/Other/Innovation/Innovation";
import NewsLetterPage from "../container/Other/Newsletter";
import RecycleBinPage from "../container/Other/RecycleBin";
import BrandPage from "../container/Products/Brands/brand";
import CategoryPage from "../container/Products/Category/category";
import SecondaryCategoryPage from "../container/Products/SecondaryCategory/category";
import TechnicalSpecificationPage from "../container/Products/TechnicalSpecifications/technicalspecification";
import SalesPointEnquiryPage from "../container/SalesPoint/enquiry";
import ServiceCenterEnquiryPage from "../container/ServiceCenter/enquiry";
import SubDealerEnquiryPage from "../container/SubDealer/enquiry";
import SubAssemblyPage from "../container/Products/SubAssembly/SubAssembly";
import AccessoryPage from "../container/Products/Accessory/Accessory";
import ModelPage from "../container/Products/Model/Model";
import SparePartPage from "../container/Products/SparePart/SparePart";
import UserPage from "../container/User/user";
import UserTemplatePage from "../container/Templates/template";
import HSNCode from "../container/Products/HSNCode/HSNCode";

const dataTable = [
	{
		title: "User",
		icon: UserOutlined,
		identifier: "U",
		path: "/usermanagement",
		subMenu: [
			{
				title: "Users",
				icon: AiOutlineForm,
				path: "/usermanagement/user",
				component: (props) => UserPage(props),
				exact: true,
				accessLevel: ["superAdmin"],
			},
			{
				title: "Templates",
				icon: HiOutlineTemplate,
				path: "/usermanagement/template",
				component: (props) => UserTemplatePage(props),
				exact: true,
				accessLevel: ["superAdmin"],
			},
		],
	},
	{
		title: "Customer",
		icon: UserOutlined,
		identifier: "C",
		path: "/customer",
		subMenu: [
			{
				title: "Enquiry",
				value: "customerEnquiry",
				icon: ContainerOutlined,
				path: "/customer/enquiry",
				component: (props) => CustomerEnquiryPage(props),
				exact: true,
				accessLevel: ["superAdmin"],
				menu: "customer",
			},
			{
				title: "Info",
				value: "customerInfo",
				menu: "customer",
				icon: ContainerOutlined,
				path: "/customer/info",
				component: (props) => CustomerInfoPage(props),
				exact: true,
				accessLevel: ["superAdmin"],
			},
		],
	},
	{
		title: "Products",
		icon: CgProductHunt,
		identifier: "PROD",
		path: "/products",
		subMenu: [
			{
				title: "Brands",
				value: "brands",
				menu: "products",
				icon: SiBrandfolder,
				path: "/products/brands",
				component: (props) => BrandPage(props),
				exact: true,
				accessLevel: ["superAdmin"],
			},
			{
				title: "Primary Categories",
				value: "productCategories",
				menu: "products",
				icon: SiGoogleclassroom,
				path: "/products/categories",
				component: (props) => CategoryPage(props),
				exact: true,
				accessLevel: ["superAdmin"],
			},
			{
				title: "Secondary Categories",
				value: "productSecondaryCategories",
				menu: "products",
				icon: BsBoundingBoxCircles,
				path: "/products/secondary/categories",
				component: (props) => SecondaryCategoryPage(props),
				exact: true,
				accessLevel: ["superAdmin"],
			},
			{
				title: "Technical Specification",
				value: "productTechnicalSpecifications",
				menu: "products",
				icon: SiAudiotechnica,
				path: "/products/technicalspecifications",
				component: (props) => TechnicalSpecificationPage(props),
				exact: true,
				accessLevel: ["superAdmin"],
			},
			{
				title: "Implement Sub Assembly",
				value: "implementesSubAssembly",
				menu: "products",
				icon: AiOutlineApartment,
				path: "/products/implement/sub-assembly",
				component: (props) => ImplementsSubAssemblyPage(props),
				exact: true,
				accessLevel: ["superAdmin"],
			},
			{
				title: "Implements",
				value: "implements",
				menu: "products",
				icon: GrUserSettings,
				path: "/products/implements",
				component: (props) => ImplementsPage(props),
				exact: true,
				accessLevel: ["superAdmin"],
			},
			{
				title: "Sub Assembly",
				value: "subAssembly",
				menu: "products",
				icon: GoSettings,
				path: "/products/sub-assembly",
				component: (props) => SubAssemblyPage(props),
				exact: true,
				accessLevel: ["superAdmin"],
			},
			{
				title: "Accessory",
				value: "accessory",
				menu: "products",
				icon: HiViewGridAdd,
				path: "/products/accessory",
				component: (props) => AccessoryPage(props),
				exact: true,
				accessLevel: ["superAdmin"],
			},
			{
				title: "Model",
				value: "model",
				menu: "products",
				icon: RiExchangeFundsFill,
				path: "/products/model",
				component: (props) => ModelPage(props),
				exact: true,
				accessLevel: ["superAdmin"],
			},
			{
				title: "Spare Part",
				value: "sparePart",
				menu: "products",
				icon: GrSettingsOption,
				path: "/products/sparepart",
				component: (props) => SparePartPage(props),
				exact: true,
				accessLevel: ["superAdmin"],
			},
			{
				title: "HSN/SAC Code",
				value: "hsnCode",
				menu: "products",
				icon: GrSettingsOption,
				path: "/products/hsncode",
				component: (props) =>  HSNCode(props),
				exact: true,
				accessLevel: ["superAdmin"],
			},
		],
	},
	{
		title: "Distributor",
		icon: TeamOutlined,
		identifier: "D",
		path: "/distributor",
		subMenu: [
			{
				title: "Enquiry",
				value: "distributorEnquiry",
				menu: "distributor",

				icon: ContainerOutlined,
				path: "/distributor/enquiry",
				component: (props) => DistributorEnquiryPage(props),
				exact: true,
				accessLevel: ["superAdmin"],
			},
		],
	},
	{
		title: "Dealer",

		icon: UserOutlined,
		identifier: "DE",
		path: "/dealer",
		subMenu: [
			{
				title: "Enquiry",
				value: "dealerEnquiry",
				menu: "dealer",

				icon: ContainerOutlined,
				path: "/dealer/enquiry",
				component: (props) => DealerEnquiryPage(props),
				exact: true,
				accessLevel: ["superAdmin"],
			},
		],
	},
	{
		title: "Subdealer",
		icon: UserSwitchOutlined,
		identifier: "SD",
		path: "/sub-dealer",
		subMenu: [
			{
				title: "Enquiry",
				value: "subdealerEnquiry",
				menu: "subDealer",

				icon: ContainerOutlined,
				path: "/sub-dealer/enquiry",
				component: (props) => SubDealerEnquiryPage(props),
				exact: true,
				accessLevel: ["superAdmin"],
			},
		],
	},
	{
		title: "Service Center",
		icon: CustomerServiceOutlined,
		identifier: "SC",
		path: "/service-center",
		subMenu: [
			{
				title: "Enquiry",
				value: "serviceCenterEnquiry",
				menu: "serviceCenter",

				icon: ContainerOutlined,
				path: "/service-center/enquiry",
				component: (props) => ServiceCenterEnquiryPage(props),
				exact: true,
				accessLevel: ["superAdmin"],
			},
		],
	},
	{
		title: "Sales Point",
		icon: FileDoneOutlined,
		identifier: "SP",
		path: "/sales-point",
		subMenu: [
			{
				title: "Enquiry",
				value: "salespointEnquiry",
				menu: "salesPoint",

				icon: ContainerOutlined,
				path: "/sales-point/enquiry",
				component: (props) => SalesPointEnquiryPage(props),
				exact: true,
				accessLevel: ["superAdmin"],
			},
		],
	},
	{
		title: "Careers",
		icon: FolderOutlined,
		identifier: "CA",
		path: "/careers",
		subMenu: [
			{
				title: "Jobs",
				value: "jobs",
				menu: "career",

				icon: BsFillBriefcaseFill,
				path: "/careers/jobs",
				component: (props) => JobPage(props),
				exact: true,
				accessLevel: ["superAdmin"],
			},
			{
				title: "Applications",
				value: "applications",
				menu: "career",

				icon: IoDocumentTextSharp,
				path: "/careers/applications",
				component: (props) => ApplicationPage(props),
				exact: true,
				accessLevel: ["superAdmin"],
			},
			{
				title: "Categories",
				value: "categories",
				menu: "career",

				icon: FolderOpenOutlined,
				path: "/careers/categories",
				component: (props) => CategoriesPage(props),
				exact: true,
				accessLevel: ["superAdmin"],
			},
		],
	},
	{
		title: "Gallery",
		icon: VideoCameraAddOutlined,
		identifier: "G",
		path: "/gallery",

		subMenu: [
			{
				title: "Added",
				value: "added",
				menu: "gallery",

				icon: VideoCameraAddOutlined,
				path: "/gallery/added",
				component: (props) => GalleryAddedPage(props),
				exact: true,
				accessLevel: ["superAdmin"],
			},
			{
				title: "Submissions",
				value: "gallerySubmissions",
				menu: "gallery",

				icon: VideoCameraAddOutlined,
				path: "/gallery/submissions",
				component: (props) => GallerySubmissionPage(props),
				exact: true,
				accessLevel: ["superAdmin"],
			},
		],
	},

	{
		title: "Other",
		icon: FolderOutlined,
		identifier: "OTH",
		path: "/other",
		subMenu: [
			{
				title: "Newsletter",
				value: "newsletter",
				menu: "other",

				icon: BsNewspaper,
				path: "/other/newsletter",
				component: (props) => NewsLetterPage(props),
				exact: true,
				accessLevel: ["superAdmin"],
			},
			{
				title: "Innovation",
				value: "innovation",
				menu: "other",

				icon: GiArchiveResearch,
				path: "/other/innovation",
				component: (props) => InnovationPage(props),
				exact: true,
				accessLevel: ["superAdmin"],
			},
			{
				title: "Recycle Bin",
				value: "recycleBin",
				menu: "other",

				icon: DeleteOutlined,
				path: "/other/recyclebin",
				component: (props) => RecycleBinPage(props),
				exact: true,
				accessLevel: ["superAdmin"],
			},
		],
	},
];

export default dataTable;

import {
	GET_DRAWER_TOGGLE,
	GET_INNOVATION_SUCCESS,
	GET_NEWSLETTER_SUCCESS,
	GET_SINGLE_INNOVATION_SUCCESS,
	GET_SOFT_DELETED_ITEMS_SUCCESS,
	PERMANENT_DELETE_ITEM_SUCCESS,
	SET_DRAWER_DEFAULT,
	SOFTDELETE_SINGLE_INNOVATION_SUCCESS,
	RESTORE_ITEM_SUCCESS,
	SOFTDELETE_SINGLE_NEWSLETTER_SUCCESS,
	UPDATE_DELETED_ITEMS_AS_EMPTY_SUCCESS,
} from "../actions/other/other.actionType";
import globalState from "../state";

const requestRegex = /_REQUEST/;
const errorRegex = /_FAILURE/;
const successRegex = /_SUCCESS/;

const initialState = {
	errorMessage: "",
	isLoading: false,
	newsletter: [],
	deletedItems: [],
	innovations: [],
	innovation: {},
	drawer: false,
	...globalState,
};

const OtherReducer = (state = initialState, action) => {
	const isRequest = requestRegex.test(action.type);
	const isError = errorRegex.test(action.type);
	const isSuccess = successRegex.test(action.type);

	if (isRequest) {
		return { ...state, isLoading: true, errorMessage: "" };
	}
	if (isError) {
		return { ...state, isLoading: false, errorMessage: action.message };
	}
	if (isSuccess) {
		state = { ...state, isLoading: false, errorMessage: "" };
	}

	switch (action.type) {
		case GET_NEWSLETTER_SUCCESS:
			return {
				...state,
				newsletter: action.data,
			};

		case SOFTDELETE_SINGLE_NEWSLETTER_SUCCESS:
			const newsletterDeletionId = action.data;
			const nonDeletedNewsletters = state.newsletter.filter(
				(newsletter) => newsletter.id !== newsletterDeletionId
			);
			return {
				...state,
				newsletter: nonDeletedNewsletters,
			};

		case GET_INNOVATION_SUCCESS:
			return {
				...state,
				innovations: action.data,
			};
		case GET_SINGLE_INNOVATION_SUCCESS:
			return {
				...state,
				innovation: action.data,
			};
		case GET_DRAWER_TOGGLE:
			return {
				...state,
				drawer: !state.drawer,
			};
		case SET_DRAWER_DEFAULT:
			return {
				...state,
				drawer: false,
			};

		case SOFTDELETE_SINGLE_INNOVATION_SUCCESS:
			const innovationDeletionId = action.data;
			const nonDeletedInnovations = state.innovations.filter(
				(innovations) => innovations.id !== innovationDeletionId
			);
			return {
				...state,
				innovations: nonDeletedInnovations,
			};

		case GET_SOFT_DELETED_ITEMS_SUCCESS:
			return {
				...state,
				deletedItems: action.data,
			};

		case PERMANENT_DELETE_ITEM_SUCCESS:
			const itemDeletionId = action.data;
			const nonDeletedItems = state.deletedItems.filter((item) => item.id !== itemDeletionId);
			return {
				...state,
				deletedItems: nonDeletedItems,
			};

		case RESTORE_ITEM_SUCCESS:
			const itemRestorationId = action.data;
			const nonRestoredItems = state.deletedItems.filter((item) => item.id !== itemRestorationId);
			return {
				...state,
				deletedItems: nonRestoredItems,
			};

		case UPDATE_DELETED_ITEMS_AS_EMPTY_SUCCESS:
			let emptyData;
			return {
				...state,
				deletedItems: emptyData,
			};
		default:
			return state;
	}
};

export default OtherReducer;

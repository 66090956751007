import { Button } from "antd";
import { NavLink } from "react-router-dom";
import "./ButtonAsLink.css";

const index = ({ text, link, danger = false }) => {
	return (
		<>
			<Button type="primary" danger={danger}>
				<NavLink to={link}>{text}</NavLink>
			</Button>
		</>
	);
};

export default index;

import Notification from "../../../components/common/Notification";
import * as subDealerApi from "../../../services/subdealer";
import {
	GET_SUBDEALER_CONTACTS_FAILURE,
	GET_SUBDEALER_CONTACTS_REQUEST,
	GET_SUBDEALER_CONTACTS_SUCCESS,
	GET_SUBDEALER_SINGLE_CONTACT_FAILURE,
	GET_SUBDEALER_SINGLE_CONTACT_REQUEST,
	GET_SUBDEALER_SINGLE_CONTACT_SUCCESS,
	SOFTDELETE_SUBDEALER_SINGLE_CONTACT_FAILURE,
	SOFTDELETE_SUBDEALER_SINGLE_CONTACT_REQUEST,
	SOFTDELETE_SUBDEALER_SINGLE_CONTACT_SUCCESS,
} from "./subdealer.actionType";

export const requestSubDealerContacts = () => {
	return {
		type: GET_SUBDEALER_CONTACTS_REQUEST,
	};
};

export const getSubDealerContactsSuccess = (data) => {
	return {
		type: GET_SUBDEALER_CONTACTS_SUCCESS,
		data,
	};
};

export const getSubDealerContactsFailure = (message) => {
	return {
		type: GET_SUBDEALER_CONTACTS_FAILURE,
		message,
	};
};

export const getSubDealerContacts = () => {
	return (dispatch) => {
		dispatch(requestSubDealerContacts());
		return subDealerApi
			.subDealerContact()
			.then((resp) => {
				const data = resp.data.data;
				dispatch(getSubDealerContactsSuccess(data));
			})
			.catch((err) => {
				const errorMessage = err.response && err.response.data.message;
				dispatch(getSubDealerContactsFailure(errorMessage));
			});
	};
};

export const requestSubDealerSingleContactData = () => {
	return {
		type: GET_SUBDEALER_SINGLE_CONTACT_REQUEST,
	};
};

export const getSubDealerSingleContactSuccess = (data) => {
	return {
		type: GET_SUBDEALER_SINGLE_CONTACT_SUCCESS,
		data,
	};
};

export const getSubDealerSingleContactFailure = (message) => {
	return {
		type: GET_SUBDEALER_SINGLE_CONTACT_FAILURE,
		message,
	};
};

export const getSubDealerSingleContact = (id, softdelete) => {
	return (dispatch) => {
		dispatch(requestSubDealerSingleContactData());
		return subDealerApi
			.subDealerSingleContact(id, softdelete)
			.then((resp) => {
				const data = resp.data.data;
				dispatch(getSubDealerSingleContactSuccess(data));
			})
			.catch((err) => {
				const errorMessage = err.response && err.response.data.message;
				dispatch(getSubDealerSingleContactFailure(errorMessage));
			});
	};
};

export const requestSubDealerSingleContactSoftDelete = () => {
	return {
		type: SOFTDELETE_SUBDEALER_SINGLE_CONTACT_REQUEST,
	};
};

export const softDeleteSubDealerSingleContactSuccess = (data) => {
	return {
		type: SOFTDELETE_SUBDEALER_SINGLE_CONTACT_SUCCESS,
		data,
	};
};

export const softDeleteSubDealerSingleContactFailure = (message) => {
	return {
		type: SOFTDELETE_SUBDEALER_SINGLE_CONTACT_FAILURE,
		message,
	};
};

export const softDeleteSubDealerSingleContact = (id, reason) => {
	return (dispatch) => {
		dispatch(requestSubDealerSingleContactSoftDelete());
		return subDealerApi
			.softDeleteSubDealerSingleContact(id, reason)
			.then((resp) => {
				dispatch(softDeleteSubDealerSingleContactSuccess(id));
				Notification("success", resp.data.message, "", "topRight");
			})
			.catch((err) => {
				const errorMessage = err.response && err.response.data.message;
				dispatch(softDeleteSubDealerSingleContactFailure(errorMessage));
			});
	};
};

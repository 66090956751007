export const GET_GALLERY_SUBMISSION_REQUEST = "GET_GALLERY_SUBMISSION_REQUEST",
	GET_GALLERY_SUBMISSION_SUCCESS = "GET_GALLERY_SUBMISSION_SUCCESS",
	GET_GALLERY_SUBMISSION_FAILURE = "GET_GALLERY_SUBMISSION_FAILURE";

export const GET_SINGLE_GALLERY_SUBMISSION_REQUEST = "GET_SINGLE_GALLERY_SUBMISSION_REQUEST",
	GET_SINGLE_GALLERY_SUBMISSION_SUCCESS = "GET_SINGLE_GALLERY_SUBMISSION_SUCCESS",
	GET_SINGLE_GALLERY_SUBMISSION_FAILURE = "GET_SINGLE_GALLERY_SUBMISSION_FAILURE";

export const DELETE_GALLERY_SUBMISSION_REQUEST = "DELETE_GALLERY_SUBMISSION_REQUEST",
	DELETE_GALLERY_SUBMISSION_SUCCESS = "DELETE_GALLERY_SUBMISSION_SUCCESS",
	DELETE_GALLERY_SUBMISSION_FAILURE = "DELETE_GALLERY_SUBMISSION_FAILURE";

export const ASSIGN_GALLERY_REQUEST = "ASSIGN_GALLERY_REQUEST",
	ASSIGN_GALLERY_SUCCESS = "ASSIGN_GALLERY_SUCCESS",
	ASSIGN_GALLERY_FAILURE = "ASSIGN_GALLERY_FAILURE";

export const ADD_GALLERY_REQUEST = "ADD_GALLERY_REQUEST",
	ADD_GALLERY_SUCCESS = "ADD_GALLERY_SUCCESS",
	ADD_GALLERY_FAILURE = "ADD_GALLERY_FAILURE";

export const GET_GALLERY_REQUEST = "GET_GALLERY_REQUEST",
	GET_GALLERY_SUCCESS = "GET_GALLERY_SUCCESS",
	GET_GALLERY_FAILURE = "GET_GALLERY_FAILURE";

export const GET_SINGLE_GALLERY_REQUEST = "GET_SINGLE_GALLERY_REQUEST",
	GET_SINGLE_GALLERY_SUCCESS = "GET_SINGLE_GALLERY_SUCCESS",
	GET_SINGLE_GALLERY_FAILURE = "GET_SINGLE_GALLERY_FAILURE";

export const UPDATE_SINGLE_GALLERY_REQUEST = "UPDATE_SINGLE_GALLERY_REQUEST",
	UPDATE_SINGLE_GALLERY_SUCCESS = "UPDATE_SINGLE_GALLERY_SUCCESS",
	UPDATE_SINGLE_GALLERY_FAILURE = "UPDATE_SINGLE_GALLERY_FAILURE";

export const DELETE_SINGLE_GALLERY_REQUEST = "DELETE_SINGLE_GALLERY_REQUEST",
	DELETE_SINGLE_GALLERY_SUCCESS = "DELETE_SINGLE_GALLERY_SUCCESS",
	DELETE_SINGLE_GALLERY_FAILURE = "DELETE_SINGLE_GALLERY_FAILURE";

export const SOFTDELETE_SINGLE_GALLERY_REQUEST = "SOFTDELETE_SINGLE_GALLERY_REQUEST",
	SOFTDELETE_SINGLE_GALLERY_SUCCESS = "SOFTDELETE_SINGLE_GALLERY_SUCCESS",
	SOFTDELETE_SINGLE_GALLERY_FAILURE = "SOFTDELETE_SINGLE_GALLERY_FAILURE";

export const SOFTDELETE_SINGLE_GALLERY_SUBMISISSION_REQUEST =
		"SOFTDELETE_SINGLE_GALLERY_SUBMISISSION_REQUEST",
	SOFTDELETE_SINGLE_GALLERY_SUBMISISSION_SUCCESS = "SOFTDELETE_SINGLE_GALLERY_SUBMISISSION_SUCCESS",
	SOFTDELETE_SINGLE_GALLERY_SUBMISISSION_FAILURE = "SOFTDELETE_SINGLE_GALLERY_SUBMISISSION_FAILURE";

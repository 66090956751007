import { Card, Image, message } from "antd";
import { useMemo ,useEffect} from "react";
import { ErrorBoundary } from "react-error-boundary";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import BasicLayout from "../../../components/BasicLayout";
import FallBack from "../../../components/common/FallBack/FallBack";
import Notification from "../../../components/common/Notification";
import { CustomTab } from "../../../components/common/Tab/Tab";
import { getSingleAccessory } from "../../../store/actions/products/Accessory/accessory.action";

const ViewAccessory = (props) => {
	const accessoryId = +props.match.params.id;
	const dispatch = useDispatch();
	const history = useHistory();
	const admin = useSelector((state) => state.admin.admin || []);
	const permissionArr = useMemo(() => {
		if (admin && admin.template) {
			const { permissions } = admin.template;
			return permissions;
		} else if (admin && admin.accessLevel === "superAdmin") {
			return "all";
		}
		return [];
	}, [admin]); 

	const accessory = useSelector((state) => state.accessory.accessory || {});
	const location = useLocation();
	const softdelete = new URLSearchParams(location.search).get("soft-delete");

	useEffect(() => {
		dispatch(getSingleAccessory(accessoryId, softdelete)).catch((err) => {
			Notification("error", err, "", "topRight");
			history.push("/products/accessory");
		});
	}, [dispatch, history, accessoryId, softdelete]);

	let data = [];
	let isOrderEmpty = Object.keys(accessory).length === 0;
	if (!isOrderEmpty) {
		data = [
			{ title: "Id", description: accessory.id },
			{
				title: "Name",
				description: accessory.name,
			},
			{
				title: "Code",
				description: accessory.code,
			},
			{
				title: "Price",
				description: accessory.price ? accessory.price : "NA",
			},
			{
				title: "Stock",
				description: accessory.stock ? accessory.stock : "NA",
			},
			{
				title: "Discount",
				description: accessory.discount ? `${accessory.discount}%` : "NA",
			},
			{
				title: "HSN/SAC Code",
				description: accessory?.hsnCode ? accessory?.hsnCode?.code : "NA",
			},
			{
				title: "Received",
				description: new Date(accessory.createdAt).toLocaleString(),
			},
			{
				title: "Image",
				description: (
					<div className="gallery-images">
						<div className="image-holder-container">
							<Image width={250} height={180} src={accessory.url} />
						</div>
					</div>
				),
			},
		];
	}

	const handleError = (error) => {
		message.error(error.message);
	};
	let operationData = {
		deletedBy: accessory?.deletedBy,
		updatedBy: accessory?.updatedBy,
		restoredBy: accessory?.restoredBy,
	};
	return (
		<BasicLayout accessLevel={props.accessLevel}>
			<ErrorBoundary FallbackComponent={FallBack} onError={handleError}>
				<Card className="enqiury-detail-card" data-testid="enqiury-detail-card">
					<CustomTab
						update={
							permissionArr === "all"
								? "true"
								: permissionArr?.products?.accessory.filter((item) => item === "update")?.length > 0
								? "true"
								: false
						}
						data={data}
						operationData={operationData}
						redirectUrl={`/products/accessory/update/${accessoryId}`}
					/>
				</Card>
			</ErrorBoundary>
		</BasicLayout>
	);
};

export default ViewAccessory;

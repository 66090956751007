import { Image } from "antd";
import Text from "antd/lib/typography/Text";
import React from "react";
import "./tab.css";

export function NoHistoryPresent() {
	return (
		<div className="no-data-found-image-parent">
			<Image
				src="/assets/image/nodatafound.svg"
				alt="No data found image"
				className="no-data-found-image"
			/>
			<Text className="no-history-present-text">No history present</Text>
		</div>
	);
}

import Notification from "../components/common/Notification";

export const imageSizeDependancy = (fileRejections) => {
	fileRejections.forEach((file) => {
		file.errors.forEach((err) => {
			if (err.code === "file-too-large") {
				const message = "Max allowed file size is 1 MB ";
				Notification("error", message, "", "topRight");
			}
		});
	});
};

import Notification from "../../../components/common/Notification";
import * as otherApi from "../../../services/other";
import {
	GET_DRAWER_TOGGLE,
	GET_INNOVATION_FAILURE,
	GET_INNOVATION_REQUEST,
	GET_INNOVATION_SUCCESS,
	GET_NEWSLETTER_FAILURE,
	GET_NEWSLETTER_REQUEST,
	GET_NEWSLETTER_SUCCESS,
	GET_SINGLE_INNOVATION_FAILURE,
	GET_SINGLE_INNOVATION_REQUEST,
	GET_SINGLE_INNOVATION_SUCCESS,
	GET_SOFT_DELETED_ITEMS_FAILURE,
	GET_SOFT_DELETED_ITEMS_REQUEST,
	GET_SOFT_DELETED_ITEMS_SUCCESS,
	PERMANENT_DELETE_ITEM_FAILURE,
	PERMANENT_DELETE_ITEM_REQUEST,
	PERMANENT_DELETE_ITEM_SUCCESS,
	RESTORE_ITEM_FAILURE,
	RESTORE_ITEM_REQUEST,
	RESTORE_ITEM_SUCCESS,
	SOFTDELETE_SINGLE_INNOVATION_FAILURE,
	SOFTDELETE_SINGLE_INNOVATION_REQUEST,
	SOFTDELETE_SINGLE_INNOVATION_SUCCESS,
	SOFTDELETE_SINGLE_NEWSLETTER_FAILURE,
	SOFTDELETE_SINGLE_NEWSLETTER_REQUEST,
	SOFTDELETE_SINGLE_NEWSLETTER_SUCCESS,
	UPDATE_DELETED_ITEMS_AS_EMPTY_SUCCESS,
} from "./other.actionType";

export const requestNewsLetter = () => {
	return {
		type: GET_NEWSLETTER_REQUEST,
	};
};

export const getNewsLetterSuccess = (data) => {
	return {
		type: GET_NEWSLETTER_SUCCESS,
		data,
	};
};

export const getNewsLetterFailure = (message) => {
	return {
		type: GET_NEWSLETTER_FAILURE,
		message,
	};
};

export const getNewsLetter = () => {
	return (dispatch) => {
		dispatch(requestNewsLetter());
		return otherApi
			.getNewsLetter()
			.then((resp) => {
				const data = resp.data.data;
				dispatch(getNewsLetterSuccess(data));
			})
			.catch((err) => {
				const errorMessage = err.response && err.response.data.message;
				dispatch(getNewsLetterFailure(errorMessage));
			});
	};
};

export const requestSoftDeleteNewsLetter = () => {
	return {
		type: SOFTDELETE_SINGLE_NEWSLETTER_REQUEST,
	};
};

export const softDeleteNewsLetterSuccess = (data) => {
	return {
		type: SOFTDELETE_SINGLE_NEWSLETTER_SUCCESS,
		data,
	};
};

export const softDeleteNewsLetterFailure = (message) => {
	return {
		type: SOFTDELETE_SINGLE_NEWSLETTER_FAILURE,
		message,
	};
};

export const softDeleteNewsLetter = (id, reason) => {
	return (dispatch) => {
		dispatch(requestSoftDeleteNewsLetter());
		return otherApi
			.softDeleteSingleNewsLetter(id, reason)
			.then((resp) => {
				const message = resp.data.message;
				dispatch(softDeleteNewsLetterSuccess(id));
				Notification("success", message, "", "topRight");
			})
			.catch((err) => {
				const errorMessage = err.response && err.response.data.message;
				dispatch(softDeleteNewsLetterFailure(errorMessage));
				Notification("error", errorMessage, "", "topRight");
			});
	};
};

export const requestInnovation = () => {
	return {
		type: GET_INNOVATION_REQUEST,
	};
};

export const getInnovationSuccess = (data) => {
	return {
		type: GET_INNOVATION_SUCCESS,
		data,
	};
};

export const getInnovationFailure = (message) => {
	return {
		type: GET_INNOVATION_FAILURE,
		message,
	};
};

export const getInnovations = () => {
	return (dispatch) => {
		dispatch(requestInnovation());
		return otherApi
			.getInnovations()
			.then((resp) => {
				const data = resp.data.data;
				dispatch(getInnovationSuccess(data));
			})
			.catch((err) => {
				const errorMessage = err.response && err.response.data.message;
				dispatch(getInnovationFailure(errorMessage));
			});
	};
};

export const requestSingleInnovation = () => {
	return {
		type: GET_SINGLE_INNOVATION_REQUEST,
	};
};

export const getSingleInnovationSuccess = (data) => {
	return {
		type: GET_SINGLE_INNOVATION_SUCCESS,
		data,
	};
};

export const getSingleInnovationFailure = (message) => {
	return {
		type: GET_SINGLE_INNOVATION_FAILURE,
		message,
	};
};

export const getSingleInnovation = (id, softdelete) => {
	return (dispatch) => {
		dispatch(requestSingleInnovation());
		return otherApi
			.getSingleInnovation(id, softdelete)
			.then((resp) => {
				const data = resp.data.data;
				dispatch(getSingleInnovationSuccess(data));
			})
			.catch((err) => {
				const errorMessage = err.response && err.response.data.message;
				dispatch(getSingleInnovationFailure(errorMessage));
				throw errorMessage;
			});
	};
};

export const requestSoftDeleteSingleInnovation = () => {
	return {
		type: SOFTDELETE_SINGLE_INNOVATION_REQUEST,
	};
};

export const softDeleteSingleInnovationSuccess = (data) => {
	return {
		type: SOFTDELETE_SINGLE_INNOVATION_SUCCESS,
		data,
	};
};

export const softDeleteSingleInnovationFailure = (message) => {
	return {
		type: SOFTDELETE_SINGLE_INNOVATION_FAILURE,
		message,
	};
};

export const softDeleteSingleInnovation = (id, reason) => {
	return (dispatch) => {
		dispatch(requestSoftDeleteSingleInnovation());
		return otherApi
			.softDeleteSingleInnovation(id, reason)
			.then((resp) => {
				dispatch(softDeleteSingleInnovationSuccess(id));
				Notification("success", resp.data.message, "", "topRight");
			})
			.catch((err) => {
				const errorMessage = err.response && err.response.data.message;
				dispatch(softDeleteSingleInnovationFailure(errorMessage));
				Notification("error", errorMessage, "", "topRight");
			});
	};
};

export const requestGetSoftDeletedItems = () => {
	return {
		type: GET_SOFT_DELETED_ITEMS_REQUEST,
	};
};

export const getSoftDeletedItemsSuccess = (data) => {
	return {
		type: GET_SOFT_DELETED_ITEMS_SUCCESS,
		data,
	};
};

export const getSoftDeletedItemsFailure = (message) => {
	return {
		type: GET_SOFT_DELETED_ITEMS_FAILURE,
		message,
	};
};

export const getSoftDeletedItems = (url) => {
	return (dispatch) => {
		dispatch(requestGetSoftDeletedItems());
		return otherApi
			.getSoftDeletedItems(url)
			.then((resp) => {
				const data = resp.data.data;
				const softDeletedItems = data.filter((data) => data.deletedAt !== null);

				if (softDeletedItems.length === 0) {
					Notification("error", "Sorry selected category has no deleted items.", "", "topRight");
					dispatch(getSoftDeletedItemsFailure());

					return null;
				} else {
					return dispatch(getSoftDeletedItemsSuccess(softDeletedItems));
				}
			})
			.catch((err) => {
				const errorMessage = err.response && err.response.data.message;
				dispatch(getSoftDeletedItemsFailure(errorMessage));
				Notification("error", errorMessage, "", "topRight");
			});
	};
};

export const requestDeletePermanent = () => {
	return {
		type: PERMANENT_DELETE_ITEM_REQUEST,
	};
};

export const deletePermanentItemSuccess = (id) => {
	return {
		type: PERMANENT_DELETE_ITEM_SUCCESS,
		data: id,
	};
};

export const deletePermanentItemFailure = (message) => {
	return {
		type: PERMANENT_DELETE_ITEM_FAILURE,
		message,
	};
};

export const deletePermanentItem = (id, url) => {
	return (dispatch) => {
		dispatch(requestDeletePermanent());
		return otherApi
			.deletePermanentItem(id, url)
			.then((resp) => {
				dispatch(deletePermanentItemSuccess(id));
				Notification("success", resp.data.message, "", "topRight");
			})
			.catch((err) => {
				const errorMessage = (err.response && err.response.data.message) || "Something went wrong";
				dispatch(deletePermanentItemFailure(errorMessage));
				throw errorMessage;
			});
	};
};

export const requestRestoreItem = () => {
	return {
		type: RESTORE_ITEM_REQUEST,
	};
};

export const restoreItemSuccess = (id) => {
	return {
		type: RESTORE_ITEM_SUCCESS,
		data: id,
	};
};

export const restorePermanentItemFailure = (message) => {
	return {
		type: RESTORE_ITEM_FAILURE,
		message,
	};
};

export const restoreItem = (id, url, reason) => {
	return (dispatch) => {
		dispatch(requestRestoreItem());
		return otherApi
			.restoreItem(id, url, reason)
			.then((resp) => {
				dispatch(restoreItemSuccess(id));
				Notification("success", resp.data.message, "", "topRight");
			})
			.catch((err) => {
				const errorMessage = err.response && err.response.data.message;
				dispatch(restorePermanentItemFailure(errorMessage));
				throw errorMessage;
			});
	};
};

export const toggleDrawer = () => {
	return {
		type: GET_DRAWER_TOGGLE,
	};
};
// This action is created to make empty array at time of  changing of selectItemsFor
export const updateDeletedItemsAsEmptySuccess = () => {
	return {
		type: UPDATE_DELETED_ITEMS_AS_EMPTY_SUCCESS,
	};
};

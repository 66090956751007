import { Card, Image, message } from "antd";
import { useEffect,useMemo } from "react";
import { ErrorBoundary } from "react-error-boundary";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import BasicLayout from "../../../components/BasicLayout";
import FallBack from "../../../components/common/FallBack/FallBack";
import Notification from "../../../components/common/Notification";
import { CustomTab } from "../../../components/common/Tab/Tab";
import { getSingleImplementsSubAssembly } from "../../../store/actions/products/implementsSubAssembly/implementsSubAssemblies.action";

const ViewImplementSubAssembly = (props) => {
	const implementsSubAssemblyId = +props.match.params.id;
	const dispatch = useDispatch();
	const implementsSubAssembly = useSelector(
		(state) => state.implementsSubAssembly.implementsSubAssembly || {}
	);
	const history = useHistory();

	const location = useLocation();
	const softdelete = new URLSearchParams(location.search).get("soft-delete");

	const admin = useSelector((state) => state.admin.admin || []);

	const permissionArr = useMemo(() => {
		if (admin && admin.template) {
			const { permissions } = admin.template;
			return permissions;
		} else if (admin && admin.accessLevel === "superAdmin") {
			return "all";
		}
		return [];
	}, [admin]);

	useEffect(() => {
		dispatch(getSingleImplementsSubAssembly(implementsSubAssemblyId, softdelete)).catch((err) => {
			Notification("error", err, "", "topRight");
			history.push("/products/implement/sub-assembly");
		});
	}, [dispatch, history, softdelete, implementsSubAssemblyId]);

	let data = [];
	let isOrderEmpty = Object.keys(implementsSubAssembly).length === 0;
	if (!isOrderEmpty) {
		data = [
			{ title: "Id", description: implementsSubAssembly.id },
			{
				title: "Name",
				description: implementsSubAssembly.name,
			},
			{
				title: "Code",
				description: implementsSubAssembly.code,
			},
			{
				title: "Received",
				description: new Date(implementsSubAssembly.createdAt).toLocaleString(),
			},
			{
				title: "Image",
				description: (
					<div className="gallery-images">
						<div className="image-holder-container">
							<Image width={250} height={180} src={implementsSubAssembly.url} />
						</div>
					</div>
				),
			},
		];
	}

	const handleError = (error) => {
		message.error(error.message);
	};
	let operationData = {
		deletedBy: implementsSubAssembly?.deletedBy,
		updatedBy: implementsSubAssembly?.updatedBy,
		restoredBy: implementsSubAssembly?.restoredBy,
	};

	return (
		<BasicLayout accessLevel={props.accessLevel}>
			<ErrorBoundary FallbackComponent={FallBack} onError={handleError}>
				<Card className="enqiury-detail-card" data-testid="enqiury-detail-card">
					<CustomTab
						update={
							permissionArr === "all"
								? "true"
								: permissionArr?.products?.implementesSubAssembly.filter((item) => item === "update")?.length > 0
								? "true"
								: false
						}
						data={data}
						operationData={operationData}
						redirectUrl={`/products/implement/sub-assembly/update/${implementsSubAssemblyId}`}
					/>
				</Card>
			</ErrorBoundary>
		</BasicLayout>
	);
};

export default ViewImplementSubAssembly;

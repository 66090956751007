import { Button, message, TreeSelect } from "antd";
import Form from "antd/lib/form/Form";
import React, { useEffect, useMemo, useState } from "react";
import { ErrorBoundary } from "react-error-boundary";
import { useDispatch, useSelector } from "react-redux";
import { NavLink } from "react-router-dom";
import BasicLayout from "../../../components/BasicLayout";
import FallBack from "../../../components/common/FallBack/FallBack";
import Tables from "../../../components/common/Table/Table";
import useExportCsv from "../../../components/Hooks/useExportCsv";
import useTableSearch from "../../../components/Hooks/useTableSearch";
import {
	getSoftDeletedItems,
	updateDeletedItemsAsEmptySuccess,
} from "../../../store/actions/other/other.action";
import { convertEpochToDate, formatFileName } from "../../../utils/helper";
import { RestoreOrDeleteModel } from "../../../utils/RestoreOrDeleteModel";
import styles from "./RecycleBin.module.css";

const { TreeNode } = TreeSelect;

const selectTreeNode = [
	{
		id: 1,
		node: "Customer",
		value: "Customer Enquiry",
	},
	{
		id: 2,
		node: "Dealer",
		value: "Dealer Enquiry",
	},
	{
		id: 3,
		node: "Subdealer",
		value: "Subdealer Enquiry",
	},
	{
		id: 4,
		node: "Distributor",
		value: "Distributor Enquiry",
	},
	{
		id: 5,
		node: "Salespoint",
		value: "Salespoint Enquiry",
	},
	{
		id: 6,
		node: "Servicecenter",
		value: "Servicecenter Enquiry",
	},
	{
		id: 7,
		node: "Gallery ",
		value: "Gallery",
	},
	{
		id: 8,
		node: "Career",
		value: "Applications",
	},
	{
		id: 9,
		node: "Newsletters",
		value: "Newsletter",
	},
	{
		id: 11,
		node: "Innovations",
		value: "Innovation",
	},
	{
		id: 12,
		node: "Product",
		value: "Brands",
	},
	{
		id: 13,
		node: "User",
		value: "Template",
	},
];

const selectItemsFor = [
	{
		id: 1,
		value: "Customer Enquiry",
		url: "/customer/contact/soft-delete",
		permanentDeleteUrl: "/customer/contact/delete",
		restoreUrl: "/customer/contact/restore",
		viewUrl: "/customer/enquiry",
		tree: "Customer",
	},
	{
		id: 2,
		value: "Dealer Enquiry",
		url: "/dealer/contact/soft-delete",
		permanentDeleteUrl: "/dealer/contact/delete",
		restoreUrl: "/dealer/contact/restore",
		viewUrl: "/dealer/enquiry",
		tree: "Dealer",
	},
	{
		id: 3,
		value: "Subdealer Enquiry",
		url: "/subdealer/contact/soft-delete",
		permanentDeleteUrl: "/subdealer/contact/delete",
		restoreUrl: "/subdealer/contact/restore",
		viewUrl: "/sub-dealer/enquiry",
		tree: "Subdealer",
	},
	{
		id: 4,
		value: "Distributor Enquiry",
		url: "/distributor/contact/soft-delete",
		permanentDeleteUrl: "/distributor/contact/delete",
		restoreUrl: "/distributor/contact/restore",
		viewUrl: "/distributor/enquiry",
		tree: "Distributor",
	},
	{
		id: 5,
		value: "Salespoint Enquiry",
		url: "/salespoint/contact/soft-delete",
		permanentDeleteUrl: "/salespoint/contact/delete",
		restoreUrl: "/salespoint/contact/restore",
		viewUrl: "/sales-point/enquiry",
		tree: "Salespoint",
	},
	{
		id: 6,
		value: "Servicecenter Enquiry",
		url: "/servicecenter/contact/soft-delete",
		permanentDeleteUrl: "/servicecenter/contact/delete",
		restoreUrl: "/servicecenter/contact/restore",
		viewUrl: "/service-center/enquiry",
		tree: "Servicecenter",
	},

	{
		id: 7,
		value: "Added",
		url: "/gallery/soft-delete",
		permanentDeleteUrl: "/gallery/delete",
		restoreUrl: "/gallery/restore",
		viewUrl: "/gallery/added",
		tree: "Gallery ",
	},
	{
		id: 8,
		value: "Gallery Submissions",
		url: "/gallery/submission/soft-delete",
		permanentDeleteUrl: "/gallery/submission/delete",
		restoreUrl: "/gallery/submission/restore",
		viewUrl: "/gallery/submissions",
		tree: "Gallery ",
	},
	{
		id: 9,
		value: "Jobs",
		url: "/careers/jobs/soft-delete",
		permanentDeleteUrl: "/careers/jobs/delete",
		restoreUrl: "/careers/jobs/restore",
		viewUrl: "/careers/jobs",
		tree: "Career",
	},
	{
		id: 10,
		value: "Applications",
		url: "/careers/applications/soft-delete",
		permanentDeleteUrl: "/careers/applications/delete",
		restoreUrl: "/careers/applications/restore",
		viewUrl: "/careers/applications",
		tree: "Career",
	},
	{
		id: 11,
		value: "Categories",
		url: "/careers/category/soft-delete",
		permanentDeleteUrl: "/careers/category/delete",
		restoreUrl: "/careers/category/restore",
		viewUrl: "/careers/categories",
		tree: "Career",
	},
	{
		id: 12,
		value: "Innovation",
		url: "/innovation/soft-delete",
		permanentDeleteUrl: "/innovation/delete",
		restoreUrl: "/innovation/restore",
		viewUrl: "/other/innovation",
		tree: "Innovations",
	},
	{
		id: 13,
		value: "Newsletter",
		url: "/newsletter/soft-delete",
		permanentDeleteUrl: "/newsletter/delete",
		restoreUrl: "/newsletter/restore",
		tree: "Newsletters",
	},
	{
		id: 14,
		value: "Customer Info",
		url: "/customer/soft-delete",
		permanentDeleteUrl: "/customer",
		restoreUrl: "/customer/restore",
		viewUrl: "/customer/info",
		tree: "Customer",
	},
	{
		id: 15,
		value: "Brands",
		url: "/brand/soft-delete",
		permanentDeleteUrl: "/brand",
		restoreUrl: "/brand/restore",
		viewUrl: "/products/brands",
		tree: "Product",
	},
	{
		id: 16,
		value: "Product Categories",
		url: "/product/primary-category/soft-delete",
		permanentDeleteUrl: "/product/primary-category",
		restoreUrl: "/product/primary-category/restore",
		viewUrl: "/products/categories",
		tree: "Product",
	},
	{
		id: 17,
		value: "Product Secondary Categories",
		url: "/category/secondary/soft-delete",
		permanentDeleteUrl: "/category/secondary/",
		restoreUrl: "/category/secondary/restore",
		viewUrl: "/products/secondary/categories",
		tree: "Product",
	},
	{
		id: 18,
		value: "Product Technical Specifications",
		url: "/product/technicalspecification/soft-delete",
		permanentDeleteUrl: "/product/technicalspecification/",
		restoreUrl: "/product/technicalspecification/restore",
		viewUrl: "/products/technicalspecifications",
		tree: "Product",
	},
	{
		id: 19,
		value: "Implementes Sub Assembly",
		url: "/implements-sub-assembly/soft-delete",
		permanentDeleteUrl: "/implements-sub-assembly/",
		restoreUrl: "/implements-sub-assembly/restore",
		viewUrl: "/products/implement/sub-assembly",
		tree: "Product",
	},
	{
		id: 20,
		value: "Implements",
		url: "/implements/soft-delete",
		permanentDeleteUrl: "/implements",
		restoreUrl: "/implements/restore",
		viewUrl: "/products/implements",
		tree: "Product",
	},
	{
		id: 21,
		value: "Sub Assembly",
		url: "/sub-assembly/soft-delete",
		permanentDeleteUrl: "/sub-assembly/",
		restoreUrl: "/sub-assembly/restore",
		viewUrl: "/products/sub-assembly",
		tree: "Product",
	},
	{
		id: 22,
		value: "Accessory",
		url: "/product/accessory/soft-delete",
		permanentDeleteUrl: "/product/accessory/",
		restoreUrl: "/product/accessory/restore",
		viewUrl: "/products/accessory",
		tree: "Product",
	},
	{
		id: 23,
		value: "Model",
		url: "/product/model/soft-delete",
		permanentDeleteUrl: "/product/model/",
		restoreUrl: "/product/model/restore",
		viewUrl: "/products/model",
		tree: "Product",
	},
	{
		id: 24,
		value: "Spare Part",
		url: "/product/sparepart/soft-delete",
		permanentDeleteUrl: "/product/sparepart/",
		restoreUrl: "/product/sparepart/restore",
		viewUrl: "/products/sparepart",
		tree: "Product",
	},
	{
		id: 25,
		value: "HSN Code",
		url: "/product/hsncode/soft-delete",
		permanentDeleteUrl: "/product/hsncode/",
		restoreUrl: "/product/hsncode/restore",
		viewUrl: "/products/hsncode",
		tree: "Product",
	},
	{
		id: 26,
		value: "User Template",
		url: "/template/soft-delete/all",
		permanentDeleteUrl: "/template/",
		restoreUrl: "/template/restore",
		viewUrl: "/usermanagement/template",
		tree: "User",
	},
	{
		id: 27,
		value: "Users",
		url: "/user/soft-delete/all",
		permanentDeleteUrl: "/user/",
		restoreUrl: "/user/restore",
		viewUrl: "/usermanagement/user",
		tree: "User",
	},
];

const Index = (props) => {
	const dispatch = useDispatch();
	const [exportToCSV] = useExportCsv();
	const deletedItems = useSelector((state) => state.other.deletedItems || []);
	const [findTypeValue, setFindTypeValue] = useState("Customer Enquiry");
	const [getColumnSearchProps] = useTableSearch();
	const [isModalVisible, setIsModalVisible] = useState(false);
	// To Set Columns in Table
	const columns = [
		{
			title: "Id",
			dataIndex: "id",
			key: "id",
		},
		{
			title: "User Name",
			dataIndex: "userName",
			key: "userName",
			...getColumnSearchProps("userName"),
		},
		{
			title: "Category",
			dataIndex: "userCategory",
			key: "userCategory",
			...getColumnSearchProps("userCategory"),
		},
		{
			title: "PhoneNumber",
			dataIndex: "phoneNumber",
			key: "phoneNumber",
			...getColumnSearchProps("phoneNumber"),
		},
		{
			title: "Email",
			dataIndex: "email",
			key: "email",
			...getColumnSearchProps("email"),
		},
		{
			title: "Name",
			dataIndex: "name",
			key: "name",
			...getColumnSearchProps("name"),
		},
		{
			title: "Contact",
			dataIndex: "phonenumber",
			key: "phonenumber",
			...getColumnSearchProps("phonenumber"),
		},
		{
			title: "Contact",
			dataIndex: "contact",
			key: "contact",
			...getColumnSearchProps("contact"),
		},
		{
			title: "SubDealer Contact Person",
			dataIndex: "subDealerContactPersonName",
			key: "subDealerContactPersonName",
			...getColumnSearchProps("subDealerContactPersonName"),
		},
		{
			title: "Dealer Contact Person",
			dataIndex: "dealerContactPersonName",
			key: "dealerContactPersonName",
			...getColumnSearchProps("dealerContactPersonName"),
		},
		{
			title: "Distributor Contact Person",
			dataIndex: "distributorContactPersonName",
			key: "distributorContactPersonName",
			...getColumnSearchProps("distributorContactPersonName"),
		},
		{
			title: "Salespoint Contact Person",
			dataIndex: "salesPointContactPersonName",
			key: "salesPointContactPersonName",
			...getColumnSearchProps("salesPointContactPersonName"),
		},
		{
			title: "ServiceCenter Contact Person",
			dataIndex: "serviceCenterContactPersonName",
			key: "serviceCenterContactPersonName",
			...getColumnSearchProps("serviceCenterContactPersonName"),
		},
		{
			title: "Gallery Submission Name",
			dataIndex: "submissionName",
			key: "submissionName",
			...getColumnSearchProps("submissionName"),
		},
		{
			title: "Position",
			dataIndex: "position",
			key: "position",
			...getColumnSearchProps("position"),
		},

		{
			title: "Category",
			dataIndex: "category",
			key: "category",
			...getColumnSearchProps("category"),
		},
		{
			title: "Experience Required",
			dataIndex: "experience",
			key: "experience",
			...getColumnSearchProps("experience"),
		},
		{
			title: "Type",
			dataIndex: "type",
			key: "type",
			...getColumnSearchProps("type"),
		},
		{
			title: "Experience Title",
			dataIndex: "title",
			key: "title",
			...getColumnSearchProps("title"),
		},
		{
			title: "Title",
			dataIndex: "title",
			key: "title",
			...getColumnSearchProps("title"),
		},
		{
			title: "Email Sent",
			dataIndex: "totalEmailSent",
			key: "totalEmailSent",
		},
		{
			title: "First Name",
			dataIndex: "firstname",
			key: "firstname",
			...getColumnSearchProps("firstname"),
		},
		{
			title: "Active",
			dataIndex: "status",
			key: "status",
			...getColumnSearchProps("status"),
		},
		{
			title: "Applied For",
			dataIndex: "appliedFor",
			key: "appliedFor",
			...getColumnSearchProps("appliedFor"),
		},
		{
			title: "Location",
			dataIndex: "location",
			key: "location",
			...getColumnSearchProps("location"),
		},
		{
			title: "State",
			dataIndex: "state",
			key: "state",
		},
		{
			title: "District",
			dataIndex: "district",
			key: "district",
		},
		{
			title: "Can Join from",
			dataIndex: "availableAfter",
			key: "availableAfter",
			...getColumnSearchProps("availableAfter"),
		},
		{
			title: "Code",
			dataIndex: "code",
			key: "code",
			...getColumnSearchProps("code"),
		},
		{
			title: "Product Number",
			dataIndex: "productNumber",
			key: "productNumber",
			...getColumnSearchProps("productNumber"),
		},
		{
			title: "Spare Part Number",
			dataIndex: "sparePartNumber",
			key: "sparePartNumber",
			...getColumnSearchProps("sparePartNumber"),
		},
		{
			title: "Received",
			dataIndex: "createdAt",
			key: "createdAt",
		},
		{
			title: "Deletion Time",
			dataIndex: "deletedAt",
			key: "deletedAt",
		},
		{
			title: "Action",
			key: "action",
			dataIndex: "action",
			render: (_text, record) => (
				<RestoreOrDeleteModel
					record={record}
					setIsModalVisible={setIsModalVisible}
					isModalVisible={isModalVisible}
					linkUrl="/other/recyclebin"
					cancelMessage="Item restoring has been cancelled"
					selectItemsFor={selectItemsFor}
					findTypeValue={findTypeValue}
				/>
			),
		},
	];

	// To set Data Source to pass in table of recycle bin
	let dataSource = [];
	if (deletedItems.length > 0) {
		dataSource = deletedItems.sort(function (a, b) {
			return new Date(b.updatedAt) - new Date(a.updatedAt);
		});

		dataSource = dataSource.map((row, otherindex) => ({
			...row,
			createdAt: new Date(row.createdAt).toLocaleString(),
			deletedAt: new Date(row.deletedAt).toLocaleString(),
			key: row.id,
		}));
		if (findTypeValue === "Newsletter") {
			dataSource = dataSource.map((row, index) => ({
				...row,
				status: row.status ? "Yes" : "No",
			}));
		} else if (findTypeValue === "Applications") {
			dataSource = dataSource.map((row, index) => ({
				...row,
				status: row.status ? "Yes" : "no",
				availableAfter: convertEpochToDate(row.availableAfter),
				appliedFor: row?.job?.position,
			}));
		} else if (findTypeValue === "Jobs") {
			dataSource = dataSource.map((row, index) => ({
				...row,
				status: row.status ? "Yes" : "no",
				experience: row.experience + " " + row.experienceUnit,
				category: row.category ? row?.category?.title : "",
			}));
		} else if (findTypeValue === "Added") {
			dataSource = dataSource.map((row, index) => ({
				...row,
				createdAt: new Date(row.createdAt).toLocaleString(),
				submissionName: row?.gallerySubmission ? (
					<NavLink to={`/gallery/submissions/${row?.gallerySubmission?.id}`}>
						{row?.gallerySubmission?.name}
					</NavLink>
				) : (
					"Added by admin"
				),
				key: row.id,
			}));
		} else {
			dataSource = dataSource.map((row, index) => ({
				...row,
			}));
		}
	}

	const admin = useSelector((state) => state?.admin?.admin || []);

	const permissionArr = useMemo(() => {
		if (admin && admin.template) {
			const { permissions } = admin.template;
			return permissions;
		}
		return [];
	}, [admin]);

	// To set Unique Value in usestate and use it in component
	const handleChange = (e) => {
		dispatch(updateDeletedItemsAsEmptySuccess());
		setFindTypeValue(e);
	};

	// To fetch data
	const handleSearch = async () => {
		// To Grab Unique url to pass api
		const dataToSearch = selectItemsFor.find((data) => data.value === findTypeValue);
		// Passing Action with Unique Url
		await dispatch(getSoftDeletedItems(dataToSearch.url));
	};

	// To Show Column in recycle bin as selected field
	let filteredColumns;
	if (findTypeValue === "Customer Enquiry") {
		filteredColumns = columns.filter(
			(data) =>
				data.dataIndex === "id" ||
				data.dataIndex === "email" ||
				data.dataIndex === "name" ||
				data.dataIndex === "phonenumber" ||
				data.dataIndex === "state" ||
				data.dataIndex === "district" ||
				data.dataIndex === "createdAt" ||
				data.dataIndex === "deletedAt" ||
				data.dataIndex === "action"
		);
	} else if (findTypeValue === "Customer Info") {
		filteredColumns = columns.filter(
			(data) =>
				data.dataIndex === "id" ||
				data.dataIndex === "firstname" ||
				data.dataIndex === "phonenumber" ||
				data.dataIndex === "state" ||
				data.dataIndex === "taluka" ||
				data.dataIndex === "createdAt" ||
				data.dataIndex === "deletedAt" ||
				data.dataIndex === "action"
		);
	} else if (findTypeValue === "Dealer Enquiry") {
		filteredColumns = columns.filter(
			(data) =>
				data.dataIndex === "id" ||
				data.dataIndex === "email" ||
				data.dataIndex === "name" ||
				data.dataIndex === "phonenumber" ||
				data.dataIndex === "dealerContactPersonName" ||
				data.dataIndex === "state" ||
				data.dataIndex === "district" ||
				data.dataIndex === "createdAt" ||
				data.dataIndex === "deletedAt" ||
				data.dataIndex === "action"
		);
	} else if (findTypeValue === "Distributor Enquiry") {
		filteredColumns = columns.filter(
			(data) =>
				data.dataIndex === "id" ||
				data.dataIndex === "email" ||
				data.dataIndex === "name" ||
				data.dataIndex === "phonenumber" ||
				data.dataIndex === "distributorContactPersonName" ||
				data.dataIndex === "state" ||
				data.dataIndex === "district" ||
				data.dataIndex === "createdAt" ||
				data.dataIndex === "action" ||
				data.dataIndex === "deletedAt"
		);
	} else if (findTypeValue === "Subdealer Enquiry") {
		filteredColumns = columns.filter(
			(data) =>
				data.dataIndex === "id" ||
				data.dataIndex === "subDealerContactPersonName" ||
				data.dataIndex === "email" ||
				data.dataIndex === "name" ||
				data.dataIndex === "phonenumber" ||
				data.dataIndex === "state" ||
				data.dataIndex === "district" ||
				data.dataIndex === "createdAt" ||
				data.dataIndex === "action" ||
				data.dataIndex === "deletedAt"
		);
	} else if (findTypeValue === "Salespoint Enquiry") {
		filteredColumns = columns.filter(
			(data) =>
				data.dataIndex === "id" ||
				data.dataIndex === "email" ||
				data.dataIndex === "name" ||
				data.dataIndex === "phonenumber" ||
				data.dataIndex === "salespointContactPersonName" ||
				data.dataIndex === "state" ||
				data.dataIndex === "district" ||
				data.dataIndex === "createdAt" ||
				data.dataIndex === "action" ||
				data.dataIndex === "deletedAt"
		);
	} else if (findTypeValue === "Servicecenter Enquiry") {
		filteredColumns = columns.filter(
			(data) =>
				data.dataIndex === "id" ||
				data.dataIndex === "email" ||
				data.dataIndex === "name" ||
				data.dataIndex === "phonenumber" ||
				data.dataIndex === "servicecenterContactPersonName" ||
				data.dataIndex === "state" ||
				data.dataIndex === "district" ||
				data.dataIndex === "createdAt" ||
				data.dataIndex === "action" ||
				data.dataIndex === "deletedAt"
		);
	} else if (findTypeValue === "Added") {
		filteredColumns = columns.filter(
			(data) =>
				data.dataIndex === "id" ||
				data.dataIndex === "name" ||
				data.dataIndex === "submissionName" ||
				data.dataIndex === "category" ||
				data.dataIndex === "type" ||
				data.dataIndex === "action" ||
				data.dataIndex === "deletedAt"
		);
	} else if (findTypeValue === "Gallery Submissions") {
		filteredColumns = columns.filter(
			(data) =>
				data.dataIndex === "id" ||
				data.dataIndex === "name" ||
				data.title === "Experience Title" ||
				data.dataIndex === "createdAt" ||
				data.dataIndex === "action" ||
				data.dataIndex === "deletedAt"
		);
	} else if (findTypeValue === "Newsletter") {
		filteredColumns = columns.filter(
			(data) =>
				data.dataIndex === "id" ||
				data.dataIndex === "email" ||
				data.dataIndex === "totalEmailSent" ||
				data.dataIndex === "status" ||
				data.dataIndex === "createdAt" ||
				data.dataIndex === "action" ||
				data.dataIndex === "deletedAt"
		);
	} else if (findTypeValue === "Innovation") {
		filteredColumns = columns.filter(
			(data) =>
				data.dataIndex === "id" ||
				data.dataIndex === "email" ||
				data.dataIndex === "name" ||
				data.dataIndex === "contact" ||
				data.dataIndex === "state" ||
				data.dataIndex === "district" ||
				data.dataIndex === "createdAt" ||
				data.dataIndex === "action" ||
				data.dataIndex === "deletedAt"
		);
	} else if (findTypeValue === "Jobs") {
		filteredColumns = columns.filter(
			(data) =>
				data.dataIndex === "id" ||
				data.dataIndex === "position" ||
				data.dataIndex === "category" ||
				data.dataIndex === "experience" ||
				data.dataIndex === "type" ||
				data.dataIndex === "createdAt" ||
				data.dataIndex === "action" ||
				data.dataIndex === "deletedAt"
		);
	} else if (findTypeValue === "Categories") {
		filteredColumns = columns.filter(
			(data) =>
				data.dataIndex === "id" ||
				data.title === "Title" ||
				data.dataIndex === "action" ||
				data.dataIndex === "deletedAt"
		);
	} else if (findTypeValue === "Applications") {
		filteredColumns = columns.filter(
			(data) =>
				data.dataIndex === "id" ||
				data.dataIndex === "email" ||
				data.dataIndex === "name" ||
				data.dataIndex === "appliedFor" ||
				data.dataIndex === "state" ||
				data.dataIndex === "district" ||
				data.dataIndex === "availableAfter" ||
				data.dataIndex === "createdAt" ||
				data.dataIndex === "action" ||
				data.dataIndex === "deletedAt"
		);
	} else if (
		findTypeValue === "Brands" ||
		findTypeValue === "Product Categories" ||
		findTypeValue === "Product Secondary Categories" ||
		findTypeValue === "Implementes Sub Assembly" ||
		findTypeValue === "Implements" ||
		findTypeValue === "Sub Assembly" ||
		findTypeValue === "Accessory"
	) {
		filteredColumns = columns.filter(
			(data) =>
				data.dataIndex === "id" ||
				data.dataIndex === "code" ||
				data.dataIndex === "name" ||
				data.dataIndex === "createdAt" ||
				data.dataIndex === "deletedAt" ||
				data.dataIndex === "action"
		);
	} else if (findTypeValue === "Product Technical Specifications") {
		filteredColumns = columns.filter(
			(data) =>
				data.dataIndex === "id" ||
				data.dataIndex === "name" ||
				data.dataIndex === "createdAt" ||
				data.dataIndex === "deletedAt" ||
				data.dataIndex === "action"
		);
	} else if (findTypeValue === "Model") {
		filteredColumns = columns.filter(
			(data) =>
				data.dataIndex === "id" ||
				data.dataIndex === "name" ||
				data.dataIndex === "productNumber" ||
				data.dataIndex === "createdAt" ||
				data.dataIndex === "deletedAt" ||
				data.dataIndex === "action"
		);
	} else if (findTypeValue === "Spare Part") {
		filteredColumns = columns.filter(
			(data) =>
				data.dataIndex === "id" ||
				data.dataIndex === "name" ||
				data.dataIndex === "sparePartNumber" ||
				data.dataIndex === "createdAt" ||
				data.dataIndex === "deletedAt" ||
				data.dataIndex === "action"
		);
	} else if (findTypeValue === "HSN Code") {
		filteredColumns = columns.filter(
			(data) =>
				data.dataIndex === "id" ||
				data.dataIndex === "code" ||
				data.dataIndex === "igst" ||
				data.dataIndex === "cgst" ||
				data.dataIndex === "hgst" ||
				data.dataIndex === "createdAt" ||
				data.dataIndex === "deletedAt" ||
				data.dataIndex === "action"
		);
	} else if (findTypeValue === "User Template") {
		filteredColumns = columns.filter(
			(data) =>
				data.dataIndex === "id" ||
				data.dataIndex === "name" ||
				data.dataIndex === "createdAt" ||
				data.dataIndex === "deletedAt" ||
				data.dataIndex === "action"
		);
	} else if (findTypeValue === "Users") {
		filteredColumns = columns.filter(
			(data) =>
				data.dataIndex === "id" ||
				data.dataIndex === "userName" ||
				data.dataIndex === "email" ||
				data.dataIndex === "phoneNumber" ||
				data.dataIndex === "userCategory" ||
				data.dataIndex === "createdAt" ||
				data.dataIndex === "deletedAt" ||
				data.dataIndex === "action"
		);
	}
	// Error Boundary Error Handling
	const handleError = (error) => {
		message.error(error.message);
	};

	// To set File name while download
	const fileName = formatFileName("recycle", "xlsx",admin);
	let downloadData = deletedItems.map((deletedItem) => {
		if (findTypeValue === "Applications") {
			return Object.assign({}, deletedItem, {
				hasAccepted: "true",
				job: deletedItem.job.position,
			});
		} else if (findTypeValue === "Jobs") {
			return Object.assign({}, deletedItem, {
				hasAccepted: "true",
				category: deletedItem.category ? deletedItem.category.title : "",
			});
		} else if (findTypeValue === "Added") {
			return Object.assign({}, deletedItem, {
				gallerySubmission: deletedItem.gallerySubmission ? deletedItem.gallerySubmission.title : "",
			});
		} else if (findTypeValue === "Gallery Submissions") {
			if (deletedItem.imageUrls) {
				deletedItem.imageUrls.map((image, index) => {
					return Object.assign({}, (deletedItem[`imageUrls ${index}`] = image));
				});
				delete deletedItem.imageUrls;
			}
			if (deletedItem.videoUrls) {
				deletedItem.videoUrls.map((video, index) => {
					return Object.assign({}, (deletedItem[`videoUrls ${index}`] = video));
				});
				delete deletedItem.videoUrls;
			}
			return Object.assign({}, deletedItem, {
				hasAccepted: "true",
			});
		} else if (findTypeValue === "Newsletter") {
			return Object.assign({}, deletedItem);
		}
		return Object.assign({}, deletedItem, {
			hasAccepted: "true",
		});
	});
	// This takes effect on initial render for make store data empty
	useEffect(() => {
		dispatch(updateDeletedItemsAsEmptySuccess());
	}, [dispatch]);

	return (
		<BasicLayout accessLevel={props.accessLevel}>
			<ErrorBoundary FallbackComponent={FallBack} onError={handleError}>
				<div className={styles.buttonStyleToRight}>
					<Form onFinish={() => handleSearch()} className={styles.buttonStyleToRightForm}>
						<TreeSelect
							showSearch
							style={{ width: "100%" }}
							dropdownStyle={{ maxHeight: 400, overflow: "auto" }}
							placeholder="Please select"
							allowClear
							value={findTypeValue}
							className={styles.selectListStyle}
							onChange={handleChange}
						>
							{selectTreeNode.map((node) => {
								return (
									<TreeNode value={node.node} key={node.node} title={node.node} selectable={false}>
										{selectItemsFor.map((data) => {
											return data.tree === node.node ? (
												<TreeNode value={data.value} key={data.value} title={data.value}></TreeNode>
											) : null;
										})}
									</TreeNode>
								);
							})}
						</TreeSelect>

						<div className={styles.btnHolder}>
							<Button htmlType="submit" className={styles.searchButtonStyle}>
								Search
							</Button>
							{permissionArr?.other?.recycleBin?.filter((item) => item === "export")?.length > 0 &&
							downloadData.length !== 0 ? (
								<Button
									className={styles.exportBtn}
									onClick={(e) => exportToCSV(downloadData, fileName)}
								>
									Export
								</Button>
							) : null}
						</div>
					</Form>
				</div>
				<Tables
					dataSource={dataSource}
					columns={filteredColumns}
					footerText={`Total Deleted Items: ${deletedItems.length}`}
				/>
			</ErrorBoundary>
		</BasicLayout>
	);
};

export default Index;

import { Card, message } from "antd";
import { useEffect, useMemo } from "react";
import { ErrorBoundary } from "react-error-boundary";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import BasicLayout from "../../components/BasicLayout";
import FallBack from "../../components/common/FallBack/FallBack";
import Notification from "../../components/common/Notification";
import { CustomTab } from "../../components/common/Tab/Tab";
import { getSingleCustomer } from "../../store/actions/customer/customer.action";
import { checkIfObjectEmpty, isEqual } from "../../utils/helper";

const CustomerDetail = (props) => {
	const customerId = +props.match.params.id;
	const dispatch = useDispatch();
	const customer = useSelector((state) => state.customer.customer || {});

	const location = useLocation();
	const softdelete = new URLSearchParams(location.search).get("soft-delete");
	const history = useHistory();

	const admin = useSelector((state) => state.admin.admin || []);

	const permissionArr = useMemo(() => {
		if (admin && admin.template) {
			const { permissions } = admin.template;
			return permissions;
		} else if (admin && admin.accessLevel === "superAdmin") {
			return "all";
		}
		return [];
	}, [admin]);

	useEffect(() => {
		const isEmpty = checkIfObjectEmpty(customer);
		if (isEmpty || !isEqual(customer?.id, customerId)) {
			dispatch(getSingleCustomer(customerId, softdelete)).catch((err) => {
				Notification("error", err, "", "topRight");
				history.push("/customer/info");
			});
		} 
	}, [dispatch, customer, customerId, softdelete, history]);

	let data = [];
	let isOrderEmpty = Object.keys(customer).length === 0;
	if (!isOrderEmpty) {
		data = [
			{ title: "Id", description: customer.id },
			{
				title: "First Name",
				description: customer.firstname,
			},
			{
				title: "Last Name",
				description: customer.lastname,
			},
			{
				title: "Phone Number",
				description: customer.phonenumber,
			},
			{
				title: "Email",
				description: customer.email,
			},
			{
				title: "Customer State",
				description: customer.state,
			},
			{
				title: "Customer District",
				description: customer.district,
			},
			{
				title: "Customer Taluka",
				description: customer.taluka,
			},
			{
				title: "Customer Village",
				description: customer?.village || "NA",
			},
			{
				title: "Landmark",
				description: customer.landmark,
			},
			{
				title: "Address",
				description: customer.address,
			},
			{
				title: "Received",
				description: new Date(customer.createdAt).toLocaleString(),
			},
		];
	}
	const handleError = (error) => {
		message.error(error.message);
	};
	let operationData = {
		deletedBy: customer?.deletedBy,
		updatedBy: customer?.updatedBy,
		restoredBy: customer?.restoredBy,
	};
	return (
		<BasicLayout accessLevel={props.accessLevel}>
			<ErrorBoundary FallbackComponent={FallBack} onError={handleError}>
				<Card
					className="enqiury-detail-card"
					data-testid="enqiury-detail-card"
					style={{ height: "80vh", overflowY: "scroll" }}
				>
					<CustomTab
						update={
							permissionArr === "all"
								? "true"
								: permissionArr?.customer?.customerInfo.filter((item) => item === "update")
										?.length > 0
								? "true"
								: false
						}
						data={data}
						operationData={operationData}
						redirectUrl={`/customer/info/update/${customerId}`}
					/>
				</Card>
			</ErrorBoundary>
		</BasicLayout>
	);
};

export default CustomerDetail;

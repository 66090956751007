import Notification from "../../../../components/common/Notification";
import * as technicalSpecificationApi from "../../../../services/technicalspecification";
import {
	ADD_TECHNICAL_SPECIFICATION_FAILURE,
	ADD_TECHNICAL_SPECIFICATION_REQUEST,
	ADD_TECHNICAL_SPECIFICATION_SUCCESS,
	GET_SINGLE_TECHNICAL_SPECIFICATION_FAILURE,
	GET_SINGLE_TECHNICAL_SPECIFICATION_REQUEST,
	GET_SINGLE_TECHNICAL_SPECIFICATION_SUCCESS,
	GET_TECHNICAL_SPECIFICATIONS_FAILURE,
	GET_TECHNICAL_SPECIFICATIONS_REQUEST,
	GET_TECHNICAL_SPECIFICATIONS_SUCCESS,
	RESTORE_SINGLE_TECHNICAL_SPECIFICATION_FAILURE,
	RESTORE_SINGLE_TECHNICAL_SPECIFICATION_REQUEST,
	RESTORE_SINGLE_TECHNICAL_SPECIFICATION_SUCCESS,
	SOFT_DELETE_SINGLE_TECHNICAL_SPECIFICATION_FAILURE,
	SOFT_DELETE_SINGLE_TECHNICAL_SPECIFICATION_REQUEST,
	SOFT_DELETE_SINGLE_TECHNICAL_SPECIFICATION_SUCCESS,
	UPDATE_TECHNICAL_SPECIFICATION_FAILURE,
	UPDATE_TECHNICAL_SPECIFICATION_REQUEST,
	UPDATE_TECHNICAL_SPECIFICATION_SUCCESS,
} from "./technicalspecification.actionType";

// GET REQUEST
export const requestGetAllTechnicalSpecifications = () => {
	return {
		type: GET_TECHNICAL_SPECIFICATIONS_REQUEST,
	};
};
export const getAllTechnicalSpecificationsSuccess = (data) => {
	return {
		type: GET_TECHNICAL_SPECIFICATIONS_SUCCESS,
		data,
	};
};
export const getAllTechnicalSpecificationsFailure = (message) => {
	return {
		type: GET_TECHNICAL_SPECIFICATIONS_FAILURE,
		message,
	};
};

// Get All TechnicalSpecifications
export const getAllTechnicalSpecifications = () => {
	return (dispatch) => {
		dispatch(requestGetAllTechnicalSpecifications());
		return technicalSpecificationApi
			.getAllTechnicalSpecifications()
			.then((resp) => {
				const data = resp.data.data;
				dispatch(getAllTechnicalSpecificationsSuccess(data));
			})
			.catch((err) => {
				const errorMessage =
					(err?.response && err?.response?.data?.message) || "Something went wrong!";
				dispatch(getAllTechnicalSpecificationsFailure(errorMessage));
			});
	};
};

export const requestGetSingleTechnicalSpecification = () => {
	return {
		type: GET_SINGLE_TECHNICAL_SPECIFICATION_REQUEST,
	};
};

export const getSingleTechnicalSpecificationSuccess = (data) => {
	return {
		type: GET_SINGLE_TECHNICAL_SPECIFICATION_SUCCESS,
		data,
	};
};

export const getSingleTechnicalSpecificationFailure = (message) => {
	return {
		type: GET_SINGLE_TECHNICAL_SPECIFICATION_FAILURE,
		message,
	};
};

export const getSingleTechnicalSpecification = (id, type) => {
	return (dispatch) => {
		dispatch(requestGetSingleTechnicalSpecification());
		return technicalSpecificationApi
			.getSingleTechnicalSpecification(id, type)
			.then((resp) => {
				const data = resp.data.data;
				dispatch(getSingleTechnicalSpecificationSuccess(data));
			})
			.catch((err) => {
				const errorMessage =
					(err?.response && err?.response?.data?.message) || "Something went wrong";
				dispatch(getSingleTechnicalSpecificationFailure(errorMessage));
				throw errorMessage;
			});
	};
};

export const requestSoftDeleteSingleTechnicalSpecification = () => {
	return {
		type: SOFT_DELETE_SINGLE_TECHNICAL_SPECIFICATION_REQUEST,
	};
};

export const softDeleteSingleTechnicalSpecificationSuccess = (data) => {
	return {
		type: SOFT_DELETE_SINGLE_TECHNICAL_SPECIFICATION_SUCCESS,
		data,
	};
};

export const softDeleteSingleTechnicalSpecificationFailure = (message) => {
	return {
		type: SOFT_DELETE_SINGLE_TECHNICAL_SPECIFICATION_FAILURE,
		message,
	};
};

export const softDeleteSingleTechnicalSpecification = (id, reason) => {
	return (dispatch) => {
		dispatch(requestSoftDeleteSingleTechnicalSpecification());
		return technicalSpecificationApi
			.softDeleteSingleTechnicalSpecification(id, reason)
			.then((resp) => {
				dispatch(softDeleteSingleTechnicalSpecificationSuccess(id));
				return Notification("success", resp.data.message, "", "topRight");
			})
			.catch((err) => {
				const errorMessage =
					(err?.response && err?.response?.data?.message) || "Something went wrong";
				dispatch(softDeleteSingleTechnicalSpecificationFailure(errorMessage));
				throw errorMessage;
			});
	};
};

export const requestTechnicalSpecificationRestore = () => {
	return {
		type: RESTORE_SINGLE_TECHNICAL_SPECIFICATION_REQUEST,
	};
};

export const restoreTechnicalSpecificationSuccess = () => {
	return {
		type: RESTORE_SINGLE_TECHNICAL_SPECIFICATION_SUCCESS,
	};
};

export const restoreTechnicalSpecificationFailure = (message) => {
	return {
		type: RESTORE_SINGLE_TECHNICAL_SPECIFICATION_FAILURE,
		message,
	};
};

export const restoreTechnicalSpecification = (id) => {
	return (dispatch) => {
		dispatch(requestTechnicalSpecificationRestore());
		return technicalSpecificationApi
			.restoreTechnicalSpecification(id)
			.then((resp) => {
				dispatch(restoreTechnicalSpecificationSuccess(id));
				Notification("success", resp.data.message, "", "topRight");
			})
			.catch((err) => {
				const errorMessage = (err.response && err.response.data.message) || "Something went wrong!";
				dispatch(restoreTechnicalSpecificationFailure(errorMessage));
			});
	};
};

export const requestTechnicalSpecificationAdd = () => {
	return {
		type: ADD_TECHNICAL_SPECIFICATION_REQUEST,
	};
};

export const technicalSpecificationAddSuccess = (data) => {
	return {
		type: ADD_TECHNICAL_SPECIFICATION_SUCCESS,
		data,
	};
};

export const technicalSpecificationAddAddFailure = (message) => {
	return {
		type: ADD_TECHNICAL_SPECIFICATION_FAILURE,
		message,
	};
};

export const addTechnicalSpecification = (data) => {
	return (dispatch) => {
		dispatch(requestTechnicalSpecificationAdd());
		return technicalSpecificationApi
			.addTechnicalSpecification(data)
			.then((resp) => {
				const message = resp.data.message;
				dispatch(technicalSpecificationAddSuccess());
				Notification("success", "", message);
			})
			.catch((err) => {
				const errorMessage = err.response && err.response.data.message;
				dispatch(technicalSpecificationAddAddFailure(errorMessage));
				Notification("error", "Error Occurred", errorMessage);
			});
	};
};

//FOR UPDATE TechnicalSpecification
export const requestUpdateTechnicalSpecification = () => {
	return {
		type: UPDATE_TECHNICAL_SPECIFICATION_REQUEST,
	};
};

export const updateTechnicalSpecificationSuccess = () => {
	return {
		type: UPDATE_TECHNICAL_SPECIFICATION_SUCCESS,
	};
};

export const updateTechnicalSpecificationFailure = (message) => {
	return {
		type: UPDATE_TECHNICAL_SPECIFICATION_FAILURE,
		message,
	};
};

export const updateTechnicalSpecification = (id, data) => {
	return (dispatch) => {
		dispatch(requestUpdateTechnicalSpecification());
		return technicalSpecificationApi
			.updateTechnicalSpecification(id, data)
			.then((resp) => {
				const message = resp.data.message;
				dispatch(updateTechnicalSpecificationSuccess());
				Notification("success", "", message);
			})
			.catch((err) => {
				const errorMessage = err.response && err.response.data.message;
				dispatch(updateTechnicalSpecificationFailure(errorMessage));
				throw err;
			});
	};
};

export const invokeApi = (axiosInstance, path, body = {}, method = "GET") => {
	return axiosInstance({
		method: method,
		url: path,
		data: JSON.stringify(body),
		withCredentials: true,
	})
		.then((resp) => resp)
		.catch((err) => {
			throw err;
		});
};

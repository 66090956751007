import {
	GET_CUSTOMERS_INFO_SUCCESS,
	GET_CUSTOMER_CONTACTS_SUCCESS,
	GET_CUSTOMER_SINGLE_CONTACT_SUCCESS,
	GET_SINGLE_CUSTOMER_SUCCESS,
	RESTORE_CUSTOMER_SINGLE_CONTACT_SUCCESS,
	SOFTDELETE_CUSTOMER_SINGLE_CONTACT_SUCCESS,
	SOFTDELETE_SINGLE_CUSTOMER_SUCCESS,
	UPDATE_SINGLE_CUSTOMER_SUCCESS,
	// UPDATE_CUSTOMER_ENQUIRY_SUCCESS,
} from "../actions/customer/customer.actionType";
import globalState from "../state";

const requestRegex = /_REQUEST/;
const errorRegex = /_FAILURE/;
const successRegex = /_SUCCESS/;

const initialState = {
	errorMessage: "",
	isLoading: false,
	enquiries: [],
	enquiry: {},
	customers: [],
	customer: {},
	...globalState,
};

const CustomerReducer = (state = initialState, action) => {
	const isRequest = requestRegex.test(action.type);
	const isError = errorRegex.test(action.type);
	const isSuccess = successRegex.test(action.type);

	if (isRequest) {
		return { ...state, isLoading: true, errorMessage: "" };
	}
	if (isError) {
		return { ...state, isLoading: false, errorMessage: action.message };
	}
	if (isSuccess) {
		state = { ...state, isLoading: false, errorMessage: "" };
	}

	switch (action.type) {
		case GET_CUSTOMER_CONTACTS_SUCCESS:
			return {
				...state,
				enquiries: action.data,
			};

		case GET_CUSTOMER_SINGLE_CONTACT_SUCCESS:
			return {
				...state,
				enquiry: action?.data,
			};

		case SOFTDELETE_CUSTOMER_SINGLE_CONTACT_SUCCESS:
			const enquiriesDeletionId = action.data;
			const nonDeletedenquiries = state.enquiries.filter(
				(enquiries) => enquiries.id !== enquiriesDeletionId
			);
			return {
				...state,
				enquiries: nonDeletedenquiries,
			};

		case RESTORE_CUSTOMER_SINGLE_CONTACT_SUCCESS:
			return {
				...state,
			};

		case GET_CUSTOMERS_INFO_SUCCESS:
			return {
				...state,
				customers: action.data,
			};

		case GET_SINGLE_CUSTOMER_SUCCESS:
			return {
				...state,
				customer: action.data,
			};

		case SOFTDELETE_SINGLE_CUSTOMER_SUCCESS:
			const customerDeletionId = action.data;
			const nonDeletedcustomers = state.customers.filter(
				(customer) => customer.id !== customerDeletionId
			);
			return {
				...state,
				customers: nonDeletedcustomers,
			};

		case UPDATE_SINGLE_CUSTOMER_SUCCESS:
			let resp = { ...state };
			const id = action.data.id;
			const customerData = action.data;
			if (state.customers.length > 0) {
				const index = state.customers.findIndex((customer) => customer.id === id);
				const newArray = [...state.customers];
				newArray[index] = {
					...customerData,
					createdAt: state.customers[index].createdAt,
					updatedAt: Date.now(),
				};
				resp.customers = newArray;
			}
			const newObject = {
				customerData,
				createdAt: state.customer.createdAt,
				updatedAt: Date.now(),
			};
			resp.customer = newObject;
			return resp;

		// case UPDATE_CUSTOMER_ENQUIRY_SUCCESS:
		// 	// let response = { ...state };
		// 	// const enquiryId = action.data.enquiryId;
		// 	// const enquiryData = action.data;
		// 	// if (state.enquiries.length > 0) {
		// 	// 	const index = state.enquiries.findIndex((enquiry) => enquiry.enquiryId === enquiryId);
		// 	// 	const newArray = [...state.enquiries];
		// 	// 	newArray[index] = {
		// 	// 		...enquiryData,
		// 	// 		createdAt: state.enquiries[index].createdAt,
		// 	// 		updatedAt: Date.now(),
		// 	// 	};
		// 	// 	response.enquiries = newArray;
		// 	// }
		// 	// const newEnquiry = {
		// 	// 	customerData,
		// 	// 	createdAt: state.enquiry.createdAt,
		// 	// 	updatedAt: Date.now(),
		// 	// };
		// 	// response.enquiry = newEnquiry;
		// 	// return response;
		// 	return {
		// 		...state,
		// 	};
		default:
			return state;
	}
};

export default CustomerReducer;

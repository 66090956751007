export function dateFormatCoverter(date, time) {
	let resp = new Date(date).toLocaleString("en-GB", {
		day: "2-digit",
		month: "long",
		year: "numeric",
	});
	let day = resp.split(" ")[0] + "th";
	let month = resp.split(" ")[1];
	let year = resp.split(" ")[2];
	let formattedDate = day + " " + month + " " + year;

	let formattedTime = formatFrom24Hrsto12Hrs(time);
	return formattedDate + ", " + formattedTime;
}

export const formatFrom24Hrsto12Hrs = (time, ignoreZero = false) => {
	let [hours, minutes, seconds] = time.split(":");
	let modifier = +hours < 12 ? " AM" : " PM";
	hours = +hours % 12 || 12;
	minutes = ignoreZero && +minutes === 0 ? "" : `:${minutes}`;
	seconds = ":" + seconds;
	return hours + minutes + seconds + modifier;
};

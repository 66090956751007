import Notification from "../../../components/common/Notification";
import * as userApi from "../../../services/users";
import {
	ADD_USER_FAILURE,
	ADD_USER_REQUEST,
	ADD_USER_SUCCESS,
	GET_SINGLE_USER_FAILURE,
	GET_SINGLE_USER_REQUEST,
	GET_SINGLE_USER_SUCCESS,
	GET_USERS_FAILURE,
	GET_USERS_REQUEST,
	GET_USERS_SUCCESS,
	RESTORE_SINGLE_USER_FAILURE,
	RESTORE_SINGLE_USER_REQUEST,
	RESTORE_SINGLE_USER_SUCCESS,
	SOFT_DELETE_SINGLE_USER_FAILURE,
	SOFT_DELETE_SINGLE_USER_REQUEST,
	SOFT_DELETE_SINGLE_USER_SUCCESS,
	UPDATE_USER_FAILURE,
	UPDATE_USER_REQUEST,
	UPDATE_USER_SUCCESS,
} from "./user.actionType";

// GET REQUEST
export const requestGetAllUsers = () => {
	return {
		type: GET_USERS_REQUEST,
	};
};
export const getAllUsersSuccess = (data) => {
	return {
		type: GET_USERS_SUCCESS,
		data,
	};
};
export const getAllUsersFailure = (message) => {
	return {
		type: GET_USERS_FAILURE,
		message,
	};
};

// Get All Users
export const getAllUsers = () => {
	return (dispatch) => {
		dispatch(requestGetAllUsers());
		return userApi
			.getAllUsers()
			.then((resp) => {
				const data = resp?.data?.data;
				dispatch(getAllUsersSuccess(data));
			})
			.catch((err) => {
				const errorMessage =
					(err?.response && err?.response?.data?.message) || "Something went wrong!";
				dispatch(getAllUsersFailure(errorMessage));
			});
	};
};

export const requestGetSingleUser = () => {
	return {
		type: GET_SINGLE_USER_REQUEST,
	};
};

export const getSingleUserSuccess = (data) => {
	return {
		type: GET_SINGLE_USER_SUCCESS,
		data,
	};
};

export const getSingleUserFailure = (message) => {
	return {
		type: GET_SINGLE_USER_FAILURE,
		message,
	};
};

export const getSingleUser = (id, type) => {
	return (dispatch) => {
		dispatch(requestGetSingleUser());
		return userApi
			.getSingleUser(id, type)
			.then((resp) => {
				const data = resp?.data?.data;
				dispatch(getSingleUserSuccess(data));
			})
			.catch((err) => {
				const errorMessage =
					(err?.response && err?.response?.data?.message) || "Something went wrong";
				dispatch(getSingleUserFailure(errorMessage));
				throw errorMessage;
			});
	};
};

export const requestSoftDeleteSingleUser = () => {
	return {
		type: SOFT_DELETE_SINGLE_USER_REQUEST,
	};
};

export const softDeleteSingleUserSuccess = (data) => {
	return {
		type: SOFT_DELETE_SINGLE_USER_SUCCESS,
		data,
	};
};

export const softDeleteSingleUserFailure = (message) => {
	return {
		type: SOFT_DELETE_SINGLE_USER_FAILURE,
		message,
	};
};

export const softDeleteSingleUser = (id, reason) => {
	return (dispatch) => {
		dispatch(requestSoftDeleteSingleUser());
		return userApi
			.softDeleteSingleUser(id, reason)
			.then((resp) => {
				dispatch(softDeleteSingleUserSuccess(id));
				return Notification("success", resp.data.message, "", "topRight");
			})
			.catch((err) => {
				const errorMessage =
					(err?.response && err?.response?.data?.message) || "Something went wrong";
				dispatch(softDeleteSingleUserFailure(errorMessage));
				throw errorMessage;
			});
	};
};

export const requestUserRestore = () => {
	return {
		type: RESTORE_SINGLE_USER_REQUEST,
	};
};

export const restoreUserSuccess = () => {
	return {
		type: RESTORE_SINGLE_USER_SUCCESS,
	};
};

export const restoreUserFailure = (message) => {
	return {
		type: RESTORE_SINGLE_USER_FAILURE,
		message,
	};
};

export const restoreUser = (id) => {
	return (dispatch) => {
		dispatch(requestUserRestore());
		return userApi
			.restoreUser(id)
			.then((resp) => {
				dispatch(restoreUserSuccess(id));
				Notification("success", resp.data.message, "", "topRight");
			})
			.catch((err) => {
				const errorMessage = (err.response && err.response.data.message) || "Something went wrong!";
				dispatch(restoreUserFailure(errorMessage));
			});
	};
};

export const requestUserAdd = () => {
	return {
		type: ADD_USER_REQUEST,
	};
};

export const userAddSuccess = (data) => {
	return {
		type: ADD_USER_SUCCESS,
		data,
	};
};

export const userAddFailure = (message) => {
	return {
		type: ADD_USER_FAILURE,
		message,
	};
};

export const addUser = (data) => {
	return (dispatch) => {
		dispatch(requestUserAdd());
		return userApi
			.addUser(data)
			.then((resp) => {
			 
				return resp.data.data;
			})
			.catch((err) => {
				const errorMessage = err.response && err.response.data.message;
				dispatch(userAddFailure(errorMessage));
				throw err;
			});
	};
};

//FOR UPDATE User
export const requestUpdateUser = () => {
	return {
		type: UPDATE_USER_REQUEST,
	};
};

export const updateUserSuccess = () => {
	return {
		type: UPDATE_USER_SUCCESS,
	};
};

export const updateUserFailure = (message) => {
	return {
		type: UPDATE_USER_FAILURE,
		message,
	};
};

export const updateUser = (id, data) => {
	return (dispatch) => {
		dispatch(requestUpdateUser());
		return userApi
			.updateUser(id, data)
			.then((resp) => {
				dispatch(updateUserSuccess());

				return resp.data.data;
			})
			.catch((err) => {
				const errorMessage = err.response && err.response.data.message;
				dispatch(updateUserFailure(errorMessage));
				throw err;
			});
	};
};

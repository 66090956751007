export const GET_SPARE_PARTS_REQUEST = "GET_SPARE_PARTS_REQUEST",
	GET_SPARE_PARTS_SUCCESS = "GET_SPARE_PARTS_SUCCESS",
	GET_SPARE_PARTS_FAILURE = "GET_SPARE_PARTS_FAILURE";

export const GET_SINGLE_SPARE_PART_REQUEST = "GET_SINGLE_SPARE_PART_REQUEST",
	GET_SINGLE_SPARE_PART_SUCCESS = "GET_SINGLE_SPARE_PART_SUCCESS",
	GET_SINGLE_SPARE_PART_FAILURE = "GET_SINGLE_SPARE_PART_FAILURE";

export const SOFT_DELETE_SINGLE_SPARE_PART_REQUEST = "SOFT_DELETE_SINGLE_SPARE_PART_REQUEST",
	SOFT_DELETE_SINGLE_SPARE_PART_SUCCESS = "SOFT_DELETE_SINGLE_SPARE_PART_SUCCESS",
	SOFT_DELETE_SINGLE_SPARE_PART_FAILURE = "SOFT_DELETE_SINGLE_SPARE_PART_FAILURE";

export const RESTORE_SINGLE_SPARE_PART_REQUEST = "RESTORE_SINGLE_SPARE_PART_REQUEST",
	RESTORE_SINGLE_SPARE_PART_SUCCESS = "RESTORE_SINGLE_SPARE_PART_SUCCESS",
	RESTORE_SINGLE_SPARE_PART_FAILURE = "RESTORE_SINGLE_SPARE_PART_FAILURE";

export const ADD_SPARE_PART_REQUEST = "ADD_SPARE_PART_REQUEST",
	ADD_SPARE_PART_SUCCESS = "ADD_SPARE_PART_SUCCESS",
	ADD_SPARE_PART_FAILURE = "ADD_SPARE_PART_FAILURE";

export const UPDATE_SPARE_PART_REQUEST = "UPDATE_SPARE_PART_REQUEST",
	UPDATE_SPARE_PART_SUCCESS = "UPDATE_SPARE_PART_SUCCESS",
	UPDATE_SPARE_PART_FAILURE = "UPDATE_SPARE_PART_FAILURE";

export const MAKE_SINGLE_SPARE_PART_EMPTY = "MAKE_SINGLE_SPARE_PART_EMPTY";

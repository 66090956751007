export const GET_IMPLEMENTS_SUB_ASSEMBLIES_REQUEST = "GET_IMPLEMENTS_SUB_ASSEMBLIES_REQUEST",
	GET_IMPLEMENTS_SUB_ASSEMBLIES_SUCCESS = "GET_IMPLEMENTS_SUB_ASSEMBLIES_SUCCESS",
	GET_IMPLEMENTS_SUB_ASSEMBLIES_FAILURE = "GET_IMPLEMENTS_SUB_ASSEMBLIES_FAILURE";

export const GET_SINGLE_IMPLEMENTS_SUB_ASSEMBLY_REQUEST =
		"GET_SINGLE_IMPLEMENTS_SUB_ASSEMBLY_REQUEST",
	GET_SINGLE_IMPLEMENTS_SUB_ASSEMBLY_SUCCESS = "GET_SINGLE_IMPLEMENTS_SUB_ASSEMBLY_SUCCESS",
	GET_SINGLE_IMPLEMENTS_SUB_ASSEMBLY_FAILURE = "GET_SINGLE_IMPLEMENTS_SUB_ASSEMBLY_FAILURE";

export const SOFT_DELETE_SINGLE_IMPLEMENTS_SUB_ASSEMBLY_REQUEST =
		"SOFT_DELETE_SINGLE_IMPLEMENTS_SUB_ASSEMBLY_REQUEST",
	SOFT_DELETE_SINGLE_IMPLEMENTS_SUB_ASSEMBLY_SUCCESS =
		"SOFT_DELETE_SINGLE_IMPLEMENTS_SUB_ASSEMBLY_SUCCESS",
	SOFT_DELETE_SINGLE_IMPLEMENTS_SUB_ASSEMBLY_FAILURE =
		"SOFT_DELETE_SINGLE_IMPLEMENTS_SUB_ASSEMBLY_FAILURE";

export const RESTORE_SINGLE_IMPLEMENTS_SUB_ASSEMBLY_REQUEST =
		"RESTORE_SINGLE_IMPLEMENTS_SUB_ASSEMBLY_REQUEST",
	RESTORE_SINGLE_IMPLEMENTS_SUB_ASSEMBLY_SUCCESS = "RESTORE_SINGLE_IMPLEMENTS_SUB_ASSEMBLY_SUCCESS",
	RESTORE_SINGLE_IMPLEMENTS_SUB_ASSEMBLY_FAILURE = "RESTORE_SINGLE_IMPLEMENTS_SUB_ASSEMBLY_FAILURE";

export const ADD_IMPLEMENTS_SUB_ASSEMBLY_REQUEST = "ADD_IMPLEMENTS_SUB_ASSEMBLY_REQUEST",
	ADD_IMPLEMENTS_SUB_ASSEMBLY_SUCCESS = "ADD_IMPLEMENTS_SUB_ASSEMBLY_SUCCESS",
	ADD_IMPLEMENTS_SUB_ASSEMBLY_FAILURE = "ADD_IMPLEMENTS_SUB_ASSEMBLY_FAILURE";

export const UPDATE_IMPLEMENTS_SUB_ASSEMBLY_REQUEST = "UPDATE_IMPLEMENTS_SUB_ASSEMBLY_REQUEST",
	UPDATE_IMPLEMENTS_SUB_ASSEMBLY_SUCCESS = "UPDATE_IMPLEMENTS_SUB_ASSEMBLY_SUCCESS",
	UPDATE_IMPLEMENTS_SUB_ASSEMBLY_FAILURE = "UPDATE_IMPLEMENTS_SUB_ASSEMBLY_FAILURE";

export const GET_IMPLEMENT_RELATED_IMPLEMENTS_SUB_ASSEMBLIES_REQUEST =
		"GET_IMPLEMENT_RELATED_IMPLEMENTS_SUB_ASSEMBLIES_REQUEST",
	GET_IMPLEMENT_RELATED_IMPLEMENTS_SUB_ASSEMBLIES_SUCCESS =
		"GET_IMPLEMENT_RELATED_IMPLEMENTS_SUB_ASSEMBLIES_SUCCESS",
	GET_IMPLEMENT_RELATED_IMPLEMENTS_SUB_ASSEMBLIES_FAILURE =
		"GET_IMPLEMENT_RELATED_IMPLEMENTS_SUB_ASSEMBLIES_FAILURE";

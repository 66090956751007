import { invokeApi } from "../utils/api";
import { $axios } from "../utils/axios";

export const getAllUsers = () =>
	invokeApi($axios, "/user", {}, "GET")
		.then((resp) => resp)

		.catch((err) => {
			throw err;
		});

export const getSingleUser = (userId, type) =>
	invokeApi($axios, `/user/${type === "true" ? "soft-delete/" : ""}${userId}`, {}, "GET")
		.then((resp) => resp)
		.catch((err) => {
			throw err;
		});

export const softDeleteSingleUser = (userId, reason) =>
	invokeApi($axios, `/user/soft-delete/${userId}?reason=${reason}`, {}, "DELETE")
		.then((resp) => resp)
		.catch((err) => {
			throw err;
		});

export const restoreUser = (userId) =>
	invokeApi($axios, `/user/restore/${userId}`, {}, "PUT")
		.then((resp) => resp)
		.catch((err) => {
			throw err;
		});

export const addUser = (data) =>
	invokeApi($axios, "/user", data, "POST")
		.then((resp) => resp)
		.catch((err) => {
			throw err;
		});

export const updateUser = (id, data) =>
	invokeApi($axios, `/user/${id}`, data, "PUT")
		.then((resp) => resp)
		.catch((err) => {
			throw err;
		});

import Notification from "../../../../components/common/Notification";
import {
	ADD_CATEGORY_FAILURE,
	ADD_CATEGORY_REQUEST,
	ADD_CATEGORY_SUCCESS,
	GET_BRAND_CATEGORIES_FAILURE,
	GET_BRAND_CATEGORIES_REQUEST,
	GET_BRAND_CATEGORIES_SUCCESS,
	GET_CATEGORIES_FAILURE,
	GET_CATEGORIES_REQUEST,
	GET_CATEGORIES_SUCCESS,
	GET_SINGLE_CATEGORY_FAILURE,
	GET_SINGLE_CATEGORY_REQUEST,
	GET_SINGLE_CATEGORY_SUCCESS,
	RESTORE_SINGLE_CATEGORY_FAILURE,
	RESTORE_SINGLE_CATEGORY_REQUEST,
	RESTORE_SINGLE_CATEGORY_SUCCESS,
	SOFT_DELETE_SINGLE_CATEGORY_FAILURE,
	SOFT_DELETE_SINGLE_CATEGORY_REQUEST,
	SOFT_DELETE_SINGLE_CATEGORY_SUCCESS,
	UPDATE_CATEGORY_FAILURE,
	UPDATE_CATEGORY_REQUEST,
	UPDATE_CATEGORY_SUCCESS,
} from "./categories.actionType";
import * as categoryApi from "../../../../services/category";

// GET REQUEST
export const requestGetAllCategories = () => {
	return {
		type: GET_CATEGORIES_REQUEST,
	};
};

export const getAllCategoriesSuccess = (data) => {
	return {
		type: GET_CATEGORIES_SUCCESS,
		data,
	};
};

export const getAllCategoriesFailure = (message) => {
	return {
		type: GET_CATEGORIES_FAILURE,
		message,
	};
};

// Get All Categories
export const getAllCategories = () => {
	return (dispatch) => {
		dispatch(requestGetAllCategories());
		return categoryApi
			.getAllCategories()
			.then((resp) => {
				const data = resp.data.data;
				dispatch(getAllCategoriesSuccess(data));
			})
			.catch((err) => {
				const errorMessage =
					(err?.response && err?.response?.data?.message) || "Something went wrong!";
				dispatch(getAllCategoriesFailure(errorMessage));
			});
	};
};

export const requestGetAllBrandCategories = () => {
	return {
		type: GET_BRAND_CATEGORIES_REQUEST,
	};
};

export const getAllBrandCategoriesSuccess = (data) => {
	return {
		type: GET_BRAND_CATEGORIES_SUCCESS,
		data,
	};
};

export const getAllBrandCategoriesFailure = (message) => {
	return {
		type: GET_BRAND_CATEGORIES_FAILURE,
		message,
	};
};

// Get All Brand Categories
export const getAllBrandCategories = (brandId) => {
	return (dispatch) => {
		dispatch(requestGetAllBrandCategories());
		return categoryApi
			.getAllBrandCategories(brandId)
			.then((resp) => {
				const data = resp.data.data;
				dispatch(getAllBrandCategoriesSuccess(data));
			})
			.catch((err) => {
				const errorMessage =
					(err?.response && err?.response?.data?.message) || "Something went wrong!";
				dispatch(getAllBrandCategoriesFailure(errorMessage));
			});
	};
};

export const requestGetSingleCategory = () => {
	return {
		type: GET_SINGLE_CATEGORY_REQUEST,
	};
};

export const getSingleCategorySuccess = (data) => {
	return {
		type: GET_SINGLE_CATEGORY_SUCCESS,
		data,
	};
};

export const getSingleCategoryFailure = (message) => {
	return {
		type: GET_SINGLE_CATEGORY_FAILURE,
		message,
	};
};

export const getSingleCategory = (id, softdelete) => {
	return (dispatch) => {
		dispatch(requestGetSingleCategory());
		return categoryApi
			.getSingleCategory(id, softdelete)
			.then((resp) => {
				const data = resp.data.data;
				dispatch(getSingleCategorySuccess(data));
			})
			.catch((err) => {
				const errorMessage =
					(err?.response && err?.response?.data?.message) || "Something went wrong";
				dispatch(getSingleCategoryFailure(errorMessage));
				throw errorMessage;
			});
	};
};

export const requestSoftDeleteSingleCategory = () => {
	return {
		type: SOFT_DELETE_SINGLE_CATEGORY_REQUEST,
	};
};

export const softDeleteSingleCategorySuccess = (data) => {
	return {
		type: SOFT_DELETE_SINGLE_CATEGORY_SUCCESS,
		data,
	};
};

export const softDeleteSingleCategoryFailure = (message) => {
	return {
		type: SOFT_DELETE_SINGLE_CATEGORY_FAILURE,
		message,
	};
};

export const softDeleteSingleCategory = (id, reason) => {
	return (dispatch) => {
		dispatch(requestSoftDeleteSingleCategory());
		return categoryApi
			.softDeleteSingleCategory(id, reason)
			.then((resp) => {
				dispatch(softDeleteSingleCategorySuccess(id));
				return Notification("success", resp.data.message, "", "topRight");
			})
			.catch((err) => {
				const errorMessage =
					(err?.response && err?.response?.data?.message) || "Something went wrong";
				dispatch(softDeleteSingleCategoryFailure(errorMessage));
				return Notification("error", errorMessage, "", "topRight");
			});
	};
};

export const requestCategoryRestore = () => {
	return {
		type: RESTORE_SINGLE_CATEGORY_REQUEST,
	};
};

export const restoreCategorySuccess = () => {
	return {
		type: RESTORE_SINGLE_CATEGORY_SUCCESS,
	};
};

export const restoreCategoryFailure = (message) => {
	return {
		type: RESTORE_SINGLE_CATEGORY_FAILURE,
		message,
	};
};

export const restoreCategory = (id) => {
	return (dispatch) => {
		dispatch(requestCategoryRestore());
		return categoryApi
			.restoreCategory(id)
			.then((resp) => {
				dispatch(restoreCategorySuccess(id));
				Notification("success", resp.data.message, "", "topRight");
			})
			.catch((err) => {
				const errorMessage = (err.response && err.response.data.message) || "Something went wrong!";
				dispatch(restoreCategoryFailure(errorMessage));
			});
	};
};

export const requestCategoryAdd = () => {
	return {
		type: ADD_CATEGORY_REQUEST,
	};
};

export const brandAddSuccess = (data) => {
	return {
		type: ADD_CATEGORY_SUCCESS,
		data,
	};
};

export const brandAddFailure = (message) => {
	return {
		type: ADD_CATEGORY_FAILURE,
		message,
	};
};

export const addCategory = (data) => {
	return (dispatch) => {
		dispatch(requestCategoryAdd());
		return categoryApi
			.addCategory(data)
			.then((resp) => {
				const data = resp.data.data;
				return data;
			})
			.catch((err) => {
				const errorMessage = err.response && err.response.data.message;
				dispatch(brandAddFailure(errorMessage));
				Notification("error", "Error Occurred", errorMessage);
			});
	};
};

//FOR UPDATE Category
export const requestUpdateCategory = () => {
	return {
		type: UPDATE_CATEGORY_REQUEST,
	};
};

export const updateCategorySuccess = (data) => {
	return {
		type: UPDATE_CATEGORY_SUCCESS,
		data,
	};
};

export const updateCategoryFailure = (message) => {
	return {
		type: UPDATE_CATEGORY_FAILURE,
		message,
	};
};

export const updateCategory = (id, data) => {
	return (dispatch) => {
		dispatch(requestUpdateCategory());
		return categoryApi
			.updateCategory(id, data)
			.then((resp) => {
				const data = resp.data;
				return data;
			})
			.catch((err) => {
				const errorMessage = err.response && err.response.data.message;
				dispatch(updateCategoryFailure(errorMessage));
				throw err;
			});
	};
};

export const GET_SUB_ASSEMBLIES_REQUEST = "GET_SUB_ASSEMBLIES_REQUEST",
	GET_SUB_ASSEMBLIES_SUCCESS = "GET_SUB_ASSEMBLIES_SUCCESS",
	GET_SUB_ASSEMBLIES_FAILURE = "GET_SUB_ASSEMBLIES_FAILURE";

export const GET_SINGLE_SUB_ASSEMBLY_REQUEST = "GET_SINGLE_SUB_ASSEMBLY_REQUEST",
	GET_SINGLE_SUB_ASSEMBLY_SUCCESS = "GET_SINGLE_SUB_ASSEMBLY_SUCCESS",
	GET_SINGLE_SUB_ASSEMBLY_FAILURE = "GET_SINGLE_SUB_ASSEMBLY_FAILURE";

export const SOFT_DELETE_SINGLE_SUB_ASSEMBLY_REQUEST = "SOFT_DELETE_SINGLE_SUB_ASSEMBLY_REQUEST",
	SOFT_DELETE_SINGLE_SUB_ASSEMBLY_SUCCESS = "SOFT_DELETE_SINGLE_SUB_ASSEMBLY_SUCCESS",
	SOFT_DELETE_SINGLE_SUB_ASSEMBLY_FAILURE = "SOFT_DELETE_SINGLE_SUB_ASSEMBLY_FAILURE";

export const RESTORE_SINGLE_SUB_ASSEMBLY_REQUEST = "RESTORE_SINGLE_SUB_ASSEMBLY_REQUEST",
	RESTORE_SINGLE_SUB_ASSEMBLY_SUCCESS = "RESTORE_SINGLE_SUB_ASSEMBLY_SUCCESS",
	RESTORE_SINGLE_SUB_ASSEMBLY_FAILURE = "RESTORE_SINGLE_SUB_ASSEMBLY_FAILURE";

export const ADD_SUB_ASSEMBLY_REQUEST = "ADD_SUB_ASSEMBLY_REQUEST",
	ADD_SUB_ASSEMBLY_SUCCESS = "ADD_SUB_ASSEMBLY_SUCCESS",
	ADD_SUB_ASSEMBLY_FAILURE = "ADD_SUB_ASSEMBLY_FAILURE";

export const UPDATE_SUB_ASSEMBLY_REQUEST = "UPDATE_SUB_ASSEMBLY_REQUEST",
	UPDATE_SUB_ASSEMBLY_SUCCESS = "UPDATE_SUB_ASSEMBLY_SUCCESS",
	UPDATE_SUB_ASSEMBLY_FAILURE = "UPDATE_SUB_ASSEMBLY_FAILURE";

export const GET_MODEL_RELATED_SUB_ASSEMBLIES_REQUEST = "GET_MODEL_RELATED_SUB_ASSEMBLIES_REQUEST",
	GET_MODEL_RELATED_SUB_ASSEMBLIES_SUCCESS = "GET_MODEL_RELATED_SUB_ASSEMBLIES_SUCCESS",
	GET_MODEL_RELATED_SUB_ASSEMBLIES_FAILURE = "GET_MODEL_RELATED_SUB_ASSEMBLIES_FAILURE";

import { invokeApi } from "../utils/api";
import { $axios } from "../utils/axios";

export const getAllTechnicalSpecifications = () =>
	invokeApi($axios, "/product/technicalspecification", {}, "GET")
		.then((resp) => resp)
		.catch((err) => {
			throw err;
		});

export const getSingleTechnicalSpecification = (technicalspecificationId, type) =>
	invokeApi(
		$axios,
		`/product/technicalspecification/${
			type === "true" ? "soft-delete/" : ""
		}${technicalspecificationId}`,
		{},
		"GET"
	)
		.then((resp) => resp)
		.catch((err) => {
			throw err;
		});

export const softDeleteSingleTechnicalSpecification = (technicalspecificationId, reason) =>
	invokeApi(
		$axios,
		`/product/technicalspecification/soft-delete/${technicalspecificationId}?reason=${reason}`,
		{},
		"DELETE"
	)
		.then((resp) => resp)
		.catch((err) => {
			throw err;
		});

export const restoreTechnicalSpecification = (technicalspecificationId) =>
	invokeApi(
		$axios,
		`/product/technicalspecification/restore/${technicalspecificationId}`,
		{},
		"PUT"
	)
		.then((resp) => resp)
		.catch((err) => {
			throw err;
		});

export const addTechnicalSpecification = (data) =>
	invokeApi($axios, "/product/technicalspecification", data, "POST")
		.then((resp) => resp)
		.catch((err) => {
			throw err;
		});

export const updateTechnicalSpecification = (id, data) =>
	invokeApi($axios, `/product/technicalspecification/${id}`, data, "PUT")
		.then((resp) => resp)
		.catch((err) => {
			throw err;
		});

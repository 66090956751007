import { Button, message } from "antd";
import { useEffect, useMemo, useState } from "react";
import { ErrorBoundary } from "react-error-boundary";
import { useDispatch, useSelector } from "react-redux";
import BasicLayout from "../../components/BasicLayout/index";
import Center from "../../components/common/Center";
import FallBack from "../../components/common/FallBack/FallBack";
import Tables from "../../components/common/Table/Table";
import { useDeleteAction } from "../../components/Hooks/useDeleteAction";
import CustomAlert from "../../components/common/CustomAlert";
import useExportCsv from "../../components/Hooks/useExportCsv";
import useTableSearch from "../../components/Hooks/useTableSearch";
import {
	getCustomersInfo,
	softDeleteSingleCustomer,
} from "../../store/actions/customer/customer.action";
import { DeleteModel } from "../../utils/DeleteModel";
import { formatFileName } from "../../utils/helper";

const CustomersInfo = (props) => {
	const [getColumnSearchProps] = useTableSearch();
	const [exportToCSV] = useExportCsv();
	const { deleteData } = useDeleteAction();
	const [deleteId, setDeleteId] = useState(undefined);
	const [isModalVisible, setIsModalVisible] = useState(false);
	const dispatch = useDispatch();
	const customers = useSelector((state) => state.customer.customers || []);
	const errorMessage = useSelector((state) => state.customer.errorMessage);

	const admin = useSelector((state) => state.admin.admin || []);

	const permissionArr = useMemo(() => {
		if (admin && admin.template) {
			const { permissions } = admin.template;
			return permissions;
		} else if (admin && admin.accessLevel === "superAdmin") {
			return "all";
		}
		return [];
	}, [admin]);

	useEffect(() => {
		dispatch(getCustomersInfo());
	}, [dispatch]);

	let dataSource = [];
	if (customers.length > 0) {
		dataSource = customers.sort(function (a, b) {
			return new Date(b.updatedAt) - new Date(a.updatedAt);
		});
		dataSource = dataSource.map((row, index) => ({
			...row,
			createdAt: new Date(row.createdAt).toLocaleString(),
			key: row.id,
		}));
	}

	async function confirm(id, reason) {
		await deleteData(id, reason, softDeleteSingleCustomer, setIsModalVisible);
	}

	const columns = [
		{
			title: "Id",
			dataIndex: "id",
			key: "id",
		},
		{
			title: "Name",
			dataIndex: "firstname",
			key: "firstname",
			...getColumnSearchProps("firstname"),
		},
		{
			title: "Last Name",
			dataIndex: "lastname",
			key: "lastname",
			...getColumnSearchProps("lastname"),
		},
		{
			title: "Phone Number",
			dataIndex: "phonenumber",
			key: "phonenumber",
			...getColumnSearchProps("phonenumber"),
		},
		{
			title: "State",
			dataIndex: "state",
			key: "state",
		},
		{
			title: "Received",
			dataIndex: "createdAt",
			key: "createdAt",
		},
		{
			title: "Action",
			key: "action",
			render: (_text, record) => (
				<DeleteModel
					record={record}
					confirm={confirm}
					setIsModalVisible={setIsModalVisible}
					isModalVisible={isModalVisible}
					cancelMessage="Customer Removing has been cancelled"
					linkUrl={`/customer/info`}
					placeholder="Please enter reason to delete customer"
					deleteId={deleteId}
					setDeleteId={setDeleteId}
					update={
						permissionArr === "all"
							? "true"
							: permissionArr?.customer?.customerInfo.filter((item) => item === "update")?.length >
							  0
							? "true"
							: false
					}
					isdelete={
						permissionArr === "all"
							? "true"
							: permissionArr?.customer?.customerInfo.filter((item) => item === "delete")?.length >
							  0
							? "true"
							: false
					}
					view={
						permissionArr === "all"
							? "true"
							: permissionArr?.customer?.customerInfo.filter((item) => item === "read")?.length > 0
							? "true"
							: false
					}
				/>
			),
		},
	];

	const fileName = formatFileName("customer information", "xlsx",admin);
	let downloadData = customers.map((data) => {
		delete data.password;
		return Object.assign({}, data, {
			hasAccepted: "true",
		});
	});

	const handleError = (error) => {
		message.error(error.message);
	};

	return (
		<BasicLayout accessLevel={props.accessLevel}>
			{errorMessage ? (
				<Center height="80vh" marginTop="10px">
					<CustomAlert message="You are not allowed to access this page" type="error" />
				</Center>
			) : (
				<ErrorBoundary FallbackComponent={FallBack} onError={handleError}>
					{permissionArr === "all" ? (
						<div className="button-style-to-right">
							<Button className="download-btn" onClick={(e) => exportToCSV(downloadData, fileName)}>
								Export
							</Button>
						</div>
					) : permissionArr?.customer?.customerInfo?.filter((item) => item === "export")?.length >
							0 && downloadData?.length !== 0 ? (
						<div className="button-style-to-right">
							<Button className="download-btn" onClick={(e) => exportToCSV(downloadData, fileName)}>
								Export
							</Button>
						</div>
					) : null}

					<Tables
						dataSource={dataSource}
						columns={columns}
						footerText={`Total Registered Customer: ${customers.length}`}
					/>
				</ErrorBoundary>
			)}
		</BasicLayout>
	);
};

export default CustomersInfo;

import { UserOutlined } from "@ant-design/icons";
import { Button, Card, Col, Form, message, Row, Space, Typography } from "antd";
import React, { useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { NavLink, Redirect, useHistory } from "react-router-dom";
import BasicLayout from "../../../components/BasicLayout";
import Center from "../../../components/common/Center";
import FallBack from "../../../components/common/FallBack/FallBack";
import InputField from "../../../components/Form/InputField";
import TextArea from "../../../components/Form/TextArea";
import {
	addSingleCategory,
	getSingleCategory,
	updateSingleCategory,
} from "../../../store/actions/Careers/careers.action";
import "./index.css";
import { ErrorBoundary } from "react-error-boundary";
import Notification from "../../../components/common/Notification";
import { wordCount } from "../../../utils/wordCount";
import { WordCountDisplayer } from "../../../components/common/WordCountDisplayer";

const { Title } = Typography;

const CategoryForm = (props) => {
	const categoryId = +props.match.params.id;
	const currentLocation = props.location.pathname;
	const history = useHistory();
	const [form] = Form.useForm();
	const dispatch = useDispatch();
	const category = useSelector((state) => state.careers.category);
	const redirectTo = useSelector((state) => state.other.redirectTo);

	const defaultValues = useMemo(
		() => ({
			title: categoryId ? category.title : "",
			description: categoryId ? category.description : "",
		}),
		[categoryId, category.title, category.description]
	);

	useEffect(() => {
		if (currentLocation !== "/careers/categories/add") {
			dispatch(getSingleCategory(categoryId)).catch((err) => {
				Notification("error", err, "", "topRight");
				history.push("/careers/categories/add");
			});
		}
	}, [currentLocation, history, dispatch, categoryId]);

	useEffect(() => {
		form.setFieldsValue(defaultValues);
	}, [form, defaultValues]);

	const onFinish = async (values) => {
		try {
			if (currentLocation === "/careers/categories/add") {
				await dispatch(addSingleCategory({ ...values }, history));
			} else {
				await wordCount(values.updateReason, 10);
				await dispatch(updateSingleCategory(categoryId, { ...values }, history));
			}
		} catch (error) {
			if (error.message) {
				return Notification("error", "Error Occurred", error.message, "topRight");
			}
			const errorMessage = error.message || "Something went wrong";
			Notification("error", errorMessage, "", "top");
		}
	};
	// To show notifications after click on cancel button
	const handleCancel = () => {
		if (currentLocation === "/careers/categories/add") {
			message.error("Job Category adding has been cancelled!");
		} else {
			message.error("Job Category updating has been cancelled");
		}
	};
	const Rules = [
		{
			required: true,
		},
	];

	if (redirectTo) {
		return <Redirect to={redirectTo} />;
	}
	const handleError = (error) => {
		message.error(error.message);
	};
	return (
		<BasicLayout accessLevel={props.accessLevel}>
			<ErrorBoundary FallbackComponent={FallBack} onError={handleError}>
				<Row type="flex" justify="center" align="middle" id="form-container">
					<Center height="4rem">
						{categoryId ? (
							<Title level={2} className="update-or-add-title">
								Update Category
							</Title>
						) : (
							<Title level={2} className="update-or-add-title">
								Add New Category
							</Title>
						)}
					</Center>
					<Col>
						<Card className="form-card">
							<Form
								form={form}
								name="basic"
								layout="vertical"
								onFinish={onFinish}
								initialValues={defaultValues}
							>
								<InputField
									rules={Rules}
									label="Title"
									name="title"
									placeholder="Please enter title"
									prefix={<UserOutlined />}
								/>
								<TextArea
									rules={Rules}
									label="Description"
									name="description"
									placeholder="Please enter Description "
								/>
								{categoryId ? (
									<WordCountDisplayer
										label="Update Reason"
										name="updateReason"
										placeholder="Please enter reason"
										rules={[
											{
												required: true,
											},
											{
												message: "update reason must be minimum 10 words.",
											},
										]}
									/>
								) : null}
								<Form.Item>
									<Center height="0px">
										<Space>
											<NavLink to="/careers/categories">
												<Button danger type="primary" onClick={handleCancel}>
													Cancel
												</Button>
											</NavLink>
											<Button type="primary" htmlType="submit">
												{categoryId ? "Update" : "Add"}
											</Button>
										</Space>
									</Center>
								</Form.Item>
							</Form>
						</Card>
					</Col>
				</Row>
			</ErrorBoundary>
		</BasicLayout>
	);
};
export default CategoryForm;

import { invokeApi } from "../utils/api";
import { $axios } from "../utils/axios";

export const getAllSpareParts = () =>
	invokeApi($axios, "/product/sparepart", {}, "GET")
		.then((resp) => resp)
		.catch((err) => {
			throw err;
		});

export const getSingleSparePart = (sparepartId, softdelete) =>
	invokeApi(
		$axios,
		`/product/sparepart/${softdelete === "true" ? "soft-delete/" : ""}${sparepartId}`,
		{},
		"GET"
	)
		.then((resp) => resp)
		.catch((err) => {
			throw err;
		});

export const softDeleteSingleSparePart = (sparepartId, reason) =>
	invokeApi($axios, `/product/sparepart/soft-delete/${sparepartId}?reason=${reason}`, {}, "DELETE")
		.then((resp) => resp)
		.catch((err) => {
			throw err;
		});

export const restoreSparePart = (sparepartId) =>
	invokeApi($axios, `/product/sparepart/restore/${sparepartId}`, {}, "PUT")
		.then((resp) => resp)
		.catch((err) => {
			throw err;
		});

export const addSparePart = (data) =>
	invokeApi($axios, "/product/sparepart", data, "POST")
		.then((resp) => resp)
		.catch((err) => {
			throw err;
		});

export const updateSparePart = (sparepartId, data) =>
	invokeApi($axios, `/product/sparepart/${sparepartId}`, data, "PUT")
		.then((resp) => resp)
		.catch((err) => {
			throw err;
		});

export const addBulkSpareParts = (data, modelName) =>
	invokeApi($axios, `product/sparepart/multiple/via-customer/${modelName}`, data, "POST")
		.then((resp) => resp)
		.catch((err) => {
			throw err;
		});

export const addBulkSparePartsApi = (data) =>
	$axios({
		method: "POST",
		url: "/product/sparepart/bulk-upload",
		data,
		withCredentials: true,
	});

export const updateBulkSparePartsApi = (data) =>
	$axios({
		method: "PUT",
		url: "/product/sparepart/bulk-update",
		data,
		withCredentials: true,
	});



export const addExternalSparepartImage = (body) =>
	invokeApi($axios, `/product/sparepart/imageupload`, body, "PUT")
		.then((resp) => resp)
		.catch((err) => {
			throw err;
		});

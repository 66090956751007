import { invokeApi } from "../utils/api";
import { $axios } from "../utils/axios";

export const getNewsLetter = () =>
	invokeApi($axios, "/newsletter", {}, "GET")
		.then((resp) => resp)
		.catch((err) => {
			throw err;
		});

export const softDeleteSingleNewsLetter = (id, reason) =>
	invokeApi($axios, `/newsletter/soft-delete/${id}?reason=${reason}`, {}, "DELETE")
		.then((resp) => resp)
		.catch((err) => {
			throw err;
		});

export const getInnovations = () =>
	invokeApi($axios, "/innovation", {}, "GET")
		.then((resp) => resp)
		.catch((err) => {
			throw err;
		});

export const getSingleInnovation = (id, softdelete) =>
	invokeApi($axios, `/innovation/${softdelete === "true" ? "soft-delete/" : ""}${id}`, {}, "GET")
		.then((resp) => resp)
		.catch((err) => {
			throw err;
		});

export const softDeleteSingleInnovation = (id, reason) =>
	invokeApi($axios, `/innovation/soft-delete/${id}?reason=${reason}`, {}, "DELETE")
		.then((resp) => resp)
		.catch((err) => {
			throw err;
		});

export const getSoftDeletedItems = (url) =>
	invokeApi($axios, `${url}`, {}, "GET")
		.then((resp) => resp)
		.catch((err) => {
			throw err;
		});

export const deletePermanentItem = (id, url) =>
	invokeApi($axios, `${url}/${id}`, {}, "DELETE")
		.then((resp) => resp)
		.catch((err) => {
			throw err;
		});

export const restoreItem = (id, url, reason) =>
	invokeApi($axios, `${url}/${id}?reason=${reason}`, {}, "PUT")
		.then((resp) => resp)
		.catch((err) => {
			throw err;
		});

import { Form, Radio, Select, Typography } from "antd";
import { MailOutlined, MobileOutlined, UserOutlined } from "@ant-design/icons";
import { RiLockPasswordLine } from "react-icons/ri";
import TextArea from "../../../components/Form/TextArea";
import { WordCountDisplayer } from "../../../components/common/WordCountDisplayer";
import InputField from "../../../components/Form/InputField";
import UploadFILE from "../../../components/common/uploadImagNpdf";
import Upload from "../../../components/Form/Upload";
import UploadPDF from "../../../components/Form/UploadPDF";

export const OtherCategoryForm = ({
	ruleIsRequired,
	onChangeState,
	getIndianStates,
	selectedStateIds,
	districtList,
	onChangeDistrict,
	onChangeSubDistrict,
	selectedDistrictIds,
	subDistrictList,
	TemplateChildren,
	userId,
	user,
	getidentityDocInputProps,
	getidentityDocRootProps,
	isidentityDocDragActive,
	identityDocFile,
	onSubUsersRadioChange,
	subUserValue,
	setCompanyStatusValue,
	statusOptions2,
	companyStatusValue,
	cinNumberIsRequired,
	onRadioChange,
	value,
	onGSTTypeRadioChange,
	gstTypeValue,
	setUploadMedia,
	file,
	isImageDragActive,
	getImageInputProps,
	getImageRootProps,
	getRegistrationPdfInputProps,
	getRegistrationPdfRootProps,
	isRegistrationPdfDragActive,
	registrationFile,
	getGstCertificateInputProps,
	getGstCertificateRootProps,
	isGstCertificateDragActive,
	gstCertificateFile,
}) => {
	const { Title } = Typography;
	const { Option } = Select;
	return (
		<div>
			<div className="userFormInputGroup">
				<div className="userFormInputChild" style={{ marginRight: "15px" }}>
					<InputField
						rules={[
							{
								required: true,
							},
							{
								min: 1,
								message: "User Name be minimum 1 character.",
							},
							{
								max: 100,
								message: "User Name be minimum 100 character.",
							},
						]}
						className="responsive"
						type="string"
						label="User Name"
						name="userName"
						placeholder="Please enter user name"
						prefix={<UserOutlined />}
					/>
				</div>
				<div className="userFormInputChild" style={{ marginRight: "15px" }}>
					<InputField
						rules={[
							{
								required: ruleIsRequired,
							},
							{ min: 1, message: "Company Name be minimum 1 character" },
							{
								max: 100,
								message: "Company Name be minimum 100 character",
							},
						]}
						label="Company Name"
						name="companyName"
						placeholder="Please enter company name"
						prefix={<UserOutlined />}
					/>
				</div>
			</div>
			<div className="userFormInputGroup">
				<div className="userFormInputChild" style={{ marginRight: "15px" }}>
					<InputField
						style={{ marginTop: "20px" }}
						rules={[
							{
								required: ruleIsRequired,
							},
							{ min: 1, message: "Owner Name be minimum 01 character" },
							{
								max: 28,
								message: "Owner Name be minimum 28 character",
							},
						]}
						label="Owner Name"
						name="ownerName"
						placeholder="Please enter Owner name"
						prefix={<UserOutlined />}
					/>
				</div>
				<div className="userFormInputChild" style={{ marginRight: "15px" }}>
					<InputField
						style={{ marginTop: "20px" }}
						rules={[
							{
								min: 10,
								max: 10,
								message: "TDS Number must be 10 characters.",
							},
						]}
						label="TDS Number"
						name="tdsNumber"
						placeholder="Please enter TDS Number."
						prefix={<UserOutlined />}
					/>
				</div>
			</div>
			<div className="userFormInputGroup">
				<div className="userFormInputChild" style={{ marginRight: "15px" }}>
					<WordCountDisplayer
						label="Description"
						name="description"
						placeholder="Please enter Description"
						rules={[
							{
								message: "Description must be minimum 10 words.",
							},
						]}
					/>
				</div>

				<div className="userFormInputChild" style={{ marginRight: "15px" }}>
					<div className="adderres">
						<TextArea
							rules={[{ required: true }]}
							label="Office Address"
							name="officeAddress"
							placeholder="Please enter Office Address"
						/>
					</div>
				</div>
			</div>
			<div className="userFormInputGroup">
				<div className="userFormInputChild" style={{ marginRight: "15px" }}>
					<div style={{ marginTop: "10px" }}>
						<Form.Item
							rules={[
								{
									required: true,
								},
							]}
							label="Select State"
							name="state"
							defaultValue={undefined}
						>
							<Select
								allowClear
								mode="multiple"
								style={{ width: "100%" }}
								onChange={onChangeState}
								defaultValue={undefined}
								placeholder="Please select states"
								filterOption={(input, option) => {
									return option?.children?.toLowerCase().indexOf(input?.toLowerCase()) >= 0;
								}}
							>
								{getIndianStates &&
									getIndianStates()?.map((state, index) => (
										<Option value={state["State Code"]} key={index}>
											{state["State Name(In English)"]}
										</Option>
									))}
							</Select>
						</Form.Item>
					</div>
				</div>
				<div className="userFormInputChild" style={{ marginRight: "15px" }}>
					<div className="userDistrict" style={{ marginTop: "10px" }}>
						<Form.Item
							rules={[
								{
									required: false,
								},
							]}
							label="Select District"
							name="district"
							defaultValue={undefined}
						>
							<Select
								placeholder={
									selectedStateIds ? "Please select a Districts" : "Please select a States first"
								}
								allowClear
								mode="multiple"
								onChange={onChangeDistrict}
								style={{ width: "100%" }}
								filterOption={(input, option) => {
									return option?.children?.toLowerCase().indexOf(input?.toLowerCase()) >= 0;
								}}
							>
								{selectedStateIds &&
									districtList?.map((district, index) => (
										<Option value={district["District Code"]} key={index}>
											{district["District Name(In English)"]}
										</Option>
									))}
							</Select>
						</Form.Item>
					</div>
				</div>
			</div>
			<div className="userFormInputGroup">
				<div className="userFormInputChild" style={{ marginRight: "15px" }}>
					<div style={{ marginTop: "10px" }}>
						<Form.Item
							rules={[
								{
									required: false,
								},
							]}
							label="Select Sub District"
							name="subDistrict"
							defaultValue={undefined}
						>
							<Select
								allowClear
								mode="multiple"
								onChange={onChangeSubDistrict}
								style={{ width: "100%" }}
								placeholder={
									selectedDistrictIds
										? "Please select a Sub-Districts"
										: "Please select a Districts first"
								}
								filterOption={(input, option) => {
									return option?.children?.toLowerCase().indexOf(input?.toLowerCase()) >= 0;
								}}
							>
								{selectedDistrictIds &&
									subDistrictList?.map((district, index) => (
										<Option value={district["Sub District Code"]} key={index}>
											{district["Sub District Name"]}
										</Option>
									))}
							</Select>
						</Form.Item>
					</div>
				</div>

				<div className="userFormInputChild" style={{ marginRight: "15px" }}>
					<div className="userTemplate" style={{ marginTop: "10px" }}>
						<Form.Item
							name="templateId"
							label="Select Template"
							rules={[{ required: true, message: "Template is required" }]}
						>
							<Select
								showSearch
								placeholder="please Select Templates"
								filterOption={(input, option) =>
									option.title.toLowerCase().indexOf(input.toLowerCase()) >= 0
								}
							>
								{TemplateChildren?.map((option, index) => (
									<Option value={option.value} key={index} title={option.key}>
										{option.key}
									</Option>
								))}
							</Select>
						</Form.Item>
					</div>
				</div>
			</div>
			<div className="userFormInputGroup">
				<div className="userFormInputChild" style={{ marginRight: "15px" }}>
					<div className="userTemplate" style={{ marginTop: "10px" }}>
						<Title
							level={5}
							style={{
								fontWeight: "400",
								fontSize: "18px",
								marginTop: "10px",
								marginBottom: "10px",
							}}
						>
							<span
								style={{
									color: "#f08080",
									fontWeight: "thin",
									fontSize: "16px",
								}}
							>
								*
							</span>{" "}
							Can company add subusers
						</Title>
						<Radio.Group
							defaultValue={null}
							onChange={onSubUsersRadioChange}
							value={subUserValue}
							style={{
								marginBottom: "20px",
							}}
						>
							<Radio value={1}>Yes</Radio>
							<Radio value={2}>No</Radio>
						</Radio.Group>
					</div>
				</div>
				<div className="userFormInputChild" style={{ marginRight: "15px" }}>
					<div className="userTemplate" style={{ marginTop: "10px" }}>
						<Form.Item
							rules={[
								{
									required: ruleIsRequired,
									message: "Company Status is required",
								},
							]}
							label="Company Status"
							name="companyStatus"
						>
							<Select
								placeholder="Please enter company status "
								onChange={(value) => {
									setCompanyStatusValue(value);
								}}
							>
								{statusOptions2.map((option, index) => (
									<Option value={option.value} key={index} title={option.key}>
										{option.key}
									</Option>
								))}
							</Select>
						</Form.Item>
					</div>
				</div>
			</div>

			<div className="userFormInputGroup">
				{subUserValue === 1 && (
					<div className="userFormInputChildWidht">
						<InputField
							style={{ marginTop: "10px" }}
							label="How many subusers can user add"
							name="allowToAddSubusers"
							placeholder="Please enter How many subusers can user add"
							prefix={<UserOutlined />}
						/>
					</div>
				)}
				{(companyStatusValue === "Private Limited" || companyStatusValue === "Public Limited") && (
					<div className="userFormInputChildWidht">
						<InputField
							style={{ marginTop: "10px" }}
							rules={[
								{
									required: cinNumberIsRequired,
									message: "Company CIN Number is required",
								},
							]}
							label="Company CIN Number"
							name="companyCINNumber"
							placeholder="Please enter Company CIN Number."
							prefix={<UserOutlined />}
						/>
					</div>
				)}
			</div>

			<div className="userFormTextGroup">
				<div className="userFormTextGroupChild" style={{ marginRight: "15px" }}>
					<div style={{ marginTop: "10px" }}>
						<InputField
							rules={[
								{
									required: true,
								},
							]}
							label="Village"
							name="village"
							placeholder="Please enter village"
							prefix={<UserOutlined />}
						/>
					</div>
				</div>
				<div className="userFormTextGroupChild">
					<InputField
						style={{ marginTop: "10px" }}
						rules={[
							{
								required: true,
								message: "Company PAN Card Number is required",
							},
							{
								min: 6,
								max: 14,
								message:
									"Company PAN Card Number must be minimumu 6 characters & maxmimum 14 characters.",
							},
						]}
						label="Company PAN Card Number"
						name="companyPANCardNumber"
						placeholder="Please enter Company PAN Card Number."
						prefix={<UserOutlined />}
					/>
				</div>
				<div className="userFormTextGroupChild">
					<InputField
						style={{ marginTop: "10px" }}
						rules={[
							{
								required: true,
								message: "Office Pincode is required",
							},
							{ min: 6, max: 6, message: "Office Pincode must be 6 characters." },
						]}
						label="Office Pincode"
						name="officePincode"
						placeholder="Please enter Office Pincode."
						prefix={<UserOutlined />}
					/>
				</div>
			</div>
			<div className="userFormTextGroup">
				<div className="userFormTextGroupChild" style={{ marginRight: "15px" }}>
					<InputField
						rules={[
							{
								required: true,
								message: "Phone Number is required",
							},
							{
								min: 10,
								message: "Phone Number must be minimum 10 characters.",
							},
							{
								max: 10,
								message: "Phone Number must be maximum 10 characters.",
							},
						]}
						label="Phone Number"
						name="phoneNumber"
						placeholder="Please enter Phone Number"
						prefix={<MobileOutlined />}
					/>
				</div>
				<div className="userFormTextGroupChild">
					<InputField
						rules={[
							{
								required: true,
								message: "Email is required",
							},
							{
								min: 1,
								message: "Email minimum = 1",
							},
						]}
						label="Email"
						name="email"
						placeholder="Please enter Email."
						prefix={<MailOutlined />}
					/>
				</div>
				<div className="userFormTextGroupChild">
					<InputField
						rules={[
							{
								required: true,
								message: "Password is required",
							},
							{
								max: 100000,
								message: "Password maximum=100000",
							},
						]}
						label="Password"
						name="password"
						inputType="password"
						placeholder="Please enter Password."
						autoComplete="current-password"
						prefix={<RiLockPasswordLine />}
					/>
				</div>
			</div>

			<div className="userFormTextGroup">
				<div className="userFormTextGroupChild" style={{ marginRight: "15px" }}>
					<Title
						level={5}
						style={{
							fontWeight: "400",
							fontSize: "18px",
						}}
					>
						<span
							style={{
								color: "#f08080",
								fontWeight: "thin",
								fontSize: "16px",
							}}
						>
							*
						</span>{" "}
						GST Available :
					</Title>
					<Radio.Group
						defaultValue={null}
						onChange={onRadioChange}
						value={value}
						style={{
							marginBottom: "20px",
						}}
					>
						<Radio value={3}>Yes</Radio>
						<Radio value={4}>No</Radio>
					</Radio.Group>
				</div>
				{value === 3 && (
					<>
						<div className="userFormTextGroupChild">
							<Form.Item label="Is GST Composite" name="gstType">
								<Radio.Group
									defaultValue={null}
									onChange={onGSTTypeRadioChange}
									value={gstTypeValue}
								>
									<Radio value={"yes"}>Yes</Radio>
									<Radio value={"no"}>No</Radio>
								</Radio.Group>
							</Form.Item>
						</div>
						<div className="userFormTextGroupChild">
							<InputField
								rules={[
									{
										min: 13,
										max: 13,
										message: "GST Number must be 13 characters.",
									},
								]}
								label="GST Number"
								name="gstNumber"
								placeholder="Please enter GST Number."
								prefix={<UserOutlined />}
							/>
						</div>
					</>
				)}
			</div>
			{value === 3 && (
				<>
					<Title
						level={5}
						style={{
							fontWeight: "400",
							fontSize: "18px",
							marginTop: "10px",
						}}
					>
						Upload GST Certificate :
					</Title>
					<UploadPDF
						style={{
							marginBottom: "25px",
						}}
						url={userId ? user?.gstCertificateUrl : ""}
						getInputProps={getGstCertificateInputProps}
						getRootProps={getGstCertificateRootProps}
						isDragActive={isGstCertificateDragActive}
						pdf={gstCertificateFile}
					/>
				</>
			)}
			<div className="userFormInputGroup">
				<div className="userFormInputChild" style={{ marginRight: "15px" }}>
					<Title
						level={5}
						style={{
							fontWeight: "400",
							fontSize: "18px",
							marginTop: "25px",
						}}
					>
						<span
							style={{
								color: "#f08080",
								fontWeight: "thin",
								fontSize: "16px",
							}}
						>
							*
						</span>
						Upload Pan Card / Aadhar Card / Driving License :
					</Title>
					<UploadFILE
						url={userId ? user?.identityDocUrl : ""}
						getInputProps={getidentityDocInputProps}
						getRootProps={getidentityDocRootProps}
						isDragActive={isidentityDocDragActive}
						file={identityDocFile}
					/>
				</div>
				<div className="userFormInputChild" style={{ marginRight: "15px" }}>
					<Title
						level={5}
						style={{
							fontWeight: "400",
							fontSize: "18px",
							marginTop: "25px",
						}}
					>
						<span
							style={{
								color: "#f08080",
								fontWeight: "thin",
								fontSize: "16px",
							}}
						>
							*
						</span>{" "}
						Upload Company Registration Document :
					</Title>
					<UploadPDF
						url={userId ? user?.companyRegisterUrl : ""}
						getInputProps={getRegistrationPdfInputProps}
						getRootProps={getRegistrationPdfRootProps}
						isDragActive={isRegistrationPdfDragActive}
						pdf={registrationFile}
					/>
				</div>
			</div>

			<div className="userFormInputGroup">
				<div className="godownAddress">
					<div style={{ marginTop: "20px" }}>
						<TextArea
							rules={[{ required: ruleIsRequired }]}
							label="Godown Address"
							name="godownAddress"
							placeholder="Please enter Godown Address"
						/>
					</div>
				</div>

				{userId ? (
					<div className="updateMobile">
						<WordCountDisplayer
							label="Update Reason"
							name="updateReason"
							placeholder="Please enter reason"
							rules={[
								{
									required: true,
								},
								{
									message: "update reason must be minimum 10 words.",
								},
							]}
						/>
					</div>
				) : null}
			</div>
			<Title
				level={5}
				style={{
					fontWeight: "400",
					fontSize: "18px",
					marginTop: "-10px",
				}}
			>
				<span
					style={{
						color: "#f08080",
						fontWeight: "thin",
						fontSize: "16px",
					}}
				>
					*
				</span>
				Upload Company Logo
			</Title>
			<Upload
				getRootProps={getImageRootProps}
				getInputProps={getImageInputProps}
				type="image"
				isDragActive={isImageDragActive}
				file={file ? file : []}
				url={userId ? user?.companyLogoUrl : ""}
				setUploadMedia={setUploadMedia}
			/>
		</div>
	);
};

import { Card, message } from "antd";
import  { useEffect ,useMemo} from "react";
import { ErrorBoundary } from "react-error-boundary";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import BasicLayout from "../../../components/BasicLayout";
import FallBack from "../../../components/common/FallBack/FallBack";
import Notification from "../../../components/common/Notification";
import { CustomTab } from "../../../components/common/Tab/Tab";
import { getSingleTechnicalSpecification } from "../../../store/actions/products/technicalspecifications/technicalspecification.action";

const ViewTechnicalSpecification = (props) => {
	const technicalspecificationId = +props.match.params.id;
	const dispatch = useDispatch();
	const technicalspecification = useSelector(
		(state) => state.technicalspecification.technicalspecification || {}
	);
	const history = useHistory();

	const admin = useSelector((state) => state.admin.admin || []);

	const permissionArr = useMemo(() => {
		if (admin && admin.template) {
			const { permissions } = admin.template;
			return permissions;
		} else if (admin && admin.accessLevel === "superAdmin") {
			return "all";
		}
		return [];
	}, [admin]);

	const location = useLocation();
	const softdelete = new URLSearchParams(location.search).get("soft-delete");

	useEffect(() => {
		dispatch(getSingleTechnicalSpecification(technicalspecificationId, softdelete)).catch((err) => {
			Notification("error", err, "", "topRight");
			history.push("/products/technicalspecifications");
		});
	}, [dispatch, softdelete, history, technicalspecificationId]);

	let data = [];
	let isOrderEmpty = Object.keys(technicalspecification).length === 0;
	if (!isOrderEmpty) {
		data = [
			{ title: "Id", description: technicalspecification.id },
			{
				title: "Name",
				description: technicalspecification.name,
			},
			{
				title: "Received",
				description: new Date(technicalspecification.createdAt).toLocaleString(),
			},
		];
	}

	const handleError = (error) => {
		message.error(error.message);
	};
	let operationData = {
		deletedBy: technicalspecification?.deletedBy,
		updatedBy: technicalspecification?.updatedBy,
		restoredBy: technicalspecification?.restoredBy,
	};
	return (
		<BasicLayout accessLevel={props.accessLevel}>
			<ErrorBoundary FallbackComponent={FallBack} onError={handleError}>
				<Card className="enqiury-detail-card" data-testid="enqiury-detail-card">
					<CustomTab
					update={
						permissionArr === "all"
							? "true"
							: permissionArr?.products?.productTechnicalSpecifications.filter((item) => item === "update")?.length > 0
							? "true"
							: false
					}
						data={data}
						operationData={operationData}
						redirectUrl={`/products/technicalspecifications/update/${technicalspecificationId}`}
					/>
				</Card>
			</ErrorBoundary>
		</BasicLayout>
	);
};

export default ViewTechnicalSpecification;

import { invokeApi } from "../utils/api";
import { $axios } from "../utils/axios";

export const getAllCategories = () =>
	invokeApi($axios, "/product/primary-category", {}, "GET")
		.then((resp) => resp)
		.catch((err) => {
			throw err;
		});

export const getAllBrandCategories = (brandId) =>
	invokeApi($axios, `/product/primary-category/filter/${brandId}`, {}, "GET")
		.then((resp) => resp)
		.catch((err) => {
			throw err;
		});

export const getSingleCategory = (categoryId, softdelete) =>
	invokeApi(
		$axios,
		`/product/primary-category/${softdelete === "true" ? "soft-delete/" : ""}${categoryId}`,
		{},
		"GET"
	)
		.then((resp) => resp)
		.catch((err) => {
			throw err;
		});

export const softDeleteSingleCategory = (categoryId, reason) =>
	invokeApi(
		$axios,
		`/product/primary-category/soft-delete/${categoryId}?reason=${reason}`,
		{},
		"DELETE"
	)
		.then((resp) => resp)
		.catch((err) => {
			throw err;
		});

export const restoreCategory = (categoryId) =>
	invokeApi($axios, `/product/primary-category/restore/${categoryId}`, {}, "PUT")
		.then((resp) => resp)
		.catch((err) => {
			throw err;
		});

export const addCategory = (data) =>
	invokeApi($axios, "/product/primary-category", data, "POST")
		.then((resp) => resp)
		.catch((err) => {
			throw err;
		});

export const updateCategory = (id, data) =>
	invokeApi($axios, `/product/primary-category/${id}`, data, "PUT")
		.then((resp) => resp)
		.catch((err) => {
			throw err;
		});

import { Editor } from "@tinymce/tinymce-react";
//import tinymce from 'tinymce/tinymce';
import "tinymce/themes/silver";
import "tinymce/icons/default";
import "tinymce/skins/ui/oxide/skin.min.css";
import "tinymce/plugins/advlist";
import "tinymce/plugins/autolink";
import "tinymce/plugins/link";
import "tinymce/plugins/image";
import "tinymce/plugins/lists";
import "tinymce/plugins/charmap";
import "tinymce/plugins/hr";
import "tinymce/plugins/anchor";
import "tinymce/plugins/spellchecker";
import "tinymce/plugins/searchreplace";
import "tinymce/plugins/wordcount";
import "tinymce/plugins/code";
import "tinymce/plugins/fullscreen";
import "tinymce/plugins/insertdatetime";
import "tinymce/plugins/media";
import "tinymce/plugins/nonbreaking";
import "tinymce/plugins/table";
import "tinymce/plugins/template";
import "tinymce/plugins/help";
import "tinymce/plugins/autoresize";
import "tinymce/plugins/preview";

// Content styles, including inline UI like fake cursors
/* eslint import/no-webpack-loader-syntax: off */
import contentCss from "!!raw-loader!tinymce/skins/content/default/content.min.css";
import contentUiCss from "!!raw-loader!tinymce/skins/ui/oxide/content.min.css";

export default function RichTextEditor({ label, value, setValue }) {
	return (
		<div>
			<div style={{ marginBottom: "10px" }}>
				<label>
					<span style={{ color: "red", marginRight: "3px" }}>*</span>
					{label}
				</label>
			</div>
			<Editor
				onEditorChange={(content) => {
					setValue(content);
				}}
				value={value}
				init={{
					skin: false,
					content_css: false,
					content_style: [contentCss, contentUiCss].join("\n"),
					plugins: ["lists link anchor searchreplace insertdatetime table autoresize preview"],
					toolbar:
						"undo redo | formatselect | " +
						"bold italic backcolor | alignleft aligncenter " +
						"alignright alignjustify | insertdatetime table link | bullist numlist | outdent indent | preview",
					branding: false,
				}}
			/>
		</div>
	);
}

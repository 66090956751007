export const GET_HSN_CODES_REQUEST = "GET_HSN_CODES_REQUEST",
	GET_HSN_CODES_SUCCESS = "GET_HSN_CODES_SUCCESS",
	GET_HSN_CODES_FAILURE = "GET_HSN_CODES_FAILURE";

export const GET_SINGLE_HSN_CODE_REQUEST =
		"GET_SINGLE_HSN_CODE_REQUEST",
	GET_SINGLE_HSN_CODE_SUCCESS = "GET_SINGLE_HSN_CODE_SUCCESS",
	GET_SINGLE_HSN_CODE_FAILURE = "GET_SINGLE_HSN_CODE_FAILURE";

export const SOFT_DELETE_SINGLE_HSN_CODE_REQUEST =
		"SOFT_DELETE_SINGLE_HSN_CODE_REQUEST",
	SOFT_DELETE_SINGLE_HSN_CODE_SUCCESS =
		"SOFT_DELETE_SINGLE_HSN_CODE_SUCCESS",
	SOFT_DELETE_SINGLE_HSN_CODE_FAILURE =
		"SOFT_DELETE_SINGLE_HSN_CODE_FAILURE";

export const RESTORE_SINGLE_HSN_CODE_REQUEST =
		"RESTORE_SINGLE_HSN_CODE_REQUEST",
	RESTORE_SINGLE_HSN_CODE_SUCCESS = "RESTORE_SINGLE_HSN_CODE_SUCCESS",
	RESTORE_SINGLE_HSN_CODE_FAILURE = "RESTORE_SINGLE_HSN_CODE_FAILURE";

export const ADD_HSN_CODE_REQUEST = "ADD_HSN_CODE_REQUEST",
	ADD_HSN_CODE_SUCCESS = "ADD_HSN_CODE_SUCCESS",
	ADD_HSN_CODE_FAILURE = "ADD_HSN_CODE_FAILURE";

export const UPDATE_HSN_CODE_REQUEST = "UPDATE_HSN_CODE_REQUEST",
	UPDATE_HSN_CODE_SUCCESS = "UPDATE_HSN_CODE_SUCCESS",
	UPDATE_HSN_CODE_FAILURE = "UPDATE_HSN_CODE_FAILURE";

import Notification from "../../../../components/common/Notification";
import * as sparePartApi from "../../../../services/sparepart";
import {
	ADD_SPARE_PART_FAILURE,
	ADD_SPARE_PART_REQUEST,
	ADD_SPARE_PART_SUCCESS,
	GET_SPARE_PARTS_FAILURE,
	GET_SPARE_PARTS_REQUEST,
	GET_SPARE_PARTS_SUCCESS,
	GET_SINGLE_SPARE_PART_FAILURE,
	GET_SINGLE_SPARE_PART_REQUEST,
	GET_SINGLE_SPARE_PART_SUCCESS,
	MAKE_SINGLE_SPARE_PART_EMPTY,
	RESTORE_SINGLE_SPARE_PART_FAILURE,
	RESTORE_SINGLE_SPARE_PART_REQUEST,
	RESTORE_SINGLE_SPARE_PART_SUCCESS,
	SOFT_DELETE_SINGLE_SPARE_PART_FAILURE,
	SOFT_DELETE_SINGLE_SPARE_PART_REQUEST,
	SOFT_DELETE_SINGLE_SPARE_PART_SUCCESS,
	UPDATE_SPARE_PART_FAILURE,
	UPDATE_SPARE_PART_REQUEST,
	UPDATE_SPARE_PART_SUCCESS,
} from "./spareparts.actionType";

// GET REQUEST
export const requestGetAllSpareParts = () => {
	return {
		type: GET_SPARE_PARTS_REQUEST,
	};
};
export const getAllSparePartsSuccess = (data) => {
	return {
		type: GET_SPARE_PARTS_SUCCESS,
		data,
	};
};
export const getAllSparePartsFailure = (message) => {
	return {
		type: GET_SPARE_PARTS_FAILURE,
		message,
	};
};

// Get All SpareParts
export const getAllSpareParts = () => {
	return (dispatch) => {
		dispatch(requestGetAllSpareParts());
		return sparePartApi
			.getAllSpareParts()
			.then((resp) => {
				const data = resp.data.data;
				dispatch(getAllSparePartsSuccess(data));
			})
			.catch((err) => {
				const errorMessage =
					(err?.response && err?.response?.data?.message) || "Something went wrong!";
				dispatch(getAllSparePartsFailure(errorMessage));
			});
	};
};

export const requestGetSingleSparePart = () => {
	return {
		type: GET_SINGLE_SPARE_PART_REQUEST,
	};
};

export const getSingleSparePartSuccess = (data) => {
	return {
		type: GET_SINGLE_SPARE_PART_SUCCESS,
		data,
	};
};

export const getSingleSparePartFailure = (message) => {
	return {
		type: GET_SINGLE_SPARE_PART_FAILURE,
		message,
	};
};

export const getSingleSparePart = (id, softdelete) => {
	return (dispatch) => {
		dispatch(requestGetSingleSparePart());
		return sparePartApi
			.getSingleSparePart(id, softdelete)
			.then((resp) => {
				const data = resp.data.data;
				dispatch(getSingleSparePartSuccess(data));
			})
			.catch((err) => {
				const errorMessage =
					(err?.response && err?.response?.data?.message) || "Something went wrong";
				dispatch(getSingleSparePartFailure(errorMessage));
				throw errorMessage;
			});
	};
};

export const requestSoftDeleteSingleSparePart = () => {
	return {
		type: SOFT_DELETE_SINGLE_SPARE_PART_REQUEST,
	};
};

export const softDeleteSingleSparePartSuccess = (data) => {
	return {
		type: SOFT_DELETE_SINGLE_SPARE_PART_SUCCESS,
		data,
	};
};

export const softDeleteSingleSparePartFailure = (message) => {
	return {
		type: SOFT_DELETE_SINGLE_SPARE_PART_FAILURE,
		message,
	};
};

export const softDeleteSingleSparePart = (id, reason) => {
	return (dispatch) => {
		dispatch(requestSoftDeleteSingleSparePart());
		return sparePartApi
			.softDeleteSingleSparePart(id, reason)
			.then((resp) => {
				dispatch(softDeleteSingleSparePartSuccess(id));
				return Notification("success", resp.data.message, "", "topRight");
			})
			.catch((err) => {
				const errorMessage =
					(err?.response && err?.response?.data?.message) || "Something went wrong";
				dispatch(softDeleteSingleSparePartFailure(errorMessage));
			});
	};
};

export const requestSparePartRestore = () => {
	return {
		type: RESTORE_SINGLE_SPARE_PART_REQUEST,
	};
};

export const restoreSparePartSuccess = () => {
	return {
		type: RESTORE_SINGLE_SPARE_PART_SUCCESS,
	};
};

export const restoreSparePartFailure = (message) => {
	return {
		type: RESTORE_SINGLE_SPARE_PART_FAILURE,
		message,
	};
};

export const restoreSparePart = (id) => {
	return (dispatch) => {
		dispatch(requestSparePartRestore());
		return sparePartApi
			.restoreSparePart(id)
			.then((resp) => {
				dispatch(restoreSparePartSuccess(id));
				Notification("success", resp.data.message, "", "topRight");
			})
			.catch((err) => {
				const errorMessage = (err.response && err.response.data.message) || "Something went wrong!";
				dispatch(restoreSparePartFailure(errorMessage));
			});
	};
};

export const requestSparePartAdd = () => {
	return {
		type: ADD_SPARE_PART_REQUEST,
	};
};

export const SparePartAddSuccess = () => {
	return {
		type: ADD_SPARE_PART_SUCCESS,
	};
};

export const SparePartAddFailure = (message) => {
	return {
		type: ADD_SPARE_PART_FAILURE,
		message,
	};
};

export const addSparePart = (data) => {
	return (dispatch) => {
		dispatch(requestSparePartAdd());
		return sparePartApi
			.addSparePart(data)
			.then((resp) => {
				const data = resp.data.data;
				return data;
			})
			.catch((err) => {
				const errorMessage = err.response && err.response.data.message;
				dispatch(SparePartAddFailure(errorMessage));
				Notification("error", "Error Occurred", errorMessage);
			});
	};
};

//FOR UPDATE SparePart
export const requestUpdateSparePart = () => {
	return {
		type: UPDATE_SPARE_PART_REQUEST,
	};
};

export const updateSparePartSuccess = (data) => {
	return {
		type: UPDATE_SPARE_PART_SUCCESS,
		data,
	};
};

export const updateSparePartFailure = (message) => {
	return {
		type: UPDATE_SPARE_PART_FAILURE,
		message,
	};
};

export const updateSparePart = (id, data) => {
	return (dispatch) => {
		dispatch(requestUpdateSparePart());
		return sparePartApi
			.updateSparePart(id, data)
			.then((resp) => {
				const data = resp.data;
				return data;
			})
			.catch((err) => {
				const errorMessage = err.response && err.response.data.message;
				dispatch(updateSparePartFailure(errorMessage));
				throw err;
			});
	};
};

export const updateSparePartFromTable = (id, data) => {
	return (dispatch) => {
		dispatch(requestUpdateSparePart());
		return sparePartApi
			.updateSparePart(id, data)
			.then((resp) => {
				dispatch(updateSparePartSuccess());
				dispatch(getAllSpareParts());
				const message = resp.data.message;
				return Notification("success", "", message);
			})
			.catch((err) => {
				const errorMessage = err.response && err.response.data.message;
				dispatch(updateSparePartFailure(errorMessage));
				throw err;
			});
	};
};

export const makeSparePartDataEmpty = () => {
	return {
		type: MAKE_SINGLE_SPARE_PART_EMPTY,
		data: [],
	};
};

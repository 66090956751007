import { Button, Form, Modal, Space, Typography } from "antd";
import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import Center from "../components/common/Center";
import TextArea from "../components/Form/TextArea";
import { wordCounterRealTime } from "./wordCount";

export function DeleteModel({
	record,
	confirm,
	setIsModalVisible,
	isModalVisible,
	linkUrl,
	update = "true",
	isdelete = "true",
	cancelMessage,
	placeholder,
	setDeleteId,
	deleteId,
	view = "true",
}) {
	const { Text } = Typography;
	const [form] = Form.useForm();
	const [word, setWord] = useState(undefined);
	const showModal = () => {
		setIsModalVisible(true);
	};

	const handleCancel = () => {
		setIsModalVisible(false);
	};
	return (
		<Space size="middle">
			{view === "true" ? <NavLink to={`${linkUrl}/${record.id}`}>View</NavLink> : null}
			{update === "true" ? <NavLink to={`${linkUrl}/update/${record.id}`}>Update</NavLink> : null}
			{isdelete === "true" &&<Text
				style={{ color: "#1890ff", cursor: "pointer" }}
				onClick={() => {
					setDeleteId(record.id);
					showModal();
				}}
			>
				Delete
			</Text>}
			<Modal visible={isModalVisible} onCancel={handleCancel} footer={null}>
				<Form
					form={form}
					name="basic"
					layout="vertical"
					onFinish={(values) => confirm(deleteId, values.reason)}
					initialValues={{ reason: "" }}
				>
					<div style={{ minHeight: "120px" }}>
						<TextArea
							rules={[
								{
									required: true,
								},
							]}
							label="Reason"
							name="reason"
							handleOnChange={(e) => setWord(e.target.value)}
							placeholder={placeholder}
							extra={`Character count: ${wordCounterRealTime(word)} out of 10`}
						/>
					</div>
					<Form.Item style={{ marginTop: "20px" }}>
						<Center height="0px">
							<Space>
								<NavLink to={`${linkUrl}`}>
									<Button danger type="primary" onClick={handleCancel}>
										Cancel
									</Button>
								</NavLink>
								<Button type="primary" htmlType="submit">
									Delete
								</Button>
							</Space>
						</Center>
					</Form.Item>
				</Form>
			</Modal>
		</Space>
	);
}

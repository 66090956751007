import {
	GET_IMPLEMENTS_SUCCESS,
	GET_SINGLE_IMPLEMENTS_SUCCESS,
	RESTORE_SINGLE_IMPLEMENTS_SUCCESS,
	SOFT_DELETE_SINGLE_IMPLEMENTS_SUCCESS,
} from "../actions/products/implements/implements.actionType";
import globalState from "../state";

const requestRegex = /_REQUEST/;
const errorRegex = /_FAILURE/;
const successRegex = /_SUCCESS/;

const initialState = {
	errorMessage: "",
	isLoading: false,
	multipleImplements: [],
	implement: {},
	...globalState,
};

const ImplementsReducer = (state = initialState, action) => {
	const isRequest = requestRegex.test(action.type);
	const isError = errorRegex.test(action.type);
	const isSuccess = successRegex.test(action.type);

	if (isRequest) {
		return { ...state, isLoading: true, errorMessage: "" };
	}
	if (isError) {
		return { ...state, isLoading: false, errorMessage: action.message };
	}
	if (isSuccess) {
		state = { ...state, isLoading: false, errorMessage: "" };
	}

	switch (action.type) {
		case GET_IMPLEMENTS_SUCCESS:
			return { ...state, multipleImplements: action.data };

		case GET_SINGLE_IMPLEMENTS_SUCCESS:
			return { ...state, implement: action.data };

		case SOFT_DELETE_SINGLE_IMPLEMENTS_SUCCESS:
			let softDeletedimplementsId = action.data;
			let afterSoftDeletedData = state.multipleImplements.filter(
				(data) => data.id.toString() !== softDeletedimplementsId.toString()
			);
			return { ...state, multipleImplements: afterSoftDeletedData };

		case RESTORE_SINGLE_IMPLEMENTS_SUCCESS:
			return { ...state };

		default:
			return state;
	}
};

export default ImplementsReducer;

import { Anchor, Card, Image, message } from "antd";
import { useEffect, useMemo } from "react";
import { ErrorBoundary } from "react-error-boundary";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import BasicLayout from "../../../components/BasicLayout";
import FallBack from "../../../components/common/FallBack/FallBack";
import Notification from "../../../components/common/Notification";
import { CustomTab } from "../../../components/common/Tab/Tab";
import { TechnicalSpecsDetailsTable } from "../../../components/common/TechnicalSpecsDetailsTable/TechnicalSpecsDetailsTable";
import { getSingleModel } from "../../../store/actions/products/model/model.action";

const ViewModel = (props) => {
	const modelId = +props.match.params.id;
	const dispatch = useDispatch();
	const location = useLocation();
	const softdelete = new URLSearchParams(location.search).get("soft-delete");
	const history = useHistory();

	const admin = useSelector((state) => state.admin.admin || []);

	const permissionArr = useMemo(() => {
		if (admin && admin.template) {
			const { permissions } = admin.template;
			return permissions;
		} else if (admin && admin.accessLevel === "superAdmin") {
			return "all";
		}
		return [];
	}, [admin]);

	const model = useSelector((state) => state.model.model);
	const { Link } = Anchor;
	useEffect(() => {
		dispatch(getSingleModel(modelId, softdelete)).catch((err) => {
			Notification("error", err, "", "topRight");
			history.push("/products/model");
		});
	}, [dispatch, history, softdelete, modelId]);

	let data = [];
	let isModelEmpty = Object.keys(model).length === 0;
	if (!isModelEmpty) {
		data = [
			{ title: "Id", description: model.id },
			{
				title: "Name",
				description: model.name ? model.name : "NA",
			},
			{
				title: "Product Number",
				description: model.productNumber ? model.productNumber : "NA",
			},
			{
				title: "Price",
				description: model.price ? model.price : "NA",
			},
			{
				title: "Stock",
				description: model.stock ? model.stock : "NA",
			},
			{
				title: "Discount",
				description: model.discount ? `${model.discount}%` : "NA",
			},
			{
				title: "Brand Name",
				description: model.brand ? model?.brand?.name : "NA",
			},
			{
				title: "Brand Code",
				description: model.brand ? model?.brand?.code : "NA",
			},
			{
				title: "Brand Image",
				description: model.brand ? (
					<div className="gallery-images">
						<div className="image-holder-container">
							<Image width={250} height={180} src={model?.brand?.url} />
						</div>
					</div>
				) : (
					"NA"
				),
			},
			{
				title: "Primary Category Name",
				description: model.primaryCategory ? model?.primaryCategory?.name : "NA",
			},
			{
				title: "Primary Category Code",
				description: model.primaryCategory ? model?.primaryCategory?.code : "NA",
			},
			{
				title: "Primary Category Image",
				description: model.primaryCategory ? (
					<div className="gallery-images">
						<div className="image-holder-container">
							<Image width={250} height={180} src={model?.primaryCategory?.url} />
						</div>
					</div>
				) : (
					"NA"
				),
			},
			{
				title: "Secondary Category Name",
				description: model.secondaryCategory ? model?.secondaryCategory?.name : "NA",
			},
			{
				title: "Secondary Category Code",
				description: model.secondaryCategory ? model?.secondaryCategory?.code : "NA",
			},
			{
				title: "Secondary Category Image",
				description: model.secondaryCategory ? (
					<div className="gallery-images">
						<div className="image-holder-container">
							<Image width={250} height={180} src={model?.secondaryCategory?.url} />
						</div>
					</div>
				) : (
					"NA"
				),
			},
			{
				title: "HSN/SAC Code",
				description: model?.hsnCode ? model?.hsnCode?.code : "NA",
			},
			{
				title: "Products PDF",
				description: model?.productsPdfUrl ? (
					<Anchor>
						<Link href={model?.productsPdfUrl || "/"} title="View" target="_blank"></Link>
					</Anchor>
				) : (
					"NA"
				),
			},
			{
				title: "Spare Parts PDF",
				description: model?.sparePartsPdfUrl ? (
					<Anchor>
						<Link href={model?.sparePartsPdfUrl || "/"} title="View" target="_blank"></Link>
					</Anchor>
				) : (
					"NA"
				),
			},
			{
				title: "Video",
				description: model?.videoUrls ? (
					<Anchor>
						<Link href={model?.videoUrls[0] || "/"} target="_blank" title="View"></Link>
					</Anchor>
				) : (
					"NA"
				),
			},
			{
				title: "Technical Name",
				description: model.technicalName ? (
					<TechnicalSpecsDetailsTable technicalName={model?.technicalName || []} />
				) : (
					"NA"
				),
			},
			{
				title: "Created At",
				description: new Date(model.createdAt).toLocaleString(),
			},
		];
	}
	
	const handleError = (error) => {
		message.error(error.message);
	};

	let operationData = {
		deletedBy: model?.deletedBy,
		updatedBy: model?.updatedBy,
		restoredBy: model?.restoredBy,
	};

	return (
		<BasicLayout accessLevel={props.accessLevel}>
			<ErrorBoundary FallbackComponent={FallBack} onError={handleError}>
				<>
					<Card
						className="enqiury-detail-card"
						data-testid="enqiury-detail-card"
						id="view-model-box"
					>
						<CustomTab
							update={
								permissionArr === "all"
									? "true"
									: permissionArr?.products?.model.filter((item) => item === "update")?.length > 0
									? "true"
									: false
							}
							data={data}
							operationData={operationData}
							redirectUrl={`/products/model/update/${modelId}`}
						/>
					</Card>
				</>
			</ErrorBoundary>
		</BasicLayout>
	);
};

export default ViewModel;

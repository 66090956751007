import { UserOutlined } from "@ant-design/icons";
import { Button, Card, Col, Form, message, Row, Space, Typography } from "antd";
import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { NavLink, Redirect, useHistory } from "react-router-dom";
import BasicLayout from "../../../components/BasicLayout";
import Center from "../../../components/common/Center";
import Notification from "../../../components/common/Notification";
import InputField from "../../../components/Form/InputField";
import Radio from "../../../components/Form/Radiobutton";
import Select from "../../../components/Form/Select";
import RichTextEditor from "../../../components/Form/RichTextEditor";
import {
	addJob,
	getCategories,
	getSingleJob,
	updateJob,
} from "../../../store/actions/Careers/careers.action";
import "./jobs.css";
import FallBack from "../../../components/common/FallBack/FallBack";
import { ErrorBoundary } from "react-error-boundary";
import { wordCount } from "../../../utils/wordCount";
import { WordCountDisplayer } from "../../../components/common/WordCountDisplayer";

const { Title } = Typography;

const JobForm = (props) => {
	const history = useHistory();
	const [form] = Form.useForm();
	const jobsId = +props.match.params.id;
	const dispatch = useDispatch();
	const currentLocation = props.location.pathname;

	const job = useSelector((state) => state.careers.job);
	const redirectTo = useSelector((state) => state.other.redirectTo);
	const categories = useSelector((state) => state.careers.categories);

	const [description, setDescription] = useState("");

	const defaultValues = useMemo(
		() => ({
			position: jobsId ? job.position : "",
			categoryId: jobsId ? job.categoryId : "",
			experience: jobsId ? job.experience : "",
			experienceUnit: jobsId ? job.experienceUnit : "",
			type: jobsId ? job.type : "",
		}),
		[jobsId, job.position, job.categoryId, job.experience, job.type, job.experienceUnit]
	);

	useEffect(() => {
		if (jobsId) setDescription(job.description);
	}, [job, jobsId]);

	useEffect(() => {
		if (currentLocation !== "/careers/jobs/add") {
			dispatch(getSingleJob(jobsId)).catch((err) => {
				Notification("error", err, "", "topRight");
				history.push("/careers/jobs");
			});
		}
		dispatch(getCategories());
	}, [currentLocation, history, dispatch, jobsId]);

	useEffect(() => {
		form.setFieldsValue(defaultValues);
	}, [form, defaultValues]);

	const onFinish = async (values) => {
		try {
			if (description === "") {
				Notification("error", "Must have a description", "", "topRight");
				return;
			} else {
				values.description = description;
			}
			if (currentLocation === "/careers/jobs/add") {
				dispatch(addJob({ ...values }, history));
			} else {
				await wordCount(values.updateReason, 10);
				await dispatch(updateJob(jobsId, { ...values }, history));
			}
		} catch (error) {
			if (error.message) {
				return Notification("error", "Error Occurred", error.message, "topRight");
			}
			const errorMessage = error?.message || "Something went wrong";
			return Notification("error", errorMessage, "", "topRight");
		}
	};
	const updateJobCancel = () => {
		Notification("info", "Job updation has been cancelled", "", "topRight");
	};
	const Rules = [
		{
			required: true,
		},
	];

	const categoryOptions = useMemo(
		() =>
			categories.map((category) => ({
				key: category.title,
				value: category.id,
			})),
		[categories]
	);

	const ExperienceYearUnit = useMemo(
		() => [
			{ key: "years", value: "years" },
			{ key: "months", value: "months" },
			{ key: "days", value: "days" },
		],
		[]
	);

	const typeOptions = useMemo(
		() => [
			{ key: "Full Time", value: "Full Time" },
			{ key: "Part Time", value: "Part Time" },
		],
		[]
	);

	const handleError = (error) => {
		message.error(error.message);
	};
	if (redirectTo) {
		return <Redirect to={redirectTo} />;
	}

	return (
		<BasicLayout accessLevel={props.accessLevel}>
			<ErrorBoundary FallbackComponent={FallBack} onError={handleError}>
				<Row type="flex" justify="center" align="middle" id="form-container">
					<Center height="4rem">
						{jobsId ? (
							<Title level={2} className="update-or-add-title">
								Update Job
							</Title>
						) : (
							<Title level={2} className="update-or-add-title">
								Add New Job
							</Title>
						)}
					</Center>
					<Col>
						<Card className="form-card">
							<Form
								form={form}
								name="basic"
								layout="vertical"
								onFinish={onFinish}
								initialValues={defaultValues}
							>
								<InputField
									rules={Rules}
									label="Position"
									name="position"
									placeholder="Please enter position"
									prefix={<UserOutlined />}
								/>
								<div className="rich-text-editor">
									<RichTextEditor
										label="Description"
										setValue={setDescription}
										value={description}
									/>
								</div>
								<Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }} id="containerExperienceFlexFlow">
									<Col className="gutter-row containerExperienceWidth" span={6}>
										<Select
											rules={Rules}
											label="Experience Unit"
											name="experienceUnit"
											placeholder="Please enter experience unit"
											options={ExperienceYearUnit}
										/>
									</Col>
									<Col className="gutter-row containerExperienceWidth" span={4}>
										<InputField
											rules={Rules}
											label="Experience"
											name="experience"
											inputType="number"
										/>
									</Col>
									<Col className="gutter-row containerExperienceWidth" span={8}>
										<Select
											rules={Rules}
											label="Job Category"
											name="categoryId"
											placeholder="Please enter Description "
											options={categoryOptions}
										/>
									</Col>
									<Col className="gutter-row containerExperienceWidth" span={6}>
										<Radio rules={Rules} label="Job Type" name="type" options={typeOptions} />
									</Col>
								</Row>
								{jobsId ? (
									<WordCountDisplayer
										label="Update Reason"
										name="updateReason"
										placeholder="Please enter reason"
										rules={[
											{
												required: true,
											},
											{
												message: "update reason must be minimum 10 words.",
											},
										]}
									/>
								) : null}
								<Form.Item>
									<Center height="0px">
										<Space>
											<NavLink to="/careers/jobs">
												<Button danger type="primary" onClick={updateJobCancel}>
													Cancel
												</Button>
											</NavLink>
											<Button type="primary" htmlType="submit">
												{jobsId ? "Update" : "Add"}
											</Button>
										</Space>
									</Center>
								</Form.Item>
							</Form>
						</Card>
					</Col>
				</Row>
			</ErrorBoundary>
		</BasicLayout>
	);
};
export default JobForm;

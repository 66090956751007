import Notification from "../../../../components/common/Notification";
import * as categoryApi from "../../../../services/productsecondarycategory";
import {
	ADD_PRODUCT_SECONDARY_CATEGORY_FAILURE,
	ADD_PRODUCT_SECONDARY_CATEGORY_REQUEST,
	ADD_PRODUCT_SECONDARY_CATEGORY_SUCCESS,
	GET_PRIMARY_RELATED_SECONDARY_CATEGORIES_FAILURE,
	GET_PRIMARY_RELATED_SECONDARY_CATEGORIES_REQUEST,
	GET_PRIMARY_RELATED_SECONDARY_CATEGORIES_SUCCESS,
	GET_PRODUCT_SECONDARY_CATEGORIES_FAILURE,
	GET_PRODUCT_SECONDARY_CATEGORIES_REQUEST,
	GET_PRODUCT_SECONDARY_CATEGORIES_SUCCESS,
	GET_SINGLE_PRODUCT_SECONDARY_CATEGORY_FAILURE,
	GET_SINGLE_PRODUCT_SECONDARY_CATEGORY_REQUEST,
	GET_SINGLE_PRODUCT_SECONDARY_CATEGORY_SUCCESS,
	RESTORE_SINGLE_PRODUCT_SECONDARY_CATEGORY_FAILURE,
	RESTORE_SINGLE_PRODUCT_SECONDARY_CATEGORY_REQUEST,
	RESTORE_SINGLE_PRODUCT_SECONDARY_CATEGORY_SUCCESS,
	SOFT_DELETE_SINGLE_PRODUCT_SECONDARY_CATEGORY_FAILURE,
	SOFT_DELETE_SINGLE_PRODUCT_SECONDARY_CATEGORY_REQUEST,
	SOFT_DELETE_SINGLE_PRODUCT_SECONDARY_CATEGORY_SUCCESS,
	UPDATE_PRODUCT_SECONDARY_CATEGORY_FAILURE,
	UPDATE_PRODUCT_SECONDARY_CATEGORY_REQUEST,
	UPDATE_PRODUCT_SECONDARY_CATEGORY_SUCCESS,
} from "./categories.actionType";

// GET REQUEST
export const requestGetAllProductSecondaryCategories = () => {
	return {
		type: GET_PRODUCT_SECONDARY_CATEGORIES_REQUEST,
	};
};

export const getAllProductSecondaryCategoriesSuccess = (data) => {
	return {
		type: GET_PRODUCT_SECONDARY_CATEGORIES_SUCCESS,
		data,
	};
};

export const getAllProductSecondaryCategoriesFailure = (message) => {
	return {
		type: GET_PRODUCT_SECONDARY_CATEGORIES_FAILURE,
		message,
	};
};

// Get All Categories
export const getAllProductSecondaryCategories = () => {
	return (dispatch) => {
		dispatch(requestGetAllProductSecondaryCategories());
		return categoryApi
			.getAllProductSecondaryCategories()
			.then((resp) => {
				const data = resp.data.data;
				dispatch(getAllProductSecondaryCategoriesSuccess(data));
			})
			.catch((err) => {
				const errorMessage =
					(err?.response && err?.response?.data?.message) || "Something went wrong!";
				dispatch(getAllProductSecondaryCategoriesFailure(errorMessage));
			});
	};
};

export const requestGetSingleProductSecondaryCategory = () => {
	return {
		type: GET_SINGLE_PRODUCT_SECONDARY_CATEGORY_REQUEST,
	};
};

export const getSingleProductSecondaryCategorySuccess = (data) => {
	return {
		type: GET_SINGLE_PRODUCT_SECONDARY_CATEGORY_SUCCESS,
		data,
	};
};

export const getSingleProductSecondaryCategoryFailure = (message) => {
	return {
		type: GET_SINGLE_PRODUCT_SECONDARY_CATEGORY_FAILURE,
		message,
	};
};

export const getSingleProductSecondaryCategory = (id, softdelete) => {
	return (dispatch) => {
		dispatch(requestGetSingleProductSecondaryCategory());
		return categoryApi
			.getSingleProductSecondaryCategory(id, softdelete)
			.then((resp) => {
				const data = resp.data.data;
				dispatch(getSingleProductSecondaryCategorySuccess(data));
			})
			.catch((err) => {
				const errorMessage =
					(err?.response && err?.response?.data?.message) || "Something went wrong";
				dispatch(getSingleProductSecondaryCategoryFailure(errorMessage));
				throw errorMessage;
			});
	};
};

export const requestSoftDeleteSingleProductSecondaryCategory = () => {
	return {
		type: SOFT_DELETE_SINGLE_PRODUCT_SECONDARY_CATEGORY_REQUEST,
	};
};

export const softDeleteSingleProductSecondaryCategorySuccess = (data) => {
	return {
		type: SOFT_DELETE_SINGLE_PRODUCT_SECONDARY_CATEGORY_SUCCESS,
		data,
	};
};

export const softDeleteSingleProductSecondaryCategoryFailure = (message) => {
	return {
		type: SOFT_DELETE_SINGLE_PRODUCT_SECONDARY_CATEGORY_FAILURE,
		message,
	};
};

export const softDeleteSingleProductSecondaryCategory = (id, reason) => {
	return (dispatch) => {
		dispatch(requestSoftDeleteSingleProductSecondaryCategory());
		return categoryApi
			.softDeleteSingleProductSecondaryCategory(id, reason)
			.then((resp) => {
				dispatch(softDeleteSingleProductSecondaryCategorySuccess(id));
				return Notification("success", resp.data.message, "", "topRight");
			})
			.catch((err) => {
				const errorMessage =
					(err?.response && err?.response?.data?.message) || "Something went wrong";
				dispatch(softDeleteSingleProductSecondaryCategoryFailure(errorMessage));
				return Notification("error", errorMessage, "", "topRight");
			});
	};
};

export const requestProductSecondaryCategoryRestore = () => {
	return {
		type: RESTORE_SINGLE_PRODUCT_SECONDARY_CATEGORY_REQUEST,
	};
};

export const restoreProductSecondaryCategorySuccess = () => {
	return {
		type: RESTORE_SINGLE_PRODUCT_SECONDARY_CATEGORY_SUCCESS,
	};
};

export const restoreProductSecondaryCategoryFailure = (message) => {
	return {
		type: RESTORE_SINGLE_PRODUCT_SECONDARY_CATEGORY_FAILURE,
		message,
	};
};

export const restoreProductSecondaryCategory = (id) => {
	return (dispatch) => {
		dispatch(requestProductSecondaryCategoryRestore());
		return categoryApi
			.restoreProductSecondaryCategory(id)
			.then((resp) => {
				dispatch(restoreProductSecondaryCategorySuccess(id));
				Notification("success", resp.data.message, "", "topRight");
			})
			.catch((err) => {
				const errorMessage = (err.response && err.response.data.message) || "Something went wrong!";
				dispatch(restoreProductSecondaryCategoryFailure(errorMessage));
			});
	};
};

export const requestProductSecondaryCategoryAdd = () => {
	return {
		type: ADD_PRODUCT_SECONDARY_CATEGORY_REQUEST,
	};
};

export const addProductSecondarysuccess = (data) => {
	return {
		type: ADD_PRODUCT_SECONDARY_CATEGORY_SUCCESS,
		data,
	};
};

export const addProductSecondaryFailure = (message) => {
	return {
		type: ADD_PRODUCT_SECONDARY_CATEGORY_FAILURE,
		message,
	};
};

export const addProductSecondaryCategory = (data) => {
	return (dispatch) => {
		dispatch(requestProductSecondaryCategoryAdd());
		return categoryApi
			.addProductSecondaryCategory(data)
			.then((resp) => {
				const data = resp.data.data;
				return data;
			})
			.catch((err) => {
				const errorMessage = err.response && err.response.data.message;
				dispatch(addProductSecondaryFailure(errorMessage));
				Notification("error", "Error Occurred", errorMessage);
			});
	};
};

//FOR UPDATE Category
export const requestUpdateProductSecondaryCategory = () => {
	return {
		type: UPDATE_PRODUCT_SECONDARY_CATEGORY_REQUEST,
	};
};

export const updateProductSecondaryCategorySuccess = (data) => {
	return {
		type: UPDATE_PRODUCT_SECONDARY_CATEGORY_SUCCESS,
		data,
	};
};

export const updateProductSecondaryCategoryFailure = (message) => {
	return {
		type: UPDATE_PRODUCT_SECONDARY_CATEGORY_FAILURE,
		message,
	};
};

export const updateProductSecondaryCategory = (id, data) => {
	return (dispatch) => {
		dispatch(requestUpdateProductSecondaryCategory());
		return categoryApi
			.updateProductSecondaryCategory(id, data)
			.then((resp) => {
				const data = resp.data;
				return data;
			})
			.catch((err) => {
				const errorMessage = err.response && err.response.data.message;
				dispatch(updateProductSecondaryCategoryFailure(errorMessage));
				throw err;
			});
	};
};

export const requestGetAllPrimaryRelatedSecondaryCategories = () => {
	return {
		type: GET_PRIMARY_RELATED_SECONDARY_CATEGORIES_REQUEST,
	};
};

export const getAllPrimaryRelatedSecondaryCategoriesSuccess = (data) => {
	return {
		type: GET_PRIMARY_RELATED_SECONDARY_CATEGORIES_SUCCESS,
		data,
	};
};

export const getAllPrimaryRelatedSecondaryCategoriesFailure = (message) => {
	return {
		type: GET_PRIMARY_RELATED_SECONDARY_CATEGORIES_FAILURE,
		message,
	};
};

// Get All Brand Categories
export const getPrimaryRelatedSecondaryCategories = (id) => {
	return (dispatch) => {
		dispatch(requestGetAllPrimaryRelatedSecondaryCategories());
		return categoryApi
			.getPrimaryRelatedSecondaryCategories(id)
			.then((resp) => {
				const data = resp.data.data;
				dispatch(getAllPrimaryRelatedSecondaryCategoriesSuccess(data));
			})
			.catch((err) => {
				const errorMessage =
					(err?.response && err?.response?.data?.message) || "Something went wrong!";
				dispatch(getAllPrimaryRelatedSecondaryCategoriesFailure(errorMessage));
			});
	};
};

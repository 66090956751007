import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAllSubDistricts, getSubDistricts } from "../../store/actions/place/place.action";

export const useIndianSubDistricts = () => {
	const dispatch = useDispatch();

	const getIndianSubDistricts = async (districtId) => {
		const data = await dispatch(getSubDistricts(districtId));
		return data;
	};

	return { getIndianSubDistricts };
};

export const useIndianAllSubDistricts = () => {
	const dispatch = useDispatch();
	const Subdistricts = useSelector((state) => state?.place?.allSubDistricts);

	useEffect(() => {
		if (Subdistricts?.length === 0) dispatch(getAllSubDistricts());
	}, [dispatch, Subdistricts?.length]);

	const getIndianAllSubDistricts = () => {
		return Subdistricts;
	};

	return { getIndianAllSubDistricts };
};

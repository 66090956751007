import {
	ADD_LOGIN_SUCCESS,
	ADMIN_ME_SUCCESS,
	GET_ADMIN_SUCCESS,
	GET_USERS_SUCCESS,
	LOGOUT_SUCCESS,
} from "../actions/admin/auth.actionType";
import globalState from "../state";

const requestRegex = /_REQUEST/;
const errorRegex = /_FAILURE/;
const successRegex = /_SUCCESS/;

const initialState = {
	errorMessage: "",
	isLoading: false,
	admin: {},
	admins: [],
	users: [],

	...globalState,
};

const AdminReducer = (state = initialState, action) => {
	const isRequest = requestRegex.test(action.type);
	const isError = errorRegex.test(action.type);
	const isSuccess = successRegex.test(action.type);

	if (isRequest) {
		return { ...state, isLoading: true, errorMessage: "" };
	}
	if (isError) {
		return { ...state, isLoading: false, errorMessage: action.message };
	}
	if (isSuccess) {
		state = { ...state, isLoading: false, errorMessage: "" };
	}

	switch (action.type) {
		case ADD_LOGIN_SUCCESS:
			return { ...state };
		case ADMIN_ME_SUCCESS:
			return { ...state, isAuthenticated: true, admin: action.data };
		case LOGOUT_SUCCESS:
			return { ...state, isAuthenticated: false, admin: {} };

		case GET_ADMIN_SUCCESS:
			return { ...state, isAuthenticated: true, admins: action?.data };

		case GET_USERS_SUCCESS:
			return { ...state, isAuthenticated: true, users: action?.data };
		default:
			return state;
	}
};

export default AdminReducer;

import { EyeOutlined } from "@ant-design/icons";
import {
	Button,
	Card,
	Checkbox,
	Col,
	Form,
	Menu,
	message,
	Modal,
	Row,
	Space,
	Tag,
	Typography,
} from "antd";
import { useEffect, useMemo, useState } from "react";
import { ErrorBoundary } from "react-error-boundary";
import { GrResources } from "react-icons/gr";
import { HiOutlineTemplate } from "react-icons/hi";
import { useDispatch, useSelector } from "react-redux";
import { NavLink, useHistory, useLocation } from "react-router-dom";
import BasicLayout from "../../components/BasicLayout";
import Center from "../../components/common/Center";
import FallBack from "../../components/common/FallBack/FallBack";
import Notification from "../../components/common/Notification";
import Tables from "../../components/common/Table/Table";
import InputField from "../../components/Form/InputField";
import { useUpdateReason } from "../../components/Hooks/useUpdateReason";
import {
	addTemplate,
	getSingleTemplate,
	updateTemplate,
} from "../../store/actions/template/template.action";
import { Resources, subResources } from "../../utils/constant";
import "./template.css";

const UserTemplateForm = (props) => {
	const { Title } = Typography;
	const { SubMenu } = Menu;
	const [form] = Form.useForm();
	const CheckboxGroup = Checkbox.Group;
	const { modelToggle, UpdateModel } = useUpdateReason();
	const [onFinishValues, setOnFinishValues] = useState();
	const [currentSelectedResource, setCurrentSelectedResource] = useState();
	const [modalVisible, setModalVisible] = useState(false);
	const dispatch = useDispatch();
	const location = useLocation();
	const history = useHistory();
	const softdelete = new URLSearchParams(location.search).get("soft-delete");
	const template = useSelector((state) => state?.template?.template);
	const templateId = +props.match.params.id;
	const currentLocation = props.location.pathname;

	useEffect(() => {
		if (templateId) {
			dispatch(getSingleTemplate(templateId, softdelete)).catch((err) => {
				Notification("error", err, "", "topRight");
				history.push("/usermanagement/template");
			});
		}
	}, [templateId, history, softdelete, dispatch]);

	const onPreviewClick = () => {
		setModalVisible(true);
	};

	const handleClick = (e) => {
		setCurrentSelectedResource({ subOption: e.key, option: e.keyPath[1] });
	};

	const defaultValues = useMemo(
		() => ({
			name: templateId ? template?.name : "",
		}),
		[templateId, template?.name]
	);

	useEffect(() => {
		form.setFieldsValue(defaultValues);
	}, [form, defaultValues]);

	const accessOptions1 = ["read", "write", "update", "import", "export", "delete"];

	const [checkedList, setCheckedList] = useState();

	useEffect(() => {
		if (currentLocation !== "/usermanagement/template/add") {
			if (template?.permissions) {
				setCheckedList(template?.permissions);
				for (const [key] of Object.entries(template.permissions)) {
					const dbObjMenu = template.permissions[key];
					for (const [key, value] of Object.entries(dbObjMenu)) {
						const dbSubMenuKey = key;
						const dbAccessAllowed = value;
						if (dbAccessAllowed.length === 0) {
							delete dbObjMenu[dbSubMenuKey];
						}
					}
				}
			}
		}
	}, [template, currentLocation]);

	const onBoxChange = (list) => {
		setCheckedList((val) => {
			return {
				...val,
				[currentSelectedResource?.option]: val
					? val[currentSelectedResource?.option]
						? {
								...val[currentSelectedResource?.option],
								[currentSelectedResource?.subOption]: val
									? val[currentSelectedResource?.subOption]
										? { ...val[currentSelectedResource?.subOption], ...list }
										: list
									: list,
						  }
						: {
								[currentSelectedResource?.subOption]: val
									? val[currentSelectedResource?.subOption]
										? { ...val[currentSelectedResource?.subOption], ...list }
										: list
									: list,
						  }
					: {
							[currentSelectedResource?.subOption]: val
								? val[currentSelectedResource?.subOption]
									? { ...val[currentSelectedResource?.subOption], ...list }
									: list
								: list,
					  },
			};
		});
	};

	const dataSource = useMemo(() => {
		let tempData = [];
		if (checkedList) {
			for (const [key] of Object.entries(checkedList)) {
				const objMenu = checkedList[key];
				const mainMenu = key.replace(/([A-Z])/g, " $1");
				for (const [key, value] of Object.entries(objMenu)) {
					const subMenuKey = key.replace(/([A-Z])/g, " $1");
					const accessAllowed = value;

					if (accessAllowed.length === 0) {
						delete objMenu[subMenuKey];
					}
					tempData?.push({
						menu: mainMenu.replace(/^./, function (str) {
							return str.toUpperCase();
						}),
						subMenu: subMenuKey.replace(/^./, function (str) {
							return str.toUpperCase();
						}),
						access: accessAllowed,
						key: subMenuKey,
					});
				}
			}
			return tempData;
		}
		return [{}];
	}, [checkedList]);

	const onFinish = async (values) => {
		try {
			let newValues = {
				...values,
				permissions: { ...checkedList },
			};
			try {
				if (currentLocation === "/usermanagement/template/add") {
					await dispatch(addTemplate({ ...newValues }));
					history.push("/usermanagement/template");
					return;
				}
				let tmpPer = {};
				for (const [key] of Object.entries(newValues.permissions)) {
					const dbObjMenu = newValues.permissions[key];
					for (const [key, value] of Object.entries(dbObjMenu)) {
						const dbSubMenuKey = key;
						const dbAccessAllowed = value;
						if (dbAccessAllowed.length === 0) {
							delete dbObjMenu[dbSubMenuKey];
						}
					}
					const isEmpty = Object.keys(dbObjMenu).length === 0;
					if (!isEmpty) {
						tmpPer = { ...tmpPer, [key]: dbObjMenu };
					}
				}
				const finalVal = { ...newValues, permissions: tmpPer };
				setOnFinishValues(finalVal);
				await modelToggle(true);
			} catch (err) {
				if (err.message) {
					return Notification("error", "Error Occurred", err.message, "topRight");
				}
				const errorMessage = err.message
					? err.message
					: (err?.response && err.response?.data?.message) || "Soemthing went wrong";
				return Notification("error", "", errorMessage);
			}
		} catch (err) {
			const errorMessage = "Something went wrong";
			return Notification("error", "Error Occurred", errorMessage, "topRight");
		}
	};
	const handleError = (error) => {
		message.error(error.message);
	};
	return (
		<BasicLayout accessLevel={props.accessLevel}>
			<ErrorBoundary FallbackComponent={FallBack} isError={handleError}>
				<Row type="flex" justify="center" align="middle" id="form-container">
					<Center height="4rem">
						{templateId ? (
							<Title level={2} className="update-or-add-title">
								Update Template
							</Title>
						) : (
							<Title level={2} className="update-or-add-title">
								Add New Template
							</Title>
						)}
					</Center>
					<Col>
						<Card className="form-card">
							<Form
								form={form}
								name="basic"
								layout="horizontal"
								onFinish={onFinish}
								initialValues={defaultValues}
							>
								<InputField
									rules={[
										{
											required: true,
										},
										{
											min: 1,
											message: "Template Name be minimum 1 character.",
										},
										{
											max: 100,
											message: "Template Name be minimum 100 character.",
										},
									]}
									label="Template Name"
									name="name"
									style={{
										width: "100%",
										display: "flex",
										flexDirection: "column",
									}}
									placeholder="Please enter template name"
									prefix={<HiOutlineTemplate />}
								/>
								<div className="templateFormResourcesGroup">
									<div className="templateFormTextGroupChild">
										<Title
											level={5}
											style={{
												fontWeight: "400",
												fontSize: "18px",
												marginTop: "20px",
											}}
										>
											<span
												style={{
													color: "#f08080",
													fontWeight: "thin",
													fontSize: "16px",
												}}
											>
												*
											</span>{" "}
											Resources :
										</Title>
										<Menu
											onClick={handleClick}
											style={{ width: 156 }}
											defaultSelectedKeys={[""]}
											defaultOpenKeys={[""]}
											triggerSubMenuAction="click"
											mode="horizontal"
										>
											<SubMenu key="resouces" icon={<GrResources />} title="Resouces" theme="dark">
												{Resources?.map((node) => {
													return (
														<SubMenu key={node.key} title={node.node}>
															{subResources?.map((data) => {
																return (
																	data.tree === node.node && (
																		<Menu.Item key={data.value}>{data.title}</Menu.Item>
																	)
																);
															})}
														</SubMenu>
													);
												})}
											</SubMenu>
										</Menu>
									</div>
									{currentSelectedResource && (
										<div className="templateFormTextGroupChild">
											<Title
												level={5}
												style={{
													fontWeight: "400",
													fontSize: "18px",
													marginBottom: "20px",
													marginTop: "20px",
												}}
											>
												<span
													style={{
														color: "#f08080",
														fontWeight: "thin",
														fontSize: "16px",
													}}
												>
													*
												</span>{" "}
												Allow Access Through Template :
											</Title>
											<CheckboxGroup
												options={accessOptions1}
												value={
													checkedList
														? checkedList[currentSelectedResource?.option]
															? checkedList[currentSelectedResource?.option][
																	currentSelectedResource?.subOption
															  ]
															: undefined
														: undefined
												}
												onChange={onBoxChange}
												style={{
													width: "70%",
													display: "flex",
													flexWrap: "wrap",
													justifyContent: "space-between",
												}}
											/>
										</div>
									)}
								</div>
								{checkedList && (
									<>
										<Title
											level={5}
											style={{
												fontWeight: "400",
												fontSize: "18px",
												marginTop: "30px",
											}}
										>
											Preview Access :
										</Title>{" "}
										<Button
											type="primary"
											onClick={onPreviewClick}
											shape="round"
											icon={<EyeOutlined />}
										>
											Preview
										</Button>
									</>
								)}
								<Modal
									width={800}
									title={
										<Center height="4rem">
											<Title level={2} className="update-or-add-title">
												Allowed Access Through this template
											</Title>
										</Center>
									}
									visible={modalVisible}
									onOk={() => setModalVisible(false)}
									onCancel={() => setModalVisible(false)}
								>
									<Title
										level={5}
										style={{
											fontWeight: "600",
											fontSize: "18px",
										}}
									>
										Selected ::
									</Title>
									<Tables columns={columns} dataSource={dataSource} pagination={false} />
								</Modal>
								<UpdateModel
									id={templateId}
									action={updateTemplate}
									valuesWithoutReason={onFinishValues}
									redirectUrl="/usermanagement/template"
									placeholder="Enter reason for update template"
								/>
								<Form.Item style={{ marginTop: "40px" }}>
									<Center height="0px">
										<Space>
											<NavLink to="/usermanagement/template">
												<Button danger type="primary">
													Cancel
												</Button>
											</NavLink>
											<Button type="primary" htmlType="submit">
												{templateId ? "Update" : "Add"}
											</Button>
										</Space>
									</Center>
								</Form.Item>
							</Form>
						</Card>
					</Col>
				</Row>
			</ErrorBoundary>
		</BasicLayout>
	);
};
export default UserTemplateForm;
const columns = [
	{
		title: "Main Menu",
		dataIndex: "menu",
		key: "menu",
	},
	{
		title: "SubMenu",
		dataIndex: "subMenu",
		key: "subMenu",
	},
	{
		title: "Allowed Access",
		dataIndex: "access",
		key: "access",
		render: (access) => (
			<>
				{access?.map((tag) => {
					return (
						<Tag color="green" key={tag}>
							{tag?.toUpperCase()}
						</Tag>
					);
				})}
			</>
		),
	},
];

import Notification from "../../../components/common/Notification";
import * as templateApi from "../../../services/template";
import {
	ADD_TEMPLATE_FAILURE,
	ADD_TEMPLATE_REQUEST,
	ADD_TEMPLATE_SUCCESS,
	GET_SINGLE_TEMPLATE_FAILURE,
	GET_SINGLE_TEMPLATE_REQUEST,
	GET_SINGLE_TEMPLATE_SUCCESS,
	GET_TEMPLATES_FAILURE,
	GET_TEMPLATES_REQUEST,
	GET_TEMPLATES_SUCCESS,
	RESTORE_SINGLE_TEMPLATE_FAILURE,
	RESTORE_SINGLE_TEMPLATE_REQUEST,
	RESTORE_SINGLE_TEMPLATE_SUCCESS,
	SOFT_DELETE_SINGLE_TEMPLATE_FAILURE,
	SOFT_DELETE_SINGLE_TEMPLATE_REQUEST,
	SOFT_DELETE_SINGLE_TEMPLATE_SUCCESS,
	UPDATE_TEMPLATE_FAILURE,
	UPDATE_TEMPLATE_REQUEST,
	UPDATE_TEMPLATE_SUCCESS,
} from "./template.actionType";

// GET REQUEST
export const requestGetAllTemplates = () => {
	return {
		type: GET_TEMPLATES_REQUEST,
	};
};
export const getAllTemplatesSuccess = (data) => {
	return {
		type: GET_TEMPLATES_SUCCESS,
		data,
	};
};
export const getAllTemplatesFailure = (message) => {
	return {
		type: GET_TEMPLATES_FAILURE,
		message,
	};
};

// Get All TechnicalSpecifications
export const getAllTemplates = () => {
	return (dispatch) => {
		dispatch(requestGetAllTemplates());
		return templateApi
			.getAllTemplates()
			.then((resp) => {
				const data = resp.data.data;
				dispatch(getAllTemplatesSuccess(data));
			})
			.catch((err) => {
				const errorMessage =
					(err?.response && err?.response?.data?.message) || "Something went wrong!";
				dispatch(getAllTemplatesFailure(errorMessage));
			});
	};
};

export const requestGetSingleTemplate = () => {
	return {
		type: GET_SINGLE_TEMPLATE_REQUEST,
	};
};

export const getSingleTemplateSuccess = (data) => {
	return {
		type: GET_SINGLE_TEMPLATE_SUCCESS,
		data,
	};
};

export const getSingleTemplateFailure = (message) => {
	return {
		type: GET_SINGLE_TEMPLATE_FAILURE,
		message,
	};
};

export const getSingleTemplate = (id, type) => {
	return (dispatch) => {
		dispatch(requestGetSingleTemplate());
		return templateApi
			.getSingleTemplate(id, type)
			.then((resp) => {
				const data = resp.data.data;
				dispatch(getSingleTemplateSuccess(data));
			})
			.catch((err) => {
				const errorMessage =
					(err?.response && err?.response?.data?.message) || "Something went wrong";
				dispatch(getSingleTemplateFailure(errorMessage));
				throw errorMessage;
			});
	};
};

export const requestSoftDeleteSingleTemplate = () => {
	return {
		type: SOFT_DELETE_SINGLE_TEMPLATE_REQUEST,
	};
};

export const softDeleteSingleTemplateSuccess = (data) => {
	return {
		type: SOFT_DELETE_SINGLE_TEMPLATE_SUCCESS,
		data,
	};
};

export const softDeleteSingleTemplateFailure = (message) => {
	return {
		type: SOFT_DELETE_SINGLE_TEMPLATE_FAILURE,
		message,
	};
};

export const softDeleteSingleTemplate = (id, reason) => {
	return (dispatch) => {
		dispatch(requestSoftDeleteSingleTemplate());
		return templateApi
			.softDeleteSingleTemplate(id, reason)
			.then((resp) => {
				dispatch(softDeleteSingleTemplateSuccess(id));
				return Notification("success", resp.data.message, "", "topRight");
			})
			.catch((err) => {
				const errorMessage =
					(err?.response && err?.response?.data?.message) || "Something went wrong";
				dispatch(softDeleteSingleTemplateFailure(errorMessage));
				throw errorMessage;
			});
	};
};

export const requestTemplateRestore = () => {
	return {
		type: RESTORE_SINGLE_TEMPLATE_REQUEST,
	};
};

export const restoreTemplateSuccess = () => {
	return {
		type: RESTORE_SINGLE_TEMPLATE_SUCCESS,
	};
};

export const restoreTemplateFailure = (message) => {
	return {
		type: RESTORE_SINGLE_TEMPLATE_FAILURE,
		message,
	};
};

export const restoreTemplate = (id) => {
	return (dispatch) => {
		dispatch(requestTemplateRestore());
		return templateApi
			.restoreTemplate(id)
			.then((resp) => {
				dispatch(restoreTemplateSuccess(id));
				Notification("success", resp.data.message, "", "topRight");
			})
			.catch((err) => {
				const errorMessage = (err.response && err.response.data.message) || "Something went wrong!";
				dispatch(restoreTemplateFailure(errorMessage));
			});
	};
};

export const requestTemplateAdd = () => {
	return {
		type: ADD_TEMPLATE_REQUEST,
	};
};

export const templateAddSuccess = (data) => {
	return {
		type: ADD_TEMPLATE_SUCCESS,
		data,
	};
};

export const templateAddFailure = (message) => {
	return {
		type: ADD_TEMPLATE_FAILURE,
		message,
	};
};

export const addTemplate = (data) => {
	return (dispatch) => {
		dispatch(requestTemplateAdd());
		return templateApi
			.addTemplate(data)
			.then((resp) => {
				const message = resp.data.message;
				dispatch(templateAddSuccess());
				Notification("success", "", message);
			})
			.catch((err) => {
				const errorMessage = err.response && err.response.data.message;
				dispatch(templateAddFailure(errorMessage));
				Notification("error", "Error Occurred", errorMessage);
			});
	};
};

//FOR UPDATE TechnicalSpecification
export const requestUpdateTemplate = () => {
	return {
		type: UPDATE_TEMPLATE_REQUEST,
	};
};

export const updateTemplateSuccess = () => {
	return {
		type: UPDATE_TEMPLATE_SUCCESS,
	};
};

export const updateTemplateFailure = (message) => {
	return {
		type: UPDATE_TEMPLATE_FAILURE,
		message,
	};
};

export const updateTemplate = (id, data) => {
	return (dispatch) => {
		dispatch(requestUpdateTemplate());
		return templateApi
			.updateTemplate(id, data)
			.then((resp) => {
				const message = resp.data.message;
				dispatch(updateTemplateSuccess());
				Notification("success", "", message);
			})
			.catch((err) => {
				const errorMessage = err.response && err.response.data.message;
				dispatch(updateTemplateFailure(errorMessage));
				throw err;
			});
	};
};

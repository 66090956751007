export const GET_DEALER_CONTACTS_REQUEST = "GET_DEALER_CONTACTS_REQUEST",
	GET_DEALER_CONTACTS_SUCCESS = "GET_DEALER_CONTACTS_SUCCESS",
	GET_DEALER_CONTACTS_FAILURE = "GET_DEALER_CONTACTS_FAILURE";

export const GET_DEALER_SINGLE_CONTACT_REQUEST = "GET_DEALER_SINGLE_CONTACT_REQUEST",
	GET_DEALER_SINGLE_CONTACT_SUCCESS = "GET_DEALER_SINGLE_CONTACT_SUCCESS",
	GET_DEALER_SINGLE_CONTACT_FAILURE = "GET_DEALER_SINGLE_CONTACT_FAILURE";

export const SOFTDELETE_DEALER_SINGLE_CONTACT_REQUEST = "SOFTDELETE_DEALER_SINGLE_CONTACT_REQUEST",
	SOFTDELETE_DEALER_SINGLE_CONTACT_SUCCESS = "SOFTDELETE_DEALER_SINGLE_CONTACT_SUCCESS",
	SOFTDELETE_DEALER_SINGLE_CONTACT_FAILURE = "SOFTDELETE_DEALER_SINGLE_CONTACT_FAILURE";

import React from "react";

const Center = (props) => {
	return (
		<div
			style={{
				display: "flex",
				flexDirection: "column",
				justifyContent: "center",
				alignItems: "center",
				height: props.height || "100vh",
				...props,
			}}
		>
			{props.children}
		</div>
	);
};

export default Center;

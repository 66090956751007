import { Form, Select } from "antd";
import React from "react";

const { Option } = Select;

const index = ({
	label,
	name,
	onChange,
	rules,
	placeholder,
	options,
	className,
	defaultValue = null,
}) => {
	return (
		<Form.Item name={name} label={label} rules={rules}>
			<Select
				style={{ marginTop: "10px", width: "100%", display: "flex", flexDirection: "column" }}
				defaultValue={defaultValue}
				showSearch
				placeholder={placeholder}
				className={className ? className : ""}
				filterOption={(input, option) =>
					option.title.toLowerCase().indexOf(input.toLowerCase()) >= 0
				}
				onChange={onChange}
			>
				{options.map((option, index) => (
					<Option value={option.value} key={index} title={option.key}>
						{option.key}
					</Option>
				))}
			</Select>
		</Form.Item>
	);
};

export default index;

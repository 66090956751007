import { invokeApi } from "../utils/api";
import { $axios } from "../utils/axios";

export const distributorContact = () =>
	invokeApi($axios, "/distributor/contact", {}, "GET")
		.then((resp) => resp)
		.catch((err) => {
			throw err;
		});

export const distributorSingleContact = (id, softdelete) =>
	invokeApi(
		$axios,
		`/distributor/contact/${softdelete === "true" ? "soft-delete/" : ""}${id}`,
		{},
		"GET"
	)
		.then((resp) => resp)
		.catch((err) => {
			throw err;
		});

export const softDeleteDistributorSingleContact = (id, reason) =>
	invokeApi($axios, `/distributor/contact/soft-delete/${id}?reason=${reason}`, {}, "DELETE")
		.then((resp) => resp)
		.catch((err) => {
			throw err;
		});

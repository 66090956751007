import { UserOutlined } from "@ant-design/icons";
import { Button, Card, Col, Form, message, Row, Space, Typography, Select } from "antd";
import React, { useEffect, useMemo, useState } from "react";
import { ErrorBoundary } from "react-error-boundary";
import { useDispatch, useSelector } from "react-redux";
import { NavLink, Redirect, useHistory } from "react-router-dom";
import BasicLayout from "../../components/BasicLayout/index";
import Center from "../../components/common/Center";
import FallBack from "../../components/common/FallBack/FallBack";
import Notification from "../../components/common/Notification";
import InputField from "../../components/Form/InputField";
import {
	getSingleCustomer,
	updateSingleCustomer,
} from "../../store/actions/customer/customer.action";
import { useIndianStates } from "../../components/Hooks/useIndianStates";
import { useIndianDistricts } from "../../components/Hooks/useIndianDistricts";
import { useIndianSubDistricts } from "../../components/Hooks/useIndianSubDistricts";
import { WordCountDisplayer } from "../../components/common/WordCountDisplayer";
const { Title } = Typography;

const { Option } = Select;
const CustomerUpdateForm = (props) => {
	const history = useHistory();
	const [form] = Form.useForm();
	const customerId = +props.match.params.id;
	const dispatch = useDispatch();

	const customer = useSelector((state) => state.customer.customer);
	const redirectTo = useSelector((state) => state.other.redirectTo);

	const [selectedStateId, setSelectedStateId] = useState("");
	const [selectedDistrictId, setSelectedDistrictId] = useState("");
	const [districtList, setDistrictList] = useState([]);
	const [subDistrictList, setSubDistrictList] = useState([]);

	const { getIndianStates } = useIndianStates();
	const { getIndianDistricts } = useIndianDistricts();
	const { getIndianSubDistricts } = useIndianSubDistricts();

	useEffect(() => {
		if (selectedStateId === "" || selectedStateId === undefined) {
			if (customer) {
				const data = getIndianStates()?.filter(
					(state) => state["State Name(In English)"] === customer?.state
				);
				const stateCode = data?.map((data) => {
					return data["State Code"].toString();
				});
				setSelectedStateId(stateCode[0]);
			}
		}
		if (selectedDistrictId === "" || selectedDistrictId === undefined) {
			if (customer) {
				const districtData = districtList?.filter(
					(state) => state["District Name(In English)"] === customer?.district
				);
				const districtCode = districtData?.map((data) => {
					return data["District Code"].toString();
				});
				setSelectedDistrictId(districtCode[0]);
			}
		}
	}, [selectedStateId, customer, getIndianStates, selectedDistrictId, districtList]);
	useEffect(() => {
		if (selectedStateId) {
			getIndianDistricts(selectedStateId)
				.then((resp) => {
					setDistrictList(resp);
				})
				.catch((err) => {
					return Notification("Something went wrong", "", "", "topRight");
				});
		}
		if (selectedDistrictId) {
			getIndianSubDistricts(selectedDistrictId)
				.then((resp) => {
					setSubDistrictList(resp);
				})
				.catch((err) => {
					return Notification("Something went wrong", "", "", "topRight");
				});
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [selectedStateId, selectedDistrictId]);

	const defaultValues = useMemo(
		() => ({
			firstname: customerId ? customer.firstname : "",
			lastname: customerId ? customer.lastname : "",
			state: customerId ? customer.state : "",
			taluka: customerId ? customer.taluka : "",
			district: customerId ? customer.district : "",
			village: customerId ? customer.village : "",
			landmark: customerId ? customer.landmark : "",
			address: customerId ? customer.address : "",
		}),
		[
			customerId,
			customer.firstname,
			customer.lastname,
			customer.state,
			customer.taluka,
			customer.district,
			customer.village,
			customer.landmark,
			customer.address,
		]
	);

	useEffect(() => {
		dispatch(getSingleCustomer(customerId)).catch((err) => {
			Notification("error", err, "", "topRight");
			history.push("/customer/info");
		});
	}, [dispatch, history, customerId]);

	useEffect(() => {
		form.setFieldsValue(defaultValues);
	}, [form, defaultValues]);

	const onFinish = async (values) => {
		try {
			if (!isNaN(values.state)) {
				const state = getIndianStates().filter((data) => data["State Code"] === values.state);
				const stateName = state[0]["State Name(In English)"];

				if (values.district === "" || values.district === undefined) {
					return Notification("Please Select district", "", "", "topRight");
				}

				const districtArr = districtList.filter(
					(data) => data["District Code"] === values.district
				);
				const districtName = districtArr[0]["District Name(In English)"];

				if (values.taluka === "" || values.taluka === undefined) {
					return Notification("Please Select Taluka", "", "", "topRight");
				}

				const subDistrictArr = subDistrictList.filter(
					(data) => data["Sub District Code"] === values.taluka
				);
				const subDistrictName = subDistrictArr[0]["Sub District Name"];

				let data = {
					...values,
					state: stateName,
					district: districtName,
					taluka: subDistrictName,
				};

				await dispatch(updateSingleCustomer(customerId, data, history));
			} else {
				await dispatch(updateSingleCustomer(customerId, values, history));
			}
		} catch (err) {
			const errorMessage =
				(err?.response && err?.response?.data?.message) || "Something went wrong";
			return Notification("error", "Error Occurred", errorMessage, "topRight");
		}
	};
	const updateCustomerCancel = () => {
		Notification("info", "Customer  updation has been cancelled", "", "topRight");
	};
	const Rules = [
		{
			required: true,
		},
	];

	const handleError = (error) => {
		message.error(error.message);
	};
	if (redirectTo) {
		return <Redirect to={redirectTo} />;
	}

	// This are state and district changed functions
	const onChangeState = (value) => {
		setSelectedStateId(value);
		setSelectedDistrictId("");
		form.setFieldsValue({ district: "", taluka: "" });
	};
	const onChangeDistrict = (value) => setSelectedDistrictId(value);

	return (
		<BasicLayout accessLevel={props.accessLevel}>
			<ErrorBoundary FallbackComponent={FallBack} onError={handleError}>
				<Row type="flex" justify="center" align="middle" id="form-container">
					<Center height="4rem">
						<Title level={2} className="update-or-add-title">
							Update Customer
						</Title>
					</Center>
					<Col>
						<Card className="form-card" style={{ height: "500px", overflowY: "scroll" }}>
							<Form
								form={form}
								name="basic"
								layout="vertical"
								onFinish={onFinish}
								initialValues={defaultValues}
							>
								<InputField
									rules={Rules}
									label="Firstname"
									name="firstname"
									placeholder="Please enter firstname"
									prefix={<UserOutlined />}
								/>
								<InputField
									rules={Rules}
									label="Lastname"
									name="lastname"
									placeholder="Please enter lastname"
									prefix={<UserOutlined />}
								/>
								<Form.Item label="Select State" name="state" rules={Rules}>
									<Select
										// defaultValue={selectedStateId}
										style={{ width: "100%" }}
										onChange={onChangeState}
									>
										{getIndianStates().map((state, index) => (
											<Option value={state["State Code"]} key={index}>
												{state["State Name(In English)"]}
											</Option>
										))}
									</Select>
								</Form.Item>
								<Form.Item label="Select District" name="district" rules={Rules}>
									<Select onChange={onChangeDistrict} style={{ width: "100%" }}>
										{selectedStateId &&
											districtList.map((district, index) => (
												<Option value={district["District Code"]} key={index}>
													{district["District Name(In English)"]}
												</Option>
											))}
									</Select>
								</Form.Item>
								<Form.Item label="Enter Taluka" name="taluka" rules={Rules}>
									<Select
										style={{ width: "100%" }}
										placeholder={
											selectedDistrictId
												? "Please select a Sub-District"
												: "Please select a District first"
										}
									>
										{selectedDistrictId &&
											subDistrictList.map((district, index) => (
												<Option value={district["Sub District Code"]} key={index}>
													{district["Sub District Name"]}
												</Option>
											))}
									</Select>
								</Form.Item>
								<InputField
									rules={[
										{
											required: false,
										},
									]}
									label="Village"
									name="village"
									placeholder="Please enter village"
									prefix={<UserOutlined />}
								/>
								<InputField
									rules={Rules}
									label="Address"
									name="address"
									placeholder="Please enter address"
									prefix={<UserOutlined />}
								/>
								<InputField
									rules={Rules}
									label="Landmark"
									name="landmark"
									placeholder="Please enter landmark"
									prefix={<UserOutlined />}
								/>
								{customerId ? (
									<WordCountDisplayer
										label="Update Reason"
										name="updateReason"
										placeholder="Please enter reason"
										rules={[
											{
												required: true,
											},
											{
												message: "update reason must be minimum 10 words.",
											},
										]}
									/>
								) : null}
								<Form.Item>
									<Center height="0px">
										<Space>
											<NavLink to="/customer/info">
												<Button danger type="primary" onClick={updateCustomerCancel}>
													Cancel
												</Button>
											</NavLink>
											<Button type="primary" htmlType="submit">
												{customerId ? "Update" : "Add"}
											</Button>
										</Space>
									</Center>
								</Form.Item>
							</Form>
						</Card>
					</Col>
				</Row>
			</ErrorBoundary>
		</BasicLayout>
	);
};
export default CustomerUpdateForm;

import { Card, Image, message } from "antd";
import { useEffect, useMemo } from "react";
import { ErrorBoundary } from "react-error-boundary";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import BasicLayout from "../../../components/BasicLayout";
import FallBack from "../../../components/common/FallBack/FallBack";
import Notification from "../../../components/common/Notification";
import { CustomTab } from "../../../components/common/Tab/Tab";
import { getSingleCategory } from "../../../store/actions/products/category/categories.action";

const ViewCategory = (props) => {
	const categoryId = +props.match.params.id;
	const dispatch = useDispatch();
	const category = useSelector((state) => state.category.category || {});
	const location = useLocation();
	const softdelete = new URLSearchParams(location.search).get("soft-delete");
	const history = useHistory();
	const admin = useSelector((state) => state.admin.admin || []);

	const permissionArr = useMemo(() => {
		if (admin && admin.template) {
			const { permissions } = admin.template;
			return permissions;
		} else if (admin && admin.accessLevel === "superAdmin") {
			return "all";
		}
		return [];
	}, [admin]);

	useEffect(() => {
		dispatch(getSingleCategory(categoryId, softdelete)).catch((err) => {
			Notification("error", err, "", "topRight");
			history.push("/products/categories");
		});
	}, [dispatch, history, categoryId, softdelete]);
	let data = [];
	let isOrderEmpty = Object.keys(category).length === 0;
	if (!isOrderEmpty) {
		data = [
			{ title: "Id", description: category.id },
			{
				title: "Name",
				description: category.name,
			},
			{
				title: "Code",
				description: category.code,
			},
			{
				title: "Brand Name",
				description: category?.brand?.name,
			},
			{
				title: "Received",
				description: new Date(category.createdAt).toLocaleString(),
			},
			{
				title: "Image",
				description: (
					<div className="gallery-images">
						<div className="image-holder-container">
							<Image width={250} height={180} src={category.url} />
						</div>
					</div>
				),
			},
		];
	}

	const handleError = (error) => {
		message.error(error.message);
	};

	let operationData = {
		deletedBy: category?.deletedBy,
		updatedBy: category?.updatedBy,
		restoredBy: category?.restoredBy,
	};

	return (
		<BasicLayout accessLevel={props.accessLevel}>
			<ErrorBoundary FallbackComponent={FallBack} onError={handleError}>
				<Card className="enqiury-detail-card" data-testid="enqiury-detail-card">
					<CustomTab
						update={
							permissionArr === "all"
								? "true"
								: permissionArr?.products?.productCategories.filter((item) => item === "update")?.length > 0
								? "true"
								: false
						}
						data={data}
						operationData={operationData}
						redirectUrl={`/products/categories/update/${categoryId}`}
					/>
				</Card>
			</ErrorBoundary>
		</BasicLayout>
	);
};

export default ViewCategory;

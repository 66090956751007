import { Card, Image, message, Anchor } from "antd";
import { useMemo } from "react";
import { useEffect } from "react";
import { ErrorBoundary } from "react-error-boundary";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import BasicLayout from "../../../components/BasicLayout";
import FallBack from "../../../components/common/FallBack/FallBack";
import Notification from "../../../components/common/Notification";
import { CustomTab } from "../../../components/common/Tab/Tab";
import { getSingleBrand } from "../../../store/actions/products/brands/brand.action";

const ViewBrand = (props) => {
	const brandId = +props.match.params.id;
	const dispatch = useDispatch();
	const brand = useSelector((state) => state.brand.brand || {});
	const { Link } = Anchor;
	const history = useHistory();
	const admin = useSelector((state) => state.admin.admin || []);
	const permissionArr = useMemo(() => {
		if (admin && admin.template) {
			const { permissions } = admin.template;
			return permissions;
		} else if (admin && admin.accessLevel === "superAdmin") {
			return "all";
		}
		return [];
	}, [admin]);

	const location = useLocation();
	const softdelete = new URLSearchParams(location.search).get("soft-delete");
	useEffect(() => {
		dispatch(getSingleBrand(brandId, softdelete)).catch((err) => {
			Notification("error", err, "", "topRight");
			history.push("/products/brands");
		});
	}, [dispatch, history, softdelete, brandId]);

	let data = [];
	let isOrderEmpty = Object.keys(brand).length === 0;
	if (!isOrderEmpty) {
		data = [
			{ title: "Id", description: brand.id },
			{
				title: "Name",
				description: brand.name,
			},
			{
				title: "Code",
				description: brand.code,
			},
			{
				title: "Received",
				description: new Date(brand.createdAt).toLocaleString(),
			},

			{
				title: "Image",
				description: (
					<div className="gallery-images">
						<div className="image-holder-container">
							<Image width={250} height={180} src={brand.url} />
						</div>
					</div>
				),
			},
			{
				title: "Products Catelogue PDF",
				description: brand?.productCatalogueUrl ? (
					<Anchor>
						<Link href={brand?.productCatalogueUrl} title="View" target="_blank"></Link>
					</Anchor>
				) : (
					"NA"
				),
			},
		];
	}

	const handleError = (error) => {
		message.error(error.message);
	};
	let operationData = {
		deletedBy: brand?.deletedBy,
		updatedBy: brand?.updatedBy,
		restoredBy: brand?.restoredBy,
	};

	return (
		<BasicLayout accessLevel={props.accessLevel}>
			<ErrorBoundary FallbackComponent={FallBack} onError={handleError}>
				<Card className="enqiury-detail-card" data-testid="enqiury-detail-card">
					<CustomTab
					update={
						permissionArr === "all"
							? "true"
							: permissionArr?.products?.brands.filter((item) => item === "update")?.length > 0
							? "true"
							: false
					}
						data={data}
						operationData={operationData}
						redirectUrl={`/products/brands/update/${brandId}`}
					/>
				</Card>
			</ErrorBoundary>
		</BasicLayout>
	);
};

export default ViewBrand;

import { Form, Input } from "antd";
import React from "react";
import Notification from "../../common/Notification";

const { TextArea } = Input;
const onPaste = (e) => {
    e.preventDefault();
    return Notification(
        "error",
        "Info Error",
        "Not allowed to paste ",
        "topRight"
    );
};
const index = ({
    label,
    name,
    rules,
    placeholder,
    className,
    autoComplete,
    handleOnChange,
    extra = undefined,
    isPasteActive,
}) => {
    return (
        <Form.Item label={label} name={name} rules={rules} extra={extra}>
            <TextArea
                onChange={handleOnChange ? handleOnChange : () => {}}
                placeholder={placeholder}
                autoSize={{ minRows: 3, maxRows: 10 }}
                className={className ? className : ""}
                autoComplete={autoComplete ? autoComplete : ""}
                onPaste={isPasteActive ? () => {} : onPaste}
            />
        </Form.Item>
    );
};

export default index;
